import { Component, OnInit, Inject } from '@angular/core';
import { MyserviceService } from 'src/app/myservice.service';
import { Utils } from '../../utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
	selector: 'app-contact-list-campaign-contact-dialog-page',
	templateUrl: './contact-list-campaign-contact-dialog-page.component.html',
	styleUrls: ['./contact-list-campaign-contact-dialog-page.component.scss']
})
export class ContactListCampaignContactDialogPageComponent implements OnInit {
	viewRecord: any = [];

	cust: any;
	arrayCustomVs: any = [];
	arrayCustomVs_labels: any = ['Application Number', 'Card Type', 'KYC', 'KYC Surrogates', 'surrogate Document', 'Appointment Date', 'Appointment Date Time', 'pickup Location', 'Remark', 'submit date'];
	_filterArrayAddon1_labels:Array<string> = ['Addon1 KYC', 'Addon1 Appointment Date','Addon1 Appintment Time', 'Addon1 Pickup Location','Addon1 Name','Addon1 Gender','Addon1 Mobile','Addon1 Email','Addon1 Relationship','Addon1 Stay Primary'];
	_filterArrayAddon2_labels:Array<string> = ['Addon2 KYC','Addon2 Appointment Date','Addon2 Appintment Time','Addon2 Pickup Location','Addon2 Name','Addon2 Gender','Addon2 Mobile','Addon2 Email','Addon2 Relationship','Addon2 Stay Primary']
	_filterArrayAddon3_labels:Array<string> = ['Addon3 KYC', 'Addon3 Appointment Date','Addon3 Appintment Time','Addon3 Pickup Location','Addon3 Name','Addon3 Gender','Addon3 Mobile','Addon3 Email','Addon3 Relationship','Addon3 Stay Primary']
	_filterArrayAddon4_labels:Array<string> = ['Addon4 KYC','Addon4 Appointment Date','Addon4 Appintment Time','Addon4 Pickup Location','Addon4 Name','Addon4 Gender','Addon4 Mobile','Addon4 Email','Addon4 Relationship','Addon4 Stay Primary']
	_pathCheckUrl = window.location.pathname;
	renderArrayAddon1: any = [];
	renderArrayAddon2: any = [];

	renderArrayAddon3: any = [];
	renderArrayAddon4: any = [];
	constructor(
		public dialogRef: MatDialogRef<ContactListCampaignContactDialogPageComponent>, 
		@Inject(MAT_DIALOG_DATA) public viewData, 
		private myservice: MyserviceService,
		private utils: Utils) { }

	ngOnInit() {
		this.viewRecord = this.viewData;
		// console.log('view data', this.viewData)
		// console.log('pathCheckURL', this._pathCheckUrl)
		//debugger;
		if (this._pathCheckUrl == "/LC-Coordinator") {
			let _tempArrayVs = [];
			if (this.viewRecord && this.viewRecord.feedback) { //this.viewRecord.customkvs
				// this.cust = (this.viewRecord.feedback.replace(/['"]+/g, '')).split("/r");
				let _filterArrayAddon1 = [];
				let _filterArrayAddon2 = [];
				let _filterArrayAddon3 = [];
				let _filterArrayAddon4 = [];
				
				
				for(var i in this.viewRecord.feedback){
					const key = this.insertSpaces(i.replace(/\\/g, ''))
					const value = this.viewRecord.feedback[i] ? this.viewRecord.feedback[i].replaceAll(null, '').replace('Invalid date', '') : '--'
					const obj = {[key]: value};
					if(key == 'submit date'){
						obj[key] = this.utils.utcToLocal(value, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY HH:mm')
					}
					// console.log(obj)
					if(this.arrayCustomVs_labels.indexOf(key) > -1){
						this.arrayCustomVs.push(obj)
					}
					if(this._filterArrayAddon1_labels.indexOf(key) > -1){
						_filterArrayAddon1.push(obj)
					}
					if(this._filterArrayAddon2_labels.indexOf(key) > -1){
						_filterArrayAddon2.push(obj)
					}
					if(this._filterArrayAddon3_labels.indexOf(key) > -1){
						_filterArrayAddon3.push(obj)
					}
					if(this._filterArrayAddon4_labels.indexOf(key) > -1){
						_filterArrayAddon4.push(obj)
					}

				}
				/* for (let i = 0; i < this.cust.length; i++) {

					_tempArrayVs.push({
						"labelName": this.insertSpaces(this.cust[i].split(":")[0].replace(/\\/g, '')),
						"valueOfLabel": this.cust[i].split(":")[1] ? this.cust[i].split(":")[1].replaceAll(null, '').replace('Invalid date', '') : '--'
					})
					if (_tempArrayVs[i].labelName == 'Application Number' || _tempArrayVs[i].labelName == 'Card Type' || _tempArrayVs[i].labelName == 'KYC' || 
					_tempArrayVs[i].labelName == 'KYC Surrogates' || _tempArrayVs[i].labelName == 'surrogate Document' || _tempArrayVs[i].labelName == 'Appointment Date' || 
					_tempArrayVs[i].labelName == 'Appointment Date Time' || _tempArrayVs[i].labelName == 'pickup Location' ||  _tempArrayVs[i].labelName ==  'Remark') {

						this.arrayCustomVs.push({
							"labelName": this.insertSpaces(this.cust[i].split(":")[0].replace(/\\/g, '')),
							"valueOfLabel": this.cust[i].split(":")[1] ? this.cust[i].split(":")[1].replaceAll(null, '').replace('Invalid date', '').replace('.',':') : '--'
						})
					}
				} */
				

				/*console.log('customvs', this.arrayCustomVs)
				
				
				console.log('_filterArrayAddon1', _filterArrayAddon1)
				
				
				console.log('_filterArrayAddon2', _filterArrayAddon2)
				
				

				console.log('_filterArrayAddon3', _filterArrayAddon3)

				

				console.log('_filterArrayAddon4', _filterArrayAddon4) */


				setTimeout(() => {
					let addon1NotAvailable = [];
					let addon2NotAvailable = [];
					let addon3NotAvailable = [];
					let addon4NotAvailable = [];
					addon1NotAvailable = _filterArrayAddon1.filter(v => {
						return (v.hasOwnProperty('Addon1 Name') && (v['Addon1 Name'] == '--'))
					});
					addon2NotAvailable = _filterArrayAddon2.filter(v => {
						return (v.hasOwnProperty('Addon2 Name') && (v['Addon2 Name'] == '--'))
					});
					addon3NotAvailable = _filterArrayAddon3.filter(v => {
						return (v.hasOwnProperty('Addon3 Name') && (v['Addon3 Name'] == '--'))
					})
					addon4NotAvailable = _filterArrayAddon4.filter(v => {
						return (v.hasOwnProperty('Addon4 Name') && (v['Addon4 Name'] == '--'))

					})
					/* console.log('addon1', addon1NotAvailable);
					console.log('addon2', addon2NotAvailable);
					console.log('addon3', addon3NotAvailable);
					console.log('addon4', addon4NotAvailable); */
					if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length == 0) && (addon4NotAvailable && addon4NotAvailable.length == 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = _filterArrayAddon3;
						this.renderArrayAddon4 = _filterArrayAddon4;
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length == 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = _filterArrayAddon3;
						this.renderArrayAddon4 = [];
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length > 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = [];
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}
					else if ((addon1NotAvailable && addon1NotAvailable.length > 0) && (addon2NotAvailable && addon2NotAvailable.length > 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = [];
						this.renderArrayAddon2 = [];
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}
				
				}, 400)
			}
		}
		else {
			if (this.viewRecord && this.viewRecord.feedback) { //this.viewRecord.customkvs
				// this.cust = (this.viewRecord.feedback.replace(/['"]+/g, '')).split("/r");//.replaceAll(",", "/r")
				let _filterArrayTemp = [];
				let _arrayFilterPrimary = [];
				let _filterArrayAddon1 = [];
				let _filterArrayAddon2 = [];
				let _filterArrayAddon3 = [];
				let _filterArrayAddon4 = [];
				/* for (let i = 0; i < this.cust.length; i++) {									
					_filterArrayTemp.push({
						"labelName": this.insertSpaces(this.cust[i].split(":")[0].replace(/\\/g, '')),
						"valueOfLabel": this.cust[i].split(":")[1] ? this.cust[i].split(":")[1].replaceAll(null, '').replace('Invalid date', '').replace('.',':') : '--'
					})
				} */

				const _arrayFilterPrimary_labels = ['Addon1 KYC','Addon1 Appointment Date','Addon1 Appintment Time','Addon1 Pickup Location',
				'Addon1 Name','Addon1 Gender','Addon1 Mobile','Addon1 Email',
				'Addon1 Relationship','Addon1 Stay Primary','Addon2 KYC','Addon2 Appointment Date','Addon2 Appintment Time','Addon2 Pickup Location',
				'Addon2 Name', 'Addon2 Gender', 'Addon2 Mobile', 'Addon2 Stay Primary','Addon2 Email',
				'Addon2 Relationship','Addon3 KYC','Addon3 Appointment Date','Addon3 Appintment Time','Addon3 Pickup Location',
				'Addon3 Name', 'Addon3 Gender', 'Addon3 Mobile', 'Addon3 Stay Primary', 'Addon3 Email','Addon3 Relationship',
				'Addon4 KYC', 'Addon4 Appointment Date', 'Addon4 Appintment Time', 'Addon4 Pickup Location', 'Addon4 Name', 'Addon4 Gender',
				'Addon4 Mobile',  'Addon4 Email', 'Addon4 Relationship', 'Addon4 Stay Primary'];

				for(var i in this.viewRecord.feedback){
					const key = this.insertSpaces(i.replace(/\\/g, ''))
					const value = this.viewRecord.feedback[i] ? this.viewRecord.feedback[i].replaceAll(null, '').replace('Invalid date', '') : '--'
					const obj = {[key]: value};
					/* let obj = {};
					const key = this.insertSpaces(i.replace(/\\/g, ''))
					obj[key] = this.viewRecord.feedback[i] */
					if(_arrayFilterPrimary_labels.indexOf(i) < 0){
						this.arrayCustomVs.push(obj)
					}
					if(this._filterArrayAddon1_labels.indexOf(i) > -1){
						_filterArrayAddon1.push(obj)
					}
					if(this._filterArrayAddon2_labels.indexOf(i) > -1){
						_filterArrayAddon2.push(obj)
					}
					if(this._filterArrayAddon3_labels.indexOf(i) > -1){
						_filterArrayAddon3.push(obj)
					}
					if(this._filterArrayAddon4_labels.indexOf(i) > -1){
						_filterArrayAddon4.push(obj)
					}
				}

				/* _filterArrayAddon1 = _filterArrayTemp.filter(v => {
					return v.labelName == 'Addon1 KYC' || v.labelName == 'Addon1 Appointment Date' || v.labelName == 'Addon1 Appintment Time' || v.labelName == 'Addon1 Pickup Location' || v.labelName == 'Addon1 Name' || v.labelName == 'Addon1 Gender' || v.labelName == 'Addon1 Mobile' || v.labelName == 'Addon1 Email' ||
						v.labelName == 'Addon1 Relationship' || v.labelName == 'Addon1 Stay Primary'
				}); */

				/* _filterArrayAddon2 = _filterArrayTemp.filter(v => {
					return v.labelName == 'Addon2 KYC' || v.labelName == 'Addon2 Appointment Date' || v.labelName == 'Addon2 Appintment Time' || v.labelName == 'Addon2 Pickup Location' || v.labelName == 'Addon2 Name' || v.labelName == 'Addon2 Gender' || v.labelName == 'Addon2 Mobile' || v.labelName == 'Addon2 Email' ||
						v.labelName == 'Addon2 Relationship' || v.labelName == 'Addon2 Stay Primary'
				});


				_filterArrayAddon3 = _filterArrayTemp.filter(v => {
					return v.labelName == 'Addon3 KYC' || v.labelName == 'Addon3 Appointment Date' || v.labelName == 'Addon3 Appintment Time' || v.labelName == 'Addon3 Pickup Location' || v.labelName == 'Addon3 Name' || v.labelName == 'Addon3 Gender' || v.labelName == 'Addon3 Mobile' || v.labelName == 'Addon3 Email' ||
						v.labelName == 'Addon3 Relationship' || v.labelName == 'Addon3 Stay Primary'
				});

				_filterArrayAddon4 = _filterArrayTemp.filter(v => {
					return v.labelName == 'Addon4 KYC' || v.labelName == 'Addon4 Appointment Date' || v.labelName == 'Addon4 Appintment Time' || v.labelName == 'Addon4 Pickup Location' || v.labelName == 'Addon4 Name' || v.labelName == 'Addon4 Gender' || v.labelName == 'Addon4 Mobile' || v.labelName == 'Addon4 Email' ||
						v.labelName == 'Addon4 Relationship' || v.labelName == 'Addon4 Stay Primary'
				}); */

				setTimeout(() => {

					let addon1NotAvailable = [];
					let addon2NotAvailable = [];
					let addon3NotAvailable = [];
					let addon4NotAvailable = [];
					addon1NotAvailable = _filterArrayAddon1.filter(v => {
						return v.labelName == 'Addon1 Name' && v.valueOfLabel == '--';

					})
					addon2NotAvailable = _filterArrayAddon2.filter(v => {
						return v.labelName == 'Addon2 Name' && v.valueOfLabel == '--';

					})
					addon3NotAvailable = _filterArrayAddon3.filter(v => {
						return v.labelName == 'Addon3 Name' && v.valueOfLabel == '--';
					})
					addon4NotAvailable = _filterArrayAddon4.filter(v => {
						return v.labelName == 'Addon4 Name' && v.valueOfLabel == '--';
					})


					if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length == 0) && (addon4NotAvailable && addon4NotAvailable.length == 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = _filterArrayAddon3;
						this.renderArrayAddon4 = _filterArrayAddon4;
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length == 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = _filterArrayAddon3;
						this.renderArrayAddon4 = [];
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length == 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = _filterArrayAddon2;
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}

					else if ((addon1NotAvailable && addon1NotAvailable.length == 0) && (addon2NotAvailable && addon2NotAvailable.length > 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = _filterArrayAddon1;
						this.renderArrayAddon2 = [];
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}
					else if ((addon1NotAvailable && addon1NotAvailable.length > 0) && (addon2NotAvailable && addon2NotAvailable.length > 0) && (addon3NotAvailable && addon3NotAvailable.length > 0) && (addon4NotAvailable && addon4NotAvailable.length > 0)) {
						this.renderArrayAddon1 = [];
						this.renderArrayAddon2 = [];
						this.renderArrayAddon3 = [];
						this.renderArrayAddon4 = [];
					}
				}, 400)

			}
		}
	}
	ngAfterViewInit(){
		this.viewRecord = this.viewData;
	}
	getKey(obj){
		return Object.keys(obj)[0]
	}
	getValue(obj){
		try{
			return String(Object.values(obj)[0]).toUpperCase()
		}catch(e){
			return ''
		}
	}
	insertSpaces(string1) {
		string1 = this.insertCapital(string1.replace(/([a-z])([A-Z])/g, '$1 $2').replace("_", " ").replace('KYC Surrogates', 'KYC'));
		string1 = this.insertCapital(string1.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2').replace("_", " ").replace('No', 'Number'));

		return string1;
	}

	insertCapital(string2) {
		const words = string2.split(" ");
		for (let i = 0; i < words.length; i++) {
			words[i] = words[i][0] + words[i].substr(1);
		}
		let string3 = words.join(' ').replace(',', ' ').replace('residence','Residence').replace('residance','Residence').replace('dob', 'Date of Birth').replace('Dob', 'Date of Birth').replace('Kyc', 'Appointment').replace('kyc', 'Appointment').replace('undefined','')
		return string3;
	}


	closeDialog(type: any) {
		if (type == "true") {
			const callSession = this.myservice.getLastCallSession();
			// if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
			// 	alert("Please Press Start for Calling.");
			// 	return;
			// }
			if (callSession && callSession.internet_speed && callSession.internet_speed < 10) {
				alert("Internet speed slow try later.");
				return;
			}
			if (callSession && callSession.internet_speed == 0) {
				alert("Please check your Internet connection.");
				return;
			}
		}
		this.dialogRef.close(type);
	}
	oddOrEven(num) {
		if (num % 2 == 0)
			return false;
		return true;
	}
}
