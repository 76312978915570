import { Component, OnInit,Inject } from '@angular/core';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-agent-info-dialog',
  templateUrl: './agent-info-dialog.component.html',
  styleUrls: ['./agent-info-dialog.component.scss'],
  providers: [ DatePipe ]
})
export class AgentInfoDialogComponent implements OnInit {
  showHidePopupDelete:boolean=false;
  createdForm:UntypedFormGroup;
  isreadonly:any=true;
  Datatable:any;
  constructor(public dialogRef: MatDialogRef<AgentInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewAgentData,public formbuilder:UntypedFormBuilder,private datePipe: DatePipe) {
    this.createdForm = this.formbuilder.group({
      batchCode:[''],
      startBatch:[''],
      endBatch:[''],
      batchCapacity:[''],
      agents:[''],
      trainer:[''],
      location:['']
    })
   }

  ngOnInit() {
    if(this.viewAgentData){
		this.Datatable=this.viewAgentData;
    this.createdForm =  this.formbuilder.group({
      name: [this.viewAgentData.name],
      userid: [this.viewAgentData.id],
      emailid: [this.viewAgentData.email],
      score: [this.viewAgentData.score],
      status: [this.viewAgentData.status],
      datejoined: this.datePipe.transform(this.viewAgentData.created_at,'M/d/yy, h:mm a'),
      //aadhaarNumber: [this.viewAgentData.adhaar_number],
      currentBatchid: [this.viewAgentData.batch_code],
      //attendence: [this.viewAgentData.day_count || 0],
      location : [this.viewAgentData.training_location],
      //batchtype: [this.viewAgentData.batch_type],
      lastupdateon: this.datePipe.transform(this.viewAgentData.last_updated,'M/d/yy, h:mm a')
      //agency: [this.viewAgentData.agency],
     // category: [this.viewAgentData.category],
   })
  }


  }
  closeDialog(){
    this.dialogRef.close();
  }
  openWarningMessage(){
    this.showHidePopupDelete=true;
  }
  closedeletePopup(){
    this.showHidePopupDelete=false;
  }
}


