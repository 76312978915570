<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>
<div class="container-fluid main-section-layout" style="padding: 60px 20px 32px 20px;">
    <div class="row">      
        <div class="col-md-12">
            <div class="row">		
                <div class="col-md-12">
                  <h4 class="titlehead" style="margin-top: 0%;padding-left: 10px;position: relative;top: 10px;z-index: 9;">Settings</h4>
                  </div>
             </div>
             <div class="row settingTabList mt-4" >
                <div class="col-md-12" style="padding: 0px 3.5px;">
                    <mat-button-toggle-group class="dashSettingcs" #group="matButtonToggleGroup" [value]="selectedButtonToggleVal"
                    (change)="onToggleButtonValChange(group.value)">
                    <mat-button-toggle value="AgentSlot" *ngIf="isshowHideAgentSlot">Agent Slot</mat-button-toggle>
                    <mat-button-toggle value="ContactRM" *ngIf="isshowHideContactRM">Contact v/s RM</mat-button-toggle>
                </mat-button-toggle-group>
                </div>
            </div>







            <mat-card class="mat-card-table-responsive" *ngIf="showHideAgentSlot">
                <p class="textAlertMessage" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
                <div class="row pdt filter-div  align-items-center">
                    <div class="col">
                        <!-- <span class="date-label">10-Jun-2020</span> -->
                    <!--<div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn "
                                [ngClass]="(selectedTabSts == 'slot_status') ? 'btn-secondary' : 'btn-outline-secondary' "
                                (click)="changeTogleStatus('slot_status')">Slot Status</button>
                        </div>-->
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-2">
                                <span class="recName">
                                    <!-- Settings -->
                                </span>
                            </div>

                            <div class="col-md-3">
                               
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="frompicker" placeholder="From" readonly
                                            (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="slotStartDate">
                                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                                        <mat-datepicker #frompicker></mat-datepicker>
                                    </mat-form-field>
                            </div>
                            <div class="col-md-5">
                                    <mat-form-field  >
                                    <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="slotEndDate">
                                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                                    <mat-datepicker #topicker></mat-datepicker>
                                </mat-form-field>
                                <button mat-raised-button class="btnAdd ml-3" (click)="refreshDataCallSlot()">Go</button>
                                <button mat-raised-button class="btnAdd ml-3" (click)="addSlot()"><i class="fa fa-cog" aria-hidden="true"></i></button>

                        </div>

                            <div class="col-md-2" >
                                    <!----->
                                <mat-form-field class="wdh">
                                    <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
                                    <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row pdt filter-div  align-items-center">
                    <div class="col-md-12 col-sm-12 col-xs-12 ">
                        <!-- <span style="font-size: 14px;color: #445c6d; font-weight: bold;">Export</span> -->
                        <!-- <mat-form-field [ngClass]="'inputClr'">
                            <mat-select placeholder="Export" (selectionChange)="downloadReport($event.value)" >
                                <mat-option value="call">Call Report</mat-option>
                                <mat-option value="audit">Audit Report</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: none !important;">
                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.date }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="start_time">
                            <mat-header-cell *matHeaderCellDef> Slot Start Time </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.start_time }}</mat-cell>
                        </ng-container>
						<ng-container matColumnDef="end_time">
                            <mat-header-cell *matHeaderCellDef> Slot End Time </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.end_time }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="capacity">
                            <mat-header-cell *matHeaderCellDef> Capacity </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.capacity }}</mat-cell>
                            
                        </ng-container>
                        <ng-container matColumnDef="booked">
                            <mat-header-cell *matHeaderCellDef> Booked </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.booked }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="waiting">
                            <mat-header-cell *matHeaderCellDef> Waiting </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.waiting }}</mat-cell>
                        </ng-container>
                        <!--<ng-container matColumnDef="occupancy">
                            <mat-header-cell *matHeaderCellDef> Occupancy </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.occupancy }}</mat-cell>
                        </ng-container>-->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="id" >
                            <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Id </mat-header-cell>
                            <mat-cell *matCellDef="let element" [style.display]="'none'">{{ element.id }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="more">
                            <mat-header-cell *matHeaderCellDef> More </mat-header-cell>
                            <mat-cell *matCellDef="let element" >
                                <button mat-icon-button aria-label="Open Detail Timings" class="" matTooltip="Details"
                                matTooltipPosition="below" (click)="Moresetting(element)">
                                <mat-icon _ngcontent-xrf-c471="" role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">launch</mat-icon>                             
                            </button>
                            </mat-cell>
                        
                        </ng-container>
                       <!--<ng-container matColumnDef="options">
                            <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                            <mat-cell *matCellDef="let element" (click)="editSlot(element)"><i class="fa fa-edit CR" style="font-size: 18px;color: #3fb90d;"></i></mat-cell>
                        
                        </ng-container>-->

                        <mat-header-row *matHeaderRowDef=displayedColumnsCallSlot></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsCallSlot"></mat-row>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

            </mat-card>
            <mat-card class="mat-card-table-responsive" *ngIf="showHideContactRM">
                <div class="row">
                <!-- <div class="col-md">	
                    <button mat-raised-button [matMenuTriggerFor]="sortBy_menu" aria-label="Filter By">
                        Filter
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <mat-menu #sortBy_menu="matMenu">
                        <button mat-menu-item (click)="resetTableDataByActivityFilter()">None</button>
                        <button mat-menu-item (click)="filterTableDataByActivity('Active')">Active</button>
                        <button mat-menu-item (click)="filterTableDataByActivity('Break')">Break</button>
                        <button mat-menu-item (click)="filterTableDataByActivity('Login')">Login</button>
                        <button mat-menu-item (click)="filterTableDataByActivity('Logout')">Logout</button>
                        <button mat-menu-item (click)="filterTableDataByActivity('null')">No Activity</button>
                    </mat-menu>
                </div> -->
                <div class="col-md-2">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-outline-secondary btnScs" id="dropdownForm1"
                          ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                          <div class="px-4 py-3" style="max-height: 300px;overflow-y: auto;">
                            <mat-checkbox (change)="updateCheck()" class="example-margin" [(ngModel)]="selectAll">
                              Select All
                            </mat-checkbox>
                            <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                              <mat-checkbox (change)="selectChildren()" [(ngModel)]="ing.checked">
                                {{ing.name}}
                              </mat-checkbox>
                            </section>
                          </div>
                        </div>
                      </div>
                </div>
                <div  class="col-md-5">
					<button class="app-btn-ui" style="width:auto;padding: 4px 15px;border: 1.6px solid #fd6c21;" (click)="download_file()"><mat-icon style="vertical-align: text-top;" class="mr-2">file_download </mat-icon>Download </button> 

                    <button class="app-btn-ui ml-4" style="width:auto;padding: 4px 15px;border: 1.6px solid #fd6c21;" (click)="uploadNew()"><mat-icon style="vertical-align: text-top;" class="mr-2">file_upload </mat-icon>Upload </button> 
                </div>
                <div class="col-md-5"></div>
            </div>
            </mat-card>
        </div>
    </div>
</div>
</mat-drawer-container>