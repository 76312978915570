<div class="row ml-0 mr-0 mt-3">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <p class="L-head" style="font-size: 22px;">Slots</p>
  </div>
</div>
<div>
  <mat-accordion>
    <mat-expansion-panel class="mt-3" *ngFor="let v of _elementRecord;let i=index;">
      <mat-expansion-panel-header>
        <mat-panel-title class="L-head">
          {{v.date}} <b>|</b> {{v.day}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table mat-table [dataSource]="v.rows">

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Time Slots </th>
          <td mat-cell *matCellDef="let element">{{element.time}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="book">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.status=='Available'"  style="background: #e10f59;color: white;"
              (click)="bookRegister(element)">Book</button>
            <button *ngIf="element.status=='Booked'"  style="background: #e10f59;color: white;"
              (click)="cancelBooked(element)">Cancel</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>