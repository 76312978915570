import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from '../../myservice.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  createdForm:UntypedFormGroup;
  msgColor:any="";
  messageDisplay:any="";
  showHideMessage:boolean=false;
  api_end_point:any;
  constructor(private formbuilder:UntypedFormBuilder,private API:ApiService,private myservice: MyserviceService) {
	  
    this.createdForm=this.formbuilder.group({
      name:['',Validators.required],
      email:['', [Validators.required, Validators.email]],
      mobileNo:['',[Validators.required,Validators.maxLength(10)]],     
      subject:['',Validators.required],
      query:['',Validators.required] 
    })
   }

  ngOnInit() {
	this.api_end_point = this.myservice.get_API_End_Point();
    let acc = document.getElementsByClassName("accordion");
let i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}
  }
  submitFaqBtn(createdForm:any){
    //debugger;
    try{
    this.showHideMessage=false;
    let _name=createdForm.controls["name"].value;
    let _email=createdForm.controls["email"].value;
    let _mobileNo=createdForm.controls["mobileNo"].value;  
    let _subject=createdForm.controls["subject"].value;
    let _query=createdForm.controls["query"].value;
    
if(_name==""){
  this.messageDisplay="Please enter name*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;

}
if(_email==""){
  this.messageDisplay="Please enter email*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;

}
var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
if (regEmail.test(_email) == false)
{
  this.messageDisplay="Please enter correct email*";
  this.msgColor="red";
  this.showHideMessage=true;
    return false;
}
if(_mobileNo==""){
  this.messageDisplay="Please enter mobile number*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;

}
if(_mobileNo.length !=10){
  this.messageDisplay="Mobile Number should be 10 digit*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;
}
if(_subject==""){
  this.messageDisplay="Please enter subject*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;

}
if(_query==""){
  this.messageDisplay="Please enter query*";
  this.msgColor="red";
  this.showHideMessage=true;
  return;

}
let _obj={
  "name":_name,
  "email":_email,
  "phone":_mobileNo, 
  "subject":_subject,
  "enquirytext":_query
    }    
  
      this.API.saveFaq(_obj,this.api_end_point).subscribe((data:any)=>{
        //debugger;
        if(data){        
          this.messageDisplay="Congratulations, Saved Successfully.!!";
          this.msgColor="green";
          this.showHideMessage=true;  
          this.createdForm.reset();
        }
        else{
          this.messageDisplay=data.errors +"*";
          this.msgColor="red";
          this.showHideMessage=true;
        }      
      });
  } catch (e) {
			console.log(e);
  }
   
    
 


  }
  omit_special_char(event)
  {   
     let k;  
     k = event.charCode;  //         k = event.keyCode;  (Both can be used)
     return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }
onlyNumberValidation(event){
  return event.charCode >= 48 && event.charCode <= 57;
}
}
