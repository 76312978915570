import { Component, OnInit, ViewChild, Input } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';


import { Utils } from '../../utils';
import { MatTableDataSource } from '@angular/material/table';import { ServicesService } from '../../dashboard/services.service';
import { MyserviceService } from '../../myservice.service';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

  currentLoader = false;
  myUser: any;
  constructor(private dashService: ServicesService,
		private userService: MyserviceService) { 
      this.myUser = this.userService.get_loggedin_user();
    }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData() {
		this.dashService.getAssessmentData(false,this.myUser.id,this.myUser.groupid).then((resolve) => {
			// console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareTableData()
		}, (err) => {
				alert(err);
				this.currentLoader = false;
		})
  }
  prepareTableData() {
    this.currentLoader = true;
    let data = null;
    this.dashService.assessment_dash_data.subscribe((d) => data = d);
   // console.log(data)

  }

}
