<div class="mainDivLoginPage">
  <!-- <div class="row mt-1">
		<div class="col-md-12 col-sm-12 col-xs-12">
			<p class="con-logo-p">
				<img src="../assets/contiinex/image/contiinexLogoImg.png" class="con-logo-img" (click)="conweb()" />
			</p>
		</div>
	</div> -->
  <div class="row mt-1">
    <div class="col-md-8 col-sm-8 col-xs-12">
      <!-- <p class="con-logo-p">
                <img src="../assets/contiinex/image/contiinexLogoImg.png" (click)="conweb()" class="con-logo-img"/>
            </p>       -->
      <div class="pimg">
        <div class="loginBgcls"></div>
      </div>
      <!-- <div class="pimg"><img src="../assets/contiinex/loginBgImage.png" class="loginBgcls" /></div> -->
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 bgimg2">
      <!-- <img src="../assets/contiinex/spinBGLogin.png" class="spinCardBgcs" /> -->
      <mat-card class="cxCard" *ngIf="showHideLoginPageUI">
        <div class="ro">
          <div class="col-md-12">
            <div class="logo_img">
              <img src="../assets/contiinex/image/contiinexLogoImg.png" class="con-logo-img" (click)="conweb()" />
            </div>
         
        <p class="pText1">{{_heading}} </p>

        <div>
          <div *ngIf="idpassword">
            <form [formGroup]="createdForm" (keyup.enter)="LoginBtn(createdForm)" style="padding: 0 20px;">
              <p class="blankMsg" *ngIf="showHideErrorMsg">{{passMsgText}}</p>
              <!-- <table class="wd-100">
                <tr style="display: none;">
                  <td>
                    <div class="input-label-group" *ngIf="showHideProduct">
                      <label>Choose Your Product*</label>

                      <p>
                        <mat-select placeholder="Select A Product" [(value)]="selectedGig"
                          (selectionChange)="onChangeProduct($event.value)">
                          <mat-option value="Gignex">Gignex</mat-option>
                          <mat-option value="Comnex">Comnex</mat-option>
                          <mat-option value="Ainex">Ainex</mat-option>
                          <mat-option value="WABA">WhatsApp Business API </mat-option>
                        </mat-select>
                      </p>
                    </div>


                    <p *ngIf="showHideProductGignexLogo" class="text-center" style="position: relative;right: 25px;">
                      <span>
                        <mat-icon class="backcls" (click)="backToSelectProduct()">arrow_back
                        </mat-icon>
                      </span>
                      <span> <img style="width: 80px;" src=".../../assets/contiinex/gignexCo.png" /></span>
                    </p>

                    <p *ngIf="showHideProductComnexLogo" class="text-center" style="position: relative;right: 25px;">
                      <span>
                        <mat-icon class="backcls" (click)="backToSelectProduct()">arrow_back
                        </mat-icon>
                      </span>
                      <span> <img style="width: 80px;" src=".../../assets/contiinex/comnexCo.png" /></span>
                    </p>

                    <p *ngIf="showHideProductAinexLogo" class="text-center" style="position: relative;right: 25px;">
                      <span>
                        <mat-icon class="backcls" (click)="backToSelectProduct()">arrow_back
                        </mat-icon>
                      </span>
                      <span> <img style="width: 80px;" src=".../../assets/contiinex/ainexCo.png" /></span>
                    </p>


                  </td>
                </tr> -->
                <p class="pt-3">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input matInput autocomplete="off" maxlength="200" type="email" [(ngModel)]="loginname"
                      placeholder="Login Email ID" required formControlName="email" autofocus>
                  </mat-form-field>
                </p>
                <p class="pt-3">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input trim="blur" type="password" placeholder="Enter your password" formControlName="password" matInput required maxlength="50" #password />

                      <mat-icon matSuffix (click)="password.type = (password.type === 'password' ? 'text' : 'password')">{{password.type === 'password' ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </mat-form-field>
                </p>
                <!-- <tr>
                  <td>
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>User Name</mat-label>
                      <input matInput autocomplete="off" maxlength="200" type="email" [(ngModel)]="loginname"
                        placeholder="Login Email ID" required formControlName="email" autofocus>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Password</mat-label>
                      <input trim="blur" type="password" placeholder="Enter your password" formControlName="password" matInput required maxlength="50" #password />

                        <mat-icon matSuffix (click)="password.type = (password.type === 'password' ? 'text' : 'password')">{{password.type === 'password' ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </mat-form-field>
                  </td>
                </tr> -->
                <!-- <tr *ngIf="showHideMultiUser">
                  <td>
                    <div class="input-label-group">
                      <label>Choose Your Store or Merchant</label>
                      <p>
                        <mat-select placeholder="Select" [(value)]="selectedMerchant"
                          (selectionChange)="onChangeMerchant($event.value)">
                          <mat-option *ngFor="let merchant of merchants_list" [value]="merchant">{{merchant}}
                          </mat-option>
                        </mat-select>
                      </p>
                    </div>
                  </td>
                </tr>
              </table> -->

              <div class="row">
                <div class="d-flex justify-content-end">
                  <span style="color: var(--primary); cursor: pointer;" (click)="forgotPasswordBtn()">Forgot Password?</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12 text-center">
                  <button mat-raised-button class="w-100" type="button" (click)="LoginBtn(createdForm)">LOGIN</button>
                </div>
              </div>
            
              <!-- <div class="row mt-2">
								<div class="col-md-12 col-sm-12 text-center">
									<span class="sp1"> New User ?</span> <span class="sp2"
										(click)="registerNowBtn()">Register Now </span>
								</div>
							</div> -->
            </form>
          </div>
          <div *ngIf="otpvalidation">
            <h4 class="text-center "> OTP</h4>
            <form [formGroup]="otpForm">
              <!-- <p class="blankMsg" *ngIf="showHideErrorMsg">{{passMsgText}}</p> -->
              <div class="row">
                <div class="col-md-12 text-left">

                  <div class="col-md-12">
                    <span class="login-label1" style="color:gray">Enter OTP sent to your
                      Email</span>
                  </div>
                  <div class="col-md-12" style="margin-top: 20px;">
                    <span style="margin-right: 50px;">
                      <input class="login-input-text vkOtp" type="text" id="otp" placeholder="One Time Password"
                        formControlName="otp" required /></span>
                    <div *ngIf="f.otp.touched && f.otp.invalid" class="alert alert-danger">
                      <div *ngIf="f.otp.errors.required">OTP is required.</div>
                      <div *ngIf="f.otp.errors.pattern">Numberic Value Only</div>
                      <div *ngIf="f.otp.errors.minlength">
                        Enter Seven Digit OTP.
                      </div>
                      <div *ngIf="f.otp.errors.maxlength">
                        Enter Seven Digit OTP.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <button class="go-Submit ml-0"
                      style="width: 120px;background: #fff;color: #dd133c;border: 1px solid #dd133c;"
                      (click)="resend_otp()" [disabled]="resend_otp_disabled">Resend OTP</button>
                    <button mat-raised-button class="w-100" (click)="otpsbumit(otpForm)"
                      [disabled]="f.otp.invalid">Submit
                      <i class="fa fa-arrow-right"></i>
                    </button>
                  </div>
                  <div class="col-md-12">
                    <span *ngIf="resend_otp_disabled" class="text-left timer" style="color: #dd133c;padding: 2px;">Time
                      left - {{clockTimer}}</span>
                    <span *ngIf="!resend_otp_disabled" class="hint">Didn't receive a code? Click <span
                        style="font-weight: bold;">Resend OTP</span>.</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        
          </div>
        </div>
      </mat-card>
      <mat-card class="cxCard" *ngIf="showHideForgotPageUI">
        <div class="ro">
          <div class="col-md-12">
            <div class="text-center">
              <img src="../assets/contiinex/image/contiinexLogoImg.png" class="con-logo-img" />
              <div class="f-24-px fw-normal pb-40-px" style="color: #000; margin-top: 15px;">Forgot Password</div>

              <!-- <p class="vk-fwText mb-1">Forgot Password</p> -->
              <!-- <p class="vk-fwDesc">Enter your email and we'll send you a link<br>
                To reset your password.</p> -->
              <p class="pt-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input type="text" matInput (keyup)="validateForgotEmail()" placeholder="Enter your email"
                    [(ngModel)]="forgotEmail" />
                </mat-form-field>
              </p>
              <p *ngIf="showHideForgotMsg"><span class="vk-ercs">{{forgotMsgError}} </span></p>
              <button class="w-100" mat-raised-button (click)="sendForgotEmail()">RECOVER PASSWORD</button>
              <p class="mt-2" style="color: #000;">OR</p>
              <button mat-raised-button (click)="backToLoginBtn()" class="w-100 mt-10">
                 BACK TO LOGIN
                </button>
              <!-- <p class="mt-4"><img src="../assets/image/forgotFooterImg.png" style="width: 100%;" /></p> -->
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="cxCard" *ngIf="showHideResetPageUI">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="mt-4 text-center">
              <p class="pt-3"><img src="../assets/image/resetLinkMailImg.png"
                  style="width: 120px;position: relative;top: 60px;" /></p>
              <br>
              <p class="vk-fwDesc" style="margin:60px 40px;">Reset link has been sent to your email<br>
                Please check your mail.</p>
              <br>
              <br>
              <p class="vk-fwBLog"><span (click)="backToLoginBtn()">
                  <mat-icon style="vertical-align: bottom;">chevron_left</mat-icon>Back to login
                </span></p>

            </div>
          </div>
        </div>
      </mat-card>
      <!-- <img src="../assets/contiinex/spinCardBgcs2.png" class="spinCardBgcs"/> -->
    </div>
  </div>
</div>
<!-- <div class="footerDivx">
	The only Customer Engagement Technology You'll Ever Need
</div> -->
<div id="cover-spin" *ngIf="showLoader"></div>
