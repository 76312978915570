<div style="overflow: hidden auto; height: calc(100vh - 166px); padding: 16px;">
	<!-- Header Information -->
	<div class="row">
		<div class="col-md-12">
			<table class="tbl1DD text-center">
				<tr>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Name</b></td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Phone</b></td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Email</b></td>
					<td *ngIf="_pathCheckUrl =='/LC-Coordinator'"><b>Agent ID</b></td>
				</tr>
				<tr>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'">{{viewRecord.name || '--'}}</td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'">{{viewRecord.number | mask_number:'--'}}</td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'">{{viewRecord.email || 'NA'}}</td>
					<td *ngIf="_pathCheckUrl =='/LC-Coordinator'">{{viewRecord.email || 'NA'}}</td>
				</tr>
			</table>
		</div>
	</div>

	<!-- Custom Fields Section -->
	<div *ngIf="arrayCustomVs.length > 0" class="mt-4">
		<div class="row">
			<div class="col-md-12">
				<table class="tbl1DD ctbl1">
					<tr *ngFor="let b of arrayCustomVs">
						<td><b>{{getKey(b) | titlecase}}</b></td>
						<td>{{getValue(b)}}</td>
					</tr>
				</table>
			</div>

			<!-- Add-on Sections -->
			<ng-container *ngFor="let addon of [
				{data: renderArrayAddon1, title: 'Add-on 1'},
				{data: renderArrayAddon2, title: 'Add-on 2'},
				{data: renderArrayAddon3, title: 'Add-on 3'},
				{data: renderArrayAddon4, title: 'Add-on 4'}
			]">
				<div class="col-md-12" *ngIf="addon.data && addon.data.length > 0">
					<h4 class="section-title">{{addon.title}}</h4>
					<table class="tbl1DD ctbl1">
						<tr *ngFor="let item of addon.data">
							<td><b>{{getKey(item) | titlecase}}</b></td>
							<td>{{getValue(item)}}</td>
						</tr>
					</table>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<!-- Action Buttons -->
<div class="vOkFx d-flex justify-content-end">
	<!-- Add a hidden element to receive initial focus -->
	<div tabindex="-1" cdkFocusInitial style="position: absolute; opacity: 0;"></div>
	
	<button mat-raised-button class="action_btn" (click)="closeDialog('false')" style="margin-right: 18px;">
		<mat-icon>close</mat-icon>
		CANCEL
	</button>
	<button mat-raised-button class="action_btn" 
			(click)="closeDialog('true')"
			*ngIf="_pathCheckUrl =='/agentPlugin/campaign-list' || _pathCheckUrl =='agentPlugin/contact-list'"
			color="primary">
			<mat-icon>call</mat-icon>
		CALL
	</button>
</div>