import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MyserviceService } from '../../myservice.service';
import { CreateDialogComponent } from '../../dashboard/assessment/create-dialog/create-dialog.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatInput } from '@angular/material/input';
			

@Component({
  selector: 'app-assessment-upload',
  templateUrl: './assessment-upload.component.html',
  styleUrls: ['./assessment-upload.component.scss']
})
export class AssessmentUploadComponent implements OnInit {

  displayedColumns = ['name',
  // 'type',
  'default',
  'passing_score',
  'timing',
  'total_questions',
  'total_score',
  'total_sections',
  'created_at',
  'download',
  'edit'];
  dataSource:any = new MatTableDataSource();
  myUser: any;
  table_data: any = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  isEditBatch:boolean=false;
  @ViewChild('searchInput', {read: MatInput}) searchInput: MatInput;
  api_end_point:any;
  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private service: MyserviceService
    ) { 
      this.myUser = this.service.get_loggedin_user();

    }
  ngOnInit() {
	this.api_end_point = this.service.get_API_End_Point();
    this.getAssessmentData();
 
   }
 
  getAssessmentData(){
    let _today:any=new Date();
    let _startDate = moment(_today).format('yyyy-MM-DD');
   let _endDate=moment( new Date(_today.setDate(_today.getDate() + 30))).format('yyyy-MM-DD');

   this.API.getAssessmentForCandidate(true, this.myUser.id, this.myUser.groupid, "training",this.api_end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {

						} else {
						//	console.log(res.success);
						let finaldata = res.success.filter(obj => {
						//	console.log("---",obj.status);
							return (obj.status == 1)})
						//	console.log("--final--",finaldata);
              //this.table_data = res.success;
              //this.dataSource = new MatTableDataSource(res.success);
			  	this.table_data = finaldata;
			  	this.dataSource = new MatTableDataSource(finaldata);
		          this.dataSource.paginator = this.paginator;
				 // debugger;
						}
					}, (err) => {

					}
				)
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  createNewBatch(){
    // this.API.clearCreateforBtn();
    // this.router.navigateByUrl('/training/create_batch');
    var dialogRef = this.dialog.open(CreateDialogComponent, {
			width: '40%',
		//	hasBackdrop: false,
      disableClose:true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				this.getAssessmentData();
			}
		});
    
  }
  downloadQuestionaire(element, index){
    // console.log(this.table_data[index]);
    // console.log(JSON.parse(this.table_data[index].questionaire))
    var obj = JSON.parse(this.table_data[index].questionaire);
    let workbook = new Workbook(); 
    for (var sIndex=0; sIndex < obj.length; sIndex++) {
      let worksheet = workbook.addWorksheet(obj[sIndex].title);
      var headers = ['Questions', 'Answer Formats', 'Answers', 'Score'];
      worksheet.addRow(headers);
      for (var rw=0; rw < obj[sIndex].child.length; rw++) {
        var rowValues = [];
       // console.log(obj[sIndex].child[rw])
        Object.keys(obj[sIndex].child[rw]).map(function(key, index) {
        //  console.log(key,index,obj[sIndex].child[rw][key])
          if(index == 2 && key != 'Answers'){
            rowValues.push("")
            rowValues.push(obj[sIndex].child[rw][key].toString())
          }else{
            rowValues.push(obj[sIndex].child[rw][key].toString())
          }
          
        });
        worksheet.addRow(rowValues);
      }
    }
    		workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, `assessment-${element.name}-${element.type}.xlsx`);
				})
    // let workbook = new Workbook();
		// 		let worksheet = workbook.addWorksheet('Candidate Data');
		// 		worksheet.addRow(headers);
		// 		data.forEach(d => {
		// 			let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
		// 			//	console.log('printing row', row);
		// 		})
		// 		//Generate & Save Excel File
		// 		workbook.xlsx.writeBuffer().then((data) => {
		// 			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		// 			fs.saveAs(blob, 'Recruitment.xlsx');
		// 		})
  }
  elementEDIT:any=[];
	openEditBatch(element, index) {
    var dialogRef = this.dialog.open(CreateDialogComponent, {
			width: '40%',
		//	hasBackdrop: false,
      disableClose:true,
			data: {data: element, index}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				this.getAssessmentData();
        this.searchInput.value="";
			}
		});
  }
  batchRowRecord(element){
    this.API.sessionTraingbatchID(element);
    this.router.navigateByUrl('/training/batch_training');
  }
}

