import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MyserviceService } from "../myservice.service";
import { Title, Meta } from '@angular/platform-browser';
import { Routes, RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'src/app/api.service';
// import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ContactListCampaignContactDialogPageComponent } from '../desktop-calling/contact-list-campaign-contact-dialog-page/contact-list-campaign-contact-dialog-page.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LcUploadDialogComponent } from '../lc-upload-dialog/lc-upload-dialog.component';
import { Utils } from '../utils';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-lc-cordinator-page',
  templateUrl: './lc-cordinator-page.component.html',
  styleUrls: ['./lc-cordinator-page.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class LcCordinatorPageComponent implements OnInit {
  currentTitle = 'LC-Coordinator';
  currentLoader: boolean = false;
  api_end_point: any;
  currentUrl: any;
  leadsdatefrom: any;
  leadsdateto: any;


  displayedColumnsA: string[] = ['slno', 'customerName', 'customerNumber', 'generationTime', 'leadFixTime', 'qdeForms','approve', 'decline'];
  dataSourceA = new MatTableDataSource();
  @ViewChild('paginatorA', { static: true }) paginatorA: MatPaginator;
  isShowDatePicker: boolean = false;
  showHidePopupApprove: boolean = false;
  user: any = this._service.get_loggedin_user();
  ChooseSelectedDateValue: any;
  fromDate: any;
  toDate: any;
  selectedDate: any = "Today";
  approved: any = 0;
  rejectcount: any = 0;
  is_minimized: boolean = false;
  totalQAD: any = 0;
  showHideRemarks: boolean = false;
  showHideResheduleDateTime: boolean = false;
  minDate2 = new Date();
  reTimeModel: any = "";
  api_data = []
  back_date_thirty = moment().subtract(30, 'days')
  @ViewChild('remarksDialog') remarksDialog: TemplateRef<any>;
  constructor(private metaTagService: Meta, private API: ApiService, private _snackBar: MatSnackBar, private titleService: Title, private _service: MyserviceService, public sanitizer: DomSanitizer, private router: Router, private dialog: MatDialog, private utils: Utils) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    this.getContacted()
    if (this.selectedDate == "Today") {
      let today1 = (moment().subtract(1, 'days').endOf('day').toString());
      let today2 = (moment().subtract(0, 'days').endOf('day').toString());
      //console.log("--daybeforeyesterday---" + today1 + "--" + today2);
      this.fromDate = moment(today1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(today2).format('YYYY-MM-DD HH:mm:ss');
    }
  }

  check_not_completed(data) {
    const p = data.filter((el) => { return el.stage != 'NOT-COMPLETED' })
    this.dataSourceA = new MatTableDataSource(p.reverse());
    this.dataSourceA.paginator = this.paginatorA;
    // console.log('data source', this.dataSourceA.data)
    this.totalQAD = this.approved + this.rejectcount + p.length;
  }

  getContacted() {
    // debugger;
    if (this.leadsdatefrom != "" && this.leadsdateto != "") {
      // console.log(this.fromDate, "---", this.toDate);
      if (this.fromDate != null && this.toDate != null || this.fromDate != undefined && this.toDate != undefined) {
        this.leadsdatefrom = this.fromDate;
        this.leadsdateto = this.toDate;
      }
      else {
        let _today: any = new Date();
        let today1 = (moment().subtract(0, 'days').startOf('day').toString());
        let today2 = (moment().subtract(0, 'days').endOf('day').toString());
        //console.log("--daybeforeyesterday---" + today1 + "--" + today2);
        this.leadsdatefrom = this.utils.localToUTC(moment(today1).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
        this.leadsdateto = this.utils.localToUTC(moment(today2).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
      }

      let obj = {
        "start_time": this.leadsdatefrom,
        "end_time": this.leadsdateto,
        "id_group": this.user.groupid,
        "user_id": this.user.id
      }
      this.currentLoader = true;
      this.API.LI_list_Contacted(obj, this.api_end_point).subscribe((data: any) => {
        this.currentLoader = false;
        let _response = data.success;
        if (_response && _response != 'No data' && _response.length) {
          let ELEMENT_DATA = _response;
          this.api_data = _response;
          let _contactedData = ELEMENT_DATA.filter(v => { return (v.stage != "FULL-FILLED" && v.stage != "rework") });
          //console.log(JSON.stringify(_contactedData));
          let finalData = _contactedData.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.id === thing.id
            ))
          )
          // console.log(finalData.length);
          let approved = 0;
          let rejectcount = 0;
          for (let i in this.api_data) {
            // console.log('final data', this.api_data[i])
            if (this.api_data[i].feedback != null) {
              // console.log(JSON.stringify(finalData[i].feedback));
              // let feedback = finalData[i].feedback;
              if (this.api_data[i].stage == "FULL-FILLED") {
                approved += 1;
              } else if (this.api_data[i].stage == "NOT-COMPLETED") {
                rejectcount += 1;
              }
            }
          }
          this.approved = approved;
          this.rejectcount = rejectcount;

          for (let i = 0; i < finalData.length; i++) {
            finalData[i]['appontmentDateTime'] = this.getAppointmentDateTime(finalData[i]);
          }

          let _dataRecent = finalData.filter(v => { return v.appontmentDateTime != '--  --' });
          _dataRecent = _dataRecent.filter(v => { return !v.appontmentDateTime.includes('--') });
          // console.log('data recent', _dataRecent.length, JSON.stringify(_dataRecent))
          // let _dataRecent = finalData;
          // console.log('api data', this.api_data)
          this.check_not_completed(_dataRecent)
        }
        if (data.success == "No data") {
          this._snackBar.open("No Data", 'close', {
            duration: 2000,
          });
        }
        if (data.errors) {
          this._snackBar.open("No User found", 'close', {
            duration: 2000,
          });
        }
      });
    }
  }

  getApplicationNumber(element) {
    //debugger;
    let _feedback = element.feedback;
    if (_feedback['ApplicationNumber']) {
      return _feedback['ApplicationNumber']
    }
    return

  }


  insertSpaces(string1) {
    string1 = this.insertCapital(string1.replace(/([a-z])([A-Z])/g, '$1 $2').replace("_", " "));
    string1 = this.insertCapital(string1.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2').replace("_", " ").replace('No', 'Number'));

    return string1;
  }

  insertCapital(string2) {
    const words = string2.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0] + words[i].substr(1);
    }
    let string3 = words.join(' ').replace(',', ' ').replace('Dob', 'Date of Birth').replace('kyc', 'Appointment')
    return string3;
  }

  getLeadFixDateTime(element) {
    let _feedback = element.feedback;
    if (_feedback['submit_date']) {
      return this.utils.utcToLocal(_feedback['submit_date'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY HH:mm')
    }
    return ''
  }

  getAppointmentDateTime(element) {
    let _feedback = element.feedback;

    let arr = [];

    for (var i in _feedback) {
      let obj = {
        "labelName": this.insertSpaces(i.replace(/\\/g, '')),
        "valueOfLabel": _feedback[i] ? _feedback[i].replaceAll(null, '').replace('Invalid date', '') : '--'
      }
      if (obj.labelName == 'Appointment Date' || obj.labelName == 'Appointment Date Time') {
        arr.push(obj)
      }
    }

    let _array1 = "";
    if (arr.length > 0) {
      //console.log(arrayCustomVs[0].valueOfLabel);
      _array1 = arr[0].valueOfLabel + '  ' + arr[1].valueOfLabel.replace('.', ':');
    }
    return _array1;
  }

  contactNameDetails(data: any) {
    //debugger;
    // console.log("---", JSON.stringify(data));
    const dialogRef = this.dialog.open(ContactListCampaignContactDialogPageComponent, {
      width: '80%',
      disableClose: true,
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'true') {

      }
      else if (result == 'false') {

      }


    });
  }
  applySearchFilter1(val: string) {
    this.dataSourceA.filter = val.toLowerCase().trim()
  }
  changeDate(val: any) {
    this.ChooseSelectedStartDateValue = "";
    this.ChooseSelectedEndDateValue = "";
    if (val == 'customDate') {
      this.isShowDatePicker = true;
    }
    else if (val == 'Today') {
      this.isShowDatePicker = false;
      let yesterday1 = (moment().subtract(0, 'days').startOf('day').toString());
      let yesterday2 = (moment().subtract(0, 'days').endOf('day').toString());
      //console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
      this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      this.getContacted();
    }
    else if (val == 'yesterday') {
      this.isShowDatePicker = false;
      let yesterday1 = (moment().subtract(1, 'days').startOf('day').toString());
      let yesterday2 = (moment().subtract(1, 'days').endOf('day').toString());
      //console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
      this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      this.getContacted();
    }
    else {
      this.isShowDatePicker = false;
    }
  }
  get_reschedule_time_format(date_time){
    try{
      const time_values = date_time.split('/')
      const date_value = time_values[0].split(" ")[0]
      return date_value + time_values[1]+":00"
    }catch(e){
      return ''

    }
  }
  approveBtn(element: any, datatype: any) {
    // debugger;
    //console.log(datatype)
    // console.log(element.user_id,"---",element);
    let obj = {};

    if (datatype != "FULL-FILLED") {

      obj['id_group'] = this.user.groupid;
      obj['id_user'] = element.user_id;
      obj['customer_to_contact'] = element.id;
      obj['approve_type'] = datatype;
      // if (datatype == "REWORK") {
      obj['remarks'] = this.remarksInputModel;
      // }
      if (datatype == "reschedule_date_time") {
        obj['approve_type'] = "NOT-COMPLETED";
        obj['substage1'] = "RESCHEDULE DATE-TIME";
        obj['substage2'] = this.get_reschedule_time_format(this.setResheduleDateTime);
        
      }
      if (datatype == "NOT-INTERESTED") {
        obj['approve_type'] = "NOT-COMPLETED";
        obj['substage1'] = "NOT-INTERESTED";
      }
      if (datatype == "NOT-CONTACTABLE") {
        obj['approve_type'] = "NOT-COMPLETED";
        obj['substage1'] = "NOT-CONTACTABLE";
      }
      if (datatype == "OCL-NON-CAF") {
        obj['approve_type'] = "NOT-COMPLETED";
        obj['substage1'] = "OCL-NON-CAF";

      }
      if (datatype == "TECHNICAL-ISSUE") {
        obj['approve_type'] = "NOT-COMPLETED";
        obj['substage1'] = "TECHNICAL-ISSUE";
      }
    }
    else {
      this.showHidePopupApprove = false;
      obj['id_group'] = this.user.groupid;
      obj['approve_type'] = "FULL-FILLED";
      obj['id_user'] = element.user_id;
      obj['customer_to_contact'] = element.id;
      obj['remarks'] = this.remarksInputModel;
    }
    this.API.LC_update_API(obj, this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response) {
        // console.log(_response);
        this._snackBar.open("Status update successfully", 'close', {
          duration: 2000,
        });
        this.ngOnInit();
      }
    })

  }
  noDocBtn() {
    this.showHidePopupApprove = false;
  }
  withDocBtn() {
    this.showHidePopupApprove = false;
  }
  handleDateChange(event: any) {
    this.ChooseSelectedDateValue = event.value;
    let _selDate1 = (moment(this.ChooseSelectedDateValue).subtract(0, 'days').startOf('day').toString());
    let _selDate2 = (moment(this.ChooseSelectedDateValue).subtract(0, 'days').endOf('day').toString());
    this.fromDate = moment(_selDate1).format('YYYY-MM-DD HH:mm:ss');
    this.toDate = moment(_selDate2).format('YYYY-MM-DD HH:mm:ss');
    this.getContacted();
    console.log(this.fromDate, "---", this.toDate);
  }

  ChooseSelectedStartDateValue: any;
  ChooseSelectedEndDateValue: any;
  handleStartDate(event: any) {
    this.ChooseSelectedStartDateValue = event.value;
    console.log('start date handle', this.ChooseSelectedEndDateValue)
    this.check_date_difference()
  }
  handleEndDate(event: any) {
    this.ChooseSelectedEndDateValue = event.value;
    // console.log('end date handle', this.ChooseSelectedStartDateValue)
    this.check_date_difference()
  }

  check_date_difference() {
    if (this.ChooseSelectedStartDateValue && this.ChooseSelectedEndDateValue) {
      let difference = this.ChooseSelectedEndDateValue.diff(this.ChooseSelectedStartDateValue, 'day')
      console.log('difference', difference)
      if (difference && (difference > 30)) {
        this._snackBar.open("Select less than 30 days only.", 'close', {
          duration: 2000,
        });
        this.ChooseSelectedEndDateValue = ''
        this.ChooseSelectedStartDateValue = ''
        console.log('selected values', this.ChooseSelectedStartDateValue, this.ChooseSelectedEndDateValue)
      }
    }
  }
  goRangeBtn() {
    // debugger;
    if (this.ChooseSelectedStartDateValue == undefined || this.ChooseSelectedStartDateValue == null || this.ChooseSelectedStartDateValue == "") {
      this._snackBar.open("Please select start date", 'close', {
        duration: 3000,
      });
      return false;
    }
    if (this.ChooseSelectedEndDateValue == undefined || this.ChooseSelectedEndDateValue == null || this.ChooseSelectedEndDateValue == "") {
      this._snackBar.open("Please select end date", 'close', {
        duration: 3000,
      });
      return false;
    }
    let _selDate1 = (moment(this.ChooseSelectedStartDateValue).subtract(0, 'days').startOf('day').toString());
    let _selDate2 = (moment(this.ChooseSelectedEndDateValue).subtract(0, 'days').endOf('day').toString());
    this.fromDate = moment(_selDate1).format('YYYY-MM-DD HH:mm:ss');
    this.toDate = moment(_selDate2).format('YYYY-MM-DD HH:mm:ss');
    this.getContacted();
    // console.log('date ranges', this.fromDate, this.toDate)
  }


  //download option for lc cordinator
  extractDataObject(ref, obj) {
    let arr = [];
    for (var i in ref) {
      if (obj.hasOwnProperty(ref[i])) {
        if (ref[i] == 'id' && obj['agent_reference_id']) {
          arr.push(obj['agent_reference_id'])
        } else {
          if (obj[ref[i]] == undefined) {
            arr.push(0);
          }
          else {
            arr.push(obj[ref[i]])
          }
        }

      } else {
        arr.push('')
      }
    }
    return arr;
  }

  downloadReport() {
    let obj = {
      end_date: moment(this.toDate).format('YYYY-MM-DD HH:mm:ss'),
      start_date: moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss'),
      id_group: this.user.groupid
    }
    this.API.download_LC_Report(obj, this.api_end_point).subscribe(
      (res: any) => {
        // debugger;
        if (res.error) {
          alert(res.error)
        } else {
          if (res.url && res.url.length) {
            this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
              () => {

              }
            )
          } else {
            alert('File URL is missing')
          }
        }
      }, (err) => {
        alert('Error in the API call');
      }
    )
  }

  remarksElement: any;
  remarksType: any;
  remarksInputModel: any;
  remarksBtn: any;

  notCompletedModel: any = "";
  showHideForNotComplted: boolean = false;
  remarksBtn1(element: any, type: any) {
    this.remarksInputModel = "";
    this.notCompletedModel = "";
    this.showHideResheduleDateTime = false;
    this.reTimeModel = "";
    this.showHideForNotComplted = false;
    this.showHideRemarks = true;
    this.remarksBtn = element;
    this.remarksType = type;

    this.dialog.open(this.remarksDialog, {
      width: '500px'
    });
  }
  remarksBtn2(element: any, type: any) {
    this.remarksInputModel = "";
    this.notCompletedModel = "";
    this.showHideResheduleDateTime = false;
    this.reTimeModel = "";
    this.showHideRemarks = true;
    this.remarksBtn = element;

    if (type == 'not_completed') {
      this.showHideForNotComplted = true;
    } else {
      this.showHideForNotComplted = false;
    }

    this.dialog.open(this.remarksDialog, {
      width: '500px'
    });
  }
  submitRemarks() {
    //debugger;

    if (this.showHideForNotComplted == true) {//|| this.remarksType=='not_completed'
      this.showHideForNotComplted = true;
      this.remarksType = this.notCompletedModel;
      if (this.notCompletedModel == "" || this.notCompletedModel == undefined || this.notCompletedModel == null) {
        this._snackBar.open("Please select reason in remarks*", 'close', {
          duration: 3000,
        });
        return;
      }
      if (this.notCompletedModel == "reschedule_date_time") {

        if (this.dateReshedule == undefined) {
          this._snackBar.open("Please select date*", 'close', {
            duration: 3000,
          });
          return;
        }
        let _date1 = moment(this.dateReshedule).format('YYYY-MM-DD HH:mm:ss');
        let _time = "";
        if ((document.getElementById('timeIdReshedule') as HTMLInputElement)) {
          _time = (document.getElementById('timeIdReshedule') as HTMLInputElement).value;
        }
        if (_date1 == "" || _date1 == undefined || _date1 == null) {
          this._snackBar.open("Please select date*", 'close', {
            duration: 3000,
          });
          return;
        }
        if (_time == "" || _time == undefined) {
          this._snackBar.open("Please enter time*", 'close', {
            duration: 3000,
          });
          return;
        }
        if (_time != "") {
          let _selectedDate: any = new Date(this.dateReshedule)
          let _sytemTodayDate: any = new Date();
          var _sytemDate: any = new Date(_sytemTodayDate.getTime());
          _sytemDate.setHours(0, 0, 0, 0);

          let _inputTextTime = (<HTMLInputElement>document.querySelector('#timeIdReshedule')).value.match(/\d+:\d+/).toString();
          let _systime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
          if ((_selectedDate - _sytemDate === 0) && _inputTextTime < _systime) {
            this._snackBar.open('Past Time not allowed.', 'close', {
              duration: 3000,
            })
            return false;
          }
        }
        if (this.remarksInputModel == "" || this.remarksInputModel == undefined) {
          this._snackBar.open("Please enter remarks*", 'close', {
            duration: 3000,
          });
          return;
        }

        this.setResheduleDateTime = _date1 + ' / ' + _time;

      }
    }



    if (this.remarksInputModel == "" || this.remarksInputModel == undefined) {
      this._snackBar.open("Please enter remarks*", 'close', {
        duration: 3000,
      });
      return;
    }
    else {
      this.approveBtn(this.remarksBtn, this.remarksType)
      this.showHideRemarks = false;
    }
  }

  cancelRemarks() {
    this.dialog.closeAll();
  }
  changeReasonNotCompleted(val: any) {
    this.reTimeModel = "";
    this.notCompletedModel = val;
    if (val == "reschedule_date_time") {
      this.showHideResheduleDateTime = true;
    }
    else {
      this.showHideResheduleDateTime = false;
    }
  }


  elementReshedule: any;
  resheduleType: any;
  dateReshedule: any;
  setResheduleDateTime: any = "";
  clickReshedule(element: any, type: any) {
    this.elementReshedule = element;
    this.resheduleType = type;
    this.showHideResheduleDateTime = true;
  }

  uploadBtnClick() {
    let _obj = {}
    const dialogRef = this.dialog.open(LcUploadDialogComponent, {
      width: '600px',
      disableClose: true,
      data: _obj
    });

    dialogRef.afterClosed().subscribe(result => {
      //	debugger;
      console.log('result', result)
      if (result && (result == 'api-response')) {
        this.getContacted()
      }
    });

  }

  // submitReshedule() {
  //   if (this.dateReshedule == undefined) {
  //     this._snackBar.open("Please select date*", 'close', {
  //       duration: 3000,
  //     });
  //     return;
  //   }
  //   let _date1 = moment(this.dateReshedule).format('YYYY-MM-DD HH:mm:ss');
  //   let _time = "";
  //   if ((document.getElementById('timeIdReshedule') as HTMLInputElement)) {
  //     _time = (document.getElementById('timeIdReshedule') as HTMLInputElement).value;
  //   }
  //   if (_date1 == "" || _date1 == undefined || _date1 == null) {
  //     this._snackBar.open("Please select date*", 'close', {
  //       duration: 3000,
  //     });
  //     return;
  //   }
  //   if (_time == "" || _time == undefined) {
  //     this._snackBar.open("Please enter time*", 'close', {
  //       duration: 3000,
  //     });
  //     return;
  //   }
  //   if (_time != "") {
  //     let _selectedDate: any = new Date(this.dateReshedule)
  //     let _sytemTodayDate: any = new Date();
  //     var _sytemDate: any = new Date(_sytemTodayDate.getTime());
  //     _sytemDate.setHours(0, 0, 0, 0);

  //     let _inputTextTime = (<HTMLInputElement>document.querySelector('#timeIdReshedule')).value.match(/\d+:\d+/).toString();
  //     let _systime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
  //     if ((_selectedDate - _sytemDate === 0) && _inputTextTime < _systime) {
  //       this._snackBar.open('Past Time not allowed.', 'close', {
  //         duration: 3000,
  //       })
  //       return false;
  //     }
  //   }


  //   this.setResheduleDateTime = _date1 + ' / ' + _time;

  //   this.approveBtn(this.elementReshedule, this.resheduleType);
  //   this.showHideResheduleDateTime = false;
  //   this.reTimeModel = "";
  // }

  onMinimizedChange(status: boolean) {
    this.is_minimized = status;
  }
}


