<form [formGroup]="campaignForm">
  <div class="dialog-container">
    <div class="dialog-header">
      <h2>Create Campaign</h2>
      <!-- <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i> -->
    </div>

    <div class="dialog-content">
      <!-- Campaign Name -->
      <div class="form-field">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Campaign Name</mat-label>
          <input matInput placeholder="Campaign Name" formControlName="name" (keypress)="omit_special_char($event)">
          <mat-error *ngIf="_form.name.errors?.required">
            Campaign name is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Campaign Date -->
      <div class="date-container">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Date" 
                 [matDatepickerFilter]="hidePreviousDates"
                 readonly (click)="picker.open()" formControlName="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="time-field">
          <mat-label>Time</mat-label>
          <input matInput type="time" placeholder="Time" formControlName="time" #timeInput>
          <div class="time-picker-trigger" (click)="timeInput.showPicker()">
            <mat-icon matSuffix>schedule</mat-icon>
          </div>
        </mat-form-field>
      </div>

      <!-- Master Campaign -->
      <div class="form-field">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Campaign Type</mat-label>
          <input matInput placeholder="Campaign Type" formControlName="master_campaign"
                 *ngIf="master_campaign_field.input == 'FT'">
          <mat-select formControlName="master_campaign" 
                     *ngIf="master_campaign_field.input == 'DD'"
                     [(value)]="master_campaign_field.default_value">
            <mat-option *ngFor="let val of master_campaign_field.values" [value]="val">
              {{val}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Incall Screen -->
      <div class="form-field" *ngIf="incallscreen_atrs.visibility">
        <mat-form-field appearance="outline">
          <mat-label>Incall Screen</mat-label>
          <mat-select formControlName="screenname" placeholder="Select Form">
            <mat-option [value]="v.definition" 
                       *ngFor="let v of incallscreen_atrs.pre_values['Incallscreen']">
              {{v.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Select Agents -->
      <div class="agents-section">
        <span>Select Agents</span>
        <mat-radio-group formControlName="agent_selected_option">
          <mat-radio-button [value]="'web'">Select From Dropdown</mat-radio-button>
          <mat-radio-button [value]="'excel'">Select From Excel</mat-radio-button>
        </mat-radio-group>

        <mat-accordion *ngIf="campaignForm.get('agent_selected_option').value=='web'">
          <mat-expansion-panel class="agents-panel">
            <mat-expansion-panel-header>
              Selected Agents ({{getSelectedCount()}})
            </mat-expansion-panel-header>
            <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl">
              <mat-tree-node style="min-height: 28px;" *matTreeNodeDef="let node" matTreeNodeToggle
                matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox style="font-size: 12px !important;white-space: pre;"
                  [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)"
                  class="checklist-leaf-node mcx1" [disabled]="node.disabled">
                  <dl>
                    <dt class="agent_name">{{node.name}} - [{{node.email}}]</dt>
                    <dt *ngIf="node.active_campaign" class="agent_campaign">
                      <span>{{beautify_active_campaign(node.active_campaign)}}</span>
                    </dt>
                  </dl>
                </mat-checkbox>
              </mat-tree-node>
              <mat-tree-node style="min-height: 28px;" *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename"
                  (click)="expand_collapse_all_childs(node)">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>

                <mat-checkbox style="font-size: 12px !important;" [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)"
                  class="checklist-node">
                  {{node.name}}
                </mat-checkbox>
              </mat-tree-node>
            </mat-tree>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- Dial Mode -->
      <div class="dial-mode-section">
        <span>Dial Mode</span>
        <mat-radio-group formControlName="priority">
          
          <mat-radio-button *ngFor="let mode of dial_mode.dial_modes" [value]="mode.name">
            <div class="d-flex align-items-center">
              <span class="me-2">{{mode.name}}</span>
              <mat-form-field appearance="outline" class="ms-2" style="width: 100px;"
                            *ngIf="mode.priority == 2 && mode.dial_ratio.dd_values">
                <mat-select (selectionChange)="setDialRatio($event.value)" 
                           [value]="mode.dial_ratio.dd_values[0]">
                  <mat-option *ngFor="let dr of mode.dial_ratio.dd_values" [value]="dr">
                    {{ dr }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Work Mode -->
      <div class="form-field">
        <mat-form-field appearance="outline">
          <mat-label>Work Mode</mat-label>
          <mat-select formControlName="campaign_mode" 
                     placeholder="Work Mode"
                     (selectionChange)="onChange($event.value)">
            <mat-option *ngFor="let mode of campaign_work_mode" [value]="mode">
              {{ mode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- File Upload -->
      <span>Data (only supports xlsx file)</span>
      <div class="file-upload-section">
        <div class="file-upload-container">
          <button mat-stroked-button class="browse-button">
            Browse
            <input type="file" #fileUpload class="file-input" accept=".xlsx"
                   formControlName="file" (change)="changeFile($event)" />
          </button>
          <span class="file-name">{{currentFileobj.name || 'No file chosen'}}</span>
        </div>
        <div class="template-download">
          <span class="v-download-cd" (click)="download_file()">
            Data Upload Sheet.xlsx
          </span>
        </div>
      </div>

      <!-- Submit Buttons -->
      <div class="action-buttons">
        <button mat-raised-button (click)="closePopup()">
          <i class="fa fa-times" aria-hidden="true"></i> &nbsp;&nbsp;CANCEL
        </button>
        <button mat-raised-button (click)="assign_later(campaignForm)">
          <mat-icon>schedule</mat-icon>
          ASSIGN LATER
        </button>
        <button mat-raised-button (click)="submit_Camapign(campaignForm)">
          <mat-icon>check</mat-icon>
          ASSIGN NOW
        </button>
       
        <!-- <div class="vk-dropdown">
          <button mat-raised-button>
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="vk-dropdown-content">
            <button mat-raised-button (click)="assign_later(campaignForm)">
              Assign Later
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <p class="succData">{{ campaignUpload_msg }}</p>
</form>

<div id="cover-spin" *ngIf="showLoader"></div>
