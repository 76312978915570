import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { SendNotificationDialogComponent } from '../send-notification-dialog/send-notification-dialog.component';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MyserviceService } from '../myservice.service';				 

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

  displayedColumns = ['checkbox','batchCode', 'batchStarts', 'batchEnds', 'trainer','timeScedule'];
  displayedColumnsAgents = ['name', 'email', 'id', 'batch_code', 'status', 'edit'];
  dataSource = new MatTableDataSource();
  selectedBatches:any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  showHideBatchButton:boolean=true;
  showHideAgentButton:boolean=false;
  api_end_point:any;
  @ViewChild('searchInput', {read: MatInput}) searchInput: MatInput;
  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private myService: MyserviceService) { }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
    // let ELEMENT_DATA = [
    //   {initial: '18 JULY 2020', isGroupBy: true},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'John',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Abdul',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'John',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Ajay',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Ajay',timeScedule:'11Am - 01pm'},
    //   {batchCode: '111111',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'ajay',timeScedule:'11Am - 01pm'},
    //   {initial: '19 JULY 2020', isGroupBy: true},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'John',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Abdul',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'John',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Ajay',timeScedule:'11Am - 01pm'},
    //   {batchCode: '324ASD',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'Ajay',timeScedule:'11Am - 01pm'},
    //   {batchCode: '111111',batchStarts:'23/07/2020',batchEnds:'07/08/2020', trainer: 'ajay',timeScedule:'11Am - 01pm'},
    // ];
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;
    this.getbatchListData();
  }
  getbatchListData(){
    let _today:any=new Date();
    let _startDate = moment(_today).format('yyyy-MM-DD');
   let _endDate=moment( new Date(_today.setDate(_today.getDate() + 30))).format('yyyy-MM-DD');
    this.API.getBatchList(_startDate,_endDate,"",this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        let _filterData=(_response || []).filter(v=> v.status!= "CANCELLED");
       _filterData = _filterData.sort((a , b) =>{
         const d1:  any = moment(a.start_date).format('YYYYMMDD');
         const d2: any = moment(b.start_date).format('YYYYMMDD');
         const result: any = d1-d2;
         return result;
       })
      // console.log(_filterData)
        var groupData:any[] = [];
        let prevDate: moment.Moment = moment(_filterData[0].start_date);
        groupData.push({date: _filterData[0].start_date, isGroupBy: true})
        _filterData.map((row,index)=>{
         // console.log(prevDate,row.start_date.split('T')[0])
          const currDate: moment.Moment = moment(row.start_date)
         // console.log(currDate.diff(prevDate, 'days'))
          if(currDate.diff(prevDate, 'days') > 0){
             groupData.push({date: row.start_date, isGroupBy: true})
           //  console.log("true")
          }
          row.isSelected = false;
          groupData.push(row);
          prevDate = moment(row.start_date);

        })

        let ELEMENT_DATA=groupData;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
          
          return (data.id && data.id.toString().toLowerCase().includes(filter)) || (data.trainer_name && data.trainer_name.toString().toLowerCase().includes(filter)) 
        };
     
      }
    });
  }
  isGroup(index, item): boolean{
    return item.isGroupBy;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }


  changeTogleTrainer(value){
    if(value=="batchBtn"){
      this.showHideBatchButton=true;
      this.showHideAgentButton=false;
    }
    else if(value=="agentBtn"){
      this.showHideBatchButton=false;
      this.showHideAgentButton=true;
    }

  }
  sendNotificationDialog(){
    if(this.selectedBatches.length == 0){
      alert("Select batch for sending notification");
      return;
    }
    const dialogRef = this.dialog.open(SendNotificationDialogComponent, {
    width: '40%',
    disableClose:true,
    data: {
      batch: true,
      ids:this.selectedBatches
    },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        this.selectedBatches = [];
        this.getbatchListData();
        this.searchInput.value="";
      }
    });
}
    resetIds(){
    this.selectedBatches = [];
     let _arrayData:any=this.dataSource.data;
     _arrayData.map((row,index)=>{
          row.isSelected = false;
        })
    this.dataSource = new MatTableDataSource(_arrayData);
        this.dataSource.paginator = this.paginator;
    
  }
  selectForMessageBatch(event, index, selectForMessageBatch){
   // console.log(event, index, selectForMessageBatch)
    if (event.checked == true){
      this.selectedBatches.push(selectForMessageBatch)
    }else{
      this.selectedBatches= this.selectedBatches.slice(0, index).concat(this.selectedBatches.slice(index + 1, this.selectedBatches.length))
    }
  //  console.log(this.selectedBatches)
  }
}
