<div class="row">
	<div class="col-md-12">



<mat-table [dataSource]="dataSource" matSort>
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
	</ng-container>
	<!-- <ng-container matColumnDef="type">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.type }}</mat-cell>
	</ng-container> -->
	<ng-container matColumnDef="default">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Default </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.latest == 1 ? 'True' : 'False' }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="passing_score">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Passing Score </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.passing_score }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="timing">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Timing </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.timing }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total_questions">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Total Questions </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.total_questions }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total_score">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Total Score </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.total_score }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total_sections">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Total Sections </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.total_sections }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="created_at">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Created At </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.created_at  | date:'dd/MM/yyyy'}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="download">
		<mat-header-cell *matHeaderCellDef>Questionaire </mat-header-cell>
		<mat-cell *matCellDef="let element; let i = index;">
			<!-- <button mat-raised-button (click)="downloadCampaignStatusReport(element)">Download</button>  -->
			<button mat-stroked-button class="download_btn deBtn" color="primary" (click)="downloadQuestionaire(element,i)" >
				<i class="fa fa-download" aria-hidden="true"></i>&nbsp;	Download&nbsp;
				<!-- <i class="fa fa-angle-down"></i> -->
				<!-- <i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i> -->
			</button>
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef>Action </mat-header-cell>
		<mat-cell *matCellDef="let element; let i = index;">
			<!-- <button mat-raised-button (click)="downloadCampaignStatusReport(element)">Download</button>  -->
			<button mat-stroked-button class="download_btn deBtn" color="primary" (click)="editAssessment(element,i)" >
				Edit&nbsp;
				<!-- <i class="fa fa-angle-down"></i> -->
				<!-- <i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i> -->
			</button>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
</div>
