import { Component, OnInit,HostListener } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import {MyserviceService} from '../myservice.service';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-contiinex-web-application',
  templateUrl: './contiinex-web-application.component.html',
  styleUrls: ['./contiinex-web-application.component.scss']
})


export class ContiinexWebApplicationComponent implements OnInit {
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
   // debugger;
  let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  let max = document.documentElement.scrollHeight;

  let x = window.matchMedia("(min-width: 1500px)");
    if(x.matches==true)
    {
      if( pos < 6100){
        this.MenuSelectedIndex =0;
        return;
       }

       if( pos > 7450 && pos < 7570){
        this.MenuSelectedIndex =0;
        if(pos > 7466 && pos < 7468){
          this.MenuSelectedIndex =2;
          return;
        }
        return;
       }
       if(pos <7600 && pos > 7450){
        this.MenuSelectedIndex =1;
        document.getElementById('aboutDescription').classList.add("animation-Two");
        return;
       }
       if(pos <8500 && pos > 6100){
        this.MenuSelectedIndex =1;
        document.getElementById('aboutDescription').classList.add("animation-Two");
        return;
       }

       if(pos <8700 &&  pos >7900){
        this.MenuSelectedIndex =2;
        return;
       }
       if(pos >10600){
        this.MenuSelectedIndex =0;
        return;
       }
       if(pos == max )   {
        this.MenuSelectedIndex =0;
        document.getElementById('homeContent').classList.add("animation-One");
        return;
       }
    }
    else{


   if( pos < 6100){
    this.MenuSelectedIndex =0;
   }
   if(pos <7600 && pos > 6180){
    this.MenuSelectedIndex =1;
   }
   if(pos <6800 && pos > 6100){
    this.MenuSelectedIndex =1;
    document.getElementById('aboutDescription').classList.add("animation-Two");
   }
   if( pos >7600){
    this.MenuSelectedIndex =2;
   }
   if(pos >12600){
    this.MenuSelectedIndex =0;
   }
   if(pos == max )   {
    this.MenuSelectedIndex =0;
    document.getElementById('homeContent').classList.add("animation-One");
   }
  }
  }
MenuList:any=[];
NameList:any=[];
selectedIndex:any=0;
MenuSelectedIndex:any=0;
showHideComnex:boolean=true;
showHideAInex:boolean=false;
showHideGignex:boolean=false;

showHideFormKnowMore:boolean=false;
createdForm:UntypedFormGroup;
passingMessage:any="";
showHidePassingMessage:boolean=false;
_color:any='';
currentLoader: boolean = false;
session: any;
api_end_point:any;

  constructor(private router:Router,private formbuilder:UntypedFormBuilder,private _service:MyserviceService, private API: ApiService) {
      window.scroll(0,0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.createdForm=this.formbuilder.group({
        formName:['',Validators.required],
        formEmail:['',Validators.required],
        formStd:['+91'],
        formPhone:['',Validators.required],
        formMessage:['',Validators.required]
      })
  }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
	  this.session = this.getParameterByName('session');
	  if (this.session) {
		  this.currentLoader = true;
		  this._service.clearAllItems();
		  window.sessionStorage.clear();
		  sessionStorage.setItem('from_external', 'true');
		  this.processRedirectedUser(JSON.parse(atob(this.session)))

	  }
    window.scroll(0,0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
   // this.getScrollLoad();
    this.MenuList=[{name:'HOME'},{name:'ABOUT CONTIINEX'},{name:'PRODUCTS'}];//,{name:'Our Clients'}


	// this.NameList=[{name:'Ainex',imgurl:'../assets/image/Ainex.png'},{name:'Dialer & Smarter ACD',imgurl:'../assets/image/DSA.png'},{name:'Workflow Automation',imgurl:'../assets/image/workflowAuto.png'},{name:'Contiinex Widget',imgurl:'../assets/image/CoWidget.png'},{name:'Gignex',imgurl:'../assets/image/gignex.png'},{name:'Contiinex Quality Audit',imgurl:'../assets/image/CoQA.png'}]
  this.NameList=[{name:'Comnex',imgurl:'../assets/image/ComnixLogo.png'},{name:'Gignex',imgurl:'../assets/image/v-comnexEngageIPic.png'},{name:'Ainex',imgurl:'../assets/image/ainexLogo.png'}]
  }

	processRedirectedUser(res) {
		var userDetails = res, stageSettings;
		let access_token = res['access_token'];
		let landing_page = '';
		if (access_token) {
			this._service.set_access_token(access_token);
			localStorage.setItem('access_token', access_token);
			this._service.set_loggedin_user(res);
			//   debugger;
			// Calling team api
			// console.log('login team api')
			this.API.teamAPI(this.api_end_point)
				.subscribe(
					(res) => {
						// debugger;
						//   console.log('printing team response',res)
						this._service.set_my_team(res);

						// Calling settings api
						//   console.log('signin settigs')
						this.API.settingsAPI(this.api_end_point).subscribe(
							(res: any) => {
								//   console.log('printing settings response',res);
								//   debugger;
								if (res.success) {
									stageSettings = res.success;
									this._service.set_stage_settings(res.success);
									// console.log('signin full team api');
									if (res.success.web) {
										for (var i in res.success.web) {
											if (res.success.web[i]['name'] == 'landing_page') {
												landing_page = res.success.web[i]['definition'];
											}
										}
									}

								} else {
									alert('Error in fetching settings');
									this.currentLoader = false;
									this._service.clearAllItems();
									this.router.navigate(['/managementTeam']);
									return;
								}
								this.API.getFullTeamData(this.api_end_point).subscribe(
									(res: any) => {
										if (res.success) {
											this._service.set_Full_team(res.success);

											// Routing to Dashboard
											if (userDetails.role == 'user') {
												var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
												this.API.agentStartEndSession(obj, this.api_end_point).subscribe(
													(res: any) => {
														console.log(res)
													}, (err) => {
														console.log(err)
													}
												)
												if (landing_page.toLowerCase().includes('audit')) {
													this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
												} else {
													// if(!userDetails.isVisonEnabled){
													//   this.router.navigate(['/agentPlugin/face']);
													//   return;
													// }
													// this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
													//   (res: any) => {
													//     if(res.success == 1){
													//       this.router.navigate(['/agentPlugin/assesment']);
													//       // return;
													//     }else{
													//       this.router.navigate(['/agentPlugin/home']);
													//     }
													//   }, (err) => {
													//     console.log(err);
													//     this.router.navigate(['/agentPlugin/home']);
													//   }
													// )
													// return;
													if (stageSettings && stageSettings.extraoptions) {
														for (let opt of stageSettings.extraoptions) {
															if (opt.name == "biometric" && opt.definition == 'true') {
																if (!userDetails.isVisonEnabled) {
																	this.router.navigate(['/agentPluginPC/face']);
																	return;
																}
															}
															if (opt.name == "assessment" && opt.definition == 'true') {
																this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id,this.api_end_point).subscribe(
																	(res: any) => {
																		if (res.success == 1) {
																			this.router.navigate(['/agentPluginPC/assessment']);
																			return;
																		} else {
																			this.router.navigate(['/agentPluginPC/home']);
																		}
																	}, (err) => {
																		console.log(err)
																	}
																)
															} else {
																this.router.navigate(['/agentPlugin/home']);
															}
														}
													} else {
														this.router.navigate(['/agentPlugin/home']);
													}
												}

											} else {
												let total_pages = this._service.setMyPages();
												// console.log('total_pages', total_pages);
												let page_found: any = false;
												for (var i in total_pages) {
													if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
														page_found = total_pages[i];
														break;
													}
												}
												if (page_found) {
													//	this.router.navigate([page_found['path']]);
													window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
												} else {
													this.router.navigate(['/managementTeam']);
												}
											}
										} else {
											alert(res.error)
											this.currentLoader = false;
										}
									}, (err) => {
										alert('Error in the full team fetch')
										this.currentLoader = false;
									}
								)
							},
							(err) => {
								alert('Error in the settings api')
								this.currentLoader = false;
							}
						)
					},
					(err) => {
						// debugger;
						alert('Error in the api')
						this.currentLoader = false;
					}
				)
		} else {
			alert('Unathorized access. Contact tech support')
			this.currentLoader = false;
		}
	}

	getParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

  getScrollLoad(){
    let element = document.getElementById("homeContent");
    let offset = 100;
    let bodyRect = document.body.getBoundingClientRect().top;
    let elementRect = element.getBoundingClientRect().top;
    let elementPosition = elementRect ;//- bodyRect;
    let offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })

  }
  setIndex(index: number,v:any) {
    this.selectedIndex = index;
    this.showHideComnex=false;
    this.showHideAInex=false;
    this.showHideGignex=false;
    let element = document.getElementById("productListContent");
    let offset = 220;
    let bodyRect = document.body.getBoundingClientRect().top;
    let elementRect = element.getBoundingClientRect().top;
    let elementPosition = elementRect - bodyRect;
    let offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
   if(v.name=="Comnex"){
      this.showHideComnex=true;
      this.MenuSelectedIndex =2;
    }
    else if(v.name=="Ainex"){

      this.showHideAInex=true;
      this.MenuSelectedIndex =2;
    }

    else if(v.name=="Gignex"){

      this.showHideGignex=true;
      this.MenuSelectedIndex =2;
    }
    else{

    }

 }
 setMenuIndex(i:any,v:any){
  // debugger;
  this.MenuSelectedIndex = i;
if(v.name=="HOME"){
  let element = document.getElementById("homeContent");
  let offset = 100;
  let bodyRect = document.body.getBoundingClientRect().top;
  let elementRect = element.getBoundingClientRect().top;
  let elementPosition = elementRect - bodyRect;
  let offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
  window.scroll(0,0);
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
  document.getElementById('homeContent').classList.add("animation-One");
}
if(v.name=="ABOUT CONTIINEX"){
  let element = document.getElementById('About');
  let offset = 80;
  let bodyRect = document.body.getBoundingClientRect().top;
  let elementRect = element.getBoundingClientRect().top;
  let elementPosition = elementRect - bodyRect;
  let offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
  document.getElementById('aboutDescription').classList.add("animation-Two");
  return;
}
if(v.name=="PRODUCTS"){
  let element = document.getElementById("Features");
  let offset = 100;
  let bodyRect = document.body.getBoundingClientRect().top;
  let elementRect = element.getBoundingClientRect().top;
  let elementPosition = elementRect - bodyRect;
  let offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })

}

 }
 coWeb(){
   window.scroll(0,0);
this.router.navigateByUrl('/home');
 }
 LoginBtn(){
  window.scroll(0,0);
    this.router.navigateByUrl('/signin');

 }

 openKnowMore() {
   this.showHideFormKnowMore=true;
  var x = document.getElementById("popDiv");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  document.getElementById('popDiv').style.display = 'block';
  document.body.style.overflow = 'hidden';
  window.scroll(0,0);
}
closeForm(){
  this.showHideFormKnowMore=false;
  var x = document.getElementById("popDiv");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  document.getElementById('popDiv').style.display = 'none';
  document.body.style.overflow = 'scroll';
}
saveForm(createdForm:any){
  //debugger;
  this.passingMessage="";
  this.showHidePassingMessage=false;
  this._color='';
  let _formName=createdForm.controls.formName.value;
  let _formEmail=createdForm.controls.formEmail.value;
  let _formStd=createdForm.controls.formStd.value;
  let _formPhone=createdForm.controls.formPhone.value;
  let _formMessage=createdForm.controls.formMessage.value;
  if(_formName==null ||_formName=="" ||_formName==undefined){
    this.passingMessage="Please Enter Name *";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
      }
  if(_formEmail==null ||_formEmail=="" ||_formEmail==undefined){
        this.passingMessage="Please Enter Email *";
        this.showHidePassingMessage=true;
        this._color='red';
        return false;
     }

     let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
     if (reg.test(_formEmail) == false)
     {
       this.passingMessage="Please Enter Valid Email ID *";
       this.showHidePassingMessage=true;
       this._color='red';
         return false;
     }

     if(_formPhone==null ||_formPhone=="" ||_formPhone==undefined){
      this.passingMessage="Please Enter Phone Number *";
      this.showHidePassingMessage=true;
      this._color='red';
      return false;
   }
   if(_formMessage==null ||_formMessage=="" ||_formMessage==undefined){
    this.passingMessage="Please Enter Message *";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
 }
  let _modelObject={
    "Name":_formName,
    "Email":_formEmail,
    "Phone":_formStd + _formPhone,
    "Message":_formMessage
    }

    //console.log(_modelObject);
    this.API.saveKnowMoreEnquiry(_modelObject,this.api_end_point).subscribe((response:any)=>{
     // console.log(response);
      if(response.success){
        this.passingMessage="Great, Successfully done !.";
        this.showHidePassingMessage=true;
        this._color='green';
        this.createdForm.reset();
        this.createdForm.controls['formStd'].setValue('+91');
        setTimeout(()=>{
          this.passingMessage="";
          this.showHidePassingMessage=false;
          this._color='';
        },4000)

      }
     else if(response.errors){
        this.passingMessage=response.errors;
        this.showHidePassingMessage=true;
        this._color='red';
      }
    },(err) => {
      alert('Error in the api'+JSON.stringify(err))
    });
}

onlyNumberValidation(event){
  return event.charCode >= 48 && event.charCode <= 57;
}

omit_special_char(event)
{
 let k;
 k = event.charCode;  //         k = event.keyCode;  (Both can be used)
 return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

validateEmail(){
  let emailField=this.createdForm.controls.formEmail.value;
  let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (reg.test(emailField) == false)
  {
    this.passingMessage="Please Enter Valid Email ID *";
    this.showHidePassingMessage=true;
    this._color='red';
      return false;
  }
  this.passingMessage="";
  this.showHidePassingMessage=false;
  this._color='';
  return true;

}
comnexPage(){
  window.open('Comnex','_blank');
}
gignexPage(){
  window.open('Gignex','_blank');
}
ainexPage(){
  window.open('Ainex','_blank');
}
contactUsPage(){
  window.open('Contactus','_blank');
}
}
