
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import {MyserviceService} from '../myservice.service';

@Component({
  selector: 'app-send-notification-dialog',
  templateUrl: './send-notification-dialog.component.html',
  styleUrls: ['./send-notification-dialog.component.scss']
})
export class SendNotificationDialogComponent implements OnInit {

  notificationData: any = {}
  messageForm: UntypedFormGroup;
	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
  _color: any = "";
  api_end_point;any
  constructor(public dialogRef: MatDialogRef<SendNotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public notifyData, private API: ApiService, private formbuilder: UntypedFormBuilder,private myService: MyserviceService) { 
   // console.log(notifyData)
    this.notificationData = notifyData;
    this.messageForm = this.formbuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required],
    })
  }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
  }
  closeDialog(refresh: boolean = false) {
    this.dialogRef.close({ refresh});
  }

  sendNotification(formData){
    this.messageAlert ="";
    this.showHide_messageAlert = false;
    this._color = '';
    if(this.messageForm.value.subject=="" || this.messageForm.value.subject==null){
      this.messageAlert ="* Please enter subject";
      this.showHide_messageAlert = true;
      this._color = 'red';
    return;
    }
    if(this.messageForm.value.body=="" || this.messageForm.value.body==null){
      this.messageAlert ="* Please enter message";
      this.showHide_messageAlert = true;
      this._color = 'red';
    return;
    }


    let _obj: any = {
      "notification_type": this.notificationData.batch ? "Batch" : "Agents",
      "notification_subject": this.messageForm.value.subject,
      "notification_body": "<p>"+this.messageForm.value.body+"</p>"
    }
    if (this.notificationData.batch){
      _obj.batch_list = this.notificationData.ids
    }
    if (!this.notificationData.batch){
      _obj.agents_list = this.notificationData.ids
    }
   // console.log(_obj)
    this.API.sendNotifications(_obj,this.api_end_point).subscribe((data: any) => {
      if (data.success) {
      //  alert("Notification sent successfully");
        this.messageAlert ="Notification sent successfully..!!";
        this.showHide_messageAlert = true;
        this._color = 'green';
       
        setTimeout(()=>{
          this.messageAlert ="";
          this.showHide_messageAlert = false;
          this._color = '';
          this.closeDialog(true);
        },5000)
      
      }
      else {
        alert(data.errors);
      }
    })
  }

}
