import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UntypedFormGroup, Validators, FormControl, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service'
@Component({
  selector: 'app-demo-portal-sip-call',
  templateUrl: './demo-portal-sip-call.component.html',
  styleUrls: ['./demo-portal-sip-call.component.scss']
})
export class DemoPortalSipCallComponent implements OnInit {
  showHideVisible:boolean=true;
  showHideVisibleSuccess:boolean=false;
  createdForm: UntypedFormGroup;
  _call_id:any="";
  _message:any="";
  api_end_point:any;
  constructor(public router :Router,private API: ApiService,
		private formbuilder: UntypedFormBuilder,private _service:MyserviceService) {
      this.createdForm = this.formbuilder.group({
				"telcoPartner": ['TWILIO', Validators.required],
				"agentNumber": ['', Validators.required],
				"customerNo": ['', Validators.required],
				"cli": ['+15512617144', Validators.required]
			  })
     }

  ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  }
  backDashboard(){
   this.router.navigateByUrl('/demo-portal');
   setTimeout(()=>{
     this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
   },100);
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  cancelBtn(){
    this.showHideVisible=true;
    this.showHideVisibleSuccess=false;
    this.createdForm.reset();
  }
  submitBtn(createdForm:any){ 
    let _telcoPartner=createdForm.controls.telcoPartner.value;
    let _agentNumber=createdForm.controls.agentNumber.value;
    let  _cusNo=createdForm.controls.customerNo.value;
    let _cli=createdForm.controls.cli.value;
    if(_telcoPartner=="" || _agentNumber=="" || _cusNo =="" || _cli==""){
        alert("Please enter all filelds");
        return false;
    }
    let _obj={
        "telco_partner":createdForm.controls.telcoPartner.value,
        "agent_number":createdForm.controls.agentNumber.value,
        "customer_number":createdForm.controls.customerNo.value,
        "cli":createdForm.controls.cli.value
    }
    this.API.sipCallApiSave(_obj,this.api_end_point).subscribe((response:any) => {
if(response.status=="success"){
 this._call_id= response.call_id;
 this._message= response.message;
  this.showHideVisible=false;
this.showHideVisibleSuccess=true;
}
else{
  alert(response.status);
}
      });
  }
  okBtn(){
    window.location.reload();
    this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
}
