import { Component, OnInit,ViewChild,Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {Router} from '@angular/router';
import { AssignBatchComponent } from '../assign-batch/assign-batch.component';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-trainers-availibility',
  templateUrl: './trainers-availibility.component.html',
  styleUrls: ['./trainers-availibility.component.scss']
})
export class TrainersAvailibilityComponent implements OnInit {
  displayedColumns = ['name', 'batch_code', 'availability', 'assign'];
  dataSource: any = new MatTableDataSource();
  startDate: any = "";
  endDate: any = "";
  passingMessage: any;
  showHidePassingMessage: boolean = false;
	_color: any = "";
  
  @Output() dateChangeEvent = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  todayDate=new Date();
  removeSearchValue:any;
  api_end_point:any;
  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private myService:MyserviceService) { }

  ngOnInit() {
    // this.getTrainerListTable(); 
	this.api_end_point = this.myService.get_API_End_Point();   
     }
  getTrainerListTable(startDate: string = "", endDate: string = ""){
    this.API.trainersAllTrainersList(startDate, endDate,"",this.api_end_point).subscribe((response:any)=>{
       if(response.success){
         let ELEMENT_DATA=  response.success;
           this.dataSource = new MatTableDataSource(ELEMENT_DATA);
           this.dataSource.paginator = this.paginator;
           this.dataSource.filterPredicate = function(data, filter: string): boolean {
            return data.name.toString().toLowerCase().includes(filter) || data.batch_code.toString().toLowerCase().includes(filter) || data.availability.toString().toLowerCase().includes(filter)
          };
       }  
     })
   }
   
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
	assignBatch(element:any){
		const dialogRef = this.dialog.open(AssignBatchComponent, {
			width: '70%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getTrainerListTable(this.startDate, this.endDate);
		  });
  }
  checkAvailibilty(){
    this.removeSearchValue="";
    this.passingMessage="";
    if(this.startDate=="" || this.endDate==""){
      this.passingMessage = "Please select both start date and end date.*";
      this.showHidePassingMessage = true;
      this._color = 'red';
      //alert("Please select both start date and end date.");
      return false;
    }
    if(this.startDate > this.endDate){
      this.passingMessage = "Please select both start date and end date.Start date should be less than end date.*";
      this.showHidePassingMessage = true;
      this._color = 'red';
      //alert("Start date should be less than end date.");
      return false;
    }
    // this.dateChangeEvent.next({startDate: this.startDate, endDate: this.endDate});
    this.getTrainerListTable(this.startDate, this.endDate);
  }
 
  getAvailibiltyColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Available':
				obj.color = 'green';
				break;
		}
		return obj;
  }
  change_date(key:string, event:MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
      this.startDate = moment(new Date(event.value)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		} else {
      this.endDate = moment(new Date(event.value)).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		}
	}
}
