import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as _ from 'underscore';

import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';

import { Utils } from '../../utils';

@Component({
	selector: 'app-agent-activity',
	templateUrl: './agent-activity.component.html',
	styleUrls: ['./agent-activity.component.scss']
})
export class AgentActivityComponent implements OnInit {

	ERR_MSG: boolean | string = false;
	TABLE_DATA = [];
	agent_obj = {
		"date": "",
		"id": "",
		"name": "",
		"email": "",
		"login_time": "",
		"logout_time": "",
		"active_hrs": "00:00:00",
		"break_hrs": "00:00:00"
	}
	activity_table: any = new MatTableDataSource();
	activity_table_columns = ["type", "reason", "start_time", "end_time", "duration"];

	constructor(
		private API: ApiService,
		private dialogRef: MatDialogRef<AgentActivityComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private utils: Utils,
		private service: MyserviceService
	) { }

	ngOnInit(): void {
		// console.log('Received data', this.data);
		this.formObject()
	}

	firstActivity_msg() {
		let p = "";
		if (this.data.first_act && this.data.first_act.activity_type) {
			p += `${this.service.renameStatusMessage(this.data.first_act.activity_type)} - [ ${this.utils.utcToLocal(this.data.first_act.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')} ]`
		}
		return p;
	}

	lastActivity_msg() {
		let p = "";
		if (this.data.last_act && this.data.last_act.activity_type) {
			p += `${this.service.renameStatusMessage(this.data.last_act.activity_type)} - [ ${this.utils.utcToLocal(this.data.last_act.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')} ]`;
		}
		return p;
	}

	formObject() {
		this.agent_obj.date = this.data.selectedDate;
		this.agent_obj.name = this.data.agent_name;
		this.agent_obj.id = this.data.agent_id;
		this.agent_obj.email = this.data.agent_email;
		this.agent_obj.login_time = this.data.login_time;
		this.agent_obj.logout_time = this.data.logout_time;
		this.agent_obj.active_hrs = this.data.active_hrs;
		this.agent_obj.break_hrs = this.data.break_hrs;

		if (this.data.data && this.data.data.length) {
			for (var i in this.data.data) {
				this.TABLE_DATA.push({
					"type": this.data.data[i]['type'],
					"start_time": this.utils.utcToLocal(this.data.data[i]['start'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A'),
					"end_time": this.utils.utcToLocal(this.data.data[i]['end'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A'),
					"duration": this.utils.convertSectoHMS(this.data.data[i]['duration']),
					"reason": this.data.data[i]['reason']
				})
			}
			this.prepareTableData(this.TABLE_DATA);
		} else {
			this.ERR_MSG = "No data found";
		}
	}

	closeWindow() {
		this.dialogRef.close();
	}

	prepareTableData(data) {
		this.activity_table = new MatTableDataSource(data);
	}

	exportExcel() {
		let data = this.activity_table.data;
		// console.log('printing data', data);
		if (!data || !data.length) {
			alert('No data found for file download');
			return;
		}
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Activity Report');
		worksheet.addRow(['Type', 'Start Time', 'End Time', 'Duration', 'Reason']);
		for (var i in data) {
			worksheet.addRow(_.values(data[i]));
		}
		workbook.xlsx.writeBuffer().then((buff_data) => {
			let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, this.agent_obj.email+'_Actiity_Report.xlsx');
		})
	}
}
