import {
    Invitation,
    Inviter,
    InviterOptions,
    Referral,
    Registerer,
    RegistererOptions,
    Session,
    SessionState,
    UserAgent,
    UserAgentOptions,
    InvitationAcceptOptions
} from "../../../assets/sip.js";

import * as moment from "moment";


export class SIPConnection {
    public static connection_status : boolean = false;
    public static sip_connected : boolean = false;
    public static call_terminated : boolean = false;
    public static call_answered : boolean = false;
    public static call_disconnected : boolean = false;
    public static SIP_USER_INFO : any;
	public static incomingSession: Session = {};
    public static userAgent: any = {};
    public stateSettings: any;
    checkC2CExitForSIP: any;
    callStatus:any;
    public static callTerminateStatus:any;
    public static SIP_IS_PROMO : boolean = false;
    constructor(SIP_USER, IS_PROMO) {
        SIPConnection.SIP_USER_INFO = SIP_USER;
        SIPConnection.SIP_IS_PROMO = IS_PROMO;
        this.stateSettings = SIP_USER.myservice.get_stage_settings();
        function test_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.sip_connected){
                    let status = SIPConnection.userAgent.isConnected();
                   // console.log("SIP Connection Status : ",status);
                    if(status){
                        SIPConnection.connection_status = true;
                        test_sip_connection(1000*5);
                    }else{
                        SIPConnection.connection_status = false;
                    }
                }else{
                    test_sip_connection(1000*5);
                }
            },timeout);
        }
        function check_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.connection_status){
                    check_sip_connection(1000*10);
                }else{
                    SIPConnection.createConnection(SIP_USER);
                    test_sip_connection(1000*10);
                    check_sip_connection(1000*60);
                }
            },timeout);
        }
        // check_sip_connection(1000*1);
        SIPConnection.createConnection(SIP_USER);
    }
    public static createConnection(SIP_USER){

        console.log('createConnection', SIP_USER);
        let sip_server = 'wss://'+SIP_USER.sip_domain;
        let transportOptions = {
            server: sip_server,
            maxReconnectionAttempts: 10,
            reconnectionTimeout:10,
            traceSip: true
        };
        let uri;
        let verifySip_Passord, verifySIP_auth;
        if(SIPConnection.SIP_IS_PROMO){
            uri = UserAgent.makeURI("sip:" + SIP_USER.sip_user_promo);
            verifySip_Passord = SIP_USER.sip_password_promo;
            verifySIP_auth = SIP_USER.sip_auth_promo;

        }else{
            uri = UserAgent.makeURI("sip:" + SIP_USER.sip_user);
            verifySip_Passord = SIP_USER.sip_password;
            verifySIP_auth = SIP_USER.sip_auth;
        }
        const userAgentOptions: UserAgentOptions = {
            authorizationPassword: verifySip_Passord,
            authorizationUsername: verifySIP_auth,
            transportOptions,
            uri,
			log: { builtinEnabled: false }
        };
        const userAgent = new UserAgent(userAgentOptions);
        const remoteStream = new MediaStream();
        function setupRemoteMedia(session: Session) {
            console.log('MediaSession', session);
            session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
                if (receiver.track) {
                    remoteStream.addTrack(receiver.track);
                }
            });
            SIP_USER.player.srcObject = remoteStream;
            SIP_USER.player.play();
        }
        userAgent.delegate = {
            onInvite(invitation: Invitation): void {
                // An Invitation is a Session
                SIPConnection.incomingSession = invitation;
                console.log("---SIPConnection.incomingSession--",SIPConnection.incomingSession.state, SIPConnection.incomingSession);
                // Setup incoming session delegate
                SIPConnection.incomingSession.delegate = {
                    // Handle incoming REFER request.
                    onRefer(referral: Referral): void {
                        console.log('delegate is hitting');
                    }
                };

                SIP_USER.subscription = SIP_USER.myservice.getCallStatus().subscribe(obj => {

					SIP_USER.callStatus = obj;
					console.log("sp-log-debug5",obj);
                    console.log("SipConnection :: 6 ");
                });
                const stage_settings = SIP_USER.myservice.get_stage_settings();
                console.log('extra options', stage_settings);
                const auto_pickup = stage_settings?.extraoptions.find(e => e.name == 'auto_answer');
                if(auto_pickup && (auto_pickup.definition == 'on')){
                    console.log('Auto answered not enabled');
                    SIP_USER.myservice.request_enabled_to_answer_call();
                }else{
                    SIP_USER.myservice.sip_message_emitter({duration: 3000, message: 'Ringing on. Call is going to auto answered in 3 seconds'});
                    SIPConnection.auto_accept_incoming_call(3000);
                }
               // Handle incoming session state changes.
                SIPConnection.incomingSession.stateChange.addListener((newState: SessionState) => {
                    console.log('incoming state received', newState, new Date());
                    // return;
                    let updateObj = SIP_USER.myservice.getLastCallStatus() || {};
                    //console.log("sp-debug2", updateObj)
                    console.log("SIPConnection newstate :: ", newState)
                    switch(newState) {
                        case SessionState.Establishing:
                            // Session is establishing.
                            console.log('SIPConnection.state Establishing', newState, new Date())
                            SIPConnection.call_terminated = false;
                            
                            if (this.stateSettings && this.stateSettings.extraoptions) {
                                for (let opt of this.stateSettings.extraoptions) {
                                    if (opt.name == "questionnaire") {
                                        let questions = JSON.parse(opt.definition)
                                        let callFlowJsonSections = [{ title: "", description: "", mandatory: "n", child: questions }];
                                        updateObj.callFlowJsonSections = callFlowJsonSections;
                                    }
                                }
                            }
                            if (updateObj && updateObj.dialing && updateObj.dialing == 3) {
                                updateObj.dialing = undefined;
                                updateObj.dialNum = undefined;
                            }
                            updateObj.status = 'establishing';
                            updateObj.callStart = moment().utc();
                            //console.log("sp-debug3", updateObj)
                            SIP_USER.myservice.updateCallStatus(updateObj);
                            SIP_USER.counter = 0;
                            SIP_USER.dialer_service.update_agent_sip_session({
                                "sip_state": newState,
                                "status": 'establishing',
                                "time_stamp": new Date()
                            })
                            // this.startCallDial();
                            break;
                        case SessionState.Established:
                            console.log('SIPConnection.state Established', newState, new Date())
                            SIPConnection.call_answered = true;
        
                            SIPConnection.call_terminated = false;
                            setupRemoteMedia(invitation);
                            updateObj.status = 'accepted';
                            updateObj.sip_user_connected = true;
                            console.log("sp-debug4", updateObj)
                            SIP_USER.myservice.updateCallStatus(updateObj);
                            SIP_USER.dialer_service.update_agent_sip_session({
                                "sip_state": newState,
                                "status": 'accepted',
                                "time_stamp": new Date()
                            })
                            break;
                        case SessionState.Terminated:
                            console.log("SessionState.Terminated", newState, updateObj, new Date());
                            /* updateObj.status = 'terminated';
                            updateObj.sip_user_connected = true;
                            SIP_USER.myservice.updateCallStatus(updateObj); */
                            if(SIPConnection.call_answered){
                            if(updateObj.status == 'accepted')
                            {
                                SIPConnection.call_terminated = true;
                                updateObj.status ='accepted' ;//'terminated';
                                updateObj.callEnd = moment().utc();
                            }
                            }else{
                            updateObj.status ='terminated' ;//'terminated';
                            updateObj.callEnd = moment().utc();
                            }
                            SIP_USER.dialer_service.update_agent_sip_session({
                                "sip_state": newState,
                                "status": 'terminated',
                                "time_stamp": new Date()
                            })
        
                            break;
                        default:
                            break;
                    }
                })
            }
        };
        
        function SIP_Registration(){
            const registerer = new Registerer(userAgent);
            try{
                SIPConnection.sip_connected = true;
                SIPConnection.userAgent = userAgent;
                userAgent.start().then(() => {
                    registerer.register();
                    console.log('SIP Registration done')
                },(error)=>{
                    console.log("SIP Connection Start Error : ",error);
					SIP_USER.myservice?.DESKTOP_CALL_BUTTON_STATUS.next(-1);
                });
            }catch(error){
                console.log("SIP Connection Start Catch Error : ",error);
				SIP_USER.myservice?.DESKTOP_CALL_BUTTON_STATUS.next(-1);
            }
        }
        SIP_Registration();
    }

    public static reject_incoming_call():void{
        console.log('rejecting incoming call.')
        SIPConnection.incomingSession.reject()
    }
    
    public static accept_incoming_call(): void{
        let constrainsDefault: MediaStreamConstraints = {
            audio: true,
            video: false,
        }
        const options: InvitationAcceptOptions = {
            sessionDescriptionHandlerOptions: {
                constraints: constrainsDefault,
            },
        }
        SIPConnection.incomingSession.accept(options);
    }
    public static auto_accept_incoming_call(timeout){
        setTimeout(()=>{
            this.accept_incoming_call()
        },timeout);
    }
	public static muteConnection() {
		console.log('SIP: muteConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = false;
					console.log('SIP: getAudioTracks set false');
				});
			});
	    }
	}
	public static unMuteConnection() {
		console.log('SIP: unMuteConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = true;
					console.log('SIP: getAudioTracks set true');
				});
			});
	    }
	}
	public static holdConnection() {
		console.log('SIP: holdConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = false;
					console.log('SIP: holdConnection set false');
				});
			});
	    }
	}
	public static unHoldConnection() {
		console.log('SIP: unHoldConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = true;
					console.log('SIP: holdConnection set true');
				});
			});
	    }
	}
	public static endConnection(){
		console.log('SIP: endConnection');
		if (SIPConnection.userAgent.isConnected()) {
			SIPConnection.userAgent.stop().then(() => {
				console.log("SIP Connection User agent stopped");
			},(error)=>{
				console.log("SIP Connection Start Error : ",error);
			});
	    }
	}
    public static recreateConnection(){
        if(SIPConnection.sip_connected){
           // console.log("Reconnecting SIP Connection");
            try{
                SIPConnection.userAgent.reconnect().then(() => {
                    //console.log("Reconnected SIP Connection");
                },(error)=>{
                    //console.log("SIP Re-Connection Error : ",error);
                    // SIPConnection.sip_connected = false;
                });
            }catch(error){
                //console.log("SIP Connection Start Catch Error : ",error);
            }
		} else if (SIPConnection.SIP_USER_INFO && SIPConnection.SIP_USER_INFO.sip_user && (!SIPConnection.sip_connected || !SIPConnection.connection_status)){
           // console.log("Recreating SIP Connection");
            SIPConnection.createConnection(SIPConnection.SIP_USER_INFO);
        }else{
           // console.log("SIP USer Details not exists");
        }
    }
    public static getConnectionStatus(){
        if(SIPConnection.sip_connected){
            let status = SIPConnection.userAgent.isConnected();
            // console.log("SIP Connection Status : ",status);
            if(status){
                SIPConnection.connection_status = true;
            }else{
                SIPConnection.connection_status = false;
            }
            return SIPConnection.connection_status;
        }
    }
    public static reCheckConnection(){
        function test_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.sip_connected){
                    let status = SIPConnection.userAgent.isConnected();
                    //console.log("SIP Connection Status Re-Check : ",status);
                    if(status){
                        SIPConnection.connection_status = true;
                        test_sip_connection(1000*5);
                    }else{
                        SIPConnection.connection_status = false;
                        SIPConnection.recreateConnection();
                        test_sip_connection(1000*10);
                    }
                }
            },timeout);
        }
        test_sip_connection(1000);
    }

}
