import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-onboarding-process-page4',
  templateUrl: './onboarding-process-page4.component.html',
  styleUrls: ['./onboarding-process-page4.component.scss']
})
export class OnboardingProcessPage4Component implements OnInit {
  createdForm:UntypedFormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  constructor(private router:Router,public formbuilder:UntypedFormBuilder,public API :ApiService) { 
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      promotional:false,
      transational: false,
      predictiveDialing:false,
      serverDialing:false,     
      normalDialing:false,
      currentDialingAnswer:[''],
      preferredDialingAnswer:['']
    })
  }

  ngOnInit(): void {
  }
  onNextBtn(createdForm: any){
   // debugger;
    this.messageDisplay="";
    this.showHideMessage = false;

    let _promotional = createdForm.controls["promotional"].value;
    let _transational = createdForm.controls["transational"].value;
    let _predictiveDialing = createdForm.controls["predictiveDialing"].value;
    let _serverDialing = createdForm.controls["serverDialing"].value;
    let _normalDialing=  createdForm.controls["normalDialing"].value;
    let _currentDialingAnswer=  createdForm.controls["currentDialingAnswer"].value;
    let _preferredDialingAnswer=  createdForm.controls["preferredDialingAnswer"].value;
    if (_promotional == false && _transational ==false) {
      this.messageDisplay = "Please select atleast one type of lines*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    let _obj = {
      "promotional": _promotional,
      "transational": _transational,
      "predictiveDialing": _predictiveDialing,
      "serverDialing": _serverDialing,
      "normalDialing": _normalDialing,
      "currentDialingAnswer":_currentDialingAnswer,
      "preferredDialingAnswer": _preferredDialingAnswer 
    }
    this.messageDisplay = "Good.., Now you are going to next page..!!";
    this.msgColor = "green";
    this.showHideMessage = true;
    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>{
      this.router.navigateByUrl('/onboarding_process_page5')
    },3000)
      }
}
