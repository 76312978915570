<mat-dialog-content>
	<div class="row align-items-center">
			<div class="col-8">
			<h4 class="titlehead vThdx1 mb-0" style="color: var(--primary) !important;">All Agents List</h4>
		</div>
		<div class="col-4">
			<mat-form-field class="w-100 mt-3" appearance="outline" >
				<mat-label>Search</mat-label>
				<input matInput #searchInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
				<mat-icon matSuffix class="searchicon">search</mat-icon>
			</mat-form-field>
		</div>
	</div>

	<div class="table-wrapper">
		<mat-table [dataSource]="dataSource" class="vkTableAgent">
			<ng-container matColumnDef="select">
				<mat-header-cell *matHeaderCellDef> Select </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<!-- <mat-checkbox (click)="adding_agent(element)"></mat-checkbox> -->
					<mat-checkbox [(ngModel)]="element.to_selected"
						(change)="adding_agent(element, $event)"></mat-checkbox>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agentID">
				<mat-header-cell *matHeaderCellDef> Agent ID </mat-header-cell>
				<mat-cell *matCellDef="let element" style="color: #7094ba !important;font-weight: 500;"> {{element.id}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agentName">
				<mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="email">
				<mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="currentCampaign">
				<mat-header-cell *matHeaderCellDef> Current Campaign </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.active_campaign}} </mat-cell>
			</ng-container>


			<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="border-bottom-color: #fff;" class="py-3"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<div class="d-flex justify-content-end gap-3">
		<button mat-raised-button (click)="cancelBtn()">
			<i class="fa fa-times" aria-hidden="true"></i> CANCEL
		</button>
		<button mat-raised-button color="primary" (click)="addAgentBtn()">
			<mat-icon class="mr-1">add</mat-icon> ADD AGENT
		</button>
	</div>
</mat-dialog-actions>

<div id="cover-spin" *ngIf="currentLoader"></div>