<div class="p-3">
	<div class="text-center mb-3">
		<h3>Stop Auto Dialling</h3>
		<p class="mb-0">Do you want to stop receiving calls from Server?</p>
	</div>

	<div class="d-flex justify-content-center mb-3">
		<mat-form-field style="width:250px" appearance="outline">
			<mat-label>Break Reason</mat-label>
			<mat-select placeholder="Select" (selectionChange)="onOptionsSelected($event.value)">
				<mat-option *ngFor="let sta of stagesetting" [value]="sta">
					{{sta}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="d-flex justify-content-end gap-3">
		<button mat-raised-button (click)="closeDialog()">
			<mat-icon>cancel</mat-icon>
			CANCEL</button>
		<button mat-raised-button (click)="submitbtn()">
			<mat-icon>check</mat-icon>
			SUBMIT</button>
	</div>
</div>