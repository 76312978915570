import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'

export interface SNACKBAR_MESSAGE {
    duration: number,
    message: string
}

@Injectable({
    providedIn: 'root'
})

export class ToastMessageService {
    constructor(private snackbar: MatSnackBar) { }

    open_message(obj: SNACKBAR_MESSAGE): void {
        this.snackbar.open(obj.message, 'Close', { duration: obj.duration })
    }
}