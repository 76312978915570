import { Component, OnInit } from '@angular/core';
import { ProfileAttendanceDialogComponent } from '../profile-attendance-dialog/profile-attendance-dialog.component';
import { ProfileLeaveDialogComponent } from '../profile-leave-dialog/profile-leave-dialog.component';
import { ProfileResignationDialogComponent } from '../profile-resignation-dialog/profile-resignation-dialog.component';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';

@Component({
  selector: 'app-profile-plugin',
  templateUrl: './profile-plugin.component.html',
  styleUrls: ['./profile-plugin.component.scss']
})
export class ProfilePluginComponent implements OnInit {
	showHideAttendance:boolean=false;
	showHideLeave:boolean=false;
	showHideResignation:boolean=false;
  constructor(private dialog: MatDialog,private router:Router) { }

  ngOnInit() {
  }
  profileRadioChange(val:any){
	  if(val=="ATTENDANCE"){
		this.showHideAttendance=true;
		this.showHideLeave=false;
		this.showHideResignation=false;
	  }
	  if(val=="LEAVE"){
		this.showHideAttendance=false;
		this.showHideLeave=true;
		this.showHideResignation=false;
	}
	if(val=="RESIGNATION"){
		this.showHideAttendance=false;
		this.showHideLeave=false;
		this.showHideResignation=true;
	
	}

  }
//   attendaceClick(){
	  
//     const dialogRef = this.dialog.open(ProfileAttendanceDialogComponent, {
// 			width: '40%',
// 			disableClose: true,
// 			data: ''
// 		});
// 		dialogRef.afterClosed().subscribe(result => {
// 		console.log("Dialog is closed");
// 		});
//   }
//   leaveClick(){
//     const dialogRef = this.dialog.open(ProfileLeaveDialogComponent, {
// 			width: '40%',
// 			disableClose: true,
// 			data: ''
// 		});
// 		dialogRef.afterClosed().subscribe(result => {
// 		console.log("Dialog is closed");
// 		});
//   }

//   resignationClick(){
//     const dialogRef = this.dialog.open(ProfileResignationDialogComponent, {
// 			width: '40%',
// 			disableClose: true,
// 			data: ''
// 		});
// 		dialogRef.afterClosed().subscribe(result => {
// 		console.log("Dialog is closed");
// 		});
//   }
}
