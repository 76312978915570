import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../utils';

import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { CampaignService } from '../../campaign.service';
import { MatDialog } from '@angular/material/dialog';import { Router } from '@angular/router';
import { CampaignDetailsTransferAgentDataDialogComponent } from '../campaign-details-transfer-agent-data-dialog/campaign-details-transfer-agent-data-dialog.component';
import { CampaignDetailsAddAgentDialogComponent } from '../campaign-details-add-agent-dialog/campaign-details-add-agent-dialog.component';
import { DashboardAddmoreDialogComponent } from '../../dashboard-addmore-dialog/dashboard-addmore-dialog.component';

@Component({
	selector: 'app-campaign-details-view-page',
	templateUrl: './campaign-details-view-page.component.html',
	styleUrls: ['./campaign-details-view-page.component.scss']
})
export class CampaignDetailsViewPageComponent implements OnInit {
	// currentTitle = "campaignDetails";
	currentTitle;
	displayedColumns: string[] = ['agentID', 'agentName', 'email', 'currentCampaign'/*, 'status'*/, 'dataValue', 'dialled', 'contacted', 'followups', 'activeDeactive'];
	displayedColumnsLogs: string[] = ['name', 'email', 'activity_type', 'created_at'];
	campaign_logs = {
		logspanelOpenState : false,
		refresh_status : false,
		data: []
	}

	is_minimized: boolean = false;
	dataSource = new MatTableDataSource();
	campaigndetails_data: any;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	activeDeactiveModel: any;
	color = 'sliver';
	checked: boolean;
	disabled = false;
	campaign_status: boolean;
	campaign_data_details = {};
	campaigndetails_status_uploaded: boolean = false;
	campaigndetails_status_assign: boolean = true;
	agent_details: any;
	api_end_point: any;
	user: any = this.myservice.get_loggedin_user();
	agent_switch_settings = this.myservice.getSettings('campaign_controls');

	constructor(private dialog: MatDialog,
		private API: ApiService,
		private utils: Utils,
		private myservice: MyserviceService,
		private router: Router,
		public campaign_service: CampaignService,
		private _snackBar: MatSnackBar) { }

	ngOnInit() {
		//this.getCampaignAgentsList();
		this.campaigndetail();
		this.api_end_point = this.myservice.get_API_End_Point();
		//console.log(this.campaigndetails_data.active_status + "---" + this.campaigndetails_data.status);
		if (this.campaigndetails_data.active_status == 1 && this.campaigndetails_data.status == "assigned") {
			this.campaign_status = true;
		}
		if(this.check_log_visibility()){
			this.refresh_campaign_logs()
		}
		//this.campaignStatusChange(this.campaigndetails_data.campaign_status, this.campaigndetails_data);
	}
	backToCampaignDashboard() {
		if (this.currentTitle == "central-campaign") {
			this.router.navigateByUrl('/central-campaign');
		} else {
			this.router.navigateByUrl('/campaignV2');
		}
	}

	applySearchFilter(val: string) {
		this.dataSource.filter = val.toLowerCase().trim()
	}
	setTogeleValue(element: any, event: any) {
		if (event.checked) {
			//	console.log("--agent-details---" + element.active_status);
			element.active_status = true;
			//console.log("agent status turn on");
			let obj = {
				"active_list": [],
				"active_agents_list": []
			}
			//console.log(element.email);
			//obj.active_agents_list.push();
			obj.active_list.push(this.campaigndetails_data.campaign_name);
			obj.active_agents_list.push(element.email);
			this.API.campaignSwitch(obj, this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.success) {
						//console.log(res.success);
						this._snackBar.open("successfully done", "close");
					}
				}, (err: any) => {
					//console.log("--" + err);
					this._snackBar.open(err, "close");
				}
			)
		} else {
			//	console.log("--agent-details---" + element.active_status);
			//element.activeDeactive = "OFF";
			element.active_status = false;
			//console.log("agent status turn off");
			let obj1 = {
				"inactive_list": [],
				"inactive_agents_list": []
			}
			//console.log(element.email);
			obj1.inactive_list.push(this.campaigndetails_data.campaign_name);
			obj1.inactive_agents_list.push(element.email);
			this.API.campaignSwitch(obj1, this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.success) {
						//	console.log(res.success);
						this._snackBar.open("successfully done", "close");
					}
				}, (err: any) => {
					//	console.log("--" + err);
					this._snackBar.open(err, "close");
				}
			)
		}

	}
	dataTransferClick() {
		//console.log(this.dataSource.data);
		//console.log(this.campaigndetails_data);
		if (this.campaigndetails_data.priority == 0) {

			var obj = {
				agent: this.dataSource.data,
				campaign_details: this.campaigndetails_data
			}
			const dialogRef = this.dialog.open(CampaignDetailsTransferAgentDataDialogComponent, {
				minWidth: '1200px',
				width: '1200px',
				disableClose: true,
				data: obj
			});
			dialogRef.afterClosed().subscribe(result => {
				console.log("Dialog is closed" + result);
			});

		}
		else {
			setTimeout(() => {
				this._snackBar.open("This feature is only enable for Normal Dial","close");
			}, 3000);
		}

	}

	limit_string(str: string) {
		if (str) {
			return this.utils.limitString(str);
		}
		return '';
	}
	addAgentClick() {
		var obj = {
			agent: this.dataSource.data,
			campaign_details: this.campaigndetails_data
		}

		const dialogRef = this.dialog.open(CampaignDetailsAddAgentDialogComponent, {
			maxWidth: '100vw',
			width: '1000px',
			height: '550px',
			disableClose: true,
			data: obj,
			panelClass: 'custom-dialog-container'
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log("Dialog is closed" + result);
		});
		
	}
	bindWithDefaultValue(val:any, default_val:any){
		try{
			if (val){
				return val;
			}
		}catch(err){
			console.log('err in bind with default value', err)
		}
		return default_val
	}
	campaigndetail() {
		let campaign_agent = []
		this.campaigndetails_data = this.myservice.get_campaign_detail();
		if (this.campaigndetails_data.pagename) {
			this.currentTitle = this.campaigndetails_data.pagename;
		}
		try{
			if(this.campaigndetails_data.details){
				this.campaign_data_details = JSON.parse(this.campaigndetails_data.details);
			}
		}catch(err){
			console.log('Error in the campaign data details getting')
			this.campaign_data_details = {}
		}
		for (var i in this.campaigndetails_data.campaign_agent_list) {
			campaign_agent.push(this.campaigndetails_data.campaign_agent_list[i]);
		}
		if (this.campaigndetails_data.agentdata) {
			this.agent_details = Object.values(this.campaigndetails_data.agentdata);
		} else {
			this.agent_details = campaign_agent;
		}
		this.dataSource = new MatTableDataSource(this.agent_details);
		this.dataSource.paginator = this.paginator;

	}

	addagent(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));

		data.dial_mode = 'normal'
		if (data.priority == 1) {
			data.dial_mode = 'server'
		}
		if (data.priority == 2) {
			data.dial_mode = 'predictive'
		}
		if (data.priority == 3) {
			data.dial_mode = 'ivr'
		}
		if (data.priority == 4) {
			data.dial_mode = 'divr'
		}
		let obj_topost = {
			'campaign': data.campaign,
			'center_id': data.user_id,
			'agents_list': data.agents_list,
			'priority': data.dial_mode
		}

		//debugger;
		this.API.getCampaignAgentsData(obj_topost).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				}
				if (res.success) {
					//console.log('printing success', res.success);
					data.agentdata = res.success;

					let _element = {
						"_element": data,
					}
					// //'this.dataSource[0]'
					// const dialogRef = this.dialog.open(DashboardAddagentComponent, {
					// 	width: '60%',
					// 	disableClose: true,
					// 	data: _element
					// });

					// dialogRef.afterClosed().subscribe(result => {
					// 	//console.log(result);
					// });
				}
			})


	}
	campaigndetails_statuscheck() {
		if (this.campaigndetails_data.status == 'uploaded') {
			this.campaigndetails_status_uploaded = true;
			this.campaigndetails_status_assign = false;
		} else {
			this.campaigndetails_status_assign = true;
			this.campaigndetails_status_uploaded = false;
		}
	}

	campaignStatusChange(e, element) {
		//	console.log('curent status', element, e.checked);
		/* console.log('event e', e);
		console.log('curent status', prev_status, e.checked); */
		this.campaigndetails_statuscheck()
		//console.log('curent status', element, e.checked);
		/* console.log('event e', e);
		console.log('curent status', prev_status, e.checked); */
		const prev_status = !e.checked;
		element.disabled = true;
		let obj = {};
		if (e.checked) {
			obj['active_list'] = [element.campaign_name];
			//console.log("the campaign_status on	");
			this.campaign_status = e.checked;
		} else {
			obj['inactive_list'] = [element.campaign_name];
			//console.log("the campaign_status off");
			this.campaign_status = e.checked;
		}

		this.API.campaignSwitch(obj, this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
				//	alert('Camapign Switch Failed');
				e.checked = prev_status;
				element.disabled = false;

				// this.messageAlert = "Camapign Switch Failed";
				this._snackBar.open("Camapign Switch Failed", "close")
				// setTimeout(()=>{
				// },6000)
			} else {
				//alert('Campaign Switch Success.');
				this._snackBar.open("Campaign Switch Success.", "close")
				this.router.navigate(['/campaignV2']);
				setTimeout(() => {
					this.router.navigate(['/campaignV2']);
				}, 6000)
			}
		})
	}
	
	downloadCmail_report(obj: any, unique_data?: boolean) {
		// console.log('printing obj', obj, this.user.id);
		obj.showLoader = true;
		var hide_number = false;
		if (this.user.id != obj.user_id) {
			var hide_customer_number = this.campaign_service.hide_customer_number_access();
			if(hide_customer_number){
				hide_number = true;
			}
		}
		let api_obj: any = {
			start_date: obj.created_at,
			end_date: this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString(),
			campaign_name: obj.campaign_name
		}
		if (unique_data) {
			api_obj.unique_data = unique_data
		}
		if (hide_number) {
			api_obj.hcd = hide_number
		}
		this.API.downloadCmailsReport(api_obj, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					//alert(res.error)
					this._snackBar.open(res.error, "close");
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						//alert('File URL is missing');
						this._snackBar.open("File URL is missing", "close");
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call');

			}
		)
	}

	downloadCustomerStatusReport(obj: any) {
		// debugger;
		// console.log('printing obj', obj);
		var hide_number = false;
		if (this.user.id != obj.user_id) {
			var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
			// console.log('report settings', reportSettings);
			if (reportSettings) {
				if (reportSettings.definition == 'true') {
					hide_number = true;
				}
			}
		}
		// return
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;

		this.API.downloadCustomerStatusReport(this.api_end_point, start_date, end_date, campaign).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call')
			}
		)
		// debugger;
	}

	downloadCallStatusReport(obj: any) {
		// debugger;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;

		this.API.downloadCallStatusReport(start_date, end_date, campaign, this.api_end_point).subscribe(
			(res: any) => {
				obj.showLoader = false;
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {

							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call');
				obj.showLoader = false;
			}
		)
	}
	addmore(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));
		/*var obj={
			forNew:"add",
			audit_client_id:this.API._selectClient.id
		}*/
		let _element = {
			"_element": data,
		}
		//'this.dataSource[0]'
		const dialogRef = this.dialog.open(DashboardAddmoreDialogComponent, {
			width: '50%',
			disableClose: true,
			data: _element
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log(result);
		});
	}
	downloadCampaignStatusReport(obj: any, with_agents?:boolean) {
		// debugger;
		let campaign_data = obj;
		campaign_data.showLoader = true;
		let loc: any = {
			'campaign_id': obj['id'],
			'offset': 330
		}
		//if (this.user.id != obj.user_id) {
			var hide_customer_number = this.campaign_service.hide_customer_number_access();
			if(hide_customer_number){
				loc.hcd = true;
			}
		//}
		if(with_agents){
			loc.agents_list = this.myservice.getAllIds('user', 'id')
		}
		if((this.user.id == obj.user_id) || this.campaign_service.having_full_access()){
			if (loc.agents_list) delete loc.agents_list
		}
		this.API.downloadCampaignStatusReport(loc, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				campaign_data.showLoader = false;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
						() => {
							//campaign_data.showLoader = false;
						}
					)
				} else {
					//	alert(res.errors)
					this._snackBar.open(res.errors, "close");
				}
			}
		)
	}

	agentStatusChange(e, element) {
		//console.log(e + "---" + element);
		if (e.checked == true) {
			//	console.log("agent status turn on");
			let obj = {
				"active_list": [],
				"active_agents_list": []
			}
			//	console.log(element.email);
			//obj.active_agents_list.push();
			return;
			this.API.campaignSwitch(obj, this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.success) {
						console.log(res.success);
						this._snackBar.open("successfully done", "close");
					}
				}, (err: any) => {
					console.log("--" + err);
					this._snackBar.open(err, "close");
				}
			)
		}
		else if (e.checked == false) {
			//	console.log("agent status turn off");
			let obj1 = {
				"inactive_list": [],
				"inactive_agents_list": []
			}
			//console.log(element.email);
			return;
			this.API.campaignSwitch(obj1, this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.success) {
						console.log(res.success);
						this._snackBar.open("successfully done", "close");
					}
				}, (err: any) => {
					console.log("--" + err);
					this._snackBar.open(err, "close");
				}
			)
		}

	}
	getdatatransfer(id) {
		// console.log(id.priority + "---" + JSON.stringify(id));
		if (!(id.priority == 0 && id.active_status == 0)) {
			// console.log(id.priority == 0 && id.active_status == 0);
			this._snackBar.open("This Feature only enable for Normal Dial", "close");
		}
		//(id.priority == 0 && id.active_status == 0) ? false : true;
		//return this.checkeddata.includes(id) ? true : false;
	}
	
	curCampainViewData(source, size) {
		if(source && source.length){
			let _data = source.length > size ? source.slice(0, size - 1) + "…" : source
			return _data;
		}
		return '';
	}

	agent_switch_status(){
		if(this.campaigndetails_data.active_status == 0){
			return true
		}
		if(this.campaigndetails_data.user_id == this.user.id){
			return false;
		}
		if(this.campaigndetails_data.priority == 2){
			return true;
		}
		return !this.campaign_service.check_accessibility('agent_switch');
	}
	data_transfer_status(){
		if(this.campaigndetails_data.active_status == 0){
			return true
		}
		/* if(this.campaigndetails_data.user_id == this.user.id){
			return false;
		} */
		if(this.campaigndetails_data.priority == 2){
			return true;
		}
		return !this.campaign_service.check_accessibility('data_transfer');
	}
	add_agent_status(){
		if(this.campaigndetails_data.active_status == 0){
			return true
		}
		return !this.campaign_service.check_accessibility('agent_add');
	}
	visibility_my_agents_report(obj){
		let campaign_full_access = this.campaign_service.having_full_access()

		if((obj.user_id != this.user.id) && !campaign_full_access){
			return true
		}
		return false
	}
	downloadDataSheet(obj: any) {
		obj.showLoader = true;
		this.API.downloadReportFromUrl(obj.url, true, this.api_end_point).then(
			() => {
				obj.showLoader = false;
			}
		)
	}
	check_log_visibility():boolean{
		if(this.user.id == this.campaigndetails_data.user_id){
			return true;
		}
		return this.campaign_service.having_full_access();
	}
	getLocalTime(obj:any) {
		return this.utils.utcToLocal(obj.created_at, 'YYYY-MM-DDTHH:mm:ss', 'MM/DD/YY hh:mm A')
	}

	refresh_campaign_logs(){
		this.campaign_logs.refresh_status = true;
		this.API.getCampaignLogs(this.campaigndetails_data.id, this.api_end_point).subscribe((res:any)=>{
			// console.log('res', res)
			this.campaign_logs.refresh_status = false;
			if(res.success){
				this.campaign_logs.data = res.success;
			}else{
				this._snackBar.open(res.errors, "close");
			}
		}, (err)=>{
			this.campaign_logs.refresh_status = false;
			this._snackBar.open('Unexpected Error', "close");
		})
	}

	onMinimizedChange(status: boolean) {
		this.is_minimized = status;
	}
}


