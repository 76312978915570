<div class="container-fluid p-3">
	<div class="row">
		<div class="col-12">
			<h4 class="text-primary mb-4">Call Recordings</h4>
			
			<div class="row mb-4">
				<div class="col-12">
					<mat-dialog-content>
						<div class="d-flex align-items-center">
							<span class="me-2">{{ state?.readableCurrentTime || '00:00' }}</span>
							<mat-slider class="w-100" 
								[min]="0" 
								[max]="state?.duration || 0" 
								[step]="1" 
								[value]="state?.currentTime || 0"
								(input)="onSliderChange($event)"
								[disabled]="state?.error || !state?.duration">
								<input matSliderThumb>
							</mat-slider>
							<span class="ms-2">{{ state?.readableDuration || '00:00' }}</span>
						</div>
						<div>
							<mat-dialog-actions class="d-flex justify-content-center">
								<button mat-icon-button matTooltip="Backward 10s" (click)="seekBackward()">
									<mat-icon class="color-primary">replay_10</mat-icon>
								</button>
								<button mat-icon-button [matTooltip]="state?.playing ? 'Pause' : 'Play'" (click)="togglePlay()">
									<mat-icon class="color-primary">{{state?.playing ? 'pause' : 'play_arrow'}}</mat-icon>
								</button>
								<button mat-icon-button matTooltip="Forward 10s" (click)="seekForward()">
									<mat-icon class="color-primary">forward_10</mat-icon>
								</button>
							</mat-dialog-actions>
						</div>
					</mat-dialog-content>
				</div>
			</div>

			

			<div class="row">
				<div class="col-12 text-end">
					<button mat-raised-button (click)="close()" class="btn-close-custom">
						<i class="fa fa-close me-2" aria-hidden="true"></i>
						CLOSE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>