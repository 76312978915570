import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MyserviceService} from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';//import { ViewDetailsDialog1Component } from '../view-details-dialog1/view-details-dialog1.component';
import { DatePipe } from '@angular/common';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import Exporting from 'highcharts/modules/exporting';
import funnel from 'highcharts/modules/funnel';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router'
Exporting(Highcharts);
funnel(Highcharts);
interface CampaignStatusNode {
  name: any;
  val:any;
  children?: CampaignStatusNode[];
}
const _funnelcolors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];

@Component({
  selector: 'app-campaign-funnel-dashboard',
  templateUrl: './campaign-funnel-dashboard.component.html',
  styleUrls: ['./campaign-funnel-dashboard.component.scss']
})
export class CampaignFunnelDashboardComponent implements OnInit {

  customer_name:string;
  project_name:string = "";
  projects:any;
  selected:any;
  selectedChannel:any;
  utils = new Utils();
  selectedDayWise="Today";
  selectedCampaign ="All Campaign";
  selectedProcess ="processFlow1";
  showHideDateRange:boolean=false;
  funnelData:any = [];
  funnelConeData:any = [];
  selected_time_range: string;
  startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
  endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	// startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	// endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);

  _campainStatusList:any=[];
  treeControl = new NestedTreeControl<CampaignStatusNode>(node => node.children);
  dataSourceTree = new MatTreeNestedDataSource<CampaignStatusNode>();
  hasChild:any = (_: number, node: CampaignStatusNode) => !!node.children && node.children.length > 0;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date())
  });
  _rangeDateStartEnd:any;
  ON_OFF:any="OFF";
  _funnelcontainer:any;

  shaowHideChartinNormal:boolean=true;
  shaowHideChartinFunnel:boolean=false;

  currentLoader:boolean=false;
  api_end_point:any;
currentTitle = 'funnel-dashboard';
currentUrl:any;
  constructor(private _service:MyserviceService, private API: ApiService,private dialog: MatDialog,private router: Router) {

  }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    this.selected="";
    this.selectedChannel="";
    this._service.set_current_time_range(this.selectedDayWise);
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = this._service.get_loggedin_user().project;
      this._service.set_current_project(this.projects);
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
      this.getFunnelDashboard()
    }



  }

  getFunnelDashboard(){
    this.currentLoader=true;
    this.selected_time_range = this._service.get_current_time_range();

    this.API.getFunnelDashboard(this.customer_name, this.project_name, this.selected_time_range, this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter), this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).subscribe((data: any) => {
      if (data.success && data.success.funnel_data) {
        this.currentLoader=false;
        this.funnelData = [];
        this.funnelConeData = [];
        const funnelData = data.success && data.success.funnel_data;
        const summaryData = data.success && data.success.summary_data;
        if (funnelData && funnelData.length) {
          this.funnelConeData.push(['Total Database',0]);
          this.funnelData.push({stage: 'Total Database', value: 0, value_perc: 100});

          for (let obj of funnelData) {
            this.funnelData[0].value += obj.total;
            this.funnelConeData[0][1]+=obj.total;
            this.funnelConeData.push([obj.stage,obj.total])
          }

          for (let obj2 of funnelData) {
            this.funnelData.push({
              stage: obj2.stage,
              value: obj2.total,
              value_perc: this.calculatePercBy(obj2.total, this.funnelConeData[0][1])
            });
          }
         // console.log('Funnel_Data',this.funnelData);
        }
        if (summaryData) {
          this.getCampaignStatus(summaryData)
        }
      }
    });
  }

  calculatePercBy(num1, num2) {
    if (num1 == 0 && num2 == 0) {
      return 0;
    }
    var value: any = (num1 / num2) * 100;
    value = value.toFixed(1);
    if(value){
      value = parseFloat(value)
    }
    return value
  }

  getCampaignStatus(summary_data){
   // console.log('getCampaignStatus data',summary_data);
    var treeData = summary_data;
    const TREE_DATA: CampaignStatusNode[] = treeData;
    this._campainStatusList=TREE_DATA;
    this.dataSourceTree.data = this._campainStatusList;
  }

  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }
  OnChangeDayTime(val:any){
    this.showHideDateRange=false;
    this.selectedDayWise=val;
    this._service.set_current_time_range(this.selectedDayWise);
    if(val=="Custom"){
      this.showHideDateRange=true;
    }else{
      this.getFunnelDashboard();
    }

  }
  valueChanged(){
    //debugger;
    //console.log(this.range.value);
    this._rangeDateStartEnd=this.range.value;
   // console.log(this._rangeDateStartEnd);
    if (this.range.value.start){
      this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, this.range.value.start);
    }
    if (this.range.value.end != null) {
      this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, this.range.value.end);
      this.getFunnelDashboard();
    }

  }
  funnelOnOffChange(event:any){
    this.shaowHideChartinNormal=false;
    this.shaowHideChartinFunnel=false;

    if(event.value=="ON"){
      this.ON_OFF="ON";
      this.shaowHideChartinNormal=false;
      this.shaowHideChartinFunnel=true;
      this.getFunulStackChart();
    }
    else if(event.value=="OFF"){
      this.ON_OFF="OFF";
      this.shaowHideChartinNormal=true;
      this.shaowHideChartinFunnel=false;
    }



    // if(event.checked==true){
    //   this.ON_OFF="ON";
    //   this.shaowHideChartinNormal=false;
    //   this.shaowHideChartinFunnel=true;
    //   this.getFunulStackChart();
    // }
    // else if(event.checked==false){
    //   this.ON_OFF="OFF";
    //   this.shaowHideChartinNormal=true;
    //   this.shaowHideChartinFunnel=false;
    // }

  }

  getFunulStackChart(){
    this._funnelcontainer = new Chart({
      chart : {
        type : 'funnel'
      },
      title : {
        text : ''
      },
      credits : {
        enabled : false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                inside: false ,
                format: '<p><b>{point.name}:{point.y:,.0f}</b></p>',
            }
        }
    },
    legend: {
        enabled: true
    },
      series : [{
        neckWidth: '18%',
        neckHeight: '25%',
        width: '50%',
        center: ['30%', '40%'],
        type:undefined,
        name: 'Record',
        colors: _funnelcolors,
        data: this.funnelConeData
      }]
    });
  }
  refreshBtn(){
    this.getFunnelDashboard();
  }
}



