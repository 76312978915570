<div class="row pdt">
	<div class="col-md-12 col-sm-12 col-xs-12">
		
		<mat-card>
			
			<h2 class="d-head">REPORTS<img src="../assets/underline.svg" class="linebelow"> </h2>

			
			<div class="searchFix">
				<span style="margin-right: 20px;">
				
					<mat-form-field style="width:110px">
						<input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
							(click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)"
							[(ngModel)]="callStartDate">
						<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						<mat-datepicker #frompicker></mat-datepicker>
					</mat-form-field>
					<mat-form-field class="ml-3" style="width:110px">
						<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
							(click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)"
							[(ngModel)]="callEndDate">
						<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
						<mat-datepicker #topicker></mat-datepicker>
					</mat-form-field>
				
					<button mat-raised-button class="btnAdd ml-3" (click)="refreshData(true)">Go</button>
					<!--*ngIf="!isToday"-->
				
					<!-- <button mat-raised-button class="btnAdd ml-3" (click)="refreshDatafortoday()">Today</button> -->
				</span>
				<span> <button mat-raised-button class="submitbtn" (click)="downLoadReport()">Download
						Report</button>&nbsp;&nbsp; </span>

				<mat-form-field style="font-size: 12px;" appearance="outline">
					<input matInput #searchInput [(ngModel)]="searchFilter" (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
					<i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
				</mat-form-field>
			</div>
			<br>
			<div class="mt-2">
                <mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" (selectionChange)="changeFilter($event.value)" style="width:150px;" placeholder="Select"  [(value)]="selectedFilter">
                  <mat-option value="All">All</mat-option>
                    <mat-option value="Regular">Regular</mat-option>
                    <mat-option value="OJT">OJT</mat-option>
                </mat-select>
            </div>
			<br>
			<div>
				<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>NAME </th>
						<td mat-cell *matCellDef="let element">{{element.name}} </td>
					</ng-container>

					<ng-container matColumnDef="user_id">
						<th mat-header-cell *matHeaderCellDef>User Id </th>
						<td mat-cell *matCellDef="let element">{{element.agent_reference_id || element.user_id}} </td>
					</ng-container>

					<ng-container matColumnDef="batch_code">
						<th mat-header-cell *matHeaderCellDef>Batch </th>
						<td mat-cell *matCellDef="let element">{{element.batch_code}} </td>
					</ng-container>
					<ng-container matColumnDef="training_type">
						<th mat-header-cell *matHeaderCellDef>Type </th>
						<td mat-cell *matCellDef="let element">{{element.training_type}} </td>
					</ng-container>

					<ng-container matColumnDef="training_days">
						<th mat-header-cell *matHeaderCellDef> Days</th>
						<td mat-cell *matCellDef="let element"> {{element.training_days}} </td>
					</ng-container>

					<ng-container matColumnDef="ojt_days">
						<th mat-header-cell *matHeaderCellDef>Attendance </th>
						<td mat-cell *matCellDef="let element">{{element.attendance}} </td>
					</ng-container>

					<ng-container matColumnDef="total_call_in_ojt">
						<th mat-header-cell *matHeaderCellDef> CALLS ATTENDED OJT</th>
						<td mat-cell *matCellDef="let element"> {{element.total_call_in_ojt}} </td>
					</ng-container>
					<ng-container matColumnDef="audit_score">
						<th mat-header-cell *matHeaderCellDef> AUDIT SCORE</th>
						<td mat-cell *matCellDef="let element"> {{element.training_type == 'Regular' ?
							element.mock_audit_score : element.training_type == 'OJT' ? element.ojt_audit_score :
							element.audit_score}} </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
				<mat-paginator [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
				</mat-paginator>
			</div>
		</mat-card>
	</div>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>