
  <div class="dialog-container" [class.upload-mode]="viewManagementTeamData?.upload">
    <div class="dialog-header">
      <h2>{{profileMode}}</h2>
      <h4 *ngIf="showHidePassingMessage" [style.color]="_color" class="success-message">
        {{passingMessage}}
      </h4>
    </div>

    <div class="d-flex flex-column flex-grow-1 justify-content-between dialog-content">
      <form [formGroup]="createdForm">

        <!-- File Upload Section -->
        <div class="file-upload-section" *ngIf="this.viewManagementTeamData && this.viewManagementTeamData.upload">
          <div class="template-download">
            <a href="javascript:void(0)" class="download-link" (click)="download_file()">
              <i class="fa fa-file-excel" aria-hidden="true"></i>
              Download Excel Template
            </a>
            
            <div class="file-upload-container">
              <button mat-raised-button class="browse-button">
                <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;&nbsp; BROWSE
                <input type="file" #fileUpload class="file-input" formControlName="file" (change)="changeFile($event)" />
              </button>
            
            </div>
            
          </div>
          
          <div class="d-flex align-items-center justify-content-end my-3">
            <span style="color: var(--primary);">{{currentFileobj.name}}</span>
          </div>

          <div class="d-flex justify-content-end gap-3">
           
            <button mat-raised-button 
                  
                    (click)="closeDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>&nbsp;&nbsp; CANCEL
            </button>
            <button mat-raised-button *ngIf="showHideSubmitUpload" 
                   
            (click)="saveButton(createdForm)">
      <i class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp; SUBMIT
    </button>
          </div>
        </div>

        <!-- Form Fields Section -->
        <div class="form-fields" 
             *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload"
             [class.upload-mode]="viewManagementTeamData?.upload">
          <div class="row-container">
            <mat-form-field appearance="outline" floatLabel="always" class="field-width">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'name'" placeholder="Enter Name" required>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="field-width">
              <mat-label>Email</mat-label>
              <input matInput [formControlName]="'email'" placeholder="Enter Email" required>
            </mat-form-field>
          </div>

          <div class="row-container">
            <div class="phone-container field-width">
              <mat-form-field appearance="outline" floatLabel="always" class="country-code">
                <mat-select formControlName="countryCode" placeholder="Select Country Code">
                  <mat-option value="+91">+91</mat-option>
                  <mat-option value="+1">+1</mat-option>
                  <mat-option value="+966">+966</mat-option>
                  <mat-option value="+971">+971</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" floatLabel="always" class="phone-number">
                <mat-label>Mobile Number</mat-label>
                <input matInput [formControlName]="'mobileNo'" placeholder="Enter Mobile Number" required>
              </mat-form-field>
            </div>

          
          </div>

          <div class="row-container">
            <mat-form-field appearance="outline" floatLabel="always" class="field-width">
              <mat-label>Region</mat-label>
              <mat-select formControlName="address2" multiple placeholder="Select Region">
                <mat-option *ngFor="let d of region[createdForm.controls.countryCode.value]" [value]="d.key">
                  {{d.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="field-width">
              <mat-label>Job Profile</mat-label>
              <mat-select formControlName="jobProfile" placeholder="Select Job Profile">
                <mat-option value="Sales Representative">Sales Representative</mat-option>
                <mat-option value="Service Executive">Service Executive</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
        </div>

        <!-- Role and Secondary Executive in one row -->
        <div class="form-fields" *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload" [class.upload-mode]="viewManagementTeamData?.upload">
          <div class="phone-container field-width">
            <mat-form-field appearance="outline" floatLabel="always" class="field-width" *ngIf="is_edit">
              <mat-label>Role</mat-label>
              <mat-select [formControlName]="'role'" placeholder="Select Role">
                <mat-option *ngFor="let role of _role" [value]="role.role">{{role.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="field-width">
              <mat-label>Secondary Executive</mat-label>
              <mat-select formControlName="secExec" placeholder="Select Secondary Executive">
                <mat-option value="">None</mat-option>
                <mat-option [value]="ag.id" *ngFor="let ag of secondaryAgents">{{ag.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Report To and Profile Status in one row -->
        <div class="form-fields" *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload">
          <div class="phone-container field-width">
            <mat-form-field class="wdh" appearance="outline" floatLabel="always">
              <mat-label>Report to</mat-label>
              <input matInput *ngIf="showHideAdd" placeholder="Report to" [(ngModel)]="meName" formControlName="reportTo"
                readonly>
    
              <mat-select *ngIf="!showHideAdd" placeholder="Report to" [(ngModel)]="_userid" formControlName="reportTo">
                <mat-option [value]="a.id" *ngFor="let a of dropDownReportTo">{{a.name}}</mat-option>
              </mat-select>
    
    
    
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="field-width" *ngIf="showHideProfileStatus">
              <mat-label>Profile Status</mat-label>
              <mat-select formControlName="profileStatus">
                <mat-option [value]="v" *ngFor="let v of _profileStatus">{{v}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Work Option in its own row -->
        <div class="form-fields" *ngIf="showHideProfileStatus">
          <mat-form-field appearance="outline" floatLabel="always" class="field-width">
            <mat-label>Work Option</mat-label>
            <mat-select formControlName="work_option">
              <mat-option [value]="v" *ngFor="let v of work_option">{{v}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Always show action buttons -->
        <div *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload">
          <div class="col-md-12 col-sm-12 col-xs-12 rightside">
            <div class="d-flex justify-content-end gap-3">
              <button mat-raised-button  (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>&nbsp;&nbsp; CANCEL
              </button>
              <button mat-raised-button *ngIf="!showHideProfileStatus" (click)="clearField()">
                <i class="fa fa-eraser" aria-hidden="true"></i>&nbsp;&nbsp; CLEAR
              </button>
              <button mat-raised-button *ngIf="showHideProfileStatus" (click)="deleteUserFromGroup()">
                <i class="fa fa-trash" aria-hidden="true"></i>&nbsp;&nbsp; DELETE
              </button>
              <button mat-raised-button  (click)="saveButton(createdForm)">
                <i class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp; SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
<div id="cover-spin" *ngIf="currentLoader"></div>
