import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Chart } from 'angular-highcharts';

import { ServicesService } from '../../dashboard/services.service';
import { MyserviceService } from '../../myservice.service';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { GenericAgentactivityComponent } from '../generic-agentactivity/generic-agentactivity.component';
import { SEND_EMAIL_BYJSON } from '../../interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as io from 'socket.io-client';
import { result } from 'underscore';

@Component({
  selector: 'app-whatsapp-dashboard',
  templateUrl: './whatsapp-dashboard.component.html',
  styleUrls: ['./whatsapp-dashboard.component.scss']
})
export class WhatsappDashboardComponent implements OnInit {
  currentTitle = 'whatsapp-dashboard';
	selectedProject:any="";	
	selectedChannel:any="whatsapp-dashboard";
  socket;
  myUser: any;
  sentDeliveredChart:any;
  donutChartrecord:any;
  ChannelNameList:any=[];
  category_xaxis:any[];
  wa_total_today:number;
  customer_name:string;
  project_name:string;
  selected_time_range:string;
  wa_total_yesterday:number;
  default_sent_axis:any=[];
  default_delivered_axis:any=[];
  selectedIndex: number = 0;
  wa_today:number[] = [0,0,0,0,0];
  wa_yesterday:number[] = [0,0,0,0,0];
  selectedDayWise: any = "Today"
  displayedColumns: string[] = ['user_id', 'name', 'phone','email', 'avg_first_message_response','avg_message_response','contacted_customers', 'total_messages_sent', 'template_messages_sent','chat_messages_sent', 'messages_received', 'messages_failed'];
  dataSource:any = new MatTableDataSource();
  settings: any = ""
  api_end_point:any;
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;
	constructor(
		private dashService: ServicesService,
		private _service: MyserviceService,
		private dialog: MatDialog,
		public router: Router,
		private activateRoute: ActivatedRoute,
		private API: ApiService,
		private utils: Utils,private _snackBar: MatSnackBar) {
			//this.socket = io('https://uearn.ai');
			// this.socket = io('http://localhost:4005');
		 }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
	this.settings = this._service.get_stage_settings();  
    this.myUser = this._service.get_loggedin_user();	
		this.selectedProject=this.myUser.company;
    this.refreshDashboard()
  }


  refreshDashboard(){
    this.wa_today = [0,0,0,0,0];
    this.wa_yesterday = [0,0,0,0,0];
    this.default_sent_axis = [];
    this.wa_total_yesterday = 0;
    this.default_delivered_axis = [];
    this.category_xaxis =[];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator
    this.ChannelNameList=[{name:'All Channels'},{name:'WhatsApp'},{name:'Outbound IVR'}];

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      // this.project_name = String(this._service.get_current_project()).toLowerCase();
    }

    this.selected_time_range = this.selectedDayWise;

    this.API.getWaDashboard(this.customer_name,this.project_name,this.selected_time_range,this.api_end_point).subscribe((data: any) => {
      if(data.errors){
        console.log('getWaDashboard failed',data.errors);
      } else {
       // console.log('getWaDashboard success',data);

        this.category_xaxis = data.xaxis;

        if (data.counts && data.counts['today']) {
          if (data.counts['today']['SENT']) {
            this.wa_today[0] = data.counts['today']['SENT']
          }
          if (data.counts['today']['DELIVERED']) {
            this.wa_today[1] = data.counts['today']['DELIVERED']
          }
          if (data.counts['today']['READ']) {
            this.wa_today[2] = data.counts['today']['READ']
          }
          if (data.counts['today']['FAILED']) {
            this.wa_today[3] = data.counts['today']['FAILED']
          }
          if (data.counts['today']['RECEIVED']) {
            this.wa_today[4] = data.counts['today']['RECEIVED']
          }
        }

        if (data.counts && data.counts['yesterday']) {
          if (data.counts['yesterday']['SENT']) {
            this.wa_yesterday[0] = data.counts['yesterday']['SENT']
          }
          if (data.counts['yesterday']['DELIVERED']) {
            this.wa_yesterday[1] = data.counts['yesterday']['DELIVERED']
          }
          if (data.counts['yesterday']['READ']) {
            this.wa_yesterday[2] = data.counts['yesterday']['READ']
          }
          if (data.counts['yesterday']['FAILED']) {
            this.wa_yesterday[3] = data.counts['yesterday']['FAILED']
          }
          if (data.counts['today']['RECEIVED']) {
            this.wa_yesterday[4] = data.counts['yesterday']['RECEIVED'] || 0
          }
        }

        for (var i in this.category_xaxis) {
          this.default_sent_axis[i] = 0;
          this.default_delivered_axis[i] = 0;
        }

        for (var i in data.sent_axis) {
          this.default_sent_axis[this.category_xaxis.indexOf(i)] = data.sent_axis[i];
        }

        for (var i in data.delivered_axis) {
          this.default_delivered_axis[this.category_xaxis.indexOf(i)] = data.delivered_axis[i];
        }

        this.wa_total_today = this.wa_today.reduce((a, b) => a+b, 0);
        this.wa_total_yesterday = this.wa_yesterday.reduce((a, b) => a+b, 0);

       // console.log(this.default_sent_axis,this.default_delivered_axis);
        if(data.agents_data){
          this.dataSource = new MatTableDataSource(data.agents_data);
          this.dataSource.paginator = this.paginator
        }
      }

      // this.ChannelNameList=[{name:'WhatsApp'},{name:'Outbound IVR'},{name:'SMS'},{name:'Inbound Call'},{name:'Outbound Call'},{name:'Inbound Email'},{name:'Outbound Email'}];
      this.getSentVsDelivered();
    });
  }

	getCurrentDate(){
		let myDate = new Date();
	  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
	  let date = myDate.getDate();
	  let month = monthsList[myDate.getMonth()];
	  let year = myDate.getFullYear();
	  let day = daysList[myDate.getDay()];
	  let today = `${date} ${month} ${year}, ${day}`;
	  return today;
	  }
	  navigateTo(value){
		//console.log(value);
		this.selectedChannel = value;
		this.router.navigate(["/"+value]);
	  }
    getSentVsDelivered(option: string = "Today"){

      this.sentDeliveredChart = new Chart({
        chart: {
          type: 'line',
          height: 300
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.category_xaxis,
          gridLineWidth: 1,
         minorGridLineWidth: 1
        },
        yAxis: {
          title:null,
          gridLineWidth: 1,
          minorGridLineWidth: 1,
  
        },
        legend: {
          reversed: false,
          verticalAlign: "top",
          align: "right",
          borderWidth: 1,
        },
        plotOptions: {
          line: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
  
        },
        series: [
  
          {
            type:undefined,
            name: "Sent",
            data: this.default_sent_axis,
            color: "#f1a627"
          },
          {
            type:undefined,
            name: "Delivered",
            data: this.default_delivered_axis,
            color: "#ed3a31"
          }
        ]
      });
  
  
  }

  OnChangeDayTime(val:any){
    this.selectedDayWise=val;
    this.refreshDashboard()
  }
  viewReport(){
    this.router.navigateByUrl('/reports');
  }
}
