import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UntypedFormGroup, Validators, FormControl,UntypedFormArray, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service';

@Component({
  selector: 'app-demo-portal-obd-call',
  templateUrl: './demo-portal-obd-call.component.html',
  styleUrls: ['./demo-portal-obd-call.component.scss']
})
export class DemoPortalObdCallComponent implements OnInit {

  showHideVisible:boolean=true;
  showHideVisibleSuccess:boolean=false;
  createdForm: UntypedFormGroup;
  _obd_id:any="";
  _message:any="";
  api_end_point:any;
  constructor(public router :Router,private API: ApiService,
		private formbuilder: UntypedFormBuilder,private _service:MyserviceService) {
      this.createdForm = this.formbuilder.group({
				"blobfile": ['true', Validators.required],
				"userid": ['25461', Validators.required],
				"campaignName": ['Demo_DIVR', Validators.required],
        "groupid": ['25461', Validators.required],
				"userEmail": ['', Validators.required],
        "company": ['OYOROOMS', Validators.required],
        "DtmFReq": ['N', Validators.required],
        "voiceScript": ['<![CDATA[<speak><break time="200ms"/>Dear Replace_Name<break time="200ms"/>Test call.  How are you?. Enjoy the day</speak>]]>', Validators.required],
        "customers_list": this.formbuilder.array([this.formbuilder.group({"name":'',"phone":''})])
			  })
     }

  ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  }
  submitBtn(createdForm:any){ 
    let _user_id=createdForm.controls.userid.value;
    let _campaign_name=createdForm.controls.campaignName.value;
    let _groupid=createdForm.controls.groupid.value;
    let _user_email=createdForm.controls.userEmail.value;
    let _company=createdForm.controls.company.value;
    let _DTMFREQ=createdForm.controls.DtmFReq.value;
    let _voiceScript=createdForm.controls.voiceScript.value;
    if(_user_id=="" || _campaign_name=="" || _groupid =="" || _user_email=="" || _company=="" ||_DTMFREQ==""||_voiceScript=="" ){
      alert("Please enter all filelds");
      return false;
  }
    let _obj={
      "blobfile":createdForm.controls.blobfile.value,
      "user_id":createdForm.controls.userid.value,
      "campaign_name":createdForm.controls.campaignName.value,
      "groupid":createdForm.controls.groupid.value,
      "user_email":createdForm.controls.userEmail.value,
      "company":createdForm.controls.company.value,
      "customers_list":createdForm.value.customers_list,
      "DTMFREQ":createdForm.controls.DtmFReq.value,
      "voiceScript":createdForm.controls.voiceScript.value,
  }
    this.API.obdCallApiSave(_obj,this.api_end_point).subscribe((response:any) => {
if(response.success){
 this._obd_id= response.success;
 this._message= "Created successfully.";// response.message;
  this.showHideVisible=false;
this.showHideVisibleSuccess=true;
}
else{
  alert(response.status);
} });
  }
  backDashboard(){
     this.router.navigateByUrl('/demo-portal');
     setTimeout(()=>{
       this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
     },100);
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  cancelBtn(){
    this.showHideVisible=true;
    this.showHideVisibleSuccess=false;
    this.createdForm.reset();
    this.createdForm.controls.blobfile.setValue('true');
  }
  okBtn(){
    window.location.reload();
    this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  get customersList() {
    return this.createdForm.get('customers_list') as UntypedFormArray;
  }
  addrow() {
    this.customersList.push(this.formbuilder.group({"name":'',"phone":''}));
  }

  deleterow(index) {
    this.customersList.removeAt(index);
  }
}
