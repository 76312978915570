import { Component, OnInit, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import {MatSort} from '@angular/material/sort';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';

import { ServicesService } from '../services.service';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';
import { MyserviceService } from 'src/app/myservice.service';
import { MatDialog } from '@angular/material/dialog';import { SendAppLinkDialogComponent } from './send-app-link-dialog/send-app-link-dialog.component';
import { FeedbackCondidatesDialogComponent } from './feedback-condidates-dialog/feedback-condidates-dialog.component';

@Component({
  selector: 'app-recruiter-table',
  templateUrl: './recruiter-table.component.html',
  styleUrls: ['./recruiter-table.component.scss']
})
export class RecruiterTableComponent implements OnInit, AfterViewInit, OnChanges {

	feedback_question = {
		'What is your Educational Qualification?': { 'dash_column': 'Education' },
		'What is your DOB?': { 'dash_column': 'DOB' },
		'Do you have a laptop/Desktop?': { 'dash_column': 'Setup' },
		'Dongle/BroadBand/Wifi/Hotspot': { 'dash_column': 'Connectivity' },
		'What is your Current City?': { 'dash_column': 'City' },
		'What is your Current State?': { 'dash_column': 'State' },
		'Are you good in Hindi and English?': { 'dash_column': 'Language' }
	};
	isInitialized:boolean = false;
	displayedColumns: string[] = ['applicant_id', 'user_id', 'name','recruiter_email', 'feedback', 'phone','email', 'gender', 'age',
		'education', 'dob', 'setup', 'connectivity', 'city', 'state', 'language', 'created_at', 'current_status','assessment_score','score_combined','assessment_id',
		'event_1_interview', 'event_1_organizer', 'event_2_interview', 'event_2_organizer', 'event_3_interview',
		'training_start_date', 'training_end_date', 'trainer_id', 'onboarding_status','agency_type','agency_name','company','app_download','action'];
	dataArr = [];
	public table_data = [];
	dataSource = new MatTableDataSource(this.dataArr);
	search_change:any;
	_profileStatus = ['Registered', 'Level 1 Selected','Level 2 Selected','Client Selected','ON BOARDING','Voice Test', 'Voice Reject', 'Voice Approved', 'Training', 'In Training', 'Training Reject', 'Training Complete', 'Document Pending', 'Project Pending', 'On Board', 'OJT', 'OJT Reject','OJT Select','Production', 'Project', 'New'];

	@ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) tableSort: MatSort;
	
	@Input('receiveFromDashboard') rfD;
	errorMsg:any="";
	statusColor:any="";
	api_end_point:any;
	constructor(
		private dashService: ServicesService,
		private userService: MyserviceService,
		private API: ApiService,
		private util: Utils,
		public dialog: MatDialog,
		private _service:MyserviceService
	) { }

	ngOnInit() {
		this.api_end_point = this._service.get_API_End_Point();
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.tableSort;
		this.isInitialized = true;
		//debugger;
		setTimeout(() => {
			this.assignData();
		}, 1000)
	}

	ngAfterViewInit(){
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.tableSort;
		this.dashService.dash_search.subscribe((d) => {
			this.search_change = d;
			// console.log('search change', d);
			if (this.search_change.isRecruiter) {
				if (this.search_change.data_filter && this.search_change.data_filter.length) {
					let d = JSON.parse(JSON.stringify(this.table_data));
					let g = _.groupBy(d, 'master_campaign');
					//console.log('printing g', g);
					if (!g.hasOwnProperty(this.search_change.data_filter)) {
						g[this.search_change.data_filter] = [];
					}
					this.dataTableRestart(g[this.search_change.data_filter]);
					// this.dataSource = new MatTableDataSource(g[this.search_change.data_filter]);
					// this.dataSource.paginator = this.paginator;
					this.applyFilter(this.search_change.value);
				} else {
					let d = JSON.parse(JSON.stringify(this.table_data));
					this.dataTableRestart(d);
					// this.dataSource = new MatTableDataSource(d);
					// this.dataSource.paginator = this.paginator;
					this.applyFilter(this.search_change.value);
				}
			}
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		//console.log('Simple changes rec-table', changes);
		//console.log('rec-table changes', this.rfD);
	}

	applyFilter(val: string) {
		this.dataSource.filter = val.trim().toLowerCase();
	}

	assignData = function () {
		// debugger;
		this.dataArr = [];
		this.dashService.applicantData.subscribe((d) => { 
			let data = d;
			//console.log("---"+data);
			//console.log("---1-"+JSON.stringify(data[1]));
			//debugger;
			// console.log('data from service',data);
			let selected_fb_questions = _.keys(this.feedback_question);
			//console.log('selected keys', selected_fb_questions);
			if (data) {
				let arr = [];
				for (var i in data) {
					var obj = {};
					var loc_obj = data[i];
					obj['sl_no'] = parseInt(i) + 1;
					obj['user_id'] = loc_obj['user_id'];
					obj['applicant_id'] = loc_obj['applicant_id'][0];
					obj['name'] = loc_obj.name[0];
					obj['email'] = loc_obj.email[0];
					obj['gender'] = loc_obj.gender;
					// obj['aadhar'] = 'XXXXX';
					obj['phone'] = loc_obj.number;
					obj['created_at'] = moment(loc_obj.created_at,'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY');
					obj['current_status'] = loc_obj.applicant_status || loc_obj.current_status;
					obj['assessment_score'] = loc_obj.assessment_score;
					obj['score_combined'] = loc_obj.score_combined;
					obj['education'] = loc_obj.education;
					obj['company'] = loc_obj.company;
					obj['age'] = loc_obj.age;
					obj['state'] = loc_obj.state;
					obj['city'] = loc_obj.city;
					obj['app_download'] = loc_obj.source_of_login && (loc_obj.source_of_login.includes('ios') || loc_obj.source_of_login.includes('android')) ? 'Yes' : 'No';
					obj['assessment_id'] = loc_obj.assessment_id;
					obj['event_1_interview'] = this.util.utcToLocal(loc_obj.event_1_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
					obj['event_1_organizer'] = this.getUserDetails(loc_obj.event_1_organizer, 'name');
					obj['event_1_status'] = loc_obj.event_1_status;
					obj['event_2_interview'] = this.util.utcToLocal(loc_obj.event_2_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
					obj['event_2_organizer'] = this.getUserDetails(loc_obj.event_2_organizer, 'name');
					obj['event_2_status'] = loc_obj.event_2_status;
					obj['event_3_interview'] = this.util.utcToLocal(loc_obj.event_3_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
					obj['event_3_status'] = loc_obj.event_3_status;
					obj['training_start_date'] = this.util.utcToLocal(loc_obj.training_start_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
					obj['training_end_date'] = this.util.utcToLocal(loc_obj.training_end_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
					obj['trainer_id'] = loc_obj.trainer_name || "";
					obj['master_campaign'] = loc_obj.master_campaign ;
					obj['campaign_name'] = loc_obj['lead_source'];
					obj['campaign_agency'] = loc_obj['others'] || loc_obj.refer_source;
					obj['onboarding_status'] = loc_obj['onboard_status'];
					obj['language'] = loc_obj['lang'];
					obj['recruiter_email'] = loc_obj['recruiter_email'];
					obj['feedback_form'] = loc_obj['feedback_form'];
					obj['ecode'] = loc_obj['agent_reference_id'] || loc_obj['user_id'];
					obj['dob'] = loc_obj['dob'] ? this.util.utcToLocal(loc_obj.dob,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY') : "";
					if(loc_obj.additional_data){
						const parsedData = JSON.parse(loc_obj.additional_data);
						if(parsedData.setup_options){
							obj['connectivity'] = parsedData.setup_options.toString()
							if(obj['connectivity'].toLowerCase().includes('desktop')){
								obj['setup'] = 'Yes';
							}
						}
					}
					let _profileStatusValue = loc_obj['status'];
					let _profileStatus = _profileStatusValue ? _profileStatusValue.replace(/\w\S*/g, function (txt) {
						return txt.charAt(0).toUpperCase()
							+ txt.substr(1).toLowerCase();
					}) : "";
					if(_profileStatus == 'Ojt'){
						obj['status'] = 'OJT';
					}else if(_profileStatus == 'Ojt Reject'){
						obj['status'] = 'OJT Reject';
					}else if(_profileStatus == 'Ojt Select'){
						obj['status'] = 'OJT Select';
					}else if(_profileStatus == 'On Boarding'){
						obj['status'] = 'ON BOARDING';
					}
					else{
						obj['status'] = _profileStatus;
					}
					
					//console.log(loc_obj['status'],_profileStatus)
					for (var k in selected_fb_questions) {
						if (loc_obj.hasOwnProperty(selected_fb_questions[k])) {
							obj[this.feedback_question[selected_fb_questions[k]]['dash_column']] = loc_obj[selected_fb_questions[k]];
						}
					}
					arr.push(obj);
				}
				// console.log('datasource after',arr)
				this.table_data = arr;
				this.dataTableRestart();
			} else {
				console.log('data not exists')
			}
		});
	}

	getStatusColor(val) {
		// console.log('printing val');
		var obj = {
			'color': '#3fb90d'
		}
		switch (val) {
			case 'Level 1 Selected':
			case 'Level 2 Selected':
			case 'selected'	:
				obj.color = '#44be20';
				break;
			case 'Level 1 Rejected':
			case 'Level 2 Rejected':
			case 'rejected'	:
				obj.color = '#e5293e';
				break;
		}
		return obj;
	}

	getUserDetails(id, key) {
		// debugger;
		if (id && (id !== '')) {
			let d = this.userService.getAgentObject(id);
			// debugger;
			if (d) {
				if (d.hasOwnProperty(key)) {
					// debugger;
					return d[key];
				} else {
					return '';
				}
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	dataTableRestart(d?) {
		let table_data = this.table_data;
		if (d) {
			table_data = d;
		}
		this.dataSource = new MatTableDataSource(table_data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.tableSort;
	}
	changeProfileStatus(value, element){
		this.errorMsg="";
		this.statusColor="";
		//console.log(value);
		var obj = {
			id: element.user_id,
			status: value
		}
		this.API.updateProfileStatus(obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("User updated successfully");
				//alert(data.success)
				this.errorMsg=data.success;
				this.statusColor="green";
				setTimeout(()=>{
					if(value == 'ON BOARDING'){
						this.openSendLinkDialog(element);
					}
					this.errorMsg="";
				this.statusColor="";
				},2000)
			}
		});
	}

	openSendLinkDialog(obj): void {
		const dialogRef = this.dialog.open(SendAppLinkDialogComponent, {
		  width: '400px',
		  height: '170px',
		  data: obj
		});
	
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	  }
	  feedbackCandidatesDialog(element:any){
		const dialogRef = this.dialog.open(FeedbackCondidatesDialogComponent, {
			width: '70%',
			height: '90%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		  }); 
	  }
}
