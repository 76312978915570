<div>
  <mat-sidenav-container autosize style="min-height: calc(100vh - 64px); z-index: 9">
    <!-- Header section -->
    <div class="main-cc-div">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-end align-items-center gap-4 px-4 py-2">
            <!-- Network status -->
            <div *ngIf="hide_network" class="d-flex align-items-center">
              <span *ngIf="!isAppAgent && con_status">
                <img src="../../../assets/desktop_calling/awesome-cellnetwork1.png" style="width: 26px" />
              </span>
              <span *ngIf="!isAppAgent && !con_status">
                <img src="../../../assets/desktop_calling/awesome-cellnetwork-off.svg" />
              </span>
            </div>

            <!-- Wifi status -->
            <div matTooltip="{{wifi_status?.result.message}} | Last Synced - {{wifi_status?.last_synced}}" matTooltipPosition="below">
              <span *ngIf="wifi_status && (wifi_status?.result.speed > 512)" style="position: relative;">
                <mat-icon style="font-size: 24px; color: #3fb90d">wifi</mat-icon>
              </span>
              <span *ngIf="wifi_status && (wifi_status?.result.speed > 0) && (wifi_status?.result.speed <= 512)" style="position: relative;">
                <mat-icon style="font-size: 28px; color: #ff6c00">wifi</mat-icon>
              </span>
              <span *ngIf="!wifi_status || (!wifi_status.result.speed)">
                <img src="../../../assets/desktop_calling/awesome-wifi-off.svg" style="vertical-align: middle; filter: invert(13%) sepia(85%) saturate(7053%) hue-rotate(6deg) brightness(102%) contrast(96%);" />
              </span>
            </div>

            <!-- Notification icon -->
            <div class="position-relative">
              <img src="../assets/desktop_calling/belvk.png" style="width: 24px; cursor: pointer;" (click)="notificationPopClick()" />
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{ notificationcount }}
              </span>
            </div>

            <!-- User profile -->
            <div class="d-flex align-items-center gap-0">
              <span class="text-muted me-n2">{{ user.name }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menuProp" style="margin-top: -4px;">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menuProp="matMenu">
                <button mat-menu-item (click)="logout()">Logout</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidenav -->
    <mat-sidenav [ngClass]="'ccsidenav'" #sidenav mode="side" opened="true" [style.width]="widthSide" style="position: fixed; border-right: 1px solid #e0e0e0;">
      <div class="d-flex flex-column h-100">
        <!-- Logo section -->
        <div class="d-flex justify-content-between align-items-center p-3">
          <ng-container *ngIf="isSidenavOpen" class="d-flex align-items-center">
            <img src="../assets/image/comnexProductImg.png" style="width: 144px; margin-left: 30px;" />
          </ng-container>
          <ng-container *ngIf="!isSidenavOpen">
            <img src="../assets/web_images/comnexlogo.png" style="width: 35px;"/>
          </ng-container>
          
          <button mat-icon-button (click)="sidenavToggle(sidenav)">
            <mat-icon>{{isSidenavOpen ? 'chevron_left' : 'chevron_right'}}</mat-icon>
          </button>
        </div>

        <!-- Navigation items -->
        <mat-nav-list>
          <mat-list-item *ngIf="navItems.includes('home')" [routerLink]="['./home']" [routerLinkActive]="['active-nav-item']"
            (click)="unselectchat()" class="py-3">
            <p class="d-flex align-items-center justify-content-start m-0 w-100 px-4">
              <span class="d-flex align-items-center gap-3">
                <mat-icon>dashboard</mat-icon>
                <span class="fw-medium" *ngIf="isSidenavOpen">Dashboard</span>
              </span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="isMenuContactList" [routerLink]="['./contact-list']" [routerLinkActive]="['active-nav-item']"
            (click)="unselectchat()" class="py-3">
            <p class="d-flex align-items-center justify-content-start m-0 w-100 px-4">
              <span class="d-flex align-items-center gap-3">
                <mat-icon>person</mat-icon>
                <span class="fw-medium" *ngIf="isSidenavOpen">Contact list</span>
              </span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="isMenuCampaignList" [routerLink]="['./campaign-list']" [routerLinkActive]="['active-nav-item']"
            (click)="unselectchat()" class="py-3">
            <p class="d-flex align-items-center justify-content-start m-0 w-100 px-4">
              <span class="d-flex align-items-center gap-3">
                <mat-icon>campaign</mat-icon>
                <span class="fw-medium" *ngIf="isSidenavOpen">Campaign List</span>
              </span>
            </p>
          </mat-list-item>
        </mat-nav-list>
        <div class="expand_logo" *ngIf="isSidenavOpen">
            <img src="../../../assets/web_images/contiinexLogoImg.png" class="full-logo" />
        </div>
        <div class="expand_logo" *ngIf="!isSidenavOpen">
          <img src="../assets/web_images/coologo.png" style="width: 35px;" />
        </div>
      </div>
    </mat-sidenav>

    
    <!-- <div class="bottom-links">
          
      <ng-container>
      </ng-container>
  </div> -->

    <router-outlet></router-outlet>
  </mat-sidenav-container>
  <audio #stream id="remoteAudio" controls style="display: contents">
    <p>Your browser doesn't support HTML5 audio.</p>
  </audio>
</div>

<div class="dc-ConnectingOverLay" *ngIf="showHideCallingScreen">
  <div class="dc-connecting-popup1">
    <div>
      <div class="dc-popupoverlay11" *ngIf="showHidecustomerupdate">
        <div class="dc-delete-popup11">
          <p><span class="v-dcTitle1">Update Details</span></p>
          <div>
            <mat-form-field style="width: 100%" [ngClass]="'vUpDet'" appearance="outline">
              <mat-label>Enter Name</mat-label>
              <input matInput placeholder="Enter Name" class="vUpDetailsInput" (keypress)="onlyNumAlphabetValidation($event)"
                [(ngModel)]="customer_name_entered" autocomplete="off" />
            </mat-form-field>
          </div>
          <p class="d-flex justify-content-end gap-2">
            <button mat-raised-button (click)="cancel_update_name()">
              <mat-icon>close</mat-icon>
              Cancel</button>
            <button mat-raised-button (click)="clear_update_name()">
              <mat-icon>clear</mat-icon>
              Clear</button>
            <button mat-raised-button (click)="update_name()">
              <mat-icon>check</mat-icon>
              Update
            </button>
          </p>
        </div>
      </div>
      <div style="min-height: 130px;max-height: auto; border-bottom: 1px solid #ccc"
        [ngStyle]="{background:_afterConnectingVariable === 'afertConnect' ?'#f2f7f0' : '#fff' }">
        <div id="#conneting_call" class="row p-4">
          <div class="col-md-5" style="margin-top: -10px;">
            <div *ngIf="isCheckProjectTypeSBICC">
              <table class="table table-bordered" style="border: 1px solid #ccc;">
                <tr>
                  <td style="font-weight: 500" matTooltip="{{_cName}}">
                    <ng-container *ngIf="_cName?.length; else no_customer_name">
                      {{ _cName.length > 15 ? _cName.substring(0, 15) + '...' : _cName }}
                    </ng-container>
                    <ng-template #no_customer_name>
                      <div *ngIf="is_inbound_settings_enabled">
                        Customer &nbsp; 
                        <button (click)="show_update_popup()">
                          <mat-icon style="color: #ff6c00; vertical-align: middle;">edit</mat-icon>
                        </button>
                      </div>
                    </ng-template>
                  </td>
                  <td style="font-weight: 500" colspan="2">
                    {{_cPhoneNum}}
                  </td>
                </tr>
                <tr *ngIf="_cStage">
                  <td style="font-weight: 500">
                    {{ _cStage}}
                  </td>
                  <td style="font-weight: 500" matTooltip="{{bind_LeadSource}}">
                    <ng-container *ngIf="bind_LeadSource">
                      {{ bind_LeadSource?.length > 20 ? bind_LeadSource.substring(0, 20) + '...' : bind_LeadSource }}
                    </ng-container>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-2">
            <div *ngIf="isCheckProjectTypeSBICC" style="margin-top: -10px;">
              <table  class="text-center">
                <tr *ngIf="check_sms_view()">
                  <td>
                    <button matTooltip="SendSMS" mat-raised-button (click)="clicktosendsms($event)" [disabled]="sms_button_disalbed" matTooltip="SendSMS">
                            <!-- <span><img src="../assets/desktop_calling/send_sms.svg" /></span>
                              -->
                            <mat-icon>message</mat-icon>
                            SEND SMS
                    </button>
                  </td>
                </tr>
                <tr *ngIf="showHideGetInfo">
                  <td>
                    <button class="dcGetInfoBtn" (click)="getCustomerInfo()">
                      GET INFO
                    </button>
                  </td>
                </tr>                
              </table>
            </div>
            <!-- <div *ngIf="is_inbound_settings_enabled">
              <p *ngIf="isCheckProjectTypeSBICC" class="v-cn-txt1">
                Already Connected ?
              </p>
              <p *ngIf="isCheckProjectTypeSBICC && (showHideGetInfo==true)">
                <button class="dcGetInfoBtn" (click)="getCustomerInfo()">
                  GET INFO
                </button>
              </p>
            </div> -->
          </div>
          <div class="col-md-5">
            <div>
              <div class="col-md-12">
                <div style="display:flex;flex-wrap:wrap;justify-content:flex-end;">
                  <div style="width: 125px">
                    <button matTooltip="Forward" class="dcFwdBtn" [disabled]="forwardDisabled"
                      [ngClass]="forwardDisabled == true ? 'disableColour' : ''" (click)="dcForwardBtn()">
                      <span><img src="../assets/desktop_calling/dc1-iconForward1.png"
                          [ngClass]="forwardDisabled == true ? 'disabledImg1' : ''" /></span>
                      FORWARD
                    </button>
                  </div>
                  <div style="width: 120px">
                    <button matTooltip="Merge" class="dcMergeBtn" [disabled]="mergeDisabled"
                      [ngClass]="mergeDisabled == true ? 'disableColour' : ''" (click)="dcMergeBtn()">
                      <span><img src="../assets/desktop_calling/dc1-iconMerge1.png"
                          [ngClass]="forwardDisabled == true ? 'disabledImg1' : ''" /></span>
                      MERGE
                    </button>
                  </div>
                  <div style="width: 120px;">
                    <button matTooltip="End" *ngIf="!showHideDuringSuccessDiv" class="dcEndBtn" [disabled]="endDisabled"
                    [ngClass]="endDisabled == true ? 'disableColour' : ''" (click)="endCallFirstToDisconnect()">
                      <span><img src="../assets/desktop_calling/dc1-iconEnd1.png" /></span>
                      END
                    </button>
                    <button matTooltip="Click End" *ngIf="showHideDuringSuccessDiv" class="dcEndBtn" [disabled]="endDisabled"
                    [ngClass]="endDisabled == true ? 'disableColour' : ''" (click)="endCallFirst()">
                      <span><img src="../assets/desktop_calling/dc1-iconEnd1.png" /></span>
                      END
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div style="display:flex;flex-wrap:wrap;justify-content:flex-end;margin-top:10px;">

                  <div *ngIf="user && user.sip_id && sipUser && defaultDisabled==false && showHideRedialCall==false">
                    <button class="dcRdlBtn" mat-raised-button *ngIf="showHideMute" (click)="muteBtn()" ><mat-icon
                        style="color: #ff6c00; vertical-align: middle;">mic_external_off</mat-icon>MUTE&nbsp;&nbsp;</button>

                    <button class="dcRdlBtn" mat-raised-button *ngIf="showHideUnMute" (click)="unmuteBtn()"><mat-icon
                        style="color: #ff6c00; vertical-align: middle;">mic_external_on</mat-icon>UNMUTE</button>

                    <button class="dcRdlBtn" mat-raised-button *ngIf="showHidePause" (click)="pauseBtn()"
                      style="margin-left: 10px;"><mat-icon
                        style="color: #ff6c00; vertical-align: middle;">pause</mat-icon>HOLD</button>

                    <button class="dcRdlBtn" mat-raised-button *ngIf="showHidePlay" (click)="playBtn()"
                      style="margin-left: 10px;"><mat-icon
                        style="color: #ff6c00; vertical-align: middle;">play_arrow</mat-icon>RELEASE</button>

                  </div>
                  <div *ngIf="showHideRedialCall">
                    <button matTooltip="Redial" mat-raised-button class="dcRdlBtn" (click)="clickCallForRedial()" *ngIf="!isTruepredicitive && !defaultDisabled"
                      matTooltip="Redial">
                      <mat-icon>call</mat-icon>
                      REDIAL
                    </button>
                  </div>
                  <div style="width: 120px" *ngIf="isTruepredicitive == true">
                    <button matTooltip="End Session" (click)="endCallSessionBtn()" class="dcEndBtn" mat-raised-button
                      style="width: 120px;background-color: #fdf7f7;color: #f71a1a;">
                      <span><mat-icon style="vertical-align: bottom">phone_disabled</mat-icon></span>
                      END SESSION
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <app-swift-status *ngIf="lead_current_status.length" [current_status]="lead_current_status"></app-swift-status>
        </div>
      </div>
      <div>
        <!-- submitFormContact(createdForm) -->
      </div>
      <!--Start ByDefault Customer Details Page-- user.sip_id && -->
      <!-- <div id='#customer_details4' *ngIf="isCheckProjectTypeSBICC">
        <div *ngIf="_custNameDetailsRecord != undefined && findC2CAvailble !=
          'availableC2C' ">
          <div *ngIf="showHideConnecting || showHideCalldialling ||
            showHide_Connected || showHideCallStatus" style="padding: 20px 2px
            6px 20px">
            <p *ngIf="isTruepredicitive == false" class="v-cn-txt1" style="color: #000; font-weight: 500"> Customer
              Details</p>
            <div class="tblrcs1" *ngIf="isTruepredicitive == false">
              <table class="duringSplitTable">
                <tr style="background-color: #f2f2f2">
                  <td><b>Name of the customer</b></td>
                  <td><b>Mobile Number </b></td>
                </tr>
                <tr>
                  <td *ngIf=" _custNameDetailsRecord.length != 0 &&
                    _custNameDetailsRecord != undefined">
                    {{ _custNameDetailsRecord.Name }}
                  </td>
                  <td *ngIf=" _custNameDetailsRecord.length != 0 &&
                    _custNameDetailsRecord != undefined">
                    {{ _custNameDetailsRecord.Number }}
                  </td>
                </tr>

                <tr *ngIf="_custNameDetailsRecord.length == 0">
                  <td *ngIf="_custNameDetailsRecord.length == 0">
                    {{ _cName }}
                  </td>
                  <td *ngIf="_custNameDetailsRecord.length == 0">
                    {{_cPhoneNUmWithoutMask}}
                  </td>
                </tr>

                <tr *ngIf="cust1 && _custNameDetailsRecord.length != 0">
                  <td colspan="2" *ngIf="arrayCustomVs1.length != 0">
                    <div style="column-count: 2">
                      <table style="width: 100%" class="ctbl1">
                        <tr *ngFor="let a of arrayCustomVs1">
                          <td>
                            <b> {{ a.labelName }} </b>
                          </td>
                          <td>{{ a.valueOfLabel }}</td>
                        </tr>
                        <tr *ngIf="oddOrEven(arrayCustomVs1.length)">
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr *ngIf="_custNameDetailsRecord.length == 0">
                  <td colspan="2" *ngIf="_custNameDetailsRecord.length == 0 &&
                    arrayCusVSCLI">
                    <div style="column-count: 2">
                      <table style="width: 100%" class="ctbl1">
                        <tr *ngFor="let b of arrayCustomVs1">
                          <td>
                            <b> {{ b.labelName }} </b>
                          </td>
                          <td>{{ b.valueOfLabel }}</td>
                        </tr>
                        <tr *ngIf="oddOrEven(arrayCustomVs1.length)">
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div> -->

      <!--Start new ui for project type SSL-->
      <div style="padding: 20px 2px 6px 20px" *ngIf="isCheckProjectTypeSBICC">
        <!-- [ngStyle]="_selectedTabDuring !='INTERESTED'? {overflow: 'hidden auto',height: 'calc(100vh - 230px)'}: {}" -->
        <div class="acpRowBtn acpRowBtn1" *ngIf="showHideAllStages">
          <div class="col-md-12 text-center mb-2">
            <button *ngFor="let item of _DuringFormListObj | keyvalue; let i=index"
              [ngClass]="{ activeParent: isActiveParentDuring(item.key) }" 
              (click)="parentTabBTn(item.key)"
              [disabled]="defaultDisabled" 
              [ngStyle]="defaultDisabled ? {background:'#8080806b', color: '#fff', border: '1px solid #ccc', cursor:'not-allowed'} : {}">
              {{ item.key }}
            </button>
            <button *ngIf="campaign_type == 'STP'" 
              (click)="parentTabBTn('BANK NOT LISTED')" 
              [disabled]="defaultDisabled" 
              [ngStyle]="defaultDisabled ? {background:'#8080806b', color: '#fff', border: '1px solid #ccc', cursor:'not-allowed'} : isActiveTab('BANK NOT LISTED') ? {background: '#8080806b', color: '#fff', border: '1px solid #ccc'} : {}" 
              [ngClass]="{ activeParent: isActiveParentDuring('BANK NOT LISTED') }">
              BANK NOT LISTED
            </button>
            <button *ngIf="campaign_type == 'STP'" 
              (click)="parentTabBTn('MANUAL')" 
              [disabled]="defaultDisabled" 
              [ngStyle]="defaultDisabled ? {background:'#8080806b', color: '#fff', border: '1px solid #ccc', cursor:'not-allowed'} : isActiveTab('MANUAL') ? {background: '#ff6c00', color: '#fff', border: '1px solid #ccc'} : {}" 
              [ngClass]="{ activeParent: isActiveParentDuring('MANUAL') }">
              MANUAL
            </button>
            <button *ngIf="campaign_type == 'STP' || campaign_type == 'MULTICARD'" 
              (click)="parentTabBTn('CARDED ON CALL')" 
              [disabled]="defaultDisabled" 
              [ngStyle]="defaultDisabled ? {background:'#8080806b', color: '#fff', border: '1px solid #ccc', cursor:'not-allowed'} : isActiveTab('CARDED ON CALL') ? {background: '#ff6c00', color: '#fff', border: '1px solid #ccc'} : {}" 
              [ngClass]="{ activeParent: isActiveParentDuring('CARDED ON CALL') }">
              CARDED ON CALL
            </button>
          </div>
        </div>
        <div class="acpRowBtn" *ngIf="showHideSubStge">
          <div class="col-md-12 text-center" *ngFor="let item of _DuringFormListObj | keyvalue">
            <div *ngIf="item.key == _selectedTabDuring">
              <p>
                <button *ngFor="let subItem of getSubItemDuringForm() | keyvalue"
                  [ngClass]="{ activeChild: isActiveChildDuring(subItem.key) }" (click)="clickBtnDuring(subItem.key)"
                  [disabled]="defaultDisabled"
                  [ngStyle]="defaultDisabled == true? {background: '#8080806b',color: '#fff',border: '1px solid #ccc',cursor:'not-allowed'}: {}">
                  {{ subItem.key }}
                </button>
              </p>
            </div>
          </div>
        </div>

        <div style="text-align: center;
          margin: 0% auto;
          color: #ff6c00;">
          <div *ngIf="(isCheckProjectTypeSBICC && showHideTextSmartFlowMsg)" class="plzwt blink_me" >
            <h3>{{_dialingCallPrompt1}}</h3> 
          </div>

          <h3 *ngIf="(isCheckProjectTypeSBICC && showHideTextSmartFlowMsg)" >{{_dialingCallPrompt2}}</h3>
        </div>

        <div *ngIf="showHideConCalanderForFollowUp" style="text-align: center;
          width: 100%; margin-top: 15px">
          <mat-form-field style="width: 215px; margin-right: 10px" appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="pickerAcpDate1" readonly (click)="pickerAcpDate1.open()" [min]="minDate1"
              [(ngModel)]="followUpDuringDateModel" />
            <mat-datepicker-toggle matSuffix [for]="pickerAcpDate1"></mat-datepicker-toggle>
            <mat-datepicker #pickerAcpDate1></mat-datepicker>
          </mat-form-field>

          <!-- <input style="border-radius: 4px;width: 130px;outline: none;border: 2px solid #fd6c21;height: 40px;text-indent: 5px;position: relative;bottom: 5px;" type="time"
             id="timeId1" name="time2" [(ngModel)]="followUpDuringTimeModel" />
        -->


          <mat-form-field class="ml-2 mr-2" style="width: 140px;" appearance="outline">
            <mat-label>Select Time Hour</mat-label>
            <mat-select (selectionChange)="selectHourChange($event.value)" [(ngModel)]="_hotcoldTimeHour">
              <mat-option *ngFor="let h of [01,02,03,04,05,06,07,08,09,10,11,12]" [value]="h">{{h}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mr-2" style="width: 140px;" appearance="outline">
            <mat-label>Select Minute</mat-label>
            <mat-select (selectionChange)="selectMinuteChange($event.value)" [(ngModel)]="_hotcoldTimeMinute">
              <mat-option *ngFor="let m of ['15','30','45','00']" [value]="m">{{m}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mr-2" style="width: 140px;" appearance="outline">
            <mat-label>Select AM/PM</mat-label>
            <mat-select (selectionChange)="selectAMPMChange($event.value)" [(ngModel)]="_hotcoldTimeAmPm">
              <mat-option *ngFor="let ap of ['AM','PM']" [value]="ap">{{ap}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <form [formGroup]="createdForm">
          <div style="overflow: hidden auto;"
            [ngStyle]="{'height': _selectedTabDuring =='INTERESTED' ? 'calc(100vh - 450px)' : 'calc(100vh - 410px)' }">
            <div
              *ngIf="_selectedTabDuring =='INTERESTED' && newCustomDetailsKVSRecord.length > 0 && customkvsDiv==true"
              >
              <!-- (type_SSl_SAWD == 'SSL' || type_SSl_SAWD == '' || type_SSl_SAWD ==null) &&  -->
              <div class="row mt-0 mb-2">
                <div class="col-md-12" style="column-count: 2">
                  <table class="tbl1DD ctbl2">
                    <tr *ngFor="let b of newCustomDetailsKVSRecord">
                      <td><b> {{b.labelName.replace('_',' ') | uppercase}} </b></td>
                      <td>{{b.valueOfLabel | uppercase}}</td>
                    </tr>
                    <tr *ngIf="oddOrEven(newCustomDetailsKVSRecord.length)">
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  </table>

                </div>
              </div>
            </div>
            <div *ngIf="showHideContactFormUI && type_SSl_SAWD =='Soft approval with Doc'; else elseInterestedForm"
              class="text-left">
              <!-- <h4>Yet to be decided</h4> -->

              <table class="duringSplitTableV1">
                <tr style="border-top: 0.5px solid #e7e7e7;border-bottom: 0.5px
        solid #e7e7e7;">
                  <td class="bno">
                    <b>Appointment Details</b>
                  </td>
                  <td>
                    <input matInput [matDatepicker]="pickerKYC2" placeholder="DD/MM/YYYY"
                      formControlName="softDateValue" autocomplete="off" class="kycDate" (click)="pickerKYC2.open()"
                      [min]="kycAppointmntDetails" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerKYC2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerKYC2></mat-datepicker>
                    <input class="kycTime" type="time" id="timeIdKYC2" name="timeKYC" formControlName="softTimeValue">

                  </td>
                  <td style="border-right:0.5px solid #fff">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style="border-top: 0.5px solid #e7e7e7;border-bottom: 0.5px
        solid #e7e7e7;">
                  <td class="bno" style="height: 55px;"><b> Pickup Location</b></td>
                  <td>
                    <mat-radio-group [value]="qdeLocationRadioModel" (change)="radioQdeLocationChange($event.value)">
                      <mat-radio-button value="Office">Office</mat-radio-button>
                      <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                      <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                    </mat-radio-group>

                  </td>
                  <td class="bno" *ngIf="showHideOtherLocationQDE" style="height:
          55px;"><b> Other Address</b></td>
                  <td *ngIf="showHideOtherLocationQDE"><input type="text" placeholder="Enter Location"
                      id="softOtherLocation" class="uperCase1" /></td>
                </tr>
              </table>

            </div>
            <ng-template #elseInterestedForm>
              <!-- && (type_SSl_SAWD == 'SSL' || type_SSl_SAWD == '') -->
              <div *ngIf="showHideContactFormUI ">
                <table class="customerViewTable" *ngIf="showHideCustViewDetails">
                  <tr>
                    <td class="bno"><b> Customer&nbsp;Name
                        <!-- Customer&nbsp;Name* -->
                      </b></td>
                    <td>
                      <mat-select placeholder="Title" formControlName="customer_Title" class="uperCase1"
                        style="width: 20% !important;">
                        <mat-option value="MR" selected>MR</mat-option>
                        <mat-option value="MRS">MRS</mat-option>
                        <mat-option value="MS">MS</mat-option>
                      </mat-select>


                      <input type="text" value="" class="uperCase1" style="width: 80% !important;text-indent: 15px;"
                        placeholder="First Name" formControlName="customer_firstName"
                        (keypress)="omit_special_char_code($event)" [readonly]="isDisabledForView" />
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm &&
                    createdForm.get('customer_firstName').errors &&
                    (createdForm.get('customer_firstName').errors.required)">Please
                    enter customer first name*</span>
                  <span style="color:red" *ngIf="submittedForm &&
                    createdForm.get('customer_Title').errors &&
                    (createdForm.get('customer_Title').errors.required)">Please
                    select title*</span> -->

                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Middle Name"
                        formControlName="customer_middleName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Last Name"
                        formControlName="customer_lastName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="bno"><b> Surrogate*</b></td>
								<td style="height: 51px">
									<button class="btnTD" [ngClass]="{ activeBtnTD: isActiveCust('CAC') }"
										(click)="setActiveItemSurrogate('CAC')">
										CAC
									</button>
									<button class="btnTD" [ngClass]="{ activeBtnTD: isActiveCust('IQ') }"
										(click)="setActiveItemSurrogate('IQ')">
										IQ
									</button>
									<button class="btnTD" [ngClass]="{ activeBtnTD: isActiveCust('RC') }"
										(click)="setActiveItemSurrogate('RC')">
										RC
									</button>
									<button class="btnTD" [ngClass]="{ activeBtnTD: isActiveCust('ITR') }"
										(click)="setActiveItemSurrogate('ITR')">
										ITR
									</button>
									<button class="btnTD" [ngClass]="{ activeBtnTD: isActiveCust('None') }"
										(click)="setActiveItemSurrogate('None')">
										NONE
									</button>
									<span style="color:red;display: block;" *ngIf="submittedForm && (tabCustSurrogate=='' || tabCustSurrogate==undefined)">Please select surrogate*</span>

								</td> -->
                    <td class="bno" style="border-bottom: none">
                      <b> Date of Birth</b>
                      <!-- <b> Date of Birth* </b> -->
                    </td>
                    <td style="border-bottom: none; height: 51px">
                      <input matInput [matDatepicker]="pickerDateBirth" placeholder="Select DOB" formControlName="dob"
                        autocomplete="off" style="width: 150px; vertical-align: baseline" [max]="todayNewdate"
                        (click)="pickerDateBirth.open()" readonly [disabled]="isDisabledForView" />
                      <!-- required -->
                      <mat-datepicker-toggle matSuffix [for]="pickerDateBirth"></mat-datepicker-toggle>
                      <mat-datepicker #pickerDateBirth></mat-datepicker>
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm && createdForm.get('dob').errors && (createdForm.get('dob').errors.required)">Please enter Dob*</span> -->
                    </td>
                    <td class="bno"><b> Nationality </b>
                      <!-- <b> Nationality* </b> -->
                    </td>
                    <td>

                        
                      <!-- <mat-form-field appearance="fill">
                        <mat-label>Nationality</mat-label> -->
                        <!-- <mat-form-field appearance="outline" style="width: 100%;"> -->
                          <mat-select formControlName="nationality" disableOptionCentering>
                            <mat-option>
                              <input 
                              matInput 
                              placeholder="Search Nationality" 
                              [(ngModel)]="nationalitySearch" 
                              (input)="filterNationalities($event)" 
                              (click)="$event.stopPropagation()" 
                              style="padding: 4px;"
                              autocomplete="off"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let item of filteredNationalities" [value]="item">
                                {{ item }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-option>
                          <mat-option *ngFor="let item of filteredNationalities" [value]="item">
                            {{ item }}
                          </mat-option>
                        </mat-select>
                      <!-- </mat-form-field> -->
                      <!-- </mat-form-field> -->
                      <!-- </mat-form-field> -->
                       <span style="color:red" *ngIf="submittedForm && createdForm.get('nationality').errors && (createdForm.get('nationality').errors.required)">Please
                    select nationality*</span>

                    </td>
                  </tr>
                  <tr>
                    <td class="bno"><b> Father&nbsp;Name </b></td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="First Name"
                        formControlName="father_firstName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('father_firstName').errors && (createdForm.get('father_firstName').errors.required)">Please enter father first name*</span> -->
                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Middle Name"
                        formControlName="father_middleName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Last Name"
                        formControlName="father_lastName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                  </tr>
                  <tr>
                    <td class="bno"><b> Mother&nbsp;Name </b></td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="First Name"
                        formControlName="mother_firstName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('mother_firstName').errors && (createdForm.get('mother_firstName').errors.required)">Please enter mother first name*</span> -->
                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Middle Name"
                        formControlName="mother_middleName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                    <td>
                      <input type="text" value="" class="uperCase1" placeholder="Last Name"
                        formControlName="mother_lastName" (keypress)="omit_special_char_code($event)"
                        [readonly]="isDisabledForView" />
                    </td>
                  </tr>

                  <tr>
                    <td class="bno"><b>
                        PAN Number
                        <!-- PAN Number* -->
                      </b></td>
                    <td>
                      <input type="text" maxlength="10" formControlName="PAN_No" class="uperCase1"
                        (keypress)="omit_special_char_code($event)" (keyup)="panFormat()"
                        [readonly]="isDisabledForView" />
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('PAN_No').errors &&
                    (createdForm.get('PAN_No').errors.required)">Please enter PAN Number with 10 digit*</span>
                  <span style="color:red" *ngIf="submittedForm && this.createdForm.get('PAN_No').invalid && (this.createdForm.get('PAN_No').touched ||
                    this.createdForm.get('PAN_No').dirty ) && createdForm.get('PAN_No').errors?.minlength">Please enter PAN Number with 10 digit*</span> -->
                      <span style="color:red;display: block;" *ngIf="submittedForm && wrongPanFormat">PAN number should
                        be allowed only in
                        proper format (ABCDE1234F)(five alphabets , four digits , one alphabets*</span>
                    </td>
                    <td class="bno"><b>Company Name
                        <!-- Company Name* -->

                      </b></td>
                    <td><input type="text" formControlName="companyName" class="uperCase1"
                        [readonly]="isDisabledForView" />
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm &&
                    createdForm.get('companyName').errors &&
                    (createdForm.get('companyName').errors.required)">Please
                    enter company name*</span> -->
                    </td>



                  </tr>

                  <!-- <tr style="display:none">
								<td class="bno"><b> SBI Relation Number</b></td>
								<td><input type="text" formControlName="sbiRelationNo" (keypress)="onlyNumberValidationCode($event)" [readonly]="isDisabledForView" /></td>
								<td  class="bno"><b> Aadhar Number*</b></td>
								<td  >
									<input type="text" formControlName="AadharNo"
										(keypress)="onlyNumberValidationCode($event)" maxlength="12" required [readonly]="isDisabledForView"/>
										<span style="color:red" *ngIf="submittedForm && createdForm.get('AadharNo').errors && (createdForm.get('AadharNo').errors.required)">Please enter Aadhar Number*</span>
										<span  style="color:red" *ngIf="submittedForm &&  this.createdForm.get('AadharNo').invalid  &&  (this.createdForm.get('AadharNo').touched || this.createdForm.get('AadharNo').dirty ) && createdForm.get('AadharNo').errors?.minlength">Please enter Aadhar Number with 12 digit*</span>
								</td>
							</tr> -->
                  <tr>
                    <td class="bno"><b>Residence Address
                        <!-- Residence Address* -->
                      </b></td>
                    <td>
                      <textarea formControlName="residance_address" class="uperCase1"
                        [readonly]="isDisabledForView"></textarea>
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('residance_address').errors &&
                    (createdForm.get('residance_address').errors.required)">Please enter residence address*</span> -->
                    </td>
                    <td class="bno"><b>
                        <!-- Designation* -->
                        Designation
                      </b></td>
                    <td><input type="text" formControlName="designation" class="uperCase1"
                        [readonly]="isDisabledForView" />
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('designation').errors &&
                    (createdForm.get('designation').errors.required)">Please enter designation*</span> -->
                    </td>

                  </tr>
                  <tr>
                    <td class="bno"><b>Residence PINCODE
                        <!-- Residence PIN CODE*   -->
                      </b></td>
                    <td>
                      <input type="text" maxlength="6" class="sameasPin" formControlName="residence_pin_code"
                        (keypress)="onlyNumberValidationCode($event)" (keyup)="residencePincode()"
                        [readonly]="isDisabledForView" />
                      <span style="color:red;display: block;" *ngIf="submittedForm && isResidencePinCode">Please enter
                        pincode with 6 digits</span>
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('residence_pin_code').errors &&
                    (createdForm.get('residence_pin_code').errors.required)">Please enter residence pin code with 6 digit*</span>
                  <span style="color:red" *ngIf="submittedForm && this.createdForm.get('residence_pin_code').invalid &&
                    (this.createdForm.get('residence_pin_code').touched ||  this.createdForm.get('residence_pin_code').dirty ) &&
                    createdForm.get('residence_pin_code').errors?.minlength">Please enter residence pin code with 6 digit*</span> -->

                    </td>
                    <td class="bno"><b> Company Address </b></td>
                    <td><input type="text" class="uperCase1" formControlName="companyAddress"
                        [readonly]="isDisabledForView" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('companyAddress').errors && (createdForm.get('companyAddress').errors.required)">Please enter company address*</span> -->
                    </td>


                    <!-- <td class="bno" style="display: none;"><b> Any Landline</b></td>
								<td style="display: none;"><input type="text" formControlName="anyLandline" (keypress)="onlyNumberValidationCode($event)" maxlength="10"  [readonly]="isDisabledForView"/></td> -->
                  </tr>

                  <tr>
                    <td class="bno"><b>Residence Landmark </b></td>
                    <td>
                      <input type="text" class="uperCase1" formControlName="landMark" [readonly]="isDisabledForView" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('landMark').errors && (createdForm.get('landMark').errors.required)">Please enter residence land mark*</span> -->
                    </td>
                    <td class="bno"><b>Company Landmark </b></td>
                    <td>
                      <input type="text" class="uperCase1" formControlName="companyLandMarkMark"
                        [readonly]="isDisabledForView" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('landMark').errors && (createdForm.get('landMark').errors.required)">Please enter residence land mark*</span> -->
                    </td>
                  </tr>
                  <tr>
                    <td class="bno"><b>
                        Zone
                        <!-- Zone*  -->
                      </b></td>
                    <td>
                      <!-- required -->
                      <mat-select placeholder="Select" formControlName="zone" class="uperCase1">
                        <mat-option value="EAST" style="text-transform: uppercase;">East</mat-option>
                        <mat-option value="WEST" style="text-transform: uppercase;">West</mat-option>
                        <mat-option value="NORTH" style="text-transform: uppercase;">North</mat-option>
                        <mat-option value="SOUTH" style="text-transform: uppercase;">South</mat-option>
                      </mat-select>
                      <!-- <span style="color:red" *ngIf="submittedForm &&
                    createdForm.get('zone').errors &&
                    (createdForm.get('zone').errors.required)">Please select
                    zone*</span> -->
                    </td>
                    <td class="bno"><b>Company PINCODE
                        <!-- Company PIN CODE*  -->
                      </b></td>
                    <td>
                      <input type="text" maxlength="6" value="" class="sameasPin" formControlName="company_pinCode"
                        (keyup)="companyPincode()" (keypress)="onlyNumberValidationCode($event)"
                        [readonly]="isDisabledForView" />
                      <span style="color:red;display: block;" *ngIf="submittedForm && isCompanyPinCode">Please enter
                        pincode with 6 digits</span>
                      <!-- required -->
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('company_pinCode').errors &&
                    (createdForm.get('company_pinCode').errors.required)">Please enter company pin code with 6 digit*</span>
                  <span style="color:red" *ngIf="submittedForm && this.createdForm.get('company_pinCode').invalid &&
                    (this.createdForm.get('company_pinCode').touched ||  this.createdForm.get('company_pinCode').dirty ) &&
                    createdForm.get('company_pinCode').errors?.minlength">Please enter company pin code with 6 digit*</span> -->
                    </td>

                  </tr>


                  <tr>
                    <td class="bno"><b> Mobile
                        <!-- Mobile* -->
                      </b></td>
                    <td>
                      <input type="text" formControlName="mobileNumber" maxlength="10" disabled />
                      <!-- <span style="color:red" *ngIf="submittedForm &&  createdForm.get('mobileNumber').errors &&
                    (createdForm.get('mobileNumber').errors.required)">Please  enter mobile number*</span> -->
                    </td>
                    <td class="bno"><b> Official Email ID</b></td>
                    <td><input type="text" class="uperCase1" formControlName="officialEmailId"
                        [readonly]="isDisabledForView" (keyup)="emailIDValidationOfficial()" />
                      <span style="color:red;display: block;" *ngIf="submittedForm && isCompanyEmail">Please enter valid
                        official email ID</span>
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('officialEmailId').errors && (createdForm.get('officialEmailId').errors.required)">Please enter valid official email ID*</span> -->
                      <!-- <span style="color:red" *ngIf="submittedForm &&  this.createdForm.get('officialEmailId').invalid &&
                    (this.createdForm.get('officialEmailId').touched ||  this.createdForm.get('officialEmailId').dirty ) &&
                    createdForm.get('officialEmailId').errors?.pattern">Please enter valid official email ID*</span> -->
                    </td>


                  </tr>
                  <tr>
                    <td class="bno"><b> Personal Email ID
                        <!-- Personal Email ID*   -->
                      </b></td>
                    <td><input type="text" class="uperCase1" formControlName="personalEmailId"
                        (keyup)="emailIDValidationPersonal()" />
                      <span style="color:red;display: block;" *ngIf="submittedForm && isPersonalEmail">Please enter
                        valid personal email ID</span>
                      <!-- <span style="color:red" *ngIf="submittedForm &&  createdForm.get('personalEmailId').errors &&
                    (createdForm.get('personalEmailId').errors.required)">Please enter valid personal email ID*</span>
                  <span style="color:red" *ngIf="submittedForm && this.createdForm.get('personalEmailId').invalid &&
                    (this.createdForm.get('personalEmailId').touched || this.createdForm.get('personalEmailId').dirty ) &&
                    createdForm.get('personalEmailId').errors?.pattern">Please enter valid personal email ID*</span> -->
                    </td>
                    <td class="bno"><b> Net Income</b></td>
                    <td><input type="text" formControlName="netIncome" (keypress)="onlyNumberValidationCode($event)" />
                    </td>
                  </tr>

                  <tr>
                    <td class="bno" style="height:50px"><b>
                        Occupation Type
                        <!-- Ocuupation Type* -->
                      </b></td>
                    <td>
                      <!-- required -->
                      <mat-select placeholder="Select" formControlName="occupationType" class="uperCase1">
                        <mat-option value="HOUSEWIFE" style="text-transform: uppercase;">Housewife</mat-option>
                        <mat-option value="SALARIED" style="text-transform: uppercase;">Salaried</mat-option>
                        <mat-option value="SELF EMPLOYED" style="text-transform: uppercase;">Self Employed</mat-option>
                        <mat-option value="STUDENT" style="text-transform: uppercase;">Student</mat-option>
                        <mat-option value="RETIRE/PENSIONER"
                          style="text-transform: uppercase;">Retire/Pensioner</mat-option>
                      </mat-select>
                      <!-- <span style="color:red" *ngIf="submittedForm &&
                    createdForm.get('occupationType').errors &&
                    (createdForm.get('occupationType').errors.required)">Please
                    select ocuupation type*</span> -->
                    </td>


                  </tr>



                  <tr>
                    <td colspan="4" class="text-center">
                      <button class="btnTD btnAddOn" id="addon1ID" [ngClass]="{activeBtnTD: isActiveAddOn('Add-on 1') }"
                        (click)="setActiveAddOn('Add-on 1')" [disabled]="addOnBtn1Disabled"
                        [ngStyle]="addOnBtn1Disabled== true ? {background: '#8080806b',color: ' #fff',border:'1px solid #ccc',cursor: 'not-allowed'}: {}">Add-on
                        1</button>
                      <button class="btnTD btnAddOn" id="addon2ID"
                        [ngClass]="{ activeBtnTD: isActiveAddOn('Add-on 2') }" (click)="setActiveAddOn('Add-on 2')"
                        [disabled]="addOnBtn2Disabled"
                        [ngStyle]="addOnBtn2Disabled== true ? {background: '#8080806b',color: ' #fff',border:'1px solid #ccc', cursor: ' not-allowed'} : {} ">
                        Add-on 2</button>
                      <button class="btnTD btnAddOn" id="addon3ID"
                        [ngClass]="{ activeBtnTD: isActiveAddOn('Add-on 3') }" (click)="setActiveAddOn('Add-on 3')"
                        [disabled]="addOnBtn3Disabled"
                        [ngStyle]="addOnBtn3Disabled == true ? { background: '#8080806b',color: ' #fff',border:'1px solid #ccc', cursor:'not-allowed' }: {} ">Add-on
                        3</button>
                      <button class="btnTD btnAddOn" id="addon4ID"
                        [ngClass]="{ activeBtnTD: isActiveAddOn('Add-on 4') }" (click)="setActiveAddOn('Add-on 4')"
                        [disabled]="addOnBtn4Disabled"
                        [ngStyle]="addOnBtn4Disabled == true ? {background: '#8080806b', color:'#fff', border:'1px solid #ccc', cursor: ' not-allowed'}:{} ">Add-on
                        4
                      </button>
                    </td>
                  </tr>
                </table>
                <table class="duringSplitTableV1" *ngIf="showHideQDEFormDetails">
                  <tr>
                    <td class="bno"><b>
                        <!-- Application Number* -->
                        Application Number
                      </b></td>
                    <td>
                      <input type="text" (keyup)="applictionNumberKeyup()" formControlName="applicationNumber"
                        minlength="13" maxlength="13" (keypress)="onlyNumberValidationCode($event)" />
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('applicationNumber').errors && (createdForm.get('applicationNumber').errors.required)">Please enter application number with 13 digit*</span> -->
                      <span style="color:red;display: block;">{{errorMsgApplication}}</span>
                      <!-- <span  style="color:red" *ngIf="submittedForm &&  this.createdForm.get('applicationNumber').invalid  &&  (this.createdForm.get('applicationNumber').touched || this.createdForm.get('applicationNumber').dirty ) && createdForm.get('applicationNumber').errors?.minlength">please enter 13 digits application number*</span> -->
                    </td>
                    <td class="bno"><b>
                        <!-- Card Type* -->
                        Card Type
                      </b></td>
                    <td><input type="text" formControlName="cardType" maxlength="4" (keyup)="cardTypeKeyup()"
                        (keypress)="omit_special_char_code($event)" class="uperCase1" />

                      <span style="color:red;display: block;">{{errorMsgCardType}}</span>
                      <!-- <span style="color:red" *ngIf="submittedForm && createdForm.get('cardType').errors && (createdForm.get('cardType').errors.required)">Please enter card type with min two and max four alphabets*</span> -->
                      <!-- <span style="color:red;display: block;">{{errorMsgCardType}}</span>
                  <span style="color:red" *ngIf="submittedForm &&  this.createdForm.get('cardType').invalid &&  (this.createdForm.get('cardType').touched ||
                    this.createdForm.get('cardType').dirty ) &&  createdForm.get('cardType').errors?.minlength && createdForm.get('cardType').errors?.maxlength">Please enter
                    card type with min two and max four alphabets*</span> -->
                    </td>
                  </tr>

                  <tr>
                    <td class="bno"><b>
                        QDE Status
                        <!-- QDE Status*  -->
                      </b></td>
                    <td>
                      <button class="btnTD" [ngClass]="{ activeBtnTD:isActiveQDE('Approve') }"
                        (click)="setActiveQde('Approve')">APPROVE</button>
                      <button class="btnTD" [ngClass]="{ activeBtnTD:isActiveQDE('Reject') }"
                        (click)="setActiveQde('Reject')"> REJECT </button>
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm && (tabQDE=='' || tabQDE==undefined)">Please select QDE Status*</span> -->
                    </td>
                    <td *ngIf="isShowDocStatus" class="bno">
                      <b> Document Status</b>
                    </td>
                    <td *ngIf="isShowDocStatus">
                      <button class="btnTD" [ngClass]="{ activeBtnTD:isActiveDocStatus('With Doc') }"
                        (click)="setActiveDocStatus('With Doc')"> WITH DOC</button>
                      <button class="btnTD" [ngClass]="{ activeBtnTD:isActiveDocStatus('No Doc') }"
                        (click)="setActiveDocStatus('No Doc')"> NO DOC </button>
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm  && tabQDE !='' && tabDocStatus==''">Please select Document Status*</span> -->
                    </td>
                  </tr>


                  <tr *ngIf="tabDocStatus=='With Doc' || tabDocStatus=='No Doc'">
                    <!-- *ngIf="tabDocStatus=='With Doc'" -->
                    <td class="bno" style="height: 55px;"><b>KYC</b>
                      <!-- <b>KYC*</b>
                  <b style="display:none;"> QDE surrogates</b> -->
                    </td>
                    <td colspan="3">
                      <span *ngFor="let s of qdeSurrogateList">
                        <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked= !s.checked"> {{
                        s.name }}
                      </span>
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm
                    && tabQDE =='Approve' && tabDocStatus=='With Doc' &&
                    selectedQdeSurrogateCB ==''">Please select KYC*</span> -->
                    </td>
                  </tr>

                  <tr *ngIf="tabDocStatus=='With Doc'">
                    <!-- *ngIf="isShowKYCSurrogate" -->
                    <td style="height:52px;" class="bno"><b>
                        <!-- Surrogate Document* -->
                        Surrogate Document
                      </b></td>
                    <td colspan="3">
                      <span *ngFor="let s of surrogateDocumentList">
                        <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked= !s.checked"> {{
                        s.name }}
                      </span>
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm
                    && tabQDE =='Approve' && surrogateDocumentValue ==''">Please
                    select Surrogate Document*</span> -->
                    </td>
                  </tr>




                  <tr *ngIf="isShowKYCSurrogate">
                    <td class="bno"><b>
                        <!-- Appointment Details* -->
                        Appointment Details
                      </b></td>
                    <td>
                      <input matInput [matDatepicker]="pickerKYC" placeholder="DD/MM/YYYY" autocomplete="off"
                        class="kycDate" formControlName="kycDate" (click)="pickerKYC.open()"
                        [min]="kycAppointmntDetails" readonly />
                      <mat-datepicker-toggle matSuffix [for]="pickerKYC"></mat-datepicker-toggle>
                      <mat-datepicker #pickerKYC></mat-datepicker>
                      <input class="kycTime" type="time" id="timeIdKYC" name="timeKYC" formControlName="kycDateTime" />
                      <!-- <span style="color:red;display: block;" *ngIf="submittedForm
                    && tabQDE =='Approve' && tabDocStatus=='With Doc' && ((
                    kycDateValue =='Invalid date' || kycDateValue =='' ) ||
                    kycTimeValue=='') ">Please enter Appointment Date, Time for
                    With DoC*</span>
                  <span style="color:red;display: block;" *ngIf="submittedForm
                    && tabQDE =='Approve' && tabDocStatus=='No Doc' && ((
                    kycDateValue =='Invalid date' || kycDateValue =='' ) ||
                    kycTimeValue=='') ">Please enter Appointment Date, Time for
                    No DoC*</span> -->
                    </td>
                    <td></td>
                    <td style="border-left:1px solid #fff"></td>
                    <!-- <td *ngIf="isShowSurrogateDocument" class="bno">
									<b> Surrogate Document*</b>
								</td>
								<td *ngIf="isShowSurrogateDocument">
									<mat-select placeholder="Select" formControlName="surrogateDocument">
										<mat-option value="option1">Option 1</mat-option>
										<mat-option value="option2">Option 2</mat-option>
										<mat-option value="option3">Option 3</mat-option>
										<mat-option value="option4">Option 4</mat-option>
									</mat-select>
									<span style="color:red;display: block;" *ngIf="submittedForm && tabQDE =='Approve' && tabDocStatus=='With Doc' && surrogateDocumentValue ==''">Please select Surrogate Document*</span>
								</td> -->
                  </tr>


                  <tr *ngIf="isShowKYCSurrogate">
                    <td class="bno" style="height: 55px;"><b>
                        Pickup Location
                        <!-- Pickup Location* -->
                      </b></td>
                    <td>
                      <mat-radio-group [value]="pickupLocationRadioModel"
                        (change)="radioPicupLocationChange($event.value)">
                        <mat-radio-button value="Office">Office</mat-radio-button>
                        <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                        <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                      </mat-radio-group>

                    </td>
                    <td class="bno" style="height: 55px;" *ngIf="showHideOtherLocationQDE" style="height:
          55px;"><b> Other Address</b></td>
                    <td *ngIf="showHideOtherLocationQDE"><input type="text" placeholder="Enter Location"
                        id="softOtherLocation" class="uperCase1" /></td>
                  </tr>
                </table>
                <!--start for addon 1 design multiple-->
                <div *ngIf="checkLengthAddon1==true">
                  <div
                    *ngIf="tabQDE=='Approve' && showHideQDEFormDetails && obj_AddOn_One && obj_AddOn_One.addOnFirstName !=''">
                    <h3 style="margin:20px 0 10px 0">Addon-1</h3>
                    <table class="duringSplitTableV1">
                      <tr>
                        <td class="bno" style="height: 55px;">
                          <!-- <b>KYC*</b> -->
                          <b>KYC</b>
                        </td>
                        <td colspan="3">
                          <span *ngFor="let s of qdeSurrogateListAddon1">
                            <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked=
                          !s.checked"> {{ s.name }}
                          </span>
                          <!-- <span style="color:red;display: block;">{{errorMsgKYCAddon1}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno"><b>
                            <!-- Appointment Details* -->
                            Appointment Details*
                          </b></td>
                        <td colspan="3">
                          <input matInput [matDatepicker]="pickerAddon1KYC" placeholder="DD/MM/YYYY" autocomplete="off"
                            class="kycDate" formControlName="kycDateAddon1" (click)="pickerAddon1KYC.open()"
                            [min]="kycAppointmntDetails" readonly />
                          <mat-datepicker-toggle matSuffix [for]="pickerAddon1KYC"></mat-datepicker-toggle>
                          <mat-datepicker #pickerAddon1KYC></mat-datepicker>
                          <input class="kycTime" type="time" id="timeIdKYCAddon1" name="timeKYC"
                            formControlName="kycAddon1DateTime" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAppointmentDetailsAddon1}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno" style="height: 55px;"><b>
                            <!-- Pickup Location* -->
                            Pickup Location
                          </b></td>
                        <td>
                          <mat-radio-group [value]="pickupLocationRadioAddon1Model"
                            (change)="radioPicupLocationChangeAddon1($event.value)">
                            <mat-radio-button value="Office">Office</mat-radio-button>
                            <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                            <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                          </mat-radio-group>
                        </td>
                        <td class="bno" style="height: 55px;"><b> Address</b></td>
                        <!-- *ngIf="showHideOtherPickupLocationAddon1"			 -->
                        <td><input type="text" formControlName="qde_locationAddon1" placeholder="Enter Location"
                            class="uperCase1" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAddressAddon1}}</span> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end for addon 1 design multiple-->

                <!--start for addon 2 design multiple-->
                <div *ngIf="checkLengthAddon2==true">
                  <div *ngIf="tabQDE=='Approve' && showHideQDEFormDetails &&
                obj_AddOn_Two && obj_AddOn_Two.addOnFirstName !=''">
                    <h3 style="margin:20px 0 10px 0">Addon-2</h3>
                    <table class="duringSplitTableV1">
                      <tr>
                        <td class="bno" style="height: 55px;"><b>KYC</b></td>
                        <td colspan="3">
                          <span *ngFor="let s of qdeSurrogateListAddon2">
                            <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked=
                          !s.checked"> {{ s.name }}
                          </span>
                          <!-- <span style="color:red;display: block;">{{errorMsgKYCAddon2}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno"><b> Appointment Details</b></td>
                        <td colspan="3">
                          <input matInput [matDatepicker]="pickerAddon2KYC" placeholder="DD/MM/YYYY" autocomplete="off"
                            class="kycDate" formControlName="kycDateAddon2" (click)="pickerAddon2KYC.open()"
                            [min]="kycAppointmntDetails" readonly />
                          <mat-datepicker-toggle matSuffix [for]="pickerAddon2KYC"></mat-datepicker-toggle>
                          <mat-datepicker #pickerAddon2KYC></mat-datepicker>
                          <input class="kycTime" type="time" id="timeIdKYCAddon2" name="timeKYC"
                            formControlName="kycAddon2DateTime" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAppointmentDetailsAddon2}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno" style="height: 55px;"><b> Pickup Location</b></td>
                        <td>
                          <mat-radio-group [value]="pickupLocationRadioAddon2Model"
                            (change)="radioPicupLocationChangeAddon2($event.value)">
                            <mat-radio-button value="Office">Office</mat-radio-button>
                            <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                            <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                          </mat-radio-group>
                        </td>
                        <td class="bno" style="height: 55px;"><b> Address</b></td>
                        <td><input type="text" formControlName="qde_locationAddon2" placeholder="Enter Location"
                            class="uperCase1" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAddressAddon2}}</span> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end for addon 2 design multiple-->




                <!--start for addon 3 design multiple-->
                <div *ngIf="checkLengthAddon3==true">
                  <div *ngIf="tabQDE=='Approve' && showHideQDEFormDetails &&
                obj_AddOn_Three && obj_AddOn_Three.addOnFirstName !=''">
                    <h3 style="margin:20px 0 10px 0">Addon-3</h3>
                    <table class="duringSplitTableV1">
                      <tr>
                        <td class="bno" style="height: 55px;"><b>KYC</b></td>
                        <td colspan="3">
                          <span *ngFor="let s of qdeSurrogateListAddon3">
                            <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked=
                          !s.checked"> {{ s.name }}
                          </span>
                          <!-- <span style="color:red;display: block;">{{errorMsgKYCAddon3}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno"><b> Appointment Details</b></td>
                        <td colspan="3">
                          <input matInput [matDatepicker]="pickerAddon3KYC" placeholder="DD/MM/YYYY" autocomplete="off"
                            class="kycDate" formControlName="kycDateAddon3" (click)="pickerAddon3KYC.open()"
                            [min]="kycAppointmntDetails" readonly />
                          <mat-datepicker-toggle matSuffix [for]="pickerAddon3KYC"></mat-datepicker-toggle>
                          <mat-datepicker #pickerAddon3KYC></mat-datepicker>
                          <input class="kycTime" type="time" id="timeIdKYCAddon3" name="timeKYC"
                            formControlName="kycAddon3DateTime" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAppointmentDetailsAddon3}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno" style="height: 55px;"><b> Pickup Location</b></td>
                        <td>
                          <mat-radio-group [value]="pickupLocationRadioAddon3Model"
                            (change)="radioPicupLocationChangeAddon3($event.value)">
                            <mat-radio-button value="Office">Office</mat-radio-button>
                            <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                            <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                          </mat-radio-group>
                        </td>
                        <td class="bno" style="height: 55px;"><b> Address</b></td>
                        <td><input type="text" formControlName="qde_locationAddon3" placeholder="Enter Location"
                            class="uperCase1" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAddressAddon3}}</span> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end for addon 3 design multiple-->
                <!--start for addon 4 design multiple-->
                <div *ngIf="checkLengthAddon4==true">
                  <div *ngIf="tabQDE=='Approve' && showHideQDEFormDetails &&
                obj_AddOn_Four && obj_AddOn_Four.addOnFirstName !=''">
                    <h3 style="margin:20px 0 10px 0">Addon-4</h3>
                    <table class="duringSplitTableV1">
                      <tr>
                        <td class="bno" style="height: 55px;"><b>KYC</b></td>
                        <td colspan="3">
                          <span *ngFor="let s of qdeSurrogateListAddon4">
                            <input type="checkbox" class="CD_CBX" [checked]="s.checked" (change)="s.checked=
                          !s.checked"> {{ s.name }}
                          </span>
                          <!-- <span style="color:red;display: block;">{{errorMsgKYCAddon4}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno"><b> Appointment Details</b></td>
                        <td colspan="3">
                          <input matInput [matDatepicker]="pickerAddon4KYC" placeholder="DD/MM/YYYY" autocomplete="off"
                            class="kycDate" formControlName="kycDateAddon4" (click)="pickerAddon4KYC.open()"
                            [min]="kycAppointmntDetails" readonly />
                          <mat-datepicker-toggle matSuffix [for]="pickerAddon4KYC"></mat-datepicker-toggle>
                          <mat-datepicker #pickerAddon4KYC></mat-datepicker>
                          <input class="kycTime" type="time" id="timeIdKYCAddon4" name="timeKYC"
                            formControlName="kycAddon4DateTime" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAppointmentDetailsAddon4}}</span> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="bno" style="height: 55px;"><b> Pickup Location</b></td>
                        <td>
                          <mat-radio-group [value]="pickupLocationRadioAddon4Model"
                            (change)="radioPicupLocationChangeAddon4($event.value)">
                            <mat-radio-button value="Office">Office</mat-radio-button>
                            <mat-radio-button value="Residence" class="pl-3">Residence</mat-radio-button>
                            <mat-radio-button value="Other" class="pl-3">Other</mat-radio-button>
                          </mat-radio-group>
                        </td>
                        <td class="bno" style="height: 55px;"><b> Address</b></td>
                        <td><input type="text" formControlName="qde_locationAddon4" placeholder="Enter Location"
                            class="uperCase1" />
                          <!-- <span style="color:red;display: block;">{{errorMsgAddressAddon4}}</span> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end for addon 4 design multiple-->





              </div>
            </ng-template>

            <div
              *ngIf=" _selectedTabDuring !='INTERESTED' && newCustomDetailsKVSRecord.length > 0 && customkvsDiv==true">
              <!-- (type_SSl_SAWD == 'SSL'  || type_SSl_SAWD == '' || type_SSl_SAWD ==null)  && -->
              <div class="row mt-0">
                <div class="col-md-12" style="column-count: 2">
                  <table class="tbl1DD ctbl2">
                    <tr *ngFor="let b of newCustomDetailsKVSRecord">
                      <td><b> {{b.labelName.replace('_',' ') | uppercase}} </b></td>
                      <td>{{b.valueOfLabel | uppercase}}</td>
                    </tr>
                    <tr *ngIf="oddOrEven(newCustomDetailsKVSRecord.length)">
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  </table>

                </div>
              </div>
            </div>


          </div>

          <div class="parent-container">
            <div class="row fixed-bottom-buttons d-flex justify-content-between align-items-center">
              <div class="col-md-7">
                <div *ngIf="(showHideRemarksForm && _selectedTabDuring !='INTERESTED') || (_selectedTabDuring =='INTERESTED' && showNextViewButton)">
                  <input type="text" placeholder="Remarks" formControlName="remarksField" class="remarkcls uperCase1" />
                </div>
              </div>
              <div class="col-md-4 d-flex justify-content-end gap-3" style="margin-bottom: -1%;margin-right: 2%;">
                <button *ngIf="showNextButton" [disabled]="defaultDisabled" mat-raised-button
                  [ngStyle]="defaultDisabled ? {background: '#8080806b',color: ' #fff',border: '1px solid #ccc', cursor: 'not-allowed'} : {} "
                  (click)="submitFormContact(createdForm,'NEXT','button1')"> 
                  <mat-icon class="me-2">navigate_next</mat-icon>
                  NEXT
                </button>
                <button *ngIf="showNextViewButton"  [disabled]="defaultDisabled" mat-raised-button
                  [ngStyle]="defaultDisabled ? {background: '#8080806b', color: ' #fff',border: '1px solid #ccc', cursor: 'not-allowed'} : {} "
                  (click)="viewOnlyCustDetails(createdForm,'VIEW')">
                  <mat-icon class="me-2">visibility</mat-icon>
                  VIEW
                </button>
                <button *ngIf="showNextSubmitButton"  [disabled]="defaultDisabled" mat-raised-button
                  [ngStyle]="defaultDisabled ? { background: '#8080806b', color: ' #fff',border: '1px solid #ccc', cursor: ' not-allowed'} : {} "
                  (click)="submitFormContact(createdForm,'NEXT','button2')">
                  <mat-icon class="me-2">done</mat-icon>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </form>
     
      </div>
      <!--End new ui for project type SSL-->

      <!-- (click)="duringFormSubmit()" -->

      <!-- <div #show_cust_details1 *ngIf="user.sip_id && _custNameDetailsRecord == undefined &&
        callStatus && callStatus.customerInfo">
        <div *ngIf="showHideConnecting || showHideCalldialling
          ||showHide_Connected || showHideCallStatus" style="padding: 20px 2px
          6px 20px">
          <p class="v-cn-txt1" style="color: #000; font-weight: 500">
            Customer Details
          </p>
          <div class="tblrcs1">
            <table class="duringSplitTable">
              <tr style="background-color: #f2f2f2">
                <td><b>Name of the customer</b></td>
                <td><b>Mobile Number </b></td>
              </tr>
              <tr>
                <td>{{ callStatus.customerInfo.customer_name }}</td>
                <td>{{ callStatus.customerInfo.customer_number }}</td>
              </tr>
              <tr *ngIf="cust">
                <td colspan="2">
                  <div style="column-count: 2">
                    <table style="width: 100%" class="ctbl1">
                      <tr *ngFor="let a of arrayCustomVs">
                        <td>
                          <b> {{ a.labelName }} </b>
                        </td>
                        <td>{{ a.valueOfLabel }}</td>
                      </tr>
                      <tr *ngIf="oddOrEven(arrayCustomVs.length)">
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div> -->
      <!--End ByDefault Customer Details Page-->

      <div #show_cust_details2 *ngIf="user.sip_id && callStatus && callStatus.customerInfo &&
        callStatus.status == 'terminated' ">
        <div style="padding: 20px 2px 6px 20px">
          <p class="v-cn-txt1" style="color: #000; font-weight: 500">
            Customer Details
          </p>
          <div class="tblrcs1">
            <table class="duringSplitTable">
              <tr style="background-color: #f2f2f2">
                <td><b>Name of the customer</b></td>
                <td><b>Mobile Number </b></td>
              </tr>
              <tr>
                <td>{{ callStatus.customerInfo.customer_name }}</td>
                <td>{{ callStatus.customerInfo.customer_number }}</td>
              </tr>
              <tr *ngIf="cust">
                <td colspan="2">
                  <div style="column-count: 2">
                    <table style="width: 100%" class="ctbl1">
                      <tr *ngFor="let a of arrayCustomVs">
                        <td>
                          <b> {{ a.labelName }} </b>
                        </td>
                        <td>{{ a.valueOfLabel }}</td>
                      </tr>
                      <tr *ngIf="oddOrEven(arrayCustomVs.length)">
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dc-AfterCallOverLay" *ngIf="showHide_Form_After_DuringForm">
  <div class="dc-afterCall-popup1">
    <div>
      <!-- *ngIf="!user.sip_id" -->

      <div *ngIf="isCheckProjectTypeSBICC">
        <table style="margin-left: 2%;margin-top:1%">
          <tr>
            <td colspan="2">
              <b>{{_cName }}</b>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="font-weight: 500">
              <!-- NEW DATA -->
              {{_cStage}}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="font-weight: 500">
              <!-- {{ type_SSl_SAWD }} -->
              {{bind_LeadSource}}
            </td>
          </tr>
        </table>
        <hr />
      </div>

      <!-- <h2 class="dcT2">After Call Form</h2> -->
      <h2 class="dcT2">Disposition</h2>

      <div *ngIf="isCheckProjectTypeSBICC">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex gap-3 justify-content-end mb-4">
              <!-- UPDATE Button -->
              <button mat-raised-button
                (click)="isDisabledUPDATEDDiv ? $event.stopPropagation() : clickHighlight('update')"
                [ngClass]="{ 'active-button': isVActive('update') }"
                [disabled]="isDisabledUPDATEDDiv"
                class="btn d-flex align-items-center px-4">
                <mat-icon class="me-2">done</mat-icon>
                <span>UPDATE</span>
              </button>

              <!-- REDIAL Button -->
              <button mat-raised-button
                (click)="clickHighlight('callback2')"
                [ngClass]="{ 'active-button': isVActive('callback2') }"
                class="btn d-flex align-items-center px-4">
                <mat-icon class="me-2">call_missed_outgoing</mat-icon>
                <span>REDIAL</span>
              </button>

              <!-- RNR Button -->
              <button mat-raised-button
                (click)="isDisabledRNRDiv ? $event.stopPropagation() : clickHighlight('rnr')"
                [ngClass]="{ 'active-button': isVActive('rnr') }"
                [disabled]="isDisabledRNRDiv"
                class="btn d-flex align-items-center px-4">
                <mat-icon class="me-2">done</mat-icon>
                <span>RNR</span>
              </button>
            </div>
          </div>
        </div>
           
      </div>

      <div *ngIf="!isCheckProjectTypeSBICC">
        <p class="mt-4 mb-3" *ngIf="customerInfo">
          <span class="v-dcTitle1">{{ customerInfo.lead_source }}</span>
        </p>
        <div class="row">
          <div class="col-md-6" *ngIf="customerInfo">
            <span class="v-acf1">Customer Name</span>
            <span class="v-acf2">{{ customerInfo.customer_name }}</span>
          </div>
          <div class="col-md-6" *ngIf="customerInfo">
            <span class="v-acf1">Customer Number</span>
            <span class="v-acf2">{{ customerInfo.customer_number }}</span>
          </div>
        </div>
        <div class="v-dcCallDivy mb-4">
          <div class="dc-ufrrBtn1" *ngIf="typeTab && typeTab != 'rnr'" (click)="clickHighlight('update')"
            [ngClass]="{ activeDCbtn: isVActive('update') }">
            <p class="mb-0">
              <mat-icon class="dcIconx1">done </mat-icon>
            </p>
            <p class="dc-Txtx1">UPDATE</p>
          </div>
          <div class="dc-ufrrBtn1" *ngIf="typeTab && typeTab != 'rnr'" (click)="clickHighlight('callback1')"
            [ngClass]="{ activeDCbtn: isVActive('callback1') }">
            <p class="mb-0">
              <mat-icon class="dcIconx1">date_range </mat-icon>
            </p>
            <p class="dc-Txtx1">FOLLOWUP</p>
          </div>

          <div class="dc-ufrrBtn1" *ngIf="!customerInfo" (click)="clickHighlight('rnr')"
            [ngClass]="{ activeDCbtn: isVActive('rnr') }">
            <p class="mb-0">
              <mat-icon class="dcIconx1">north_east </mat-icon>
            </p>
            <p class="dc-Txtx1">RNR</p>
          </div>

          <div class="dc-ufrrBtn1" *ngIf="typeTab && typeTab != 'rnr'" (click)="clickHighlight('callback2')"
            [ngClass]="{ activeDCbtn: isVActive('callback2') }">
            <p class="mb-0">
              <mat-icon class="dcIconx1"> call_missed_outgoing</mat-icon>
            </p>
            <p class="dc-Txtx1">REDIAL</p>
          </div>
        </div>

        <div class="mt-2" *ngIf="showHideCallBack1">
          <mat-radio-group [ngClass]="'dcAfterRadio'" [value]="SelectTimeModel">
            <mat-radio-button value="SelectTime" style="width: 50%">
              <span class="v-acf1">Select Time </span>

              <div style="position: absolute" class="mt-2">
                <button class="ddl-dd-btn" (click)="clickTimeHighlight('10')"
                  [ngClass]="{ activeTimeBtn: isTimeActive('10') }">
                  10 Mins
                </button>
                <button class="ddl-dd-btn" (click)="clickTimeHighlight('30')"
                  [ngClass]="{ activeTimeBtn: isTimeActive('30') }">
                  30 Mins
                </button>
                <button class="ddl-dd-btn" (click)="clickTimeHighlight('60')"
                  [ngClass]="{ activeTimeBtn: isTimeActive('60') }">
                  60 Mins
                </button>
              </div>
            </mat-radio-button>
            <mat-radio-button value="SelectDay" style="width: 50%">
              <span class="v-acf1">Select Day </span>

              <div style="position: absolute" class="mt-2">
                <table>
                  <tr>
                    <td>
                      <mat-form-field appearance="outline" [ngClass]="'ddl-dc-date1'"
                        style="width: 120px; margin-right: 10px">
                        <input matInput [matDatepicker]="pickerx" placeholder="Date" readonly [(ngModel)]="dateModel"
                          (click)="pickerx.open()" />
                        <mat-datepicker-toggle matSuffix [for]="pickerx">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerx></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td style="position: absolute">
                      <span>
                        <input type="time" id="appt" name="appt" placeholder="Time" [(ngModel)]="timeModel"
                          class="v-dc-time1" /></span>
                    </td>
                  </tr>
                </table>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="showHideCallRNR">
          <div class="text-left">
            <mat-form-field appearance="outline" [ngClass]="'ddl-dd'" style="width: 300px; margin-right: 15px">
              <mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" (selectionChange)="selectRnrReason($event.value)"
                placeholder="Select" [(value)]="selectedRnrReason">
                <mat-option value="{{ r }}" *ngFor="let r of rnroptions">{{
                  r
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="text-left">
						<label>Description</label>
				   </div> -->
          <div class="text-left">
            <mat-form-field appearance="outline" [ngClass]="'ddl-dd'" style="width: 300px; margin-right: 15px">
              <textarea matInput placeholder="Description" [(ngModel)]="rnrDescription" rows="5"
                style="resize: none"></textarea>
            </mat-form-field>
          </div>
          <p class="text-left" style="font-family: poppins; font-size: 16px;
            width: 70%">
            Please Click <span style="color: #3fb90d">Submit</span> to complete
            this call and move on to the next call.
          </p>
          <p style="color: red" *ngIf="showDuringFormError">
            Please fill mandatory details
          </p>
        </div>
      </div>
      <p class="text-right mt-4 mb-2 pt-3" style="position: absolute; bottom:
        10px; right: 40px">
        <button mat-raised-button (click)="endCall()">
          <mat-icon class="me-2">done</mat-icon>
          SUBMIT
        </button>
      </p>
    </div>

    <!--Start ACP-- After Call Disposition-->
    <div class="container" *ngIf="!isCheckProjectTypeSBICC">
      <!-- *ngIf="user.sip_id" -->
      <h2 class="dcT2">Disposition</h2>
      <div>
        <div class="acpRowBtn acpRowBtn1">
          <div class="col-md-12 text-center">
            <button mat-raised-button *ngFor="let item of dc_acp_optionsArray | keyvalue; let i=
              index" [ngClass]="{ activeParent: isActiveParent(item.key) }" (click)="contactableBTn(item.key)">
              {{ item.key }}
            </button>
          </div>
        </div>
        <div class="acpRowBtn">
          <div class="col-md-12 text-center" *ngFor="let item of
            dc_acp_optionsArray | keyvalue">
            <div *ngIf="item.key == _selectedTab">
              <p *ngIf="showHideContactSubBtn || showHideNonContactSubBtn">
                <button *ngFor="let subItem of getSubItem() | keyvalue"
                  [ngClass]="{ activeChild: isActiveChild(subItem.key) }" (click)="clickBtn(subItem.key)">
                  {{ subItem.key }}
                </button>
              </p>

              <p style="
                border-top: 1px solid #ccc;
                padding-top: 15px;
                margin: 14px 0;
                " *ngIf="showHideContactNoInterestedSubBtn">
                <button class="acpRowBtn3" *ngFor="
                  let subChildItem of getChildSubItemNoInterst() | keyvalue
                  " [ngClass]="{
                  activeChildSub: isActiveChildSub(subChildItem.key)
                  }" (click)="
                  clickChildSubBtn(subChildItem.key, subChildItem.value)
                  ">
                  {{ subChildItem.value }}
                </button>
              </p>
              <p style="
                border-top: 1px solid #ccc;
                padding-top: 15px;
                margin: 14px 0;
                " *ngIf="showHideContactNoEligibleSubBtn">
                <button class="acpRowBtn3" *ngFor="
                  let subChildItem of getChildSubItemNoEligible() | keyvalue
                  " [ngClass]="{
                  activeChildSub: isActiveChildSub(subChildItem.key)
                  }" (click)="
                  clickChildSubBtn(subChildItem.key, subChildItem.value)
                  ">
                  {{ subChildItem.value }}
                </button>
              </p>

              <div style="position: fixed; width: 95%; bottom: 0">
                <div *ngIf="showHideConCalander" style="text-align: center;
                  width: 80%">
                  <mat-form-field style="width: 215px; margin-right: 10px" appearance="outline">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="pickerAcpDate" readonly (click)="pickerAcpDate.open()"
                      [(ngModel)]="acpContactStartDate" [min]="minDate1" />
                    <mat-datepicker-toggle matSuffix [for]="pickerAcpDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerAcpDate></mat-datepicker>
                  </mat-form-field>

                  <input style="
                    border-radius: 4px;
                    width: 130px;
                    outline: none;
                    border: 2px solid #fd6c21;
                    height: 40px;
                    text-indent: 5px;
                    position: relative;
                    bottom: 5px;
                    " type="time" id="timeId" name="time1" [(ngModel)]="timeAcpModel" />
                </div>
                <br />
                <div style="text-align: left">
                  <mat-form-field style="width: 78%">
                    <textarea matInput placeholder="Remark" [(ngModel)]="acpRemarkModel"></textarea>
                  </mat-form-field>
                </div>
                <p class="text-right" style="width: 78%" (click)="submitDispositionACP()">
                  <button mat-raised-button>
                    <mat-icon class="me-2">done</mat-icon>
                    SUBMIT
                  </button>
                </p>

                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End ACP-- After Call Disposition-->
  </div>
</div>

<div class="dc-popupoverlayChat" *ngIf="
  chatStatus &&
  chatStatus.slot1 &&
  chatStatus.groupList[chatStatus.slot1.index]
  ">
  <div class="dc-delete-popupChat" [ngClass]="showHideMinimize == false ?
    'my_class2Min' : ''">
    <div class="dc-chatDivy1">
      <table style="width: 100%">
        <tr>
          <td>
            <span class="v-dcTitle1">{{ chatStatus.slot1._dataTabName }}</span>
          </td>
          <td class="text-right">
            <mat-icon class="dcMinimizeChat1" *ngIf="showHideMinimize" (click)="minimizeClick('Minimize')">minimize
            </mat-icon>
            <mat-icon class="dcMinimizeChat1" *ngIf="showHideMaximize" (click)="minimizeClick('Maximize')"
              style="align-items: center; top: 1px">add
            </mat-icon>
            <mat-icon (click)="removeSlot(1)" class="dcCloseChat1">close
            </mat-icon>
          </td>
        </tr>
      </table>

      <!-- <div id="chatBox" class="message_area chats">
				<div class="incoming-msg" >
					<div><img src="../assets/desktop_calling/chatMenPic.png" style="width:35px;"></div>
					<div class="incoming"><p class="incoming-p"> <span>hiii kruna swedqwe eqwe eqw ewqeqwe wqeqwl</span></p></div>
				</div>

				<div class="outgoing-message">
					<div class="outgoing">
						<p class="outgoing-p"> <span>hello veenit dqweqw qweqwe eqweqwe ewqeqwe ewqeqwe wqeqw ewqeqwew	weew weeqw</span></p>
					</div>
					<div><img src="../assets/desktop_calling/chatMenPic.png" style="width: 35px;margin-right: 10px;"></div>
				</div>
			</div> -->
      <div id="messageBody1" class="message_area chats"
        [ngStyle]="{ display: showHideMinimize == false ? 'none' : 'block' }">
        <!-- *ngFor="let msg of chatStatus.groupList[chatStatus.slot1.index].messages;let $msgIndex=index" -->
        <div *ngFor="
          let msg of chatStatus.groupList[chatStatus.slot1.index].messages;
          let $msgIndex= index
          ">
          <div class="incoming-msg" *ngIf="myUser.id != msg.from_id">
            <div style="width: 45px">
              <img src="../assets/desktop_calling/chatMenPic.png" style="width:
                35px" />
            </div>
            <!-- <h4>29-11-2020</h4> -->
            <div class="incoming">
              <p *ngIf="msg.message_type == 'TEXT'" class="incoming-p">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }}
                </span>
                <span *ngIf="checkIfYoutubeLink(msg.message) == false" style="display: block; padding: 6px 0 2px 0">{{
                  msg.message }}</span>
                <span *ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
                    [src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
                </span>
                <br />
                <span class="time_date">
                  {{msg.date | date: "dd-MM-yyyy h:mm a" }}
                </span>
              </p>
              <p class="incoming-p" *ngIf="msg.message_type == 'IMAGE'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }}
                </span>
                <img src="{{ msg.message }}" style="width: 100%" />
                <span class="time_date">
                  {{ msg.date | date : "dd-MM-yyyy h:mm a" }}
                </span>
              </p>
              <p class="incoming-p" *ngIf="msg.message_type == 'DOCUMENT'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><a href="{{ msg.message }}" download>
                  <mat-icon>file_download </mat-icon>
                </a>
                <span class="time_date">
                  {{ msg.date | date : "dd-MM-yyyy h:mm a" }}
                </span>
              </p>
              <p class="incoming-p" *ngIf="msg.message_type == 'AUDIO'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><audio style="width: 100%" controls>
                  <source src="{{ msg.message }}" type="audio/ogg" />
                  <source src="{{ msg.message }}" type="audio/mpeg" />
                </audio>
                <span class="time_date">
                  {{ msg.date | date : "dd-MM-yyyy h:mm a" }}
                </span>
              </p>
            </div>
          </div>

          <div class="outgoing-message" *ngIf="myUser.id == msg.from_id" style="margin-top: -8px">
            <!-- <h4>30-11-2020</h4> -->
            <div class="outgoing">
              <p class="outgoing-p" *ngIf="msg.message_type == 'TEXT'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><span
                  *ngIf="checkIfYoutubeLink(msg.message) == false" style="
                  display: block;
                  padding: 6px 0 2px 0;
                  word-break: break-all;
                  ">{{ msg.message }}</span>
                <span *ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
                    [src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
                </span>
                <br />
                <span class="time_date">
                  <span *ngIf="msg.to_id">
                    <mat-icon [ngStyle]="{ color: checkSeenOutgoing(msg) }"
                      style="vertical-align: middle; margin-right: 5px" [matTooltip]="tootlTipText">
                      <!-- done_all -->
                      {{ iconDoneOrDoneAll }}
                    </mat-icon>
                  </span>
                  {{ msg.date | date : "dd-MM-yyyy h:mm a" }}
                </span>
              </p>
              <p class="outgoing-p" *ngIf="msg.message_type == 'IMAGE'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><img src="{{ msg.message }}" style="width: 100%; padding-bottom:
                  15px" />
                <span class="time_date">
                  <span *ngIf="msg.to_id">
                    <mat-icon [ngStyle]="{ color: checkSeenOutgoing(msg) }"
                      style="vertical-align: middle; margin-right: 5px">
                      <!-- done_all -->
                      {{ iconDoneOrDoneAll }}
                    </mat-icon> </span>{{ msg.date | date : "dd-MM-yyyy h:mm a"
                  }}
                </span>
              </p>
              <p class="outgoing-p" *ngIf="msg.message_type == 'DOCUMENT'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><a href="{{ msg.message }}" download>
                  <mat-icon>file_download </mat-icon>
                </a>
                <span class="time_date">
                  <span *ngIf="msg.to_id">
                    <mat-icon [ngStyle]="{ color: checkSeenOutgoing(msg) }"
                      style="vertical-align: middle; margin-right: 5px">
                      <!-- done_all -->
                      {{ iconDoneOrDoneAll }}
                    </mat-icon> </span>{{ msg.date | date : "dd-MM-yyyy h:mm a"
                  }}
                </span>
              </p>
              <p class="outgoing-p" *ngIf="msg.message_type == 'AUDIO'">
                <span style="font-size: 11px; display: block; color: black">
                  {{ msg.user == "Server" ? msg.to_id : msg.user }} </span><audio style="width: 100%" controls>
                  <source src="{{ msg.message }}" type="audio/ogg" />
                  <source src="{{ msg.message }}" type="audio/mpeg" />
                </audio>
                <span class="time_date">
                  <span *ngIf="msg.to_id">
                    <mat-icon [ngStyle]="{ color: checkSeenOutgoing(msg) }"
                      style="vertical-align: middle; margin-right: 5px">
                      <!-- done_all -->
                      {{ iconDoneOrDoneAll }}
                    </mat-icon> </span>{{ msg.date | date : "dd-MM-yyyy h:mm a"
                  }}
                </span>
              </p>
            </div>
            <div style="width: 45px">
              <img src="../assets/desktop_calling/chatMenPic.png" style="width: 35px; margin-right: 10px" />
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute; bottom: 0px"
        [ngStyle]="{ display: showHideMinimize == false ? 'none' : 'block' }">
        <div class="dc-sendChatDiv1">
          <table style="width: 100%">
            <tr>
              <td>
                <input type="text" id="text" (keyup)="onTextMessage1()" [(ngModel)]="txtmsg" class="sendInputTB"
                  placeholder="Type a message
                  here…" required="true" [disabled]="socketConnection" (keydown.enter)="
                  sendMessage_With_Enter(txtmsg, chatStatus.slot1.index, 1)
                  " autocomplete="off" />
              </td>
              <td style="position: relative; top: 2px">
                <!-- <mat-icon matSuffix class="vDcMic mr-2">mic</mat-icon> -->
                <mat-icon class="vDcMic mr-2" style="cursor: pointer" *ngIf="showHideStartMic1"
                  (click)="startRecordingBtn(1)">mic</mat-icon>
                <mat-icon class="vDcMic mr-2" *ngIf="showHideStopMic1" style="
                  color: #d7392d;
                  border: 2px solid #d7392d;
                  cursor: pointer;
                  " (click)="stopRecordingBtn(1)">mic
                </mat-icon>

                <mat-icon matSuffix class="vDcAttachment mr-2" (click)="attachmnetClick(1)">attachment</mat-icon>
                <mat-icon matSuffix class="vDcSend mr-2" (click)="
                  sendMessage_With_Enter(txtmsg, chatStatus.slot1.index, 1)
                  ">send</mat-icon>
              </td>
              <!-- <td style="position: relative;top:2px;">
							<mat-icon matSuffix class="vDcMic mr-2">mic</mat-icon>
							<mat-icon matSuffix class="vDcAttachment mr-2" (click)="attachmnetClick()">attachment</mat-icon>
							<mat-icon matSuffix class="vDcSend mr-2">send</mat-icon>
						</td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dc-popupoverlayNotification" *ngIf="showHideNotificationPopup">
  <div class="dc-delete-popupNotification">
    <!-- <p class="text-right" style="height: 0">
      <span (click)="closeNotification()">
        <mat-icon class="dc-close-notif-popup">close</mat-icon>
      </span>
    </p> -->
    <div class="p-4">
      <p>
        <mat-icon class="mr-2" style="vertical-align: bottom">notifications</mat-icon>
        <span class="v-dcTitle1">Notification</span>
        <span style="display: none"><button class="markBtnx2" (click)="clickMarkasRead()">
            Mark as Read
          </button></span>
      </p>

      <hr />
      <div style="overflow: auto; height: 250px">
        <p *ngIf="
          notifications == undefined ||
          (notifications && notifications.length == 0)
          ">
          No notifications found
        </p>
        <div *ngFor="let v of notifications; let i= index" (click)="selectUnreadMasg(v, i)" [ngStyle]="{
          'background-color': v.status === 'UNREAD' ? '#f2f0f0' : '#fff'
          }">
          <p class="p-1">
            <span class="v-acf1" style="color: #0f659b"> {{ v.title }} </span>
          </p>
          <p class="v-notifiText p-1">{{ v.message }}</p>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button mat-raised-button (click)="closeNotification()">
          <mat-icon>close</mat-icon>
 CLOSE
        </button>
      </div>
    </div>
  </div>
</div>

<div class="dc-popupoverlayAttachment" *ngIf="showHideAttachmentPopup">
  <div class="dc-delete-popupAttachment">
    <p class="text-right" style="height: 0">
      <span (click)="closeAttachment()">
        <mat-icon class="dc-close-notif-popup">close</mat-icon>
      </span>
    </p>
    <div class="p-4 text-center">
      <p><span class="v-dcTitle1">Select An Attachment Option</span></p>
      <div class="attacDiv1">
        <div>
          <img src="../assets/desktop_calling/attachmentImgA.png" style="width:
            80px" />
          <p class="v-notifiText pt-2" style="color: #0f659b">Documents</p>
        </div>
        <div>
          <img src="../assets/desktop_calling/attachmentImgB.png" style="width:
            80px" />
          <p class="v-notifiText pt-2" style="color: #0f659b">Image</p>
        </div>
        <div>
          <img src="../assets/desktop_calling/attachmentImgC.png" style="width:
            80px" />
          <p class="v-notifiText pt-2" style="color: #0f659b">Audio</p>
        </div>
        <!-- <div>
				<img src="../assets/desktop_calling/attachmentImgD.png" style="width:80px;"/>
               <p class="v-notifiText pt-2"  style="color: #0f659b;">Video</p>
			</div> -->
      </div>
      <div style="display: flex; justify-content: center">
        <div style="width: 150px">
          <div class="aa-btnUpload">
            <span class="label"> Choose File </span>
            <input id="file-input" type="file" class="aa-uploadBox" (change)="changeFile($event, popupSlotIndex)"
              file-model="myFile" />
            <br />
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <span *ngIf="popupSlotIndex == 1" style="word-break: break-all">{{
          txtmsg
          }}</span>
        <span *ngIf="popupSlotIndex == 2" style="word-break: break-all">{{
          txtmsg2
          }}</span>
        <span *ngIf="popupSlotIndex == 3" style="word-break: break-all">{{
          txtmsg3
          }}</span>
      </div>
    </div>
  </div>
</div>

<div class="dc-popupoverlayInformation" style="display: none">
  <div class="dc-delete-popupInformation">
    <div class="p-4">
      <p>
        <mat-icon class="mr-2" style="vertical-align: bottom; color: #fd6c21">info</mat-icon>
        <span class="v-dcTitle1">Information</span>
      </p>
      <hr />
      <div>
        <!-- <p class="infix1">Are you Really on Break?</p> -->
        <!-- <p class="infix1">Get Back to Active Mode</p> -->
        <p class="infix1">You Have New Campaign on priority to Call</p>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 mt-4">
          <p class="text-right">
            <!-- <button  class="okBtn2" style="margin-right: 18px;">NO</button>
						<button  class="subBtnx2" style="margin-right: 18px;">YES</button> -->
            <button class="subBtnx2" style="margin-right: 18px">OK</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--start popup for Add on 1 -->

<div class="dc-popupoverlayAddOn1" *ngIf="showPopupAddOn1">
  <div class="dc-delete-popupAddOn1">
    <div class="p-4">
      <p><span class="v-dcTitle1">Add-on 1</span></p>
      <hr />
      <div>
        <table class="addonTable">
          <tr>
            <td><b>Addon Name*</b></td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>First Name*</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="addOnFirstName"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput placeholder="Middle Name" autocomplete="off" [(ngModel)]="addOnMiddleName"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" autocomplete="off" [(ngModel)]="addOnLastName"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr style="position: relative; bottom: 10px">
            <td><b>Gender*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnGender" class="uperCase1">
                <mat-radio-button value="Male">Male</mat-radio-button>
                <mat-radio-button value="Female" class="pl-3">Female</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td><b>Addon Mobile No*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Mobile No.*</mat-label>
                <input matInput placeholder="Mobile No." autocomplete="off"
                  [(ngModel)]="addonMobile" (keypress)="onlyNumberValidationCode($event)" maxlength="10" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><b>Addon Email*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Email*</mat-label>
                <input matInput class="uperCase1" placeholder="Email" autocomplete="off"
                  [(ngModel)]="addOnEmail" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td style="vertical-align: top"><b>Relationship*</b></td>
            <td colspan="3">
              <!-- <mat-checkbox [(ngModel)]="addOnCbSpouse">Spouse</mat-checkbox>
							<mat-checkbox class="pl-3" [(ngModel)]="addOnCbParent">Parent</mat-checkbox>
							<mat-checkbox class="pl-3" [(ngModel)]="addOnCbSibling">Sibling</mat-checkbox>
							<mat-checkbox class="pl-3" [(ngModel)]="addOnCbSon">Son</mat-checkbox>
							<mat-checkbox class="pl-3" [(ngModel)]="addOnCbDaughter">Daughter</mat-checkbox> -->
              <mat-radio-group [(ngModel)]="addOnRelationShip" class="uperCase1">
                <mat-radio-button value="Spouse">Spouse</mat-radio-button>
                <mat-radio-button value="Parent" class="pl-3">Parent</mat-radio-button>
                <mat-radio-button value="Sibling" class="pl-3">Sibling</mat-radio-button>
                <mat-radio-button value="Son" class="pl-3">Son</mat-radio-button>
                <mat-radio-button value="Daughter" class="pl-3">Daughter</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td class="mt-2" style="width: 210px">
              <b>Addon Stay with Primary*</b>
            </td>
            <td colspan="3" class="mt-2" style="vertical-align: bottom">
              <mat-radio-group [(ngModel)]="addOnStayPrimary" class="uperCase1">
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No" class="pl-3" checked>No</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </table>
      </div>
    
        <div class="d-flex justify-content-end gap-3">
          <!-- <p class="text-right"> -->
            <button mat-raised-button (click)="cancelAddon1()">
              <mat-icon>close</mat-icon>
              CLOSE
            </button>
            <button mat-raised-button (click)="saveAddonBtn()">
              <mat-icon>save</mat-icon>
              SAVE
            </button>
          <!-- </p> -->
        </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<!--end popup for Add on 1 -->



<div class="dc-StartOverLay" *ngIf="showHideNextCustomerUIScreen">
  <div class="dc-start-popup1">
    <div class="nextStartDiv">
      <table class="nextStartTable">
        <!-- duringSplitTable -->
        <table class="previewSplitTable">
          <tr>
            <td style="font-weight: 500" matTooltip="{{_nextCustomerName}}">
              {{ _nextCustomerName.length > 25 ? _nextCustomerName.substring(0, 25) + '...' : _nextCustomerName }}
            </td>
            <td style="font-weight: 500">{{_nextCustomerNumber}}</td>
          </tr>
           <tr>
            <td style="font-weight: 500">
              {{ _next_Stage}}
            </td>
            <td style="font-weight: 500" matTooltip="{{_next_CampaignName}}">
              {{ _next_CampaignName.length > 30 ? _next_CampaignName.substring(0, 20) + '...' : _next_CampaignName }}
            </td>
          </tr>
        </table>
        <tr>
          <td colspan="2" class="pt-3"><b class="pr-2">Customer Details</b>
          </td>
        </tr>
        <tr>
          <td colspan="2" *ngIf="arrayCusVSNext">
            <div style="overflow: hidden auto;height: calc(100vh - 375px);">
              <div style="column-count: 2">
                <table style="width: 100%;font-size: 12px;line-height: 30px;" class="ctbl1">
                  <tr *ngFor="let b of arrayCusVSNext">
                    <td>
                      <b> {{ b.labelName }} </b>
                    </td>
                    <td>{{ b.valueOfLabel }}</td>
                  </tr>
                  <tr *ngIf="oddOrEven(arrayCusVSNext.length)">
                    <td>--</td>
                    <td>--</td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="timerDiv2 text-center">
            <div class="circleProgress_wrapper" id="id1">
              <div class="wrapper right1">
                <div class="circleProgress rightcircle right_cartoon"></div>
              </div>
              <div class="wrapper left">
                <div class="circleProgress leftcircle left_cartoon"></div>
              </div>
              <span id="timersetID1">
                {{timeLeft}}
              </span>
            </div>
            <div class="d-flex justify-content-center gap-3">
              <button mat-raised-button (click)="stopNextBtn()">
                <mat-icon>stop</mat-icon>
                STOP</button>
              <button mat-raised-button (click)="DialBtn()">
                <mat-icon>phone</mat-icon>
                DIAL NOW</button>
              <button mat-raised-button (click)="skipNextButton()">
                <mat-icon>skip_next</mat-icon>
                SKIP</button>
            </div>
          </td>
        </tr>

      </table>


    </div>

  </div>
</div>


<div class="dc-popupoverlayInformationView " *ngIf="showHideViewCust1">
  <div class="dc-delete-popupInformationView ">
    <div class="p-4">
      <p>

        <span class="v-dcTitle1 ">VIEW</span>
      </p>
      <hr />
      <div class="divtableView1">


        <table border="1" class="tableView1">
          <tr>
            <td> <b>Customer Name</b></td>
            <td>{{View_CustomerName}} </td>
            <td>
              <b>Date of Birth</b>
            </td>
            <td> {{View_DateofBirth}} </td>
          </tr>
          <tr>
            <td> <b>Nationality</b></td>
            <td>{{view_natioanality}} </td>
            <td>
              <b>PAN Number</b>
            </td>
            <td> {{View_PANNumber}} </td>
          </tr>
          <tr>
            <td><b>Father Name</b> </td>
            <td> {{View_FatherName}}</td>
            <td> <b>Mother
                Name</b></td>
            <td>{{View_MotherName}} </td>
          </tr>

          <tr>
            <td> <b>Residence Address</b></td>
            <td> {{View_ResidenceAddress}}
            </td>
            <td><b>Residence PINCODE</b> </td>
            <td>
              {{View_ResidencePINCODE}}</td>
          </tr>

          <tr>
            <td> <b>Residence Land Mark</b></td>
            <td>{{View_LandMark}} </td>
            <td>
              <b>Zone</b>
            </td>
            <td>{{view_Zone}} </td>
          </tr>
          <tr>
            <td> <b>Mobile</b></td>
            <td>{{view_Mobile}} </td>
            <td> <b>Personal
                Email ID</b></td>
            <td>{{view_PersonalEmailId}} </td>
          </tr>

          <tr>
            <td> <b>Company Name</b></td>
            <td> {{View_CompanyName}}</td>
            <td>
              <b>Designation</b>
            </td>
            <td> {{View_Designation}} </td>
          </tr>

          <tr>
            <td> <b>Company Address</b></td>
            <td colspan="3">
              {{View_CompanyAddress}} </td>
          </tr>
          <tr>
            <td> <b>Company Landmark</b></td>
            <td> {{View_CompanyAddress}}
            </td>
            <td><b>Company PINCODE</b> </td>
            <td> {{View_CompanyPINCODE}}
            </td>
          </tr>
          <tr>
            <td> <b>Official Email ID</b></td>
            <td> {{View_OfficialEmailID}}</td>
            <td>
              <b>Occupation Type</b>
            </td>
            <td> {{View_OccupationType}}</td>
          </tr>
          <tr>
            <td> <b>Net Income</b></td>
            <td> {{View_NetIncome}}</td>
            <td> <b>Remarks</b></td>
            <td>
              {{View_Remarks}}</td>
          </tr>
        </table>
        <div *ngIf="checkLengthAddon1==true">
          <div *ngIf="obj_AddOn_One && obj_AddOn_One.addOnFirstName !=''">
            <h3 class="text-center m-3">Addon 1</h3>
            <table border="1" class="tableView1">
              <tr>
                <td><b>Addon Name </b></td>
                <td>{{View_Addon1Fullname}} </td>
                <td><b>Addon
                    Email </b></td>
                <td> {{View_Addon1Email}} </td>
              </tr>
              <tr>
                <td><b> Gender </b></td>
                <td> {{View_Addon1Gender}}</td>
                <td><b>
                    Relationship </b></td>
                <td> {{View_Addon1Relationship}}</td>
              </tr>
              <tr>
                <td><b> Addon Mobile Number </b></td>
                <td>{{View_Addon1Mobile}}
                </td>
                <td><b> Add Stay With Primary </b></td>
                <td>{{View_Addon1StayPrimary}}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="checkLengthAddon2==true">
          <div *ngIf="obj_AddOn_Two && obj_AddOn_Two.addOnFirstName !=''">
            <h3 class="text-center m-3">Addon 2</h3>
            <table border="1" class="tableView1">
              <tr>
                <td><b>Addon Name </b></td>
                <td>{{View_Addon2Fullname}} </td>
                <td><b>Addon
                    Email </b></td>
                <td> {{View_Addon2Email}} </td>
              </tr>
              <tr>
                <td><b> Gender </b></td>
                <td> {{View_Addon2Gender}}</td>
                <td><b>
                    Relationship </b></td>
                <td> {{View_Addon2Relationship}}</td>
              </tr>
              <tr>
                <td><b> Addon Mobile Number </b></td>
                <td>{{View_Addon2Mobile}}
                </td>
                <td><b> Add Stay With Primary </b></td>
                <td>{{View_Addon2StayPrimary}}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="checkLengthAddon3==true">
          <div *ngIf="obj_AddOn_Three && obj_AddOn_Three.addOnFirstName !=''">
            <h3 class="text-center m-3">Addon 3</h3>
            <table border="1" class="tableView1">
              <tr>
                <td><b>Addon Name </b></td>
                <td>{{View_Addon3Fullname}} </td>
                <td><b>Addon
                    Email </b></td>
                <td> {{View_Addon3Email}} </td>
              </tr>
              <tr>
                <td><b> Gender </b></td>
                <td> {{View_Addon3Gender}}</td>
                <td><b>
                    Relationship </b></td>
                <td> {{View_Addon3Relationship}}</td>
              </tr>
              <tr>
                <td><b> Addon Mobile Number </b></td>
                <td>{{View_Addon3Mobile}}
                </td>
                <td><b> Add Stay With Primary </b></td>
                <td>{{View_Addon3StayPrimary}}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="checkLengthAddon4==true">
          <div *ngIf="obj_AddOn_Four && obj_AddOn_Four.addOnFirstName !=''">
            <h3 class="text-center m-3">Addon 4</h3>
            <table border="1" class="tableView1">
              <tr>
                <td><b>Addon Name </b></td>
                <td>{{View_Addon4Fullname}} </td>
                <td><b>Addon
                    Email </b></td>
                <td> {{View_Addon4Email}} </td>
              </tr>
              <tr>
                <td><b> Gender </b></td>
                <td> {{View_Addon4Gender}}</td>
                <td><b>
                    Relationship </b></td>
                <td> {{View_Addon4Relationship}}</td>
              </tr>
              <tr>
                <td><b> Addon Mobile Number </b></td>
                <td>{{View_Addon4Mobile}}
                </td>
                <td><b> Add Stay With Primary </b></td>
                <td>{{View_Addon4StayPrimary}}
                </td>
              </tr>
            </table>
          </div>
        </div>








      </div>
      <div class="row mt-4 d-flex justify-content-end gap-3">
        <div class="col-md-12 mt-4 d-flex justify-content-end gap-3">
          <p>
            <button class="subBtnx2View " mat-raised-button (click)="closeView1()">
              <mat-icon class="me-2">close</mat-icon>
              OK</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<!--start popup for Add on 2 -->

<div class="dc-popupoverlayAddOn1" *ngIf="showPopupAddOn2">
  <div class="dc-delete-popupAddOn1">
    <div class="p-4">
      <p><span class="v-dcTitle1">Add-on 2</span></p>
      <hr />
      <div>
        <table class="addonTable">
          <tr>
            <td><b>Addon Name*</b></td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>First Name*</mat-label>
                <input matInput placeholder="First  Name*" autocomplete="off"
                  [(ngModel)]="addOnFirstName2" (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput placeholder="Middle Name" autocomplete="off"
                  [(ngModel)]="addOnMiddleName2" (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" autocomplete="off" [(ngModel)]="addOnLastName2"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr style="position: relative; bottom: 10px">
            <td><b>Gender*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnGender2" class="uperCase1">
                <mat-radio-button value="Male">Male</mat-radio-button>
                <mat-radio-button value="Female" class="pl-3">Female</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td><b>Addon Mobile No*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Mobile No.*</mat-label>
                <input matInput placeholder="Mobile No." autocomplete="off"
                  [(ngModel)]="addonMobile2" (keypress)="onlyNumberValidationCode($event)" maxlength="10" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><b>Addon Email*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Email*</mat-label>
                <input matInput placeholder="Email" autocomplete="off" [(ngModel)]="addOnEmail2" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td style="vertical-align: top"><b>Relationship*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnRelationShip2" class="uperCase1">
                <mat-radio-button value="Spouse">Spouse</mat-radio-button>
                <mat-radio-button value="Parent" class="pl-3">Parent</mat-radio-button>
                <mat-radio-button value="Sibling" class="pl-3">Sibling</mat-radio-button>
                <mat-radio-button value="Son" class="pl-3">Son</mat-radio-button>
                <mat-radio-button value="Daughter" class="pl-3">Daughter</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td class="mt-2" style="width: 210px">
              <b>Addon Stay with Primary*</b>
            </td>
            <td colspan="3" class="mt-2" style="vertical-align: bottom">
              <mat-radio-group [(ngModel)]="addOnStayPrimary2" class="uperCase1">
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No" class="pl-3" checked>No</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </table>
      </div>
      <div class="d-flex justify-content-end gap-3">
        <button mat-raised-button (click)="cancelAddon2()">
          <mat-icon>close</mat-icon>
          CLOSE
        </button>
        <button mat-raised-button (click)="saveAddonBtn2()">
          <mat-icon>save</mat-icon>
          SAVE
        </button>
      </div>
      <!-- <div class="row mt-4">
        <div class="col-md-12 mt-4">
          <p class="text-right">
            <button class="okBtn2" style="margin-right: 18px" (click)="cancelAddon2()">
              Close
            </button>
            <button class="subBtnx2" style="margin-right: 18px" (click)="saveAddonBtn2()">
              Save
            </button>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!--end popup for Add on 2 -->




<!--start popup for Add on 3-->

<div class="dc-popupoverlayAddOn1" *ngIf="showPopupAddOn3">
  <div class="dc-delete-popupAddOn1">
    <div class="p-4">
      <p><span class="v-dcTitle1">Add-on 3</span></p>
      <hr />
      <div>
        <table class="addonTable">
          <tr>
            <td><b>Addon Name*</b></td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>First Name*</mat-label>
                <input matInput placeholder="First Name*" autocomplete="off" [(ngModel)]="addOnFirstName3"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput placeholder="Middle Name" autocomplete="off" [(ngModel)]="addOnMiddleName3"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" autocomplete="off" [(ngModel)]="addOnLastName3"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr style="position: relative; bottom: 10px">
            <td><b>Gender*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnGender3" class="uperCase1">
                <mat-radio-button value="Male">Male</mat-radio-button>
                <mat-radio-button value="Female" class="pl-3">Female</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td><b>Addon Mobile No*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Mobile No.*</mat-label>
                <input matInput placeholder="Mobile No." autocomplete="off" [(ngModel)]="addonMobile3"
                  (keypress)="onlyNumberValidationCode($event)" maxlength="10" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><b>Addon Email*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Email*</mat-label>
                <input matInput placeholder="Email" autocomplete="off" [(ngModel)]="addOnEmail3" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td style="vertical-align: top"><b>Relationship*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnRelationShip3" class="uperCase1">
                <mat-radio-button value="Spouse">Spouse</mat-radio-button>
                <mat-radio-button value="Parent" class="pl-3">Parent</mat-radio-button>
                <mat-radio-button value="Sibling" class="pl-3">Sibling</mat-radio-button>
                <mat-radio-button value="Son" class="pl-3">Son</mat-radio-button>
                <mat-radio-button value="Daughter" class="pl-3">Daughter</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td class="mt-2" style="width: 210px">
              <b>Addon Stay with Primary*</b>
            </td>
            <td colspan="3" class="mt-2" style="vertical-align: bottom">
              <mat-radio-group [(ngModel)]="addOnStayPrimary3" class="uperCase1">
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No" class="pl-3" checked>No</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </table>
      </div>
      <div class="d-flex justify-content-end gap-3">
        <button mat-raised-button (click)="cancelAddon3()">
          <mat-icon>close</mat-icon>
          CLOSE
        </button>
        <button mat-raised-button (click)="saveAddonBtn3()">
          <mat-icon>save</mat-icon>
          SAVE
        </button>
      </div>

      <!-- <div class="row mt-4">
        <div class="col-md-12 mt-4">
          <p class="text-right">
            <button class="okBtn2" style="margin-right: 18px" (click)="cancelAddon3()">
              Close
            </button>
            <button class="subBtnx2" style="margin-right: 18px" (click)="saveAddonBtn3()">
              Save
            </button>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!--end popup for Add on 3 -->


<!--start popup for Add on 4 -->

<div class="dc-popupoverlayAddOn1" *ngIf="showPopupAddOn4">
  <div class="dc-delete-popupAddOn1">
    <div class="p-4">
      <p><span class="v-dcTitle1">Add-on 4</span></p>
      <hr />
      <div>
        <table class="addonTable">
          <tr>
            <td><b>Addon Name*</b></td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>First Name*</mat-label>
                <input matInput placeholder="First Name*" autocomplete="off" [(ngModel)]="addOnFirstName4"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput placeholder="Middle Name" autocomplete="off" [(ngModel)]="addOnMiddleName4"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" autocomplete="off" [(ngModel)]="addOnLastName4"
                  (keypress)="omit_special_char_code($event)" class="uperCase1" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr style="position: relative; bottom: 10px">
            <td><b>Gender*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnGender4" class="uperCase1">
                <mat-radio-button value="Male">Male</mat-radio-button>
                <mat-radio-button value="Female" class="pl-3">Female</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td><b>Addon Mobile No*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Mobile No.*</mat-label>
                <input matInput placeholder="Mobile No." autocomplete="off" [(ngModel)]="addonMobile4"
                  (keypress)="onlyNumberValidationCode($event)" maxlength="10" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><b>Addon Email*</b></td>
            <td colspan="2">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Email*</mat-label>
                <input matInput placeholder="Email" autocomplete="off" [(ngModel)]="addOnEmail4" class="uperCase1" />
              </mat-form-field>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td style="vertical-align: top"><b>Relationship*</b></td>
            <td colspan="3">
              <mat-radio-group [(ngModel)]="addOnRelationShip4" class="uperCase1">
                <mat-radio-button value="Spouse">Spouse</mat-radio-button>
                <mat-radio-button value="Parent" class="pl-3">Parent</mat-radio-button>
                <mat-radio-button value="Sibling" class="pl-3">Sibling</mat-radio-button>
                <mat-radio-button value="Son" class="pl-3">Son</mat-radio-button>
                <mat-radio-button value="Daughter" class="pl-3">Daughter</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="4"><br /></td>
          </tr>
          <tr>
            <td class="mt-2" style="width: 210px">
              <b>Addon Stay with Primary*</b>
            </td>
            <td colspan="3" class="mt-2" style="vertical-align: bottom">
              <mat-radio-group [(ngModel)]="addOnStayPrimary4" class="uperCase1">
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No" class="pl-3" checked>No</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </table>
      </div>
      <div class="d-flex justify-content-end gap-3">
        <button mat-raised-button (click)="cancelAddon4()">
          <mat-icon>close</mat-icon>
          CLOSE
        </button>
        <button mat-raised-button (click)="saveAddonBtn4()">
          <mat-icon>save</mat-icon>
          SAVE
        </button>
      </div>
      <!-- <div class="row mt-4">
        <div class="col-md-12 mt-4">
          <p class="text-right">
            <button class="okBtn2" style="margin-right: 18px" (click)="cancelAddon4()">
              Close
            </button>
            <button class="subBtnx2" style="margin-right: 18px" (click)="saveAddonBtn4()">
              Save
            </button>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>


<!--end popup for Add on 4-->