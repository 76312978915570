import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import { AgentInfoDialogComponent } from '../agent-info-dialog/agent-info-dialog.component';
import { OjtAgentInfoComponent } from '../ojt-agent-info/ojt-agent-info.component';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-on-job-training-agent',
  templateUrl: './on-job-training-agent.component.html',
  styleUrls: ['./on-job-training-agent.component.scss']
})
export class OnJobTrainingAgentComponent implements OnInit {
  displayedColumns = ['name', 'email', 'id','batch_code','status','score','uncertify_count','edit'];
  dataSource = new MatTableDataSource();  
  api_end_point:any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private dialog: MatDialog,private API: ApiService,private myService:MyserviceService) { }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
 this.getOJTAgent();
  }

getOJTAgent(){
    this.API.getAgentList("OJT",null,null,null,this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
         let elementData=_response;
         this.dataSource = new MatTableDataSource(elementData);
         this.dataSource.paginator = this.paginator;
         this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
          return data.name.toString().toLowerCase().includes(filter) || data.email.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase().includes(filter) || data.batch_code==filter|| data.status.toString().toLowerCase().includes(filter)
        };
     
      }
    }
    )
}


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
	getStatusColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Active':
				obj.color = 'gray';
				break;
			case 'Inactive':
				obj.color = 'red';
				break;
		}
		return obj;
	}

	openOjtAgentsView(element:any) {
		const dialogRef = this.dialog.open(OjtAgentInfoComponent, {
			width: '50%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		  });
	}
}
