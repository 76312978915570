<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>

<div class="row" style="padding-top:50px;">

  <div class="col-md-12">

  <div class="container-fluid main-section-layout" style="padding-top: 30px;">
   
  <div class="m-3">
  <mat-card class="mt-cd">
<br>
	<div class="row" style="margin: 10px 0 10px -25px;padding: 18px 0px 0px 18px;">
		<div class="col-md-3">
			<mat-label class="matLavel">Select Language</mat-label><br>
			<!-- <mat-form-field class="wd-100"> -->
			<mat-select placeholder="Select Language" [(ngModel)]="selectedLang" class="languageDDl">
				<mat-option value="en-US">English(en-US)</mat-option>
				<mat-option value="en-UK">English(en-UK)</mat-option>
				<mat-option value="en-IN">English(en-IN)</mat-option>
				<mat-option value="hi-IN">Hindi(hi-IN)</mat-option>
			</mat-select>
			<!-- </mat-form-field>  -->
		</div>
		<div class="col-md-9 globalTrans" style="bottom:unset">
			<div class="row g-trans">
				<div class="col-md-2">
	
					<mat-label class="matLavel">Speaker</mat-label><br>
	
					<mat-slide-toggle [ngClass]="'slideSkyBlue'" [(ngModel)]="selectedSpeaker"></mat-slide-toggle>
					<!-- {{selectedSpeaker}} -->
					<span class="onOffCs" *ngIf="selectedSpeaker==false">Off</span>
					<span class="onOffCs" *ngIf="selectedSpeaker==true">On</span>
				</div>
				<div class="col-md">
	
					<mat-label class="matLavel">WCA</mat-label><br>
					<mat-slide-toggle [ngClass]="'slideSkyBlue'" [(ngModel)]="selectedWorkConfidenceLevel">
					</mat-slide-toggle>
					<!-- {{selectedWorkConfidenceLevel}} -->
					<span class="onOffCs" *ngIf="selectedWorkConfidenceLevel==false">Off</span>
					<span class="onOffCs" *ngIf="selectedWorkConfidenceLevel==true">On</span>
				</div>
				<div class="col-md">
					<!-- </mat-form-field>  -->
					<mat-label class="matLavel">Streaming</mat-label><br>
					<mat-slide-toggle [ngClass]="'slideSkyBlue'" [(ngModel)]="transmissionStream"></mat-slide-toggle>
					<!-- {{transmissionStream}} -->
					<span class="onOffCs" *ngIf="transmissionStream==false">Off</span>
					<span class="onOffCs" *ngIf="transmissionStream==true">On</span>
				</div>
				<div class="col-md">
					<mat-label class="matLavel">Punctuation</mat-label><br>
					<mat-slide-toggle [ngClass]="'slideSkyBlue'" [(ngModel)]="punctuation"></mat-slide-toggle>
					<span class="onOffCs" *ngIf="punctuation==false">Off</span>
					<span class="onOffCs" *ngIf="punctuation==true">On</span>
				</div>
				<div class="col-md">
					<mat-label class="matLavel">Verbatim</mat-label><br>
					<mat-slide-toggle [ngClass]="'slideSkyBlue'" [(ngModel)]="verbatim"></mat-slide-toggle>
					<span class="onOffCs" *ngIf="verbatim==false">Off</span>
					<span class="onOffCs" *ngIf="verbatim==true">On</span>
				</div>
			</div>
		</div>
	
	</div>
	<br>
	<div class="row">
		<div class="col-md-12">
			<mat-button-toggle-group [ngClass]="'togVKBtnSkyBlue'" (change)="changeRadioSelectFile($event.value)"
				[(ngModel)]="selectedRadioAudioFile">
				<mat-button-toggle value="SelectFile" class="highlight-button">Select File</mat-button-toggle>
				<mat-button-toggle value="UploadFile" class="highlight-button">Upload File</mat-button-toggle>
				<mat-button-toggle value="RecordNow">Record Now</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</div>
	<div class="row" style="padding: 30px 0px 0px 8px;">
		<div class="col-md-5" *ngIf="showHideSearchableAudioFile">
			<mat-form-field class="wd-100" style="width: 75%;">
				<mat-label>Select searchable audio file</mat-label>
				<input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
				<mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFile($event.option.value)">
					<mat-option *ngFor="let option of filteredAudioFileOptions | async" [value]="option">
						{{option}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</div>
		<div class="col-md-5" *ngIf="showHideSearchableAudioFile">
			<mat-form-field class="wd-100" style="width: 75%;">
				<mat-label>Select transcripted file</mat-label>
				<input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
				<mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTranscritionFile($event.option.value)">
					<mat-option value='{{d.file_name}}' *ngFor="let d of transcriptedText">
						<!-- <mat-option *ngFor="let d of transcriptedText | async" [value]='d.file_name'> -->
						{{d.file_name}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</div>
		<!-- <div class="col-md-5" *ngIf="showHideSearchableAudioFile">
	        <mat-form-field class="wd-100" style="width: 75%;">
	          <mat-label>Select transcripted file</mat-label>
	          <mat-select (selectionChange)="selectedTranscritionFile($event.value)" >
	          <mat-option value='{{d.file_name}}' *ngFor="let d of transcriptedText">{{d.file_name}}</mat-option>
	          </mat-select>
	         
	        </mat-form-field>
	      </div> -->
		<!-- <div  class="col-md-1"> <span style="position: relative;top: 10px;">OR</span> </div> -->
		<div class="col-md-2">
			<span *ngIf="showHideUplaodFile">
				<div class="upload-btn-wrapper text-center">
					<button class="btn">Choose File</button>
					<input type="file" id="fileID" (change)="fileEvent($event)" />
				</div>
				<p class="text-center"><span class="textNam"> {{_fileNameView }}</span>
			</span>
		</div>
		<div class="col-md-4">
			<div *ngIf="showHideUplaodFile">
				<button class="fileSubmit" [disabled]="!_fileDetails" style="margin-top: 0px;"
					(click)="submitBtnFileUpload()">Upload file </button>
	
				<span class="textNam ml-2" *ngIf="showHidePleaseWaitMsg" style="color:#e40659">Please Wait.....</span>
	
				<span class="textNam ml-2" style="color:green;font-size:20px;" *ngIf="fileUploaded"> {{_successMsg}}</span>
			</div>
		</div>
		<!-- <div class="col-md-3">
	        <button class="goBtnSubmit" (click)="goBtn()" *ngIf="transcription_status != 1">Speech to Text</button>
	        <button class="fileSubmit"  *ngIf="transcription_status == 1"><i class="fa fa-spinner" aria-hidden="true"></i> Transcribing...</button>
	        </div> -->
	</div>
	<br>
	<div class="row" style="padding: 0px 0px 0px 8px;">
		<!-- <div class="col-md-3" *ngIf=" myUser.groupid != 84021">
	        <mat-form-field >
						<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
							(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [max]="today" [(ngModel)]="startDateFilter">
						<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						<mat-datepicker #frompicker></mat-datepicker>
					</mat-form-field>
	      </div> -->
		<!-- <div class="col-md-3" *ngIf=" myUser.groupid != 84021">       
					<mat-form-field  class="ml-3">
						<input matInput  #fromInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [max]="today" [(ngModel)]="endDateFilter">
						<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
						<mat-datepicker #topicker></mat-datepicker>
					</mat-form-field>
	      </div>
	      <div class="col-md-2" *ngIf=" myUser.groupid != 84021">  <br>    
					<button mat-raised-button class="btnAdd ml-3" (click)="fetchAllCalls()" style="background-color: #249cf4 !important;">Go</button>
	      </div> -->
		<!-- <div class="col-md-2" *ngIf=" myUser.groupid != 84021">
	        <mat-form-field  class="wd-100" style="width: 140px;">
	          
	          <mat-label>Select Agent</mat-label>
	            <input type="text"
	                  matInput
	                  [formControl]="myControlAgent"
	                  [matAutocomplete]="auto1">
	                  <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
	            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onagentnameSelect($event.option.value)">
	              <mat-option *ngFor="let option of filteredAgentOptions | async" [value]="option.agent_name">
	                {{option.agent_name}}
	              </mat-option>
	        </mat-autocomplete>
	        </mat-form-field>
	      </div> -->
		<!-- <div class="col-md-2" *ngIf=" myUser.groupid != 84021">
	        <mat-form-field  class="wd-100" style="width: 120px;">
	       
	
	        <mat-label>Select Call</mat-label>
	        <input type="text"
	               matInput
	               [formControl]="myControlCall"
	               [matAutocomplete]="auto2">
	               <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
	        <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onCallSelect($event.option.value)">
	          <mat-option *ngFor="let option of filteredCallOptions | async" [value]="option.call_id">
	            {{option.call_id}}
	          </mat-option>
	        </mat-autocomplete>
	
	      </mat-form-field>
	      </div> -->
	</div>
	
	
	
	
	<div class="text-center" *ngIf="showHideRecordNow">
	
		<p class="text-center" *ngIf="showHideStartMic"><span>
				<mat-icon class="micCs" matTooltip="Click for start recording" (click)="startRecordingBtn()">mic</mat-icon>
			</span> </p>
		<p class="text-center" *ngIf="showHideStopMic"><span>
				<mat-icon class="micCs" matTooltip="Click for stop recording"
					style="color: #d7392d;border: 2px solid #d7392d;" (click)="stopRecordingBtn()">mic</mat-icon>
			</span> </p>
		<span class="textNam ml-2" *ngIf="showHidePleaseWaitMsg" style="color:#e40659">Please Wait.....</span>
	
		<span class="textNam ml-2" style="color:green;font-size:20px;" *ngIf="fileUploaded"> {{_successMsg}}</span>
		<p class="text-center" *ngIf="showHideStartMic" style="color: gray;"> Click over icon to start recording</p>
		<p class="text-center" *ngIf="showHideStopMic" style="color: gray;"> Click over icon to stop recording</p>
		<!-- <audio controls="" *ngIf="url">
	    <source [src]="sanitize(url)" type="audio/wav">
	  </audio> -->
	</div>
      
<br>
    <div class="row" style="padding: 0px 0px 0px 8px;">
      <div class="col-md-5" *ngIf=" myUser.groupid != 84021">
        <mat-form-field style="width:120px;">
					<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
						(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [max]="today" [(ngModel)]="startDateFilter">
					<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
					<mat-datepicker #frompicker></mat-datepicker>
				</mat-form-field>
				<mat-form-field style="width:120px;"  class="ml-3">
					<input matInput  #fromInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [max]="today" [(ngModel)]="endDateFilter">
					<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
					<mat-datepicker #topicker></mat-datepicker>
				</mat-form-field>
				<button mat-raised-button class="btnAdd ml-3" (click)="getVoiceCallData()" >Go</button>
      </div>
      <div class="col-md-3" *ngIf=" myUser.groupid != 84021">
        <mat-form-field  class="wd-100">
          <!-- <mat-select placeholder="Select searchable audio file">
            <mat-option value="1">File 1</mat-option>
              <mat-option value="2">File 2</mat-option>
              <mat-option value="3">File 3</mat-option>
          </mat-select> -->
          <!-- <mat-label>Select searchable call</mat-label> -->
          <!-- <input type="text"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto"> -->
                 <!-- <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon> -->
          <!-- <mat-select placeholder="Select Agent Name" (selectionChange)="onagentnameSelect($event.value)" [(ngModel)]="SelectAgent">
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let option of agent_name" [value]="option.agent_name">
              {{option.agent_name}}
            </mat-option>
          </mat-select> -->
          <mat-label>Select Agent</mat-label>
            <input type="text"
                  matInput
                  [formControl]="myControlAgent"
                  [matAutocomplete]="auto1" (keyup)="clearKeyUpSelectAgent($event.value)">
                  <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onagentnameSelect($event.option.value)">
              <mat-option *ngFor="let option of filteredAgentOptions | async" [value]="option.agent_name">
                {{option.agent_name}}
              </mat-option>
        </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-md-3" *ngIf=" myUser.groupid != 84021">
        <mat-form-field  class="wd-100">
        <!-- <mat-select placeholder="Select searchable audio file">
          <mat-option value="1">File 1</mat-option>
            <mat-option value="2">File 2</mat-option>
            <mat-option value="3">File 3</mat-option>
        </mat-select> -->
        <!-- <mat-label>Select searchable call</mat-label> -->
        <!-- <input type="text"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto"> -->
               <!-- <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon> -->
        <!-- <mat-select placeholder="Select Call" (selectionChange)="onCallSelect($event.value)" [(ngModel)]="SelectCall">
          <mat-option *ngFor="let option of allCalls;let callIndex=index;" [value]="callIndex">
            {{option.call_id}}
          </mat-option>
        </mat-select> -->

        <mat-label>Select Call</mat-label>
        <input type="text"
               matInput
               [formControl]="myControlCall"
               [matAutocomplete]="auto2">
               <mat-icon class="arrowSign" matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onCallSelect($event.option.value)">
          <mat-option *ngFor="let option of filteredCallOptions | async" [value]="option.call_id">
            {{option.call_id}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
      </div>
      <div class="col-md-1"></div>

    </div>

    <div class="flexcontainer">
      <div> <span class="span-label"> Call Id:</span> 
                  <span class="span-label-data">{{_callID}}</span></div>
      <div>  <span class="span-label"> Agent:</span> 
                  <span class="span-label-data">{{_agent}}</span></div>      
      <div>  <span class="span-label">Project:</span> 
                  <span class="span-label-data">{{_project}}</span></div>
      <div> <span class="span-label"> TL:</span> 
                    <span class="span-label-data">  {{agentInfo.tlName}} </span></div>
      <div *ngIf="inputObject && inputObject.start_time "> <span class="span-label"> Call Date:</span> 
                      <span class="span-label-data">  {{inputObject.start_time | date:'yyyy-MM-dd h:mm a' }} </span></div>            
      <div>     <span class="span-label">Calls to Audit:</span> 
                  <span class="span-label-data">{{_callsToAudit}}</span></div>
      <div> <span class="span-label"> Audited:</span> 
                  <span class="span-label-data">{{_Audited}}</span></div>  
      <div > <span class="span-label" > Call Duration:</span> 
                  <span class="span-label-data">{{callDuration}}</span></div>  
      <!-- <div> <span class="span-label"> Total Earned:</span> 
                  <span class="span-label-data">  {{_totalEarned}} </span></div> -->
                 <div>&nbsp;</div>
       <!-- <div>  <span class="span-label"> <input type="text" placeholder="search" class="input-field"></span></div> -->
    </div>
       <!-- <div class="row">        
            <div class="col-md-2">
              <span class="span-label"> Call Id:</span> 
              <span class="span-label-data">12038255</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label"> Agent:</span> 
              <span class="span-label-data">Rachana Sachan</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label">Project:</span> 
              <span class="span-label-data">OYO Rooms</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label">Calls to Audit:</span> 
              <span class="span-label-data">6</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label"> Audited:</span> 
              <span class="span-label-data">866</span>
          </div>
           <div class="col-md-2">
                 <span class="span-label"> Total Earned:</span> 
              <span class="span-label-data">5266</span>
          </div>           
  </div>   -->
    <!-- <div class="mt-2" style="float:right;">
                 <span class="span-label"> <input type="text" placeholder="search" class="input-field"></span>
          </div>   -->
 

          <div class="row mt-3 mb-2" *ngIf="_callID">
            <div class="col-md-4"><button class="viewCallBtn1 vdcf"  (click)="viewQuestionnaire()"> VIEW DURING CALL FORM</button></div>
          </div>

  <div class="span-voice">
  <span class="span-label pdl"> Voice Call Audit</span> 
    </div> 
    <!--voice call audio start-->
    <div style="width: 100%" class="ml-2 pr-3 mt-3" *ngIf="showHideDivUI">

      <div id="wave-timeline" class="vk-waveTineline" style="border: 1.8px solid #beb9b9;border-bottom:none;background: #f4f4f4;height: 40px;"></div>
      <div id="waveform" class="waveOverflow" style="border: 1.8px solid #beb9b9;padding: 4px;"></div>
      <!-- <div id="waveform"></div>
      <div id="wave-timeline"></div> -->

      <!-- <div style="margin-top:10px">
        <span class="controls spanPlayButton">
          <i (click)="onStopPressed()" class="fa fa-stop" aria-hidden="true"></i>
        </span>
         <span class="controls spanPlayButton">
        <i (click)="onBackwardPressed()" class="fa fa-backward" aria-hidden="true"></i>
      </span>
      <span *ngIf="waveStop" class="controls spanPlayButton">
        <i (click)="onPausePressed()" class="fa fa-pause" aria-hidden="true"></i>
      </span>

      <span *ngIf="wavePlay" class="spanPauseButton">
        <i class="fa fa-play" aria-hidden="true"  (click)="onPreviewPressed()"></i>
      </span>
       <span class="controls spanPlayButton">
        <i (click)="onForwardPressed()" class="fa fa-forward" aria-hidden="true"></i>
      </span> 
    </div> -->




    <div class="row">
      <div class="col-md-5" style="padding-top: 6px;">
       <span class="totCal1">Total Call Duration </span> <span class="totCal2"> <b>{{ _totalCallDuration || transcriptionLength  || '--'}} </b></span>
      </div>	
      <div class="col-md-4">
        <div style="margin-top:10px" class="text-left" *ngIf="_totalCallDuration">
          <span class="controls spanPlayButton" style="display: none;">
            <i (click)="onStopPressed()" class="fa fa-stop" aria-hidden="true"></i>
          </span>
           <span class="controls spanPlayButton">
          <i (click)="onBackwardPressed()" class="fa fa-backward" aria-hidden="true"></i>
        </span>
        <span *ngIf="waveStop" class="controls spanPlayButton">
          <i (click)="onPausePressed()" class="fa fa-pause" aria-hidden="true"></i>
        </span>
  
        <span *ngIf="wavePlay" class="spanPauseButton">
          <i class="fa fa-play" aria-hidden="true"  (click)="onPreviewPressed()"></i>
        </span>
         <span class="controls spanPlayButton">
          <i (click)="onForwardPressed()" class="fa fa-forward" aria-hidden="true"></i>
        </span>          
        <!-- <button class="submit-Btn" (click)="openAudio()">open in new tab</button> -->    
      </div>
    
      </div>
      <div class="col-md-3" style="margin-top: 4px;">
        <span class="totCal1" style="top: 1px;display: initial;">Volume </span>   <input id="volume" type="range" min="0" max="1" value="1" step="0.1" (change)="onchangeVolume($event.target.value)">
      </div>
    </div>
<br>













    </div> 
      <!--voice call audio end-->
        </mat-card>


        <!-- <div class="text-center" *ngIf="_callID">   <button class="goBtnSubmit goBtnSubmit1" (click)="goBtn()" *ngIf="transcription_status != 1"><img src="../assets/image/startTrans.svg" style="position: relative;vertical-align: text-top;right: 15px;"/> START TRANSCRIPTION</button>
          <button class="fileSubmit goBtnSubmit1"  *ngIf="transcription_status == 1">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;Transcribing...</button>
        </div> -->

		<div class="row mt-2" *ngIf="_callID">
			<div class="col-md-6 mt-1">
				<div *ngIf="_callID"> <button class="goBtnSubmit" (click)="goBtn()" *ngIf="transcription_status != 1"><img
							src="../assets/image/startTrans.svg" /> Start Transcription</button>
					<button class="fileSubmit" (click)="goBtn()" *ngIf="transcription_status == 1">
						<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;Transcribing...</button>
					<span style="margin-left: 15px;" *ngIf="transcriptionTime" class="totCal1">Processing Time</span> <span
						class="totCal2" style="padding-left: 0px;" *ngIf="transcriptionTime"> <b>{{ transcriptionTime ||
							'--'}} </b></span>
				</div>
			</div>
			<div class="col-md-6 mt-1 text-right">
		
				<!-- <div *ngIf="_callID">  
		    <span style="margin-right: 15px;" *ngIf="transcriptionTime" class="totCal1">Audit Time</span> <span class="totCal2"
		      style="text-align: left; padding-left: 0px;" *ngIf="transcriptionTime"> <b>{{ totalAuditTime ||
		        '--'}} </b></span>
		     <button class="goBtnSubmit"  style="border: none;" [ngStyle]="{'background':(this.transcription_status == 0 && this.transcription != '') ? '#249cf4' : 'black'}" (click)="autoAuditBtn()"><i *ngIf="auditLoader == false" class="fa fa-check-square-o mr-4" style="font-size: 28px;padding-top: 2px;vertical-align: middle;" aria-hidden="true"></i> <i class="fa fa-spinner fa-spin" style="margin-right: 10px;" *ngIf="auditLoader == true"
		      aria-hidden="true"></i>AUTO AUDIT</button>
		  </div> -->
		
		
		
		
			</div>
		</div>








        <div class="row mt-2" *ngIf="_callID">
            <div class="col-md-12 mt-1">

            <mat-card class="matcard mt-2 pb-3">
              <p class="title-heading title-heading-1">Transcription</p>
              <div class="Trans-div">
              <!-- <p class="text-right"> 
                <button class="goBtnSubmit" (click)="goBtn()" *ngIf="transcription_status != 1">Speech to Text</button>
                  <button class="fileSubmit"  *ngIf="transcription_status == 1">
                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;Transcribing...</button></p> -->
                <table style="width:100%;"  *ngIf="transcriptionTime">
                  <tr>
                    <td><b>File Name:</b> {{selectedFileForProcess}}</td>
                    <td> <b>Audio Length:</b> {{transcriptionLength}}</td>
                    <td></td>
                  </tr>  
                  <tr><td colspan="3"><br></td></tr>
                  <tr>
                    <td><b>Processing Time:</b> {{transcriptionTime}}</td>
                    <td><b>Confidence Level:</b> {{transcriptionConfidence}}</td>
                    <td><b>Word Accuracy:</b> {{transcriptionWord}}</td>
                  </tr>    
                </table>
              
                <!-- <p *ngIf="transcription"> File Name: {{selectedFileForProcess}}</p>    
                <p  *ngIf="transcriptionTime">Processing Time : {{transcriptionTime}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Audio Length : {{transcriptionLength}}</p> -->
              
                <div>
		<div style=" overflow-y: auto;" id="messageBody">
			<div *ngFor="let v of transmissionChunk">
				<div class="TDiv" [innerHTML]="v.text" [ngStyle]="{'color':v.active ? 'red' : 'black'}"></div>
			</div>
		</div>

          </div>

                  <!-- <div class="TDiv">
                    <p>Hello. How are you?</p>
                    <span class="time-right">11:00</span>
                  </div>
                  
                  <div class="TDiv darker"> 
                    <p>Hey! I'm fine. Thanks for asking!</p>
                    <span class="time-left">11:01</span>
                  </div>
                  
                  <div class="TDiv">
                    <p>Sweet! So, what do you wanna do today?</p>
                    <span class="time-right">11:02</span>
                  </div>
                  
                  <div class="TDiv darker"> 
                    <p> I wanna learn more coding.</p>
                    <span class="time-left">11:05</span>
                  </div>
                  </div> -->
                </div>
            </mat-card>
            </div>          
                <div class="col-md-12 mt-1" *ngIf="_callID">
                  <!-- <mat-card class="matcard mt-3" >
                    <p class="title-heading">Questionnaire Response</p>
                    <div class="tablediv" style="padding-bottom: 15px;">
                  <table class="table-cls" >
                    <tr><td><span style="color: #3fb90d;font-size: 14px;"> Questions </span> </td><td> <span style="color: #3fb90d;font-size: 14px;"> Answer </span></td> </tr>
                    <tr *ngFor="let v of QuestionAnswer"><td>{{v.question}} </td><td>{{v.answer}} </td> </tr>
                    </table> 
                   
                      </div>
                  </mat-card> -->
<br>
  <mat-card class="matcard" *ngIf="_callID">
	<p class="title-heading">Call Auditing score</p>
	<span>
		<p class="mt-2 mb-2 errmsg" [ngStyle]="{'color':statusColor}">{{errorMsg}}</p>
	</span>
	
	<div class="row">
		<div style="padding-left:30px; padding-top:12px;" class="col-md-6">
	
			<span style="font-weight: 500;color: gray;">Select Audit Form : </span>
			<select class="selectddl" (change)="onFormChange($event.target.value)">
				<option [value]="formIndex" *ngFor="let form of allForms; let formIndex=index;">{{form.audit_form_name}}
				</option>
			</select>
		</div>
		<div style="padding-right: 30px; margin-top: -25px;" class="col-md-6 text-right">
			<span style="padding-left: 50px;" *ngIf="totalAuditTime" class="totCal1">Audit Time</span>
			<span class="totCal2" style="text-align: left; padding-left: 0px;" *ngIf="totalAuditTime"> <b>{{ totalAuditTime
					|| '--'}} </b></span>
			<button class="goBtnSubmit" style="border: none;" (click)="autoAuditBtn()"><i *ngIf="auditLoader == false"
					class="fa fa-check-circle mr-4" style="font-size: 28px;padding-top: 2px;vertical-align: middle;"
					aria-hidden="true"></i> <i class="fa fa-spinner fa-spin" style="margin-right: 10px;"
					*ngIf="auditLoader == true" aria-hidden="true"></i>AUTO AUDIT</button>
		</div>
	</div>
      <br/> <br/>
        <div class="tablediv" *ngFor="let qcategory of CallAuditingscore;let i=index;"  >
      <p class="mt-1" style="color: #3fb90d;margin-bottom: 3px;"  *ngIf="qcategory.category != 'comments'">{{i+1}}. {{qcategory.category}} </p>
          <table  class="tableaudit">
            
  <tr *ngFor="let b of qcategory.questions;let j=index;"> 
	<td style="width: 56%; word-break: break-all;"
		[ngStyle]="{'color':b.response == 'yes' ? '#3fb90d' : b.response == 'no' ? 'red' : b.response == 'NA' ? 'gray' : 'black'}">
		{{i+1}}.{{j+1}} {{b.q}}
	
		<i *ngIf="b.legend" style="font-size: 18px; position: relative; top: 2px;" class="fa fa-info-circle"
			matTooltip="{{b.legend}}" aria-hidden="true"></i>
	
	
		<!--start first play pause button-->
		<span *ngIf="b.timings">
			<!-- <span class="controls spanPlayButton" style="font-size: 18px;color: #808080;width: 30px;"
	          *ngFor="let timing of b.timings;let timeIndex=index;">
	          <i class="fa fa-pause" aria-hidden="true" (click)="jumpPlayer(timing.startTime)"></i>
	        </span> -->
			<!-- <span class="spanPauseButton" *ngIf="b.timings && (b.timings.length == 0 || b.timings.length > 0)" style="width: 30px;">
	          <i class="fa fa-check" aria-hidden="true"></i>
	        </span> -->
			<span class="spanPauseButton" *ngFor="let timing of b.timings;let timeIndex=index;" style="width: 30px;">
	
				<i class="fa fa-play" aria-hidden="true" *ngIf="timing.startTime && timing.endTime && timing.play == false"
					(click)="jumpPlayer(timing.startTime, i, j,timeIndex, true,timing.endTime)"></i>
				<i class="fa fa-pause" aria-hidden="true" *ngIf="timing.startTime && timing.endTime && timing.play == true"
					(click)="jumpPlayer(timing.startTime,i, j,timeIndex,false,timing.endTime)"></i>
			</span>
		</span>
	
	
	
		<!--end first play pause button-->
	
	
	
	
	
	
	
	
	
	</td>
   <td style="text-align: right; position: relative; right: 20px;" > 
    <i class="fa fa-spinner fa-spin" style="margin-right: 10px;" *ngIf="!b.timings && auditLoader == true" aria-hidden="true"></i>
    <span style="margin-right: 5px;" *ngIf="b.done && b.weighted_score">{{b.a.name === 'YES' ? b.weighted_score : 0}}</span>
    <select class="selectddl" [(ngModel)]="b.a.name" (change)="ddlChangeOption($event.target.value,i,j)">
       <option [value]="v.name" *ngFor="let v of b.audit_params" [selected]="v == b.audit_params[0]">{{v.name}}</option>
     </select>
    
     <span>
      <input type="text" class="rmarkInput" [(ngModel)]="b.remark"/>
    </span>
      </td> 
     
   </tr>
            </table>
      </div>
      <div  class="tablediv">
                <table style="width:100%;">
   <tr>
     <td colspan="2"> <p class="mt-2" style="color: #3fb90d;"><b>Scope for improvements*</b> </p> </td>
     </tr>
      <tr>
     <td colspan="2"> 
       <input type ="text" class="input-field wd"  [value] = "comment" (input)="updateValue($event.target.value,'comment')"/>
       <p *ngIf="showCommentError && comment == '' " style="color: red;">Please enter comment</p>
        </td>
     </tr>
        <tr>
     <td> 
      <p class="mt-2" style="color: #3fb90d;"><b>Total Score:{{audit_score}}</b> </p> 
        </td>
          <td style="position:absolute;right:12px;margin-top:7px"> 
      <button class="submit-Btn" (click)="submitBtn()">Submit</button>
        </td>
     </tr>
            </table>
      </div>
  </mat-card>
            </div>
        </div>
    </div>

    </div>

    </div>
    </div>

    <div id="cover-spin" *ngIf="currentLoader"></div>
    </mat-drawer-container>