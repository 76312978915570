<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" [ngClass]="{'minimize': is_minimized}" mode="side" opened="true">
		<app-menu 
			[navigateLink]="currentTitle" 
			[is_minimized]="is_minimized"
			(is_minimizedChange)="onMinimizedChange($event)">
		</app-menu>
	</mat-drawer>

  <app-logout></app-logout>
  <div class="container-fluid main-section-layout" [ngClass]="{'padding-fix': currentUrl != '/managementTeam'}"
    style="background: #f6f6f6;">
    <div class="row">
      <!-- <div class="col-md-2" *ngIf="showHideSpaceAuditorTeam"></div>
        <div class="col-md-2" *ngIf="showHideSpaceManagementTeam"></div>  <div [class]="wdFlex12">  -->
      <div class="col-md-12">
        <!-- <h4 class="titlehead" style="margin-top: 0%;padding-left: 10px;position: relative;z-index: 9;">Team Management
        </h4> -->
        <!-- <div class="row">
                <div class="col-md-12" >
                    <h4 class="titlehead" style="margin-top: 0%;padding-left: 10px;position: relative;top: 0px;z-index: 9;">Management Team</h4>
                </div>	
            </div> -->
        <!-- <br> -->
        <mat-card appearance="outlined">

          <div>

            <div class="d-flex justify-content-between align-items-center w-100">
                <!-- Left Section: Select Dropdowns -->
                <div class="d-flex flex-wrap align-items-center">
                  <mat-card-title class="text-primary">{{me_name}}</mat-card-title> <!-- Add margin to space it out from the first select -->
              
                  <div style="margin-left: 10px;">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,0)" [(ngModel)]="selectedAllFirstOption">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="v.email" *ngFor="let v of allNonUsers">{{v.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 0">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,1)" [(ngModel)]="selectedAllOptionLevel0">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel0">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 1">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,2)" [(ngModel)]="selectedAllOptionLevel1">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel1">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 2">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,3)" [(ngModel)]="selectedAllOptionLevel2">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel2">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 3">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,4)" [(ngModel)]="selectedAllOptionLevel3">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel3">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 4">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,5)" [(ngModel)]="selectedAllOptionLevel4">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel4">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 5">
                    <mat-form-field class="me-2" appearance="outline"> 
                      <mat-select (selectionChange)="onChange($event.value,6)" [(ngModel)]="selectedAllOptionLevel5">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel5">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="selectedLevel > 6">
                    <mat-form-field class="me-2" appearance="outline">
                      <mat-select (selectionChange)="onChange($event.value,7)" [(ngModel)]="selectedAllOptionLevel6">
                        <mat-option value="All">All</mat-option>
                        <mat-option [value]="g.email" *ngFor="let g of _ddlGroupGroupsLevel6">{{g.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              
                <!-- Right Section: Buttons -->
                <div class="d-flex justify-content-end">
                  <button mat-raised-button class="action_btn ms-2 responsive-button" (click)="resetTeam()">
                    <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;&nbsp;RESET
                  </button>
                  <button mat-raised-button class="action_btn ms-2 responsive-button" (click)="addProfile(true)">
                    <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;&nbsp;UPLOAD
                  </button>
                  <button mat-raised-button class="action_btn ms-2 responsive-button" (click)="workoption(true)">
                    <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;&nbsp;WORK MODE UPLOAD
                  </button>
                  <button mat-raised-button class="ms-2 responsive-button" (click)="addProfile(false)">
                    <i class="fa fa-user" aria-hidden="true"></i>+
                  </button>
                </div>
              </div>
              





            <!-- <div class="row">
            <div class="col-md-2 col-sm-12 col-xs-12">
                <h3 class="MT-h3"> {{me_name}}</h3>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12">
                    <mat-form-field class="wdh">
                        <mat-select (selectionChange)="onChange($event.value,0)" [(ngModel)]="selectedAllFirstOption">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="v.name" *ngFor="let v of allNonUsers">{{v.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="col-md-2 col-sm-12 col-xs-12"  *ngIf="selectedLevel > 0">
                    <mat-form-field  class="wdh">
                        <mat-select (selectionChange)="onChange($event.value,1)" [(ngModel)]="selectedAllOptionLevel0">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel0">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12"  *ngIf="selectedLevel > 1">
                    <mat-form-field  class="wdh">
                        <mat-select (selectionChange)="onChange($event.value,2)" [(ngModel)]="selectedAllOptionLevel1">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel1">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12" *ngIf="selectedLevel > 2">
                    <mat-form-field  class="wdh" >
                        <mat-select (selectionChange)="onChange($event.value,3)" [(ngModel)]="selectedAllOptionLevel2">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel2">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12" *ngIf="selectedLevel > 3">
                    <mat-form-field  class="wdh" >
                        <mat-select (selectionChange)="onChange($event.value,4)" [(ngModel)]="selectedAllOptionLevel3">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel3">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
              
                <div class="col-md-2 col-sm-12 col-xs-12"  *ngIf="selectedLevel > 4">
                    <mat-form-field  class="wdh">
                        <mat-select (selectionChange)="onChange($event.value,5)" [(ngModel)]="selectedAllOptionLevel4">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel4">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12" *ngIf="selectedLevel > 5">
                    <mat-form-field  class="wdh" >
                        <mat-select (selectionChange)="onChange($event.value,6)" [(ngModel)]="selectedAllOptionLevel5">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel5">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12"  *ngIf="selectedLevel > 6">
                    <mat-form-field  class="wdh">
                        <mat-select (selectionChange)="onChange($event.value,7)" [(ngModel)]="selectedAllOptionLevel6">
                            <mat-option value="All">All</mat-option>
                            <mat-option [value]="g.name" *ngFor="let g of _ddlGroupGroupsLevel6">{{g.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
             
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <button mat-raised-button class="btnAdd" (click)="resetTeam()"><i class="fa fa-upload" aria-hidden="true"></i>Reset</button>
                        &nbsp;
                        <button mat-raised-button class="btnAdd" (click)="addProfile(true)"><i class="fa fa-upload" aria-hidden="true"></i>Upload</button>
                        &nbsp;
                        <button mat-raised-button class="btnAdd" (click)="addProfile(false)"><i class="fa fa-user" aria-hidden="true"></i>+</button>
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <mat-form-field  class="wdh">
                           <input matInput placeholder="Search" #searchfeild (keyup)="applyFilter($event.target.value)"/>
                           <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                        </mat-form-field>
                    </div>
                </div>  -->
            <div class="row pdt fMain-div">
              <!-- <div class="col-md-12 col-sm-12 col-xs-12">
                        <span class="print-export">Print</span>                        
                        <span class="print-export">Export</span>
                        </div>  -->
            </div>
          </div>
          <div class="row pdt fMain-div" *ngIf="showHideOptionDDL">
            <div class="col-md-3 col-sm-12 col-xs-12">
              <span class="labelName">Supervisor Name:</span>
              <span class="labelData">{{_name}}</span>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
              <span class="labelName">Supervisor Email: </span>
              <span class="labelData"> {{_email}}</span>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
              <span class="labelName">Supervisor Mobile:</span>
              <span class="labelData">{{_phone}}</span>
            </div>

          </div>
          <br>
          <!-- <div style="float: right;right: 15px;">
    <mat-form-field  class="wdh">
       <input matInput placeholder="Search" #searchfeild (keyup)="applyFilter($event.target.value)" autocomplete="off"/>
       <i class="fa fa-search" matSuffix aria-hidden="true"></i>
    </mat-form-field>
</div> -->

          <div class="row">
            <div class="col-md-9 col-sm-12 col-xs-12">
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
              <div>
                <mat-form-field class="wdh" appearance="outline">
                  <mat-label>Search</mat-label>
                  <input matInput placeholder="Search" #searchfeild (keyup)="applyFilter($event.target.value)" />
                  <i class="fa fa-search me-2" matSuffix aria-hidden="true"></i>
                </mat-form-field>
              </div>
            </div>
          </div>






          <div class="overflow-auto custom-scrollbar custom-table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: none !important;">
              <ng-container matColumnDef="sno">
                <th class="text-center" mat-header-cell *matHeaderCellDef>S No </th>
                <td class="text-center" mat-cell *matCellDef="let element; let i = index;">{{i+1}} </td>
              </ng-container>
              <ng-container matColumnDef="active_status">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Status </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <mat-slide-toggle [checked]="element.active_status === 'disable' ? false : true"
                    (change)="changeStatus($event, element)"></mat-slide-toggle>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Name </th>
                <td class="text-center" mat-cell *matCellDef="let element">{{element.name}}</td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Email </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="phone">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Mobile No </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element.phone}} </td>
              </ng-container>

              <ng-container matColumnDef="role">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Role </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  {{getUserRole(element.role)}}
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.role">
                <mat-option [value]="element.role">{{element.role}}</mat-option>
            </mat-select>
        </mat-form-field> -->
                </td>
              </ng-container>

              <ng-container matColumnDef="sip_id">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Desktop/Mobile </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <!-- <span style="text-transform: capitalize;">{{element.address2 ? element.address2.split(',').length : ''}}</span> -->
                  <span style="text-transform: capitalize;">{{element.sip_id ? 'Desktop' : 'Mobile'}}</span>
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.status">
                <mat-option [value]="element.status">{{element.status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->

                </td>
              </ng-container>

              <ng-container matColumnDef="address2">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Region </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <!-- <span style="text-transform: capitalize;">{{element.address2 ? element.address2.split(',').length : ''}}</span> -->
                  <span style="text-transform: capitalize;">{{element.address2}}</span>
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.status">
                <mat-option [value]="element.status">{{element.status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->

                </td>
              </ng-container>

              <ng-container matColumnDef="job_profile">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Profile </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <span style="text-transform: capitalize;">{{element.job_profile}}</span>
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.status">
                <mat-option [value]="element.status">{{element.status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->

                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Status </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <span style="text-transform: capitalize;">{{element.status}}</span>
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.status">
                <mat-option [value]="element.status">{{element.status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->

                </td>
              </ng-container>
              <ng-container matColumnDef="reportTo">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Report To </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  {{element.reportTo}}
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.reportTo">
                <mat-option [value]="element.reportTo">{{element.reportTo}}</mat-option>
            </mat-select>
        </mat-form-field>  -->
                </td>
              </ng-container>
              <ng-container matColumnDef="coordinator">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Secondary Executive </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  {{getSecExecName(element.coordinator)}}
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.reportTo">
                <mat-option [value]="element.reportTo">{{element.reportTo}}</mat-option>
            </mat-select>
        </mat-form-field>  -->
                </td>
              </ng-container>

              <ng-container matColumnDef="work_option">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Work Option </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <span style="text-transform: capitalize;">{{element.work_option}}</span>
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.status">
                <mat-option [value]="element.status">{{element.status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->

                </td>
              </ng-container>

              <ng-container matColumnDef="profile_status">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Profile Status </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  {{element.profile_status | titlecase}}
                  <!-- <mat-form-field  class="ddlwdh">
            <mat-select [(ngModel)]="element.profile_status">
                <mat-option [value]="element.profile_status ">{{element.profile_status}}</mat-option>
            </mat-select>
        </mat-form-field>  -->
                </td>
              </ng-container>

              <ng-container matColumnDef="options">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Options </th>
                <td class="text-center" mat-cell *matCellDef="let element" (click)="editProfile(element)">
                  <i class="fa fa-edit CR" style="font-size: 18px; color: var(--primary);"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </mat-card>
      </div>
    </div>
  </div>
</mat-drawer-container>
