<!-- TL Wise counts html starts -->
<div class="md-12 sm-12 xs-12">
	<div [ngClass]="{'call_summary_height' : tl_sourceCallSummary.data.length > 7 }" style="padding: 0px !important;">
	<mat-table [dataSource]="tl_sourceCallSummary">
		<ng-container matColumnDef="supervisor">
		  <mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef>TL </mat-header-cell>
		  <mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element">{{element.supervisor}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="agents">
		  <mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> Agents </mat-header-cell>
		  <mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.agents}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="dialled">
		  <mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef>Calls Placed</mat-header-cell>
		  <mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.dialled}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="unique_dialled">
		  <mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef>Unique Calls Placed</mat-header-cell>
		  <mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.unique_dialled}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="contacted">
			<mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> Contacted </mat-header-cell>
			<mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.contacted}} </mat-cell>
		  </ng-container>
		
		<ng-container matColumnDef="unique_contacted">
			<mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> Unique Contacted </mat-header-cell>
			<mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.unique_contacted}} </mat-cell>
		  </ng-container>
		 
		<ng-container matColumnDef="rnr">
			<mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> RNR </mat-header-cell>
			<mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.rnr}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="forms">
			<mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> Forms </mat-header-cell>
			<mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.forms}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="aht_pulse">
			<mat-header-cell class="d-flex justify-content-center align-items-center" *matHeaderCellDef> AHT </mat-header-cell>
			<mat-cell class="d-flex justify-content-center align-items-center" *matCellDef="let element"> {{element.aht_pulse}} </mat-cell>
		</ng-container>
		
		<mat-header-row *matHeaderRowDef="tl_dataColumnsCallSummary;sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: tl_dataColumnsCallSummary;" ></mat-row>
		</mat-table>
	</div>
</div>
<!-- TL Wise counts html ends -->