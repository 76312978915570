import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { SendNotificationDialogComponent } from '../send-notification-dialog/send-notification-dialog.component';
import { ApiService } from '../api.service';
import { MatInput } from '@angular/material/input';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-send-message-agent',
  templateUrl: './send-message-agent.component.html',
  styleUrls: ['./send-message-agent.component.scss']
})
export class SendMessageAgentComponent implements OnInit {

  displayedColumns = ['checkbox','name', 'phone', 'userid'];
  dataSource = new MatTableDataSource();
  selectedAgents:any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  showHideBatchButton:boolean=true;
  showHideAgentButton:boolean=false;
  api_end_point:any;
  @ViewChild('searchInput', {read: MatInput}) searchInput: MatInput;
  constructor(private dialog: MatDialog, public router: Router, private API: ApiService,private myservice: MyserviceService,) { }

  ngOnInit() {
 
    // let ELEMENT_DATA = [
    //   {name: 'Ajay',phone:'8347477484',userid:'324ASD'},
    //   {name: 'Vivek',phone:'8347477484',userid:'324ASD'},
    //   {name: 'JOhn',phone:'8347477484',userid:'324ASD'},
    //   {name: 'Abdul',phone:'8347477484',userid:'324ASD'},
    //   {name: 'Raja',phone:'8347477484',userid:'324ASD'},
    //   {name: 'Ajay',phone:'8347477484',userid:'324ASD'},
      
    //    ];
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;
    this.getAgentListData()
	this.api_end_point = this.myservice.get_API_End_Point();
  }

  getAgentListData() {
    this.API.getAgentList("In Training",null,"","",this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response) {
        
         let _filterData=_response;
        _filterData.map((row,index)=>{
          row.isSelected = false;
        })
        this.dataSource = new MatTableDataSource(_filterData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
          return data.name.toString().toLowerCase().includes(filter) || data.email.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase().includes(filter) || data.batch_code == filter || data.status.toString().toLowerCase().includes(filter)
        };

      }
    }
    )
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  sendNotificationDialog(){
    if(this.selectedAgents.length == 0){
      alert("Select agent for sending notification");
      return;
    }
    	const dialogRef = this.dialog.open(SendNotificationDialogComponent, {
			width: '40%',
			disableClose:true,
        data: {
          batch: false,
          ids: this.selectedAgents
        },
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			  if(result && result.refresh){
          this.selectedAgents = [];
          this.getAgentListData();
          this.searchInput.value="";
        }
		  });
  }
  resetIds(){
    this.selectedAgents = [];
     let _arrayData:any=this.dataSource.data;
     _arrayData.map((row,index)=>{
          row.isSelected = false;
        })
    this.dataSource = new MatTableDataSource(_arrayData);
        this.dataSource.paginator = this.paginator;
    
  }
  selectForMessageAgents(event, index, selectForMessageAgent) {
   // console.log(event, index, selectForMessageAgent)
    if (event.checked == true) {
      this.selectedAgents.push(selectForMessageAgent)
    } else {
      this.selectedAgents = this.selectedAgents.slice(0, index).concat(this.selectedAgents.slice(index + 1, this.selectedAgents.length))
    }
    // console.log(this.selectedAgents)
  }
}
