import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import {MyserviceService}from '../../myservice.service';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

@Component({
  selector: 'app-register-agent',
  templateUrl: './register-agent.component.html',
  styleUrls: ['./register-agent.component.scss']
})
export class RegisterAgentComponent implements OnInit {
  createdForm: UntypedFormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  currentFileobj: any = {};
  todayDate:Date = new Date();
  assessmentUpload_msg: string = '';
  linkedInToken = "";
  client_ids={
    'REC-1': {rec_id:'82580', group_id: '81419',trainer_id: '48461', name:'CONNEQT'}
  }
  selectedClientId = {rec_id:'24275', group_id: '81419',trainer_id: '48461', name:'CONNEQT'};
  cityvaluesave: any ="";
  countryList: Array<any> = [
    { name: 'Andaman and Nicobar Islands', cities: ['Port Blair'] },
    { name: 'Andhra Pradesh', cities: ['Visakhapatnam','Vijayawada','Nellore','Kurnool','Rajahmundry','Tirupati','Kakinada','Kadapa','Anantapuram','Eluru','Anantapur','Vijayanagaram','Tenali','Ongole','Nandyal','Chittoor','Machilipatnam','Adoni','Proddatur','Hindupur','Bhimavaram','Madanapalle','Guntakal','Srikakulam','Dharmavaram','Gudivada','Narasaraopet','Tadipatri','Kavali','Tadepalligudem','Amaravati'] },
    { name: 'Arunachal Pradesh', cities: ['Itanagar','Roing','Tezu'] },
    { name: 'Assam', cities: ['Guwahati','Silchar','Dibrugarh','Jorhat','Nagaon','Bongaigaon','Tinsukia','Tezpur',] },
    { name: 'Bihar', cities: ['Patna','Gaya','Bhagalpur','Muzaffarpur','Bihar Sharif','Darbhanga','Purnia','Arrah','Begusarai','Munger','Chapra','Danapur','Katihar','Saharsa','Sasaram','Hajipur','Dehri','Siwan','Bettiah','Motihari','Kishanganj','Jamalpur','Buxar','Jehanabad','Aurangabad'] },
    { name: 'Chandigarh', cities: ['Chandigarh'] },
    { name: 'Chhattisgarh', cities: ['Raipur','Bhilai','Korba','Sambalpur','Durg'] },
    { name: 'Dadra & Nagar Haveli and Daman & Diu', cities: ['Daman & Diu'] },
    { name: 'Delhi', cities: ['New Delhi'] },
    { name: 'Goa', cities: ['Margao, Panaji','Ponda','vasco da dama'] },
    { name: 'Gujarat', cities: ['Ahmedabad','Surat','Vadodara','Rajkot','Bhavnagar','Jamnagar','Junagadh','Gandhidham','Nadiad','Gandhinagar','Anand','Morbi','Mehsana','Surendranagar Dudhrej'] },
    { name: 'Haryana', cities: ['Faridabad','Gurgaon','Rohtak','Panipat','Karnal','Sonipat','Yamunanagar','Panchkula','Bhiwani','Ambala','Sirsa'] },
    { name: 'Himachal Pradesh', cities: ['Shimla'] },
    { name: 'Jammu and Kashmir', cities: ['Srinagar'] },
    { name: 'Jharkhand', cities: ['Ranchi','Dhanbad','Jamshedpur','Bokaro','Mango','Deoghar','Phusro','Hazaribagh','Giridih','Ramgarh','Medininagar'] },
    { name: 'Karnataka', cities: ['Bangalore','Hubli-Dharwad','Mysore','Gulbarga','Mangalore','Belgaum','Davanagere','Bellary','Bijapur','Shimoga','Tumkur','Raichur','Bidar','Hospet','Udupi'] },
    { name: 'Kerala', cities: ['Thiruvananthapuram','Kochi','Kozhikode','Kollam','Thrissur','Alappuzha','Kottayam'] },
    { name: 'Ladakh', cities: ['Ladakh'] },
    { name: 'Lakshadweep', cities: ['Lakshadweep'] },
    { name: 'Madhya Pradesh', cities: ['Indore','Bhopal','Gwalior','Jabalpur','Ujjain','Sagar','Dewas','Satna','Ratlam','Rewa','Katni','Singrauli','Burhanpur','Khandwa','Morena','Bhind','Guna','Shivpuri'] },
    { name: 'Maharashtra', cities: ['Mumbai','Pune','Nagpur','Thane','Pimpri-Chinchwad','Nashik','Kalyan-Dombivli','Vasai-Virar','Aurangabad','Navi Mumbai','Solapur','Mira-Bhayandar','Jalgaon','Bhiwandi','Amravati','Nanded','Kolhapur','Akola','Ulhasnagar','Sangli-Miraj & Kupwad','Malegaon','Latur','Dhule','Ahmednagar','Satara','Chandrapur','Parbhani','Ichalkaranji','Jalna','Ambarnath','Bhusawal','Panvel'] },
    { name: 'Manipur', cities: ['Imphal'] },
    { name: 'Meghalaya', cities: ['Shillong'] },
    { name: 'Mizoram', cities: ['Aizawl'] },
    { name: 'Nagaland', cities: ['Beijing'] },
    { name: 'Odisha', cities: ['Bhubaneswar','Cuttack','Rourkela','Berhampur','Bilaspur','Raurkela Industrial Township'] },
    { name: 'Puducherry', cities: ['Ozhukarai','Pondicherry'] },
    { name: 'Punjab', cities: ['Ludhiana','Amritsar','Jalandhar','Patiala','Bathinda','Phagwara','Mohali'] },
    { name: 'Rajasthan', cities: ['Jaipur','Jodhpur','Kota','Bikaner','Ajmer','Udaipur','Bhilwara','Alwar','Bharatpur','Sikar','Pali','Sri Ganganagar'] },
    {name: 'sikkim', cities:['Gangtok']},
    { name: 'Tamil Nadu', cities: ['Chennai','Coimbatore','Madurai','Tiruchirappalli','Tiruppur','Salem','Erode','Ambattur','Tirunelveli','Avadi','Tiruvottiyur','Thoothukudi', 'Nagercoil','Thanjavur','Pallavaram','Dindigul','Vellore','Kumbakonam','Pudukkottai','Hosur','Karaikudi'] },
    { name: 'Telangana', cities: ['Hyderabad','Warangal','Nizamabad','Khammam','Karimnagar','Ramagundam','Secunderabad','Mahbubnagar','Suryapet','Miryalaguda'] },
    { name: 'Tripura', cities: ['Agartala'] },
    { name: 'Uttar Pradesh', cities: ['Lucknow','Kanpur','Ghaziabad','Agra','Meerut','Varanasi','Allahabad','Bareilly','Aligarh','Moradabad','Saharanpur','Gorakhpur','Noida','Firozabad','Loni','Jhansi','Muzaffarnagar','Mathura','Shahjahanpur','Rampur','Mau','Farrukhabad','Hapur','Etawah','Mirzapur','Bulandshahr','Sambhal','Amroha','Fatehpur','Raebareli','Khora, Ghaziabad','Orai','Bahraich','Jaunpur','Unnao','Ballia'] },
    { name: 'Uttarakhand', cities: ['Dehradun','Haridwar'] },
    { name: 'West Bengal', cities: ['Kolkata','Howrah'] },
  ];
  cities: Array<any>;
  filteredOptions: Observable<string[]>;
  private sub: any;
  linkedInCredentials = {
    clientId: "7754fgjnnfa2j2",
    redirectUrl: "",
    scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
  };
  
  disabledBtnRegister:boolean=false;
  countryUnitedStateList:Array<any> = [];
  IndiaCountryStates:Array<any> = [];
  showHideOtherCity:boolean=false;

  api_end_point:any;
  constructor(private authService: SocialAuthService, private formbuilder: UntypedFormBuilder, private router: Router, private API: ApiService,private _service:MyserviceService, private activateRoute: ActivatedRoute, private route: ActivatedRoute) {
    this._service.clearAllItems();
    this.createdForm = this.formbuilder.group({
      fullName: ['', [Validators.required]],
      mobileNo: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(10),Validators.pattern("^[0-9]*$")]],
      email: ['', [Validators.required, Validators.email,Validators.maxLength(250),Validators.minLength(5), Validators.pattern(/.+@.+\..+/)]],
      password: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
      gender: ['M'],
      age: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      othercity:[''],
      source:['', Validators.required],
      typingSpeed:['', Validators.required],
      language: ['', Validators.required],
      educatioanl: ['', Validators.required],
      countryCode: ['+91', Validators.required],
      workExperience: ['', Validators.required],
      setup: ['', Validators.required],
      myControl:[''],
      "fileSource": [''],
      "file": [''],
    })
  }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
    this.IndiaCountryStates=this.countryList;
    this.linkedInCredentials.redirectUrl = location.protocol + '//' + location.host + location.pathname;
    this.linkedInToken = this.route.snapshot.queryParams["code"];
    console.log(this.linkedInToken);
    if(this.linkedInToken){
      this.fetchLinkedinProfile();
    }
    
    window.scroll({ top: 0 });
    try {
			let prevForm = localStorage.getItem("register-form");
			if (prevForm) {
        let parseForm = JSON.parse(prevForm);
      
				this.createdForm.controls.fullName.setValue(parseForm.fullName);
				this.createdForm.controls.mobileNo.setValue(parseForm.mobileNo);
				this.createdForm.controls.email.setValue(parseForm.email);
				this.createdForm.controls.password.setValue(parseForm.password);
				this.createdForm.controls.gender.setValue(parseForm.gender);
        if(parseForm.age== "1970-01-01T00:00:00.000Z" || (parseForm.age==null)){
          this.createdForm.controls.age.setValue("");
        }
        else{
          this.createdForm.controls.age.setValue(new Date(parseForm.age));
        }
        // if((parseForm.age != "1970-01-01T00:00:00.000Z") || (parseForm.age!=null)){
        //   this.createdForm.controls.age.setValue(new Date(parseForm.age));
        // }
				
				this.createdForm.controls.state.setValue(parseForm.state);
        let _loadCity=parseForm.city;
        if(_loadCity=="Other"){
          this.createdForm.controls.city.setValue('');
        }
        else{
				this.createdForm.controls.city.setValue(parseForm.city);
        }
        this.changeCountry(parseForm.state)
				this.createdForm.controls.source.setValue(parseForm.source);
				this.createdForm.controls.typingSpeed.setValue(parseForm.typingSpeed);
				this.createdForm.controls.language.setValue(parseForm.language);
				this.createdForm.controls.educatioanl.setValue(parseForm.educatioanl);
				this.createdForm.controls.countryCode.setValue(parseForm.countryCode);
				this.createdForm.controls.workExperience.setValue(parseForm.workExperience);
				// this.createdForm.controls.experienceCustomerSupport.setValue(parseForm.experienceCustomerSupport);
				// this.createdForm.controls.businessProcess.setValue(parseForm.businessProcess);
				// this.createdForm.controls.expVoiceSupport.setValue(parseForm.expVoiceSupport);
				this.createdForm.controls.setup.setValue(parseForm.setup);
			}
		} catch (e) {
			
		}
  
    this.sub = this.activateRoute.params.subscribe(params => {
    
      if(params['id']){
        this.selectedClientId =  this.client_ids[params['id']];
        
        this.API.getClientConfig(params['id']).subscribe((data: any) => {
          if(data.errors){
            return;
          }
          if(data.success){
            this.selectedClientId = {
              rec_id:data.success.recruitment_groupid, group_id: data.success.groupid,trainer_id: data.success.trainer_groupid, name:data.success.name
            }
          }
        })
      }
      console.log(this.selectedClientId)
    });
    this.onChanges();
    this.authService.authState.subscribe((user) => {
      console.log(user);
      this.createdForm.controls.fullName.setValue(user.name);
			this.createdForm.controls.email.setValue(user.email);
    });
    this.getUnitedStatesCountryList();
  
  }



getUnitedStatesCountryList(){
 this.countryUnitedStateList = [
    { name: 'Alabama', cities: ['Alexander City','Andalusia','Anniston','Athens','Atmore','Auburn','Bessemer','Birmingham','Chickasaw','Clanton','Cullman','Decatur','Demopolis','Dothan','Enterprise','Eufaula','Florence','Fort Payne','Gadsden','Greenville','Guntersville','Huntsville','Jasper','Marion','','Mobile','Montgomery','Opelika','Ozark','Phenix City','Prichard','Scottsboro','Selma','Sheffield','Sylacauga','Talladega','Troy','Tuscaloosa','Tuscumbia','Tuskegee'] },
    { name: 'Alaska', cities: ['Anchorage','Cordova','Fairbanks','Haines','Homer','Juneau','Ketchikan','Kodiak','Kotzebue','Nome','Palmer','Seward','Sitka','Skagway','Valdez'] },
	
    { name: 'Arizona', cities: ['Ajo','Avondale','Bisbee','Casa Grande','Chandler','Clifton','Douglas','Flagstaff','Florence','Gila Bend','Glendale','Globe','Kingman','Lake Havasu City','Mesa','Nogales','Oraibi','Phoenix','Prescott','Scottsdale','Sierra Vista','Tempe','Tombstone','Tucson','Walpi','Window Rock','Winslow','Yuma'] },
	
    { name: 'Arkansas', cities: ['Arkadelphia','Arkansas Post','Batesville','Benton','Blytheville','Camden','Conway','Crossett','El Dorado','Fayetteville','Forrest City','Fort Smith','Harrison','Helena','Hope','Hot Springs','Jacksonville','Jonesboro','Little Rock','Magnolia','Morrilton','Newport','North Little Rock','Osceola','Pine Bluff','Rogers','Searcy','Stuttgart','Van Buren','West Memphis'] },
    { name: 'California', cities: ['Alameda','Alhambra','Anaheim','Antioch','Arcadia','Bakersfield','Barstow','Belmont','Berkeley','Beverly Hills','Brea','Buena Park','Burbank','Calexico','Calistoga','Carlsbad','Carmel','Chico','Chula Vista','Claremont','Compton','Daly City','Davis','Downey','Eureka','Fairfield','Fontana','Hollywood','Yuba City','Other'] },
    { name: 'Colorado', cities: ['Alamosa','Aspen','Aurora','Boulder','Breckenridge','Brighton','Canon City','Central City','Climax','Colorado Springs','Cortez','Cripple Creek','Denver','Durango','Englewood','Estes Park','Fort Collins','Fort Morgan','Georgetown','Glenwood Springs','Golden','Grand Junction','Greeley','Gunnison','La Junta','Leadville','Littleton','Longmont','Loveland','Montrose','Ouray','Pagosa Springs','Pueblo','Silverton','Steamboat Springs','Sterling','Telluride','Trinidad','Vail','Walsenburg','Westminster'] },
    { name: 'Connecticut', cities: ['Ansonia','Berlin','Bloomfield','Branford','Coventry','Danbury','Darien','Shelton','Simsbury','Southington','Stamford','Stonington','Stratford','Willimantic','Other'] },
    { name: 'Delaware', cities: ['Dover','Lewes','Milford','New Castle','Newark','Smyrna','Wilmington'] },
    { name: 'Florida', cities: ['Apalachicola','Bartow','Belle Glade','Cocoa Beach','Daytona Beach','Fort Walton Beach','Hollywood','Lake City','Largo','Miami Beach','Palm Beach','Panama City','Other'] },
    { name: 'Georgia', cities: ['Albany','Americus','Blairsville','Columbus','Dalton','Darien','Rome','Washington','Other'] },
    { name: 'Hawaii', cities: ['Hanalei','Hilo','Honaunau','Honolulu','Kahului','Kaneohe','Kapaa','Kawaihae','Lahaina','Laie','Wahiawa','Wailuku','Waimea'] },
    { name: 'Idaho', cities: ['Blackfoot','Boise','Bonners Ferry','Caldwell','Coeur d’Alene','Idaho City','Idaho Falls','Kellogg','Lewiston','Moscow','Nampa','Pocatello','Priest River','Rexburg','Sun Valley','Twin Falls'] },
    { name: 'Illinois', cities: ['Alton','Belleville','Brookfield','Calumet City','Salem','Vandalia','Wood River','Zion','Other'] },
    { name: 'Indiana', cities: ['Anderson','Bedford','Bloomington','Columbus','Connersville','Corydon','Other'] },
    { name: 'Iowa', cities: ['Amana Colonies','Ames','Boone','Burlington','Cedar Falls','Cedar Rapids','Other'] },
    { name: 'Kansas', cities: ['Abilene','Arkansas City','Atchison','Kansas City','Newton','Salina','Wichita'] },
    { name: 'Kentucky', cities: ['Ashland','Barbourville','Bardstown','Berea','Other'] }, 
    { name: 'Louisiana', cities: ['Abbeville','Alexandria','Bastrop','Baton Rouge','Bogalusa','Bossier City','Gretna','Houma','Lafayette','Lake Charles','Monroe','Morgan City','Natchitoches','New Iberia','New Orleans','Opelousas','Ruston','Saint Martinville','Shreveport','Thibodaux'] },
    { name: 'Maine', cities: ['Auburn','Augusta','Bangor','Farmington','Orono','Portland','Rockland','York'] },
    { name: 'Maryland', cities: ['Aberdeen','Annapolis','Baltimore','Bethesda-Chevy Chase','Bowie','Cambridge','Other'] },
    { name: 'Massachusetts', cities: ['Abington','Adams','Amesbury','Bourne','Chicopee','Dennis','Harwich','Other'] },
    { name: 'Michigan', cities: ['Adrian','Alma','Ann Arbor','Battle Creek','Bay City','Benton Harbor','Cheboygan','Dearborn','Other'] },
    { name: 'Minnesota', cities: ['Albert Lea','Brainerd','Hastings','Hibbing','International Falls','Rochester','Saint Cloud','Willmar','Winona','Other'] },
    { name: 'Mississippi', cities: ['Bay Saint Louis','Biloxi','Canton','Columbia','Columbus','Pascagoula','Yazoo City','Other'] },
    { name: 'Missouri', cities: ['Boonville','Branson','Cape Girardeau','Lamar','Saint Joseph','Saint Louis','Sainte Genevieve','West Plains','Other'] },
    { name: 'Montana', cities: ['Anaconda','Billings','Bozeman','Great Falls','Havre','Other'] },
    {name: 'Nebraska', cities:['Beatrice','Bellevue','Boys Town','Chadron','Columbus','Fremont','Grand Island','Hastings','Kearney','Lincoln','McCook','Minden','Nebraska City','Sidney','Other']},
    { name: 'Nevada', cities: ['Boulder City','Carson City','Elko','Ely','Fallon','Genoa','Goldfield','Henderson','Las Vegas','North Las Vegas','Reno','Sparks','Virginia City','Winnemucca'] },
    { name: 'New Hampshire', cities: ['Berlin','Concord','Laconia','Lebanon','Salem','Somersworth','Other'] },
    { name: 'New Jersey', cities: ['Asbury Park','Clifton','Lakehurst','Long Branch','Madison','Princeton','Union City','Woodbridge','Other'] },
    { name: 'New Mexico', cities: ['Acoma','Hobbs','Shiprock','Silver City','Tucumcari','Other'] },
    { name: 'New York', cities: ['Albany','Amsterdam','Bronx','Cortland','Jamestown','New York City','Yonkers','Other'] },
    { name: 'North Carolina', cities: ['Asheboro','Concord','Halifax','Morganton','Washington','Other'] },
	{ name: 'North Dakota', cities: ['Bismarck','Devils Lake','Dickinson','Fargo','Grand Forks','Jamestown','Mandan','Minot','Rugby','Valley City','Wahpeton','Williston'] },
	{ name: 'Ohio', cities: ['KAkron','Alliance','Ashtabula','Bedford','Other'] },
	{ name: 'Oklahoma', cities: ['Ada','Claremore','Elk City','Lawton','Midwest City','Woodward','Other'] },
	{ name: 'Oregon', cities: ['Baker City','Beaverton','Bend','Brookings','Other'] },
	{ name: 'Pennsylvania', cities: ['Abington','Aliquippa','Other'] },
	{ name: 'Rhode Island', cities: ['Kingston','Middletown','Other'] },
	{ name: 'South Carolina', cities: ['Columbia','Darlington','Other'] },
	{ name: 'South Dakota', cities: ['Aberdeen','Brookings','Other'] },
	{ name: 'Tennessee', cities: ['Athens','Chattanooga','Other'] },
	{ name: 'Texas', cities: ['Crystal City','Dallas','Other'] },
	{ name: 'Utah', cities: ['Alta','Delta','Other'] },
	{ name: 'Vermont', cities: ['Barre','Newport','Other'] },
	{ name: 'Virginia', cities: ['Hanover','Hampton','Other'] },
	{ name: 'Washington', cities: ['Kelso','Longview','Other'] },
	{ name: 'West Virginia', cities: ['Bath','Bluefield','Other'] },
	{ name: 'Wisconsin', cities: ['Baraboo','Madison','Other'] },
	{ name: 'Wyoming', cities: ['Cody','Powell','Other'] },
  ];
  
}





  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithLinkedin() {
    console.log(`https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${
      this.linkedInCredentials.clientId
    }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`)
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${
      this.linkedInCredentials.clientId
    }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
  }

  fetchLinkedinProfile(){
    const queryParam = `?auth_code=${this.linkedInToken}&redirectUrl=${this.linkedInCredentials.redirectUrl}`;
    this.API.fetchLinkedinProfile(queryParam,this.api_end_point).subscribe((data: any) => {
      if(data.errors){
        return;
      }
      if(data.success){
        this.createdForm.controls.fullName.setValue(data.success.name);
        this.createdForm.controls.email.setValue(data.success.email);
      }
    })
  }


  signOut(): void {
    this.authService.signOut();
  }

  backtoLogin() {
    this.router.navigateByUrl('/login');
  }

  changeCountry(count, refreshCity: boolean = false) {
    this.showHideOtherCity=false;
    if(refreshCity){
      this.createdForm.controls.city.setValue('');
    }
    
    this.cities = this.countryList.find(con => con.name == count).cities;
    this.getcitiesList(this.cities);
  }

  getcitiesList(files:any[] = []){
    //this.cities= files;
    this.filteredOptions = this.createdForm.controls['myControl'].valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.cities.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  selectedFile(file){
    this.showHideOtherCity=false;
    this.cityvaluesave=file;
    if(file=="Other"){
      this.showHideOtherCity=true;
    }
  } 

  get _form() { return this.createdForm.controls }

  
  registerBtn(createdForm: any) {
    //debugger;
    const charpattern = new RegExp("^[a-zA-Z ]*$");
    const phonepattern= new RegExp("^[0-9]");
    this.showHideMessage = false;
    let _fullName = createdForm.controls["fullName"].value;
    //let _mobileNo = createdForm.controls["countryCode"].value+createdForm.controls["mobileNo"].value;
    let _mobileNo = "+91"+createdForm.controls["mobileNo"].value;
    let _email = createdForm.controls["email"].value;
    let _password = createdForm.controls["password"].value;
    let _gender = createdForm.controls["gender"].value;

    let dob = createdForm.controls["age"].value;
    let _state = createdForm.controls["state"].value;
    let _city = createdForm.controls["city"].value;
     let _othercity= createdForm.controls["othercity"].value;
    let _source = createdForm.controls["source"].value;
    let _typingSpeed= createdForm.controls["typingSpeed"].value;

    let _language = createdForm.controls["language"].value;
    let _educatioanl = createdForm.controls["educatioanl"].value;
    let _workExperience = createdForm.controls["workExperience"].value;
    // let _experienceCustomerSupport = createdForm.controls["experienceCustomerSupport"].value;
    // let _businessProcess = createdForm.controls["businessProcess"].value;
    // let _expVoiceSupport = createdForm.controls["expVoiceSupport"].value;
    let _setup = createdForm.controls["setup"].value;
    
    if (_fullName == "" || _fullName==null) {
      this.messageDisplay = "Please enter full name*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if(!charpattern.test(createdForm.controls["fullName"].value))
    {
      this.messageDisplay = "Special Characters and numbers not allowed in name*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_mobileNo == "" || (createdForm.controls["mobileNo"].value)==null) {
      this.messageDisplay = "Please enter mobile number*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    
    if (createdForm.controls["mobileNo"].value.length < 10) {
      this.messageDisplay = "Mobile Number should be 10 digit*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if(!phonepattern.test(createdForm.controls["mobileNo"].value))
    {
      this.messageDisplay = "Special Characters not allowed in mobile number*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_email == "" || _email==null) {
      this.messageDisplay = "Please enter email*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (regEmail.test(_email) == false) {
      this.messageDisplay = "Please enter correct email*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return false;
    }
    if (_password == "" ||_password == null|| (_password.length < 8) || (15 < _password.length)) {
      this.messageDisplay = "Please enter password between 8 and 15 character*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (dob == "" || dob == null) {
      this.messageDisplay = "Please enter date of birth*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_state == "" || _state == null) {
      this.messageDisplay = "Please enter state*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_city == "" || _city==null) {
      this.messageDisplay = "Please select city from dropdown*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }


    if(this.cityvaluesave=="Other"){

    if (_othercity == "" || _othercity==null) {
      this.messageDisplay = "Please enter city for selected other city*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
  }





    // if(!charpattern.test(createdForm.controls["city"].value))
    // {
    //   this.messageDisplay = "Special Characters and numbers not allowed in city*";
    //   this.msgColor = "red";
    //   this.showHideMessage = true;
    //   window.scroll({
    //     top: 20,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    //   return;
    // }
    if (_source == "" || _source == null) {
      this.messageDisplay = "Please select source*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_typingSpeed == "" || _typingSpeed == null) {
      this.messageDisplay = "Please enter typing speed*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_language == "" || _language == null) {
      this.messageDisplay = "Please select atleast one language*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_educatioanl == "" || _educatioanl == null) {
      this.messageDisplay = "Please select education*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    if (_workExperience == "" || _workExperience == null) {
      this.messageDisplay = "Please select work experience*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }
    // if (_expVoiceSupport == "") {
    //   this.messageDisplay = "Please select voice support*";
    //   this.msgColor = "red";
    //   this.showHideMessage = true;
    //   window.scroll({
    //     top: 20,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    //   return;

    // }
    if (_setup == "" || _setup==null) {
      this.messageDisplay = "Please select atleast one setup*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;

    }

    const age = moment().diff(moment(createdForm.controls["age"].value, "DD-MM-YYYY"), 'years');
    console.log(createdForm.controls["age"].value)
    console.log(moment(createdForm.controls["age"].value, "DD-MM-YYYY"))
    if(age < 18){
      this.messageDisplay = "Age should be more than 18*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    this.disabledBtnRegister=true;
    let _cityData;
if(_city=="Other"){
_cityData=_othercity;
}
else{
_cityData=this.cityvaluesave;
}
    let _obj = {
      "name": _fullName,
      "phone": _mobileNo,
      "email": _email,
      "password": _password,
      "gender": _gender,
      "refer_source": _source,
      "typing_speed": _typingSpeed,
      "age": age,
      "dob": moment(moment(dob).format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD HH:mm:ss"),
      "state":_state,
      "city":_cityData,//this.cityvaluesave,
      "language": _language.toString(),
      "education": _educatioanl,
      "work_experience": _workExperience,
      'reg_mode': 'WEB',
      'login_method': 'smb',
       'company':this.selectedClientId.name,
       'actual_company':this.selectedClientId.name,
      'groupid': this.selectedClientId.group_id,
      'actual_group_id': this.selectedClientId.group_id,
      'applicant_group': this.selectedClientId.rec_id,
      'additional_data': JSON.stringify({
        
        "setup_options": _setup
      })
    }
   
    this.API.saveJoin(_obj).subscribe((data: any) => {
      if(data.errors){
        alert(data.errors);
        this.disabledBtnRegister=false;
        return;
      }
      if (data) {
        localStorage.removeItem("register-form");
		  let formData = {
			  "email": _email,
			  "password": _password,
			  "login_method": "smb"
		  };
		  this.API.loginAinexAPI(formData)
			  .subscribe(
				  (res2: any) => {
					  localStorage.setItem('ainex_token', res2["token"])
				  })
        let access_token = data['token'];
        if(access_token){
          this._service.set_access_token(access_token);
          this._service.set_loggedin_user(data);
		 // this.API_End_Point = res['platform_details']['API_End_Point'];
        }
        this.API.settingsAPI(null).subscribe(
          (res:any) => {
            
            if (res.success) {
            this._service.set_stage_settings(res.success);
            }

            var recruitmentData = {
              id: data.id,
              prodcution_group_id: this.selectedClientId.rec_id,
              name: data.name,
              email: data.email,
              number: data.phone
            }
            var objProfile = {
              id: data.id,
              role: 'user'
            }
            this.API.updateRoleStatus(objProfile,this.api_end_point).subscribe((data: any) => {
              if (data.success) {
                // alert("User updated successfully");
               // alert(data.success);
                this.messageDisplay = data.success;
                this.msgColor = "green";
                this.showHideMessage = true;
                window.scroll({
                  top: 20,
                  left: 0,
                  behavior: 'smooth'
                });
                this.disabledBtnRegister=true;
                setTimeout(()=>{
                  this.showHideMessage = false;
                },2000);
                setTimeout(()=>{
                  this.disabledBtnRegister=false;
                },5000)
              }
            });
            this.API.recruitmentSingle(recruitmentData,this.api_end_point).subscribe((res: any) => {
              if (res.errors) {
                console.log(res.errors);
                this.disabledBtnRegister=false;
              } else {
    
                let file = this.createdForm.get('fileSource').value;
                if(file){
                  let file_type = "RESUME";
                  let doc_id = "resume";
    
                  let formData = new FormData();
                  formData.append('attachment', file, file.name.replace(/\s/g, ''));
                  formData.append('file_name', file.name.replace(/\s/g, ''));
                  formData.append('doc_type', file_type);
    
                  formData.append('applicant_email', data.email);
                  formData.append('doc_value', doc_id);
                  formData.append('applicant_id', res.success.data[0].applicant_id);
                  formData.append('user_id', res.success.data[0].user_id);
                  this.API.documentUpload(formData,this.api_end_point).subscribe((res: any) => {
                    if (res.errors) {
                      // alert(res.errors);
                      this.disabledBtnRegister=false;
                    } else {
                      
                    }
                    this.navigateAfterRegister();
                     // alert("Successfully created account");
                     
    
                  }, (err) => {
                    console.log(err)
                    this.disabledBtnRegister=false;
                    // alert('Error in the Upload')
                    this.navigateAfterRegister();
                  })
                }else{
                  this.navigateAfterRegister();
                }
                
              }
    
            }, (err) => {
              console.log(err)
              this.disabledBtnRegister=false;
              alert(err)
            })
    
           
            // this.createdForm.reset();
    
            window.scroll({
              top: 20,
              left: 0,
              behavior: 'smooth'
            });
        })
        
      }
      else {
        this.messageDisplay = data.errors + "*";
        this.msgColor = "red";
        this.showHideMessage = true;
        window.scroll({
          top: 20,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  navigateAfterRegister(){
    this.resetForm();
    this.messageDisplay = "Congratulations, Saved Successfully.!!";
    this.msgColor = "green";
    this.showHideMessage = true;
    setTimeout(()=>{
      this.router.navigate(['/agentPluginPC/assessment']);
    },2000);
    this.messageDisplay = "Your account has been created successfully";
    this.msgColor = "green";
    this.showHideMessage = true;
    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    this.disabledBtnRegister=true;
    setTimeout(()=>{
      this.showHideMessage = false;
    },2000);
    setTimeout(()=>{
      this.disabledBtnRegister=false;
    },5000)
    
  }

  omit_special_char_num(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);

 
  
  }
  onlyNumberValidation(event) {
    console.log(event.target.value)
    // if(event.charCode >= 48 && event.charCode <= 57){
    //   return true;
    // }
    // if(Math.sign(event.target.value) == -1){
    //   return false;
    // }
    return event.charCode >= 48 && event.charCode <= 57;
  }

  changeFile(event) {
    this.currentFileobj = {};

    if (event.target.files && event.target.files.length) {
      console.log(event.target.files);
      this.currentFileobj = event.target.files[0];
      const [upload_file] = event.target.files;
      this.createdForm.patchValue({
        fileSource: upload_file
      });
    }
  }
  signInBtn(){
    this.router.navigateByUrl('/signin');
  }

  onChanges(): void {
    this.createdForm.valueChanges.subscribe(val => {
      console.log(val);
      try {
        localStorage.setItem("register-form", JSON.stringify(val));
      } catch (e) {
        console.log('Error in storing the value in the settings')
      }
    });
  }
  resetForm(){
    this.currentFileobj = {};
    this.createdForm.reset();
    this.createdForm.controls.countryCode.setValue('+91');
    this.createdForm.controls.gender.setValue('M');
    this.disabledBtnRegister=false;
  }
  cityChange(){
   // alert("Please select city from dropdown ");
    this.createdForm.controls.city.setValue('');
    // this.createdForm.controls.state.setValue('');
    // this.filteredOptions = new Observable();
    let _lenCityValue=  this.createdForm.controls["city"].value;
    if(_lenCityValue==""){
      this.showHideOtherCity=false;
      this.createdForm.controls.othercity.setValue('')
    }
     
  }
  changeCountryCode(value:any){
    console.log(this.countryList);
    if(value=="+1"){
      this.countryList=[];  
      this.filteredOptions = new Observable(); 
    //   this.createdForm.controls.city.setValue(''); 
      let _US=[];
       _US=this.countryUnitedStateList;
      this.countryList= _US;
    }
  else if(value=="+91"){
      this.countryList=[];
      this.filteredOptions = new Observable();
      this.createdForm.controls.city.setValue('');
      let _IN=[];
      _IN= this.IndiaCountryStates
      this.countryList= _IN;
    }
    else{
      this.countryList=[];
      this.filteredOptions = new Observable();
      this.createdForm.controls.city.setValue('');
      let _IN=[];
      _IN= this.IndiaCountryStates
      this.countryList= _IN;
    }
	setTimeout(()=>{
		this.createdForm.controls.state.setValue('');

		this.createdForm.controls.city.setValue('');

	},500)
  }
}
 
