import { Injectable } from '@angular/core';

import { MyserviceService } from './myservice.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  user: any = this.myservice.get_loggedin_user();
  campaign_controls = this.myservice.getSettings('campaign_controls');
  
  constructor(
    private myservice: MyserviceService,
  ) {
    /* console.log('campaign controls', this.campaign_controls);
    this.myservice.settings.subscribe(e => {
      this.campaign_controls = this.myservice.getSettings('campaign_controls');
      console.log('campaign controls', this.campaign_controls);
    }) */
   }

  disable_based_on_incallscreen(campaignObj){
    // console.log('input', campaignObj)
    try{
      if (campaignObj.incallscreen){
        return true
      }
      return false
    }catch(e){
      return false
    }
  }

  disable_campaign_full_access(campaignObj){
    try{
      if(campaignObj.user_id == this.user.id){
        return false
      }else{
        if(this.campaign_controls){
          let master_control = this.campaign_controls.filter(obj => obj.name == 'full_access');
          if(master_control.length){
            return master_control[0]['definition'] != 'on';
          }
        }
        return true
      }
    }catch(error){
      return true
    }
  }

  having_full_access(){
    try{
      if(this.campaign_controls){
        let master_control = this.campaign_controls.filter(obj => obj.name == 'full_access');
        if(master_control.length){
          return master_control[0]['definition'] == 'on';
        }
      }
      return false
    }catch(e){
      return false
    }
  }

  hide_customer_number_access(){
    try{
      let control = this.campaign_controls.filter(obj => obj.name == 'hide_customer_number');
      let master_control = this.campaign_controls.filter(obj => obj.name == 'full_access');
      
      if(master_control.length){
        if(master_control[0]['definition'] == 'on'){
          return false;
        }
      }
      if(control.length){
        return control[0]['definition'] == 'on';
      }
      return false;
    }catch(e){
      return false;
    }
  }

  check_accessibility(access_name?: string){
    try{
      let master_control = this.campaign_controls.filter(obj => obj.name == 'full_access');
      if(master_control.length){
        if(master_control[0]['definition'] == 'on'){
          return true;
        }
      }
      if(access_name){        
        let control = this.campaign_controls.filter(obj => obj.name == access_name);
        if(control.length){
          return control[0]['definition'] == 'on'
        }
      }
      return false;
    }catch(err){
      return false;
    }
  }
}
