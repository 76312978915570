import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { ApiService } from '../api.service';
import {FormControl} from '@angular/forms';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { CertificationScoreDialogComponent } from '../auditor/certification-score-dialog/certification-score-dialog.component';
import {MyserviceService} from '../myservice.service';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {
 displayedColumns = ['name', 'email', 'phone','id', 'score','audit_score','uncertify_count','batch_status','batch_type','action','certified_date'];
  dataSource = new MatTableDataSource();
  selectedBatch;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  batchOptionList:any;
  filterBatchValue:any;
  selectedCertified:any;
  selectedBatchObj: any;
  updateCertificationEdit: boolean = false;
  errorMsg:any="";
  statusColor:any="";
  api_end_point:any;

  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private _service:MyserviceService) { }

  ngOnInit() { 
	this.api_end_point = this._service.get_API_End_Point();
    this.getCertificationBatch();
    }
  getCertificationBatch(){   
    let obj={
      "for_group":2053,
      "for_certificate":true
    }
   this.API.getCertificationBatch(obj,this.api_end_point).subscribe((data:any)=>{
     let _response=data.success;
     if(_response){       
       this.batchOptionList=_response;
    
     }
   });
  }
  changeBatch(value:any){
    if(!value){
      return;
    }
    this.errorMsg="";
    this.statusColor="";
   // console.log(value)
    this.selectedBatch = value.id;
    this.selectedBatchObj = value;
let obj={
"for_group":2053,
"batch_code":value.id,
"for_certificate":true,
"batch_type": value.training_type
}
if(value.training_type == 'OJT'){
  
  this.displayedColumns = ['name', 'email', 'phone','id', 'audit_score','uncertify_count','batch_status','batch_type','certified_date']
}else{
  this.displayedColumns = ['name', 'email', 'phone','id', 'score','audit_score','uncertify_count','batch_status','batch_type','action','certified_date']
}
this.filterBatchValue=value.id;
this.API.getCertificationList(obj,this.api_end_point).subscribe((data:any)=>{

 let _response=data.success;
 if(_response){   
  let ELEMENT_DATA=_response;
  this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  this.dataSource.paginator = this.paginator;
  this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
    return data.name.toString().toLowerCase().includes(filter) || data.email.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase().includes(filter) 
  };
 }
 if(data.errors){
   //alert(data.errors);
   this.errorMsg=data.errors;
   this.statusColor="red";
   this.dataSource = new MatTableDataSource();
  this.dataSource.paginator = this.paginator;
 }
});

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
 
  updateCertification(){
    this.errorMsg="";
    this.statusColor="";
    if(!this.updateCertificationEdit){
     // alert('Please update certification')
      this.errorMsg="Please update certification";
      this.statusColor="red";
      return;
    }
     let _arrayData:any=this.dataSource.data;
    let _findData=this.batchOptionList.filter(v=>{return v.id==this.filterBatchValue });
    var _agentData: any = [], _all_agentData:any = [], _all_agentData_toremove:any = []
    if(_findData[0].training_type == 'OJT'){
      for(let i=0;i<_arrayData.length;i++){
        _all_agentData.push(_arrayData[i].id)
        if(_arrayData[i].ojt_certified_date){
          _agentData.push({
            "user_id":_arrayData[i].id,
            "status":_arrayData[i].ojt_certified_date == 'Uncertified' ? 'Uncertified' : 'Certified'
          });
          if(_arrayData[i].ojt_certified_date == 'Uncertified'){
            _all_agentData.pop(_arrayData[i].id)
          }
        }
     }
    }
    if(_findData[0].training_type == 'Regular'){
      for(let i=0;i<_arrayData.length;i++){
       // console.log(_arrayData[i])
        _all_agentData.push(_arrayData[i].id)
        if(_arrayData[i].certified_date){
          _agentData.push({
            "user_id":_arrayData[i].id,
            "status":_arrayData[i].certified_date == 'Uncertified' ? 'Uncertified' : 'Certified'
          })
          if(_arrayData[i].certified_date == 'Uncertified'){
            _all_agentData.pop(_arrayData[i].id)
          }
        }
        
     }
    }
  //  console.log(_all_agentData)
   let _obj= {
      "for_group":2053,
      "trainer_group":2044,
      "recruitment_group":24275,
      "batch_code":_findData[0].id,
      "training_type":_findData[0].training_type,
      "agents_data":_agentData,
      "agents_data_to_keep":_all_agentData,
   }
      this.API.postCerification(_obj,this.api_end_point).subscribe((data:any)=>{
        if(data.success){
        //  alert("Certification updated successfully");
        setTimeout(()=>{
        this.errorMsg="Certification updated successfully";
        this.statusColor="green";
      },100)
        setTimeout(()=>{
          this.errorMsg="";
          this.statusColor="";
          this.changeBatch(this.selectedBatchObj)
        },6000)
         

        
        }
        else{
         // alert(data.errors);
          this.errorMsg=data.errors;
          this.statusColor="red";
        }
      })

    
  }

  onSelectChange(select, index, batchType){
    // console.log(select)
    // console.log(index);
    this.updateCertificationEdit = true;
     let _arrayData:any=this.dataSource.data;
     if(batchType == 'Regular'){
      _arrayData[index].certified_date = select.value;
     }
     if(batchType == 'OJT'){
      _arrayData[index].ojt_certified_date = select.value;
     }
     
    this.dataSource.paginator = this.paginator;
  }

  extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
        if( ref[i] == 'id' && obj['agent_reference_id']){
          arr.push(obj['agent_reference_id'])
        }else{
          arr.push(obj[ref[i]])
        }
				
			} else {
				arr.push('')
			}
		}
		return arr;
  }
  
  exportBatch(){
    let _data_mapper = {
      'name': "Name", 'email':"Email", 'phone': 'Phone','id': "Id",'batch_status':'Status', 'Reports To':'Reports To','mock_audit_score': "Mock Audit Score"
    }
    if(this.selectedBatchObj.training_type == 'OJT'){
      _data_mapper['ojt_audit_score'] = "OJT Audit Score"
    }
    // let data = this.dataSource.data;
    let data = this.dataSource.filteredData ? this.dataSource.filteredData : this.dataSource.data;
    var i = 0;
    for(let obj of data){
      const score = obj['score'];
      if(score){
        const dataP = score.split(',');
        for(let obj of dataP){
          const dataC = obj.split(';');
          if(dataC.length){
            if(!_data_mapper['Test- '+dataC[0]]){
              _data_mapper['Test- '+dataC[0]] = 'Test- '+dataC[0];
            }
            data[i]['Test- '+dataC[0]] = (dataC.length > 0 ? dataC[1] : '') + '- ' + (dataC.length > 1 ? dataC[2] : '')
            //this.allTest.push({name: dataC[0], score: dataC.length > 0 ? dataC[1] : '', eligible: dataC.length > 1 ? dataC[2] : ''})
          }
        }
      }
      i = i+ 1;
    }
    let headers = Object.values(_data_mapper);
    if (data && data.length) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Candidate Data');
      worksheet.addRow(headers);
      data.forEach((d: any) => {
        // if(d.certified_date != null && d.certified_date != 'Uncertified'){
        //   let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        // }
        let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        
        //console.log('printing row', row);
      })
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Certification-list-'+this.selectedBatch+'.xlsx');
      })
    }
  }

  exportBatchEligible(){
    let _data_mapper = {
      'name': "Name", 'email':"Email", 'phone': 'Phone','id': "Id",'batch_status':'Status', 'Reports To':'Reports To','mock_audit_score': "Mock Audit Score"
    }
    if(this.selectedBatchObj.training_type == 'OJT'){
      _data_mapper['ojt_audit_score'] = "OJT Audit Score"
    }
    let data = this.dataSource.filteredData ? this.dataSource.filteredData : this.dataSource.data
    var i = 0;
    for(let obj of data){
      const score = obj['score'];
      if(score){
        const dataP = score.split(',');
        for(let obj of dataP){
          const dataC = obj.split(';');
          if(dataC.length){
            if(!_data_mapper['Test- '+dataC[0]]){
              _data_mapper['Test- '+dataC[0]] = 'Test- '+dataC[0];
            }
            data[i]['Test- '+dataC[0]] = (dataC.length > 0 ? dataC[1] : '') + '- ' + (dataC.length > 1 ? dataC[2] : '')
            //this.allTest.push({name: dataC[0], score: dataC.length > 0 ? dataC[1] : '', eligible: dataC.length > 1 ? dataC[2] : ''})
          }
        }
      }
      i = i+ 1;
    }
    let headers = Object.values(_data_mapper);
    if (data && data.length) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Candidate Data');
      worksheet.addRow(headers);
      data.forEach((d: any) => {
        // if(d.certified_date != null && d.certified_date != 'Uncertified'){
        //   let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        // }
        if(d.agent_status == 'On Board'){
          let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        }
        
        
        //console.log('printing row', row);
      })
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Certification-list-eligible'+this.selectedBatch+'.xlsx');
      })
    }
  }
  viewScoreDialog(element:any){
	  
	//console.log(element);
	//debugger;
	if(element.score==null){
		return;
	}
    var dialogRef = this.dialog.open(CertificationScoreDialogComponent, {
			width: '35%',
      disableClose:true,
			data: {data: element}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
			}
		});
  }

  markForMockCalling(agentObj){
    this.API.markForMockCalling(agentObj.id, agentObj.groupid,this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){       
        this.changeBatch(this.selectedBatchObj)
     
      }
    });
  }
 }

