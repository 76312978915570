<mat-drawer-container autosize>

  <mat-drawer #drawer class="gig-sidenav" mode="side" [ngClass]="{'minimize': is_minimized}" opened="true">
    <app-menu [navigateLink]="currentTitle" [is_minimized]="is_minimized" (is_minimizedChange)="onMinimizedChange($event)"></app-menu>
  </mat-drawer>

  <div class="v-mainDetailsDiv mt-100-px">
    <mat-card appearance="outlined" class="bg-white mat-elevation-z1">
      <div class="row">
        <div class="col-md-6 mb-2 pt-1">
          <h4 class="text-primary fw-normal" style="position: relative; top: 0px; z-index: 9;">Campaign Details</h4>
        </div>
        <div class="col-md-6 text-end mb-2">
          <button mat-raised-button class="mat-primary" (click)="backToCampaignDashboard()">
            <mat-icon>arrow_back</mat-icon> BACK
          </button>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-9 mb-2 pt-1">
          <div class="titlehead vThdx1" style="position: relative; top: 0px; z-index: 9;">
            <span>{{campaigndetails_data.campaign_name}}</span>
            <span class="toggle_btn" *ngIf="campaigndetails_status_assign">
              <mat-slide-toggle [(ngModel)]="campaign_status"
                (change)="campaignStatusChange($event,campaigndetails_data)"
                [disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)">
              </mat-slide-toggle>
            </span>
            <button mat-stroked-button class="ms-3">
              {{campaigndetails_data.priority == 2 ? 'Predictive' : 'Preview'}}
            </button>
          </div>
        </div>
        <div class="col-md-3 text-end mb-2">
          <button mat-button [matMenuTriggerFor]="menu">
             DOWNLOAD REPORT&nbsp;
             <!-- REPORTS&nbsp; -->
             <i class="fa fa-angle-down"></i>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="downloadCmail_report(campaigndetails_data)">CALL STATUS REPORT</button>
            <button mat-menu-item (click)="downloadCampaignStatusReport(campaigndetails_data,true)"
              *ngIf="visibility_my_agents_report(campaigndetails_data)">CAMPAIGN STATUS REPORT (MY AGENTS)</button>
            <button mat-menu-item [disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)"
              (click)="downloadCampaignStatusReport(campaigndetails_data)">CAMPAIGN STATUS REPORT</button>
            <button mat-menu-item [disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)"
              (click)="downloadDataSheet(campaigndetails_data)">DATA SHEET</button>
          </mat-menu>
        </div>
      </div>

      <div class="mt-2">
        <div class="divFlexCs1">
          <div>
            <span class="span1">Campaign Name</span>
            <span class="span2" matTooltip="{{campaigndetails_data.campaign_name}}">
              {{campaigndetails_data.campaign_name}}
            </span>
          </div>
          <div>
            <span class="span1">Owner</span>
            <span class="span2">{{campaigndetails_data.camapign_owner}}</span>
          </div>
          <div>
            <span class="span1">Status</span>
            <span class="span2">{{campaigndetails_data.status}}</span>
          </div>
          <div>
            <span class="span1">Last Refreshed</span>
            <span class="span2">{{campaigndetails_data.last_updated}}</span>
          </div>
          <div>
            <span class="span1">Campaign Created On</span>
            <span class="span2">{{campaigndetails_data.created_at | date: 'short'}}</span>
          </div>
          <div>
            <span class="span1">Uploaded</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_uploaded, 0)}}</span>
          </div>
          <div>
            <span class="span1">Duplicate</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_duplicate, 0)}}</span>
          </div>
          <div>
            <span class="span1">Errors</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.errors, 0)}}</span>
          </div>
          <div>
            <span class="span1">Assigned</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_added, 0)}}</span>
          </div>
          <div>
            <span class="span1">Dialled</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_dialled, 0)}}</span>
          </div>
          <div>
            <span class="span1">Contacted</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.contacted, 0)}}</span>
          </div>
          <div>
            <span class="span1">Rechurn</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.rechurn, 0)}}</span>
          </div>
          <div>
            <span class="span1">Type</span>
            <span class="span2">{{campaigndetails_data.data_source}}</span>
          </div>
          <div>
            <span class="span1">WCP Dropped</span>
            <span class="span2">{{bindWithDefaultValue(campaign_data_details['Total WCP Dropped'], '0')}}</span>
          </div>
          <div>
            <span class="span1">Total DND</span>
            <span class="span2">{{bindWithDefaultValue(campaign_data_details['Total DND'], '0')}}</span>
          </div>
          <div>
            <span class="span1">Form Type</span>
            <span class="span2">{{bindWithDefaultValue(campaigndetails_data.incallscreen, '')}}</span>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <hr style="border:none;border-top: 3px solid #ebf2f7;">
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-card class="mat-elevation-z4">
            <mat-card-header class="d-flex justify-content-between p-0">
              <mat-card-title class="text-primary fw-normal">
                Campaign Agents List
              </mat-card-title>
              <div class="d-flex gap-3">
                <mat-form-field appearance="outline" class="search-box">
                  <mat-label>Search</mat-label>
                  <input matInput placeholder="Search" autocomplete="off"
                    (keyup)="applySearchFilter($event.target.value)" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <!--  [disabled]="data_transfer_status()"  -->
                <button mat-raised-button color="primary" (click)="dataTransferClick()"
                  [disabled]="data_transfer_status()"
                  class="custom-button">
                  <mat-icon class="mr-1">swap_horiz</mat-icon> DATA TRANSFER
                </button>
                <button mat-raised-button color="primary" (click)="addAgentClick()" 
                  class="custom-button">
                  <mat-icon class="mr-1">person_add</mat-icon> ADD AGENT
                </button>
              </div>
            </mat-card-header>
            <div class="overflow-auto custom-scrollbar custom-table-container">
              <mat-table [dataSource]="dataSource" class="table table-striped table-bordered vkTableAgent">
                <ng-container matColumnDef="agentID">
                  <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Agent ID
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.id}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agentName">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Agent Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Email
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{limit_string(element.email)}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="currentCampaign">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Current
                  Campaign </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center"
                  matTooltip="{{element.active_campaign}}">
                  {{curCampainViewData(element.active_campaign, 20)}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="dataValue">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Data
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.data}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="dialled">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Dialled
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.dialled}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="contacted">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Contacted
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.contacted}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="followups">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Followups
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  {{element.followup}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="activeDeactive">
                <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center"> Active |
                  Deactive </mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center">
                  <mat-slide-toggle [(ngModel)]="element.active_status" (change)="setTogeleValue(element, $event)"
                    [disabled]="agent_switch_status()">
                  </mat-slide-toggle>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns" class="bg-light"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            </div>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="mt-3"></mat-paginator>
          </mat-card>
        </div>
      </div>

      <div class="row" *ngIf="check_log_visibility()">
        <mat-accordion>
          <mat-expansion-panel (opened)="campaign_logs.logspanelOpenState = true"
            (closed)="campaign_logs.logspanelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="text-primary fw-normal">
                Campaign Logs &nbsp;
                <i class="fas fa-sync-alt fa-spin" *ngIf="campaign_logs.refresh_status"></i>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex justify-content-end align-items-end">
              <button mat-stroked-button (click)="refresh_campaign_logs()" class="mb-3"
                [disabled]="campaign_logs.refresh_status">Refresh
                <mat-icon class="mr-1">refresh</mat-icon>
              </button>
            </div>

            <table mat-table [dataSource]="campaign_logs.data">
              <ng-container matColumnDef="name">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Name </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Email </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>
              <ng-container matColumnDef="activity_type">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Activity </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element.activity_type}} </td>
              </ng-container>
              <ng-container matColumnDef="created_at">
                <th class="text-center" mat-header-cell *matHeaderCellDef> Date & Time </th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{getLocalTime(element)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsLogs"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsLogs;"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-card>
  </div>
</mat-drawer-container>
