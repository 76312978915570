<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" [ngClass]="{'minimize': is_minimized}" mode="side" opened="true">
		<app-menu 
			[navigateLink]="currentTitle" 
			[is_minimized]="is_minimized"
			(is_minimizedChange)="onMinimizedChange($event)">
		</app-menu>
	</mat-drawer>

  <app-logout></app-logout>
  <router-outlet *ngIf="show_details"></router-outlet>
  <div class="row" style="background: #f6f6f6;">

    <div class="col-md-12">

      <div id="main_data" *ngIf="!show_details">

        <div class="row">
					<div class="col-md-12">
						<!-- <h4 class="titlehead"
							style="margin-top: 0%;padding-left: 10px;position: relative;top: -15px;z-index: 9;">Campaigns
							Dashboard</h4> -->
              <mat-card-title class="text-primary">Campaigns Dashboard</mat-card-title>
					</div>
				</div>

        <div class="row">
          <div class="col-md-3" style="padding-right: 0;">
            <mat-card class="card" appearance="outlined">
              <div style="color: var(--primary); position: sticky; top: 0; z-index: 1;">All Campaigns</div>
              <div class="card-body">
                <table class="Cam-table">
                  <tr>
                    <td><span>Data Assigned</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="summary.total.data_percent">
                      </mat-progress-bar>
                    </td>
                    <td class="tdData1"><span>{{ summary.total.data | number
												}} ({{get_decimal_val(summary.total.data_percent)}} %)</span></td>
                  </tr>
                  <tr>
                    <td><span>Data Dialled</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                        [value]="summary.total.to_call_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData2"><span> {{summary.total.to_call | number
												}} ({{get_decimal_val(summary.total.to_call_percent)}} %)</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span>Data Contacted</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                        [value]="summary.total.called_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData3"><span>
                        {{summary.total.called | number}} ({{get_decimal_val(summary.total.called_percent)}} %)</span>
                    </td>
                  </tr>
                </table>


                <!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1"
										[style.width.%]="summary.total.data_percent" /></div></td>
							<td><div class="show_count">{{summary.total.data_percent}}% <span class="value_count">({{ summary.total.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"
										[style.width.%]="summary.total.to_call_percent" /></div></td>
							<td><div class="show_count">{{summary.total.to_call_percent}}% <span class="value_count">({{ summary.total.to_call }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div><hr class="level3" [style.width.%]="summary.total.called_percent" /></div></td>
							<td><div class="show_count">{{summary.total.called_percent}}% <span class="value_count">({{ summary.total.called }})</span>	</div>
							</td>
						</tr>
					</table> -->
              </div>
            </mat-card>
          </div>
          <div class="col-md-3" style="padding-right: 0;">
            <mat-card class="card" appearance="outlined">
              <div style="color: var(--primary); position: sticky; top: 0; z-index: 1;">Active Campaigns</div>
              <div class="card-body">
                <table class="Cam-table">
                  <tr>
                    <td><span>Data Assigned</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                        [value]="summary.active.data_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData1"><span> {{
												summary.active.data | number }} ({{get_decimal_val(summary.active.data_percent)}} %)</span></td>
                  </tr>
                  <tr>
                    <td><span>Data Dialled</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                        [value]="summary.active.to_call_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData2"><span> {{
												summary.active.to_call | number }} ({{get_decimal_val(summary.active.to_call_percent)}} %)</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span>Data Contacted</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                        [value]="summary.active.called_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData3"><span>
                        {{summary.active.called | number}} ({{get_decimal_val(summary.active.called_percent)}} %)</span>
                    </td>
                  </tr>
                </table>



                <!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1" [style.width.%]="summary.active.data_percent" /></div></td>
							<td><div class="show_count">{{summary.active.data_percent}}% <span	class="value_count">({{ summary.active.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"	[style.width.%]="summary.active.to_call_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.active.to_call_percent}}% <span class="value_count">({{ summary.active.to_call }})</span>	</div>	</td>
						</tr>
						<tr><td><div class="show_bar">	<div>Data Contacted</div><hr class="level3"
										[style.width.%]="summary.active.called_percent" /></div></td>
							<td><div class="show_count">{{summary.active.called_percent}}% <span
										class="value_count">({{ summary.active.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
              </div>
            </mat-card>
          </div>
          <div class="col-md-3" style="padding-right: 0;">
            <mat-card class="card" appearance="outlined">
              <div style="color: var(--primary); position: sticky; top: 0; z-index: 1;">Inactive Campaigns</div>
              <div class="card-body">

                <table class="Cam-table">
                  <tr>
                    <td><span>Data Assigned</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                        [value]="summary.inactive.data_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData1"><span>
                        {{summary.inactive.data | number }} ({{get_decimal_val(summary.inactive.data_percent)}}
                        %)</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span>Data Dialled</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                        [value]="summary.inactive.to_call_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData2"><span> {{
												summary.inactive.to_call | number
												}} ({{get_decimal_val(summary.inactive.to_call_percent)}} %)</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span>Data Contacted</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                        [value]="summary.inactive.called_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData3"><span>
                        {{summary.inactive.called | number}} ({{get_decimal_val(summary.inactive.called_percent)}}
                        %)</span>
                    </td>
                  </tr>
                </table>






                <!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div>
									<hr class="level1"	[style.width.%]="summary.inactive.data_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.inactive.data_percent}}% <span
										class="value_count">({{ summary.inactive.data }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar">
									<div>Data Dialled</div><hr class="level2" [style.width.%]="summary.inactive.to_call_percent" /></div></td>
							<td><div class="show_count">	{{summary.inactive.to_call_percent}}% <span
										class="value_count">({{ summary.inactive.to_call }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div>	<hr class="level3"	[style.width.%]="summary.inactive.called_percent" /></div>	</td>
							<td><div class="show_count">
									{{summary.inactive.called_percent}}% <span
										class="value_count">({{ summary.inactive.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
              </div>
            </mat-card>
          </div>

          <div class="col-md-3" style="padding-right: 0;">
            <mat-card class="card" appearance="outlined">
              <div style="color: var(--primary); position: sticky; top: 0; z-index: 1;">Data Sources</div>
              <div class="card-body">

                <table class="Cam-table">
                  <tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
                    <td><span style="text-transform: capitalize;">{{ campaign }}</span>
                      <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                        [value]="summary.data_source[campaign].called_percent"></mat-progress-bar>
                    </td>
                    <td class="tdData1"><span>
                        {{summary.data_source[campaign].data | number }}
                        ({{get_decimal_val(summary.data_source[campaign].called_percent)}} %)</span>
                    </td>
                  </tr>
                </table>
              </div>
            </mat-card>
          </div>

          <!-- <div class="col-md-3">
						<div class="card">
							<div class="card-header">Data Sources</div>
							<div class="card-body scroll">
								<table class="tablecs">
									<thead>
										<tr>
											<th>Source</th>
											<th>Data</th>
											<th>Utilized</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
											<td class="data_source_head"><span>{{ campaign }}</span></td>
											<td class="data_source_data">
												{{ summary.data_source[campaign].data | number}}</td>
											<td class="numeric_threshold">
												{{ summary.data_source[campaign].called_percent }} %</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div> -->
        </div>


        <!-- <br> -->
        <mat-card appearance="outlined">
          <div class="row">
            <div class="col-md-12">
              <!-- Header section with fixed position -->
              <div class="position-sticky top-0" style="z-index: 2;">
                <div class="pDiv1">
                  <div class="div_inline_items">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <span *ngIf="showCampaign" class="text-primary">
                          Campaigns Summary
                        </span>
                        <span class="datecs ms-3">{{ today }}</span>
                        <mat-form-field appearance="outline" [ngClass]="'mat-form-responsive'" class="mt-1 ms-3">
                          <mat-label>Select Month</mat-label>
                          <mat-select [value]="selected_month.value" (selectionChange)="change_selectedMonth($event)">
                            <mat-option *ngFor="let month of months_filter" [value]="month.value">
                              {{month.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="d-flex align-items-center">
                        <button class="btn btn-primary me-2" mat-raised-button (click)="refreshCampaignData()">
                          <mat-icon>arrow_right_alt</mat-icon> GO
                        </button>
                        <button class="btn btn-primary me-2" mat-raised-button (click)="openCreateCampaign()" [disabled]="isCampbtnDisable" *ngIf="showCampaign">
                          <mat-icon>add</mat-icon> CREATE CAMPAIGN
                        </button>
                        <button class="btn btn-primary" mat-raised-button (click)="downloadAttachment()" *ngIf="showCampaign">
                          <mat-icon>download</mat-icon> DOWNLOAD
                        </button>
                      </div>
                    </div>

                    <span *ngIf="is_custom_date" class="custom_date_range">
                      <div class="date-range-picker">
                        <mat-form-field appearance="outline" [ngClass]="'selectOutlineCss'" style="margin-right: 20px;">
                          <mat-label>Start Date</mat-label>
                          <input matInput [matDatepicker]="startPicker" [(ngModel)]="custom_startDate"
                            (dateChange)="onCustomStartDateChange($event)" />
                          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                          <mat-datepicker #startPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" [ngClass]="'selectOutlineCss'">
                          <mat-label>End Date</mat-label>
                          <input matInput [matDatepicker]="endPicker" [(ngModel)]="custom_endDate"
                            [min]="custom_startDate" [max]="custom_maxEndDate"
                            (dateChange)="onCustomEndDateChange($event)" />
                          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                          <mat-datepicker #endPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </span>
                  <div>
                    <div class="pDiv1 bulk_select_sec" *ngIf="check_visibility_bulk_div">
                      <div>
                        <button mat-stroked-button (click)="bulk_camapign_btn_click()">
                          SELECT CAMPAIGNS
                          <mat-icon class="ml-auto">keyboard_arrow_down</mat-icon>
                        </button>
                        <div class="searchSpan">
                          <mat-form-field class="search-inputbox w-400 mt-1" appearance="outline">
                            <mat-label>Search</mat-label>
                            <input matInput #_searchValue (keyup.enter)="applyFilter($event.target.value)"
                            placeholder="Search">
                          <mat-icon matSuffix (click)="applyFilter(_searchValue.value)" class="searchicon fs-3">search
                          </mat-icon>
                        </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <!-- <div> -->
                    <div class="pDiv1 bulk_select_sec">
                      <div class="bulk_select_banner" *ngIf="show_bulk_selection_banner" style="margin-top: 5%;">
                        <div class="sub_banner">
                          <div class="checkbox_selector">
                            <mat-checkbox class="bulk_select_checkbox" [checked]="bulk_all_campaigns_selected" color="primary"
                              [indeterminate]="bulk_partial_camapigns_selected()"
                              [disabled]="bulk_all_campaigns_check_disabled()"
                              (change)="bulk_campaigns_select_all($event.checked)">
                              <span *ngIf="!count_campaigns_selected()">Select All To</span>
                              <span
                                *ngIf="count_campaigns_selected() && !bulk_assign_all_selected()">{{ count_campaigns_selected() }}
                                Campaigns Selected To</span>
                              <span *ngIf="bulk_assign_all_selected()">All Campaigns Selected To</span>
                            </mat-checkbox>
                          </div>

                          <div class="toggle_slider">
                            <mat-slide-toggle [(ngModel)]="bulk_camapigns_switch"
                            
                            (change)="reset_bulk_selection_controls()">
                            </mat-slide-toggle>
                            Turn <span>{{bulk_camapigns_switch ? "ON" : "OFF"}} the Campaigns</span>
                          </div>
                          <!-- <button mat-raised-button (click)="send_bulk_action_campaigns()"
                            [disabled]="!bulk_all_campaigns_selected && !bulk_partial_camapigns_selected()"
                            class="action-button">
                            <mat-icon>arrow_right_alt</mat-icon>
                            <span style="margin-left: 10px;">GO</span>
                          </button> -->
                          <button mat-raised-button (click)="send_bulk_action_campaigns()"
                          [disabled]="!bulk_all_campaigns_selected && !bulk_partial_camapigns_selected()"
                          >
                            <mat-icon>arrow_right_alt</mat-icon>
                            <span >GO</span>
                          </button>
                          <mat-icon class="mat-icon-class" (click)="close_bulk_camapign_selection_banner()">cancel</mat-icon>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                    <!-- </div> -->
                 
                    <span class="searchSpan">
                    
                    </span>
                  </div>
                  </div>
                </div>
              </div>
              <!-- <br> -->
            
              <br>
            </div>

            <!-- Table section with adjusted height -->
            <div class="pDiv1">
              <div class="row">
                <div class="col-12">
                  <!-- Table container with fixed height to enable scrolling -->
                  <div class="overflow-auto custom-scrollbar custom-table-container"> <!-- Set your desired height here -->
                    <mat-table [dataSource]="dataSourceCampaign" class="cTable w-100">
                      <!-- Bulk Select Column -->
                      <ng-container matColumnDef="bulk_selected">
                        <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                          Select </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                          <mat-checkbox class="camapign_select_box" color="primary" [checked]="element.bulk_selected"
                            (change)="bulk_camapign_selected(element)" [disabled]="check_bulk_campaign_disabled(element)">
                          </mat-checkbox>
                        </mat-cell>
                      </ng-container>

                      <!-- SL No Column -->
                      <ng-container matColumnDef="sl_no">
                        <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                          SL.No </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                          {{element.sl_no}} </mat-cell>
                        </ng-container>

                        <!-- Campaign Name Column -->
                        <ng-container matColumnDef="campaign_name">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-start align-items-center col-3">
                            Name 
                          </mat-header-cell>
                          <mat-cell *matCellDef="let element" 
                            class="d-flex justify-content-start align-items-center col-3 text-truncate"
                            (click)="actionViewMore(element)" 
                            [matTooltip]="element.campaign_name"
                            [matTooltipPosition]="'right'">
                            <span style="word-break: break-word; white-space: normal;">{{element.campaign_name}}</span>
                          </mat-cell>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="actionViewMore">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Action </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            <button mat-button class="btn btn-link" (click)="actionViewMore(element)">VIEW MORE</button>
                          </mat-cell>
                        </ng-container>

                        <!-- Campaign Owner Column -->
                        <ng-container matColumnDef="campaign_owner">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Owner </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            {{element.campaign_owner}} </mat-cell>
                          </ng-container>

                        <!-- Work Type Column -->
                        <ng-container matColumnDef="work_type">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Work Type </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            {{element.work_type}} </mat-cell>
                          </ng-container>

                        <!-- Campaign Status Column -->
                        <ng-container matColumnDef="campaign_status">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Status </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            <mat-slide-toggle color="primary" *ngIf="check_val_type(element.campaign_status) == 'boolean'"
                              [(ngModel)]="element.campaign_status" (change)="campaignStatusChange($event, element)"
                              [disabled]="campaign_service.disable_campaign_full_access(element)"
                              >
                            </mat-slide-toggle>
                            <button mat-stroked-button color="primary" class="btn btn-link"
                              *ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'uploaded')"
                              (click)="AssignNow(element)">
                              ASSIGN
                            </button>
                            <span
                              *ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'assigning')"
                              class="text-success text-uppercase">{{element.campaign_status}}</span>
                          </mat-cell>
                        </ng-container>

                        <!-- Campaign Created Column -->
                        <ng-container matColumnDef="campaign_created">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Created </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            {{element.campaign_created}} </mat-cell>
                          </ng-container>

                        <!-- Last Updated Column -->
                        <ng-container matColumnDef="last_updated">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Last Refreshed </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            {{element.last_updated}} </mat-cell>
                          </ng-container>

                        <!-- Data Uploaded Column -->
                        <ng-container matColumnDef="data_uploaded">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Uploaded </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.data_uploaded | number}} </mat-cell>
                          </ng-container>

                        <!-- Data Duplicate Column -->
                        <ng-container matColumnDef="data_duplicate">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Duplicate </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.data_duplicate | number}} </mat-cell>
                          </ng-container>

                        <!-- Errors Column -->
                        <ng-container matColumnDef="errors">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Errors </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.errors | number}} </mat-cell>
                          </ng-container>

                        <!-- Agents Count Column -->
                        <ng-container matColumnDef="agents_count">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Agents </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.agents_count | number}} </mat-cell>
                          </ng-container>

                        <!-- Data Added Column -->
                        <ng-container matColumnDef="data_added">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Assigned </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.data_added | number}} </mat-cell>
                          </ng-container>

                        <!-- Data Dialled Column -->
                        <ng-container matColumnDef="data_dialled">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Dialled </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.data_dialled | number}} </mat-cell>
                          </ng-container>

                        <!-- Contacted Column -->
                        <ng-container matColumnDef="contacted">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Contacted </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.contacted | number}} </mat-cell>
                          </ng-container>

                        <!-- Rechurn Column -->
                        <ng-container matColumnDef="rechurn">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-1">
                            Rechurn </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-1">
                            {{element.rechurn | number}} </mat-cell>
                          </ng-container>

                        <!-- Reports Column -->
                        <ng-container matColumnDef="downloadReports">
                          <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center align-items-center col-2">
                            Reports </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center col-2">
                            <button mat-button class="no-hover" [matMenuTriggerFor]="menu" [disabled]="element.showLoader">
                              REPORTS&nbsp;
                              <i class="fa fa-angle-down" *ngIf="!element.showLoader"></i>
                              <i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item matTooltip="Call Status Report"
                                (click)="downloadCallStatusReportPost(element)">Call Status Report</button>
                                <button mat-menu-item matTooltip="Campaign Status Report (My Agents)"
                                (click)="downloadCampaignStatusReport(element, true)"
                                *ngIf="visibility_my_agents_report(element)">Campaign Status
                                Report (My Agents)</button>
                              <button mat-menu-item
                                [matTooltip]="getTextMerge(element.master_campaign, 'Master Cmpaign Status Report (My Agents)')"
                                (click)="downloadCampaignStatusReportMasterMYagents(element, true)"
                                *ngIf="element.master_campaign && visibility_my_agents_report(element)">{{element.master_campaign}}
                                - Master Campaign Status Report (My Agents)</button>
                              <button mat-menu-item
                                (click)="downloadCallStatusReportMasterMyagents(element)"
                                [matTooltip]="getTextMerge(element.master_campaign, 'Master Call Status Report (My Agents)')"
                                *ngIf="element.master_campaign && visibility_my_agents_report(element)">{{element.master_campaign}}
                                - Master Call Status Report (My Agents)</button>
                              <button mat-menu-item matTooltip="Campaign Status Report"
                                [disabled]="campaign_service.disable_campaign_full_access(element)"
                                (click)="downloadCampaignStatusReport(element)">Campaign Status
                                Report</button>
                              <button mat-menu-item *ngIf="element.master_campaign"
                                [disabled]="campaign_service.disable_campaign_full_access(element)"
                                (click)="downloadCampaignStatusReportMaster(element)">{{element.master_campaign}}
                                - Master Campaign Status
                                Report</button>
                              <button mat-menu-item *ngIf="element.master_campaign"
                                [matTooltip]="getTextMerge(element.master_campaign, 'Master Call Status Report')"
                                [disabled]="campaign_service.disable_campaign_full_access(element)"
                                (click)="downloadCallStatusReportMaster(element)">{{element.master_campaign}}
                                - Master Call Status
                                Report</button>
                              <!-- <button mat-menu-iterm *ngIf="element.data_source && (element.data_source !='others')" (click)="downloadMasterCampaignStatusreport(element)">{{element.data_source}} - Master Campaign Status Report</button>
                      <button mat-menu-iterm *ngIf="element.data_source && (element.data_source !='others')" (click)="downloadMasterCampaigncallStatusreport(element)">{{element.data_source}} - Master Campaign Call Status Report</button> -->
                              <button mat-menu-item *ngIf="false"
                                (click)="downloadDataSheet(element)">Data Sheet</button>
      
                            </mat-menu>
                          </mat-cell>
                        </ng-container>

                        <!-- Header and Row Definitions -->
                        <mat-header-row *matHeaderRowDef="displayedColumnsCampaigan"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsCampaigan"></mat-row>
                      <!-- </ng-container> -->
                    </mat-table>
                  </div>

                  <!-- Removed paginator -->
                  <div class="position-sticky bottom-0 bg-white" style="z-index: 2;">
                    <mat-paginator #campaignpaginator 
                      [pageSizeOptions]="[10, 20, 50, 100]" 
                      showFirstLastButtons
                      (page)="get_details_by_campaigns_loaded($event)">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div id="cover-spin" *ngIf="showLoader"></div>
</mat-drawer-container>
