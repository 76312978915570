<div style="margin-left: 5%;margin-right: 2%;">
    <div class="row">
        <div class="col-md-12 col-sm-7 col-xs-12">        
            <h3 class="d-head"> Adding More Data to the campaign</h3>
            <h4 *ngIf="showHidePassingMessage" [ngStyle]="{'color':_color}" style="font-weight: bold;font-size: 20px;">{{passingMessage}}</h4>
          </div>
    </div>
    <form [formGroup]="createdForm">
        <div class="row pdt" >
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Campaign Name</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{this.obj.campaign}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Pushed Date</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{this.obj.pushed_date| date :'dd/MM/yy'}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total Leads</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj1['Total Leads'] }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total Pushed</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj1['Total Pushed'] }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total DND</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj1['Total DND'] }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total Errors</label>
                    </div>
                    <div class="col-md-6"  >
                        <label> {{ this.obj1['Total Errors'] }}</label>
                    </div>
                </div>

            </div>

            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total Reports Drops</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj1['Total Repeat Drops'] }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Total WCP Dropped</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj1['Total WCP Dropped'] }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"  >
                        <label>Incall Screen</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj.incallscreen }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" >
                        <label>Dial mode</label>
                    </div>
                    <div class="col-md-6"  >
                        <label>{{ this.obj.dialmode }}</label>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6" >
                        <label>Add More Data</label>
                    </div>
                    <div class="col-md-6"  >
                       <!--label><a href='{{this.obj.url}}'><i class="fa fa-file-excel-o" aria-hidden="true"></i></a></label>-->
						<button mat-raised-button (click)="download_file()">Download_Excel
							<i class="fa fa-file-excel" aria-hidden="true"></i>
						</button>
                    </div>
                </div>
            </div>

          <div>
            <div class="upload-btn-wrapper" style="margin-left: 15px;">
                <button class="btnbrowse">Browse</button>
             <input type="file" #fileUpload class="filecs" id="fileID" formControlName="file" accept=".xlsx" (change)="changeFile($event)"/>
             <span style="color: #bababa;padding-left: 10px;">{{currentFileobj.name}}</span>
              </div>
          </div>
  
        </div>
        
        <div class="row pdt" >      
                <div class="col-md-12 col-sm-12 col-xs-12 rightside">
                    <button mat-raised-button  (click)="closeDialog('')">Cancel</button>&nbsp;&nbsp;&nbsp;

                    <button mat-raised-button (click)="saveButton(createdForm)" >Save</button>
                </div>
            
        </div>
        
        </form>
      </div>
      <div id="cover-spin" *ngIf="currentLoader"></div>