import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../.../../../../api.service';
import * as moment from 'moment';
import {MyserviceService} from '../../../myservice.service'
@Component({
  selector: 'app-feedback-condidates-dialog',
  templateUrl: './feedback-condidates-dialog.component.html',
  styleUrls: ['./feedback-condidates-dialog.component.scss']
})
export class FeedbackCondidatesDialogComponent implements OnInit {
  createdForm:UntypedFormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  api_end_point:any;
  constructor(public dialogRef: MatDialogRef<FeedbackCondidatesDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData,public formbuilder:UntypedFormBuilder,private API: ApiService,private _service:MyserviceService) {

    this.createdForm=this.formbuilder.group({
      candidateName:['',Validators.required],
      recruiterName:[''],
      dobDate:[''],
      dateOne:[''],
      process:[''],
      source:[''],
      voiceNonvoiceRadio:[''],
      voiceNonvoiceComment:[''],
      shiftRadio:[''],
      shiftComment:[''],
      wklyOff:[''],
      wklyOffComment:[''],
      workLocation:[''],
      workLocationComments:[''],
      typing:[''],
      typingComments:[''],
      speakersays:[''],
      speakerListner:[''],
      sentence:[''],
      thought:[''],
      promotResponse:[''],
      voiceQuality:[''],
      confidence:[''],
      listeningSkills:[''],
      attitude:[''],
      writtenSkills:[''],
      candidateMove:[''],
      additionalComments:[''],
      strength:[''],
      areaImprovement:[''],
    })
  }
  ngOnInit(){
	this.api_end_point = this._service.get_API_End_Point();
   // console.log(this.viewData)
    if(this.viewData.feedback_form){
        var values = JSON.parse(this.viewData.feedback_form);
       // console.log("values",values);
        this.createdForm.patchValue(values);
        setTimeout(() => this.createdForm.disable(), 500);

    }
    this.createdForm.controls.candidateName.setValue(this.viewData.name);
    this.createdForm.controls.recruiterName.setValue(this.viewData.recruiter_email);
    this.createdForm.controls.dobDate.setValue(this.viewData.dob);
    this.createdForm.controls.dateOne.setValue(new Date());
    
  }
  closeDialog(){
    this.dialogRef.close();
  }

  saveFeedback(createdForm:any){
   // console.log(createdForm.value)
    this.messageDisplay="";
    this.msgColor = "";
    this.showHideMessage = false;

// if(_candidateName==""||_candidateName==null){
//   this.messageDisplay = "Please Enter Candidate Name";
//   this.msgColor = "red";
//   this.showHideMessage = true;
//   window.scroll({
//     top: 40,
//     left: 0,
//     behavior: 'smooth'
//   });
//   return;
// }
// if(_recruiterName==""||_recruiterName==null){
//   this.messageDisplay = "Please Enter Recruiter Name";
//   this.msgColor = "red";
//   this.showHideMessage = true;
//   window.scroll({
//     top: 40,
//     left: 0,
//     behavior: 'smooth'
//   });
//   return;
// }
  
    var obj = {
			id: this.viewData.user_id,
			feedback_form: createdForm.value
      
		}
		this.API.updateFeedbackForm(obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
        this.messageDisplay = "Saved Successfully.!!";
        this.msgColor = "green";
        this.showHideMessage = true;
        this.createdForm.reset();
        setTimeout(()=>{
        
          this.messageDisplay = "";
          this.msgColor = "";
          this.showHideMessage = false;
          this.closeDialog();
        },4000)
			}
		});

  }
  cancelClear(){
    this.createdForm.reset();
  }
}


