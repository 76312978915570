import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { UntypedFormGroup, Validators, FormControl, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as Excel from 'exceljs';
import * as XLSX from 'xlsx';

import { MyserviceService } from '../../../myservice.service';
import { ServicesService } from '../../services.service';
import { ApiService } from '../../../api.service';

import { Utils } from '../../../utils';


interface agencyList{
	name: string,
	value: string
}

export function timeValidator(control: AbstractControl): { [key: string]: any; } | null  {
	var hr = moment().format('HH');
	var min = moment().format('mm');
	// debugger;
	if (control.value) {
		var [c_hr, c_min] = control.value.split(':');
		// debugger;
		if (parseInt(c_hr) >= parseInt(hr)) {
			if (parseInt(c_min) >= parseInt(min)) {
				// debugger;
				return;
			}
		}
		// debugger;
		return {'timeError': 'Invalid time. Choose curent time of future time'};
	}
}
  

@Component({
  selector: 'app-campaign-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.scss']
})
export class CampaignDialogComponent implements OnInit {
	bulkUploadFile = [];
	utils = new Utils();
	showLoader = false;
	allow_repush: boolean = false;
	currentFile: any;
	currentFileobj: any = {};
	current_time = moment().format('HH:mm');
	campaignUpload_msg: string = '';
	campaignType: string = 'online_agency';
	myUser: any;
	agenciesList: agencyList[] = [
		{ 'name': 'Vahan', 'value': 'Vahan' },
		{ 'name': 'Workex', 'value': 'Workex' },
		{ 'name': 'Capricco', 'value': 'Capricco' },
		{ 'name': 'CII', 'value': 'CII' },
		{ 'name': 'Aspiring Minds', 'value': 'Aspiring Minds' },
		{ 'name': 'Others', 'value': 'Others' }
	]
	campaignForm: UntypedFormGroup;
	
	email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	num_pattern = /^(\+91)?(91)?(\d{10})$/;
	name_pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
	api_end_point:any
	constructor(
		private API: ApiService,
		public dialogRef: MatDialogRef<CampaignDialogComponent>,
		private service: MyserviceService,
		private dashService: ServicesService,
		private formbuilder: UntypedFormBuilder
	) { }

	ngOnInit() {
		this.api_end_point = this.service.get_API_End_Point();
		this.campaignForm = this.formbuilder.group({
			"name": ['', Validators.required],
			"date": [new Date(), Validators.required],
			"time": [this.current_time, [Validators.required, timeValidator]],
			"agency": [this.agenciesList[0], Validators.required],
			"category": ['', Validators.required],
			"priority": [1, Validators.required],
			"file": ['', Validators.required],
			"fileSource": ['', Validators.required],
			"reset_push": [this.allow_repush]
		})
		this.myUser = this.service.get_loggedin_user();
		this.campaignUpload_msg = '';
		// debugger;
	}
	get _form() { return this.campaignForm.controls }
	hidePreviousDates = (d: Date | null): boolean => {
		const day = (d || new Date());
		// console.log(day);
		return moment(day).isSameOrAfter(new Date(), 'day');
	  }
	download_file(){
		this.API.bulkTemplateAPI(this.api_end_point).subscribe(
			(res:HttpResponse<any>) => {
				// debugger;
				// console.log('printing res', res);
				let content_type = res.headers.get("content-type");
				let file_data = res.headers.get("content-disposition");
				let p = file_data.split(';')[1];
				let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
				let body = res.body;
				let blob: any = new Blob([body], { type: content_type });
				let url = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.download = file_name;
				anchor.href = url;
				// debugger;
				anchor.click();
			},
			(err) => {
				// debugger;
			//	console.log('printing errors',err)
				alert('Error in downloading the file')
			}
		)
	}

	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.campaignForm.patchValue({
				fileSource: upload_file
			});
		  }
	}

	submit_Camapign(campaignForm: UntypedFormGroup) {
		this.campaignUpload_msg = 'File Data checking started';
		this.validateExcelSheet().then((resolve) => {
			// console.log('Resolved');
			this.campaignUpload_msg = 'File Data checking completed';
			this.campaignUpload_msg = 'Start uploading ...';
			let campaign = campaignForm;
			let agent_list = {};
				agent_list[this.myUser.groupid] = this.service.getAllAgentsByLevel('Level 1');
			let campaign_name = campaign.controls.name.value;
			let date = moment(campaign.controls.date.value).format('YYYY-MM-DD');
			let time = String(campaign.controls.time.value + ':00');
			let full_time = String(date).concat('T', time.toString()).toString();
			let bulk_file = campaign.get('fileSource').value;
			let master_campaign = campaign.get('category').value;
			let agency = campaign.controls.agency.value;
			let priority = campaign.controls.priority.value;
			// debugger;
			let agent_listdata = this.service.getAllAgentsByLevel('Level 1');
			//console.log("--agent list data-",agent_listdata);

			if (master_campaign == 'offline_agency') {
				agent_list[this.myUser.groupid] = this.service.getAllAgentsByLevel('Level 2');
			}

			if (master_campaign == 'online_agency') {
				agent_list[this.myUser.groupid] = this.service.getAllAgentsByLevel('Level 1');
			}


				// agent list lenght available if not alert 	
			let formData = new FormData();
			// formData.append('user_id', this.myUser.id);
			formData.append('campaign_name', campaign_name + this.utils.getUniqueCodeFromTime());
			formData.append('pushed_date', full_time);
			formData.append('master_campaign', master_campaign);
			formData.append('agents_list', JSON.stringify(agent_list));
			// debugger;
			formData.append('dial_ratio', '2');
			formData.append('priority', priority);
			// debugger;
			formData.append('campaign_category', 'None');
			// debugger;
			formData.append('leads', bulk_file, bulk_file.name);
			formData.append('others', agency.value);
			formData.append('campaign_stat', this.allow_repush.toString());

		//	console.log("----",agent_listdata);
			this.API.bulkUploadAPI(formData,this.api_end_point).subscribe(
				(res:any) => {
					// debugger;
					this.campaignUpload_msg = 'Camapign Upload Finished.'
					// console.log(res)
					let uploaded_file = res.url.split('/');
					let file_name = uploaded_file.pop();

					let obj = {
						"blobfile": file_name,
						"start_date": full_time,
						"maxleadsperday": 500,
						"options": "Drop repeated data",
						"campaign_name": campaign_name,
						"priority": priority,
						"dial_ratio": 2,
						"campaign_category": "None",
						"master_campaign": master_campaign,
						"campaign_stat": this.allow_repush,
						"groupedagentslist": agent_list
					}
					if (this.allow_repush) {
						obj.options = 'Allow repeated data';
					}
					this.campaignUpload_msg = 'Start Assigning the data';
					this.API.bulkPushAPI(obj,this.api_end_point).subscribe(
						(res) => {
							// console.log('printnig res', res);
							// alert('Camapign Uploaded successfully.');
							this.campaignUpload_msg = 'Refreshing the campaign data';
							//this.closePopup();
							this.campaignForm.reset();
							this.dialogRef.close('true');
							this.showLoader = true;
							this.dashService.setMyCampaigns().then(
								() => {
									alert('Campaign data refreshed');
									this.showLoader = false;
								}
							)
						}, (err) => {
							// debugger;
							console.log('err', err)
							this.showLoader = false;
						}
					)
				}, (err) => {
					// debugger;
					console.log(err)
				}
			)			
		}, (reject) => {
				this.campaignUpload_msg = reject;
			//	console.log('Validation reject', reject)
		})
	}

	validateExcelSheet() {
		let promise = new Promise((resolve, reject) => {
			// console.log(this.currentFileobj);
			let file_path = this.currentFileobj;
			let mime_type = file_path.name.split('.').pop().toLowerCase();
			if (mime_type != 'xlsx') {
				reject('File format is not correct. Use xlsx file');
				return;
			}
			if (!file_path.name) {
				reject('File not found for validations');
				return;
			}
			// debugger;
			const reader: FileReader = new FileReader();
			reader.readAsBinaryString(file_path);
			reader.onload = (e: any) => {
				// debugger;
				/* create workbook */
				const binarystr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
				// debugger;
				/* selected the first sheet */
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];
				// debugger;
				/* save data */
				const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
				// console.log(data); // Data will be logged in array format containing objects
				if (data && data.length) {
					// console.log('data to check', data.length);
					let mandatory_keys = ['ASPIRANT NAME (Compulsory)', 'ASPIRANT NUMBER(Compulsory)', 'EMAIL(Compulsory)'];
					let error = null;
					for (var i = 0; i < data.length && !error; i++) {
						let loc = data[i];
						let loc_line = i + 1;
						for (var k in mandatory_keys) {
							if (!loc.hasOwnProperty(mandatory_keys[k])) {
								error = mandatory_keys[k] + " not found at line number " + loc_line;
								break;
							}
							if (typeof loc[mandatory_keys[k]] != 'string') {
								loc[mandatory_keys[k]] = JSON.stringify(loc[mandatory_keys[k]]);
							}
							loc[mandatory_keys[k]] = loc[mandatory_keys[k]].trim();
							switch (mandatory_keys[k]) {
								case mandatory_keys[0]:
									// Looking for name
									if (!loc[mandatory_keys[k]].length) {
										error = "Name not found at line number " + loc_line;
										break;
									}
									if (this.name_pattern.test(loc[mandatory_keys[0]])) {
										error = "Name is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[1]:
									if(!this.num_pattern.exec(loc[mandatory_keys[1]])){
										error = "Number is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[2]:
									if (!this.email_regex.exec(loc[mandatory_keys[2]])) {
										error = "Email is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
							}
						}
					}
					if (error) {
						reject(error);
						return;
					}
					resolve(null);
				}
			};
			// debugger;
		})
		return promise;
	}

	closePopup() {
		this.campaignForm.reset();
		this.dialogRef.close();
	}
	omit_special_char(event)
	{   
	   let k;  
	   k = event.charCode;  // k = event.keyCode; (Both can be used)
	   if(k==95){
		return true;
	}
	   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
	}

}
