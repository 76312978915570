import { Component, OnInit } from '@angular/core';
import { CallFormPreviewDialogComponent } from '../call-form-preview-dialog/call-form-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';import { CallDuringFormSummaryDialogComponent } from '../call-during-form-summary-dialog/call-during-form-summary-dialog.component';
import { MyserviceService } from '../../myservice.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-call-form-create',
  templateUrl: './call-form-create.component.html',
  styleUrls: ['./call-form-create.component.scss']
})
export class CallFormCreateComponent implements OnInit {
  Untitledform:any="Title"
  callFlowJson: any;
  callFlowJsonSections: any[] = [];
  first: number;
  second: number;
  AddRowList: callFlow[];
  AddRowPersonalDetailsList: callFlow[];
  showHideUntitled: boolean = true;
  showHidePersonalDetails: boolean = false;
  showHideHeadingPersonal: boolean = true;
  showHideHeading: boolean = false;
  innerRadioSections: any[]= [];
  currentRadioAction: any;
  selectedLevel: number = 2;
  currentLevel: number = 1;
  currentRadioIndex: number = 0;
  currentChildIndex: number = 0;
  parentAdd = true;
  constructor(private dialog: MatDialog, private service: MyserviceService) {
    this.AddRowList = [];
    this.addMore();
    this.AddRowPersonalDetailsList = [];
  }

  ngOnInit() {
    
  }
  generateJson(){
   // console.log(this.callFlowJsonSections)
    this.callFlowJson = JSON.stringify(this.callFlowJsonSections)
    this.service.saveCallForm(this.callFlowJsonSections);
  }
  addMore() {
    this.callFlowJsonSections.push({title: "", description:"", mandatory: "n", child: [] });
    //console.log(this.callFlowJsonSections)
  }
  removeMore(index: number) {
    if (index == 0) {
      alert("You can't delete first one..!");
      return;
    }
    else {
      this.callFlowJsonSections.splice(index, 1);
    }
  }
  addUntitled(index) {
    this.showHideUntitled = false;
    this.showHidePersonalDetails = true;
    
    
    // this.callFlowJsonSections[index]['q'] = this.callFlowJsonSections[index]['q'] ||  "";
    // this.callFlowJsonSections[index]['at'] = this.callFlowJsonSections[index]['at'] || "";
    // this.callFlowJsonSections[index]['mandatory'] = this.callFlowJsonSections[index]['mandatory'] || "n";
    if(this.currentLevel == 1){
      var childIndex = this.callFlowJsonSections[index]['child'].length;
      var obj = {
          q: this.callFlowJsonSections[index]['q'] ||  "",
          at: this.callFlowJsonSections[index]['at'] || "",
          mandatory: this.callFlowJsonSections[index]['mandatory'] || "n"
      }
      if(this.parentAdd == false && this.callFlowJsonSections[index]['child'].length>0 && this.callFlowJsonSections[index]['child'][childIndex - 1].rbShow){
        if(!this.callFlowJsonSections[index]['child'][childIndex - 1][this.currentRadioAction]){
          this.callFlowJsonSections[index]['child'][childIndex - 1][this.currentRadioAction] = []
        }
        this.callFlowJsonSections[index]['child'][childIndex - 1][this.currentRadioAction].push({title:"", q:"",at:"", mandatory: "n"})
        
      }else{
        this.callFlowJsonSections[index]['child'].push(obj)
        //this.callFlowJsonSections[index]['child'][childIndex].push()
      }
    }
    if(this.currentLevel == 2){
      var childIndex = this.callFlowJsonSections[index]['child'][this.currentChildIndex][this.currentRadioAction][this.currentRadioIndex][this.currentRadioAction].length;
      var obj = {
        q: this.callFlowJsonSections[index]['child'][this.currentChildIndex][this.currentRadioAction][this.currentRadioIndex][this.currentRadioAction]['q'] ||  "",
        at: this.callFlowJsonSections[index]['child'][this.currentChildIndex][this.currentRadioAction][this.currentRadioIndex][this.currentRadioAction]['at'] || "",
        mandatory: this.callFlowJsonSections[index]['child'][this.currentChildIndex][this.currentRadioAction][this.currentRadioIndex][this.currentRadioAction]['mandatory'] || "n"
       }
       this.callFlowJsonSections[index]['child'][this.currentChildIndex][this.currentRadioAction][this.currentRadioIndex][this.currentRadioAction].push(obj)
    }
    
    //console.log(this.callFlowJsonSections)
  }
  removedUntitled(index, childIndex, level, index2) {
    //console.log(index, childIndex)
    this.showHideUntitled = false;
    this.showHidePersonalDetails = false;
    this.callFlowJsonSections[index]['child'].splice(childIndex, 1);
    // this.callFlowJsonSections[index] = {title: "", description:""};
    // this.callFlowJsonSections[index]['q'] = undefined;
    // this.callFlowJsonSections[index]['at'] = "";
    // this.callFlowJsonSections[index]['mandatory'] = "n";

  }
  removedSubUntitled(index,childIndex,level, radioIndex) {
    //console.log(index,childIndex, level,radioIndex);
    if(level == 1){
      for(const key in this.callFlowJsonSections[index]){
        if(key != 'title' && key != 'description' && key != 'mandatory' && key != 'q' && key != 'at' && !key.includes('data') && !key.includes('Show')){
          delete this.callFlowJsonSections[index]['child'][childIndex][key]; 
        }
      }
    }
    if(level == 2){
      this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction].splice(radioIndex, 1); 
    }
    
  }
  updateDropDown(data, index, level, index2, childIndex){
    //console.log(data, index, level,this.currentRadioAction);
    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['dd_data'] = data;
    }
    if(level == 2){
      this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['dd_data'] = data;
    }
    
  }
  updateNP(data: any, index, level, index2, childIndex){
    //console.log(data, index, level,this.currentRadioAction);
    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['min_value'] = data.min_value;
      this.callFlowJsonSections[index]['child'][childIndex]['max_value'] = data.max_value;
    }
    if(level == 2){
      this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['min_value'] = data.min_value;
      this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['max_value'] = data.max_value;
    }
    
  }
    updateSDDropDown(data, index, level, index2, childIndex){
   // console.log(data);
    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['sdd_data'] = data;
    }
    if(level == 2){
     this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['sdd_data'] = data;
    }
  }
  updateMSDDropDown(data, index, level, index2, childIndex){
    //console.log(data);
    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['msdd_data'] = data;
    }
    if(level == 2){
     this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['msdd_data'] = data;
    }
  }
  updateRadio(data, index, level, index2, childIndex, selectedLevel){
   // console.log(data, index, level,this.currentRadioAction);

    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['rb_data'] = data;
    }
    if(level == 2){
     this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][index2]['rb_data'] = data;
    }
  }
  triggnerInnerRadio(value, index, childIndex,selectedLevel, radioIndex){
    // console.log(value,selectedLevel);
    // console.log(this.callFlowJsonSections[index]['child'][childIndex][value]);
    if(selectedLevel == 1){
      this.parentAdd = false;
        this.callFlowJsonSections[index]['child'][childIndex]['rbShow'] = true;
      if(this.callFlowJsonSections[index]['child'][childIndex][value] && this.callFlowJsonSections[index]['child'][childIndex][value].length > 0){

      }else{
        this.callFlowJsonSections[index]['child'][childIndex][value] = [];
      }
    }
    if(selectedLevel == 2){
      this.parentAdd = false;
      this.currentLevel = 2;
      this.currentRadioIndex = radioIndex;
      this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][radioIndex]['rbShow'] = true;
      if(this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][value] && this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][value].length > 0){

      }else{
        this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][radioIndex][value] = [];
      }
    }
    
    this.currentRadioAction = value;
  }

  exitToMain(index){
    this.parentAdd = true;
  }
  updateButton(data, index, level, childIndex){
   // console.log(data, index, level,this.currentRadioAction);
    if(level == 1){
      this.callFlowJsonSections[index]['child'][childIndex]['bt_data'] = data;
    }    
  }
  updateCheckbox(data, index, level, childIndex){
    // console.log(data, index, level,this.currentRadioAction);
     if(level == 1){
       this.callFlowJsonSections[index]['child'][childIndex]['cb_data'] = data;
     }    
   }

  removePersonalDetailsMore(index: number) {
  }

  untitleToggle(index,event: MatSlideToggleChange, level, childIndex, radioIndex) {
    let _check = event.checked;
   // debugger;
    if(childIndex == undefined){
      if (_check == true) {
        this.callFlowJsonSections[index]['mandatory']="y";
      }
      else {
        this.callFlowJsonSections[index]['mandatory']="n";
      }
    }else if(level == 1 && childIndex != undefined){
      if (_check == true) {
        this.callFlowJsonSections[index]['child'][childIndex]['mandatory']="y";
      }
      else {
        this.callFlowJsonSections[index]['child'][childIndex]['mandatory']="n";
      }
    }else if(level == 2 && childIndex != undefined){
      if (_check == true) {
        this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][radioIndex]['mandatory']="y";
      }
      else {
        this.callFlowJsonSections[index]['child'][childIndex][this.currentRadioAction][radioIndex]['mandatory']="n";
      }
    }
    
    
  }
  answerType(val: any, index) {
    if(val !=undefined){
      this.Untitledform="Heading";
    }
    else{
      this.Untitledform="Title";
    }
  }
  answerTypeInner(val: any, index, childIndex) {
    if(val !=undefined){
      this.Untitledform="Heading";
    }
    else{
      this.Untitledform="Title";
    }
  }
  formPreview(){
    
		const dialogRef = this.dialog.open(CallFormPreviewDialogComponent, {
      width: '30%',
      height:'100%', 
      panelClass: 'previewclass',     
      position:{top:'0',right:'0'},
			disableClose:false,
			data: this.callFlowJsonSections
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			//console.log('The dialog was closed');
		  });

  }
  callDuringFormSummary(){
    const dialogRef = this.dialog.open(CallDuringFormSummaryDialogComponent, {
      width: '40%',
      height:'100%', 
      panelClass: 'previewclass',     
      position:{top:'0',right:'0'},
			disableClose:false,
			data: this.callFlowJsonSections
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
		//	console.log('The dialog was closed');
		  });
  }
}

export class callFlow {
  first: number;
  second: number;
  constructor(first: number, second: number) {
    this.first = first;
    this.second = second;
  }
}