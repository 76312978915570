<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12" >
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
        
        <h2 class="d-head">ASSIGN BATCH<img src="../assets/underline.svg" class="linebelow"> </h2>
        <div class="rightside" *ngIf="showHidePassingMessage">
            <h4  [style.color]="_color" style="font-weight: bold;font-size: 17px;">{{passingMessage}}</h4>
          
             </div>
    </div>
    
</div>
<form [formGroup]="createdForm">
<div class="row pdt">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field style="background: lightgray;height: 40px;"  [ngClass]="'inputClr'">
            <input matInput placeholder="TRAINER" formControlName="trainer"  [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">      
        <mat-form-field  [ngClass]="'inputClr'">
            <mat-select  placeholder="BATCH Type" (selectionChange)="changeBatchType($event.value)" formControlName="batchType">
             <mat-option value="Regular">Regular</mat-option>
             <mat-option value="OJT">OJT</mat-option>
           </mat-select>
       </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field [ngClass]="'inputClr'">
     <input matInput [matDatepicker]="startpicker" [min]="todayDate"  placeholder="BATCH STARTS ON" formControlName="startBatch" readonly (click)="startpicker.open()">
     <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
     <mat-datepicker #startpicker></mat-datepicker>
   </mat-form-field>


</div>
    <!-- <div class="col-md-4 col-sm-12 col-xs-12">
         <mat-form-field [ngClass]="'inputClr'">
            <input matInput [matDatepicker]="availpicker" placeholder="AVAILABLE DATES" readonly (click)="availpicker.open()" formControlName="availablepicker">
            <mat-datepicker-toggle matSuffix [for]="availpicker"></mat-datepicker-toggle>
            <mat-datepicker #availpicker></mat-datepicker>
          </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'" >
            <mat-select placeholder="AVAILABLE TIMINGS" formControlName="availableTiming">
                <mat-option>None</mat-option>
                <mat-option value="option1">11AM-3PM</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
        </mat-form-field>
    </div> -->

</div>
<div class="row pdt">
    <!-- <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'" style="background: #d3d3d3ad;height: 41px;">
            <input matInput placeholder="BATCH CODE" formControlName="batchCode" readonly>
        </mat-form-field>
    </div> -->
   
    <div class="col-md-4 col-sm-12 col-xs-12">
         <mat-form-field [ngClass]="'inputClr'">
            <input matInput #toInput [matDatepicker]="endpicker" [min]="todayDate"  placeholder="BATCH ENDS ON" formControlName="endBatch" readonly (click)="endpicker.open()">
            <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
            <mat-datepicker #endpicker></mat-datepicker>
          </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">  
        <mat-form-field  [ngClass]="'inputClr'">
          <input matInput placeholder="BATCH TIME(hh:mm:ss 24hrs)" (change)="formatTime()" formControlName="batchTime" maxlength="8">
      </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">  
        <mat-form-field  [ngClass]="'inputClr'">
           <mat-select placeholder="DURATION"  formControlName="duration">
            <mat-option>None</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="30">30</mat-option>
            <mat-option value="45">45</mat-option>
            <mat-option value="60">60</mat-option>
            <mat-option value="75">75</mat-option>
            <mat-option value="90">90</mat-option>
            <mat-option value="120">120</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
</div>

<div class="row pdt">

    <div class="col-md-4 col-sm-12 col-xs-12">  
    <mat-form-field [ngClass]="'inputClr'">     
                <mat-select placeholder="BATCH CAPACITY"  formControlName="capacity">
                  <mat-option>None</mat-option>
                  <mat-option value="5">5</mat-option>
                  <mat-option value="10">10</mat-option>
                  <mat-option value="15">15</mat-option>
                  <mat-option value="20">20</mat-option>
                  <mat-option value="25">25</mat-option>
                  <mat-option value="30">30</mat-option>
                  <mat-option value="35">35</mat-option>
                  <mat-option value="40">40</mat-option>
                  <mat-option value="45">45</mat-option>
                  <mat-option value="50">50</mat-option>
                </mat-select>
              </mat-form-field>                
    </div>

  <div class="col-md-4 col-sm-12 col-xs-12">
    <mat-form-field [ngClass]="'inputClr'">     
  <mat-select placeholder="SELECT AGENTS" multiple  formControlName="agents">
    <mat-option value='{{d.id}}' *ngFor="let d of agentsDDL">{{d.email}}</mat-option>
  </mat-select>
</mat-form-field>
</div>

    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field [ngClass]="'inputClr'">  
          <input matInput placeholder="TRAINING LINK"  formControlName="location">             
        </mat-form-field>
    </div>
</div>
<!-- <div class="row pdt">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <mat-select placeholder="SELECT BATCH CODE" formControlName="batchcode">
                <mat-option>None</mat-option>
                <mat-option value="option1">11AM-3PM</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <mat-select placeholder="BATCH DATE" formControlName="batchDate">
                <mat-option>None</mat-option>
                <mat-option value="option1">18 Jul 20-22Aug 20 </mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <mat-select placeholder="BATCH TIME" formControlName="batchTime">
                <mat-option>None</mat-option>
                <mat-option value="option1">11AM-3PM</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
        </mat-form-field>
    </div>

</div> -->
<div class="row pdt">
    <!-- <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <mat-select placeholder="SELECT AGENTS" formControlName="agents" multiple>
                <mat-option value="option1">option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
        </mat-form-field>
    </div> -->
</div>
<div class="row pdt"> 
    <div class="col-md-6 col-sm-12 col-xs-12"></div>  
        <div class="col-md-6 col-sm-12 col-xs-12 rightside">
            <button mat-raised-button class="cancelbtn" (click)="cancelAssign()">CLEAR</button>
            <button mat-raised-button class="submitbtn" (click)="assignBtn(createdForm)">ASSIGN</button>
        </div>
      
        </div>
    </form>
