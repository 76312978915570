import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api.service';
import { MyserviceService } from '../../../myservice.service';

@Component({
  selector: 'app-send-app-link-dialog',
  templateUrl: './send-app-link-dialog.component.html',
  styleUrls: ['./send-app-link-dialog.component.scss']
})
export class SendAppLinkDialogComponent implements OnInit {

  userData: any;
  showHideSentLinkMsg: boolean = false;
  myUser: any;
  api_end_point:any
  constructor(public dialogRef: MatDialogRef<SendAppLinkDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public viewData,private API: ApiService,private service: MyserviceService) { 
    this.userData = viewData;
  }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
	this.myUser = this.service.get_loggedin_user();
  }

  sendAppLink(){
		this.showHideSentLinkMsg=false;
		var obj = {
			emails: JSON.stringify([''+this.userData.email]),
			production_group:  this.myUser.groupid
		}
		this.API.sendAppLink(obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("User updated successfully");
				//alert("Emails sent successfully")
				this.showHideSentLinkMsg=true;
				setTimeout(()=>{
					this.showHideSentLinkMsg=false;
          this.closePopup();
				},2000)
			}
		});
	}

  closePopup() {
		this.dialogRef.close();
	}

}
