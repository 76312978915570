<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->

<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>
  
	<div class="gig-sidenav-content">
	  
	  <div style="padding:65px 30px;background-color: #f6f6f6 !important;background: #f6f6f6 !important;">


		<div class="row">
			<!-- <div class="col-md-2"></div> -->
			<div class="col-md-12">
	
				<div class="row">		
					<div class="col-md-12">
					
						<h4 class="titlehead" style="margin-top: 0%;padding-left: 10px;position: relative;top: 8px;z-index: 9;">Dashboard</h4>
						</div>
						</div>
						<br>
		<div class="row hiringTabList" >
			<div class="col-md-12" style="padding: 0px 3.5px;">
				<mat-button-toggle-group class="dashHiringcs" #group="matButtonToggleGroup" [value]="selectedButtonToggleVal"
				(change)="onToggleButtonValChange(group.value)">
				<mat-button-toggle value="campaignValue">Campaigns</mat-button-toggle>
				<mat-button-toggle value="recruiterValue">Candidates</mat-button-toggle>
				<mat-button-toggle value="hiring_agents">Recruiters</mat-button-toggle>
				<mat-button-toggle value="assessment">Assessment</mat-button-toggle>
			</mat-button-toggle-group>
			</div>
		</div>
		<div class="row">		
			<div class="col-md-12">
				<div *ngIf="showCampaign">
					<app-campaign [refreshTriggered]="refreshTriggered" (resetTrigger)="resetTrigger()"></app-campaign>
				</div>
				<div *ngIf="showRecruiter">
					<!-- <app-recruiter (applicantDataRefresh)="refreshRecruitData()"></app-recruiter> -->
					<app-recruiter [startDateFilter]="startDateFilter" [endDateFilter]="endDateFilter"></app-recruiter>
				</div>
				<div *ngIf="showHiring">
					<app-agents [startDateFilter]="startDateFilter" [endDateFilter]="endDateFilter"></app-agents>
				</div>
				<div *ngIf="showAssessment">
					<app-assessment [startDateFilter]="startDateFilter" [endDateFilter]="endDateFilter"></app-assessment>
				</div>
			</div>
		</div>
		<br>
		<mat-card>
		<div class="row">
		
			<div class="col-md-12">
				<div  class="pDiv1" style="padding-top: 10px;padding-bottom: 10px;">
					<p class="dateCondition" *ngIf="showHideDateCondition">Start date should be less than end date*</p>
					<p style="color:green;font-size:21px;font-weight: bold;text-align:center" *ngIf="showHideSentLinkMsg">
						Emails sent successfully </p>
					<div class="divprintRt">
	
	
						<div *ngIf="showCampaign">
	<div class="row">
	<div class="col-md-4"><span class="recName">Campaign Summary</span> </div>
	<div class="col-md-3" style="text-align: end;">
		<span class="das-pe1" (click)="printwindow()">Print</span>
		<span class="das-pe1" (click)="exportExcel()">Export</span>
		<span class="das-pe1" (click)="refreshDataSource()">Refresh</span>
	</div>
	
	<div class="col-md-5" style="text-align: end;"> 
		<span class="searchSpan" style="float:initial">
			<mat-form-field style="width:170px;">
				<input matInput #searchInput [ngModel]="tableSearch.value"
					(keyup)="applyFilter($event.target.value)" placeholder="Search">
				<i matSuffix class="fa fa-search searchicon" style="color: #fd6c21 ;"
					aria-hidden="true"></i>
			</mat-form-field>
		</span>
		<span class="createbtn" style="float:right;">
			<button mat-raised-button (click)="openCreateCampaign()">+ Create Campaign </button>
		</span>
		</div>
	</div>
	
	
						</div>
	
	
	
	
						<div *ngIf="showHiring">
							<div class="row">
								<div class="col-md-2"><span class="recName">Recruiters</span> </div>
								<div class="col-md-5">
									<span>
										<mat-form-field style="width: 130px;margin-right: 20px;">
											<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
												(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)"
												[(ngModel)]="startDateFilter">
											<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
											<mat-datepicker #frompicker></mat-datepicker>
										</mat-form-field>
									</span>
									<mat-form-field style="width: 130px;">
										<input matInput #fromInput [matDatepicker]="topicker" placeholder="To" readonly
											(click)="topicker.open()" (dateChange)="change_date('end_date', $event)"
											[(ngModel)]="endDateFilter">
										<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
										<mat-datepicker #topicker></mat-datepicker>
									</mat-form-field>
									<button mat-raised-button class="btnAdd ml-3" (click)="refreshDataByDate('hiring')">Go</button>
								</div>
								<div class="col-md-5 text-right">
									<span class="das-pe1" style="top: 7px;" (click)="printwindow()">Print</span>
															<span style="top: 7px;" class="das-pe1" (click)="exportExcel()">Export</span>
															<span style="top: 7px;" class="das-pe1" (click)="refreshDataSource()">Refresh</span>
									<span class="searchSpan" style="float:initial">
										<mat-form-field style="position: relative;top: 10px;width: 170px;">
											<input matInput #searchInput [ngModel]="tableSearch.value"
												(keyup)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix class="fa fa-search searchicon" style="color: #3fb91f;"
												aria-hidden="true"></i>
										</mat-form-field>
									</span>
									
								</div>
							</div>
	
							
							<!-- <div class="vk-container">
								<div style="width:100px;"><span class="recName">Recruiters</span></div>
								<div style="width:100px;">
									<span>
										<mat-form-field style="width: 90%;">
											<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
												(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)"
												[(ngModel)]="startDateFilter">
											<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
											<mat-datepicker #frompicker></mat-datepicker>
										</mat-form-field>
									</span>
								</div>
								<div style="width:100px;">
									<mat-form-field style="width: 90%;">
										<input matInput #fromInput [matDatepicker]="topicker" placeholder="To" readonly
											(click)="topicker.open()" (dateChange)="change_date('end_date', $event)"
											[(ngModel)]="endDateFilter">
										<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
										<mat-datepicker #topicker></mat-datepicker>
									</mat-form-field>
	
								</div>
								<div style="width:150px;">
									<button mat-raised-button class="btnAdd ml-3" (click)="refreshDataByDate('hiring')">Go</button>
								</div>
								<div> <span class="das-pe1" (click)="printwindow()">Print</span>
									<span class="das-pe1" (click)="exportExcel()">Export</span>
									<span class="das-pe1" (click)="refreshDataSource()">Refresh</span>
								</div>
	
								<div><span class="searchSpan">
										<mat-form-field>
											<input matInput #searchInput [ngModel]="tableSearch.value"
												(keyup)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix class="fa fa-search searchicon" style="color: #3fb91f;"
												aria-hidden="true"></i>
										</mat-form-field>
									</span>
								</div>
	
							</div> -->
						</div>
	
	
	
	
						<div *ngIf="showRecruiter">
							<div class="vk-container">
								<div style="width:auto;">
									<span class="recName">
										Candidates list - {{selectAgencies}}
									</span>
								</div>
								<div style="width: auto;">
									<mat-form-field style="width: 135px;">
										<mat-select placeholder="Select Agency" #agencies
											(selectionChange)="changeAgencies($event)" [(ngModel)]="selectAgencies">
											<mat-option value="">All</mat-option>
											<mat-option value="uearn_organic_traffic">Uearn organic traffic</mat-option>
											<mat-option value="online_agency">Online Agencies</mat-option>
											<mat-option value="offline_agency">Offline Agencies</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div style="width: auto;">
									<mat-form-field style="width: 135px;">
										<mat-select placeholder="Select Source" #agencies
											(selectionChange)="changeSources($event)" [(ngModel)]="selectSources">
											<mat-option value="">All</mat-option>
											<mat-option value="Direct Walk-In">Direct Walk-In</mat-option>
											<mat-option value="Consultants">Consultants</mat-option>
											<mat-option value="Employee Refer">Employee Refer</mat-option>
											<mat-option value="Mass SMS">Mass SMS</mat-option>
											<mat-option value="Social Media">Social Media</mat-option>
											<mat-option value="Friends Outside">Friends Outside</mat-option>
											<mat-option value="Mass Email">Mass Email</mat-option>
											<mat-option value="Job Fair">Job Fair</mat-option>
											<mat-option value="Re-Hire">Re-Hire</mat-option>
											<mat-option value="T-Best Training">T-Best Training</mat-option>
											<mat-option value="Campus">Campus</mat-option>
											<mat-option value="Job Board">Job Board</mat-option>
											<mat-option value="IGrow">IGrow</mat-option>
											<mat-option value="Re-deployment">Re-deployment</mat-option>
											<mat-option value="NGOs">NGOs</mat-option>
											<mat-option value="Flyers">Flyers</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div style="width: auto;">
									<mat-form-field style="width: 135px;">
										<mat-select placeholder="Select Level" (selectionChange)="filterByLevel($event)"
											[(ngModel)]="selectedLevel">
											<mat-option value="">All</mat-option>
											<mat-option value="Level 1 Selected">Level 1</mat-option>
											<mat-option value="Level 2 Selected">Level 2</mat-option>
											<mat-option value="Client Selected">Client</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div  style="width: auto;">
									<mat-form-field  style="width: 105px;">
										<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
											(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)"
											[(ngModel)]="startDateFilter">
										<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
										<mat-datepicker #frompicker></mat-datepicker>
									</mat-form-field>
								</div>
								<div style="width: auto;">
									<mat-form-field style="width: 105px;">
										<input matInput #fromInput [matDatepicker]="topicker" placeholder="To" readonly
											(click)="topicker.open()" (dateChange)="change_date('end_date', $event)"
											[(ngModel)]="endDateFilter">
										<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
										<mat-datepicker #topicker></mat-datepicker>
									</mat-form-field>
	
								</div>
								<div style="width: auto;">
									<button mat-raised-button class="btnAdd ml-3" style="position: relative;top:10px;"
										(click)="refreshDataByDate('recruiter')">Go</button>
	
								</div>
							
							</div>
	
	<br>
							<div class="row">
								<div class="col-md-6"> 
									<!--<span class="searchSpan" style="position: relative;top: 10px;float: initial;">
										<button mat-raised-button style="width: 105px;" (click)="sendAppLink()">Send Link</button>
									</span>-->
									<span class="searchSpan" style="position: relative;top:10px;float: initial;">
										<button mat-raised-button (click)="openCientStatusdailog()">Update agent status</button>
									</span>
									<span style="position: relative;top: 10px;float: initial;">
										<button mat-raised-button [matMenuTriggerFor]="menuDownloadreport">Download Report
										</button>
									</span>
									<mat-menu #menuDownloadreport="matMenu">
										<button mat-menu-item (click)="downloadReportBySource()">By source</button>
										<button mat-menu-item (click)="downloadReportByjoined()">Joined Candidates</button>
									</mat-menu>
	
								</div>
								<div class="col-md-6 text-right" style="padding-top:9px;"> 
									<span class="das-pe1" (click)="printwindow()">Print</span>
									<span class="das-pe1" (click)="exportExcel()">Export</span>
									<span class="das-pe1" (click)="refreshDataSource()">Refresh</span>
									<span class="searchSpan">
										<mat-form-field>
											<input matInput #searchInput [ngModel]="tableSearch.value"
												(keyup)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix class="fa fa-search searchicon" style="color: #3fb91f;"
												aria-hidden="true"></i>
										</mat-form-field>
									</span>
								</div>
								</div>
	
	
	
	
	
	
						</div>
	
						<div *ngIf="showAssessment">
							<br>
							<div class="row">
	
								<div class="col-md-8 col-sm-8 col-xs-12">
									<span class="createbtn">
										<button mat-raised-button (click)="openCreateAssessment('')">+ Create Assessment </button>
									</span>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 "><span class="searchSpan">
										<mat-form-field>
											<input matInput #searchInput [ngModel]="tableSearch.value"
												(keyup)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix class="fa fa-search searchicon" style="color: #f74f1d;"
												aria-hidden="true"></i>
										</mat-form-field>
									</span>
								</div>
							</div>
						</div>
	
					</div>

					<!-- <span class="createbtn" *ngIf="showCampaign">
							
							<button mat-raised-button (click)="openCreateCampaign()">Create campaign +</button>
					</span>
					<span class="createbtn" *ngIf="showAssessment">
						<button mat-raised-button (click)="openCreateAssessment('')" >Create assessment +</button>
				</span>
				<span *ngIf="showRecruiter" style="padding-left: 3%;">
					<mat-form-field style="width:160px">
						<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
							(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="startDateFilter">
						<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						<mat-datepicker #frompicker></mat-datepicker>
					</mat-form-field>
					<mat-form-field style="width:160px"  class="ml-3">
						<input matInput  #fromInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="endDateFilter">
						<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
						<mat-datepicker #topicker></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button class="btnAdd ml-3" (click)="refreshDataByDate('recruiter')" >Go</button>
					
				</span>
					<span *ngIf="showHiring" style="padding-left: 3%;">
						<mat-form-field >
							<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
								(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="startDateFilter">
							<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
							<mat-datepicker #frompicker></mat-datepicker>
						</mat-form-field>
						<mat-form-field  class="ml-3">
							<input matInput  #fromInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="endDateFilter">
							<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
							<mat-datepicker #topicker></mat-datepicker>
						</mat-form-field>
						<button mat-raised-button class="btnAdd ml-3" (click)="refreshDataByDate('hiring')" >Go</button>
						
					</span>
					<span class="searchSpan" style="float: right;position: relative;right: 20px;top:10px;" *ngIf="showRecruiter">
						<button mat-raised-button (click)="uploadNew()">Upload candidates</button>
					</span>
					<span class="searchSpan" style="float: right;position: relative;right: 20px;top:10px;" *ngIf="showRecruiter">
						<button mat-raised-button (click)="openCientStatusdailog()">Update agent status</button>
					</span>
					<span class="searchSpan" style="float: right;position: relative;top:10px;right: 20px;" *ngIf="showRecruiter">
						<button mat-raised-button style="width: 110px;" (click)="sendAppLink()">Send Link</button>
					</span>
					<span class="searchSpan">
						<mat-form-field >
							<input matInput #searchInput [ngModel]="tableSearch.value" (keyup)="applyFilter($event.target.value)" placeholder="Search">
							<i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
						</mat-form-field>
					</span>
					<p *ngIf="!showAssessment">
						<span class="das-pe" (click)="printwindow()">Print</span>
						<span class="das-pe" (click)="exportExcel()">Export</span>
						<span class="das-pe" (click) = "refreshDataSource()">Refresh</span>
					
						<span *ngIf="showRecruiter">
							<button mat-raised-button [matMenuTriggerFor]="menuDownloadreport">Download Report </button>
						</span>
							<mat-menu #menuDownloadreport="matMenu">
							<button mat-menu-item (click)="downloadReportBySource()">By source</button>
							<button mat-menu-item (click)="downloadReportByjoined()">Joined Candidates</button>
							</mat-menu>
					</p> -->
				
				</div>
			</div>
		</div>
		<br>
		<div class="row">		
			<div class="col-md-12">
				<div class="pDiv1">
					<div *ngIf=showCampaign>
						<app-campaign-table></app-campaign-table>
					</div>
					<div *ngIf=showRecruiter>
						<app-recruiter-table [receiveFromDashboard]="tableSearch"></app-recruiter-table>
					</div>
					<div *ngIf=showHiring>
						<app-agents-table [receiveFromDashboard]="tableSearch"></app-agents-table>
					</div>
					<div *ngIf=showAssessment>
						<app-assessment-table [receiveFromDashboard]="tableSearch"
							(openCreateAssessment)="openCreateAssessment($event)"></app-assessment-table>
					</div>
				</div>
			</div>
			</div>
		</mat-card>
		</div>
	</div>
	</div>
	<div id="cover-spin" *ngIf="showLoader"></div>
	</div>
  
  </mat-drawer-container>
