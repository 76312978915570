
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">TRAINERS<img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix" *ngIf="showHideAllTrainers">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH FOR TRAINER">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
      <span>
        <a routerLink="/managementTeam">  <button mat-raised-button class="T-createbtn" style="width: 215px !important;"><i class="fa fa-plus" style="padding-left: 10px;
			font-size: 16px;"></i>&ensp;ADD NEW TRAINER  </button></a>
        <!-- (click)="createNewBatch()" -->
        </span>
</div>
<br>
<div>
    <mat-button-toggle-group [ngClass]="'tgGroupClass'" style="z-index: 9;text-align: center;" name="fontStyle" aria-label="Font Style" (change)="changeTogleTrainer($event.value)">
        <mat-button-toggle [ngClass]="'tgClass'" value="allTrainers" checked>All Trainers</mat-button-toggle>
        <mat-button-toggle [ngClass]="'tgClass'" value="availability">Availability</mat-button-toggle>
      </mat-button-toggle-group>
</div>
<br>

<div *ngIf="showHideAllTrainers">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef>NAME </th>
  <td mat-cell *matCellDef="let element">{{element.name}} </td>
</ng-container>

<ng-container matColumnDef="batch_code">
  <th mat-header-cell *matHeaderCellDef> CURRENT BATCH CODES </th>
  <td mat-cell *matCellDef="let element"> {{element.batch_code}} </td>
</ng-container>

<ng-container matColumnDef="rating">
  <th mat-header-cell *matHeaderCellDef>RATINGS </th>
  <td mat-cell *matCellDef="let element"  style="padding-top: 10px;">
    <div id="bloc1"> {{element.rating  | number:'1.1-1'}}</div>  
    <div id="bloc2"> 
      <ng-template #t let-fill="fill">
        <span class="star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
        </span>
      </ng-template>
      <ngb-rating [max]="5" [(rate)]="element.rating"  [starTemplate]="t" [readonly]="true"></ngb-rating>
      <!-- <star-rating-comp  [starType]="'icon'" [disabled]="true" [rating]="element.rating" [readonly]="true"></star-rating-comp> -->
    </div>
    <div id="bloc3">/ {{element.total_reviews}}</div>
  </td>
</ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef> DETAILS </th>
    <td mat-cell *matCellDef="let element" (click)="openViewDetails(element)"> <span class="detailsCss">View Details</span> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator  [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
     

<div *ngIf="showHideAvailibity">
    <app-trainers-availibility (dateChangeEvent)="filterAvailablity($event)"></app-trainers-availibility>
</div>


</mat-card>
</div>
</div>

