import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { ApiService } from '../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../utils';
import { MyserviceService } from 'src/app/myservice.service';

@Component({
	selector: 'app-payment-dashboard-detailsview-dialog',
	templateUrl: './payment-dashboard-detailsview-dialog.component.html',
	styleUrls: ['./payment-dashboard-detailsview-dialog.component.scss']
})
export class PaymentDashboardDetailsviewDialogComponent implements OnInit {
	table_data: any = [];
	displayedColumns: string[] = ['date', 'name', 'type', 'actual_earnings', 'deductions', 'opening_balance', 'amount', 'closing_balance', 'pan_number', 'description'];
	dataSource: any = new MatTableDataSource();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	_viewData: any;
	selectedCurYear: any;
	selectedMonth: any;
	_numberMonthCurrent: any;
	currency_code:any;
	api_end_point:any;

	constructor(
		public dialogRef: MatDialogRef<PaymentDashboardDetailsviewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData,
		public formbuilder: UntypedFormBuilder,
		private API: ApiService,
		private utils: Utils,
		private myService: MyserviceService
	) { }

	ngOnInit() {
		this.getCurrentMonthYear();
		if (this.viewData) {
			this._viewData = this.viewData.element;
			this.getTableData();
			this.api_end_point = this.myService.get_API_End_Point();
		}
	}
	closeDialog() {
		this.dialogRef.close();
	}
	getCurrentMonthYear() {
		let _aboutToday = new Date();
		// let _fullDate = _aboutToday.getUTCDate();
		let _getNowMonth = _aboutToday.toLocaleString('default', { month: 'long' });
		let _getCurrentMonth = _getNowMonth.substring(0, 3)
		let _getCurrentYear = _aboutToday.getFullYear();
		this.selectedMonth = _getCurrentMonth;
		this.selectedCurYear = _getCurrentYear.toString();
		this._numberMonthCurrent = _aboutToday.getMonth();
	}
	limit_string(str) {
		return str.slice(0, 10);
	}
	getTableData() {
		this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				console.log(data.errors);
				return;
			}
			if (data.success) {
			//	console.log(data.success);
				var setting = data.success;
	
				for (const currencysetting of setting.payment) {
					if (currencysetting.name == "currency_code") {
						//console.log("currency Symbol is ",currencysetting.definition);
					this.currency_code= currencysetting.definition;
					} 
				}
			}
		})
		this.table_data = this.viewData.historytable[this.viewData.element.user_id].history;
		this.table_data.forEach(element => {
			element.short_desc = true;
		});
		//console.log('printing table data', this.table_data);
		this.dataSource = new MatTableDataSource(this.table_data);
		this.dataSource.paginator = this.paginator;
	}

	exportExcel() {
		// console.log('summary', this.viewData);
		// console.log('_viewdata', this._viewData);
		let data = this.viewData.historytable[this.viewData.element.user_id].history;
		let summary = this.viewData.historytable[this.viewData.element.user_id].summary;
		// console.log('printing data', data);
		if (!data || !data.length) {
			alert('No data found for file download');
			return;
		}
		data.forEach(element => {
			element['gig_id'] = summary['gig_id'];
			element['user_id'] = summary['user_id'];
			element['agent_name'] = summary['name'];
			element['email'] = summary['email'];
			element['companycode'] = summary['companycode'];
		});
		// console.log('data after', data);
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Approval Report');
		worksheet.addRow(['Gig ID', 'Agent ID', 'Agent Name', 'Agent Email', 'Cost Code', 'Transaction Date', 'Transaction Name', 'Transaction Type', 'Gross Earnings', 'Tax Deductions', 'Opening Balance', 'Amount', 'Closing Balance', 'Description']);

		let table_keys = ['gig_id', 'user_id', 'agent_name', 'email', 'companycode', 'created_at', 'name', 'type', 'actual_earnings', 'deductions', 'opening_balance', 'amount', 'closing_balance', 'description'];

		for (var k in data) {
			let arr = [];
			const loc_obj = data[k];
			for (var i in table_keys) {
				const key = table_keys[i];
				if (loc_obj.hasOwnProperty(key)) {
					if ((key == 'created_at')) {
						if (loc_obj.hasOwnProperty(key)) {
							arr.push(this.utils.utcToLocal(loc_obj[key], 'YYYY-MM-DDTHH:mm:ss', 'lll'))
						}
					} else {
						arr.push(loc_obj[key]);
					}
				} else {
					arr.push('');
				}
			}
			worksheet.addRow(arr);
		}
		workbook.xlsx.writeBuffer().then((buff_data) => {
			let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, summary.email + '_transactions.xlsx');
		})
	}
}
