<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>


	</mat-drawer>

	<div style="padding: 80px 25px;background-color: #f6f6f6 !important;background: #f6f6f6 !important;">
		<div class="row">

			<div class="col-md-12">
				<h4 class="titlehead" style="margin-top:0;padding-left: 10px;position: relative;top: 0px;z-index: 9;">
					Dashboard</h4>
			</div>
		</div>
		<br>
		<div class="row">

			<div class="col-md-12">

				<div class="row">
					<div class="col-lg-3 col-md-3" style="padding-right: 0px;">
						<div class="card">
							<div class="card-header">L1 Interview Status</div>
							<div class="card-body" style="padding: 0 1.25rem;">
								<table class="Cam-table">
									<tr>
										<td><span>Total</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="ops_summary.l1.total"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>{{ ops_summary.l1.total }}</span></td>
									</tr>
									<tr>
										<td><span>Selected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="ops_summary.l1.selected"></mat-progress-bar>
										</td>
										<td class="tdData2"><span>{{ ops_summary.l1.selected }}</span>
										</td>
									</tr>
									<tr>
										<td><span>Rejected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-five'"
												[value]="ops_summary.l1.rejected"></mat-progress-bar>
										</td>
										<td class="tdData5"><span>{{ ops_summary.l1.rejected}}</span>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3" style="padding-right: 0px;">
						<div class="card">
							<div class="card-header">L2 Interview Status</div>
							<div class="card-body" style="padding: 0 1.25rem;">
								<table class="Cam-table">
									<tr>
										<td><span>Total</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="ops_summary.l2.total"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>{{ ops_summary.l2.total }}</span></td>
									</tr>
									<tr>
										<td><span>Selected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="ops_summary.l2.selected"></mat-progress-bar>
										</td>
										<td class="tdData2"><span>{{ ops_summary.l2.selected }}</span>
										</td>
									</tr>
									<tr>
										<td><span>Rejected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-five'"
												[value]="ops_summary.l2.rejected"></mat-progress-bar>
										</td>
										<td class="tdData5"><span>{{ ops_summary.l2.rejected}}</span>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3" style="padding-right: 0px;">
						<div class="card">
							<div class="card-header">Client Interview Status</div>
							<div class="card-body" style="padding: 0 1.25rem;">

								<table class="Cam-table">
									<tr>
										<td><span>Total</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="ops_summary.client.total"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>{{ ops_summary.client.total }}</span></td>
									</tr>
									<tr>
										<td><span>Selected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="ops_summary.client.selected"></mat-progress-bar>
										</td>
										<td class="tdData2"><span>{{ ops_summary.client.selected }}</span>
										</td>
									</tr>
									<tr>
										<td><span>Rejected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-five'"
												[value]="ops_summary.client.rejected"></mat-progress-bar>
										</td>
										<td class="tdData5"><span>{{ ops_summary.client.rejected}}</span>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3">
						<div class="card">
							<div class="card-header">Document Status</div>
							<div class="card-body" style="padding: 0 1.25rem;">
								<table class="Cam-table">
									<tr>
										<td><span>In Progress</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="ops_summary.doc.in_progress"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>{{ops_summary.doc.in_progress}}</span></td>
									</tr>
									<tr>
										<td><span>Verified</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="ops_summary.doc.verified"></mat-progress-bar>
										</td>
										<td class="tdData2"><span>{{ ops_summary.doc.verified }}</span>
										</td>
									</tr>
									<tr>
										<td><span>Rejected</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-five'"
												[value]="ops_summary.doc.rejected"></mat-progress-bar>
										</td>
										<td class="tdData5"><span>{{ ops_summary.doc.rejected}}</span>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<br>
		<div class="row">

			<div class="col-md-12">
				<mat-card>
					<div class="pDiv1">
						<div class="divprintRt">
							<p class="dateCondition" *ngIf="showHideDateCondition">Start date should be less than end
								date*</p>


							<div class="vk-container">
								<div style="width:120px"> <span class="recName">Onboarding list </span> </div>
								<div style="width:auto">
									<mat-form-field style="width: 110px;">
										<input matInput [matDatepicker]="frompicker" placeholder="From" readonly
											(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)"
											[max]="today" [(ngModel)]="startDateFilter">
										<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
										<mat-datepicker #frompicker></mat-datepicker>
									</mat-form-field>
									<mat-form-field class="ml-3" style="width: 110px;">
										<input matInput #fromInput [matDatepicker]="topicker" placeholder="To" readonly
											(click)="topicker.open()" (dateChange)="change_date('end_date', $event)"
											[max]="today" [(ngModel)]="endDateFilter">
										<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
										<mat-datepicker #topicker></mat-datepicker>
									</mat-form-field>

								</div>
								<div style="width:120px">
									<button mat-raised-button class="btnAdd ml-3" (click)="refreshData()">Go</button>
								</div>
								<div>
									<p>
										<span class="das-pe1" (click)="printwindow()">Print</span>
										<span class="das-pe1" (click)="exportExcel()">Export</span>
										<span class="das-pe1" (click)="refreshData()">Refresh</span>
									</p>
								</div>
								<div>
									<span class="searchSpan">
										<mat-form-field>
											<input matInput #_searchValue autocomplete="off"
												(keyup)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix class="fa fa-search searchicon" style="color: #3fb91f;"
												aria-hidden="true"></i>
										</mat-form-field>
									</span>
								</div>
							</div>
						</div>

					</div>


					<!--start table-->
					<br>
					<div>

						<mat-table [dataSource]="dataSource">
							<ng-container matColumnDef="sl_no">
								<mat-header-cell *matHeaderCellDef> SNo. </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="user_id">
								<mat-header-cell *matHeaderCellDef> User ID </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.user_id}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="applicant_name">
								<mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.applicant_name}} </mat-cell>
							</ng-container>

							<ng-container matColumnDef="aadhar_no">
								<mat-header-cell *matHeaderCellDef> Aadhar No </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.aadhar_no}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="applicant_number">
								<mat-header-cell *matHeaderCellDef> Phone No </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.applicant_number}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="reg_date">
								<mat-header-cell *matHeaderCellDef> Reg Date </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.reg_date}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="assessment_score">
								<mat-header-cell *matHeaderCellDef> Score </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.assessment_score}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="typing_speed">
								<mat-header-cell *matHeaderCellDef> Typing </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.typing_speed}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="l1_interview">
								<mat-header-cell *matHeaderCellDef> L1 Interview </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="capitalize_text"
										[ngStyle]="{color: getStatusColorCode(element.l1_status)}">{{ element.l1_status
										}}</span>
									<span>{{ element.l1_interview_date }}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="l2_interview">
								<mat-header-cell *matHeaderCellDef> L2 Interview </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="capitalize_text"
										[ngStyle]="{color: getStatusColorCode(element.l2_status)}">{{ element.l2_status
										}}</span>
									<span>{{ element.l2_interview_date }}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="client_interview">
								<mat-header-cell *matHeaderCellDef> Client Interview </mat-header-cell>
								<mat-cell *matCellDef="let element">
									<span class="capitalize_text"
										[ngStyle]="{color: getStatusColorCode(element.client_interview)}">{{
										element.client_interview }}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="resume">
								<mat-header-cell *matHeaderCellDef> Resume </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.resume.isUploaded"
										(click)="openDocUPload($event,element, 'RESUME')">Upload</span>
									<span class="view_btn" *ngIf="element.resume.isUploaded"
										(click)="openDocView($event,element.resume, 'RESUME')">View</span>
									<span *ngIf="element.resume.isUploaded">{{element.resume.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="aadhar_doc">
								<mat-header-cell *matHeaderCellDef> Aadhar </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.aadhar.isUploaded"
										(click)="openDocUPload($event,element, 'AADHAR')">Upload</span>
									<span class="view_btn" *ngIf="element.aadhar.isUploaded"
										(click)="openDocView($event,element.aadhar, 'AADHAR')">View</span>
									<span *ngIf="element.aadhar.isUploaded">{{element.aadhar.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="pan_doc">
								<mat-header-cell *matHeaderCellDef> PAN </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.pan.isUploaded"
										(click)="openDocUPload($event,element, 'PAN')">Upload</span>
									<span class="view_btn" *ngIf="element.pan.isUploaded"
										(click)="openDocView($event,element.pan, 'PAN')">View</span>
									<span *ngIf="element.pan.isUploaded">{{element.pan.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="service_doc">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> Service Agreement
								</mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.service.isUploaded"
										(click)="openDocUPload($event,element, 'SERVICE AGREEMENT')">Upload</span>
									<span class="view_btn" *ngIf="element.service.isUploaded"
										(click)="openDocView($event,element.service, 'SERVICE AGREEMENT')">View</span>
									<span *ngIf="element.service.isUploaded">{{element.service.date}}</span>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="passbook_doc">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> Bank Passbook </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.pass_book.isUploaded"
										(click)="openDocUPload($event,element, 'PASS BOOK')">Upload</span>
									<span class="view_btn" *ngIf="element.pass_book.isUploaded"
										(click)="openDocView($event,element.pass_book, 'PASS BOOK')">View</span>
									<span *ngIf="element.pass_book.isUploaded">{{element.pass_book.date}}</span>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="SSC_Certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> SSC </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.SSC.isUploaded"
										(click)="openDocUPload($event,element, 'SSC')">Upload</span>
									<span class="view_btn" *ngIf="element.SSC.isUploaded"
										(click)="openDocView($event,element.SSC, 'SSC')">View</span>
									<span *ngIf="element.SSC.isUploaded">{{element.SSC.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="HSC_Certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> HSC </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.HSC.isUploaded"
										(click)="openDocUPload($event,element, 'HSC')">Upload</span>
									<span class="view_btn" *ngIf="element.HSC.isUploaded"
										(click)="openDocView($event,element.HSC, 'HSC')">View</span>
									<span *ngIf="element.HSC.isUploaded">{{element.HSC.date}}</span>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="education_certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap" style="flex: 0 0 250px;">
									Diploma/Degree/Higher Education </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display" style="flex: 0 0 240px;">
									<span class="upload_btn deBtn" *ngIf="!element.education_certificate.isUploaded"
										(click)="openDocUPload($event,element, 'EDUCATION')">Upload</span>
									<span class="view_btn" *ngIf="element.education_certificate.isUploaded"
										(click)="openDocView($event,element.education_certificate, 'EDUCATION')">View</span>
									<span
										*ngIf="element.education_certificate.isUploaded">{{element.education_certificate.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="typing_certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> Typing </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.typing_certificate.isUploaded"
										(click)="openDocUPload($event,element, 'TYPING_CERTIFICATE')">Upload</span>
									<span class="view_btn" *ngIf="element.typing_certificate.isUploaded"
										(click)="openDocView($event,element.typing_certificate, 'TYPING')">View</span>
									<span
										*ngIf="element.typing_certificate.isUploaded">{{element.typing_certificate.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="GST_Certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> GST </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.GST.isUploaded"
										(click)="openDocUPload($event,element, 'GST')">Upload</span>
									<span class="view_btn" *ngIf="element.GST.isUploaded"
										(click)="openDocView($event,element.GST, 'GST')">View</span>
									<span *ngIf="element.GST.isUploaded">{{element.GST.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="ITR_Certificate">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> ITR </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span class="upload_btn deBtn" *ngIf="!element.ITR.isUploaded"
										(click)="openDocUPload($event,element, 'ITR')">Upload</span>
									<span class="view_btn" *ngIf="element.ITR.isUploaded"
										(click)="openDocView($event,element.ITR, 'ITR')">View</span>
									<span *ngIf="element.ITR.isUploaded">{{element.ITR.date}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="status">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> Doc Status </mat-header-cell>
								<mat-cell *matCellDef="let element" class="grid-display">
									<span *ngIf="element.doc_status" class="view_btn status_font"
										[ngStyle]="{color: check_color_code(element.doc_status)}"> {{ element.doc_status
										}}</span>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="action">
								<mat-header-cell *matHeaderCellDef class="text_wrap"> Action</mat-header-cell>
								<mat-cell *matCellDef="let element">
									<!-- <mat-form-field   class="wdh">                       
							<mat-select name="element.doc_status" [(ngModel)]="element.doc_status" style="font-size: 12px;">
							  <mat-option value=""></mat-option>
							  <mat-option value="IN PROGRESS">In Progress</mat-option>
							  <mat-option value="VERIFIED">Verified</mat-option>
							  <mat-option value="REJECTED">Rejected</mat-option>
							</mat-select>
						</mat-form-field> -->
									<span ><button mat-stroked-button class="deBtn1"
											(click)="submitFinalStatus($event,element)">Update</button></span>
									<!-- <span *ngIf="element.doc_status" class="view_btn status_font" [ngStyle]="{color: check_color_code(element.doc_status)}"> {{ element.doc_status }}</span> -->
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="last_update">
								<mat-header-cell *matHeaderCellDef> Updated At </mat-header-cell>
								<mat-cell *matCellDef="let element" style="flex: 0 0  180px;"> {{element.last_update |
									date:'dd-MMM-yyyy hh:mm:ss a'}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="verified_by">
								<mat-header-cell *matHeaderCellDef> Verified By </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.verified_by}} </mat-cell>
							</ng-container>


							<mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
						</mat-table>
						<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
					</div>
					<!--end table-->
				</mat-card>
			</div>
		</div>

	</div>

	<div id="cover-spin" *ngIf="currentLoader"></div>
</mat-drawer-container>