<div class="row mt-4" *ngIf="showHideforgotResetPageUI">
  <div class="col-md-12">
    <div class="vk-mainForgotDiv mt-4 text-center">
      <p class="text-left"><img src="../assets/image/contiinexLogoImg.png" class="loginLogoImg" /> </p><br>
      <p class="pt-3"><img src="../assets/image/resetPwdImg.png" /></p>
      <p class="vk-fwText mb-1" style="font-weight: 100;">Forgot Your Password ?</p>
      <p class="vk-fwDesc mb-1 mt-3" style="font-size:16px">Hi {{tokeninfo.name}},</p>
      <p class="vk-fwDesc" style="margin: 2px 25px;font-size:13px">
        A request has been received to change the password for
        Your Contiinex account.</p>
      <p><button class="vk-fwBtn" style="width: auto;height:auto;padding: 0 20px;margin: 30px;min-height: 45px;"
          (click)="reseBtnClick()">RESET PASSWORD</button></p>
      <p class="vk-fwDesc mb-0" style="margin: 2px 25px;">
        If you did not initiate this request, please contact us immediately at<br>
      </p>
      <p style="color:#e00b37;" class="mt-0">support&#64;contiinex.com
      </p>
      <p class="vk-fwDesc mb-1 mt-3" style="font-size:20px">Thank you, </p>
      <p class="vk-fwDesc mb-1">
        <span style="color:gray;font-size: 14px;"> The Contiinex Team </span>
      </p>
      <p class="text-center">
        <a href="https://www.facebook.com/Contiinex-110359611200493" target="_blank" class="fcbk"><i
            class="fa fa-facebook" style="padding: 6px 7px;" aria-hidden="true"></i></a>
        <a href="https://www.linkedin.com/company/contiinex-com" target="_blank" class="lnkdin"><i
            class="fa fa-linkedin" style="padding: 6px 7px;" aria-hidden="true"></i></a>
        <a href="javascript:void(0)" target="_blank" class="twtr"><i class="fa fa-twitter" aria-hidden="true"></i></a>
        <a href="javascript:void(0)" target="_blank" class="playVideo"><i class="fa fa-youtube-play"
            aria-hidden="true"></i></a>
      </p><br>

    </div>
  </div>
</div>

<div class="row mt-4" *ngIf="showHideSetResetPageUI">
  <div class="col-md-12">
    <div class="vk-mainForgotDiv mt-4 text-center">
      <p class="pt-3"><img src="../assets/image/resetPwdImg.png" /></p>
      <p class="vk-fwText mb-1">Reset Password</p>
      <p class="vk-fwDesc">Hi {{tokeninfo.name}},</p>
      <p class="vk-fwDesc vk-desc-reset">Please enter new password for<br>
        {{tokeninfo.email}}</p>
      <p class="pt-3">
        <label class="vk-reset-new-label">New Password</label>
        <input type="password" autocomplete="off" class="vk-fwd-input" [(ngModel)]="newPassword" />
      </p>
      <p class="pt-3">
        <label class="vk-reset-new-label">Confirm Password</label>
        <input type="password" autocomplete="off" class="vk-fwd-input" [(ngModel)]="confirmPassword" />
      </p>
      <p *ngIf="forgotMsgError.length"><span class="vk-ercs">{{forgotMsgError}} </span></p>
      <p><button class="vk-fwBtn" (click)="resetPasswordBtn()">SUBMIT</button></p>
      <p class="vk-fwBLog">
        <span (click)="backToLoginBtn()">
          <mat-icon style="vertical-align: bottom;">chevron_left</mat-icon>
          
        Back to login</span>
      </p>
      <p class="mt-4"><img src="../assets/image/forgotFooterImg.png" style="width: 100%;" /></p>
    </div>
  </div>
</div>

<div class="row mt-4" *ngIf="showHideUpdatedPasswordPageUI">
  <div class="col-md-12">
    <div class="vk-mainForgotDiv mt-4 text-center">
      <p class="pt-3"><img src="../assets/image/resetPasswordDoneImg.png" /></p>
      <p class="vk-fwText mb-1">Password Updated!</p>
      <p class="vk-fwDesc" style="font-size: 0.75em;">Your password has been changed Successfully</p>
      <p class="vk-fwDesc"> Your password has been changed successfully
        Use your new password to log iin.</p>

      <p class="vk-fwBLog"><span (click)="backToLoginBtn()"><mat-icon
            style="vertical-align: bottom;">chevron_left</mat-icon>Back to login</span></p>
      <p class="mt-4"><img src="../assets/image/forgotFooterImg.png" style="width: 100%;" /></p>
    </div>
  </div>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">