<div class="container-fluid p-4">
  <div class="row">
    <div class="col-12">
      <h5>Upload Status</h5>
      <h4 *ngIf="showHidePassingMessage" [style.color]="_color" class="text-success fw-bold fs-5">
        {{passingMessage}}
      </h4>
    </div>
  </div>

  <form [formGroup]="createdForm">
    <div class="row">
      <div class="col-12">
        <!-- Top row for Download and Browse buttons -->
        <div class="d-flex justify-content-between align-items-center">

          <a href="javascript:void(0)" class="download-link" (click)="download_file()">
            <i class="fa fa-file-excel" aria-hidden="true"></i>
            Download Excel Template
          </a>

          <div class="upload-btn-wrapper">
            <button mat-raised-button>
              <mat-icon>folder_open</mat-icon>
              Browse
            </button>
            <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)" [disabled]="form_submitted" />
          </div>
          
        </div>
        <div class="d-flex justify-content-end mb-3">
          <span class="text-muted">{{currentFileobj.name}}</span>
        </div>
        <!-- Bottom row for Cancel and Submit buttons -->
        <div class="d-flex justify-content-end gap-3">
          <button mat-raised-button (click)="closeDialog()" [disabled]="form_submitted">
            <mat-icon>close</mat-icon>
            Cancel
          </button>

          <button *ngIf="showHideSubmitUpload" mat-raised-button
                  (click)="saveButton(createdForm)" [disabled]="form_submitted">
            <mat-icon>check</mat-icon>
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div id="cover-spin" *ngIf="currentLoader"></div>
  