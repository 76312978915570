import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-call-form-preview-dialog',
  templateUrl: './call-form-preview-dialog.component.html',
  styleUrls: ['./call-form-preview-dialog.component.scss']
})
export class CallFormPreviewDialogComponent implements OnInit {

  formView: any[] = [];

  constructor(public dialogRef: MatDialogRef<CallFormPreviewDialogComponent>,@Inject(MAT_DIALOG_DATA) public viewData) { 
   // console.log(viewData)
    this.formView = viewData;
  }

  ngOnInit(): void {
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
