import { Component, OnInit, ViewChild,AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';

import { IddailogComponent } from './iddailog/iddailog.component';

import { TechService } from './tech.service';
import { MyserviceService } from '../myservice.service';

import { Utils } from '../utils';

export const MY_FORMATS = {
	parse: {
	  dateInput: 'LLL',
	},
	display: {
	  dateInput: 'LLL',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
  };

@Component({
  selector: 'app-techsupport',
  templateUrl: './techsupport.component.html',
	styleUrls: ['./techsupport.component.scss'],
  providers: []
})
export class TechsupportComponent implements OnInit, AfterViewInit, OnChanges {
	
	navigationTitle = 'techSupport';
	utils = new Utils();
	default_summary = function () {
		return {
			'tickets': {
				'total': 0,
				'open': 0,
				'close': 0
			},
			'status': {
				'assigned': 0,
				'inprogress': 0,
				'closed': 0
			},
			'priority': {
				'high': 0,
				'medium': 0,
				'low': 0
			}
		}
	};
	summary = this.default_summary();
	edit_arr: any = [];

	rawData: any = {};

	tableDate = {
		'start_date': new UntypedFormControl(moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')),
		'end_date': new UntypedFormControl(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'))
	}

	tech_Name:any="Tech supporter";
	displayedColumns: string[] = ['sno', 'name', 'agentid', 'ticketno', 'issuetype', 'issuestatus', 'priority', 'assignedTo',
		'assignedOn', 'resolvedBy', 'resolvedOn', 'modifiedOn', 'estimateOn', 'edit'];

	dataSource = new MatTableDataSource([]);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	readOnlyTrueFalse:boolean=true;
	textAppearance:any="none";
	showHideUpdateDel:boolean=false;

	constructor(
		private dialog: MatDialog,
		private techService: TechService,
		private _service: MyserviceService
	) { }

	ngOnInit() {
		this.dataSource.paginator = this.paginator;
		this.refreshData();
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator; 
	}
	ngOnChanges(changes:SimpleChanges) {
		console.log('on changes', changes);
	}
	change_date(key:string, event:MatDatepickerInputEvent<Date>) {
		console.log('change date', key, event.value);
		if (key == 'start_date') {
			this.tableDate.start_date.patchValue(moment(new Date(event.value)).startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
		} else {
			this.tableDate.end_date.patchValue(moment(new Date(event.value)).endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
		}
	}
	refreshData() {
		// console.log('printing dates', this.tableDate.start_date.value, this.tableDate.end_date.value);
		let obj = {
			'start_date': this.tableDate.start_date.value,
			'end_date': this.tableDate.end_date.value
		};
		this.techService.getTechIssues(obj).then(
			() => {
				this.techService.tech_issues.subscribe((d) => {
					this.rawData = d;
					this.prepareTableData();
				})
			}, (err) => {
				alert(err)
			}
		)
	}
	prepareSummaryData() {
		let data = this.dataSource.data;
		let obj = this.default_summary();
		if (data && data.length) {
			for (var i in data) {
				let loc_obj = data[i];
				if (loc_obj && loc_obj['issuestatus']) {
					obj.tickets.total += 1;
					switch (loc_obj['issuestatus']) {
						case 'ASSIGNED':
							obj.tickets.open += 1;
							obj.status.assigned += 1;
							break;
						case 'INPROGRESS':
							obj.tickets.open += 1;
							obj.status.inprogress += 1;
							break;
						case 'RESOLVED':
						case 'REJECTED':
							obj.tickets.close += 1;
							obj.status.closed += 1;
							break;
					}
				}
				if (loc_obj && loc_obj['priority']) {
					switch (loc_obj['priority']) {
						case 'HIGH':
							obj.priority.high += 1;
							break;
						case 'MEDIUM':
							obj.priority.medium += 1;
							break;
						case 'LOW':
							obj.priority.low += 1;
							break;
					}
				}
			}
		}
		this.summary = obj;
	}
	prepareTableData() {
		let data = this.rawData.tickets;
		let arr = [];
		if (data && data.length) {
			for (var i in data) {
				let loc_obj = data[i];
				let obj = {};
				let resolvedTime = this.utils.getdateObj(loc_obj['resolved_time'], 'YYYY-MM-DDTHH:mm:ss');
				let assignedTime = this.utils.getdateObj(loc_obj['assigned_time'], 'YYYY-MM-DDTHH:mm:ss');
				let modifiedTime = this.utils.getdateObj(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss');
				let estimateTime:any = moment().format('YYYY-MM-DDTHH:mm:ss');
				if (loc_obj && loc_obj['estimated_time']) {
					estimateTime = this.utils.getdateObj(loc_obj['estimated_time'], 'YYYY-MM-DDTHH:mm:ss');
				}
				obj['sno'] = parseInt(i) + 1;
				obj['name'] = loc_obj['user_name'];
				obj['agentid'] = loc_obj['user_id'];
				obj['ticketno'] = loc_obj['id'];
				obj['issuetype'] = loc_obj['issue_type'];
				obj['priority'] = loc_obj['issue_priority'];
				obj['issuestatus'] = loc_obj['status'];
				obj['assignedTo'] = this.getAgentData(loc_obj['tech_user_id'], 'name');
				// obj['assignedOn'] = moment().format('YYYY-MM-DDTHH:mm:ss');
				obj['assignedOn'] = this.utils.utcToLocal(assignedTime, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
				obj['resolvedBy'] = '';
				obj['resolvedOn'] = '';
				if (resolvedTime && (resolvedTime != '')) {
					obj['resolvedOn'] = this.utils.utcToLocal(resolvedTime, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
				}
				obj['modifiedOn'] = '';
				if (modifiedTime && (modifiedTime != '')) {
					obj['modifiedOn'] = this.utils.utcToLocal(modifiedTime, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
				}
				obj['estimateOn'] = estimateTime;
				obj['edit'] = '';
				obj['showHideUpdateDel'] = false;
				arr.push(obj);
			}
		}
		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
		this.prepareSummaryData();
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	createidDialog(){
		const dialogRef = this.dialog.open(IddailogComponent, {
		  width: '27%',
		  disableClose:true,
		  data: ""
		});
	
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	  }
	editBtnClick(event, element:any, index:number){
		// debugger;
		this.edit_arr[index] = JSON.stringify(element);
		this.textAppearance="legacy";
		element.readOnlyTrueFalse = !element.readOnlyTrueFalse;
		element.showHideUpdateDel = !element.showHideUpdateDel;
		// console.log('edit option', index, this.edit_arr);
	}
	updateBtnClick(event, element: any, index:number) {
		let obj = {};
		obj['ticketid'] = element['ticketno'];
		obj['user_id'] = element['agentid'];
		obj['issue_type'] = element['issuetype'];
		obj['issue_priority'] = element['priority'];
		obj['status'] = element['issuestatus'];
		obj['assigned_time'] = element['assignedOn'];
		obj['issue_resolved_by'] = element['resolvedBy'];
		obj['resolved_time'] = element['resolvedOn'];
		obj['estimated_time'] = element['estimateOn'];
		this.techService.updateTicket(obj).then(
			(success) => {
				alert(success);
				this.prepareSummaryData();
				element.showHideUpdateDel = !element.showHideUpdateDel;
				element.readOnlyTrueFalse = !element.readOnlyTrueFalse;
			}, (err) => {
				alert(err);
				this.resetTableData(index);
			}
		)
		// console.log('print element data',element)
	}
	deleteBtnClick(event, element: any, index: number) {
		element.showHideUpdateDel= !element.showHideUpdateDel;
		element.readOnlyTrueFalse = !element.readOnlyTrueFalse;
		this.resetTableData(index);
		// console.log('after delete', table_data, this.edit_arr);
	}

	resetTableData(index:number) {
		let table_data = this.dataSource.data;
		table_data[index] = JSON.parse(this.edit_arr[index]);
		this.edit_arr.splice(index, 1);
		this.dataSource = new MatTableDataSource(table_data);
	}

	getAgentData(id, key) {
		let user:any = this._service.getAgentObject(id);
		if (user && user.hasOwnProperty(key)) {
			return user[key];
		} else {
			return '';
		}
	}
}

  
