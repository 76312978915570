import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import {UntypedFormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import * as faceapi from '../../../assets/face-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assesment',
  templateUrl: './assesment.component.html',
  styleUrls: ['./assesment.component.scss']
})
export class AssesmentComponent implements OnInit {
   _timer:any="00";
   _getMinute:any;//='3';
  assessment: any;
  callFlowJsonSections: any;
  createdForm:UntypedFormGroup;
  testStatus: string = "";
  timer:any;
  showResult: boolean = false;
  result:any;
  startTime = new Date().getTime();
  lastTime;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) 
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas2', { static: true }) 
  canvas2: ElementRef<HTMLCanvasElement>;
  videoWidth = 0;
    videoHeight = 0;
    constraints = {
        video: {
            facingMode: "user",
            width: { ideal: 300 },
            height: { ideal: 300 }
        }
    };
    myUser: any;
    photoCount: number=0;
    errorCount: number=0;
    alignFace: any =true;
    hideQ: any =false;
	api_end_point:any;
  constructor(private renderer: Renderer2, private API: ApiService, private service: MyserviceService,private router: Router) { 
    this.myUser = this.service.get_loggedin_user();

  }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
    // if(this.myUser.isVisonEnabled != true ){
    //   this.router.navigate(['/agentPlugin/face']);
    //   return;
    // }
    this.fetchAssessment();
    this.startCamera();
    
  }

  fetchAssessment(){
    this.API.getAssessmentForCandidate(false,this.myUser.id,this.myUser.applicant_group,null,this.api_end_point)
			.subscribe(
				(res: any) => {
					if (res.errors) {
            alert(res.errors)
            this.testStatus = '';
					} else {
            this.assessment = res.success;
            this.callFlowJsonSections = this.assessment.questionaire;
            this._getMinute = this.assessment.timing;
           // console.log(this.callFlowJsonSections);
            this.testStatus = 'initial'
					}
				});
  }
  startTimeClick(){
    this._getMinute = this.assessment.timing - 1;
    this.timeload();
    this.testStatus = 'started';
  }
  timeload() {
    var minute = this._getMinute;
       var sec = 59;
  this.timer = setInterval(()=>{
    if(minute==undefined){
      document.getElementById("timer").innerHTML="Expired";    
      return false;
    }
    else{
    document.getElementById("timer").innerHTML = minute + " Min : " + sec +" Secs";
    sec--;
    let _zero=Number('00')
    if (sec == _zero) {
      minute --;
      sec = 59;
      if (minute == -1 && sec==59) {// if (minute == 0)
          minute = this._getMinute;          
         // debugger;
          if(minute==undefined){
            document.getElementById("timer").innerHTML="Expired";
           this.submitQuestionaire();
            return false;
          }         
      }      
    }
  }
  }, 1000);

  }

  submitQuestionaire(){
    clearInterval(this.timer)
    this.testStatus = 'ended';
    
   // console.log(JSON.stringify(this.callFlowJsonSections));
    var obj = {
      "production_group":this.myUser.applicant_group,
      "assessment_id":this.assessment.id,
      "user_answers": JSON.stringify(this.callFlowJsonSections),
      "user_id": this.myUser.id,
    };
//	console.log(obj);
	//debugger;
    this.API.validateQuestionaire(obj,this.api_end_point)
			.subscribe(
				(res: any) => {
					if (res.errors) {

					} else {
           // console.log(res.success)
            this.showResult = true;
            this.result = res.success;
					}
				});
  }
  handleError(error) {
    console.log('Error: ', error);
}
attachVideo(stream) {
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
  //  console.log("hi")
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
    //  console.log(this.videoWidth, this.videoHeight)
      //const canvas  = faceapi.createCanvasFromMedia(this.videoElement.nativeElement);
      // document.body.append(canvas)
      //this.canvas.nativeElement = canvas;
      const displaySize = { width: this.videoWidth, height: this.videoHeight }
      faceapi.matchDimensions(this.canvas.nativeElement, displaySize);
      var scanInterval = setInterval(async () => {
        // const detections = await faceapi.detectAllFaces(this.videoElement.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
        
        // console.log(detections)
        if(true){
          // this.alignFace = true;
          if(this.lastTime == undefined || ((this.startTime - this.lastTime) > 10000 && this.photoCount < 4)){
            this.lastTime = this.startTime;
           // console.log(this.lastTime);
            this.photoCount = this.photoCount + 1;
            if(this.photoCount == 4){
              this.photoCount = 0;
              this.errorCount = 0;
            }
            const displaySize = { width: this.videoWidth, height: this.videoHeight }

            // const detections = await faceapi.detectAllFaces(this.videoElement.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
            // const resizedDetections = faceapi.resizeResults(detections, displaySize);
            this.renderer.setProperty(this.canvas2.nativeElement, 'width', this.videoWidth);
            this.renderer.setProperty(this.canvas2.nativeElement, 'height', this.videoHeight);
            this.canvas2.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
            this.canvas2.nativeElement.toBlob(blob=> {
              let formData = new FormData();
                formData.append('image', blob, `user.jpg`);
                this.canvas2.nativeElement.width = 0;
                this.canvas2.nativeElement.height = 0;
				this.API.bulkUploadFaceRecognizeAinexServer(formData)
                .subscribe(
                  (res: any) => {
                    if (res.errors) {

                    } else {
                      if(res.status){
                      //  console.log("result ",res.status)
                        if(res.auth_type == 'unauthorized'){
                          this.errorCount = this.errorCount + 1;
                          if(this.errorCount > 2){
                            // alert("Wrong identity");
                            this.API.assessmentUpdateFradulent({production_group: this.myUser.applicant_group, user_id: this.myUser.id},this.api_end_point)
                            .subscribe(
                              (res: any) => {
                                if (res.errors) {
                                  alert(res.errors);
                                  window.location.reload();
                                } else {
                                  if(res.status){
                                    
                                  }
                                }
                            });
                          }
                        }else if(res.status == 'failed'){
                          this.alignFace = false;
                          setTimeout(()=>{
                            this.alignFace = true;
                          },3000)
                        }else{
                          this.errorCount = 0;
                        }
                      }
                      // console.log(res)
                    }
                });
            }, 'image/jpeg');
            
          }
        }else{
          this.alignFace = false;
        }
        // const resizedDetections = faceapi.resizeResults(detections, displaySize)
        this.canvas.nativeElement.getContext('2d').clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
        // faceapi.draw.drawDetections(this.canvas.nativeElement, resizedDetections)
        // faceapi.draw.drawFaceLandmarks(this.canvas.nativeElement, resizedDetections)
        // faceapi.draw.drawFaceExpressions(this.canvas.nativeElement, resizedDetections);
        this.startTime = new Date().getTime();
        // console.log(this.startTime)
      }, 500)
  });
  // Promise.all([
  //   faceapi.nets.tinyFaceDetector.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceLandmark68Net.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceRecognitionNet.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceExpressionNet.loadFromUri('../../../assets/models')
  // ]).then(()=>{
   
  // })
    
}
startCamera() {
  if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
      
  } else {
      alert('Sorry, camera not available.');
  }
}
  readyWebCam(){
    setTimeout(()=>{
      this.startCamera();
      // setTimeout(()=>{
      //   this.capture();
      // },2000)
     },100)
   
  }

  mddValueChange(event,index,childIndex,mddIndex){
   // console.log(event.checked,index,childIndex,mddIndex)
    if(event.checked){
      var dd_data = this.callFlowJsonSections[index].child[childIndex].Answers;
      var total = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer : "";
      if(total == ""){
        total = total + dd_data[mddIndex]; 
      }else{
        total = total + "," + dd_data[mddIndex]; 
      }
      this.callFlowJsonSections[index].child[childIndex].answer = total;
    //  console.log(this.callFlowJsonSections[index].child[childIndex].answer)
    }else{
      var array = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : []
     // console.log(mddIndex,array)
      var findIndex  = 0;
      for(var i = 0; i < array.length; i++){
        if(array[i] == this.callFlowJsonSections[index].child[childIndex].Answers[mddIndex]){
          findIndex = i;
          array.splice(findIndex, 1);
          this.callFlowJsonSections[index].child[childIndex].answer = array.toString()
          break;
        }
      }
     // console.log(array)
    }
    var arrayPrev = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : [];
    var arrayDD = this.callFlowJsonSections[index].child[childIndex].Answers;
    var finalArray = [];
    for(var j = 0; j < arrayDD.length; j++){
      for(var k = 0; k < arrayPrev.length; k++){
        if(arrayDD[j] == arrayPrev[k]){
          finalArray.push(arrayDD[j]);
        }
      }
    }
  //  console.log(finalArray);
    this.callFlowJsonSections[index].child[childIndex].answer = finalArray.toString()
  }
}


 

