import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-auditor-auditors',
  templateUrl: './auditor-auditors.component.html',
  styleUrls: ['./auditor-auditors.component.scss']
})
export class AuditorAuditorsComponent implements OnInit {

  displayedColumns = ['name', 'project_name', 'cx_touch_point', 'totalauditor','payment_type','earned','received','balance','lastPay'];
  dataSource:any = new MatTableDataSource();
  myUser: any;
  allAuditors: any[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  isEditBatch:boolean=false;
  constructor(private API: ApiService, private service: MyserviceService, private dialog: MatDialog,public router:Router,
    ) { 
    }
  ngOnInit() {
 
    
    this.myUser = this.service.get_loggedin_user();

    this.API.getClientsAuditorsByGroup(this.myUser.id).subscribe((response: any) => {
      for (const project of response.success) {
        let obj: any = project;
        obj.totalauditor = '1000';
        obj.lastPay = '08/10/2020';
        obj.earned = 'Rs. 5000'; obj.received = 'Rs. 5000'; obj.balance = 'Rs. 0';
        this.allAuditors.push(obj);
      }
      this.dataSource = new MatTableDataSource(this.allAuditors);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
        return data.project_name.toString().toLowerCase().includes(filter) || data.name.toString().toLowerCase().includes(filter)
      };
    });
    
   }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}

