<div class="sidenav-container">
    <div class="left-side-nav" [ngClass]="{ 'minimize': is_minimized}"> 
        <div class="logo">
            <ng-container>
                <img src="../assets/web_images/comnexlogo.png" class="short-logo" [ngClass]="{'d_none': !is_minimized}" />
            </ng-container>
            <ng-container>
                <div class="expand_logo" [ngClass]="{'d_none': is_minimized}">
                    <img src="../assets/web_images/comnexProductImg.png" class="full-logo" />
                </div>
            </ng-container>
        </div>
        <div class="menu-items" #myDIVMenu>
            <mat-accordion class="menu_accordion" displayMode="flat">
                <ng-container *ngFor="let page of total_pages | keyvalue">
                    <div *ngIf="!page.value.child" class="zero_padding menu-tab">
                        <ng-container>
                            <button mat-menu-item [ngClass]="{'no-text': is_minimized}" data-id="{{page.key}}" #menu_button
                            (click)="resetMenuHighlighter($event, page.value)">
                            <mat-icon [matTooltip]="page.value.name" [matTooltipDisabled]="!is_minimized" *ngIf="page.value.mat_icon" [ngClass]="{ 'max_icon': is_minimized}">{{page.value.mat_icon}}</mat-icon>
                            <span [ngClass]="{'d_none': is_minimized}">{{page.value.name}}</span>
                        </button>
                        </ng-container>
                        <!-- <ng-container>
                            <button mat-menu-item data-id="{{page.key}}" #menu_button
                            (click)="resetMenuHighlighter($event, page.value)">
                            <mat-icon *ngIf="page.value.mat_icon" [ngClass]="{ 'max_icon': is_minimized}">{{page.value.mat_icon}}</mat-icon>
                        </button>
                        </ng-container> -->
                    </div>
                    <mat-expansion-panel *ngIf="page.value.child" [ngClass]="{'minimized_class': is_minimized}" (opened)="(page.panelOpenState = true)"
                        (closed)="(page.panelOpenState = false)" hideToggle>
                        <mat-expansion-panel-header class="zero_padding">
                            <mat-panel-title>
                                <ng-container>
                                    <button mat-menu-item [ngClass]="{'no-text': is_minimized}" data-id="{{page.key}}" #parent_button>
                                    <mat-icon *ngIf="page.value.mat_icon" [matTooltip]="page.value.name" [matTooltipDisabled]="!is_minimized">{{page.value.mat_icon}}</mat-icon>
                                    <span [ngClass]="{'d_none': is_minimized}">{{page.value.name}}</span>
                                    <span class="p-chevron" *ngIf="!is_minimized">
                                        <mat-icon *ngIf="page.panelOpenState">expand_less</mat-icon>
                                        <mat-icon *ngIf="!page.panelOpenState">expand_more</mat-icon>
                                    </span>
                                </button>
                                </ng-container>
                                <!-- <ng-container *ngIf="is_minimized">
                                    <button mat-menu-item data-id="{{page.key}}" #parent_button>
                                        <mat-icon *ngIf="page.value.mat_icon">{{page.value.mat_icon}}</mat-icon>
                                    </button>
                                </ng-container> -->
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let child of page.value.child">
                            <ng-container>
                                <button #menu_button mat-menu-item [ngClass]="{'no-text': is_minimized}" data-id="{{child.data_id}}" [attr.data-parent-id]="page.key"
                                (click)="resetMenuHighlighter($event, child)">
                                <mat-icon *ngIf="child.mat_icon" [matTooltip]="child.name" [matTooltipDisabled]="!is_minimized" [ngClass]="{ 'max_icon': is_minimized}">{{child.mat_icon}}</mat-icon>
                                <span [ngClass]="{'d_none': is_minimized}">{{child.name}}</span>
                            </button>
                            </ng-container>
                            <!-- <ng-container *ngIf="is_minimized">
                                <button #menu_button mat-menu-item data-id="{{child.data_id}}" [attr.data-parent-id]="page.key"
                                (click)="resetMenuHighlighter($event, child)">
                                <mat-icon *ngIf="child.mat_icon" [ngClass]="{ 'max_icon': is_minimized}">{{child.mat_icon}}</mat-icon>
                                <span>{{child.name}}</span>
                            </button>
                            </ng-container> -->
                        </ng-container>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
        <div class="bottom-links">
          
            <ng-container>
                <div class="footer_logo"  [ngClass]="{'d_none': is_minimized}">
                    <img src="../assets/web_images/contiinexLogoImg.png" class="full-logo" />
                </div>
            </ng-container>
        </div>
    </div>
    <div class="toggle-button" [ngClass]="{'minimize': is_minimized}">
        <mat-icon matTooltip="{{is_minimized ? 'Maximize' : 'Minimize'}} Sidebar" 
                matTooltipPosition="right" 
                class="pointer"
                (click)="update_side_menu_collapse_status(!is_minimized)">
            {{is_minimized ? 'last_page' : 'first_page'}}
        </mat-icon>
    </div>
</div>