<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->

<mat-drawer-container autosize id="drawer-container">
	

	<mat-drawer #drawer class="gig-sidenav" [ngClass]="{'minimize': is_minimized}" mode="side" opened="true">
		<app-menu 
			[navigateLink]="currentTitle" 
			[is_minimized]="is_minimized"
			(is_minimizedChange)="onMinimizedChange($event)">
		</app-menu>
	</mat-drawer>
	<app-logout></app-logout>
	<!-- Feedback Dashboard starts -->
	<div class="content-container" id="content-container">
		<div class="grid-container">
			<!-- Back Button Section -->
			<div class="d-flex justify-content-end p-2">
				<button mat-raised-button class="back_btn" (click)="goBack()">
					<mat-icon>arrow_back</mat-icon>
					BACK</button>

			</div>

			<!-- Date Filter Section -->
			<div class="grid-item">
				<div>
					<mat-card appearance="outlined">
						<mat-card-header>
							<mat-card-title>Call Logs</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<!-- Date Filter Section -->
							<div class="row pl-4 pt-3">
								<div class="col-md-3">
									<mat-form-field class="wd-100" appearance="outline">
										<mat-label>From</mat-label>
										<input matInput [matDatepicker]="frompicker" [max]="maxDate"
											placeholder="From" readonly (click)="frompicker.open()"
											(dateChange)="change_date_status('start_date', $event)"
											[(ngModel)]="StartDate">
										<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
										<mat-datepicker #frompicker></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field class="wd-100" appearance="outline">
										<mat-label>To</mat-label>
										<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate"
											placeholder="To" readonly (click)="topicker.open()"
											(dateChange)="change_date_status('end_date', $event)"
											[(ngModel)]="EndDate">
										<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
										<mat-datepicker #topicker></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<button mat-raised-button (click)="refreshData()">
										<mat-icon>arrow_forward</mat-icon>
										GO
									</button>
								</div>
								<div class="col-md-4 col-sm-12 gap-3 d-flex justify-content-end flex-wrap">
									<button mat-raised-button (click)="exportExcel()">
										<mat-icon>download</mat-icon>
										EXPORT
									</button>
									<button mat-raised-button (click)="exportExcelByGroup()">
										<mat-icon>download</mat-icon>
										EXPORT BY GROUP
									</button>
								</div>
							</div>

							<!-- Feedback Summary Section -->
							<div class="mt-4">
								<mat-card-title>Feedback Summary</mat-card-title>
								<ng-container *ngIf="summary_table_data.data.length">
									<div class="overflow-auto custom-scrollbar custom-table-container">
										<mat-table [dataSource]="summary_table_data" style="border-right: 1px solid #cccccc57;">
											<ng-container *ngFor="let d of summary_table_columns" [matColumnDef]="d">
											<mat-header-cell *matHeaderCellDef> {{getKeyName(d)}} </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element[d]">
												{{element[d]}}
											</mat-cell>
										</ng-container>
										<mat-header-row *matHeaderRowDef=summary_table_columns></mat-header-row>
											<mat-row *matRowDef="let row; columns: summary_table_columns"></mat-row>
										</mat-table>
									</div>
									<mat-paginator #summaryPaginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons></mat-paginator>
									 
									<!-- <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons></mat-paginator> -->

								</ng-container>
								<ng-container *ngIf="!summary_table_data.data.length">
									<p class="p-no-data">No data available</p>
								</ng-container>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>

			<!-- Calls Summary Section -->
			<div class="grid-item">
				<div class="mt-4">
					<mat-card appearance="outlined">
						<mat-card-header>
							<mat-card-title>Calls Summary</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<ng-container>
								<app-table-filter [table_data]="dataSource" [filter_columns_obj]="columns_filter"
									(filtered_data)="filtered_data($event)"></app-table-filter>
								<div class="overflow-auto custom-scrollbar custom-table-container">
								<mat-table class="mtblcs" [dataSource]="dataSource">
									<ng-container matColumnDef="supervisor">
										<mat-header-cell *matHeaderCellDef> TL </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.supervisor}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="u_name">
										<mat-header-cell *matHeaderCellDef> A.Name </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="element.u_name">
											{{element.u_name}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="u_email">
										<mat-header-cell *matHeaderCellDef> A.Email </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="element.u_email">
											{{element.u_email}}
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="cust_name">
										<mat-header-cell *matHeaderCellDef> C.Name </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="element.cust_name">
											{{limit_string(element.cust_name)}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="cust_number">
										<mat-header-cell *matHeaderCellDef> C.Number </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.cust_number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="start_time">
										<mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="element.start_time">
											{{limit_string(element.start_time)}}
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="end_time">
										<mat-header-cell *matHeaderCellDef> End </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="element.end_time">
											{{limit_string(element.end_time)}}
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="event_type">
										<mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{renameCallEventName(element.event_type)}}
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="url">
										<mat-header-cell *matHeaderCellDef> Play </mat-header-cell>
										<mat-cell *matCellDef="let element">
											<span *ngIf="element.url" style="cursor: pointer;" (click)="playCall(element); scrollToBottom()">
												<mat-icon style="color: var(--primary);">play_circle_filled</mat-icon>
											</span>
											<span *ngIf="!element.url">NA</span>
										</mat-cell>
									</ng-container>
									<ng-container *ngFor="let dataMap of additional_columns" [matColumnDef]="dataMap">
										<mat-header-cell *matHeaderCellDef> {{ dataMap }} </mat-header-cell>
										<mat-cell *matCellDef="let element" [matTooltip]="check_table_value(element, dataMap)">
											{{
											limit_string(check_table_value(element, dataMap)) }} </mat-cell>
									</ng-container>
									<mat-header-row *matHeaderRowDef=total_display_columns></mat-header-row>
									<mat-row *matRowDef="let row; columns: total_display_columns"></mat-row>
								</mat-table>
								</div>
								<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
							</ng-container>
						</mat-card-content>
					</mat-card>
				</div>
			</div>

			<!-- URL Input Section -->
			<div class="grid-item">
				<div class="mt-4">
					<mat-card appearance="outlined">
					<div class="row">
						<div class="col-md-12">
							<span class="recName">
								Paste call url below
							</span>
							<mat-form-field class="wd-100 example-form-field mt-5" appearance="outline">
								<mat-label>Paste URL</mat-label>
								<input matInput type="text" [(ngModel)]="externalUrl"
									(keydown.enter)="loadExternalUrl()">
								<button *ngIf="externalUrl" type="submit" matSuffix mat-icon-button
									aria-label="Search" (click)="loadExternalUrl()">
									<mat-icon>search</mat-icon>
								</button>
							</mat-form-field>

							<app-audio-player [source_url]="selected_url" *ngIf="selected_url"></app-audio-player>
						</div>
					</div>
				</mat-card>
				</div>
			</div>
		</div>

		<div class="audio-player-container">
			
		</div>
	</div>
	<!-- Feedback Dashboard ends -->
</mat-drawer-container>