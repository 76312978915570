import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-id',
  templateUrl: './edit-id.component.html',
  styleUrls: ['./edit-id.component.scss']
})
export class EditIDComponent implements OnInit {

	candidateData: any;

	constructor(
		public dailogRef: MatDialogRef<EditIDComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { 
		this.candidateData = this.data;
	}

	ngOnInit() {
	  
  	}

}
