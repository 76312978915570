import * as moment from 'moment';
import {AbstractControl } from '@angular/forms';
import { Injectable } from "@angular/core";

interface CampaignMonth {
	name: string,
	value: string,
	start_date: string,
	end_date: string
}

@Injectable()
export class Utils {
	constructor() {
		// console.log('Initiated the utils file')
	}

	public getStorageDataVariables():string[]{
		return ['access_token', 'my_team', 'full_team', 'settings', 'user', 'my_pages', 'call_form', 'api_end_point', 'project_r', 'project', 'current_time_range'];
	}

	/* Get the dropdown values for the month selection in the campaign dashboard */
	public getCampaignMonthDropdown() {
		let limit = 12;
		let ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		var arr: CampaignMonth[] = [];
		for (var i = 0; i < limit; i++) {
			let obj: any = {};
			let dateObject = moment().subtract(i, 'month');
			// console.log('this ', i, dateObject);
			obj.name = ms[dateObject.month()] + " - " + dateObject.year();
			obj.value = obj.name;
			obj.start_date = moment.utc(moment(dateObject).startOf('M')).format('YYYY-MM-DDTHH:mm:ss');
			obj.end_date = moment.utc(moment(dateObject).endOf('M')).format('YYYY-MM-DDTHH:mm:ss');
			arr.push(obj);
		}
		arr.push({
			name: 'Custom Date Range',
			value: 'custom',
			start_date: '',
			end_date: ''
		})
		return arr;
	}

	public getUniqueCodeFromTime() {
		var s = 0
		var d = new Date();
		s += d.getHours() * 60;
		s += d.getMinutes();
		var p = (d.getUTCDate()).toString() + (d.getUTCMonth()).toString();
		return p + s.toString();
	}
	// Date and time utils functions
	public format_to(val: any, input_format: any, output_format: any) {
		let result = '';
		try {
			if (val.length > 0) {
				return result = moment(val, input_format).format(output_format);
			} else {
				return result;
			}
		} catch (e) {
			return result;
		}
	}

	public getUtc(format?: string) {
		let t = moment().utc();
		if (format) {
			return t.format(format);
		}
		return t;
	}

	public getLocalDate(format?: string) {
		let t = moment();
		if (format) {
			return t.format(format);
		}
		return t;
	}

	public utcToLocal(utc_time: any, input_format: any, output_format?: any) {
		try {
			if (moment(utc_time).isValid()) {
				let p = moment.utc(utc_time, input_format);
				if (output_format) {
					return p.local().format(output_format);
				}
				return p.local();
			} else {
				return '';
			}
		} catch (e) {
			return '';
		}
	}

	public localToUTC(local_time: any, input_format: string, output_format?: string) {
		try {
			if (moment(local_time).isValid()) {
				let p = moment(local_time, input_format).utc();
				if (output_format) {
					return p.format(output_format);
				} else {
					return p;
				}
			} else {
				return '';
			}
		} catch (e) {
			return '';
		}
	}

	public localConvert(local_time: any, input_format: string, output_format?: string) {
		try {
			let p;
			if (moment(local_time).isValid()) {
				p = moment(local_time, input_format)
				
				if (output_format) {
					return p.format(output_format);
				} else {
					return p;
				}
			} else {
				return '';
			}
		} catch (e) {
			return '';
		}
	}

	public getdateObj(val: string, format?: string) {
		try {
			if (val && val.length) {
				if (moment(val).isValid) {
					if (format) {
						return moment(val).format(format);
					}
					return moment(val)
				}
			}
			return '';
		} catch (e) {
			return ''
		}
	}

	public getValue(val: any, _default: any) {
		try {
			if (val) {
				return val;
			} else {
				return _default;
			}
		} catch (e) {
			return _default;
		}
	}

	public getDifference(bigVal: number, smallVal: number, _default?:any) {
		let output = 0;
		if (_default) {
			output = _default
		}
		try {
			return (bigVal - smallVal)
		} catch (e) {
			return output;
		}
	}

	public getYesterdaystartDay(output_format: string, isUTC?: boolean) {
		let d: any = "";
		try {
			d = moment().subtract(1, 'day').startOf('day').format(output_format);
		if (isUTC) {
			d = this.localToUTC(d, output_format, output_format);
		}
		} catch (e) {
			d = "";
		}
		return d;
	}
	public getYesterdayendtDay(output_format: string, isUTC?: boolean) {
		let d: any = "";
		try {
			d = moment().subtract(1, 'day').endOf('day').format(output_format);
		if (isUTC) {
			d = this.localToUTC(d, output_format, output_format);
		}
		} catch (e) {
			d = "";
		}
		return d;
	}

	public getStartOf(val: any, output_format?: any, isUTC?: boolean, custDate: any = ""){
		let d = "";
		if (custDate) {
			d = moment(custDate).startOf(val).format(output_format);
		} else {
			d = moment().startOf(val).format(output_format);
		}
		if (isUTC) {
			return this.localToUTC(d, output_format, output_format)
		} else {
			return d;
		}
	}

	public getEndOf(val: any, output_format?: any, isUTC?: boolean, custDate: any = "") {
		let d = "";
		if (custDate) {
			d = moment(custDate).endOf(val).format(output_format);
		} else {
			d = moment().endOf(val).format(output_format);
		}
		if (isUTC) {
			return this.localToUTC(d, output_format, output_format)
		} else {
			return d;
		}
	}

	public getDuration(start_time, end_time, format: string, duration_type: any) {
		let r = null;
		if (!moment(start_time).isValid) {
			return r;
		}
		if (!moment(end_time).isValid) {
			return r;
		}
		try {
			let s_d = moment(start_time, format);
			let e_d = moment(end_time, format);
			if (duration_type) {
				return s_d.diff(e_d, duration_type, true);
			}
			return s_d.diff(e_d);
		} catch (e) {
			return r;
		}
	}

	public convertSectoMin(s: number) {
		let m = 0;
		if (s) {
			m = s / 60;
		}
		return m;
	}
	public convertSectoHrs(s: number) {
		let h = 0;
		let m = 0;
		if (s) {
			h = Math.floor(s / 3600);
			m = Math.floor(this.convertSectoMin(s % 3600));
		}
		return h + " hr : " + m +" min";
	}

	public numbertostringdoubleval(n: number) {
		let p = '00';
		if (n < 10) {
			p = '0' + n;
		} else {
			p = String(n);
		}
		return p;
	}

	public convertSectoHMS(seconds: number) {
		if(!seconds){
			return '00:00:00';
		  }
		  if(isNaN(seconds)){
			return '00:00:00';
		  }
		  
		  var days = Math.floor(seconds / (24*3600));
		  seconds = Math.floor(seconds % (24*3600));
		  var hours: number | string   = Math.floor(seconds / 3600) % 24;
		  if(isNaN(hours)){
			hours = 0;
		  }
		  var minutes = Math.floor(seconds / 60) % 60;
		  if(isNaN(minutes)){
			minutes = 0;
		  }
		  var seconds = seconds % 60;
		  if(isNaN(seconds)){
			seconds = 0;
		  }
		  if(days){
			hours += days*24;
		}
		  //console.log('hms',hours+'h '+minutes+'m '+seconds+'s');
		  return this.numbertostringdoubleval(hours)+':'+this.numbertostringdoubleval(minutes)+':'+this.numbertostringdoubleval(seconds);
	}

	public getDivision(num: number, denm: number): number {
		let output = 0;
		if (denm && (denm != 0)) {
			output = num / denm;
		}
		return output;
	}
	public getPercentage(num: number, denm: number, decimal?: number, symbol?: boolean) {
		if (num == 0 && denm == 0) {
			return "0.0";
		}
		let output:number | string = 0;
		let decimal_point = 2;
		if (decimal) {
			decimal_point = decimal;
		}
		output = this.getDivision(num, denm) * 100;
		output = output.toFixed(decimal_point)
		if (!symbol) {
			return output;
		}
		return output + '%';
	}
	public  ValidateEmptyInput(control: AbstractControl): {[key: string]: any} | null  {
		var regExp = /[a-zA-Z]/g;
		console.log(regExp.test(control.value))
		if (!regExp.test(control.value)) {
		  return { 'inputInvalid': true };
		}
		return null;
	}
	public limitString(str: string, limit?: number) {
		// console.log('calling limit string', str, limit);
		if (!limit) {
			limit = 10
		}
		if (str.length > limit) {
			return str.slice(0, limit - 3) + "...";
		}
		return str;
	}
	public getCallTypeNames(call_type: string) {
		switch (call_type) {
			case 'call':
				return 'CALL';
			case 'call_rnr':
				return 'RNR';
			case 'missed_call':
				return 'MISSED CALL';
			default:
				return '';
		}
	}
	public addDelay(milliseconds: number){
		new Promise(resolve => setTimeout(resolve, milliseconds));	
	}
	public wifi_status (speed?){
		let obj = {}
		if (!isNaN(speed)) {
			let connect_val = parseInt(speed);
			if ((connect_val > 0) && (connect_val <= 50)) {
				obj['connect_status'] = 'GOOD';
				obj['connect_color'] = '#01AD43';
			} else if ((connect_val > 50) && (connect_val <= 500)) {
				obj['connect_status'] = 'MODERATE';
				obj['connect_color'] = '#F9931D';
			} else if ((connect_val > 500) && (connect_val <= 750)) {
				obj['connect_status'] = 'AVERAGE';
				obj['connect_color'] = '#FACE00';
			} else if ((connect_val > 750)) {
				obj['connect_status'] = 'POOR';
				obj['color'] = '#EC1E24';
			} else {
				obj['connect_status'] = 'NO INTERNET';
				obj['color'] = '#1A75BB';
			}
		} else {
			obj['connect_status'] = 'MODERATE';
			obj['color'] = '#F9931D';
		}
		return obj
	}
	public gethmsformat(sec, mode) {
		var hrs, min, seconds;
		if (!sec) {
			hrs = 0, min = 0, seconds = 0;
		}else{
			hrs = sec / 3600;
			var temp = sec % 3600;
			min = temp / 60;
			seconds = temp % 60;
		}
		function getDigit(digit) {
			if (!digit) {
				return '00'
			} else {
				digit = Math.floor(digit);
				if (digit > 9) {
					return digit;
				} else {
					return ('0' + digit);
				}
			}
		}
		switch (mode) {
			case 'clock':
				return getDigit(hrs) + ' : ' + getDigit(min) + ' : ' + getDigit(seconds);
				break;
			default:
				return Math.round(hrs) + 'hrs ' + Math.round(min) + 'min ' + Math.round(seconds) + 'sec';
				break;
		}
	}

	public get_decimal_val(val:number | string){
		try{
			return parseFloat(val.toString()).toFixed(1);
		}catch(e){
			val = 0;
			return parseFloat(val.toString()).toFixed(1);
		}
	}
}