import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {
    constructor() { }
    setStorageItem(key: string, val: any) {
        try {
            sessionStorage.setItem(key, val);
        } catch (e) {
            console.log('Error in storing the value in the settings', e);
        }
    }
    getStorageItem(key: string) {
        try {
            let item = sessionStorage.getItem(key);
            if (item) {
                return item;
            }
            return false;
        } catch (e) {
            // debugger;
            return false;
        }
    }
}