import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-on-job-training',
  templateUrl: './on-job-training.component.html',
  styleUrls: ['./on-job-training.component.scss']
})
export class OnJobTrainingComponent implements OnInit {
  displayedColumns = ['id', 'start_date', 'end_date', 'trainer_name','certiStartDate','certiEndDate','status','edit'];
  dataSource = new MatTableDataSource();
  api_end_point:any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private myService:MyserviceService) { }

  ngOnInit() {
    this.getOJTbatchList();
	this.api_end_point = this.myService.get_API_End_Point();
  }

  getOJTbatchList(){
    let _today:any=new Date();
    let _startDate = moment(_today).format('yyyy-MM-DD');
   let _endDate=moment( new Date(_today.setDate(_today.getDate() + 30))).format('yyyy-MM-DD');
    this.API.getBatchList(_startDate,_endDate,"OJT",this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        let _filterData;
        if(_response.length>1){
         _filterData=(_response || []).filter(v=> v.status!= "CANCELLED");
        }
        else{
          _filterData=_response;
        }
        let ELEMENT_DATA=_filterData;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
          var found = false;
          if(data.id && data.id.toString().toLowerCase().includes(filter)){
            found = true
          }
          if(data.trainer_name && data.trainer_name.toString().toLowerCase().includes(filter)){
            found = true
          }
          return found;
        };
     
      }
      if(data.errors=="No Data Found"){
        alert("No Data Found in OJT Batch");
      }
    });
  }


  elementEDIT:any=[];
	openEditOJTBatch(element:any) {
		if(element.status == 'COMPLETED'){
			return;
		}
  this.elementEDIT=element;
   this.API.editBatchBind(element);
   this.router.navigateByUrl('/training/create_ojt_batch');
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  createNewBatch(){
    this.API.clearCreateforBtn();   
   this.router.navigateByUrl('/training/create_ojt_batch');
  }
}



