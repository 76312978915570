<mat-card style="margin-top:10px;">

    <div class="pad-LR">
    
    <div class="row pdt">
        <div class="col-md-6 col-sm-12 col-xs-12">
            <h2 class="d-head"  *ngIf="!showHideDeleteForEdit">CREATE NEW OJT BATCH<img src="../assets/underline.svg" class="linebelow"> </h2>
            <h2 class="d-head" *ngIf="showHideDeleteForEdit">EDIT NEW OJT BATCH<img src="../assets/underline.svg" class="linebelow"> </h2>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 rightside" *ngIf="showHideDeleteForEdit">
            
            <button mat-raised-button class="T-createbtn" style="width: 215px !important;" (click)="openWarningMessage()">DELETE OJT BATCH
              <!-- <i class="fa fa-times-circle" style="padding-left: 10px; font-size: 22px;"></i> -->
              <img src="../assets/web_images/deleteRed.png" style="width: 30px;padding: 5px;">
            </button>
        </div>
      
    </div>
    <div class="rightside" *ngIf="showHidePassingMessage">
      <h4  [style.color]="_color" style="font-weight: bold;font-size: 17px;">{{passingMessage}}</h4>
    
       </div>
    <form [formGroup]="createdForm">
    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'" style="background: #d3d3d3ad;height: 41px;">
                <input matInput placeholder="BATCH CODE"  formControlName="batchCode" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
                   <mat-form-field [ngClass]="'inputClr'">
                <input matInput [matDatepicker]="startpicker"  [min]="todayDate"  formControlName="startOJTBatch" placeholder="OJT STARTS" readonly (click)="startpicker.open()">
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker></mat-datepicker>
              </mat-form-field>
    
    
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
             <mat-form-field [ngClass]="'inputClr'">
                <input matInput [matDatepicker]="endpicker"  [min]="minDate"  formControlName="endOJTBatch" placeholder="OJT ENDS" readonly (click)="endpicker.open()">
                <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                <mat-datepicker #endpicker></mat-datepicker>
              </mat-form-field>
        </div>
    
    </div>
    
    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">  
            <mat-form-field  [ngClass]="'inputClr'">
              <input matInput placeholder="BATCH TIME(hh:mm:ss 24hrs)" (change)="formatTime()" formControlName="batchTime" maxlength="8">
          </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12">  
            <mat-form-field  [ngClass]="'inputClr'">
              <input matInput placeholder="DURATION(In hrs)" autocomplete="off" (keypress)="onlyNumberDecimalValidation($event)" formControlName="duration" maxlength="5">
               <!-- <mat-select placeholder="DURATION"  formControlName="duration">
                <mat-option>None</mat-option>
                <mat-option value="15">15</mat-option>
                <mat-option value="30">30</mat-option>
                <mat-option value="45">45</mat-option>
                <mat-option value="60">60</mat-option>
                <mat-option value="75">75</mat-option>
                <mat-option value="90">90</mat-option>
                <mat-option value="120">120</mat-option>
              </mat-select> -->
          </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12">  
            <mat-form-field [ngClass]="'inputClr'">     
                        <mat-select placeholder="BATCH CAPACITY"  formControlName="capacity">
                          <mat-option>None</mat-option>
                          <mat-option value="5">5</mat-option>
                          <mat-option value="10">10</mat-option>
                          <mat-option value="15">15</mat-option>
                          <mat-option value="20">20</mat-option>
                          <mat-option value="25">25</mat-option>
                          <mat-option value="30">30</mat-option>
                          <mat-option value="35">35</mat-option>
                          <mat-option value="40">40</mat-option>
                          <mat-option value="45">45</mat-option>
                          <mat-option value="50">50</mat-option>
                        </mat-select>
                      </mat-form-field>
            </div>

    </div>
    
    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field [ngClass]="'inputClr'">     
          <mat-select placeholder="SELECT AGENTS" multiple  formControlName="agents">
            <mat-option value='{{d.id}}' *ngFor="let d of agentsDDL">{{d.email}}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field [ngClass]="'inputClr'">     
          <mat-select placeholder="AVAILABLE TRAINER"  formControlName="trainer">
            <mat-option value="{{d.trainer_id}}" *ngFor="let d of trainerDDL">{{d.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
                <mat-form-field [ngClass]="'inputClr'">  
                  <input matInput placeholder="TRAINING LINK"  formControlName="location">
                </mat-form-field>
            </div>
    </div>
    <div class="row pdt"> 
        
            <div class="col-md-12 col-sm-12 col-xs-12 rightside">
                <button mat-raised-button class="cancelbtn" (click)="backtoOJTbatchList()" >Back</button>
            <button style="display: none;"  class="cancelbtn" (click)="cancelClear()">Cancel</button>
            <button mat-raised-button class="submitbtn" (click)="saveBatch(createdForm)" >Save</button>
            </div>
          
            </div>
        </form>      
         
    </div>
    </mat-card>
    <div class="popupoverlay" *ngIf="showHidePopupDelete">
        <div class="delete-popup">
            <h3 class="h3Message">Are you sure want to delete ?</h3>
            <a class="close CR" (click)="closedeletePopup()">&times;</a>
            <div class="content">
                <button class="cancelDelete" (click)="cancelDeleteOjtBatch()">Cancel</button>
                <button class="delete-button" (click)="deleteOjtBatch()">Delete</button>
            </div>
        </div>
    </div>