import { Component, OnInit,Input,Inject,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatSort } from '@angular/material/sort';

import { MyserviceService }from '../../myservice.service';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';

export interface Table_data{
  "columns":any,
  "data":any
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

	@Input()
	table_data:Table_data;

	columns: string[] =  [];
	data: any = [];

	dataSource = new MatTableDataSource(this.data);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(private router: Router,private http:HttpClient,private _service:MyserviceService) {

	}

	ngOnInit() {
		// debugger;
		this.columns = this.table_data.columns;
		this.data = this.table_data.data;
		this.dataSource.paginator = this.paginator; 
		this.dataSource.sort = this.sort;
		// console.log('printing on init',this.table_data)
	}
	ngAfterViewInit(){
		this.columns = this.table_data.columns;
		this.data = this.table_data.data;
		this.dataSource.paginator = this.paginator; 
		this.dataSource.sort = this.sort;
		console.log('printing table_data after view init',this.table_data)
	}
}
