import { Component, OnInit, Inject, ViewChild , ElementRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { AuditFormsComponent } from '../audit-forms/audit-forms.component';
import { HttpResponse } from '@angular/common/http';
import * as Excel from 'exceljs';

@Component({
  selector: 'app-audit-form-dialog',
  templateUrl: './audit-form-dialog.component.html',
  styleUrls: ['./audit-form-dialog.component.scss']
})
export class AuditFormDialogComponent implements OnInit {
  action:string;
  //auditdata: string[]= [];
  auditdata: any;
  local_data:any;

  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  showLoader = false;

  createdForm: UntypedFormGroup;
	profileMode: any;
	_userid: any;
	meName: any;
	showHideAdd: boolean = false;
	is_edit: boolean = false;
	showHideProfileStatus: boolean = false;
  profileStatus: any;	
  passingMessage: any;
  showHidePassingMessage: boolean = false;
	_color: any = "";

  myUser = this.service.get_loggedin_user();
	currentFileobj: any = {};
	currentLoader:boolean=false;
	showHideSubmitUpload:boolean=false;
  _isreadonly:boolean=false;
  @ViewChild('fileUpload')
  fileUploadInput: ElementRef;
  api_end_point:any
  constructor(public dialogRef: MatDialogRef<AuditFormsComponent>, @Inject(MAT_DIALOG_DATA) public viewAuditFormDetails, public formbuilder: UntypedFormBuilder, private API: ApiService, private service: MyserviceService) {
		//console.log(viewAuditFormDetails)
		this.createdForm = this.formbuilder.group({
      		audit_client_id:['',Validators.required],
			audit_form_name: ['', Validators.required],
			// cx_touch_point: ['', Validators.required],
			normalized_score: ['', Validators.required,Validators.max(5)],
			//audit_form_object: ['',Validators.required],
			"fileSource": [''],
			"file": [''],
		})
	}

	ngOnInit() {
		this.api_end_point = this.service.get_API_End_Point();
		this._userid = this.myUser.id;
		if (this.viewAuditFormDetails._element.forNew == "add") {
			this.profileMode = this.viewAuditFormDetails && this.viewAuditFormDetails.upload ? "Upload Form" : "Add Form";
			this.showHideAdd = true;
			this.showHideProfileStatus = false;
      		this._isreadonly = false;
		}
		else {
			this.is_edit = true;
			this.showHideAdd = false;
			this.showHideProfileStatus = true;
      this._isreadonly = true;
	  //console.log(this.viewAuditFormDetails)
			if (this.viewAuditFormDetails) {
				this.profileMode = "Edit Form";

				if (this.viewAuditFormDetails._typeDrodown == "All") {
					this._userid = this.viewAuditFormDetails._element.id
					this.showHideAdd = true;
					this.meName = this.viewAuditFormDetails.meName;
				}
				else {
					this._userid = this.viewAuditFormDetails._id;
					this.showHideAdd = false;
				}
				this.createdForm.controls.audit_client_id.setValue(this.viewAuditFormDetails._element.audit_client_id);
				this.createdForm.controls.audit_form_name.setValue(this.viewAuditFormDetails._element.audit_form_name);
				this.createdForm.controls.normalized_score.setValue(this.viewAuditFormDetails._element.normalized_score);
			// 	this.createdForm = this.formbuilder.group({
          	// 		audit_client_id: this.viewAuditFormDetails._element.audit_client_id,
			// 		audit_form_name: this.viewAuditFormDetails._element.audit_form_name,
			// 		// cx_touch_point: this.viewAuditFormDetails._element.cx_touch_point,
			// 		// normalized_score: this.viewAuditFormDetails._element.normalized_score,
          	// 		//audit_form_object: this.viewAuditFormDetails._element.audit_form_object
			// 	})
			 }

		}
	}
	
	closeDialog(refresh) {
		this.createdForm.reset();
		this.dialogRef.close(refresh);
	}
	saveButton(createdForm: any) {
		

		var regex = /^[A-Za-z0-9 ]+$/;
		this.currentLoader=true;

		if (createdForm.controls["audit_form_name"].value == "" || createdForm.controls["audit_form_name"].value == null) {
			this.passingMessage = "Please enter Audit Form Name*";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.currentLoader=false;
					return;
		}
		if (regex.test(createdForm.controls["audit_form_name"].value) == false) {
			this.messageDisplay = "Special Charaters not allowed*";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.currentLoader=false;
			return false;
		}
		if (createdForm.get('audit_form_name').value == "") {
			this.passingMessage = "Please enter Audit Form Name*";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.currentLoader=false;
					return;
		}
				let formData = new FormData();
				var isvisible= 1;
				let audit_client_id= this.viewAuditFormDetails._element.audit_client_id;
				let	audit_form_name= createdForm.controls.audit_form_name.value;
				let bulk_file = createdForm.get('fileSource').value;
				formData.append('audit_client_id', audit_client_id);
				//formData.append('audit_form_name', audit_form_name);
				formData.append('cx_touch_point', 'Call');
				formData.append('normalized_score', createdForm.controls.normalized_score.value);

				if(bulk_file){
					formData.append('leads', bulk_file, bulk_file.name);
				}
			
	
				if(this.viewAuditFormDetails._element.id)
				{
					var editaduit_form = audit_form_name.split(" ",1);
					var auditformname1 = editaduit_form[0]+" "+Math.floor(Math.random() * 10000);
					var query = `?audit_client_id=${audit_client_id}&audit_form_name=${auditformname1}&cx_touch_point=Call&normalized_score=${createdForm.controls.normalized_score.value}&isvisible=${isvisible}`
					//console.log(query);

					if(this.viewAuditFormDetails._element.id){
						query = query + `&id=${this.viewAuditFormDetails._element.id}`
					}
					
					
					formData.append('audit_form_name', auditformname1);
					this.API.updateAuditForm(formData, query, this.myUser.groupid,this.api_end_point).subscribe(
						(res:any) => {
							if(res.success){
							this.passingMessage = "Audit form updated successfully*";
							this.showHidePassingMessage = true;
							this._color = 'green';
							this.currentLoader=false;
								setTimeout(()=>{
									this.showHideMessage=false;
									this.closeDialog({refresh:true});
								  },4000)
								
							}
							this.showLoader = false;
						}, (err) => {
							// debugger;
							console.log(err)
						})
				}
				else{
					
					var auditformname2 = audit_form_name+" "+Math.floor(Math.random() * 10000);
					formData.append('audit_form_name', auditformname2);

					var query = `?audit_client_id=${audit_client_id}&audit_form_name=${auditformname2}&cx_touch_point=Call&normalized_score=${createdForm.controls.normalized_score.value}&isvisible=${isvisible}`
					//console.log(query);

					if (createdForm.get('fileSource').value == "") {
						this.passingMessage = "Please attached Audit Form file*";
								this.showHidePassingMessage = true;
								this._color = 'red';
								this.currentLoader=false;
								return;
					}

				let _fileDetails = createdForm.get('fileSource').value;
				let _fileName=createdForm.get('fileSource').value.name;
				let fileInput = document.getElementById('fileID') as HTMLInputElement;
				let filePath = createdForm.get('fileSource').value.name;
				//console.log(createdForm.get('fileSource').value.name);
				let allowedExtensions = /(\.xlsx)$/i;
				
				if(!allowedExtensions.exec(filePath)){
					this.passingMessage = "Please upload .xlsx extensions file only.";
					this.showHidePassingMessage = true;
					this._color = "red";
					fileInput.value = '';
					this.currentLoader=false;      
					return;
				}

					this.API.insertAuditForm(formData, query, this.myUser.groupid,this.api_end_point).subscribe(
						(res:any) => {
							if(res.success){
								this.passingMessage = "Audit form created successfully";
								this.showHidePassingMessage = true;
								this._color = 'green';
								this.currentLoader=false;
								setTimeout(()=>{
									this.showHideMessage=false;
									this.closeDialog({refresh:true});
								  },2000)
								
							}
							//this.showLoader = false;
						})
				}
		


		}

	download_file(){
		this.API.getAuditFormTemplate(this.api_end_point).subscribe(
			(res:HttpResponse<any>) => {
				let content_type = res.headers.get("content-type");
				let file_data = res.headers.get("content-disposition");
				let p = file_data.split(';')[1];
				let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
				let body = res.body;
				let blob: any = new Blob([body], { type: content_type });
				let url = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.download = file_name;
				anchor.href = url;
				// debugger;
				anchor.click();
      });
	}

	changeFile(event) {
		this.currentFileobj = {};
		this.passingMessage="";
		this.showHidePassingMessage=false;
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.xlsx)$/i;
		if(!allowedExtensions.exec(filePath)){
			//alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
			this.passingMessage="Please upload file having extensions .xlsx only.";
			this.showHidePassingMessage=true;
			this._color = "red";
		setTimeout(()=>{
			this.passingMessage = "";
			this.showHidePassingMessage=false;
			this._color = "green"
		},3000)
				return false;
		}
		const file = event.target.files[0]
    const reader = new FileReader()
    const workbook = new Excel.Workbook();
    var rowCount = 0;
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer: any = reader.result;
      workbook.xlsx.load(buffer).then(workbook => {
       // console.log(workbook, 'workbook instance')

        workbook.eachSheet((sheet, id) => {
          
          sheet.eachRow((row, rowIndex) => {
            rowCount = rowCount + 1;
           // console.log(row.values, rowIndex)
          })
        })
        if(rowCount < 2){
          this.currentFileobj = {};
          this.passingMessage="Data empty for uploaded file";
		  this.showHidePassingMessage=true;
		  this._color = "red";
          this.fileUploadInput.nativeElement.value = "";
		  this.createdForm.controls.fileSource.setValue(null);
		  this.createdForm.controls.file.setValue(null);
          setTimeout(()=>{
            this.passingMessage = "";
			this._color = "green";
			this.showHidePassingMessage=false;
          },3000);
          
        }else{
		if (event.target.files && event.target.files.length) {
			//console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.createdForm.patchValue({
				fileSource: upload_file
			});
			}
        }
        
      })
    }
		
  }
    omit_special_char(event) {
		let k;  
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

	only_number(event) {
		let k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 47 && k < 58));
	  }

	deleteAuditFrom(){
		this.currentLoader=true;
		let _modelObject = {
			"id": this.viewAuditFormDetails._element.id
		}
			this.API.deleteAuditForm(_modelObject,this.api_end_point).subscribe ((data: any) => {
				if (data.success) {					
					// alert("User updated successfully");
					this.passingMessage = "Form Deleted successfully..!";
					this.showHidePassingMessage = true;
					this._color = 'green';
					setInterval(() => {
						this.dialogRef.close({ refresh: true });
						this.currentLoader=false;
					}, 2000);	
				}
				if (data.errors) {
					//  alert(data.errors);
					this.passingMessage = data.errors;
					this.showHidePassingMessage = true;
					this._color = "red";
					this.currentLoader=false;
				}
			});
		}
	
	  clearField() {
		this.createdForm.get('audit_form_name').reset();
		this.createdForm.get('file').reset();
		this.currentFileobj="";
    	//this.createdForm.get('audit_form_object').reset();
		this.passingMessage = null;
		this.showHidePassingMessage = false;
		this._color = '';
	}

}
