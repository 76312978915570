import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatSnackBar } from '@angular/material/snack-bar';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { Utils } from '../utils';

import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-callrecordsdash',
	templateUrl: './callrecordsdash.component.html',
	styleUrls: ['./callrecordsdash.component.scss']
})
export class CallrecordsdashComponent implements OnInit {

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	currentTitle: string = "callrecords";
	maxDate = new Date();
	StartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	current_url: string = null;

	table_raw_data = [];

	table_columns_arr = ['call_id', 'agent_name', 'cust_name', 'cust_number', 'call_type', 'start_time', 'end_time', 'url'];
	excel_column_arr = [
		{
			key: "call_id",
			title: "Call Id"
		},
		{
			key: "agent_name",
			title: "Agent"
		},
		{
			key: "cust_name",
			title: "Customer"
		},
		{
			key: "cust_number",
			title: "Number"
		},
		{
			key: "call_type",
			title: "Call Type"
		},
		{
			key: "start_time_full",
			title: "Call Start Time"
		},
		{
			key: "end_time_full",
			title: "Call End Time"
		},
		{
			key: "url",
			title: "Call Url"
		}
	]
	columns_filter: any = [
		{ "key": "agent_name", "title": "Agent" },
		{ "key": "cust_name", "title": "Customer" },
		{ "key": "cust_number", "title": "Number" }
	]
	dataSource = new MatTableDataSource(this.table_raw_data);
	api_end_point:any;
	constructor(
		private utils: Utils,
		private API: ApiService,
		private _snackBar: MatSnackBar,
		private myService: MyserviceService
	) { }

	ngOnInit(): void {
		this.api_end_point = this.myService.get_API_End_Point();
		this.refreshData();
	}

	change_date_status(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.StartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			}
		} else {
			this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = undefined;
				alert("Start date should be less than end date");
			}

		}
	}

	refreshData() {
		// alert('Refreshing Data');
		let my_team = this.myService.getOnlyMyTeamIds();
		let my_details = this.myService.getMyDetails();
		if (my_team.length) {
			let my_teamIds = _.pluck(my_team, 'id');
			if (!my_details) {
				alert('My details not found');
			}
			my_teamIds = my_teamIds.concat(my_details.id);
			// console.log('total team', my_teamIds);
			if (my_teamIds.length) {
				let obj = {
					"start_date": this.utils.localToUTC(this.StartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'),
					"end_date": this.utils.localToUTC(this.EndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'),
					"for_users": my_teamIds
				}
				this.API.genericCallsDataForUsers(obj,this.api_end_point).subscribe(
					(res: any) => {
						// console.log('after response', res);
						this.table_raw_data.length = 0;
						if (res.success) {
							let table_arr = [];
							let raw_response = res.success;
							for (var i in res.success) {
								if (res.success[i]['calls'] && res.success[i]['calls'].length) {
									table_arr = table_arr.concat(res.success[i]['calls']);
								}
							}
							if (table_arr.length) {
								for (var k in table_arr) {
									let agent_obj = raw_response[table_arr[k]['user_id']]['agent_obj'];
									this.table_raw_data.push({
										"call_id": table_arr[k]['call_id'],
										"agent_name": agent_obj.name,
										"cust_name": table_arr[k]['cust_name'],
										"cust_number": table_arr[k]['cust_number'],
										"call_type": this.utils.getCallTypeNames(table_arr[k]['event_type']),
										"start_time": this.utils.utcToLocal(table_arr[k]['call_start'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm A'),
										"start_time_full": this.utils.utcToLocal(table_arr[k]['call_start'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'),
										"end_time": this.utils.utcToLocal(table_arr[k]['call_end'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm A'),
										"end_time_full": this.utils.utcToLocal(table_arr[k]['call_end'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'),
										'url': (table_arr[k]['url']) ? table_arr[k]['url'] : null
									})
								}
								// console.log('raw data', this.table_raw_data);
							} else {
								this._snackBar.open('No Data found', 'close', {
									duration: 2000,
								});
							}
							this.refreshTable(this.table_raw_data);
							// console.log('final table data', table_arr);
						}
						if (res.errors) {
							this._snackBar.open(res.errors, 'close', {
								duration: 2000,
							});
						}
					}, (err) => {
						this._snackBar.open('Error in fetching data', 'close', {
							duration: 2000,
						});
					}
				)
			} else {
				this._snackBar.open('Team data not found', 'close', {
					duration: 2000,
				});
			}
		} else {
			this._snackBar.open('My team details not found', 'close', {
				duration: 2000,
			});
		}
	}

	refreshTable(arr) {
		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
	}
	filtered_data(val) {
		if (val == 'NULL FILTER') {
			this.refreshTable(this.table_raw_data)
		} else {
			var arr = _.filter(this.table_raw_data, function (row) {
				var result = false;
				for (var i in val) {
					if (row.hasOwnProperty(i) && (val[i].indexOf(row[i]) > -1)) {
						result = true;
					}
				}
				return result;
			});
			// console.log('printing arr', arr);
			this.refreshTable(arr);
		}
	}

	exportExcel() {
		let table_data = this.dataSource.data;
		// console.log('printing standard columns', this.standard_columns_mapper);
		// return;
		//console.log('Table data', table_data);
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Call Records');
			worksheet.addRow(_.pluck(this.excel_column_arr, 'title'));
			table_data.forEach(d => {
				let arr = [];
				for (var i in this.excel_column_arr) {
					if (d.hasOwnProperty(this.excel_column_arr[i]['key'])) {
						arr.push(d[this.excel_column_arr[i]['key']])
					} else {
						arr.push("")
					}
				}
				let row = worksheet.addRow(arr);
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'CallRecordsReport.xlsx');
			})
		}
	}
	selectCall(url:string) {
		this.current_url = url;
	}
	print_row(row) {
		//console.log('row', row);
	}
}
