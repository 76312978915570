import { Component, OnInit, ElementRef, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MyserviceService} from "../../myservice.service";
import { Title, Meta } from '@angular/platform-browser';


import { MatDialog } from '@angular/material/dialog';import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContiinexCreateCampaignPageComponent } from '../contiinex-create-campaign-page/contiinex-create-campaign-page.component';
import { ApiService } from 'src/app/api.service';
import * as XLSX from 'xlsx';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-contiinex-campaign-page',
  templateUrl: './contiinex-campaign-page.component.html',
  styleUrls: ['./contiinex-campaign-page.component.scss']
})
export class ContiinexCampaignPageComponent implements OnInit {

  currentLoader:boolean=false;
  user: any = this._service.get_loggedin_user();
  displayedColumnsCampaigan: string[] = ['id', 'campaign_name','template_name','language_code', 'scheduled_at','created_at', 'status', 'data_loaded', 'data_sent', 'data_failed', 'downloadReports'];

  today = moment().format('DD-MMM-YYYY');
	// months_filter: any=[{
  //   name:'Mar-2022',value:'Mar-2022'
  // },
  // {
  //   name:'Apr-2022',value:'Apr-2022'
  // }] ;
	// selected_month:any;
  isCampbtnDisable: boolean = false;

  showHideCampaignList:boolean=false;
 dataSourceCampaign: any = new MatTableDataSource();
@ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
@ViewChild(MatSort) tableSort: MatSort;
selectedDDl:any="WhatsApp";
wa_number:any="";
_agentID:any="";

api_end_point:any;
currentTitle = 'contiinex-campaign';
currentUrl:any;
  constructor(private metaTagService: Meta,private titleService:Title,private _service:MyserviceService,public sanitizer: DomSanitizer,	private dialog: MatDialog,private API:ApiService,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if (this._service && this._service.get_loggedin_user() &&
      (this._service.get_loggedin_user().businessType == 'WABA') && this._service.get_loggedin_user().agentid) {
   
    }
    if (this._service.get_loggedin_user()) {   
      this.wa_number = String(this._service.get_loggedin_user().phone);
      this._agentID=this._service.get_loggedin_user().agentid;
    } 
this.getListTable();

  }

getListTable(){
  this.currentLoader=true;    
let waNumber=this.wa_number.replace('+','');
let agentUId=this._agentID;
  this.API.getCampaignListDetails(waNumber,agentUId,this.api_end_point).subscribe((res: any) => {
if(res.status== "success"){  
    this.dataSourceCampaign = new MatTableDataSource(res.data);
  this.dataSourceCampaign.paginator = this.paginator;
  this.dataSourceCampaign.sort = this.tableSort;
  this.currentLoader=false;
}
else{
  this.currentLoader=false;
}
  });
}

refreshBtn(){
  this.getListTable();
}


  ddlChange(val:any){
    this.showHideCampaignList=false;;
   if(val=="Call"){
     this.showHideCampaignList=true;
   } 
  }
  openCreateCampaign(){
    this.refreshBtn();
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(ContiinexCreateCampaignPageComponent, {
			width: '55%',
      height:'75%',
			hasBackdrop: false,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
        this.refreshBtn();
			}		
			this.isCampbtnDisable = false;
		});
  }


  applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSourceCampaign.filter = filterValue;
	
	}
  
	change_selectedMonth(e) {
		// this.selected_month = _.find(this.months_filter, (obj) => {
		// 	return obj.value == e.value;
		// })
	}

  downloadReport(obj: any){
    let _wa_number=this.wa_number.replace('+','');
    let _agentUID=this._agentID;
    this.API.getCampaignDownloadReport(obj,_wa_number,_agentUID,this.api_end_point).subscribe((res: any) => {
      if(res.status=="success"){
        let _dataList=res.data; 
        const workBook = XLSX.utils.book_new(); 
        const workSheet = XLSX.utils.json_to_sheet(_dataList);    
        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); 
        XLSX.writeFile(workBook, 'DownloadExcel.xlsx'); 
        }
    });
  }
  convertDTime (isoTime) {
    let a=isoTime.split('T')
    let b=a[0];
    let c=a[1];
      var hours   = parseInt(c.substring(0, 2), 10),
          minutes = parseInt(c.substring(3, 5), 10),
          ampm    = 'am';
    
      if (hours == 12) {
        ampm = 'pm';
      } else if (hours == 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
      }
    
   // return  b + " / " + hours + ':' + minutes + ' ' + ampm;
    var isoDateTime = new Date(isoTime);
let localDateTime = b  + " / " + isoDateTime.toLocaleTimeString();// isoDateTime.toLocaleDateString() +
return localDateTime;
    }

}
