<div class="container-fluid">
  <div class="row p-3">
    <div class="col-12">
      <h4 class="mb-4">Transfer Data</h4>
    </div>
  </div>

  <div class="row px-3">
    <!-- From Table -->
    <div class="col-6">
      <label class="mb-0 fw-bold">From</label>
      <mat-card appearance="outlined" class="h-100">
        <div class="form-group mb-3">
          <div class="d-flex justify-content-end mb-2">
         
            <div class="search-box justify-content-end d-flex">
              <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" (keyup)="applySearchFilter1($event.target.value)">
              </mat-form-field>
            </div>
          </div>
          <div class="overflow-auto custom-scrollbar custom-table-container">
          <mat-table [dataSource]="dataSourceA">
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef style="flex: 0 0 60px">Select</mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex: 0 0 60px">
                <mat-checkbox [(ngModel)]="element.from_selected" (change)="from_tableagents(element,$event)" 
                            [disabled]="element.to_selected"></mat-checkbox>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="agentName">
              <mat-header-cell *matHeaderCellDef>Agent Name</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataValue">
              <mat-header-cell *matHeaderCellDef style="flex: 0 0 70px">Data</mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex: 0 0 70px">{{element.data}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsA"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsA"></mat-row>
          </mat-table>
          </div>
          <mat-paginator #paginatorA="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </mat-card>
    </div>

    <!-- To Table -->
    <div class="col-6">
      <label class="mb-0 fw-bold">To</label>
      <mat-card appearance="outlined" class="h-100">
        <div class="form-group mb-3">
          <div class="d-flex justify-content-end mb-2">
            
            <div class="search-box justify-content-end d-flex">
              <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" (keyup)="applySearchFilter2($event.target.value)">
              </mat-form-field>
            </div>
          </div>
          <div class="overflow-auto custom-scrollbar custom-table-container">
          <mat-table [dataSource]="dataSourceB">
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef style="flex: 0 0 60px">Select</mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex: 0 0 60px">
                <mat-checkbox [(ngModel)]="element.to_selected" (change)="to_agents(element,$event)" 
                            [disabled]="element.from_selected"></mat-checkbox>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="agentName">
              <mat-header-cell *matHeaderCellDef>Agent Name</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataValue">
              <mat-header-cell *matHeaderCellDef style="flex: 0 0 70px">Data</mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex: 0 0 70px">{{element.data}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsB"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsB"></mat-row>
          </mat-table>
          </div>
          <mat-paginator #paginatorB="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="row px-3">
    <div class="col-12">
      <div class="d-flex justify-content-end gap-2 mt-3 mb-4">
        <button mat-raised-button (click)="cancelBtn()">
          <i class="fas fa-times me-1"></i>
          CANCEL
        </button>
        <button mat-raised-button  (click)="transferDoneBtn()">
          <i class="fas fa-check me-1"></i>
          DONE
        </button>
      </div>
    </div>
  </div>

  <div id="cover-spin" *ngIf="currentLoader"></div>
</div>
