import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MyserviceService } from 'src/app/myservice.service';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import {
  Web, Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions
} from "../../../assets/sip.js";
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';

@Component({
  selector: 'app-start-dial-dialog',
  templateUrl: './start-dial-dialog.component.html',
  styleUrls: ['./start-dial-dialog.component.scss']
})
export class StartDialDialogComponent implements OnInit {

  server = "wss://edge.sip.onsip.com";
  options: any;
  $player: HTMLAudioElement;
  transportOptions: any;
  showHideCallPad: boolean = false;
  showHideStartCall: boolean = false;
  showHideStartCallButton: boolean = true;
  showHideConnecting: boolean = false;
  showHide_Connected: boolean = false;
  subscription: Subscription;
  callStatus: any;
  allStatus: string[] = ['establishing', 'accepted'];
  callFlowJsonSections: any[] = [];
  user: any = this.myservice.get_loggedin_user();
  sessionStarted: boolean = false;
  currentFileobj: any = {};
  sioPushUrl: any = "";
  questionnaire: any = "";
  socketId: any = "";
  socketStatusBeforeCheck: any = "";
  socket: any = "";
  customerInfo: any = "";
  widgetUser: any = "";
  subscriptionDial: Subscription;
  _countLeadLength: any;
  _countfollowUpLength: any;
  _auditScore: any;
  _auditTime: any;
  _nextBooked: any;
  _availableSlots: any;
  breakoption:any="breakoption";
  api_end_point:any;
  @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.$player = ref.nativeElement;
  }
  _caller_id: any = "";
  c2cEndPoint:any="";
  myUser:any;
  isAppAgent: boolean = false;
  constructor(private myservice: MyserviceService, private API: ApiService, private utils: Utils, private router: Router
  ) {
    this.subscription = this.myservice.getCallStatus().subscribe(obj => {
      this.callStatus = obj;
      if (obj) {
        console.log("home12345", obj)
        this.callStatus = obj;
        if (this.callStatus.callFlowJsonSections) {
          this.callFlowJsonSections = this.callStatus.callFlowJsonSections;

          console.log(78, this.callFlowJsonSections)
        }

        if (obj.status == 'establishing') {
          this.showHideConnecting = true;
          this.currentFileobj = {};
        }

        if (obj.customerInfo && obj.status != 'end_call' && obj.status != 'terminated') {
          this.showHideConnecting = false;
          this.showHide_Connected = true;
		  this.showHideCallPad = false;
        }
        // if(obj.status == 'accepted'){
        //   setTimeout(()=>{
        //     this.showHideConnecting = false;
        //     this.showHide_Connected = true;
        //   },10000)
        // }
        if (obj.status == 'end_call_agent') {
          this.currentFileobj = {};
          // this.dialogRef.close();
          this.showHideConnecting = false;
          this.showHide_Connected = false;
			this.showHideCallPad = false;
        }
      } else {
        this.callStatus = ""

      }
    });
  }

  ngOnInit() {
	this.api_end_point = this.myservice.get_API_End_Point();
    this.myUser = this.myservice.get_loggedin_user();
	if(!this.myUser.sip_id){
		this.myUser.sip_id = 'krunal@smarterbizkrunal.onsip.com';
		this.isAppAgent = true;
	}
	if(!this.user.sip_id){
		this.user.sip_id = 'krunal@smarterbizkrunal.onsip.com';
		this.isAppAgent = true;
	}
    var interval_id = window.setInterval(() => { }, 99999);
    for (var i = 0; i < interval_id; i++) {
      window.clearInterval(i);
    }
    this.myservice.clearCallStatus();
    //debugger;
    let stateSettings: any = this.myservice.get_stage_settings();

    let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
    this._caller_id = _inNumberOneDefinition[0].definition;
    console.log(this._caller_id);

    if (stateSettings && stateSettings.extraoptions) {
      for (let opt of stateSettings.extraoptions) {
if(opt.name=="c2cendpoint"){
  console.log(opt);
  this.c2cEndPoint=opt.definition;



}
        if (opt.name == "biometric" && opt.definition == 'true') {
          if (this.user.isVisonEnabled != true) {
            this.router.navigate(['/agentPluginPC/face']);
            return;
          }
        }
        if (opt.name == "siopushurl") {
          this.sioPushUrl = opt.definition;
          console.log(72, this.sioPushUrl);
          //this.socket = io(this.sioPushUrl);
        }
      }
    }
    //this.initSocket();
    this.getHomeData();
    //this.getDashboardList();
    this.getAgentSlot();
    this.getContacted();

    this.subscriptionDial = this.myservice.getCallStatus().subscribe(obj => {
      console.log("home1", obj)
      if (obj) {
        if (obj.dialing == 2) {
          if (!this.user.sip_id) {
            alert('Desktop Calling Not Enabled');
            return;
          }
		  if(this.isAppAgent){
			  var dataGsm: any = {
				  "customer_number": obj.dialNum.includes('+') ? obj.dialNum : ("+91" + obj.dialNum),
				  "agent_number": this.user.phone,
				  "user_id": this.user.id,
				  "caller_id": this._caller_id,
				  "networktype": "WIFI",
				  "pingdelay": "45"
			  }
			 

			  console.log(dataGsm)
			  this.showHideConnecting = true;
			  this.currentFileobj = {};
			  this.API.sipOutboundCall(dataGsm, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
				  //debugger;
				 // console.log(res)
					// this.showHideStartCallButton = true;
					
				  console.log("Establishing")
				  var updateObj = this.callStatus || {};
				  let stateSettings: any = this.myservice.get_stage_settings();
				  if (stateSettings && stateSettings.extraoptions) {
					  for (let opt of stateSettings.extraoptions) {
						  if (opt.name == "questionnaire") {
							//   var questions = [{ "q": "What is this call regarding?", "at": "RB", "rb_data": "['Enquiry', 'Complaint']", "Enquiry": [{ "q": "Which product category you are looking for?", "at": "DD", "dd_data": "['Entertainment', 'Communications']", "Communications": [{ "q": "Which product information you want?", "at": "SDD", "sdd_data": "['Telephones','Cell phones','Desktop computers','Laptops','Printers','Modem','Network card','Bluetooth devices','Wi-Fi Router','PDA']", "action_type": "PRODUCT_NAME_INPUT" }, { "q": "Where can I send the product informations to you?", "at": "MSDD", "msdd_data": "['SMS','WHATSAPP','EMAIL']", "action_type": "EXTERNAL_TRIGGER", "next_action": { "post_url": "/api/v2/incallactivity" } }, { "q": "Can you please share your Whatsapp Number?", "at": "FN", "action_type": "PHONE_NO_INPUT" }, { "q": "Attach product details", "at": "ATTACHMENT", "action_type": "LINK_INPUT" }, { "q": "When can we expect you to visit our store?", "at": "DATETIME" }, { "q": "Document(s) required", "at": "CB", "cb_data": "['PAN Card','Aadhaar card','Passport','Driving License','Voters ID','None of the above']" }, { "q": "Is the information provided is helpful to you?", "at": "YN" }, { "q": "Please provide your valuable feedback", "at": "RT" }] }] }];
							  var questions = JSON.parse(opt.definition)
							  this.questionnaire = questions;
							  this.callFlowJsonSections = [{ title: "", description: "", mandatory: "n", child: this.questionnaire }];
							  // var updateObj = this.callStatus || {};
							  updateObj.callFlowJsonSections = this.callFlowJsonSections;
							  // this.myservice.updateCallStatus(updateObj);
							 // console.log(78, this.questionnaire);
						  }
					  }
				  }
				  console.log(stateSettings)
				  if (updateObj && updateObj.dialing && updateObj.dialing == 3) {
					  updateObj.dialing = undefined;
					  updateObj.dialNum = undefined;
				  }
				  updateObj.status = 'establishing';
				  updateObj.callStart = moment().utc();
				  this.myservice.updateCallStatus(updateObj);
				  this.counter = 0;
				  this.startCallDial()
			  }, (err) => {
				  this.showHideConnecting = false;
				  //console.log(err)
			  }
			  )
		  }else{
			  var data: any = {
				  "customer_number": "+91" + obj.dialNum,
				  "agent_number": this.user.phone,
				  "user_id": this.myUser.id,
				  "caller_id": this._caller_id,
				  "networktype": "WIFI",
				  "pingdelay": "45"
			  }
			  if (this.user.sip_id) {
				  data.sip_id = this.user.sip_id
			  }



			  this.API.sipOutboundCall(data, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
				 // debugger;
				  //console.log(res)

			  }, (err) => {
				  console.log(err)
			  }
			  )



          // this.API.siptogsmcall(data, this.user.server_url).subscribe(
          //   (res: any) => {
          //     console.log(res)

          //   }, (err) => {
          //     console.log(err)
          //   }
          // )
		  }
          var updateObj = this.callStatus;
          updateObj.dialing = 3;
          this.myservice.updateCallStatus(updateObj);
          // var data = {
          //   "telco_partner": "TWILIO",
          //   "agent_number": "sip:" + this.user.sip_id,
          //   "customer_number": "+91" + obj.dialNum,
          //   "cli": "+15512617144"
          // }

          
        }
      }
    });
	this.user.sip_id
    if (!this.user.sip_id) {
      alert('Desktop Calling Not Enabled');
      return;
    }
    this.API.getSipUserByPhone(this.user.sip_id,this.api_end_point).subscribe(
      (res: any) => {
        const sipUser = res.success;
        const displayName = "SIP.js Demo";
        const simpleUserDelegate: Web.SimpleUserDelegate = {
          onCallCreated: (): void => {
            console.log(`[${displayName}] Call created`);

          },
          onCallReceived: (): void => {
            console.log(`[${displayName}] Call received`);

          },
          onCallAnswered: (): void => {
            console.log(`[${displayName}] Call answered`);

          },
          onCallHangup: (): void => {
            console.log(`[${displayName}] Call hangup`);

          },
          onCallHold: (held: boolean): void => {
            console.log(`[${displayName}] Call hold ${held}`);

          }
        };
        const simpleUserOptions: Web.SimpleUserOptions = {
          delegate: simpleUserDelegate,
          media: {
            remote: {
              audio: this.$player
            }
          },
          userAgentOptions: {
            displayName
          }
        };
        this.options = Web.SimpleUserOptions = {
          aor: "sip:echo@sipjs.onsip.com", // caller
          media: {
            constraints: { audio: true, video: false }, // audio only call
            remote: { audio: this.$player } // play remote audio
          }
        };
        const simpleUser = new Web.SimpleUser(this.server, simpleUserOptions);
        // simpleUser.connect()
        // .then(() => simpleUser.call("sip:krunal@smarterbizkrunal.onsip.com"))
        // .catch((error: Error) => {
        //   console.log(error)
        // });
        const transportOptions = {
          server: "wss://edge.sip.onsip.com"
        };
        if (this.user && this.user.sip_id && this.user.sip_id.length && this.user.sip_id.indexOf(".knowlarity.com") != -1) {
          transportOptions["server"] = "wss://webrtchablr.knowlarity.com/ws";
        }
        const uri = UserAgent.makeURI("sip:" + this.user.sip_id);
        function onInvite(invitation) {
          //console.log("callcame")
          invitation.accept();
        }
        const userAgentOptions: UserAgentOptions = {
          authorizationPassword: sipUser.sip_password,
          authorizationUsername: sipUser.sip_auth,
          // delegate: {onInvite},
          transportOptions,
          uri
        };
        const userAgent = new UserAgent(userAgentOptions);
        var tplayer = this.$player;
        var this2 = this;

        //console.log(this2)
        userAgent.delegate = {
          onInvite(invitation: Invitation): void {

            // An Invitation is a Session
            const incomingSession: Session = invitation;

            // Setup incoming session delegate
            incomingSession.delegate = {
              // Handle incoming REFER request.
              onRefer(referral: Referral): void {
                // ...
              }
            };

            const remoteStream = new MediaStream();
            function setupRemoteMedia(session: Session) {
             // console.log(this2)
              session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
                if (receiver.track) {
                  remoteStream.addTrack(receiver.track);
                }
              });
              this2.$player.srcObject = remoteStream;
              this2.$player.play();
            }
            this2.subscription = this2.myservice.getCallStatus().subscribe(obj => {
             // console.log("home12", obj);
              this2.callStatus = obj;
              if (obj) {
               // console.log("home123", obj)
                this2.callStatus = obj;

                //console.log(this2.allStatus.includes(this2.callStatus.status))
                if (obj.status == 'end_call_agent') {
                  var salesSts = {
                    user_id: this2.user.id,
                    contact_person: {
                      number: this2.customerInfo.customer_number
                    },
                    description: "",
                    order_potential: "",
                    status: "NEW DATA",
                    lat: "22.0858725",
                    long: "82.1879959",
                    start_time: obj.callStart || moment().utc(),
                    substatus1: "",
                    substatus2: "",
                    machine_status: "",
                    wrapup: 0,
                    lead_source: '',
                    appointment_id: "",
                    call_end_time: obj.callEnd || moment().utc(),
                    customkvs: ""
                  } as any;

                  if (this2.callStatus.callFlowJsonSections) {
                    var finalObj = {
                      experience: this2.callStatus.QA
                    };
                    salesSts.extranotes = JSON.stringify(finalObj)
                  }

                  //console.log(this2);
                  this2.API.salesStatus(salesSts, this2.user.server_url).subscribe(
                    (res: any) => {
                     // console.log(res)
                      this2.getContacted();
                    }, (err) => {
                     // console.log(err)
                    }
                  )


                  this2.myservice.clearCallStatus();
                  this2.subscription.unsubscribe();
                  this2.customerInfo = "";
                  this2.widgetUser = "";
                  var interval_id = window.setInterval(() => { }, 99999);
                  for (var i = 0; i < interval_id; i++) {
                    window.clearInterval(i);
                  }

                }
                if (obj.status == 'end_call_agent') {
                  incomingSession.bye();
                }
              } else {


              }
            });
            // Handle incoming session state changes.
            incomingSession.stateChange.addListener((newState: SessionState) => {
              switch (newState) {
                case SessionState.Establishing:
                  // Session is establishing.
                 // console.log("Establishing")
                  var updateObj = this2.callStatus || {};
                  let stateSettings: any = this2.myservice.get_stage_settings();
                  if (stateSettings && stateSettings.extraoptions) {
                    for (let opt of stateSettings.extraoptions) {
                      if (opt.name == "questionnaire") {
						var questions = JSON.parse(opt.definition)

                        this2.questionnaire = questions;
                        this2.callFlowJsonSections = [{ title: "", description: "", mandatory: "n", child: this2.questionnaire }];
                        // var updateObj = this2.callStatus || {};
                        updateObj.callFlowJsonSections = this2.callFlowJsonSections;
                        // this2.myservice.updateCallStatus(updateObj);
                       // console.log(78, this2.questionnaire);
                      }
                    }
                  }
                  //console.log(stateSettings)
                  if (updateObj && updateObj.dialing && updateObj.dialing == 3) {
                    updateObj.dialing = undefined;
                    updateObj.dialNum = undefined;
                  }
                  updateObj.status = 'establishing';
                  updateObj.callStart = moment().utc();
                  this2.myservice.updateCallStatus(updateObj);
                  this2.counter = 0;
                  this2.startCallDial()
                  break;
                case SessionState.Established:
                 // console.log("Established")
                  setupRemoteMedia(invitation);
                  var updateObj = this2.callStatus || {};
                  updateObj.status = 'accepted';
                  this2.myservice.updateCallStatus(updateObj);
                  break;
                case SessionState.Terminated:
                 // console.log("Terminated");
                  var updateObj = this2.callStatus || {};
                  updateObj.status = 'terminated';
                  updateObj.callEnd = moment().utc();
                  this2.myservice.updateCallStatus(updateObj);
                  // Session has terminated.
                  break;
                default:
                  break;
              }
            });

            // Handle incoming INVITE request.
            let constrainsDefault: MediaStreamConstraints = {
              audio: true,
              video: false,
            }

            const options: InvitationAcceptOptions = {
              sessionDescriptionHandlerOptions: {
                constraints: constrainsDefault,
              },
            }

            incomingSession.accept(options)
          }
        };
        const registerer = new Registerer(userAgent);

        userAgent.start().then(() => {
          registerer.register();
        });
      }, (err) => {
        console.log(err)
      }
    )
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  endCall() {

	  var QA = [];
	  for (let section of this.callFlowJsonSections) {
		  var obj1: any = [];
		  for (let child of section.child) {
			  var temp1: any = { "Q:": child.q, "A:": child.answer || "" };
			  if (child.action_type) {
				  temp1["action_type:"] = child.action_type
			  }

			  obj1.push(JSON.stringify(temp1))
			  if (child[child.answer]) {
				  for (let child2 of child[child.answer]) {
					  var temp2: any = { "Q:": child2.q, "A:": child2.answer || "" };
					  if (child2.action_type) {
						  temp2["action_type:"] = child2.action_type
					  }
					  obj1.push(JSON.stringify(temp2))
					  if (child2[child2.answer]){
						  for (let child3 of child2[child2.answer]) {
							  var temp3: any = { "Q:": child3.q, "A:": child3.answer || "" };
							  if (child3.action_type) {
								  temp3["action_type:"] = child3.action_type
							  }
							  obj1.push(JSON.stringify(temp3))
						  }
					  }
					  
				  }

			  }

		  }
		  QA = obj1
	  }
    //console.log(QA);
    var updateObj = this.callStatus;
    updateObj.status = 'end_call_agent';
    if (this.callFlowJsonSections) {
      updateObj.callFlowJsonSections = this.callFlowJsonSections
      updateObj.QA = QA
    }
	if(this.isAppAgent && updateObj.status == 'end_call_agent'){
		
			var salesSts = {
				user_id: this.user.id,
				contact_person: {
					number: this.customerInfo.customer_number
				},
				description: "",
				order_potential: "",
				status: "NEW DATA",
				lat: "22.0858725",
				long: "82.1879959",
				start_time: this.callStatus.callStart || moment().utc(),
				substatus1: "",
				substatus2: "",
				machine_status: "",
				wrapup: 0,
				lead_source: '',
				appointment_id: "",
				call_end_time: this.callStatus.callEnd || moment().utc(),
				customkvs: ""
			} as any;

			if (this.callStatus.callFlowJsonSections) {
				var finalObj = {
					experience: this.callStatus.QA
				};
				salesSts.extranotes = JSON.stringify(finalObj)
			}

		//	console.log(this);
			this.API.salesStatus(salesSts, this.user.server_url).subscribe(
				(res: any) => {
				//	console.log(res)
					this.getContacted();
				}, (err) => {
					console.log(err)
				}
			)
			this.showHide_Connected = false;
			this.showHideConnecting = false;
			this.showHideCallPad = false;
			this.myservice.clearCallStatus();
			this.customerInfo = "";
			this.widgetUser = "";
			var interval_id = window.setInterval(() => { }, 99999);
			for (var i = 0; i < interval_id; i++) {
				window.clearInterval(i);
			}

		}else{
		this.showHide_Connected = false;
		this.showHideConnecting = false;
		this.myservice.updateCallStatus(updateObj);
		}
	
    
  }

  removeNum() {
    let _num = (document.getElementById('code') as HTMLFormElement).value;
    _num = _num.substring(0, _num.length - 1);
    (document.getElementById('code') as HTMLFormElement).value = _num;
    this.myservice.clearCallStatus();
    this.showHideStartCallButton = true;
	this.showHideCallPad = false;
  }
  clickNumBtn(num: any) {

    let _numLength = ((document.getElementById('code') as HTMLFormElement).value).length;
    if (_numLength == 12) {
      (document.getElementById('code') as HTMLFormElement).value;
      return false;
    }
    (document.getElementById('code') as HTMLFormElement).value = (document.getElementById('code') as HTMLFormElement).value + num.toString();
    //console.log((document.getElementById('code') as HTMLFormElement).value);

    var updateObj = this.callStatus;
    updateObj.dialNum = (document.getElementById('code') as HTMLFormElement).value;
    updateObj.dialed = false;
    this.myservice.updateCallStatus(updateObj);
  }

  startCallLoading() {
    this.showHideCallPad = true;
    this.showHideStartCallButton = false;
    this.showHideStartCall = false;
    // setTimeout( () => {
    //    this.showHideConnecting=false;
    //    this.showHideStartCallButton=false;
    //    this.showHideStartCall=false;


    //   //  setTimeout( () => {
    //   //  this.showHide_Connected=true;
    //   //  this.showHideConnecting=false;
    //   //  this.showHideStartCallButton=false;
    //   //  this.showHideStartCall=false;
    //   // }, 8000 )

    //   }, 6000 )
    var updateObj = this.callStatus;
    if (this.callStatus && this.callStatus.dialing && this.callStatus.dialing == 1) {
      updateObj.dialing = 2;
      this.myservice.updateCallStatus(updateObj);
    }
    if (this.callStatus && !this.callStatus.dialing) {
      updateObj.dialing = 1;
      this.myservice.updateCallStatus(updateObj);
    }


  }

  mddValueChange(event, index, childIndex, mddIndex) {
   // console.log(event.checked, index, childIndex, mddIndex)
    if (event.checked) {
      var dd_data = this.callFlowJsonSections[index].child[childIndex].cb_data;
      var total = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer : "";
      if (total == "") {
        total = total + dd_data[mddIndex];
      } else {
        total = total + "," + dd_data[mddIndex];
      }
      this.callFlowJsonSections[index].child[childIndex].answer = total;
      //console.log(this.callFlowJsonSections[index].child[childIndex].answer)
    } else {
      var array = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : []
     // console.log(mddIndex, array)
      var findIndex = 0;
      for (var i = 0; i < array.length; i++) {
        if (array[i] == this.callFlowJsonSections[index].child[childIndex].cb_data[mddIndex]) {
          findIndex = i;
          array.splice(findIndex, 1);
          this.callFlowJsonSections[index].child[childIndex].answer = array.toString()
          break;
        }
      }
     // console.log(array)
    }
    var arrayPrev = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : [];
    var arrayDD = this.callFlowJsonSections[index].child[childIndex].cb_data;
    var finalArray = [];
    for (var j = 0; j < arrayDD.length; j++) {
      for (var k = 0; k < arrayPrev.length; k++) {
        if (arrayDD[j] == arrayPrev[k]) {
          finalArray.push(arrayDD[j]);
        }
      }
    }
   // console.log(finalArray);
    this.callFlowJsonSections[index].child[childIndex].answer = finalArray.toString();
    var updateObj = this.callStatus;
    if (this.callFlowJsonSections) {
      updateObj.callFlowJsonSections = this.callFlowJsonSections
    }
    this.myservice.updateCallStatus(updateObj);
  }
  change_date(event: MatDatepickerInputEvent<Date>, index, childIndex) {
    //console.log(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value), index, childIndex);
    this.callFlowJsonSections[index].child[childIndex].answer = event.value;
  }

  change_time(event: any, index, childIndex) {
   // console.log(event.target.value, index, childIndex);
    var dateAdded = this.callFlowJsonSections[index].child[childIndex].answer;
    // dateAdded = moment(dateAdded);
    var timeAdded = event.target.value;
    var splitDate = timeAdded.split(':')
    var finalDate = moment(dateAdded).add(Number(splitDate[0]), 'hours').add(Number(splitDate[1]), 'minutes')
    //console.log(finalDate.format());
  }


  changeFile(event, index) {
    this.currentFileobj = {};

    if (event.target.files && event.target.files.length) {
     // console.log(event.target.files);
      this.currentFileobj = event.target.files[0];
      let formData = new FormData();
      formData.append('image', event.target.files[0], event.target.files[0].name);
      this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
        (res: any) => {
          this.callFlowJsonSections[0].child[index].answer = res.url;
        }, (err) => {
          console.log(err)
        }
      )
    }
  }
changeFileLevel2(event, index1,index2, index3, answer1, answer2) {
    this.currentFileobj = {};

    if (event.target.files && event.target.files.length) {
    //  console.log(event.target.files);
      this.currentFileobj = event.target.files[0];
      let formData = new FormData();
      formData.append('image', event.target.files[0], event.target.files[0].name);
      this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
        (res: any) => {
			  this.callFlowJsonSections[0].child[index1].answer1[index2].answer2[index3].answer = res.url;
        }, (err) => {
          console.log(err)
        }
      )
    }
  }

  initSocket() {
    this.socket.on('connect', () => {
    //  console.log(this.socket);
      this.socketId = this.socket.id;

      this.socket.emit("registerme", this.user.id);
      this.socket.on("registerme-ack", (msg: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
       // console.log("socket-registerme-", msg)
      });
      this.socket.on("pushnotif", (type: any, message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
       // console.log("pushnotif", message)
        if (type == 'system alert') {
			this.showHideConnecting = false;
          this.customerInfo = JSON.parse(message).data;
          var updateObj = this.callStatus;
          updateObj.customerInfo = this.customerInfo;
          this.myservice.updateCallStatus(updateObj);
          var interval_id = window.setInterval(() => { }, 99999);
          for (var i = 0; i < interval_id; i++) {
            window.clearInterval(i);
          }
		  if (!this.isAppAgent && updateObj.dialNum == undefined){
			this.fetchUser(this.customerInfo.customer_number)
		  }
        //   setInterval(() => {
		// 	  if (!this.isAppAgent && updateObj.dialNum == undefined){
		// 		  this.fetchUser(this.customerInfo.customer_number)
		// 	  }
            
        //   }, 3000)
        }
        this.socket.emit("pushnotif-ack", this.user.if, JSON.parse(message).data, "Connecting");

       // console.log("socket-pushnotif-", type, message);
      });
      this.socket.on("isalive", (message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

       // console.log("socket-isalive-", message);
        this.socket.emit("isalive-ack", this.user.id)
      });
      this.socket.on("message", (message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

        console.log("socket-", message);
      });
    });
   // console.log(this.socket.id)


    this.socket.on("TEST_CONNECTION", (msg: any) => {
      // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
      this.socketStatusBeforeCheck = msg.status;
     // console.log(this.socketStatusBeforeCheck);
    });
    this.socket.on("questionnaire", (questionnaireMsg: any) => {
      // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

      console.log(questionnaireMsg);
    });


  }

  fetchUser(phone) {
    this.API.getWidgetUserByPhone(phone,this.api_end_point).subscribe((data: any) => {
      let _response = data;
      if (_response && _response.success) {

        var updateObj = this.callStatus;



        if (!this.widgetUser) {
          this.widgetUser = _response.success;
          updateObj.widgetUser = this.widgetUser;
          this.myservice.updateCallStatus(updateObj);
        } else if (_response.success.external_url != this.widgetUser.external_url) {
          this.counter = 0;
          this.widgetUser.external_url = _response.success.external_url;
          updateObj.widgetUser = this.widgetUser;

          this.myservice.updateCallStatus(updateObj);
        }
      }
      else {

      }
    });
  }

  onInvite(invitation) {
   // console.log("call came")
    invitation.accept();
  }

  getHomeData() {
    let _today: any = new Date();
    let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
    let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
    let userid = this.user.id;
    let obj = {
      "userid": userid,
      "start_time": _startDate,
      "end_time": _endDate,
      "agents_list": userid
    }
    this.API.agentPluginHome(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data;
      if (_response) {
       // console.log(_response);
        this._auditScore = _response.audited_score;
        this._auditTime = _response.talk_time;
      }
      else {

      }
    });
  }
  getDashboardList() {
    let _today: any = new Date();
    let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
    let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
    let userid = this.user.id;
    let obj = {
      "userid": userid,
      "start_time": _startDate,
      "end_time": _endDate,
      "skip": 0,
      "calendar_type": "smartercalendar",
      "top": 100

    }
    this.API.agentPluginDashboardList(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.event;
      if (_response.length != 0) {
        let ELEMENT_DATA = _response;
        // let _followupData=ELEMENT_DATA.filter(v=>{return v.calendar_type== "smartercalendar"})
        let _followupData = ELEMENT_DATA[0].flpcount[0];
        this._countfollowUpLength = this.API.followupTodo(_followupData);
      }
      else {
        this._countfollowUpLength = this.API.followupTodo(0);
      }
    });
  }
  getAgentSlot() {
    let userid = this.user.id;
    let obj = {
      "userid": userid
    }
    this.API.agentPluginGetAgentSlot(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success.data;
      if (_response) {
        for (let day of _response) {
          for (let slot of day.rows) {
            if (slot.status == 'Available' && !this._availableSlots) {
              this._availableSlots = day.date + " " + slot.time
            }
            if (slot.status == 'Booked' && !this._nextBooked) {
              this._nextBooked = day.date + " " + slot.time
            }
          }
        }

        let _workSlotArray = _response;
      }
    });
  }
  getContacted() {
    let _today: any = new Date();
    let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
    let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
    let userid = this.user.id;
    let obj = {
      "userid": userid,
      "start_time": _startDate,
      "end_time": _endDate
    }
    this.API.agentPluginContacted(obj,this.api_end_point).subscribe((data: any) => {

      let _response = data.success;
      if (_response) {
        let ELEMENT_DATA = _response;
        let _contactedData = ELEMENT_DATA.filter(v => { return v.event_type == "call" })
        this._countLeadLength = this.API.leadsConnected(_contactedData.length);
      }
    });

  }
  startCallDial() {
    // const dialogRef = this.dialog.open(StartDialDialogComponent, {
    //   width: '30%',
    //   height: '90%',
    //   position: { right: '20px', bottom: '0' },
    //   data: ''
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
    // this.myservice.updateCallStatus({dialing: true});
  }
  counter: any = 0;
  transformUrl(url: string) {
    // if(this.counter==0){
    //   this.counter++;
    // console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    // return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    // }
  }

  startSession() {
	  if ((document.getElementById('code') as HTMLFormElement)) {
		  let _num = (document.getElementById('code') as HTMLFormElement).value;
		  _num = _num.substring(0, _num.length - 1);
		  (document.getElementById('code') as HTMLFormElement).value = _num;
	  }
	  this.myservice.clearCallStatus();
	  this.showHideStartCallButton = true;
	  this.showHideCallPad = false;
    this.API.remoteDialStart(this.user.id, this.user.server_url).subscribe(
      (res: any) => {
       // console.log(res)
        this.sessionStarted = true;
      }, (err) => {
        console.log(err)
      }
    )
  }
  endSession() {
	  if ((document.getElementById('code') as HTMLFormElement)){
		  let _num = (document.getElementById('code') as HTMLFormElement).value;
		  _num = _num.substring(0, _num.length - 1);
		  (document.getElementById('code') as HTMLFormElement).value = _num;
	  }
	  
	  this.myservice.clearCallStatus();
	  this.showHideStartCallButton = true;
	  this.showHideCallPad = false;
    this.API.remoteDialStop(this.user.id, this.user.server_url,this.breakoption).subscribe(
      (res: any) => {
       // console.log(res)
        this.sessionStarted = false;
       // this.removeNum();
       this.myservice.clearCallStatus();
       this.showHideStartCallButton = true;
      }, (err) => {
        console.log(err)
      }
    )
  }

  getParsed(data){
	  return JSON.parse(data.replace(/'/g, '"'));
  }

	checkNextTrigger(event, nextAction){
		//console.log(event, nextAction);
		if (nextAction && nextAction.post_url){
			var salesSts = {
				user_id: this.user.id,
				contact_person: {
					number: this.customerInfo.customer_number
				},
				description: "",
				order_potential: "",
				status: "NEW DATA",
				lat: "22.0858725",
				long: "82.1879959",
				start_time: this.callStatus.callStart || moment().utc(),
				substatus1: "",
				substatus2: "",
				machine_status: "",
				wrapup: 0,
				lead_source: '',
				appointment_id: "",
				call_end_time: this.callStatus.callEnd || moment().utc(),
				customkvs: ""
			} as any;

			if (this.callFlowJsonSections) {
			//	console.log(this.callFlowJsonSections)
				
				var QA = [];
				for (let section of this.callFlowJsonSections) {
					var obj1: any = [];
					for (let child of section.child) {
						var temp1:any = { "Q:": child.q, "A:": child.answer || "" };
						if (child.action_type){
							temp1["action_type:"] = child.action_type
						}
						
						obj1.push(JSON.stringify(temp1))
						if (child[child.answer]){
							for (let child2 of child[child.answer]) {
								var temp2:any = { "Q:": child2.q, "A:": child2.answer || "" };
								if (child2.action_type) {
									temp2["action_type:"] = child2.action_type
								}
								obj1.push(JSON.stringify(temp2))
								for (let child3 of child2[child2.answer]) {
									var temp3:any = { "Q:": child3.q, "A:": child3.answer || "" };
									if (child3.action_type) {
										temp3["action_type:"] = child3.action_type
									}
									obj1.push(JSON.stringify(temp3))
								}
							}
							
						}
						
					}
					QA = obj1
				}
				var finalObj = {
					experience: QA
				};
				salesSts.extranotes = JSON.stringify(finalObj)
			}

			this.API.customActionTrigger(salesSts, this.user.server_url, nextAction.post_url).subscribe(
				(res: any) => {
				}, (err) => {
					console.log(err)
				}
			)
		}
		
    }

}
