<!-- Audio Player starts -->
<div class="media-player">
  <mat-toolbar>
    <mat-toolbar-row>
      <span class="time-display">{{ state?.readableCurrentTime }}</span>
      <mat-slider color="primary" class="time-slider" [disabled]="state?.error">
        <input matSliderThumb
               [min]="0"
               [max]="state?.duration || 0"
               [step]="1"
               [value]="state?.currentTime || 0"
               (input)="onSliderChange($event)"
               (change)="onSliderChangeEnd($event)">
      </mat-slider>
      <span class="time-display">{{ state?.readableDuration }}</span>
    </mat-toolbar-row>
    
    <mat-toolbar-row class="media-controls">
      <button mat-icon-button matTooltip="10 seconds backward" (click)="previous()">
        <mat-icon>replay_10</mat-icon>
      </button>
      
      <button mat-icon-button class="btn d-flex align-items-center justify-content-center p-0" style="width: 48px; height: 48px;" matTooltip="Play" (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">
        <mat-icon fontSet="material-icons-outlined" class="d-flex align-items-center justify-content-center" style="font-size: 32px; width: 32px; height: 32px;">play_circle_filled</mat-icon>
      </button>
      
      <button mat-icon-button class="btn d-flex align-items-center justify-content-center p-0" style="width: 48px; height: 48px;" matTooltip="Pause" (click)="pause()" *ngIf="state?.playing">
        <mat-icon fontSet="material-icons-outlined" class="d-flex align-items-center justify-content-center" style="font-size: 32px; width: 32px; height: 32px;">pause</mat-icon>
      </button>
      
      <button mat-icon-button matTooltip="10 seconds forward" (click)="next()">
        <mat-icon>forward_10</mat-icon>
      </button>
      
      <button mat-icon-button matTooltip="Download" (click)="download()">
        <mat-icon>download</mat-icon>
      </button>
      
      <div class="volume-controls">
        <mat-icon class="volume_icon" matTooltip="Volume" *ngIf="!state?.isMute" (click)="setMute()">volume_up</mat-icon>
        <mat-icon class="volume_icon" matTooltip="Volume" *ngIf="state?.isMute" (click)="setUnmute()">volume_off</mat-icon>
        <mat-slider color="primary" class="time-slider">
          <input matSliderThumb
                 [min]="0"
                 [max]="1"
                 [step]="0.01"
                 [value]="state?.volume"
                 (input)="onVolumeChange($event)"
                 (change)="onVolumeChange($event)">
        </mat-slider>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <div *ngIf="state?.error" class="error-message">
    Not a valid file.
  </div>
</div>
<!-- Audio Player ends -->