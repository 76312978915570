import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-contiinex-contactus-page',
  templateUrl: './contiinex-contactus-page.component.html',
  styleUrls: ['./contiinex-contactus-page.component.scss']
})
export class ContiinexContactusPageComponent implements OnInit {

  createdForm:UntypedFormGroup;
  showHideErrorMsg:any;
  msgColor: any = "";
  showHideMsg:boolean=false;
  constructor( private router: Router,
    private http:HttpClient,
    private fb:UntypedFormBuilder,private API: ApiService) {
      this.createdForm=fb.group({
        firstName:['',[Validators.required,Validators.maxLength(50)]],
        lastName:['',[Validators.maxLength(50)]],
        email:['',[Validators.required,Validators.email]],
        relatedProblem:['',Validators.required],
        message:['',Validators.required],
      })

     }

  ngOnInit(): void {
  }

  submitBtn(createdForm:any){
  //debugger;
  this.showHideMsg=false;
  let _firstName=createdForm.controls.firstName.value;
  let _lastName=createdForm.controls.lastName.value;
  let _email=createdForm.controls.email.value;
  let _relatedProblem=createdForm.controls.relatedProblem.value;
  let _message=createdForm.controls.message.value;
  
  if(_firstName==""||_email==""||_relatedProblem==""||_relatedProblem==""||_message==""){
    this.showHideErrorMsg="Please fill all fields *.";
    this.showHideMsg=true;
    this.msgColor="red";
    return false;
  }    
  else{
    let emailField=createdForm.controls.email.value;
    let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  
    if (reg.test(emailField) == false) 
    {
      this.showHideErrorMsg="Invalid Email Address";
      this.msgColor="red";
      this.showHideMsg=true;
      //  alert('Invalid Email Address');
        return false;
    }
    let formData={
      "first_name":_firstName,
      "last_name":_lastName,
      "email": _email,
      "query": _relatedProblem,
      "message":_message
    }


this.API.saveContactFormApi(formData).subscribe((data: any) => {
if(data.success){
this.showHideErrorMsg=data.success;
this.msgColor="green";
this.showHideMsg=true;
this.createdForm.reset();
setTimeout(()=>{
  this.showHideMsg=false;
  this.showHideErrorMsg="";
  this.msgColor="";
  },3000);
}
else{

}
});
  }

}

omit_special_char(event)
{   
 let k;  
 k = event.charCode;  //         k = event.keyCode;  (Both can be used)
 return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

validateEmail(){
  let emailField=this.createdForm.controls.email.value;
  let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (reg.test(emailField) == false) 
  {
    this.showHideErrorMsg="Invalid Email Address";
    this.msgColor="red";
    this.showHideMsg=true;
    //  alert('Invalid Email Address');
      return false;
  }
  this.showHideMsg=false;
  return true;

}
}
