import { Component, OnInit,ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import {ApiService} from "../../api.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
@Component({
  selector: 'app-contiinex-shopify-dashboard',
  templateUrl: './contiinex-shopify-dashboard.component.html',
  styleUrls: ['./contiinex-shopify-dashboard.component.scss']
})
export class ContiinexShopifyDashboardComponent implements OnInit {
  currentLoader:boolean=false;
  Columnchart:any = null;
  category_xaxis:any[];
  act_series:any[];


  displayed_columns = ['name', 'whatsapp', 'calls', 'chat', 'text'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  donutChartData:any;

  customer_name:string;
  project_name:string = "";
  projects:any;
  selectedDayWise:any="Today";
  selectedActivityType:any="COMPLETED";
  selectedSalesType:any="ALL";
  wa_axis_data:any=[];
  chat_axis_data:any=[];
  text_axis_data:any=[];
  calls_axis_data:any=[];
  all_data:any;
  //selected_time_range:any;
  selectedProjectVal:any;
  showHideCustomDate:boolean=false;
  _startCustomDate:any;
  _endCustomDate:any;

  _totalVisited:any;
  _totalActiveNow:any;
  _whatsApp_Count:any;
  _calls_count:any;
  _chats_count:any;
  _text_count:any;
  api_end_point:any;

  currentTitle = 'shopify-dashboard';
  currentUrl:any;
  constructor(private _service:MyserviceService, private API: ApiService,private _snackBar: MatSnackBar,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = String(this._service.get_loggedin_user().project);
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_loggedin_user().project).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
    }
    this.getInitialData();
  }

  OnActivityChange(val: any) {
    this.getInitialData();
  }

  OnChangeProject(val:any){
   // debugger;
    //this._service.set_current_project(val); 
    this.selectedProjectVal=val;     
      this.project_name = val;
      this.getInitialData();
  }

  OnChangeDayTime(val:any){
    this.showHideCustomDate=false;
    this.selectedDayWise=val;
    if(val=="CustomDate"){
      this.showHideCustomDate=true;
     }
     else{
      this.getInitialData();
     }
   // this.selected_time_range= this.selectedDayWise;
    // if(this.selectedProjectVal=="" || this.selectedProjectVal==undefined || this.selectedProjectVal==null ){
    //   this._snackBar.open("Please Select Project", 'close', {
    //     duration: 3000,
    //   });
    //   return;
    // }   
   // this._service.set_current_time_range(this.selectedDayWise);

  }
  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
   // debugger;
    this._startCustomDate=moment(dateRangeStart.value).format('YYYY-MM-DDTHH:mm:ss');
    this._endCustomDate=moment(dateRangeEnd.value).format('YYYY-MM-DDTHH:mm:ss');
    if(this._startCustomDate !="Invalid date" && this._endCustomDate !="Invalid date"){
      this.getInitialData();
    }
  }

  getInitialData(){

    //console.log('Called getInitialData');

    if(this.selectedDayWise=="" || this.selectedDayWise==undefined || this.selectedDayWise==null ){
      this._snackBar.open("Please Select Date Range also", 'close', {
        duration: 3000,
      });
      return;
    }

    let _obj={
      "customer": this.customer_name,
      "project": this.project_name,
      "dateRange":this.selectedDayWise,
      "view_type":'This Year'
    };

    if(this.showHideCustomDate==true){
      _obj["startDate"] = this._startCustomDate;
      _obj["endDate"] = this._endCustomDate;
      _obj["view_type"] = 'Custom';
    } else{
    }

    _obj["activity_type"] = this.selectedActivityType;
    _obj["sales_type"] = this.selectedSalesType;
    this.currentLoader=true;
    this.API.getWidgetDashboard(_obj,this.api_end_point).subscribe((data: any) => {
    //  debugger;
      if(data.errors){
        this.currentLoader=false;
      //  console.log('getWidgetDashboard failed',data.errors);
      }
      else {
        this.currentLoader=false;
        this.all_data = data;
      
        this._totalVisited=this.all_data.widget_counts["Total"] ? this.all_data.widget_counts["Total"] : 0;
        this._totalActiveNow =this.all_data.widget_counts["Widget_Opened"] ? this.all_data.widget_counts["Widget_Opened"][0].count : 0;
        if(this.all_data.channel_counts["Whatsapp"]){
          this._whatsApp_Count =this.all_data.channel_counts["Whatsapp"][this.selectedActivityType]?this.all_data.channel_counts["Whatsapp"][this.selectedActivityType]:0; 
        }
        if(this.all_data.channel_counts["Call"]){
          this._calls_count=this.all_data.channel_counts["Call"][this.selectedActivityType]?this.all_data.channel_counts["Call"][this.selectedActivityType]:0;
        }
        if(this.all_data.channel_counts["Chat"]){
          this._chats_count=this.all_data.channel_counts["Chat"][this.selectedActivityType]?this.all_data.channel_counts["Chat"][this.selectedActivityType]:0;
        }
        if(this.all_data.channel_counts["Text"]){
          this._text_count=this.all_data.channel_counts["Text"][this.selectedActivityType]?this.all_data.channel_counts["Text"][this.selectedActivityType]:0;
        }

       // console.log('getWidgetDashboard success',this.all_data);

        this.channelOverviewProject(data);
        this.getTableData(data);
        this.getDonutChart();

      }
    });

  }


  channelOverviewProject(data){
    this.category_xaxis = data.xaxis;
    this.wa_axis_data = [];
    this.chat_axis_data = [];
    this.text_axis_data = [];
    this.calls_axis_data = [];

    for (var i in this.category_xaxis) {
      this.wa_axis_data[i] = 0;
      this.chat_axis_data[i] = 0;
      this.text_axis_data[i] = 0;
      this.calls_axis_data[i] = 0;
    }
//debugger;
    const channels = ['Whatsapp', 'Call', 'Chat', 'Text'];
    let total_count = 0;

    for (let ct in channels) {
      var channel_type = channels[ct];
      //console.log('Selecting',channel_type,data.channel_counts[channel_type][this.selectedActivityType]);

      total_count += data.channel_counts[channel_type][this.selectedActivityType];

      var data_axis = data[this.selectedActivityType.toLowerCase()+"_axis"];

      if (channel_type = 'Whatsapp') {
        for (var i in data_axis[channel_type]) {
          this.wa_axis_data[this.category_xaxis.indexOf(i)] = data_axis[channel_type][i];
        }
      }

      if (channel_type = 'Chat') {
        for (var i in data_axis[channel_type]) {
          this.chat_axis_data[this.category_xaxis.indexOf(i)] = data_axis[channel_type][i];
        }
      }

      if (channel_type = 'Text') {
        for (var i in data_axis[channel_type]) {
          this.text_axis_data[this.category_xaxis.indexOf(i)] = data_axis[channel_type][i];
        }
      }

      if (channel_type = 'Call') {
        for (var i in data_axis[channel_type]) {
          this.calls_axis_data[this.category_xaxis.indexOf(i)] = data_axis[channel_type][i];
        }
      }

    }

    //console.log('Channel Counts loaded',total_count);

    this.act_series=[{
    name: 'WhatsApp',
    data: this.wa_axis_data,
    color:'#10c63d'

  }, {
    name: 'Chat',
    data: this.chat_axis_data,
    color: '#d07d1e'
  }, {
    name: 'Call',
    data: this.calls_axis_data,
    color: '#8756b4'

  }, {
    name: 'Text',
    data: this.text_axis_data,
    color: '#196eae'
  }];
    let _Columnchart = new Chart({
      chart: {
        type: 'column',
        height: 180
      },
      title: {
        text: ''
      },
      
      exporting: {
        enabled: false
      },
      xAxis: {
        categories: this.category_xaxis,
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        lineColor: '#c0c98c',
        gridLineColor: '#c0c98c',
        labels: {
          style: {
            color: '#868f6c',
            fontWeight:'bold'
          }
        }
      },
      yAxis: {

        title:null,
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        tickInterval: 5,
        lineColor: '#c0c98c',
        gridLineColor: '#c0c98c',
        labels: {
          style: {
            color: '#868f6c',
            fontWeight:'bold'
          }
        }

      },
      legend: {
        reversed: false,
        verticalAlign: "top",
        align: "right",
        borderWidth: 0,

      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,

        }
      },
      series: this.act_series,

      });
      this.Columnchart= _Columnchart;
  }
  getTableData(data){

    /*let tableData=[
      {'name':'Ram', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'},
      {'name':'Arav', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'},
      {'name':'Rahul', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'},
      {'name':'Ram', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'},
      {'name':'Arav', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'},
      {'name':'Rahul', 'whatsapp':'887', 'calls':'887 | 30 Min', 'chat':'887 | 30 Min', 'text':'887 | 30 Min'}
    ]*/

    let tableData = [];
    let teamInfo = data.team_info[this.selectedActivityType];
    for (let i in teamInfo) {
      let obj = {};
      obj["name"] = i;
      obj['whatsapp'] = teamInfo[i]["Whatsapp"];
      obj['calls'] = teamInfo[i]["Call"];
      obj['chat'] = teamInfo[i]["Chat"];
      obj['text'] = teamInfo[i]["Text"];
      tableData.push(obj);
    }

    this.dataSource = new MatTableDataSource(tableData);
    this.dataSource.paginator = this.paginator;
   
  }

 
  getDonutChart(){
   this.donutChartData=  new Chart({
			title: {
				text: ''
			},
   
			credits: {
				enabled: false
			},
      exporting: {
        enabled: false
      },
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
            enabled: false,
						color: 'white',
						style: {
							fontWeight: '500',
						},
					},
         
					showInLegend: true,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: true
						}
					},
					name: 'Active',
					data: [
						
						{
							name: 'WhatsApp',
							y: this.all_data.channel_counts["Whatsapp"][this.selectedActivityType]?this.all_data.channel_counts["Whatsapp"][this.selectedActivityType]:0,
              color:'#10c63d'
						}, {
							name: 'Calls',
							y: this.all_data.channel_counts["Call"][this.selectedActivityType]?this.all_data.channel_counts["Call"][this.selectedActivityType]:0,
              color: '#8756b4'
						},
						{
							name:'Chat',
							y: this.all_data.channel_counts["Chat"][this.selectedActivityType]?this.all_data.channel_counts["Chat"][this.selectedActivityType]:0,
              color: '#d07d1e'
						}
						,
						{
							name: "Text",
							y: this.all_data.channel_counts["Text"][this.selectedActivityType]?this.all_data.channel_counts["Text"][this.selectedActivityType]:0,
              color: '#196eae'
						}
					],
					type: 'pie',        
					
					innerSize: '2%',
				}]
		});
	

  }

}
