<h1 mat-dialog-title>Hi {{user?.name}}</h1>
<div mat-dialog-content>You are getting a call. Please answer it.</div>
<div mat-dialog-actions>
    <button mat-raised-button aria-label="Decline"  mat-dialog-close style="background-color: #f44336; color: white;">
        <mat-icon>call_end</mat-icon>
        <span>DECLINE</span>
      </button>
      <button mat-raised-button aria-label="Accept"  [mat-dialog-close]="true" style="background-color: #4caf50; color: white;">
        <mat-icon>call</mat-icon>
        <span>ACCEPT</span>
      </button>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true">Accept</button> -->
</div>