<app-menu [navigateLink]='navigationTitle'></app-menu>
<h4 class="titlehead">Tech Support Dashboard</h4>
<table class="maintable">             
    <tr><td>
        <div class="containecs">          
<!--start for tech support-->
<div>
<div class="colcomn">
<div class="card">
<div class="card-header">Tickets</div>
<div class="card-body">
    <table class="tablecs">
        <tr><td>Total</td><td>{{ summary.tickets.total }}</td></tr>
        <tr><td>Open</td><td>{{ summary.tickets.open }}</td></tr>
        <tr><td>Resolved</td><td>{{ summary.tickets.close }}</td></tr>
    </table> 
</div> 
</div>
</div>
<div class="colcomn">
<div class="card">
<div class="card-header">Issue Status</div>
<div class="card-body">
    <table class="tablecs">
        <tr><td>Assigned</td><td>{{ summary.status.assigned }}</td></tr>
        <tr><td>In Progress</td><td>{{ summary.status.inprogress }}</td></tr>
        <tr><td>Resolved</td><td>{{ summary.status.closed }}</td></tr>
    </table>
</div> 

</div>
</div>
<div class="colcomn">
<div class="card">
<div class="card-header">Priority</div>
<div class="card-body">
    <table class="tablecs">
        <tr><td>High</td><td>{{ summary.priority.high }}</td></tr>
        <tr><td>Medium</td><td>{{ summary.priority.medium }}</td></tr>
        <tr><td>Low</td><td>{{ summary.priority.low }}</td></tr>       
    </table>
</div> 

</div>
</div>
<div class="colcomn">
<div class="card">
<div class="card-header">Title</div>
<div class="card-body">
    <table class="tablecs">
        <tr><td>Label</td><td>0</td></tr>
        <tr><td>Label</td><td>0</td></tr>
        <tr><td>Label</td><td>0</td></tr>
    </table>
</div> 

</div>
</div>
</div>


<!--end for tech support-->
            </div>
    </td></tr>
</table>
<div class="pDiv">
    <div class="divprintRt">
        <table style="width: 100%;">
            <tr>
                <td>
                    <span class="recName">{{tech_Name}}  </span>
					<span class="datecs">10-Jun-2020</span>
					<!-- <span class="das-pe" (click) = "refreshData()">Refresh</span> -->
                </td>
                <td>
                    <mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="frompicker" placeholder="From Date" 
						[formControl]="tableDate.start_date" (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)"
						readonly>
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                      </mat-form-field>
                </td>
                <td>
                    <mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="topicker" placeholder="To Date" 
						[formControl]="tableDate.end_date" (click)="topicker.open()" (dateChange)="change_date('end_date', $event)"
						readonly>
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                </td>
                <td>
                    <span>
                        <button mat-raised-button (click) = "refreshData()">Go</button>
                      </span>
                </td>
                <td>
                    <span>
                        <!-- <button mat-raised-button (click)="createidDialog()">Create ID</button> -->
                      </span>
                </td>
                <td style="text-align: right;position: relative;right: 20px;">
                    <span class="searchSpan"> <mat-form-field >
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
                      </mat-form-field></span>
                </td>
            </tr>
           
        </table>
      
        
 
    </div>
<!--start table-->
    <div>
        <mat-table [dataSource]="dataSource">
                        
            <ng-container matColumnDef="sno">
              <mat-header-cell *matHeaderCellDef> SNo. </mat-header-cell>
              <mat-cell *matCellDef="let element">
                 {{element.sno}}               
                 </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let element;"> 
                <mat-form-field class="wdh"  appearance="{{textAppearance}}">
                <input matInput name="element.name" [(ngModel)]="element.name" disabled/>
                </mat-form-field>
              </mat-cell>
            </ng-container>
     
            <ng-container matColumnDef="agentid">
              <mat-header-cell *matHeaderCellDef> Agent Id </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-form-field  class="wdh"  appearance="{{textAppearance}}">
                  <input matInput name="element.agentid" [(ngModel)]="element.agentid" disabled/>
                  </mat-form-field>
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="ticketno">
              <mat-header-cell *matHeaderCellDef> Ticket No </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-form-field  class="wdh"  appearance="{{textAppearance}}">
                  <input matInput name="element.ticketno" [(ngModel)]="element.ticketno" disabled/>
                  </mat-form-field>  
              </mat-cell>
            </ng-container>          
          
              <ng-container matColumnDef="issuetype">
                <mat-header-cell *matHeaderCellDef> Issue Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
                  	<mat-form-field  class="wdh" appearance="{{textAppearance}}">
                    	<input matInput name="element.issuetype" [(ngModel)]="element.issuetype" [disabled]="!element.readOnlyTrueFalse"/>
                    </mat-form-field>  
                </mat-cell>
              </ng-container>
        
              <ng-container matColumnDef="issuestatus">
                <mat-header-cell *matHeaderCellDef>Issue Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
                    
                    <mat-form-field  class="wdh" >                       
                        <mat-select name="element.issuestatus" [(ngModel)]="element.issuestatus" [disabled]="!element.readOnlyTrueFalse" style="font-size: 12px;">
                          <mat-option value="ASSIGNED">ASSIGNED</mat-option>
                          <mat-option value="INPROGRESS">INPROGRESS</mat-option>
                          <mat-option value="SOLVED">SOLVED</mat-option>
                          <mat-option value="REJECTED">REJECTED</mat-option>
                        </mat-select>
                      </mat-form-field>
                
                </mat-cell>
              </ng-container>
         
             
              <ng-container matColumnDef="priority">
                <mat-header-cell *matHeaderCellDef> Priority </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-form-field   class="wdh">                       
                        <mat-select name="element.priority" [(ngModel)]="element.priority"  [disabled]="!element.readOnlyTrueFalse"  style="font-size: 12px;">
                          <mat-option value="HIGH">HIGH</mat-option>
                          <mat-option value="LOW">LOW</mat-option>
                          <mat-option value="MEDIUM">MEDIUM</mat-option>
                        </mat-select>
                      </mat-form-field>                    
                    </mat-cell>
              </ng-container>
      
             
              <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field  class="wdh" appearance="{{textAppearance}}">
                    <input matInput name="element.assignedTo" [(ngModel)]="element.assignedTo" disabled/>
                    </mat-form-field>   
                </mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="assignedOn">
                <mat-header-cell *matHeaderCellDef> Assigned On </mat-header-cell>
                <mat-cell *matCellDef="let element">
					<mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="assignedOn" placeholder="" name="element.assignedOn"
						[(ngModel)] ="element.assignedOn" (click)="assignedOn.open()" [disabled]="!element.readOnlyTrueFalse">
						<mat-datepicker-toggle matSuffix [for]="assignedOn"></mat-datepicker-toggle>
						<mat-datepicker #assignedOn></mat-datepicker>
					</mat-form-field>
				</mat-cell>
              </ng-container>
              <ng-container matColumnDef="resolvedBy">
                <mat-header-cell *matHeaderCellDef> Resolved By </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field  class="wdh"  appearance="{{textAppearance}}">
                    <input matInput name="element.resolvedBy" [(ngModel)]="element.resolvedBy" [disabled]="!element.readOnlyTrueFalse"/>
                    </mat-form-field>   
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="resolvedOn">
                <mat-header-cell *matHeaderCellDef> Resolved On </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <!-- <mat-form-field  class="wdh"  appearance="{{textAppearance}}">
                    <input matInput value="{{element.resolvedOn}}" [disabled]="!element.readOnlyTrueFalse"/>
					</mat-form-field>   -->
					<mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="reslovledOn" placeholder="" name="element.resolvedOn"
						[(ngModel)] ="element.resolvedOn" (click)="reslovledOn.open()" [disabled]="!element.readOnlyTrueFalse">
						<mat-datepicker-toggle matSuffix [for]="reslovledOn"></mat-datepicker-toggle>
						<mat-datepicker #reslovledOn></mat-datepicker>
					</mat-form-field>
                   </mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef> Modified On </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
					<mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="modifiedOn" placeholder="" name="element.modifiedOn"
						[(ngModel)] ="element.modifiedOn" (click)="modifiedOn.open()" [disabled]="!element.readOnlyTrueFalse">
						<mat-datepicker-toggle matSuffix [for]="modifiedOn"></mat-datepicker-toggle>
						<mat-datepicker #modifiedOn></mat-datepicker>
					</mat-form-field> 
                </mat-cell>
			  </ng-container>
			  <ng-container matColumnDef="estimateOn">
                <mat-header-cell *matHeaderCellDef> Estimate On </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
					<mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="estimateOn" placeholder="" name="element.estimateOn"
						[(ngModel)] ="element.estimateOn" (click)="estimateOn.open()" [disabled]="!element.readOnlyTrueFalse">
						<mat-datepicker-toggle matSuffix [for]="estimateOn"></mat-datepicker-toggle>
						<mat-datepicker #estimateOn></mat-datepicker>
					</mat-form-field> 
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
					<span *ngIf="!element.showHideUpdateDel">
						<i class="fas fa-pencil-alt editpencil"  (click)="editBtnClick($event, element, i)"></i>
					</span> 
					<span class="modifySaveClose" *ngIf="element.showHideUpdateDel">
						<i class="fas fa-save editpencil" (click)="updateBtnClick($event, element, i)"></i>
						<span>Update</span>
					</span>
					<span class="modifySaveClose" *ngIf="element.showHideUpdateDel">
						<i class="fas fa-window-close editpencil" (click)="deleteBtnClick($event, element, i)"></i>
						<span>Cancel</span>
					</span>            
                </mat-cell>              
              </ng-container>
            <mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
    </div>
 <!--end table-->
 </div>