import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root',
})
export class DataResolver  {
  constructor(private sharedService: SharedDataService) {}

  async resolve(): Promise<any> {
    console.log('loading the resolver');
    return this.sharedService.load_initials();
  }
}
