import { Component, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '../utils';

import { ServicesService } from '../dashboard/services.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-pmdashboard',
  templateUrl: './pmdashboard.component.html',
  styleUrls: ['./pmdashboard.component.scss']
})
export class PmdashboardComponent implements OnInit {
	currentTitle = 'pmdashboard';
	currentLoader = false;
	today = moment().format('DD-MMM-YYYY');
	utils = new Utils();
	displayedColumns = [
		'user_id',
		'name',
		'team_lead',
		'manager',
		'status',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'audit_score',
		'ctc_score'
	];

	_summary = function () {
		return {
			'data': {
				'data_to_call': 0,
				'available': 0,
				'followup':0
			},
			'dialled': {
				'total': 0,
				'unique': 0
			},
			'contacted': {
				'total': 0,
				'unique': 0
			},
			'forms': {
				'total': 0
			}
		}
	}
	_tableData = function () {
		return {
			'user_id': '',
			'name': '',
			'team_lead': '',
			'manager': '',
			'status': '',
			'dialled': 0,
			'unique_dialled': 0,
			'contacted': 0,
			'unique_contacted': 0,
			'rnr': 0,
			'forms': 0,
			'audit_score': 0,
			'ctc_score': 0
		}
	}

	summary = this._summary();

	@ViewChild(MatPaginator) paginator: MatPaginator;
	dataSource = new MatTableDataSource();
	api_end_point:any;
	constructor(
		private dashService: ServicesService,
		private userService: MyserviceService
	) { 
		this.utils = new Utils();
	}

	ngOnInit() {
		this.api_end_point = this.userService.get_API_End_Point();
		this.refreshData();
	}
	
	applyFilter(val: string) {
		// console.log('pm filter starts', val);
		this.dataSource.filter = val.trim().toLowerCase();
	}

	refreshData() {
	//	console.log('Table data refreshing');
		this.currentLoader = true;
		let obj:any = {};
		obj.start_date = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true);
		obj.end_date = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true);
		obj.agents_list = this.userService.getAllIds('user');
		// debugger;
		if (!(obj.agents_list && obj.agents_list.length)) {
			return;
		}
		this.dashService.getPMDashData(obj,this.api_end_point).then((resolve) => {
			//console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareTableData()
		}, (err) => {
				alert(err);
				this.currentLoader = false;
		})
	}

	prepareTableData() {
		this.currentLoader = true;
		this.summary = this._summary();
		let data = null;
		let arr = [];
		this.dashService.pm_dash_data.subscribe((d) => data = d);
	//	console.log('printing data', data);
		if (data.hasOwnProperty('pd_count_group')) {
			this.summary.data.data_to_call = data['pd_count_group']
		}
		if (data.hasOwnProperty('extra_data')) {
			let e = data['extra_data'];
			if (e.hasOwnProperty('total_calls')) {
				this.summary.dialled.total = e['total_calls'];
			}
			if (e.hasOwnProperty('unique_dialled')) {
				this.summary.dialled.unique = e['unique_dialled'];
			}
		}
		if (data.hasOwnProperty('success')) {
			let d = data['success'];
			//console.log('success data', JSON.parse(JSON.stringify(d)));
			for (var i in d) {
				let loc = d[i];
				// console.log('printing loc', loc);
				let obj = this._tableData();
				obj['user_id'] = i;
				obj['name'] = loc['name'];
				obj['dialled'] = loc['dialled'];
				obj['unique_dialled'] = loc['unique_dialled'];
				if (!isNaN(loc['contacted'])) {
					obj['contacted'] = loc['contacted'];
					this.summary.contacted.total += obj['contacted'];
				}
				if (!isNaN(loc['unique_connected'])) {
					obj['unique_contacted'] = loc['unique_connected'];
					this.summary.contacted.unique += obj['unique_contacted'];	
				}
				if (!isNaN(loc['forms_filled'])) {
					obj['forms'] = loc['forms_filled'];
					this.summary.forms.total += obj['forms'];
				}
				obj['audit_score'] = loc['avg_score'];
				obj['ctc_score'] = loc['ctc_score'];
				obj['status_color'] = this.getStatusColor(loc['last_call']);
				arr.push(obj);
			}
		}
	//	console.log('table data', arr);
		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
		this.currentLoader = false;
	}

	getStatusColor(val:any) {
		let color = '#DEE1E6';
		try {
			if (val) {
				let duration = this.utils.getDuration(
					val,
					this.utils.getUtc('YYYY-MM-DDTHH:mm:ss'),
					'YYYY-MM-DDTHH:mm:ss',
					'm'
				)
			
				if (duration) {
					if ((duration >= 0) && (duration < 5)) {
						color = '#33EB1A';
					}
					if ((duration >= 5) && (duration < 30)) {
						color = '#C9B248';
					}
					if (duration > 30) {
						color = '#FF0000';
					}
				}
			} 
			return color;
		} catch (e) {
			return color;
		}
	}

}
