import { Injectable } from '@angular/core'
import { Subject, BehaviorSubject, observable, Observable } from 'rxjs';
import { MyserviceService } from '../myservice.service';

interface AGENTSIPSESSION{
    sip_state?: string,
    status?: string,
    time_stamp?: string | Date
}

@Injectable({
    providedIn: 'root'
})

export class DialerService {

    constructor(private myservice: MyserviceService) { }

    private stateSettings = this.myservice.get_stage_settings();
    public ACTIVATE_AGENT_SESION = new BehaviorSubject(false);
    public IS_ACCEPT_DIALOG_REQUESTED = new BehaviorSubject(false);
    public AGENT_SIP_SESSION:BehaviorSubject<AGENTSIPSESSION> = new BehaviorSubject({});

    is_inbound_settings_enabled(): boolean {
        try {
            const inbound_settings = this.stateSettings?.extraoptions.find(e => e.name == 'allowinbound');
            return inbound_settings && (inbound_settings.definition == 'on')
        } catch (e) {
            return false;
        }
    }

    is_agent_session_enabled(): boolean{
        return this.ACTIVATE_AGENT_SESION.value;
    }

    is_call_accept_dialog_opened(): boolean {
        return this.IS_ACCEPT_DIALOG_REQUESTED.value;
    }

    change_agent_session_status(): Observable<boolean> {
        return this.ACTIVATE_AGENT_SESION.asObservable();
    }

    sip_event_emitter(event:string, obj:any):void {
        console.log('dialer service', event, obj);
    }

    /* Agent Session Function */
    update_agent_sip_session(obj:AGENTSIPSESSION):void{
        this.AGENT_SIP_SESSION.next(obj);
    }

    current_agent_sip_session():AGENTSIPSESSION{
        return this.AGENT_SIP_SESSION.value;
    }

    change_agent_sip_session():Observable<AGENTSIPSESSION>{
        return this.AGENT_SIP_SESSION.asObservable();
    }
}