import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-onboarding-process-page1',
  templateUrl: './onboarding-process-page1.component.html',
  styleUrls: ['./onboarding-process-page1.component.scss']
})
export class OnboardingProcessPage1Component implements OnInit {
  createdForm:UntypedFormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  constructor(private router:Router,public formbuilder:UntypedFormBuilder,public API :ApiService) { 
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      projectName: [''],
      marketPlace:false,
      platform: false,
      pocStartDate:[''],
      pocEndDate:[''],
      pocHeadCount:[''],
      productionHeadCount:[''],
      addFile:[''],
      inboundCall:false,
      outboundCall:false,
      emailCB:false,
      chatbot:false
    })
  }

  ngOnInit(): void {
  }
  onNextBtn(createdForm: any){
    //debugger;
    this.messageDisplay="";
    this.showHideMessage = false;
    let _projectName = createdForm.controls["projectName"].value;
    let _marketPlace = createdForm.controls["marketPlace"].value;
    let _platform = createdForm.controls["platform"].value;
    let _pocStartDate = createdForm.controls["pocStartDate"].value;
    let _pocEndDate = createdForm.controls["pocEndDate"].value;
    let _pocHeadCount = createdForm.controls["pocHeadCount"].value;   
    let _productionHeadCount = createdForm.controls["productionHeadCount"].value;

    let _inboundCall=  createdForm.controls["inboundCall"].value;
    let _outboundCall=  createdForm.controls["outboundCall"].value;
    let _emailCB=  createdForm.controls["emailCB"].value;
    let _chatbot=  createdForm.controls["chatbot"].value;

    if (_projectName == "") {
      this.messageDisplay = "Please enter project name*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_marketPlace == false && _platform ==false) {
      this.messageDisplay = "Please select atleast one project type*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_pocStartDate == "" || _pocEndDate=="") {
      this.messageDisplay = "Please enter start date / end date *";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_inboundCall == false && _outboundCall ==false && _emailCB ==false && _chatbot ==false) {
      this.messageDisplay = "Please select atleast one process type*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    let _obj = {
      "projectName": _projectName,
      "marketPlace": _marketPlace,
      "platform": _platform,
      "pocStartDate": _pocStartDate,
      "pocEndDate": _pocEndDate,
      "pocHeadCount":_pocHeadCount,
      "productionHeadCount": _productionHeadCount ,
      "inboundCall":_inboundCall,
      "outboundCall":_outboundCall,
      "emailCB":_emailCB,
      "chatbot":_chatbot
    }

    this.messageDisplay = "Good.., Now you are going to next page..!!";
    this.msgColor = "green";
    this.showHideMessage = true;
    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>{
      this.router.navigateByUrl('/onboarding_process_page2')
    },3000)
    
      }
      omit_special_char(event)
      {   
         let k;  
         k = event.charCode;
         return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
      }
      
      onlyNumberValidation(event){
        return event.charCode >= 48 && event.charCode <= 57;
      }
}
