import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ServicesService } from '../dashboard/services.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { PmdashboardDailogComponent } from '../pmdashboard-dailog/pmdashboard-dailog.component';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'app-dashboard-inbound',
  templateUrl: './dashboard-inbound.component.html',
  styleUrls: ['./dashboard-inbound.component.scss']
})
export class DashboardInboundComponent implements OnInit {
  messageAlert:any="";
	showHide_messageAlert:boolean=false;
  currentTitle = 'dashboard-inbound';
  currentLoader = false;
  maxDate = new Date(); 
  inboundDashboardData : any = {
    "total_calls": 0,
    "total_calls_perc": 0.0,
    "received_calls": 0,
    "received_calls_perc": 0.0,
    "abonden_calls": 0,
    "abonden_calls_perc": 0.0,
    "total_aht": 0,
    "total_aht_perc": 0.0,
    "avg_aht": 0,
    "avg_aht_perc": 0.0,

  }
outboundDashboardData : any = {
    "total_calls": 0,
    "total_calls_perc": 0.0,
    "received_calls": 0,
    "received_calls_perc": 0.0,
    "abonden_calls": 0,
    "abonden_calls_perc": 0.0,
    "total_aht": 0,
    "total_aht_perc": 0.0,
    "avg_aht": 0,
    "avg_aht_perc": 0.0,
}
inboundAgentsData: any = {
     "Active": 0,
      "Available": 0,
      "Not Active": 0,
      "Sign IN": 0,
      "Un Available": 0,
      "0-5 Min": 1,
      "5-30 Min": 0,
      ">30 Min": 0,
      "Not Called": 26,
      "Total Active Agents": 1,
      "Contacted Agents": 0,
      "Avg Contacted Calls": 0,
      "Agents Above Avg Connected Calls": 0,
      "agents": []
};

  selectedTabSts = 'call_status';
  utils = new Utils();
  alert: Alert = { type: 'success', message: "Refreshed successfully..." };
  alertClosed = true;
  slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  settings: any = ""
  workHours: any = ""
  dashData: any [] = [];
  selectAll = true;
  displayedColumnsCallSummary = [
    'type',
    'time',
    'spoken_time',
    'wrap_time',
    'wait_time',
    'idle_time',
  ];

  displayedColumnsCallStatus = [
    'user_id',
    'name',
    'status',
    'ib_total_calls',
    'ib_received_calls',
    'ib_abonden_calls',
    'ib_total_aht_in_sec',
    'ob_total_calls',
    'ob_received_calls',
    'ob_abonden_calls',
    'ob_total_aht_in_sec',
    'login_hours',
    'audited_calls',
    'avg_score',
    'ctc_calls',
    'ctc_score',
  ];
  displayedColumnsCallSlot = [
    'date',
    'time',
    "capacity",
    'booked',
    'waiting',
    'occupancy',
    'status',
    'options',
    'id'
  ];
  _summary = function () {
    return {
      'data': {
        'total': 0,
        'total_perc': 0,
        'data_to_call': 0,
        'data_to_call_perc': 0,
        'available': 0,
        'followup': 0
      },
      'dialled': {
        'total': 0,
        'total_perc': 0,
        'unique': 0,
        'unique_perc': 0,
      },
      'contacted': {
        'total': 0,
        'total_perc': 0,
        'unique': 0,
        'unique_perc': 0
      },
      'forms': {
        'total': 0
      }
    } as any
  }
  _tableDataCallStatus = function () {
    return {
      'user_id': '',
      'name': '',
      'status': '',
      'dialled': 0,
      'unique_dialled': 0,
      'contacted': 0,
      'unique_contacted': 0,
      'rnr': 0,
      'forms': 0,
      'audit_score': 0,
      'ctc_score': 0
    }
  }
  _tableDataCallSlot = function () {
    return {
      'date': "",
      'time': "",
      "capacity": 0,
      'booked': 0,
      'waiting': 0,
      'occupancy': 0,
      'status': "",
      'options': "",
      'id': ""
    }
  }

  agentCallingStatus: any = {
    calling: 0,
    on_break: 0,
    not_submit: 0,
    wait_l_10: 0,
    wait_g_10: 0,
    log_out: 0,
    remaining: 0
  }

  agentStatusChart: any;
  agentStatusChartData: any = {
    calling: 0,
    calling_perc: "0",
    notclick: 0,
    notclick_perc: 0,
    waitinglt10: 0,
    waitinglt10_perc: 0,
    waitinggt10: 0,
    waitinggt10_perc: 0,
    notcalling: 0,
    notcalling_perc: 0,
    loggedout: 0,
    loggedout_perc: 0,
  };
  dashSummary: any;
  callSummaryData: any[] = [{
    'type': 'Total',
    'time': "00:00:00",
    'spoken_time': "00:00:00",
    'wrap_time': '00:00:00',
    'wait_time': '00:00:00',
    'idle_time': '00:00:00'
  },
  {
    'type': 'Average of 0 Agents',
    'time': "00:00:00",
    'spoken_time': "00:00:00",
    'wrap_time': '00:00:00',
    'wait_time': '00:00:00',
    'idle_time': '00:00:00'
  }]

  selectedAgents: any[] = []
  selectedAgentsLen: number = 0;
  myUser: any;
  summary = this._summary();
  dataSource: any = new MatTableDataSource();
  dataSourceCallSummary: any = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) tableSort: MatSort;
  @ViewChild(' fromInput', {
		read: MatInput
    })  fromInput: MatInput;
    selectedProject:any="";	
	selectedChannel:any="dashboard-inbound";
	api_end_point:any;
  constructor(private dashService: ServicesService, private myService: MyserviceService, private dialog: MatDialog, public router: Router, private API: ApiService,) { }


  ngOnInit() {
    // this.getCallStatus();
    this.settings = this.myService.get_stage_settings();
    /* this.myService.settings.subscribe(e => {
      this.settings = e;
    }) */
    this.workHours = this.settings.workhours;
	this.api_end_point = this.myService.get_API_End_Point();
    
    this.refreshData();
    this.prepareCallSummary();
    this.myUser = this.myService.get_loggedin_user();
    this.selectedProject=this.myUser.company;
  }

  prepareCallSummary() {
    this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);

  }

  refreshDataCallSlot() {


		this.currentLoader = true;
		let obj: any = {};
		obj.start_date = this.utils.localToUTC(this.slotStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.slotEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')


		this.dashService.getDatewiseSlots(obj,this.api_end_point).then((resolve) => {
		//	console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareSlotTableData();
		}, (err) => {
			// alert(err);
			this.currentLoader = false;
			this.alert.type = "danger";
			this.alert.message = err;
			this.alertClosed = false;
			setTimeout(() => this.alertClosed = true, 3000);
			this.dataSource = new MatTableDataSource([]);
			this.dataSource.paginator = this.paginator;
		})

	}



  refreshData() {
    if(!this.callStartDate || !this.callEndDate){
      return;
    }
    //console.log('Table data refreshing');
    this.currentLoader = true;
    let obj: any = {};
    var not_submit: number = 0, calling: number = 0, wait_l_10: number = 0, wait_g_10: number = 0, on_break: number = 0, log_out: number = 0;
    
    var now = moment();
    obj.start_date = this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
    obj.end_date = this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss');
    
    this.API.getFullTeamData(this.api_end_point).subscribe(
      (res: any) => {
        if (res.success) {
          this.myService.set_Full_team(res.success);
          
          if(this.selectedAgents.length == 0){
            for(const item of this.myService.getAllIds('user')){
              let agent ={name: item.name,user_id: item.id, checked: true};
              this.selectedAgents.push(agent);
            }
            obj.agents_list = this.myService.getAllIds('user', 'id');
            this.selectedAgentsLen = this.selectedAgents.length;
          }else{
            obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
            this.selectedAgentsLen = obj.agents_list.length;
          }
          
          
          
          this.dashService.getPMDashDataInbound(obj,this.api_end_point).then((resolve) => {
            
            this.currentLoader = false;
            

            this.prepareTableData();
          }, (err) => {
            alert(err);
            this.currentLoader = false;
          })
        } else {
          alert(res.error)
        }
      }, (err) => {
        alert('Error in the full team fetch')
      });


  }

  check_number(val){
    if(!isNaN(val)){
        return parseInt(val)
    }else{
        return 0;
    }
}

  prepareSlotTableData() {
    this.currentLoader = true;
    let data = null;
    let arr = [];
    this.dashService.pm_dash_slot_data.subscribe((d) => data = d);
    let d = data['success'];
    
    for (var i in d) {
      let loc = d[i];
      let obj = this._tableDataCallSlot();
      obj['date'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('L');
      obj['time'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') + " - " + moment(loc['slot_end_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
      obj['capacity'] = loc['max_limit'];
      obj['booked'] = loc['current_booked'];
      obj['waiting'] = loc['waiting'];
      obj['occupancy'] = loc['waiting'];
      obj['occupancy'] = loc['waiting'];
      obj['status'] = loc['status'];
      obj['id'] = loc['id'];
      arr.push(obj);
    }
    
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = function (data, filter: any): boolean {
      return data.date.toString().toLowerCase().includes(filter)
    };
    this.currentLoader = false;
  }
  
  agent_params (id,obj){
    var dashRow = {} as any;
    dashRow.isVisible = true;
    dashRow.user_id = id;
    dashRow.name = (obj && obj.name) ? obj.name : '';
    dashRow.email = (obj && obj.name) ? obj.email : '';
    dashRow.phone = (obj && obj.phone) ? obj.phone : '';
    dashRow.role = (obj && obj.role) ? obj.role : '';
    dashRow.agentid = (obj && obj.agentid) ? obj.agentid : '';
    dashRow.active_campaign = (obj && obj.active_campaign) ? obj.active_campaign : '';
    dashRow.tpd_queues = (obj && obj.tpd_queues) ? obj.tpd_queues : '';
    dashRow.idle_time = (obj && obj.idle_time) ? obj.idle_time : 0;
    dashRow.wait_time = (obj && obj.wait_time) ? obj.wait_time : 0;
    dashRow.wrap_time = (obj && obj.wrap_time) ? obj.wrap_time : 0;
    dashRow.active_time = (obj && obj.active_time) ? obj.active_time : 0;
    dashRow.spoken_time = (obj && obj.spoken_time) ? obj.spoken_time : 0;
    // dashRow.color = check_color(obj.last_call);
    dashRow.dialled = (obj && obj.dialled) ? obj.dialled : 0;
    dashRow.contacted = (obj && obj.contacted) ? obj.contacted : 0;
    dashRow.unique_dialled = (obj && obj.unique_dialled) ? obj.unique_dialled : 0;
    dashRow.unique_connected = (obj && obj.unique_connected) ? obj.unique_connected : 0;
    dashRow.forms_filled = (obj && obj.forms_filled) ? obj.forms_filled : 0;
    // dashRow.signin = (obj && obj.signin) ? $rootScope.changeFormat(obj.signin,'YYYY-MM-DDTHH:mm:ss', 'HH:mm:ss') : '00:00:00';
    // dashRow.signout = (obj && obj.signout) ? $rootScope.changeFormat(obj.signout,'YYYY-MM-DDTHH:mm:ss', 'HH:mm:ss') : '00:00:00';
    dashRow.audited_calls = (obj && obj.audited_calls) ? obj.audited_calls : 0;
    dashRow.avg_score = (obj && obj.avg_score) ? obj.avg_score : 0;
    dashRow.ctc_calls = (obj && obj.ctc_calls) ? obj.ctc_calls : 0;
    dashRow.ctc_score = (obj && obj.ctc_score) ? obj.ctc_score : 0;
    return dashRow;
}
  
  summarizeData(){
    let obj = {};
    
    var add_keys = ['dialled','contacted','unique_dialled','unique_connected','forms_filled','idle_time','wait_time','wrap_time','active_time','spoken_time','audited_calls','avg_score','ctc_calls','ctc_score'];
        for(var i in this.dashData){
            var loc_obj = this.dashData[i];
            for(var k in loc_obj){        
                if(add_keys.indexOf(k) > -1){
                    if(obj.hasOwnProperty(k)){
                        obj[k] += parseInt(loc_obj[k])
                    }else{
                        obj[k] = parseInt(loc_obj[k])
                    }
                }
            }
        }
        this.dashSummary = obj;
        this.callSummaryData = [{
          'type': 'Total',
          'time': ""+this.gethmsformat(this.dashSummary.active_time,'clock'),
          'spoken_time': ""+this.gethmsformat(this.dashSummary.spoken_time,'clock'),
          'wrap_time': ""+this.gethmsformat(this.dashSummary.wrap_time,'clock'),
          'wait_time': ""+this.gethmsformat(this.dashSummary.wait_time,'clock'),
          'idle_time': ""+this.gethmsformat(this.dashSummary.idle_time,'clock'),
        },
        {
          'type': ""+`Average of ${this.dashData.length} Agents`,
          'time': ""+this.gethmsformat(this.getDivision(this.dashSummary.active_time,this.dashData.length),'clock'),
          'spoken_time':this.gethmsformat(this.getDivision(this.dashSummary.spoken_time,this.dashData.length),'clock'),
          'wrap_time': ""+this.gethmsformat(this.getDivision(this.dashSummary.wrap_time,this.dashData.length),'clock'),
          'wait_time': ""+this.gethmsformat(this.getDivision(this.dashSummary.wait_time,this.dashData.length),'clock'),
          'idle_time': ""+this.gethmsformat(this.getDivision(this.dashSummary.idle_time,this.dashData.length),'clock'),
        }];

        
        this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);

        
  }

  getDivision(num,denm,delim:any="",type:any=""){
    if(!num || !denm){
        return 0;
    }
    if(!denm || (denm == 0)){
        return 0;
    }else{
        let val = Math.abs(num/denm);
        if(type == 'percentage'){
            return Math.abs(num*100/denm).toFixed(0)
        }else{
            if((delim != undefined) && (delim > -1) && (!isNaN(delim))){
                return val.toFixed(parseInt(delim));
            }else{
                return val.toFixed(1);
            }
        }
    }
}

getHMSDelimiter(seconds,delimiter,type){
  let arr: any[] = [0,0,0];
  if(!seconds){
    return arr.join(delimiter)
  }
  let d = 0
  if(seconds > 24*3600){
    d = Math.floor(seconds / (24*3600))
  }
  arr[0]   = Math.floor(seconds / 3600) % 24;
  arr[1] = Math.floor(seconds / 60) % 60;
  arr[2] = seconds % 60;
  if(d){
    arr[0] += d*24
  }
  if(type == '0int'){
    for(var i in arr){
      if(arr[i] < 10){
        arr[i] = '0'+String(arr[i])
      }
    }
  }
  return arr.join(delimiter);
}

gethmsformat(sec,mode){
  if(!sec){
      return 0;
  }
  var hrs, min, seconds;
  hrs = sec/3600;
  var temp = sec % 3600;
  min = temp/60;
  seconds = temp % 60;
  function getDigit(digit){
      if(!digit){
          return ''
      }else{
          digit = Math.floor(digit);
          if(digit > 9){
              return digit;
          }else{
              return ('0'+digit);
          }
      }
  }
  switch (mode){
      case 'clock':

          return getDigit(hrs)+' : '+getDigit(min)+' : '+getDigit(seconds);
          break;
      default:
          return Math.round(hrs)+'hrs '+Math.round(min)+'min '+Math.round(seconds)+'sec';
      break;
  }
}


  prepareTableData() {
    this.currentLoader = true;
    this.summary = this._summary();
    let data = null;
    let arr = [];
    this.dashService.pm_dash_inbound_data.subscribe((d) => data = d);
    
    this.inboundDashboardData = {...this.inboundDashboardData,...data.inboundData};
    this.outboundDashboardData = {...this.outboundDashboardData,...data.inboundData};
    // this.outboundDashboardData = {...this.outboundDashboardData,...data.dispositionsData};
    this.inboundAgentsData = data.agentsData;
    
    this.inboundDashboardData.total_calls = this.inboundDashboardData.ib_total_calls || 0;
    this.inboundDashboardData.total_calls_perc = this.calculatePercBy(this.inboundDashboardData.total_calls,this.inboundDashboardData.total_calls)

    this.inboundDashboardData.received_calls = this.inboundDashboardData.ib_received_calls || 0;
    this.inboundDashboardData.received_calls_perc = this.calculatePercBy(this.inboundDashboardData.received_calls,this.inboundDashboardData.total_calls)

    this.inboundDashboardData.abonden_calls = this.inboundDashboardData.ib_abonden_calls || 0;
    this.inboundDashboardData.abonden_calls_perc = this.calculatePercBy(this.inboundDashboardData.abonden_calls,this.inboundDashboardData.total_calls)

    this.inboundDashboardData.total_aht = Math.round(this.inboundDashboardData.ib_total_aht/60) || 0;
    this.inboundDashboardData.total_aht_label = this.getHMSDelimiter(this.inboundDashboardData.total_aht || 0,':','0int') ;

    this.inboundDashboardData.avg_aht = Math.round(this.inboundDashboardData.ib_avg_aht/60) || 0;
    this.inboundDashboardData.avg_aht_label = this.getHMSDelimiter(this.inboundDashboardData.ib_avg_aht || 0,':','0int') ;
    this.inboundDashboardData.total_aht_perc = this.inboundDashboardData.total_aht > 0 ? "100" : "0.0";
    this.inboundDashboardData.avg_aht_perc = this.inboundDashboardData.ib_avg_aht ? this.calculatePercBy(this.inboundDashboardData.ib_avg_aht, this.inboundDashboardData.ib_total_aht) : "0.0";
    
    this.outboundDashboardData.total_calls = this.outboundDashboardData.ob_total_calls || 0;
    this.outboundDashboardData.total_calls_perc = this.calculatePercBy(this.outboundDashboardData.total_calls,this.outboundDashboardData.total_calls)

    this.outboundDashboardData.received_calls = this.outboundDashboardData.ob_received_calls || 0;
    this.outboundDashboardData.received_calls_perc = this.calculatePercBy(this.outboundDashboardData.received_calls,this.outboundDashboardData.total_calls)

    this.outboundDashboardData.abonden_calls = this.outboundDashboardData.ob_abonden_calls || 0;
    this.outboundDashboardData.abonden_calls_perc = this.calculatePercBy(this.outboundDashboardData.abonden_calls,this.outboundDashboardData.total_calls)

    this.outboundDashboardData.total_aht = Math.round(this.outboundDashboardData.ob_total_aht/60) || 0;
    this.outboundDashboardData.total_aht_label = this.getHMSDelimiter(this.outboundDashboardData.total_aht || 0,':','0int') ;

    this.outboundDashboardData.avg_aht = Math.round(this.outboundDashboardData.ob_avg_aht/60) || 0;
    this.outboundDashboardData.avg_aht_label = this.getHMSDelimiter(this.outboundDashboardData.ob_avg_aht || 0,':','0int') ;
    this.outboundDashboardData.total_aht_perc = this.outboundDashboardData.total_aht > 0 ? "100" : "0.0";
    this.outboundDashboardData.avg_aht_perc = this.outboundDashboardData.ob_avg_aht ? this.calculatePercBy(this.outboundDashboardData.ob_avg_aht, this.outboundDashboardData.ob_total_aht) : "0.0";

   

    //console.log('table data', this.inboundDashboardData);
    this.dataSource = new MatTableDataSource(this.inboundAgentsData.agents);
    this.dataSource.sort = this.tableSort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
      return data.name.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase().includes(filter) 
   };
    this.preparePieChart(this.inboundAgentsData);

    this.currentLoader = false;
  }

  getCallStatus() {
    this.refreshData();
  }

  getSlotStatus() {
    this.refreshDataCallSlot()
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addSlot() {
    const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '70%',
			disableClose: true,
			data: '',
		});

		dialogRef.afterClosed().subscribe(result => {
		//	console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
  }
  editSlot(element: any) {
  }
  changeTogleStatus(value) {
    this.selectedTabSts = value;
    if (value == 'slot_status') {
      this.getSlotStatus();
    } else {
      this.getCallStatus();
    }
  }

  getStatusColor(val) {
    switch (val) {
      case '1':
        return 'red';
        break;
      case '2':
        return 'green';
        break;
      default:
        return 'orange';
    }
  }

  change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
    
    if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			}
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				alert("Start date should be less than end date");
			} else {

			}

		}
  }
  change_date(key: string, event: MatDatepickerInputEvent<Date>) {

		if (key == 'start_date') {
			this.slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				// alert("Start date should be less than end date");
				return;
			}
		} else {
			this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert="Start date should be less than end date *";
				this.showHide_messageAlert=true;
				return;
			} else {
				this.showHide_messageAlert=false;
			}

		}
	}

  preparePieChart(data) {
    const total_mins = data['0-5 Min']+data['5-30 Min']+data['>30 Min'];
    let chartdata = new Chart({
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            distance: -30,
            enabled: true,
            color: 'white',
            style: {
              fontWeight: '500',
            }
          },
          showInLegend: false,
          borderWidth: 5,
        },
      },

      series: [
        {
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              enabled: false
            }
          },
          name: 'Active',
          data: [

            {
              name: ""+this.calculatePercBy(data['0-5 Min'],total_mins),
              y: Number(this.calculatePercBy(data['0-5 Min'],total_mins)), color: '#e40f59'
            }, {
              name: ""+this.calculatePercBy(data['5-30 Min'],total_mins),
              y: Number(this.calculatePercBy(data['5-30 Min'],total_mins)), color: '#1b7ec7'
            },
            {
             name: ""+this.calculatePercBy(data['>30 Min'],total_mins),
              y: Number(this.calculatePercBy(data['>30 Min'],total_mins)), color: '#08566e'
            }
          ],
          type: 'pie',

          innerSize: '40%',
        }]
    });

    this.agentStatusChart = chartdata;
  }
  calculatePercBy(num1, num2) {
    if (num1 == 0 && num2 == 0) {
      return "0.0";
    }
    var value: any = (num1 / num2) * 100;
    value = value.toFixed(1);
    return value
  }
  selectChildren() {    
    if (this.selectedAgents.every(a => a.checked)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    this.selectedAgentsLen = this.selectedAgents.filter(e => e.checked === true).length;
  }

  updateCheck() {
    if (this.selectAll === true) {
      this.selectedAgents.map((agent) => {
        agent.checked = true;
      });
      this.selectedAgentsLen = this.selectedAgents.length;
    } else {
      this.selectedAgents.map((agent) => {
        agent.checked = false;
      });
      this.selectedAgentsLen = 0;
    }
  }

  downloadAgentLoginReport(){
    this.API.downloadAgentLoginInboundReport(this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')).subscribe(
        (res:any) => {
          // debugger;
          if (res.url) {
            this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
              () => {
                // campaign_data.showLoader = false;
              }
            )
          } else {
            alert(res.errors)
          }
        }, (err) => {
          // debugger;
        }
    )
  }

   downloadAgentActivityReport(){
    this.API.downloadAgentActivityInboundReport(this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'),this.api_end_point).subscribe(
        (res:any) => {
          // debugger;
          if (res.url) {
            this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
              () => {
                // campaign_data.showLoader = false;
              }
            )
          } else {
            alert(res.errors)
          }
        }, (err) => {
          // debugger;
        }
    )
  }

  downloadInboundcallsReport(){
    this.API.downloadCallStatusInboundReport(this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'),this.api_end_point).subscribe(
        (res:any) => {
          // debugger;
          if (res.url) {
            this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
              () => {
                // campaign_data.showLoader = false;
              }
            )
          } else {
            alert(res.errors)
          }
        }, (err) => {
          // debugger;
        }
    )
  }
  navigateTo(value){
   // console.log(value);
    this.selectedChannel = value;
    this.router.navigate(["/"+value]);
  }
}

