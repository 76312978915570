import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; // for html purpose
import { Subscription } from 'rxjs'
import { DialerService } from '../../desktop-calling/dialer.service';
import { MyserviceService } from '../../myservice.service';

@Component({
    selector: 'call-answer-dialog',
    templateUrl: './call-answer-dialog.component.html',
    styleUrls: ['./call-answer-dialog.component.scss']
})

export class CallAnswerDialogComponent implements OnInit, OnDestroy {
    public is_dialog_opened: boolean = false;
    public subscription = new Subscription();
    public user = this.my_service.get_loggedin_user();
    constructor(private dialogRef: MatDialogRef<CallAnswerDialogComponent>, 
        private dialer_service: DialerService, private my_service: MyserviceService) {};

    event_subscription = this.subscription.add(
        this.dialer_service.change_agent_sip_session().subscribe(val => {
            if (val.status == 'terminated') {
                if (this.is_dialog_opened) {
                    this.dialogRef.close(false);
                }
            }
        })
    )

    ngOnInit(): void {
        this.is_dialog_opened = true;
    }

    ngOnDestroy(): void {
        this.is_dialog_opened = false;
        this.event_subscription.unsubscribe();
    }
}