import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import {Router} from '@angular/router';
import { TrainerInfoDialogComponent } from '../trainer-info-dialog/trainer-info-dialog.component';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-trainer-list',
  templateUrl: './trainer-list.component.html',
  styleUrls: ['./trainer-list.component.scss']
})
export class TrainerListComponent implements OnInit {
   displayedColumns = ['name', 'batch_code', 'rating', 'details'];
  dataSource:any = new MatTableDataSource();  
  @ViewChild(MatPaginator) paginator: MatPaginator;
showHideAllTrainers:boolean=true;
showHideAvailibity:boolean=false;
api_end_point:any;
  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private myService:MyserviceService) { }

  ngOnInit() {
 this.getTrainerListTable();
 this.api_end_point = this.myService.get_API_End_Point();
  }
getTrainerListTable(startDate: string = "", endDate: string = ""){
  this.API.trainersAllTrainersList(startDate, endDate,"",this.api_end_point).subscribe((response:any)=>{
    if(response.success){
      let ELEMENT_DATA=  response.success;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function(data, filter: any): boolean {
          return data.name.toString().toLowerCase().includes(filter) || data.batch_code.toString().toLowerCase().includes(filter)  || data.rating.toString().toLowerCase().includes(filter) 
        };
    }  
  })
}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  createNewBatch(){
   // this.router.navigateByUrl('/training/create_trainer');
   
  }
	openViewDetails(element:any) {
    // this.router.navigateByUrl('/create_trainer');
		const dialogRef = this.dialog.open(TrainerInfoDialogComponent, {
			width: '50%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		  });
  }
  changeTogleTrainer(value){
    if(value=="allTrainers"){
      this.showHideAllTrainers=true;
      this.showHideAvailibity=false;
      this.getTrainerListTable();
    }
    else if(value=="availability"){
      this.showHideAllTrainers=false;
      this.showHideAvailibity=true;
    }

  }
  filterAvailablity(dateObj){
    // this.getTrainerListTable(dateObj.startDate, dateObj.endDate);
  }
}
