<button mat-raised-button (click)="closedialog()">Close</button>
<div class="customer_view">
	<table>
		<tr>
			<th>Employee Name</th>
			<td>{{inputObj.customer_name}}</td>
		</tr>
		<tr>
			<th>Employee Number</th>
			<td>{{inputObj.customer_number}}</td>
		</tr>
		<tr>
			<th>Agent Name</th>
			<td>{{inputObj.agent_name}}</td>
		</tr>
		<tr>
			<th>Agent Number</th>
			<td>{{inputObj.agent_number}}</td>
		</tr>
		<tr>
			<th>Call</th>
			<td>
				<audio controls>
					<source src="{{inputObj.call_url}}" type="audio/mpeg">
				  Your browser does not support the audio element.
				  </audio>
			</td>
		</tr>
		<tr>
			<th>Call Start Time</th>
			<td>{{inputObj.start_time}}</td>
		</tr>
		<tr>
			<th>Call End Time</th>
			<td>{{inputObj.end_time}}</td>
		</tr>
	</table>
</div>
<div class="form_feedback">
	<table>
		<tr *ngFor="let f of inputObj.feedback">
			<td>{{f.question}}</td>
			<th>{{f.answer}}</th>
		</tr>
	</table>
</div>