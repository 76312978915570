<div class="headercontainer"> 
    <div class="div1">      
        <img src="../assets/web_images/uearn_logo.svg" class="LogoForAuditor"/>
      </div> 
    <div class="div2" style="padding-right: 45px;">
<div class="d-flex flex-row justify-content-end ">
    <div class="AM-uname"><span><button mat-raised-button  class="postbtn" (click)="publishForm()" >Publish</button></span></div>  
</div>
    </div>
</div> 
<div>
    <mat-sidenav-container autosize style="min-height: calc(100vh);" >
      <mat-sidenav #sidenav class="TR-sidenav" mode="side" opened="true" style="width: 100px !important;">
        <div>         
        <mat-nav-list *ngFor="let v of tabNameList" style="margin: 10px 0px;padding: 5px !important;
        height: 45px !important;">   
          <mat-list-item  (click)="clickTab(v.name)"  [routerLink]="[v.routerLinkUrl]" [routerLinkActive]="v.active" >
            <div class="d-flex flex-row justify-content-end ">
              <div class="mr-4"> <mat-icon aria-hidden="false" aria-label=""  class="TR-icon" style="color: #AEB8C6;font-size: 40px;">{{v.iconName}}</mat-icon> </div> 
              <!-- <div class="ml-1" style="padding-top: 4px;font-size: 18px;"> {{v.name}} </div> -->
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
      </mat-sidenav>
  <!--start-->
  <div class="TR-sidenav-content">
    <router-outlet></router-outlet>
  </div>
  <!--end-->    
    </mat-sidenav-container>    
    </div>