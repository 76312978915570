import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-leave-dialog',
  templateUrl: './profile-leave-dialog.component.html',
  styleUrls: ['./profile-leave-dialog.component.scss']
})
export class ProfileLeaveDialogComponent implements OnInit {
  todayDate=new Date();
  constructor() { }
  //public dialogRef: MatDialogRef<ProfileLeaveDialogComponent>,

  ngOnInit(): void {
  }
  closeDialog(){
   // this.dialogRef.close();
  }
}
