import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-onboarding-process-page2',
  templateUrl: './onboarding-process-page2.component.html',
  styleUrls: ['./onboarding-process-page2.component.scss']
})
export class OnboardingProcessPage2Component implements OnInit {
  createdForm:UntypedFormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  constructor(private router:Router,public formbuilder:UntypedFormBuilder,public API :ApiService) { 
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      agentHuntingActive:false,
      agentHuntingAvailable: false,
      agentHuntingOther:false,
      agentHuntingOtherDesc:[''],
      cliSBTeam:false,
      cliCustomer:false,
      callingSIP:false,
      callingSim:false,
    })
  }

  ngOnInit(): void {
  }
  onNextBtn(createdForm: any){
   // debugger;
    this.messageDisplay="";
    this.showHideMessage = false;

    let _agentHuntingActive = createdForm.controls["agentHuntingActive"].value;
    let _agentHuntingAvailable = createdForm.controls["agentHuntingAvailable"].value;
    let _agentHuntingOther = createdForm.controls["agentHuntingOther"].value;
    let _agentHuntingOtherDesc = createdForm.controls["agentHuntingOtherDesc"].value;

    let _cliSBTeam=  createdForm.controls["cliSBTeam"].value;
    let _cliCustomer=  createdForm.controls["cliCustomer"].value;

    let _callingSIP=  createdForm.controls["callingSIP"].value;
    let _callingSim=  createdForm.controls["callingSim"].value;

    if (_agentHuntingActive == false && _agentHuntingAvailable ==false && _agentHuntingOther ==false) {
      this.messageDisplay = "Please select atleast one agent hunting*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if(_agentHuntingOther ==true){
      if(_agentHuntingOtherDesc==""){
        this.messageDisplay = "Please enter other description*";
        this.msgColor = "red";
        this.showHideMessage = true;
        window.scroll({
          top: 20,
          left: 0,
          behavior: 'smooth'
        });
        return;
      }
    }
    let _obj = {
      "agentHuntingActive": _agentHuntingActive,
      "agentHuntingAvailable": _agentHuntingAvailable,
      "agentHuntingOther": _agentHuntingOther,
      "agentHuntingOtherDesc": _agentHuntingOtherDesc,
      "cliSBTeam": _cliSBTeam,
      "cliCustomer":_cliCustomer,
      "callingSIP": _callingSIP ,
      "callingSim":_callingSim
    }
    this.messageDisplay = "Good.., Now you are going to next page..!!";
    this.msgColor = "green";
    this.showHideMessage = true;
    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>{
      this.router.navigateByUrl('/onboarding_process_page3')
    },3000)
  
      }
}
