<!-- <p>Download Attachment works!</p> -->
<div mat-dialog-title class="d-flex justify-content-between align-items-center">
  <!-- <div class="f-20-px w-100">Download Campaign</div> -->
  <!-- <mat-icon mat-dialog-close class="c-pointer w-24-px h-24-px" (click)="closePopup()">close</mat-icon> -->
</div>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Link</mat-label>
    <input type="text" matInput id="username" name="username" #username placeholder="Paste the link to download" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="d-flex w-100 justify-content-end gap-12-px">
    <button mat-raised-button mat-dialog-close>
      <i class="fa fa-times" aria-hidden="true"></i>&nbsp;&nbsp;CANCEL
    </button>
    <button mat-raised-button type="button" (click)="clickme(username.value)">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;DOWNLOAD
    </button>
  </div>
</div>