<mat-card>
    <mat-card-header>
        <mat-card-title>Timeout Error!</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Looks like the server is taking too long to respond, please try again in sometime.</p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button cdkFocusInitial mat-dialog-close>Close</button>
        <button mat-raised-button class="" (click)="close('refresh')">Retry</button>
      </mat-card-actions>
</mat-card>