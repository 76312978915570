import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { PmdashboardDailogComponent } from '../pmdashboard-dailog/pmdashboard-dailog.component';
import { ServicesService } from '../dashboard/services.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MoreSlotDialogComponent } from '../more-slot-dialog/more-slot-dialog.component';
import { timeStamp } from 'console';
import { ContactRmUploadDailogComponent } from './contact-rm/contact-rm-upload-dailog/contact-rm-upload-dailog.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

interface Alert {
	type: string;
	message: string;
}

@Component({
	selector: 'app-timeslot-setting',
	templateUrl: './timeslot-setting.component.html',
	styleUrls: ['./timeslot-setting.component.scss']
})
export class TimeslotSettingComponent implements OnInit {

	currentTitle = 'settings';
	currentLoader = false;
	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	myUser: any;
	slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	alert: Alert = { type: 'success', message: "Refreshed successfully..." };
	alertClosed = true;
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	dataSource: any = new MatTableDataSource();
	obj: any = {};

	displayedColumnsCallSlot = [
		'date',
		'start_time',
		'end_time',
		//'time',
		"capacity",
		'booked',
		'waiting',
		//'occupancy',
		'status',
		//'options',
		'more',
		'id'
	];

	_tableDataCallSlot = function () {
		return {
			'date': "",
			'start_time': "",
			'end_time': "",
			"capacity": 0,
			'booked': 0,
			'waiting': 0,
			//'occupancy': 0,
			'status': "",
			//'options': "",
			'more': "",
			'id': ""
		}
	}

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) tableSort: MatSort;

	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	selectedButtonToggleVal: string = 'AgentSlot';
	showHideAgentSlot: boolean = true;
	showHideContactRM: boolean = false;
	isshowHideContactRM: boolean = false;
	isshowHideAgentSlot: boolean = true;
	selectAll = true;
	selectedAgents: any[] = []
	selectedAgentsLen: number = 0;
	api_end_point:any;

	constructor(private dashService: ServicesService, private myService: MyserviceService, private dialog: MatDialog, public router: Router, private API: ApiService, private utils: Utils, private _snackBar: MatSnackBar) { }


	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		//current date
		console.log(this.callStartDate, this.callEndDate)
		this.myUser = this.myService.get_loggedin_user();
		this.refreshDataCallSlot();
		//this.selectedAgents = [{ name: 'name1' }, { name: 'name2' }];
		
	}

	refreshDataCallSlot() {

		let currentdate: any = {};
		currentdate.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		currentdate.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		this.dashService.getDatewiseSlots(currentdate,this.api_end_point).then((resolve) => {
			console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareSlotTableData();
		}, (err) => {
			// alert(err);
			this.currentLoader = false;
			this.alert.type = "danger";
			this.alert.message = err;
			this.alertClosed = false;
			setTimeout(() => this.alertClosed = true, 3000);
			this.dataSource = new MatTableDataSource([]);

			this.dataSource.paginator = this.paginator;
		})

		this.currentLoader = true;
		let obj: any = {};
		obj.start_date = this.utils.localToUTC(this.slotStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.slotEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')


		this.API.getFullTeamData(this.api_end_point).subscribe(
			(res: any) => {
				if (res.success) {
					//console.log(res.success);
					this.myService.set_Full_team(res.success);
					//this._searchValue.nativeElement.value = "";
					if (this.selectedAgents.length == 0) {
						for (const item of this.myService.getAllIds('user')) {
							let agent = { name: item.name, user_id: item.id, checked: true };
							this.selectedAgents.push(agent);
						}
						obj.agents_list = this.myService.getAllIds('user', 'id');
						this.selectedAgentsLen = this.selectedAgents.length;
					} else {
						obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
						//console.log("---", JSON.stringify(obj.agents_list));
						this.selectedAgentsLen = obj.agents_list.length;
					}
				}
			});
		this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				console.log(data.errors);
				return;
			}
			if (data.success) {
				console.log(data.success);
				var setting = data.success;

				for (const slotsetting of setting.workslots) {
					if (slotsetting.name == "ASF" && slotsetting.definition == "on") {
						this.isshowHideAgentSlot = true;
						this.showHideAgentSlot = true;
						for (const settingitem of setting.extraoptions) {
							//console.log(settingitem.name);
							//console.log(settingitem.name=="agenttocustomermapping");
							if (settingitem.name == "agenttocustomermapping" && settingitem.definition == "on") {
								this.isshowHideContactRM = true;
								//this.showHideContactRM = true;
							}
		
						}
					} else {
						this.isshowHideAgentSlot = false;
						this.showHideAgentSlot = false;

						for (const settingitem of setting.extraoptions) {
							//console.log(settingitem.name);
							//console.log(settingitem.name=="agenttocustomermapping");
							if (settingitem.name == "agenttocustomermapping" && settingitem.definition == "on") {
								this.isshowHideContactRM = true;
								this.showHideContactRM = true;
							}
		
						}
					}
				}
			}
		})

	}

	addSlot() {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '70%',
			disableClose: true,
			data: '',
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	}

	prepareSlotTableData() {
		this.currentLoader = true;
		let data = null;
		let arr = [];
		var now = new Date().getTime();
		var currenttime = moment(now).format('HH:mm:ss');
		this.dashService.pm_dash_slot_data.subscribe((d) => data = d);
		let d = data['success'];

		//debugger;
		for (var i in d) {
			let loc = d[i];
			var endtime, starttime;
			let obj = this._tableDataCallSlot();
			obj['date'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('L');
			//obj['time'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss') + " - " + moment(loc['slot_end_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
			obj['start_time'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss');
			//obj['start_time'] =
			obj['end_time'] = moment(loc['slot_end_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss');
			obj['capacity'] = loc['max_limit'];
			obj['booked'] = loc['current_booked'];
			obj['waiting'] = loc['waiting'];
			//obj['occupancy'] = loc['waiting'];
			//obj['occupancy'] = loc['waiting'];
			obj['status'] = loc['status'];
			obj['id'] = loc['id'];
			//console.log();
			arr.push(obj);
		}

		this.dataSource = new MatTableDataSource(arr);
		console.log("all details", this.dataSource.data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.filterPredicate = function (data, filter: any): boolean {
			return data.date.toString().toLowerCase().includes(filter)
		};
		this.currentLoader = false;
	}

	change_date(key: string, event: MatDatepickerInputEvent<Date>) {

		if (key == 'start_date') {
			this.slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				// alert("Start date should be less than end date");
				return;
			}
		} else {
			this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert = "Start date should be less than end date *";
				this.showHide_messageAlert = true;
				return;
			} else {
				this.showHide_messageAlert = false;
			}

		}
	}

	editSlot(element: any) {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '50%',
			disableClose: true,
			data: element
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	Moresetting(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		console.log(JSON.stringify(data));
		var now = new Date().getTime();
		var currenttime = moment(now).format('HH:mm:ss');
		var starttime, endtime;
		starttime = data.start_time;
		endtime = data.end_time;

		if (currenttime > starttime && currenttime > endtime) {
			this._snackBar.open("slot Expire", "close");
		} else {
			//debugger;
			let _element = {
				"_element": data,
			}
			//'this.dataSource[0]'
			const dialogRef = this.dialog.open(MoreSlotDialogComponent, {
				width: '90%',
				disableClose: true,
				data: _element
			});

			dialogRef.afterClosed().subscribe(result => {
				console.log(result);
				this.refreshDataCallSlot()
			});
		}
	}
	onToggleButtonValChange(val: string) {
		this.selectedButtonToggleVal = val;
		this.showHideAgentSlot = false;
		this.showHideContactRM = false;
		if (val == 'AgentSlot') {
			this.showHideAgentSlot = true;
		}
		else if (val == 'ContactRM') {
			this.showHideContactRM = true;
		}
	}

	updateCheck() {
		if (this.selectAll === true) {
			this.selectedAgents.map((agent) => {
				agent.checked = true;
			});
			this.selectedAgentsLen = this.selectedAgents.length;
		} else {
			this.selectedAgents.map((agent) => {
				agent.checked = false;
			});
			this.selectedAgentsLen = 0;
		}
	}
	selectChildren() {
		if (this.selectedAgents.every(a => a.checked)) {
			this.selectAll = true;
		} else {
			this.selectAll = false;
		}
		this.selectedAgentsLen = this.selectedAgents.filter(e => e.checked === true).length;
	}
	uploadNew() {

		if (this.selectAll === true) {
			console.log("--all--", this.selectedAgents);
			this.obj = this.selectedAgents;
		} else {
			this.obj = this.selectedAgents.filter(e => e.checked === true);
			console.log("--selected only--", this.obj);
		}

		const dialogRef = this.dialog.open(ContactRmUploadDailogComponent, {
			width: '40%',
			disableClose: true,
			data: this.obj
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == true) {

				//this.fetchAgents();
			}
		});
	}
	download_file() {
		var users_list: any = []
		let selecteduser;
		if (this.selectAll === true) {
			console.log("--all--", this.selectedAgents);
			selecteduser = this.selectedAgents;
		} else {
			selecteduser = this.selectedAgents.filter(e => e.checked === true);
			console.log("--selected only--", selecteduser);
		}

		for (const property in selecteduser) {
			console.log(`${property}: ${selecteduser[property].user_id}`);
			users_list.push(selecteduser[property].user_id);
		}
		var obj = { users_list: users_list };
		console.log(JSON.stringify(obj));
		this.API.getContacts(obj,this.api_end_point).subscribe(
			(res: any) => {
				console.log(res);
				if (res.status) {

					console.log(JSON.stringify(res.contacts_data));
					//this.convertintoexcel= res.success.contacts_data
					//this._snackBar.open("success", "close");
					let _data_mapper = {
						"Customer ID": "Customer ID",
						"Customer Name": "Customer Name",
						"Customer Contact": "Customer Contact",
						"Customer Email ID": "Customer Email ID",
						"RM Email": "RM Email",
						"RM Name": "RM Name",
						"RM Number": "RM Number"

					}
					let data = res.contacts_data;
					let headers = Object.values(_data_mapper);
					if (data && data.length) {
						let workbook = new Workbook();
						let worksheet = workbook.addWorksheet('Customer Data');
						worksheet.addRow(headers);
						data.forEach(d => {
							let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
							//	console.log('printing row', row);
						})
						//debugger;

						workbook.xlsx.writeBuffer().then((data) => {
							let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
							fs.saveAs(blob, 'Customer_download_details.xlsx');
						})
					}

				} else {
					this._snackBar.open(res.errors, "close");
				}
			})
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {

				if (ref[i] == 'Customer ID' && obj['Customer ID']) {
					console.log(ref[i], obj)
					arr.push(obj['Customer ID'])
				} else {
					arr.push(obj[ref[i]])
				}
			} else {
				arr.push('')
			}
		}
		return arr;
	}


}
