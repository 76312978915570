import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'

import { MyserviceService } from './myservice.service'
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()

export class TokenInterceptorService implements HttpInterceptor {
  _userid: any;
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let service = this.injector.get(MyserviceService);
    let url = req.url;
    const sub_urls = [
      "/auth",
      "/check_login.json",
      "/gettoken",
      "softdial/repository",
      "/cmail_users.json?ver=2",
      "/reset_password",
      "/changepwd_withtoken",
      "/demo_request",
      "/placecall",
      "/config/clientDetails",
      "/callCustomer",
      "/chat/message",
      "/createdobd",
      "/sendmailstocustomers",
      "/linkedin/userInfo",
      "/gateway/WA/create_template",
      "/gateway/WA/update_template",
      "/new_contactus",
      "/gateway/wa/delete_template",
      "api.ipify.org",
      "/speedtest"
    ]
    // console.log('url', url)
    //url.indexOf("smarter-biz.com") == -1 &&
    if (sub_urls.every(e => url.indexOf(e) < 0)) {
      // console.log(service.get_access_token())
      // console.log('if condition works', url);
      var headers = {
        'x-access-token': service.get_access_token(),
        'Authorization': "Bearer " + service.get_access_token()
      }
      if (url.indexOf('ainex') > -1) {
        this._userid = service.get_loggedin_user();
        // console.log(this._userid)
        headers['userid'] = "" + this._userid.id;
        headers['token'] = localStorage.getItem('ainex_token');
      }
      let tokenRequest = req.clone({
        setHeaders: headers,
        setParams: {
          access_token: service.get_access_token()
        }
      })

      return next.handle(tokenRequest).pipe(catchError((error)=>{
        // console.log(error)
        return throwError(error)
      }));
    } else {
      // console.log('going for login request',url)
      // debugger;
      return next.handle(req);
    }
  }
}
