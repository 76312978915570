<div>
    <form [formGroup]="createdForm">
    <div class="row">
        <div class="col-md-12">
            <span> <b>Add Agent</b> </span>
        </div>
    </div>
    <p class="msgerrorcs" [ngStyle]="{'color':msgColor}" *ngIf="showHideMsg">{{showHideErrorMsg}}</p>
    <div class="row">
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Agent Name</label><br>
                <span class="inputWdSp">
                    <input matInput autocomplete="off" maxlength="100" required  formControlName="agentName"  (keypress)="omit_special_char($event)">
                </span>
            </p>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Agent Email</label><br>
                <span class="inputWdSp">
                    <input matInput [readonly]="!showHideStdForEdit" autocomplete="off" maxlength="100" required  formControlName="agentEmail">
                </span>
            </p>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Password</label><br>
                <span class="inputWdSp">
                    <input matInput  type="password" autocomplete="off" maxlength="30" required  formControlName="agentPassword">
                </span>
            </p>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Confirm Password</label><br>
                <span class="inputWdSp">
                    <input matInput  type="password" autocomplete="off" maxlength="30" required  formControlName="agentConfirmPassword">
                </span>
            </p>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-6">
            <div class="mb-1 mt-4"> 
                <p class="mb-0 mt-4"><label class="lblSopify">Phone Number</label><br></p>
                <table style="width:100%;">
                    <tr>
                        <td style="width:25%" *ngIf="showHideStdForEdit">
                            <span  class="inputWdSp">
                                <mat-select required  formControlName="agentStdCode">
                                    <mat-option value="+91">+91</mat-option>
                                    <mat-option value="+1">+1</mat-option>                        
                                  </mat-select>
                            </span> 
                        </td>
                        <td>     <span class="inputWdSp" >
                            <input matInput [readonly]="!showHideStdForEdit" autocomplete="off" maxlength="15" style="width:100%" (keypress)="omitSpaceChar($event)" required  formControlName="agentPhoneNumber">
                        </span><span *ngIf="!showHideStdForEdit" style="font-size: 0.68em;color: #b1b0b0;display: block;"> (eg: +919001234567)</span></td>
                    </tr>
                </table>
               
            
            </div>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Category</label><br>
                <span class="inputWdSp">
                    <mat-select required  formControlName="agentCategory">
                        <mat-option value="Sales Enquiry">Sales Enquiry</mat-option>    
                        <mat-option value="General Enquiry">General Enquiry</mat-option>            
                      </mat-select>
                </span>
            </p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12 text-right">
            <button class="prevAdd mt-0 ml-3" style="color:gray !important ;border:1px solid gray !important;background: #fff !important;"  (click)="closePopup()">
                CANCEL
            </button>
    
            <button class="prevAdd mt-0 ml-3"  (click)="submitBtn(createdForm)" >
               {{addEditText}}
            </button>
        </div>
    </div>
</form>
</div>