<app-menu [navigateLink]="currentTitle"></app-menu>

<div class="container-fluid main-section-layout">
  <ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed" (close)="alertClosed = true">
    {{ alert.message }}</ngb-alert>

  <div class="row">
	<!-- This is not required for manual dialling -->
    <!-- <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>New Data</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Total Data(Active & In Active)</span>
              <hr class="level1" [style.width.%]="this.summary.data.total_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.summary.data.total_perc}}%</span>({{this.summary.data.total}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Data Dialled</span>
              <hr class="level2" [style.width.%]="this.summary.dialled.unique_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.summary.dialled.unique_perc}}%</span>({{this.summary.dialled.unique}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Data Contacted</span>
              <hr class="level3" [style.width.%]="this.summary.contacted.unique_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.summary.contacted.unique_perc}}%</span>({{this.summary.contacted.unique}})</span>

          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Data to Call</span>
              <hr class="level4" [style.width.%]="this.summary.data.data_to_call_perc" /></span>

            <span class="tile-text"><span
                class="tile-percent">{{this.summary.data.data_to_call_perc}}%</span>({{this.summary.data.data_to_call}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Total Connected Calls</span>
              <hr class="level5" [style.width.%]="this.summary.contacted.total_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.summary.contacted.total_perc}}%</span>({{this.summary.contacted.total}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Total Calls</span>
              <hr class="level6" [style.width.%]="0" /></span>
            <span class="tile-text"><span class="tile-percent">{{this.summary.data.total_calls}}</span></span>
          </div>
        </mat-card-content>
      </mat-card>
    </div> -->
	<div class="col-md-3 col-sm-12 col-xs-12">
		<mat-card>
		  <mat-card-header>
			<mat-card-title>Total Calls</mat-card-title>
			<button mat-icon-button aria-label="Open in new tab" class="feedback_ext"
			  matTooltip="Detailed Form Dispositions" matTooltipPosition="before" (click)="openFeedback()">
			  <mat-icon>open_in_new</mat-icon>
			</button>
		  </mat-card-header>
		  <mat-card-content>
			<div class="tile-row">
			  <span class="pr-4 flex-fill"> <span class="tile-title">Dialled</span>
				<hr class="level1" [style.width.%]="this.dashSummary.calls_dialled_perc" /></span>
			  <span class="tile-text"><span class="tile-percent"></span>({{this.dashSummary.calls_dialled}})</span>
			</div>
			<div class="tile-row">
			  <span class="pr-4 flex-fill"> <span class="tile-title">RNR</span>
				<hr class="level2" [style.width.%]="this.dashSummary.calls_rnr_perc" /></span>
			  <span class="tile-text"><span
				  class="tile-percent">{{this.dashSummary.calls_rnr_perc}}%</span>({{this.dashSummary.calls_rnr}})</span>
			</div>
			<div class="tile-row">
			  <span class="pr-4 flex-fill"> <span class="tile-title">Contacted</span>
				<hr class="level3" [style.width.%]="this.dashSummary.calls_contacted_perc" /></span>
			  <span class="tile-text"><span
				  class="tile-percent">{{this.dashSummary.calls_contacted_perc}}%</span>({{this.dashSummary.calls_contacted}})</span>
			</div>
		  </mat-card-content>
		</mat-card>
	  </div>
    <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Follow ups</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Total</span>
              <hr class="level1" [style.width.%]="this.dashSummary.followup_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.dashSummary.followup_perc}}%</span>({{this.dashSummary.followup}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">Completed</span>
              <hr class="level2" [style.width.%]="this.dashSummary.followupdone_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.dashSummary.followupdone_perc}}%</span>({{this.dashSummary.followupdone}})</span>
          </div>
          <div class="tile-row">
            <span class="pr-4 flex-fill"> <span class="tile-title">ToDo</span>
              <hr class="level3" [style.width.%]="this.dashSummary.followuptodo_perc" /></span>
            <span class="tile-text"><span
                class="tile-percent">{{this.dashSummary.followuptodo_perc}}%</span>({{this.dashSummary.followuptodo}})</span>
          </div>
          <!--  <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Dialled</span><hr class="level3" [style.width.%]="50"/></span>
                        <span class="tile-text"><span class="tile-percent">50%</span>(677)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Unique Dialled</span><hr class="level4" [style.width.%]="35"/></span>
                        <span class="tile-text"><span class="tile-percent">25%</span>(266)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Contacted</span><hr class="level5" [style.width.%]="25"/></span>
                        <span class="tile-text"><span class="tile-percent">25%</span>(89)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Unique Contacted</span><hr class="level6" [style.width.%]="10"/></span>
                        <span class="tile-text"><span class="tile-percent">10%</span>(20)</span>
                    </div> -->
          <!-- <div class="d-flex flex-row justify-content-between py-2">
				<span>Total</span>
				<span class="pr-2">{{ summary.dialled.total }}</span>
			</div>
			<div class="d-flex flex-row justify-content-between py-2">
				<span>Unique</span>
				<span class="pr-2">{{ summary.dialled.unique }}</span>
			</div> -->
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Agents Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Total Agents</span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.dashData.length}}</span>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Today Logged IN</span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-2">
              <span
                class="tile-percent-primary pr-2">{{this.agentCallingStatus.login}}</span>
            </div>
		  </div>
		  <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agents Logged Out</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#D3BA80', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.logout }}</span>
            </div>
          </div>
		  <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent Active Today</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#33EB1A', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.active}}</span>
            </div>
		  </div>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent On Call</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#33EB1A', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.on_call}}</span>
            </div>
		  </div>
		  <!-- This is not required for manual dialling -->
          <!-- <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent Waiting < 10mins</span> </div> <div class="col-md-2">
                  <span class="statusCircle" [ngStyle]="{'background-color':'#FF8C00', border: 'none' }"></span>
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.wait_l_10 }}</span>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent Waiting > 10mins</span>
            </div>
            <div class="col-md-2">
              <span class="statusCircle" [ngStyle]="{'background-color':'#87CEFA', border: 'none' }"></span>
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.wait_g_10 }}</span>
            </div>
          </div>-->
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent OnBreak</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.break }}</span>
            </div>
		  </div>
        </mat-card-content>
      </mat-card>
	</div>
	<div class="col-md-3 col-sm-12 col-xs-12 pr-3">
		<mat-card>
			<mat-card-header>
				<mat-card-title>Agents On Break</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row py-2">
					<div class="col-md-8">
					  <span class="tile-percent">Bio Break</span>
					</div>
					<div class="col-md-2">
					  <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
					</div>
					<div class="col-md-2">
					  <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.bio_break }}</span>
					</div>
				</div>
				<div class="row py-2">
					<div class="col-md-8">
					  <span class="tile-percent">Lunch Break</span>
					</div>
					<div class="col-md-2">
					  <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
					</div>
					<div class="col-md-2">
					  <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.lunch_break }}</span>
					</div>
				</div>
				<div class="row py-2">
					<div class="col-md-8">
					  <span class="tile-percent">Coffee Break</span>
					</div>
					<div class="col-md-2">
					  <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
					</div>
					<div class="col-md-2">
					  <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.coffe_break }}</span>
					</div>
				</div>
				<div class="row py-2">
					<div class="col-md-8">
					  <span class="tile-percent">Team briefing</span>
					</div>
					<div class="col-md-2">
					  <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
					</div>
					<div class="col-md-2">
					  <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.team_break }}</span>
					</div>
				</div>
				<div class="row py-2">
					<div class="col-md-8">
					  <span class="tile-percent">End of Day Work</span>
					</div>
					<div class="col-md-2">
					  <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
					</div>
					<div class="col-md-2">
					  <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.eod_break }}</span>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
  </div>


  <div class="md-12 sm-12 xs-12">
    <mat-card class="mat-card-table-responsive call-summary" style="padding: 0px !important;">
      <table mat-table [dataSource]="dataSourceCallSummary" class="mat-elevation-z8">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type </th>
          <td mat-cell *matCellDef="let element" class="CR">{{element.type}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef> Login Hours </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.time}} </td>
		</ng-container> -->

        <ng-container matColumnDef="login_time">
          <th mat-header-cell *matHeaderCellDef> Login Hours </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.login_time}} </td>
        </ng-container>

        <ng-container matColumnDef="spoken_time">
          <th mat-header-cell *matHeaderCellDef> Talk Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.spoken_time}} </td>
        </ng-container>

        <ng-container matColumnDef="wrap_time">
          <th mat-header-cell *matHeaderCellDef> Wrap Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.wrap_time}} </td>
        </ng-container>

        <ng-container matColumnDef="wait_time">
          <th mat-header-cell *matHeaderCellDef> Dial Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.wait_time}} </td>
        </ng-container>

        <ng-container matColumnDef="idle_time">
          <th mat-header-cell *matHeaderCellDef> Idle Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.idle_time}} </td>
        </ng-container>

        <ng-container matColumnDef="break_time">
          <th mat-header-cell *matHeaderCellDef> Break Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.break_time}} </td>
        </ng-container>

        <ng-container matColumnDef="unavailable_time">
          <th mat-header-cell *matHeaderCellDef> Unavailable Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.unavailable_time}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCallSummary; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCallSummary;"></tr>
      </table>
    </mat-card>
  </div>
  <div class="md-12 sm-12 xs-12">
    <app-tl-table-counts [table_data]="dataSource"></app-tl-table-counts>
  </div>
  <!-- <div class="md-12 sm-12 xs-12 app_activity_tbl">
	<app-activity></app-activity>
  </div> -->
  <div class="md-12 sm-12 xs-12">
    <mat-card class="mat-card-table-responsive">
      <p class="textAlertMessage" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
      <div class="row pdt filter-div  align-items-center">
        <div class="col">
          <!-- <span class="date-label">10-Jun-2020</span> -->
          <button mat-raised-button [matMenuTriggerFor]="export_menu" aria-label="Example icon-button with a menu">
            Reports
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #export_menu="matMenu">
            <!-- <button mat-menu-item (click)="downloadReportGeneral()">Call Report</button> -->
            <button mat-menu-item (click)="downloadCallStatusReport()">Call Report</button>
            <button mat-menu-item (click)="downloadAuditReport()">Audit Report</button>
            <button mat-menu-item (click)="agentSummaryReport()">Agent Summary Report</button>
            <button mat-menu-item (click)="exportAgentActivity()">Agent Activity Report</button>
          </mat-menu>
		</div>
		<!-- Sort by activity starts -->
		<div class="col">
			<button mat-raised-button [matMenuTriggerFor]="sortBy_menu" aria-label="Filter By">
				Filter
				<mat-icon>filter_list</mat-icon>
			</button>
			<mat-menu #sortBy_menu="matMenu">
				<button mat-menu-item (click)="resetTableDataByActivityFilter()">None</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Active')">Active</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Break')">Break</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Login')">Login</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Logout')">Logout</button>
				<button mat-menu-item (click)="filterTableDataByActivity('null')">No Activity</button>
			</mat-menu>
		</div>
		<!-- Sort by activity ends -->
		<div class="col">
			<mat-form-field style="width: 100%;">
				<input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
				  (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)"
				  [(ngModel)]="callStartDate">
				<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
				<mat-datepicker #frompicker></mat-datepicker>
			  </mat-form-field>
		</div>
		<div class="col">
			<mat-form-field class="ml-3" style="width: 100%;">
				<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
				  (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)"
				  [(ngModel)]="callEndDate">
				<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
				<mat-datepicker #topicker></mat-datepicker>
			  </mat-form-field>
		</div>
		<div class="col" *ngIf="!isToday">
			<button mat-raised-button class="btnAdd ml-3" (click)="refreshData(true)">Go</button>
		</div>
		<div class="col">
          	<button mat-raised-button class="btnAdd ml-3" (click)="refreshDatafortoday()">Today</button>
		</div>
		<div class="col">
          <div ngbDropdown class="d-inline-block ml-2">
			<!-- <span class="table-tag px-2">Select Agent</span> -->
			<button class="btn btn-outline-secondary"
              style="border-radius: 6px;" id="dropdownForm1"
              ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
              <div class="px-4 py-3" style="max-height: 300px;overflow-y: auto;">
                <mat-checkbox (change)="updateCheck()" class="example-margin" [(ngModel)]="selectAll">
                  Select All
                </mat-checkbox>
                <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                  <mat-checkbox (change)="selectChildren()" [(ngModel)]="ing.checked">
                    {{ing.name}}
                  </mat-checkbox>
                </section>
              </div>
            </div>
          </div>
		</div>
        <div class="col" style="margin-left: auto">
          <mat-form-field class="wdh">
            <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
            <i class="fa fa-search" matSuffix aria-hidden="true"></i>
          </mat-form-field>
        </div>
      </div>

      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 das-table"
          *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
          <ng-container matColumnDef="user_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
            <mat-cell *matCellDef="let element"  [ngStyle]="{'color': highlight_color(element)}">{{ element.name }}</mat-cell>
		  </ng-container>


      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Action  </mat-header-cell>
        <mat-cell *matCellDef="let element">         
        <span class="actionBtn" *ngIf="getBargeinBtn(element)" (click)="actionBergewishper(element,'BI')">Barge IN</span>
        <span class="actionBtn" *ngIf="getBargeToWishperBtn(element)" (click)="actionBergewishper(element,'BW')">Barge to whisper</span>
        <span class="actionBtn" *ngIf="getWishperToBargeBtn(element)" (click)="actionBergewishper(element,'WB')">Whisper to barge</span>
        </mat-cell>
  </ng-container>


		  <ng-container matColumnDef="live_status">
			<mat-header-cell *matHeaderCellDef mat-sort-header> Call Status </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<!-- <span class="statusCircle"
					[ngStyle]="{'background-color': getStatusColor(element), border: 'none' }">
				</span> -->
				<!-- <div *ngIf="isToday && getCallStatusObj(element) as call_status" style="display: grid;">
					<span *ngIf="call_status.text" style="font-weight: bold;" [ngStyle]="{'color':'call_status.color'}">{{ call_status.text }}</span>
					<span *ngIf="call_status.duration" style="font-weight: bold;">{{ call_status.duration }} min</span>
				</div> -->
				<div *ngIf="isToday && element.sub_disposition" style="display: grid;">
					<span>{{element.sub_disposition}}</span>
					<span>[{{element.duration_now}}] mins</span>
				</div>
			</mat-cell>
		  </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Active Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- <span class="statusCircle"
				[ngStyle]="{'background-color': getStatusColor(element), border: 'none' }"></span> -->
				<!-- <div *ngIf="isToday && getCurrentStatus(element) as loc_status" style="display: grid;">
					<span *ngIf="loc_status.msg" style="font-weight:bold;" [ngStyle]="{'color': loc_status.colour}">{{loc_status.msg}}</span>
					<span 
					*ngIf="loc_status.sync_time" 
					style="font-size: 10px;"
					matTooltip="Last Sync At - {{loc_status.sync_time}}"
                	matTooltipPosition="above"
					>{{loc_status.sync_time}}</span>
				</div> -->
				<div *ngIf="isToday" style="display: grid;">
					<span style="font-weight: bold;" [ngStyle]="{'color': element.activity_color}">{{ element.last_activity }}</span>
					<span 
					style="font-size: 10px;"
					matTooltip="Last Sync At - {{element.last_activity_at}}"
                	matTooltipPosition="above"
					>{{element.last_activity_at}}</span>
				</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="dialled">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Dialled </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.dialled }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unique_dialled">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Dialled </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_dialled }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contacted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.contacted }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unique_contacted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_contacted }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="rnr">
            <mat-header-cell *matHeaderCellDef mat-sort-header> RNR </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.rnr }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="forms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Forms </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.forms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="login_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Login Hours </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.login_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unavailable_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Unavailable Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unavailable_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="idle_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Idle Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.idle_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="wait_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Dial Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.wait_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="spoken_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Talk Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.spoken_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="wrap_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Wrap Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.wrap_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="break_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.break_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="aht">
			<mat-header-cell *matHeaderCellDef mat-sort-header> AHT </mat-header-cell>
			<mat-cell *matCellDef="let element">{{ element.aht }}</mat-cell>
		  </ng-container>
          <ng-container matColumnDef="audit_score">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Audit score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="ctc_score">
            <mat-header-cell *matHeaderCellDef mat-sort-header> CTC score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="more">
            <mat-header-cell *matHeaderCellDef mat-sort-header> More </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Open Detail Timings" class="" matTooltip="Details"
                matTooltipPosition="below" (click)="openDetailActivity(element)">
                <mat-icon style="color: #fd6c21 !important;">launch</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5,10, 20, 30, 50]" showFirstLastButtons></mat-paginator>
    </mat-card>

  </div>

</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
