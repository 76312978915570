import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-resignation-dialog',
  templateUrl: './profile-resignation-dialog.component.html',
  styleUrls: ['./profile-resignation-dialog.component.scss']
})
export class ProfileResignationDialogComponent implements OnInit {

  todayDate=new Date();
  constructor() { }
//public dialogRef: MatDialogRef<ProfileResignationDialogComponent>,
  ngOnInit(): void {
  }
  closeDialog(){
    //this.dialogRef.close();
  }
}
