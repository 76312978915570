import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { SessionStorageService } from './session-storage.service';
import { Utils } from './utils';

@Injectable({
    providedIn: 'root'
})

export class SharedDataService {
    private teamDataSubject = new BehaviorSubject<any>(null);
    private fullTeamDataSubject = new BehaviorSubject<any>(null);
    private settingsDataSubject = new BehaviorSubject<any>(null);
    private storageVariables = new Utils().getStorageDataVariables();
    private loggedInUser:any;
    constructor(
        private API: ApiService,
        private sessionStorageServie: SessionStorageService,
        private router: Router
    ) { }

    get_API_End_Point() {
        const p = this.sessionStorageServie.getStorageItem(this.storageVariables[7]);
        if (p) {
            return JSON.parse(p)
        }
    }

    isLoggedIn(): boolean {
        return !!this.sessionStorageServie.getStorageItem(this.storageVariables[0]);
    }

    isUserRole():boolean{
        if(!this.loggedInUser){
            const user:any = this.sessionStorageServie.getStorageItem(this.storageVariables[4]);
            this.loggedInUser = JSON.parse(user);
        }
        return this.loggedInUser?.role == 'user';
    }

    async load_initials() {
        console.log('checking pre data for pages');
        console.log('calling team data', new Date())
        await this.verify_team_data();
        console.log('calling full team data', new Date());
        await this.verify_full_team_data();
        console.log('calling settings data', new Date());
        await this.verify_settings_data();
    }

    async verify_team_data() {
        return new Promise((resolve, rej) => {
            const team = this.get_team_data();
            if (!team && this.isLoggedIn()) {
                var api_end_point = this.get_API_End_Point();
                this.API.teamAPI(api_end_point).subscribe((res: any) => {
                    this.set_team_data(res);
                    resolve(null);
                }, (err) => {
                    console.log('Team details not found', err);
                    rej();
                })
            } else {
                resolve(null);
            }
        })
    }

    async verify_full_team_data() {
        return new Promise((resolve, rej) => {
            const full_team = this.get_full_team_data();
            if (!full_team && this.isLoggedIn()) {
                var api_end_point = this.get_API_End_Point();
                this.API.getFullTeamData(api_end_point).subscribe((res: any) => {
                    if (res.success) {
                        let p = res.success;
                        // this.fullTeamDataSubject.next(JSON.stringify(p));
                        this.set_full_team_data(JSON.stringify(p));
                        resolve(null)
                    }else{
                        rej();
                    }
                }, (err) => {
                    console.log('Error in setting full team data', err);
                    throw new Error(err)
                })
            }else{
                resolve(null)
            }
        })
    }

    async verify_settings_data() {
        return new Promise((resolve, rej)=>{
            const settings = this.get_settings_data();
            if (!settings && this.isLoggedIn()) {
                var api_end_point = this.get_API_End_Point();
                this.API.settingsAPI(api_end_point).subscribe((res: any) => {
                    if (res && res.success) {
                        this.set_settings_data(JSON.stringify(res.success));
                        // this.settingsDataSubject.next(JSON.stringify(res.success))
                        resolve(null);
                    }else{
                        rej();
                    }
                }, (err) => {
                    console.log('Error in the settings fetch', err);
                    rej()
                })
            }else{
                resolve(null)
            }
        })
    }

    set_team_data(data){
        if(this.isUserRole()){
            this.sessionStorageServie.setStorageItem(this.storageVariables[1], data);
        }else{
            this.teamDataSubject.next(data);
        }
    }

    get_team_data() {
        if(this.isUserRole()){
            return this.sessionStorageServie.getStorageItem(this.storageVariables[1]);
        }
        return this.teamDataSubject.getValue();
    }

    set_settings_data(data){
        if(this.isUserRole()){
            this.sessionStorageServie.setStorageItem(this.storageVariables[3], data);
        }else{
            this.settingsDataSubject.next(data);
        }
    }

    get_settings_data() {
        if(this.isUserRole()){
            return this.sessionStorageServie.getStorageItem(this.storageVariables[3])
        }
        return this.settingsDataSubject.getValue();
    }

    set_full_team_data(data){
        if(this.isUserRole()){
            this.sessionStorageServie.setStorageItem(this.storageVariables[2], data)
        }else{
            this.fullTeamDataSubject.next(data)
        }
    }

    get_full_team_data() {
        if(this.isUserRole()){
            return this.sessionStorageServie.getStorageItem(this.storageVariables[2]);
        }
        return this.fullTeamDataSubject.getValue();
    }
}