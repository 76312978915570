<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" [ngClass]="{'minimize': is_minimized}" mode="side" opened="true">
		<app-menu 
			[navigateLink]="currentTitle" 
			[is_minimized]="is_minimized"
			(is_minimizedChange)="onMinimizedChange($event)">
		</app-menu>
	</mat-drawer>
	<app-logout></app-logout>
	<div class="CN-Main sbPad pd-25p">
		<p class="CN-Tab-head text-primary">
			<!-- LC Cordinator -->
			Fulfillment Co-ordinator
		</p>
		<div>
			<mat-card appearance="outlined">
				<div class="row">
					<div class="col-md-3">
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>Select Date</mat-label>
							<mat-select [(value)]="selectedDate" (selectionChange)="changeDate($event.value)">
								<mat-option value="Today">Today</mat-option>
								<mat-option value="yesterday">Yesterday</mat-option>
								<mat-option value="customDate">Custom Date</mat-option>
							</mat-select>
						</mat-form-field>

					</div>
					<!-- <div class="col-md-3" *ngIf="isShowDatePicker">
						<mat-form-field style="width:100%">
							<mat-label>Enter a date range</mat-label>
							<input matInput [matDatepicker]="pickerChooseDateFollowUp"
								(dateChange)="handleDateChange($event)" (click)="pickerChooseDateFollowUp.open()"
								readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateFollowUp"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateFollowUp></mat-datepicker>
						</mat-form-field>
					</div> -->
					<div class="col-md-3 mb-2">
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>Search</mat-label>
							<input matInput placeholder="Search" autocomplete="off"
								(keyup)="applySearchFilter1($event.target.value)">
							<mat-icon matSuffix
								style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
						</mat-form-field>
					</div>
					<div class="col-md-3 mb-2 pl-0 button-container">
						<button mat-raised-button class="mt-2" (click)="downloadReport()">
						  <mat-icon>download</mat-icon>
						  DOWNLOAD
						</button>
						<button mat-raised-button class="action_btn mt-2" (click)="uploadBtnClick()" style="margin-left: 20px;">
						  <mat-icon>upload</mat-icon>
						  UPLOAD
						</button>
					  </div>
				</div>



				<div class="row"  *ngIf="isShowDatePicker">
					<div class="col-md-3">
						<mat-form-field style="width:100%" appearance="outline">
							<mat-label>Start Date</mat-label>
							<input matInput (dateChange)="handleStartDate($event)" [max]="ChooseSelectedEndDateValue" [min]="back_date_thirty" [matDatepicker]="pickerChooseDateStart" [(ngModel)]="ChooseSelectedStartDateValue" (click)="pickerChooseDateStart.open()" readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateStart"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateStart></mat-datepicker>							
						</mat-form-field>
					</div>
					<div class="col-md-3">
						<mat-form-field style="width:100%" appearance="outline">
							<mat-label>End Date</mat-label>
							<input matInput  (dateChange)="handleEndDate($event)" [min]="ChooseSelectedStartDateValue" [matDatepicker]="pickerChooseDateEnd" [(ngModel)]="ChooseSelectedEndDateValue" (click)="pickerChooseDateEnd.open()" readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateEnd"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateEnd></mat-datepicker>							
						</mat-form-field>
					</div>
					<div class="col-md-3 pl-0">
						<button mat-raised-button  (click)="goRangeBtn()">
							<mat-icon>arrow_forward</mat-icon>
							GO
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						<mat-card class="CN-Card cardW" appearance="outlined">
							<p><span class="CN-Tab-head1 text-primary">Total Leads Received :- {{totalQAD}}</span></p>
							<p class="cn-p CNP1">{{approved}} <br> <span class="cn-sub-title">Full-filled</span> </p>
							<hr class="hr1">
							<p class="cn-p CNP2">{{rejectcount}}<br> <span class="cn-sub-title"> Not Completed</span>
							</p>

						</mat-card>
					</div>

				</div>
				<div class="row">
					<div class="col-md-12 overflow-auto custom-scrollbar custom-table-container">
						<mat-table [dataSource]="dataSourceA" class="vkTableAgent table">
							<ng-container matColumnDef="slno">
								<mat-header-cell *matHeaderCellDef style="flex:0 0 120px;" class="w-152-px d-flex justify-content-center"> Sl No </mat-header-cell>
								<mat-cell *matCellDef="let element;let i = index"
									style="flex:0 0 120px;" class="w-152-px d-flex justify-content-center">{{i+1}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="customerName">
								<mat-header-cell class="d-flex justify-content-left" *matHeaderCellDef>
									Customer&nbsp;Name
								</mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-left" *matCellDef="let element"> {{element.name}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="customerNumber">
								<mat-header-cell class="w-152-px d-flex justify-content-center" *matHeaderCellDef>
									<!-- Customer&nbsp;Number -->
									Application&nbsp;Number
								</mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-center" *matCellDef="let element">
									<!-- {{element.number}} -->
									{{getApplicationNumber(element) || '--'}}

								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="generationTime">
								<mat-header-cell class="w-152-px d-flex justify-content-center" *matHeaderCellDef>Appointment Details </mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-center" *matCellDef="let element">
									<!-- {{element.start_time| slice:0:10 | date:'dd-MMM-yyyy'}}&ensp;{{element.start_time | date:'h:mm a'}} -->
									{{getAppointmentDateTime(element)}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="leadFixTime">
								<mat-header-cell class="w-152-px d-flex justify-content-center" *matHeaderCellDef>Lead Fix Time </mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-center" *matCellDef="let element">
									{{getLeadFixDateTime(element)}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="qdeForms">
								<mat-header-cell *matHeaderCellDef class="w-152-px d-flex justify-content-center">Leads Received </mat-header-cell>
								<mat-cell *matCellDef="let element" class="w-152-px d-flex justify-content-center">
									<button mat-stroked-button class="w-100 action_btn" (click)="contactNameDetails(element)">VIEW</button>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="approve">
								<mat-header-cell *matHeaderCellDef class="w-152-px d-flex justify-content-center"> &nbsp; </mat-header-cell>
								<mat-cell *matCellDef="let element" class="w-152-px d-flex justify-content-center">
									<button mat-stroked-button class="w-100 action_btn f-f-btn" (click)="remarksBtn1(element,'FULL-FILLED')">
										<!-- Completed //(click)="approveBtn(element,'FULL-FILLED')"-->
										FULL-FILLED
									</button>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="decline">
								<mat-header-cell class="w-152-px d-flex justify-content-center" *matHeaderCellDef>&nbsp; </mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-center" *matCellDef="let element">
									<!-- <button class="btnnn2" (click)="approveBtn(element,'not_completed')">Not Completed</button> -->
									<button mat-stroked-button class="w-100 action_btn" (click)="remarksBtn2(element,'not_completed')">NOT COMPLETED</button>

								</mat-cell>
							</ng-container>
							<!-- <ng-container matColumnDef="reWork">
								<mat-header-cell class="w-152-px d-flex justify-content-center" *matHeaderCellDef style="display:none">&nbsp; </mat-header-cell>
								<mat-cell class="w-152-px d-flex justify-content-center" *matCellDef="let element" style="display:none">
									<button mat-stroked-button class="w-100  text-success" (click)="remarksBtn(element,'REWORK')">Remarks</button>
								</mat-cell>
							</ng-container> -->
							<mat-header-row *matHeaderRowDef=displayedColumnsA></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumnsA"></mat-row>
						</mat-table>
						<mat-paginator #paginatorA="matPaginator" [pageSizeOptions]="[5, 10, 20]"
							showFirstLastButtons></mat-paginator>
					</div>
				</div>

			</mat-card>
		</div>
	</div>
</mat-drawer-container>

<div class="dc-popupoverlayApprove" *ngIf="showHidePopupApprove">
	<div class="dc-delete-popupApprove">
		<div class="p-4">
			<div class="row mt-0">
				<div class="col-md-12 mt-2">
					<p><b>Approve</b></p>
					<div class="text-right btn-grp">
						<button mat-raised-button class="action_btn" (click)="noDocBtn()">NO DOC</button>
						<button mat-raised-button class="action_btn" (click)="withDocBtn()">WITH DOC</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #remarksDialog>
	<h2 mat-dialog-title>Remarks</h2>
	<mat-dialog-content>
		<div *ngIf="showHideForNotComplted">
			<mat-form-field style="width:100%" appearance="outline" class="mt-1">
				<mat-label>Select Reason</mat-label>
				<mat-select (selectionChange)="changeReasonNotCompleted($event.value)"
					[(ngModel)]="notCompletedModel">
					<mat-option value="NOT-INTERESTED">NOT INTERESTED</mat-option>
					<mat-option value="NOT-CONTACTABLE">NOT CONTACTABLE</mat-option>
					<mat-option value="OCL-NON-CAF">OCL / NON-CAF</mat-option>
					<mat-option value="reschedule_date_time">RESCHEDULE DATE & TIME</mat-option>
					<mat-option value="TECHNICAL-ISSUE">TECHNICAL ISSUE</mat-option>
				</mat-select>
			</mat-form-field>

			<div *ngIf="showHideResheduleDateTime">
				<mat-form-field style="width:100%" appearance="outline">
					<mat-label>Select Date</mat-label>
					<input matInput [matDatepicker]="pickerReshedule" placeholder="DD/MM/YYYY"
						[(ngModel)]="dateReshedule" autocomplete="off" class="kycDate"
						(click)="pickerReshedule.open()" readonly [min]="minDate2" />
					<mat-datepicker-toggle matSuffix [for]="pickerReshedule"></mat-datepicker-toggle>
					<mat-datepicker #pickerReshedule></mat-datepicker>
				</mat-form-field>

				<mat-form-field style="width:100%" appearance="outline">
					<mat-label>Time</mat-label>
					<input matInput type="time" id="timeIdReshedule" name="timeIdReshedule"
						[(ngModel)]="reTimeModel">
				</mat-form-field>
			</div>
		</div>

		<mat-form-field style="width:100%" appearance="outline" class="mt-1">
			<mat-label>Remarks</mat-label>
			<input matInput placeholder="Remarks" [(ngModel)]="remarksInputModel">
		</mat-form-field>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<button mat-raised-button (click)="cancelRemarks()">
			<mat-icon>close</mat-icon>
			CANCEL
		</button>
		<button mat-raised-button color="primary" (click)="submitRemarks()">
			<mat-icon>check</mat-icon>
			SUBMIT
		</button>
	</mat-dialog-actions>
</ng-template>

<div id="cover-spin" *ngIf="currentLoader"></div>