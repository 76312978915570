import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MyserviceService } from './myservice.service'

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard  {
  constructor(private _service: MyserviceService, private router: Router) {
    // Logic of checking settings and login here.
    // console.log('construct root gaurd')
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return true;
    console.log('auth guard looking', this.router.url);
    if (this._service.isLoggedIn()) {
      // this.sharedService.load_initials();
      return true;
    }
    else {
      alert("You don't have permission page");
      if (window.location.href.includes('dev.youearn.in')) {
        window.location.href = 'https://dev.contiinex.com/';
      } else {
        window.location.href = 'https://contiinex.com/';
      }
    }
  }

}
