import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-contiinex-web-login',
	templateUrl: './contiinex-web-login.component.html',
	styleUrls: ['./contiinex-web-login.component.scss']
})

export class

ContiinexWebLoginComponent implements OnInit {
	createdForm: UntypedFormGroup;
	otpForm: UntypedFormGroup;
	loginname: any;
	showLoader = false;
	selectedGig: any = "Gignex";
	showHideErrorMsg: boolean = false;
	passMsgText: any = "";

	showHideLoginPageUI: boolean = true;
	showHideForgotPageUI: boolean = false;
	forgotEmail: any;
	forgotMsgError: any = "";
	showHideForgotMsg: boolean = false;
	showHideResetPageUI: boolean = false;
	newPassword: any = "";
	confirmPassword: any = "";
	loginfactor: boolean = false;
	otp: any;
	otpvalidation: any = false;  //true ; //false;
	idpassword: any = true;  //false; //true;
	email: any;
	password: any;
	totalTime: number;
	min: number;
	sec: number;
	clockTimer: string = '00 : 00';
	subscribe: any;
	otpstatus: any = "firstlogin";
	API_End_Point:any;

	constructor(private activeRoute: ActivatedRoute, private router: Router, private http: HttpClient,
		private fb: UntypedFormBuilder, private API: ApiService, private _service: MyserviceService, private _snackBar: MatSnackBar) {
		this.createdForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.maxLength(50)]]
		}), this.otpForm = fb.group({
			otp: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(7), Validators.maxLength(7)]]
		})
	}

	ngOnInit(): void {
		const id = this.activeRoute.snapshot.paramMap.get('id');
		if (id) {
			this.selectedGig = id;
		}
		this.loginname = '';
		if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
			this.loginname = this._service.get_loggedin_user().email;
		}
		//this.testingforotp();
	}
	testingforotp() {
		this.otpvalidation = true;
		this.idpassword = false;

		if (this.otpstatus = "firstlogin") {
			this.min = 10 * 60;
			this.sec = 0; //10 % 60;
			this.totalTime = this.min + this.sec;
			this.timerClock();
		}
	}

	LoginBtn(createdForm: any) {
		this.showHideErrorMsg = false;
		this.passMsgText = "";

		let _email = createdForm.controls.email.value;
		let _password = createdForm.controls.password.value;


		if (_email == "") {
			this.showHideErrorMsg = true;
			this.passMsgText = "* Please enter user name.";
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return;
		}

		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (reg.test(_email) == false) {
			this.showHideErrorMsg = true;
			this.passMsgText = "* Please enter valid email.";
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return false;
		}
		if (_password == "") {
			this.showHideErrorMsg = true;
			this.passMsgText = "* Please enter password.";
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return;
		}

		this.email = _email;
		this.password = _password;
		let formData = {
			"email": _email,
			"password": _password,
			"login_method": "smb"
		};

		var userDetails, stageSettings;

		if (this.selectedGig == 'Gignex') {
			this.uearnLogin(formData)
		} else if (this.selectedGig == 'Ainex') {
			this.ainexLogin(formData)
		} else {
			this.API.loginAPIComnex(formData)
				.subscribe(
					(res: any) => {
						//  debugger;
						console.log('after login response', res)
						let _error = res["errors"];
						if (_error == "Invalid Credentials" || _error == "User does not exist") {
							const demo_user = this._service.is_ainex_demo_user(formData.email, formData.password)
							if(demo_user){
								let objJsonStr = JSON.stringify(demo_user);
								let objJsonB64 = btoa(objJsonStr);
								window.location.href = 'http://ainexdemo.contiinex.com/' + objJsonB64;
								return;
							}else{
								this.showLoader = false;
								this.showHideErrorMsg = true;
								this.passMsgText = _error;
								setTimeout(() => {
									this.showHideErrorMsg = false;
									this.passMsgText = "";
								}, 5000);
								return false;
							}
						}
						else {
							userDetails = res.user_details;
							let objJsonStr = JSON.stringify(userDetails);
							let objJsonB64 = btoa(objJsonStr);
							window.location.href = 'https://dev.contiinex.com/Dashboard?session=' + objJsonB64;


							return;
						}
					},
					(err) => {
						// alert("Something went wrong...");
						this.showHideErrorMsg = true;
						this.passMsgText = "Something went wrong...";
						setTimeout(() => {
							this.showHideErrorMsg = false;
							this.passMsgText = "";
						}, 5000);

					});
		}


	}

	uearnLogin(formData) {
		this.showLoader = true;
		//   console.log('login api')
		var userDetails, stageSettings;
		//console.log(formData);
		this.API.loginAPI(formData)
			.subscribe(
				(res) => {
					// console.log('printing res',res)
					let _error = res["errors"];
					if (_error == "Invalid Credentials" || _error == "User does not exist") {
						this.showLoader = false;
						alert(_error);
						// alert("Invalid Credentials,User does not exist");
						return false;
					}
					else {
						//console.log("--" + res['user_details']);
						//console.log(res['user_details']['id']);
						if (res['user_details']) {
							userDetails = res['user_details'];
							let access_token = res['user_details']['access_token'];
							localStorage.setItem('access_token', access_token);
							this.API_End_Point = res['platform_details']['API_End_Point'];
							this._service.set_API_End_Point(res['platform_details']['API_End_Point']);
							let landing_page = '';
							if (access_token) {
								this._service.set_access_token(access_token);
								this._service.set_loggedin_user(res['user_details']);
								//   debugger;
								// Calling team api
								// console.log('login team api')

								this.API.teamAPI(this.API_End_Point)
									.subscribe(
										(res) => {

										//	debugger;
											//   console.log('printing team response',res)
											this._service.set_my_team(res);

											// Calling settings api
											//   console.log('signin settigs')

											this.API.settingsAPI(this.API_End_Point).subscribe(
												(res: any) => {
													 // console.log('printing settings response',res);
													//   debugger;
													if (res.success) {
														stageSettings = res.success;
														this._service.set_stage_settings(res.success);
														// console.log('signin full team api');
														if (res.success.web) {
															for (var i in res.success.web) {
																if (res.success.web[i]['name'] == 'landing_page') {
																	landing_page = res.success.web[i]['definition'];
																}
															}
														}

													} else {
														alert('Error in fetching settings');
														this._service.clearAllItems();
														this.router.navigate(['/managementTeam']);
														return;
													}
													this.API.getFullTeamData(this.API_End_Point).subscribe(
														(res: any) => {
															if (res.success) {
																this._service.set_Full_team(res.success);
																this.showLoader = false;
																// Routing to Dashboard
																if (userDetails.role == 'user') {
																	var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
																	this.API.agentStartEndSession(obj, this.API_End_Point).subscribe(
																		(res: any) => {
																			console.log(res)
																		}, (err) => {
																			console.log(err)
																		}
																	)
																	if (landing_page.toLowerCase().includes('audit')) {
																		this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
																	} else {
																		// if(!userDetails.isVisonEnabled){
																		//   this.router.navigate(['/agentPlugin/face']);
																		//   return;
																		// }
																		// this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
																		//   (res: any) => {
																		//     if(res.success == 1){
																		//       this.router.navigate(['/agentPlugin/assesment']);
																		//       // return;
																		//     }else{
																		//       this.router.navigate(['/agentPlugin/home']);
																		//     }
																		//   }, (err) => {
																		//     console.log(err);
																		//     this.router.navigate(['/agentPlugin/home']);
																		//   }
																		// )
																		// return;
																		if (stageSettings && stageSettings.extraoptions) {
																			for (let opt of stageSettings.extraoptions) {
																				if (opt.name == "biometric" && opt.definition == 'true') {
																					if (!userDetails.isVisonEnabled) {
																						this.router.navigate(['/agentPluginPC/face']);
																						return;
																					}
																				}
																				if (opt.name == "assessment" && opt.definition == 'true') {
																					this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id,this.API_End_Point).subscribe(
																						(res: any) => {
																							if (res.success == 1) {
																								this.router.navigate(['/agentPluginPC/assessment']);
																								return;
																							} else {
																								this.router.navigate(['/agentPluginPC/home']);
																							}
																						}, (err) => {
																							console.log(err)
																						}
																					)
																				} else {
																					this.router.navigate(['/agentPlugin/home']);
																				}
																			}
																		} else {
																			this.router.navigate(['/agentPlugin/home']);
																		}
																	}

																} else {
																	let total_pages = this._service.setMyPages();
																	// console.log('total_pages', total_pages);
																	let page_found: any = false;
																	for (var i in total_pages) {
																		if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
																			page_found = total_pages[i];
																			break;
																		}
																	}
																	if (page_found) {
																		//	this.router.navigate([page_found['path']]);
																		window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
																	} else {
																		this.router.navigate(['/managementTeam']);
																	}
																}
															} else {
																alert(res.error)
															}
														}, (err) => {
															alert('Error in the full team fetch')
														}
													)
												},
												(err) => {
													alert('Error in the settings api')
												}
											)
										},
										(err) => {
											// debugger;
											alert('Error in the api')
										}
									)
								this.API.loginAinexAPI(formData)
									.subscribe(
										(res2: any) => {
											localStorage.setItem('ainex_token', res2["token"])
										})

							} else {
								alert('Unathorized access. Contact tech support')
							}
						}
						else {
							if (res['user_details']['mail_otp_authentication'] == true) {
								this.otpvalidation = true;
								this.idpassword = false;

								if (this.otpstatus == "firstlogin") {
									this.min = 10 * 60;
									this.sec = 0; //10 % 60;
									this.totalTime = this.min + this.sec;
									//console.log("--this--" + this.totalTime);
									this.timerClock();
								}
							}
						}

					}
				},
				(err) => {
					alert("User does not exist")
				});
		return;
	}

	ainexLogin(formData) {
		var userDetails, stageSettings;

		this.API.loginAinexAPI(formData)
			.subscribe(
				(res: any) => {
					// debugger;
					// console.log('printing res',res)
					let _error = res["errors"];
					if (_error == "Invalid Credentials" || _error == "User does not exist") {
						this.showLoader = false;
						// alert("Invalid Credentials,User does not exist");
						// alert(_error);
						this.showHideErrorMsg = true;
						this.passMsgText = _error;
						setTimeout(() => {
							this.showHideErrorMsg = false;
							this.passMsgText = "";
						}, 5000);
						return false;
					}
					else {
						userDetails = res;
						let objJsonStr = JSON.stringify(userDetails);
						let objJsonB64 = btoa(objJsonStr);
						window.location.href = 'https://ainex.contiinex.com/voiceCallAudit?session=' + objJsonB64;

						return;
					}
				},
				(err) => {
					// alert("User does not exist");
					this.showHideErrorMsg = true;
					this.passMsgText = "User does not exist";
					setTimeout(() => {
						this.showHideErrorMsg = false;
						this.passMsgText = "";
					}, 5000);
				});
	}

	backBtn() {
		this.router.navigateByUrl('/home');
	}
	radioChangeGig(val: any) {
		if (val == "Gignex") {

		}
		else if (val == "Comnex") {

		}
		else if (val == "Ainex") {

		}

	}
	forgotPasswordBtn() {
		this.forgotEmail = "";
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;

		this.showHideLoginPageUI = false;
		this.showHideForgotPageUI = true;
		this.showHideResetPageUI = false;
	}
	backToLoginBtn() {
		this.forgotEmail = "";
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;
		this.showHideLoginPageUI = true;
		this.showHideForgotPageUI = false;
		this.showHideResetPageUI = false;
		this.router.navigateByUrl('/signin');
	}


	validateForgotEmail() {
		let emailField = this.forgotEmail;
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if (reg.test(emailField) == false) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			return false;
		}
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;

		return true;

	}
	sendForgotEmail() {
		//debugger;
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;
		let emailField = this.forgotEmail;
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (emailField == "" || emailField == null || emailField == undefined) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		}
		if (reg.test(emailField) == false) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		}
		let obj = {
			"email": emailField
		}

		/*if (emailField) {
			this.showHideForgotMsg = true;
			this.forgotMsgError = "This email is not registered";
		}*/

		this.API.passwordReset(emailField).subscribe((res: any) => {
			if (res.errors) {
				this.forgotMsgError = res.errors;
				this.showHideForgotMsg = true;
				setTimeout(() => {
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
				}, 3000)
				return false;
			} else {

				setTimeout(() => {
					this.showHideResetPageUI = true;
					this.showHideForgotPageUI = false;
					this.showHideLoginPageUI = false;
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
				}, 2000)
				return false;
			}
		}, (err) => {
			this.forgotMsgError = "Something went wrong we couldnt not fetch your details";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		});

	}
	otpsbumit(otpForm: any) {
		let _email = this.email;
		let _password = this.password;
		//let _email = "CM@bank1test.com";
		//let _password = "q";

		let otp = otpForm.controls.otp.value;
		//console.log(otp);
		if (otp == "") {
			this.showHideErrorMsg = true;
			this.passMsgText = "* Please enter otp ";
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return;
		}
		else {
			let formData = {
				"email": _email,
				"password": _password,
				"login_method": "smb",
				"otp_number": otp
			};
			//console.log(formData);
			var userDetails, stageSettings;
			this.API.SBIloginAPI(formData)
				.subscribe(
					(res) => {
						// console.log('printing res',res)
						let _error = res["errors"];
						if (_error) {
							this.showLoader = false;
							alert(_error);
							// alert("Invalid Credentials,User does not exist");
							return false;
						}
						else {
						//	console.log(JSON.stringify(res['user_details']))
							if (res['user_details']) {
								userDetails = res['user_details'];
								let access_token = res['user_details']['access_token'];
								localStorage.setItem('access_token', access_token);
								let landing_page = '';
								if (access_token) {
									this._service.set_access_token(access_token);
									this._service.set_loggedin_user(res['user_details']);
									this.API_End_Point = res['platform_details']['API_End_Point'];
									//   debugger;
									// Calling team api
									// console.log('login team api')
									this.API.teamAPI(this.API_End_Point)
										.subscribe(
											(res) => {

												//debugger;
												//   console.log('printing team response',res)
												this._service.set_my_team(res);

												// Calling settings api
												//   console.log('signin settigs')
												this.API.settingsAPI(this.API_End_Point).subscribe(
													(res: any) => {
														//   console.log('printing settings response',res);
														//   debugger;
														if (res.success) {
															stageSettings = res.success;
															this._service.set_stage_settings(res.success);
															// console.log('signin full team api');
															if (res.success.web) {
																for (var i in res.success.web) {
																	if (res.success.web[i]['name'] == 'landing_page') {
																		landing_page = res.success.web[i]['definition'];
																	}
																}
															}

														} else {
															alert('Error in fetching settings');
															this._service.clearAllItems();
															this.router.navigate(['/managementTeam']);
															return;
														}
														this.API.getFullTeamData(this.API_End_Point).subscribe(
															(res: any) => {
																if (res.success) {
																	this._service.set_Full_team(res.success);
																	this.showLoader = false;
																	// Routing to Dashboard
																	if (userDetails.role == 'user') {
																		var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
																		this.API.agentStartEndSession(obj, this.API_End_Point).subscribe(
																			(res: any) => {
																				console.log(res)
																			}, (err) => {
																				console.log(err)
																			}
																		)
																		if (landing_page.toLowerCase().includes('audit')) {
																			this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
																		} else {
																			// if(!userDetails.isVisonEnabled){
																			//   this.router.navigate(['/agentPlugin/face']);
																			//   return;
																			// }
																			// this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
																			//   (res: any) => {
																			//     if(res.success == 1){
																			//       this.router.navigate(['/agentPlugin/assesment']);
																			//       // return;
																			//     }else{
																			//       this.router.navigate(['/agentPlugin/home']);
																			//     }
																			//   }, (err) => {
																			//     console.log(err);
																			//     this.router.navigate(['/agentPlugin/home']);
																			//   }
																			// )
																			// return;
																			if (stageSettings && stageSettings.extraoptions) {
																				for (let opt of stageSettings.extraoptions) {
																					if (opt.name == "biometric" && opt.definition == 'true') {
																						if (!userDetails.isVisonEnabled) {
																							this.router.navigate(['/agentPluginPC/face']);
																							return;
																						}
																					}
																					if (opt.name == "assessment" && opt.definition == 'true') {
																						this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id,this.API_End_Point).subscribe(
																							(res: any) => {
																								if (res.success == 1) {
																									this.router.navigate(['/agentPluginPC/assessment']);
																									return;
																								} else {
																									this.router.navigate(['/agentPluginPC/home']);
																								}
																							}, (err) => {
																								console.log(err)
																							}
																						)
																					} else {
																						this.router.navigate(['/agentPlugin/home']);
																					}
																				}
																			} else {
																				this.router.navigate(['/agentPlugin/home']);
																			}
																		}

																	} else {
																		let total_pages = this._service.setMyPages();
																		// console.log('total_pages', total_pages);
																		let page_found: any = false;
																		for (var i in total_pages) {
																			if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
																				page_found = total_pages[i];
																				break;
																			}
																		}
																		if (page_found) {
																			//	this.router.navigate([page_found['path']]);
																			window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
																		} else {
																			this.router.navigate(['/managementTeam']);
																		}
																	}
																} else {
																	alert(res.error)
																}
															}, (err) => {
																alert('Error in the full team fetch')
															}
														)
													},
													(err) => {
														alert('Error in the settings api')
													}
												)
											},
											(err) => {
												// debugger;
												alert('Error in the api')
											}
										)
									/* this.API.loginAinexAPI(formData)
										.subscribe(
											(res2: any) => {
												localStorage.setItem('ainex_token', res2["token"])

											}) */

								} else {
									alert('Unathorized access. Contact tech support')
								}
							}
						}
					})
		}
	}
	resend_otp() {
		let _email = this.createdForm.controls.email.value;
		let _password = this.createdForm.controls.password.value;
		let formData = {
			"email": _email,
			"password": _password,
			"login_method": "smb"
		};
		//console.log(formData);
		const source = timer(0, 1000);
		this.subscribe = source
			.subscribe(val => {
				this.subscribe.unsubscribe();
			});
		this.clockTimer = '00 : 00';
		this.totalTime = 0;
		this.totalTime = this.min + this.sec;
	//	console.log("--this--" + this.totalTime);
		this.otpstatus = "resentotp";
		this.min = 5 * 60;
		this.sec = 0; //10 % 60;
		this.totalTime = this.min + this.sec;
		this.timerClock();
		this.uearnLogin(formData);
	}
	timerClock() {
		let minutes, seconds, formatedMinutes, formatedSeconds;
		let nTime = this.totalTime;

		const source = timer(0, 1000);
		this.subscribe = source
			.subscribe(val => {

				if (this.totalTime === -1) {
					//this._snackBar.open('Time is Up!', "close");
					this.otpvalidation = false;
					this.idpassword = true;
					this.subscribe.unsubscribe();

				} else {

					minutes = Math.floor(this.totalTime / 60);
					seconds = this.totalTime % 60;

					formatedMinutes = minutes > 9 ? minutes : '0' + minutes;
					formatedSeconds = seconds > 9 ? seconds : '0' + seconds;
					this.totalTime = nTime - val;

					this.clockTimer = `${formatedMinutes} : ${formatedSeconds}`;
				}
			});
	}
	get f() {
		return this.otpForm.controls;
	}
}
