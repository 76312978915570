import { Component, OnInit, HostListener ,ViewChild} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MyserviceService } from "../../myservice.service";
import { Title, Meta } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';import { ContiinexSelectChannelDialogComponent } from '../contiinex-select-channel-dialog/contiinex-select-channel-dialog.component';
import { ApiService } from 'src/app/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';import { ContiinexSettingTemplateEditDialogComponent } from '../contiinex-setting-template-edit-dialog/contiinex-setting-template-edit-dialog.component';
@Component({
  selector: 'app-contiinex-shopify-settings',
  templateUrl: './contiinex-shopify-settings.component.html',
  styleUrls: ['./contiinex-shopify-settings.component.scss']
})

export class ContiinexShopifySettingsComponent implements OnInit {
  currentLoader: boolean = false;
  selectedButtonToggleVal: string = 'channelSetting';
  myTitle = 'Contiinex | Shopify Channel Settings';

  waModel: any = false;
  chatModel: any = false;
  callModel: any = false;
  textModel: any = false;

  company: any;
  businessType: any;
  touch_points: any;
  _partner: any;

  msg_Display: any;
  iconTextSW: any = "";
  icon_color: any = "";
  myUser;
  widgetClientSettings: any = "";
  currentTitle = 'shopify-setting';
  
  @HostListener('click') closeCustomPopup() {
    const closeButton = document.querySelector(".vk-popclose-button");
    closeButton.addEventListener("click", this.openCustomPopup);

  }
  isShowChannel:boolean=true;
  isShowTemplate:boolean=false;
  displayed_columns = ['notificationEvent', 'content', 'partner', 'status', 'action','preview'];
  dataSourceTemplate = new MatTableDataSource();
  api_end_point:any;
  @ViewChild('paginatorA', {static: false}) paginatorA: MatPaginator;
  constructor(private dialog: MatDialog, private metaTagService: Meta, private titleService: Title, private _service: MyserviceService, public sanitizer: DomSanitizer, private API: ApiService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
    this.myUser = this._service.get_loggedin_user();
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.company = String(this._service.get_loggedin_user().company);
      this.businessType = String(this._service.get_loggedin_user().businessType);
      this._partner = String(this._service.get_loggedin_user().partner);
      if (this._service.get_loggedin_user().touch_points) {
        // this.touch_points = this._service.get_loggedin_user().touch_points;
      }
      this.API.getWidgetClientSettings(this.company,this.api_end_point).subscribe((res: any) => {
        if (res.status == 'failure') {
          // this.createWidgetClient();
        }
        if (res.status == 'success') {
          for (let obj of res['data']) {
            if (obj.name == "widget_options") {
              var widgetOptions = JSON.parse(obj.definition);
              var jsonWidgetOptions:any = {};
             // console.log(widgetOptions);
              // this.touch_points = obj.definition
              this.waModel = false;
              this.callModel = false;
              this.chatModel = false;
              this.textModel = false;
              if (widgetOptions.includes('WhatsApp')) {
                this.waModel = true;
                jsonWidgetOptions.wa = true;
              }
              if (widgetOptions.includes('Call')) {
                this.callModel = true;
                jsonWidgetOptions.call = true;
              }
              if (widgetOptions.includes('Chat')) {
                this.chatModel = true;
                jsonWidgetOptions.chat = true;
              }
              if (widgetOptions.includes('Text')) {
                this.textModel = true;
                jsonWidgetOptions.text = true;
              }
              this.touch_points = widgetOptions && widgetOptions.length > 0 ? jsonWidgetOptions : '';
              if (!this.touch_points) {
                const dialogRef = this.dialog.open(ContiinexSelectChannelDialogComponent, {
                  width: '75%',
                  height: '480px',
                  disableClose: true,
                  panelClass: 'cbxSelect1',
                  data: this._service.get_loggedin_user().name
                });

                dialogRef.afterClosed().subscribe(result => {
                 // console.log('The dialog was closed', result);
                  if (result) {
                    this.waModel = result.wa;
                    this.chatModel = result.chat;
                    this.callModel = result.call;
                    this.textModel = result.text;
                    this.createWidgetClient();
                  }
                });
              } 
            }
          }
        }else{
          if (!this.touch_points) {
            const dialogRef = this.dialog.open(ContiinexSelectChannelDialogComponent, {
              width: '75%',
              height: '480px',
              disableClose: true,
              panelClass: 'cbxSelect1',
              data: this._service.get_loggedin_user().name
            });

            dialogRef.afterClosed().subscribe(result => {
            //  console.log('The dialog was closed', result);
              if (result) {
                this.waModel = result.wa;
                this.chatModel = result.chat;
                this.callModel = result.call;
                this.textModel = result.text;
                this.createWidgetClient();
              }
            });
          } 
        }

      });
    }
    

  }
  onToggleButtonValChange(val: any) {
    this.isShowChannel=false;
    this.isShowTemplate=false;
    this.currentLoader=false;
    if (val == "channelSetting") {
      this.isShowChannel=true;
    }
    else if(val=="templateSetting"){
      this.currentLoader=true;
      this.isShowTemplate=true;
      let tableData=[
        {notificationEvent:'Shipped',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Active'},
        {notificationEvent:'Out of Delivery',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Active'},
        {notificationEvent:'Delivered',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Active'},
        {notificationEvent:'Failed Delivery',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Deactive'},
        {notificationEvent:'Shipped',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Active'},
        {notificationEvent:'Delivered',content:'The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by',partner:'Clickpost',status:'Active'},
      ];
 
      setTimeout(()=>{
        this.dataSourceTemplate = new MatTableDataSource(tableData);
        this.dataSourceTemplate.paginator = this.paginatorA;
        this.currentLoader=false;
      },2000)
    }
  }

  createWidgetClient(){
    let _waModel = this.waModel;
    let _chatModel = this.chatModel;
    let _callModel = this.callModel;
    let _textModel = this.textModel;
    var clientObj = {
      "settings_type": "WIDGET",
      "company": this.company,
      "groupid": this.myUser.groupid ? this.myUser.groupid : this.myUser.id,
      "chat_type": "COMNEX",
      "nocloudcall": "on",
      "widget_options": []
    }
    if (_waModel) {
      clientObj.widget_options.push("WhatsApp")
    }
    if (_chatModel) {
      clientObj.widget_options.push("Chat")
    }
    if (_callModel) {
      clientObj.widget_options.push("Call")
    }
    if (_textModel) {
      clientObj.widget_options.push("Text")
    }
    this.API.createWidgetClient(clientObj,this.api_end_point).subscribe((res: any) => {
      if (res.status == 'success') {
        this.widgetClientSettings = res.Inserted_Settings;
      }
    });
  }
  updateBtn() {
    //  debugger;
    this.currentLoader = true;
    let _waModel = this.waModel;
    let _chatModel = this.chatModel;
    let _callModel = this.callModel;
    let _textModel = this.textModel;
    let _cbxTrue = {
      "wa": _waModel,
      "chat": _chatModel,
      "call": _callModel,
      "text": _textModel
    };
    let jsonObj = JSON.stringify(_cbxTrue);

    let _obj = {
      "partner": this._partner || "SHOPIFY",
      "company": this.company || "",
      "email": this._service.get_loggedin_user().email || "",
      "touch_points": jsonObj
    };
    this.updateWidgetClient();
    this.API.updateSelectChannel(_obj,this.api_end_point).subscribe((res: any) => {
     // debugger;
      this.currentLoader = false;
     // console.log(res);
      if (res.success) {
        if (!this.widgetClientSettings){
          this.createWidgetClient();
        }
        
        // this._snackBar.open(res.success, 'close', {
        //   duration: 4000,
        // });
        let _result = res.success;
        this.openCustomPopup(_result);
        this.iconTextSW = "done_all";
        this.icon_color = "green";

      }
      if (res.errors) {
        // this._snackBar.open(res.errors, 'close', {
        //   duration: 4000,
        // });
        let _result = res.errors;
        this.openCustomPopup(_result);

        this.iconTextSW = "warning";
        this.icon_color = "#e1c929";
      }
    });




  }


    updateWidgetClient(){
      let _waModel = this.waModel;
      let _chatModel = this.chatModel;
      let _callModel = this.callModel;
      let _textModel = this.textModel;
      var clientObj = {
        "settings_type": "CALLING",
        "company": this.company,
        "user_id": this.myUser.groupid ? this.myUser.groupid : this.myUser.id,
        "telco_partner": "AIRTEL",
        "cli_number": "+918080808080",
        "widget_options": []
      }
      if (_waModel) {
        clientObj.widget_options.push("WhatsApp")
      }
      if (_chatModel) {
        clientObj.widget_options.push("Chat")
      }
      if (_callModel) {
        clientObj.widget_options.push("Call")
      }
      if (_textModel) {
        clientObj.widget_options.push("Text")
      }
      this.API.createWidgetClient(clientObj,this.api_end_point).subscribe((res: any) => {
        if (res.status == 'success') {
          this.widgetClientSettings = res.Inserted_Settings;
        }
      });
    }



  openCustomPopup(_result: any) {
    const modal = document.querySelector(".vk-popupmodal");
    modal.classList.toggle("vk-show-modal");
    this.msg_Display = _result;

  }
  editTemplateProfileDialog(element){
    const dialogRef = this.dialog.open(ContiinexSettingTemplateEditDialogComponent, {
      width: '65%',
    //  height: '80%',
      disableClose: true,
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
      if (result) {
      }
    });
  }


}

