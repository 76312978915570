import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpResponse } from '@angular/common/http';
import * as moment from 'moment';

@Component({
	selector: 'app-lc-upload-dialog',
	templateUrl: './lc-upload-dialog.component.html',
	styleUrls: ['./lc-upload-dialog.component.scss']
})
export class LcUploadDialogComponent implements OnInit {

	createdForm: UntypedFormGroup;
	profileMode: any;
	_userid: any;
	meName: any;
	myUser: any;


	passingMessage: any;
	showHidePassingMessage: boolean = false;
	_color: any = "";

	currentFileobj: any = {};
	currentLoader: boolean = false;
	showHideSubmitUpload: boolean = false;
	secondaryAgents: any = [];
	previous_email: any;
	api_end_point: any;
	user: any = this.myService.get_loggedin_user();
	form_submitted = false;

	constructor(private myService: MyserviceService, public dialogRef: MatDialogRef<LcUploadDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData, public formbuilder: UntypedFormBuilder, private API: ApiService, private service: MyserviceService, private _snackBar: MatSnackBar) {
		console.log(viewData)
		this.createdForm = this.formbuilder.group({
			"file": ['']
		})

	}

	ngOnInit() {
		this.myUser = this.myService.get_loggedin_user();

		this.api_end_point = this.myService.get_API_End_Point();



	}
	closeDialog(params?) {
		this.dialogRef.close(params);
	}

	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.createdForm.patchValue({
				fileSource: upload_file
			});
			this.showHideSubmitUpload = true;
		}
		else {
			this.showHideSubmitUpload = false;
		}
	}
	saveButton(createdForm: any) {
		this.form_submitted = true;
		let file = this.currentFileobj; //this.createdForm.value.file
		let file_name = this.currentFileobj.name
		console.log(file, file_name);
		// console.log(file.replaceAll("\\", "/"));

		let formData = new FormData();

		formData.append('attachment', file);
		formData.append('user_id', this.myUser.id);
		formData.append('groupid', this.myUser.groupid);

		this.API.saveUploadLC(formData, this.api_end_point).subscribe((response: any) => {
			if (response.success) {
				this._snackBar.open("success", 'close', {
					duration: 2000,
				});
				this.closeDialog('api-response')
			}
			if (response.errors) {
				this._snackBar.open("error", 'close', {
					duration: 2000,
				});
			}
			this.form_submitted = false;
		});
	}

	clearField() {
		this.passingMessage = null;
		this.showHidePassingMessage = false;
		this._color = '';
	}

	download_file() {
		let url = "https://desktopio.smarter-biz.com/api/v1/attachments/LC-upload-Template.xlsx"
		//let url = 'https://desktopio.smarter-biz.com/api/v1/attachments/LC-upload-Template.xlsx'+"?access_token="+this.myUser.access_token;
		this.API.downloadReportFromUrl(url, null, this.api_end_point).then(
			() => {
				this._snackBar.open("File Downloaded", 'close', {
					duration: 2000,
				});
			})
	}
}


