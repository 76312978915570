<div class="scrollingDiv">
    <table class="tbl">
        <tr>
            <mat-card>
                <mat-card-header>
                  <mat-card-title>
                    <h5 class="title-color">Select Agents</h5>  
                    <!-- <div style="position:absolute; right:20px; top:0;">
                        <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
                    </div> -->
                  </mat-card-title>
                </mat-card-header>
                  <mat-card-content class="team_content">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle matTreeNodePadding [ngStyle]="{display: isValidNode(node) ? 'inherit' : 'none'}">
                            <button mat-icon-button disabled></button>
                            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                                (change)="checklistSelection.toggle(node);prnt(node);">{{node.name}}</mat-checkbox>
                        </mat-tree-node>
        
                        <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
                            <button mat-icon-button disabled></button>
                            <mat-form-field>
                                <input matInput #itemValue placeholder="New item...">
                            </mat-form-field>
                            <button mat-raised-button (click)="saveNode(node, itemValue.value)">Save</button>
                        </mat-tree-node> -->
        
                        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                            <button mat-icon-button matTreeNodeToggle>
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <mat-checkbox [checked]="descendantsAllSelected(node)"
                                [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
                            <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
                        </mat-tree-node>
                    </mat-tree>
                  </mat-card-content>
                <mat-card-actions align="end" style="gap: 8px;">
                  <button mat-raised-button class="action_btn" (click)="closePopup()">
                    <i class="fa fa-times" aria-hidden="true"></i>&nbsp;&nbsp;
                    CANCEL</button>
                  <button mat-raised-button (click)="apply_selection()">
                    <i class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp;
                    APPLY</button>
                </mat-card-actions>
            </mat-card> 
        </tr>
    </table>
</div>