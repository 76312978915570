<div class="row ml-0 mr-0 mt-3">
    <div class="col-md-7">
        <small>{{callFlowJson}}</small>
        <div class="row">
            <div class="col-md-9">               
                <h3 class="h3-call">Create During Call Form</h3>
            </div>
            <div class="col-md-3 text-right">
                <button mat-raised-button class="savbtn" (click)="generateJson()">Save</button>
            </div>
        </div>
        <div>
            <div *ngFor="let section of callFlowJsonSections;let $index=index" style="margin-bottom: -28px;">
                <mat-card class="mtCard mt-4">
                    <div  class="bdr-bg" >&nbsp;</div>
                    <!-- <h3 class="h3-heading">Untitled form</h3> -->
                    <div class="row ml-0 mr-0"  >
                        <div class="col-md-12 col-sm-12">
                            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                                <input matInput [(ngModel)]="section.title" placeholder="{{Untitledform}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ml-0 mr-0"  >
                        <div class="col-md-12 col-sm-12">
                            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                                <input matInput [(ngModel)]="section.description" placeholder="Form Description">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row"  >
                        <div class="col-md-8">
                            &nbsp;
                        </div>
                        <div class="col-md-4">
                            <span class="callReq"> Required&nbsp;&nbsp;
                                <mat-slide-toggle  [checked]="section.mandatory === 'y' ? true : false" (change)="untitleToggle($index,$event,1, undefined, undefined)" class="callReq"></mat-slide-toggle>
                                </span>
                            <span class="call-del CR" (click)="removeMore($index)">
                                <mat-icon class="CR" style="font-size: 35px;">delete_forever</mat-icon>
                                
                            </span>
                            <span class="call-del CR ml-3" *ngIf="parentAdd == false" (click)="exitToMain($index)">
                                <mat-icon class="CR" style="font-size: 35px;">exit_to_app</mat-icon>
                                
                            </span>
                        </div>
                    </div>
                    <br>
    <!--start personal details-->
    <div *ngFor="let child of section.child;let $childIndex=index">
        <div style="margin-bottom: -28px;">
               
                <div class="row ml-0 mr-0">
                    <div class="col-md-8">
                        &nbsp;
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <mat-select placeholder="Select Answer Type"
                                (selectionChange)="answerType($event.value, $childIndex)" [(ngModel)]="child.at">
                                <mat-option>None</mat-option>
                                <mat-option value="FT"> Free text/Email/Blank</mat-option>
                                <mat-option value="RT">Ratings/Seekbar</mat-option>
                                <mat-option value="FN">Number (FN/SMS)</mat-option>
                                <mat-option value="NP">Number Picker</mat-option>
                                <mat-option value="DATE">Date</mat-option>
                                <mat-option value="TIME">Time</mat-option>
                                <mat-option value="DATETIME">Date & Time</mat-option>
                                <mat-option value="CB">Checkboxes</mat-option>
                                <mat-option value="RB">Radio button</mat-option>
                                <mat-option value="DD">Dropdown</mat-option>
                                <mat-option value="SDD">Searchable Dropdown</mat-option>
                                <mat-option value="MSDD">Multiselection Dropdown</mat-option>
                                <mat-option value="YN">(Yes/No) button</mat-option>
                                <mat-option value="FLP">FollowUp</mat-option>
                                <mat-option value="ATTACHMENT">Attachment</mat-option>
                                <mat-option value="BLANK">Message</mat-option>
                                <mat-option value="BT">Button</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row ml-0 mr-0">
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter your question here" [(ngModel)]="child.q">
                        </mat-form-field>
                    </div>

                </div>
                <br>
                
                
                
                <div *ngIf="child.at == 'FT'">
                    <app-call-free-text  [removeSubSection]="removedUntitled.bind(this)" [selectedLevel]="1" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false"  [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-free-text>
                </div>
                <div *ngIf="child.at == 'RT'">
                    <app-call-rating-seekbar  [selectedLevel]="1" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-rating-seekbar>
                </div>
                <div *ngIf="child.at == 'FN'">
                    <app-call-number [selectedLevel]="1" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-number>
                </div>
                <div *ngIf="child.at == 'NP'">
                    <app-call-number-picker [selectedLevel]="1" [triggeredAction]="currentRadioAction" [updateNP]="updateNP.bind(this)" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-number-picker>
                </div>
                <div *ngIf="child.at == 'DATE'">
                    <app-call-date [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-date>
                </div>
                <div *ngIf="child.at == 'YN'">
                    <app-call-yesno-button [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-yesno-button>
                </div>
        
                <div *ngIf="child.at == 'ATTACHMENT'">
                    <app-call-attachment [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-attachment>
                </div>
                
                <div *ngIf="child.at == 'BLANK'">
                    <app-call-message [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-message>
                </div>
                
                <div *ngIf="child.at == 'CB'">
                    <app-call-checkbox [selectedLevel]="1" [updateCheckbox]="updateCheckbox.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false"></app-call-checkbox>
                </div>
                
                <div *ngIf="child.at == 'RB'">
                    <app-call-radiobutton [selectedLevel]="1" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [updateRadio]="updateRadio.bind(this)" [triggnerInnerRadio]="triggnerInnerRadio.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [prevCallFlow]="callFlowJsonSections" [triggeredAction]="currentRadioAction"></app-call-radiobutton>
                </div>
                
                <div *ngIf="child.at == 'SDD'">
                    <app-call-searchable-dropdown [selectedLevel]="1"  [updateSDDropDown]="updateSDDropDown.bind(this)" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-searchable-dropdown>
                </div>
               
                <div *ngIf="child.at == 'MSDD'">
                    <app-call-multiselection-dropdown [selectedLevel]="1" [updateMSDDropDown]="updateMSDDropDown.bind(this)" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-multiselection-dropdown>
                </div>
                
                <div *ngIf="child.at == 'TIME'">
                    <app-call-time [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-time>
                </div>

                <div *ngIf="child.at == 'DATETIME'">
                    <app-call-datetime [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-datetime>
                </div>

                <div  *ngIf="child.at == 'DD'">
                    <app-call-dropdown [selectedLevel]="1" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [updateDropDown]="updateDropDown.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-dropdown>
                </div>
                <div *ngIf="child.at == 'FLP'">
                    <app-call-followup [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-followup>
                </div>

                <div *ngIf="child.at == 'BT'">
                    <app-call-button  [selectedLevel]="1" [removeSubSection]="removedUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="child.mandatory === 'y' ? true : false" [updateButton]="updateButton.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex"></app-call-button>
                </div>
                <br>
        </div>
    
    <div *ngIf="child && child.rbShow == true && currentRadioAction != undefined " >
        <div *ngFor="let radioQuestion of child[currentRadioAction];let radioIndex=index">
            <div style="margin-bottom: -28px;">
                   
                    <div class="row ml-0 mr-0">
                        <div class="col-md-8">
                            &nbsp;
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                                <mat-select placeholder="Select Answer Type" (selectionChange)="answerTypeInner($event.value, $index, $childIndex)" [(ngModel)]="radioQuestion.at">
                                    <mat-option>None</mat-option>
                                    <mat-option value="FT"> Free text/Email/Blank</mat-option>
                                    <mat-option value="RT">Ratings/Seekbar</mat-option>
                                    <mat-option value="FN">Number (FN/SMS)</mat-option>
                                    <mat-option value="NP">Number Picker</mat-option>
                                    <mat-option value="DATE">Date</mat-option>
                                    <mat-option value="TIME">Time</mat-option>
                                    <mat-option value="DATETIME">Date & Time</mat-option>
                                    <mat-option value="CB">Checkboxes</mat-option>
                                    <mat-option value="RB">Radio button</mat-option>
                                    <mat-option value="DD">Dropdown</mat-option>
                                    <mat-option value="SDD">Searchable Dropdown</mat-option>
                                    <mat-option value="MSDD">Multiselection Dropdown</mat-option>
                                    <mat-option value="YN">(Yes/No) button</mat-option>
                                    <mat-option value="FLP">FollowUp</mat-option>
                                    <mat-option value="ATTACHMENT">Attachment</mat-option>
                                    <mat-option value="BLANK">Message</mat-option>
                                    <mat-option value="BT">Button</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ml-0 mr-0"  >
                        <div class="col-md-12 col-sm-12">
                            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                                <input matInput [(ngModel)]="radioQuestion.title" placeholder="{{Untitledform}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ml-0 mr-0">
                        <div class="col-md-12 col-sm-12">
                            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                                <input matInput placeholder="Enter your question here" [(ngModel)]="radioQuestion.q">
                            </mat-form-field>
                        </div>
    
                    </div>
                    <br>
                    
                    
                    
                    <div *ngIf="radioQuestion.at == 'FT'">
                        <app-call-free-text [removeSubSection]="removedSubUntitled.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [radioIndex]="radioIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-free-text>
                    </div>
                    <div *ngIf="radioQuestion.at == 'RT'">
                        <app-call-rating-seekbar [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-rating-seekbar>
                    </div>
                    <div *ngIf="radioQuestion.at == 'FN'">
                        <app-call-number [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-number>
                    </div>
                    <div *ngIf="radioQuestion.at == 'NP'">
                        <app-call-number-picker [updateNP]="updateNP.bind(this)" [removeSubSection]="removedSubUntitled.bind(this)"[radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-number-picker>
                    </div>
                    

                    <div *ngIf="radioQuestion.at == 'DATE'">
                        <app-call-date [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-date>
                    </div>
                    <div *ngIf="radioQuestion.at == 'YN'">
                        <app-call-yesno-button [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-yesno-button>
                    </div>
            
                    <div *ngIf="radioQuestion.at == 'ATTACHMENT'">
                        <app-call-attachment [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-attachment>
                    </div>
                    
                    <div *ngIf="radioQuestion.at == 'BLANK'">
                        <app-call-message [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-message>
                    </div>
                    
                    <div *ngIf="radioQuestion.at == 'CB'">
                        <app-call-checkbox [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-checkbox>
                    </div>
                    
                    <div *ngIf="radioQuestion.at == 'RB'">
                        <app-call-radiobutton [updateRadio]="updateRadio.bind(this)" [triggnerInnerRadio]="triggnerInnerRadio.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [radioIndex]="radioIndex" [prevCallFlow]="callFlowJsonSections" [triggeredAction]="currentRadioAction" [removeSubSection]="removedSubUntitled.bind(this)" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-radiobutton>
                    </div>
                    
                    <div *ngIf="radioQuestion.at == 'SDD'">
                        <app-call-searchable-dropdown [updateSDDropDown]="updateSDDropDown.bind(this)" [removeSubSection]="removedSubUntitled.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [radioIndex]="radioIndex" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-searchable-dropdown>
                    </div>
                   
                    <div *ngIf="radioQuestion.at == 'MSDD'">
                        <app-call-multiselection-dropdown [updateMSDDropDown]="updateMSDDropDown.bind(this)" [removeSubSection]="removedSubUntitled.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [radioIndex]="radioIndex" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-multiselection-dropdown>
                    </div>
                    
                    <div *ngIf="radioQuestion.at == 'TIME'">
                        <app-call-time [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-time>
                    </div>
    
                    <div *ngIf="radioQuestion.at == 'DATETIME'">
                        <app-call-datetime [removeSubSection]="removedSubUntitled.bind(this)" [removeSubSection]="removedSubUntitled.bind(this)"[ radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-datetime>
                    </div>
    
                    <div  *ngIf="radioQuestion.at == 'DD'">
                        <app-call-dropdown [updateDropDown]="updateDropDown.bind(this)" [removeSubSection]="removedSubUntitled.bind(this)" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [radioIndex]="radioIndex" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-dropdown>
                    </div>
                    <div *ngIf="radioQuestion.at == 'FLP'">
                        <app-call-followup [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-followup>
                    </div>
    
                    <div *ngIf="radioQuestion.at == 'BT'">
                        <app-call-button [removeSubSection]="removedSubUntitled.bind(this)" [radioIndex]="radioIndex" [selectedIndex]="$index" [selectedChildIndex]="$childIndex" [selectedLevel]="selectedLevel" [untitleToggle]="untitleToggle.bind(this)"  [checked]="radioQuestion.mandatory === 'y' ? true : false" ></app-call-button>
                    </div>
                    <br>
            </div>
        </div>
    </div>
</div>
    <!--end personal details-->
                </mat-card>
                <div class="iconOnBdrBtmDiv">
                    <img class="CR" src="../assets/addCircleIcon.svg" (click)="addUntitled($index)" width="70" />
                </div>
            </div>

        </div>

    </div>
    <div class="col-md-1 mt-6">
        <div>
            <mat-card style="width: 110px;position: relative;top: 66px;border-radius: 12px;">
                <mat-list>
                    <mat-list-item class="CR" matTooltip="Add New Section" matTooltipPosition="after"
                        (click)="addMore()"> <img src="../assets/copyCallIcon.svg" width="50"> </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item class="CR"> <img src="../assets/teleCallIcon.svg" width="40"> </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item class="CR"  matTooltip="Form Preview" matTooltipPosition="after" (click)="formPreview()"> <img src="../assets/mobileCallIcon.svg" width="20"> </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item class="CR" matTooltip="Form Summary" matTooltipPosition="after" (click)="callDuringFormSummary()"> <img src="../assets/setCallIcon.svg" width="35"> </mat-list-item>
                </mat-list>
            </mat-card>

        </div>
    </div>
</div>