<form [formGroup]="campaignForm">
    <table class="tbl">
        <tr style="border-bottom: 1px solid #ccc;">
            <td>
                <h5 class="hd5" style="color: #fd6c21;">Create Campaign</h5>
            </td>
            <td style="text-align: right;"> <i class="fa fa-times-circle closeicon" aria-hidden="true"
                    (click)="closePopup()"></i></td>
        </tr>
        <tr>
            <td><span>Campaign Name</span></td>
            <td>
                <mat-form-field style="width:100%">
                    <input matInput placeholder="Campaign Name" autocomplete="off" formControlName="campaignName"
                        (keypress)="omit_special_char($event)">
                </mat-form-field>
            </td>
        </tr>
        <!-- <tr>
            <td><span>Template Name</span></td>
            <td>
                <mat-form-field style="width:100%">
                    <mat-select  #selectSearch placeholder="Template Name" formControlName="templateName" (selectionChange)="changeTemplateDDL($event.value)">
                        <mat-select-filter *ngIf="selectSearch.focused" [array]="_templateNameListDDl" (filteredReturn)="filteredList3  =$event"></mat-select-filter>
                        <mat-option>None</mat-option>
                        <mat-option [value]="v" *ngFor="let v of filteredList3">{{v}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr> -->
        <tr>
            <td colspan="2">
                <mat-accordion>
                    <mat-expansion-panel hideToggle style="box-shadow: none;border: 1px solid #fd6c21;margin: 0px 0 15px 0;">
                      <mat-expansion-panel-header>
                        <mat-panel-title><b>  Template Preview</b> </mat-panel-title>
                      </mat-expansion-panel-header>

                <table class="previewTbl">

        <tr *ngIf="isShowHeaderEyeOn" style="border-bottom: 1px solid #e3e3e3;">
            <td colspan="2">
                <div  id="hdrText1"  class="mb-2" style="min-height: 20px;padding: 10px;border-radius: 4px;color: black;" >
                <span ><b> {{_headerDataText}} </b></span>
                 <span class="vOnOff" (click)="headerVisibiltyClick('on')"><mat-icon>visibility</mat-icon></span>
            </div>

            </td>
        </tr>
        <tr *ngIf="isShowHeaderEyeOff" style="border-bottom: 1px solid #e3e3e3;">
            <td colspan="2">
            <div   id="hdrText2"  class="mb-2" style="min-height: 20px;padding: 10px;border-radius: 4px;color: black;" >
                 <span > <b> {{headerChangeTextWithBraces}}</b></span>
                 <span class="vOnOff" (click)="headerVisibiltyClick('off')"><mat-icon>visibility_off</mat-icon></span>
            </div>

            </td>
        </tr>

<tr *ngIf="_imageFormatText=='IMAGE'">
    <td colspan="2">
        <p  class="mb-2 pt-2">
            <mat-icon style="color: #ff6c00;vertical-align: middle;">image</mat-icon><span class="ml-2">IMAGE</span><br>

            <mat-radio-group (change)="radioChangeImage($event.value)" [value]="modelGroupImageRadio">
                <mat-radio-button value="selectImageUploadRadio">
                    <span class="btn-file1" style="width: 160px;">
                        Choose Jpg or Png<input type="file" id="testfileImg" (change)="getImageFile($event,$event.target.files)" [disabled]="imageLinkDisabled1">                      
                    </span>                
                </mat-radio-button>
                <mat-radio-button value="enterImageUrlRadio" class="ml-2">
                    <span class="mr-4"> Image Url : </span>
                    <mat-form-field >
                        <input matInput placeholder="Enter Image Url" autocomplete="off" formControlName="enterUrlImageModel" [readonly]="imageLinkDisabled2">
                      </mat-form-field>                
                </mat-radio-button>
              </mat-radio-group>       
                <span style="display: block;">{{fileImageNameContainer}}</span>
        </p>

    </td>
</tr>
<tr *ngIf="_videoFormatText=='VIDEO'">
    <td colspan="2">
          <p  class="mb-2  pt-2">
            <mat-icon style="color: #ff6c00;vertical-align: middle;">video_library</mat-icon><span class="ml-2">VIDEO</span><br>

            <mat-radio-group (change)="radioChangeVideo($event.value)" [value]="modelGroupVideoRadio">
                <mat-radio-button value="selectVideoUploadRadio">
                    <span class="btn-file1 mb-4" style="width:160px;">
                        Choose Video Mp4<input type="file" id="testfile2" (change)="getVideoFile($event,$event.target.files)"  [disabled]="videoLinkDisabled1">
                    </span>                
                </mat-radio-button>
                <mat-radio-button value="enterVideoUrlRadio" class="ml-2">
                    <span class="mr-4"> Video Url : </span>
                    <mat-form-field >
                        <input matInput placeholder="Enter Video Url" autocomplete="off" formControlName="enterUrlVideoModel"  [readonly]="videoLinkDisabled2">
                      </mat-form-field>                
                </mat-radio-button>
              </mat-radio-group>  

            <span style="display: block;">{{filenameContainerVideo}}</span>
          </p>

    </td>
</tr>
<tr *ngIf="_documentFormatText=='DOCUMENT'">
    <td colspan="2">
          <p  class="mb-2  pt-2">
            <mat-icon style="color: #ff6c00;vertical-align: middle;">picture_as_pdf</mat-icon><span class="ml-2">DOCUMENT</span><br>


            <mat-radio-group (change)="radioChangeDocument($event.value)" [value]="modelGroupDocumentRadio">
                <mat-radio-button value="selectDocumentUploadRadio">                  
            <span class="btn-file1" style="width: 170px;">
                Choose PDF file only<input type="file" id="testfile3" (change)="getDocumentFile($event)"  [disabled]="documentLinkDisabled1">
            </span>              
                </mat-radio-button>
                <mat-radio-button value="enterDocumentUrlRadio" class="ml-2">
                    <span class="mr-4"> Document Url : </span>
                    <mat-form-field >
                        <input matInput placeholder="Enter Document Url" autocomplete="off" formControlName="enterUrlDocumentModel" [readonly]="documentLinkDisabled2">
                      </mat-form-field>                
                </mat-radio-button>
              </mat-radio-group> 

            <span  style="display: block;">{{filenameDocumentContainer}}</span>
          </p>
    </td>
</tr>



        <tr>
            <td colspan="2">
                <div style="min-height: 100px;padding: 10px;border-radius: 4px;color: black;"
                    id="resultdivTemplate1"></div>
                <div style="min-height: 100px;padding: 10px;border-radius: 4px;color: black;"
                    id="resultdivTemplate2"></div>

                <p class="text-right mb-0" *ngIf="isShowEyeOn">
                    <mat-icon style="cursor: pointer;color: #dd133c;" (click)="previewTemplateClick('on')">visibility
                    </mat-icon>
                </p>
                <p class="text-right mb-0" *ngIf="isShowEyeOff">
                    <mat-icon style="cursor: pointer;color: #dd133c;" (click)="previewTemplateClick('off')">
                        visibility_off</mat-icon>
                </p>
            </td>
        </tr>
        <tr *ngIf="isShowOnTemplate && _footerData !=''" style="border-top: 1px solid #e3e3e3;">
            <td colspan="2">
                <div class="mb-2"
                    style="min-height: 20px;padding: 10px;border-radius: 4px;color: #a8a4a4;">
                    {{_footerData}}
                </div>
            </td>
        </tr>
        <!--start for buttons-->
<tr *ngIf="btnArrayList.length > 0">
    <td colspan="2">
       <button *ngFor="let b of btnArrayList" class="btnUIDisplay">{{b}}</button>
    </td>
</tr>
<!--end for buttons-->

    </table>
</mat-expansion-panel>
</mat-accordion>


</td>
</tr>






        <tr *ngIf="isShowOnTemplate">
            <td>&nbsp;</td>
            <td>
                <span class="dnldbtn">
                    <button mat-raised-button (click)="download_file()" style="background-color: #fd6c21 !important;">Download
                        Excel Template &nbsp;&nbsp; <i class="fa fa-file-excel" aria-hidden="true"></i> </button>
                </span>
            </td>
        </tr>
        <tr>
            <td><span>Upload Excel</span><span> (only supports xlsx file)</span></td>
            <td>
                <div class="upload-btn-wrapper">
                    <button class="btnbrowse" style="width: 120px;">Browse</button>
                    <input type="file" #fileUpload class="filecs" id="testfile1" formControlName="file"
                        (change)="changeFile($event)" />
                </div>
            </td>
        </tr>
        <tr>
            <td></td>
            <td style="color: #bababa;">{{currentFileobj.name}}</td>
        </tr>

        <tr>
            <td><span>Schedule Date</span></td>
            <td>
                <mat-radio-group [value]="selectRadioValModel" (change)="radioChangeDate($event.value)">
                    <mat-radio-button value="now">Now</mat-radio-button>
                    <mat-radio-button class="ml-4" value="pickTheDate">Pick the Date</mat-radio-button>
                  </mat-radio-group>
            </td>
        </tr>

<tr *ngIf="isShowForDatePick">
    <td></td>
    <td>
        <mat-form-field style="width:120px;">
            <input matInput [matDatepicker]="pickerPickDate" id="dateSetID" [min]="minDateAllow" formControlName="dateSet" placeholder="Date" readonly (click)="pickerPickDate.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerPickDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerPickDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="ml-3"  style="width:120px;">
            <input formControlName="timeSet" id="timeSetID" matTimepicker readonly placeholder="Time" autocomplete="off">
      </mat-form-field>
    </td>
</tr>



        <tr>
            <td></td>
            <td>
                <span>
                    <button mat-raised-button [disabled]="!campaignForm.valid" class="upload_btn uploadSave"
                        style="background-color: #fd6c21 !important;margin: 10px 0;position: relative;"
                        (click)="submit_Camapign(campaignForm)">Save compaign </button>
                </span>
            </td>
        </tr>
    </table>
</form>
<div id="cover-spin" *ngIf="showLoader"></div>