import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, ElementRef, Inject} from '@angular/core';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { MyserviceService } from '../myservice.service';
import { ApiService } from '../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface StreamState {
	playing: boolean;
	readableCurrentTime: string;
	readableDuration: string;
	duration: number | undefined;
	currentTime: number | undefined;
	canplay: boolean;
	error: boolean;
	volume: number;
	isMute: boolean;
  }

@Component({
	selector: 'app-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit, OnChanges {

	@Input() source_url: string;

	state: StreamState = {
		playing: false,
		readableCurrentTime: '',
		readableDuration: '',
		duration: undefined,
		currentTime: undefined,
		volume: 0.5,
		canplay: false,
		error: false,
		isMute: false
	};
	private stop$ = new Subject();
	private audioObj = new Audio();

	audioEvents = [
		"ended",
		"error",
		"play",
		"playing",
		"pause",
		"timeupdate",
		"canplay",
		"loadedmetadata",
		"loadstart",
		"seeked"
	];
	private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(
		this.state
	);

	constructor(private service: MyserviceService, private API: ApiService, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
		//console.log(this.listenAudioData);
		this.resetState()
	}

	ngOnChanges(changes: SimpleChanges) {
		this.resetState()
		this.playStream(this.source_url).subscribe(events => {
			// console.log('events', events);
		})
	}

	onSliderChange(event: any) {
		this.seekTo(event.target.value);
	}

	onSliderChangeEnd(event: any) {
		this.seekTo(event.target.value);
	}

	onVolumeChange(event: any) {
		this.setVolume(event.target.value);
	}

	private updateStateEvents(event: Event): void {
		switch (event.type) {
			case "canplay":
				this.state.duration = this.audioObj.duration;
				this.state.readableDuration = this.formatTime(this.state.duration);
				this.state.canplay = true;
				break;
			case "playing":
				this.state.playing = true;
				break;
			case "pause":
				this.state.playing = false;
				break;
			case "timeupdate":
				this.state.currentTime = this.audioObj.currentTime;
				this.state.readableCurrentTime = this.formatTime(
					this.state.currentTime
				);
				break;
			case "error":
				console.log('error event', event);
				this.resetState();
				this.state.error = true;
				break;
		}
		this.stateChange.next(this.state);
	}

	private resetState() {
		this.state = {
			playing: false,
			readableCurrentTime: '',
			readableDuration: '',
			duration: undefined,
			currentTime: undefined,
			volume: 0.5,
			canplay: false,
			error: false,
			isMute: false
		};
	}

	getState(): Observable<StreamState> {
		return this.stateChange.asObservable();
	}
	private streamObservable(url) {
		return new Observable(observer => {
			// Play audio
			this.audioObj.src = url;
			this.audioObj.load();
			this.audioObj.play();

			const handler = (event: Event) => {
				// console.log('name of event', event);
				this.updateStateEvents(event);
				observer.next(event);
			};

			this.addEvents(this.audioObj, this.audioEvents, handler);
			return () => {
				// Stop Playing
				this.audioObj.pause();
				this.audioObj.currentTime = 0;
				// remove event listeners
				this.removeEvents(this.audioObj, this.audioEvents, handler);
				// reset state
				this.resetState();
			};
		});
	}

	private addEvents(obj, events, handler) {
		events.forEach(event => {
			obj.addEventListener(event, handler);
		});
	}

	private removeEvents(obj, events, handler) {
		events.forEach(event => {
			obj.removeEventListener(event, handler);
		});
	}

	playStream(url:string) {
		return this.streamObservable(url).pipe(takeUntil(this.stop$))
	}

	initPlay(url:string) {
		fetch(url)
			.then(response => { response.blob() })
			.then((blob) => {
				return this.audioObj.play();
			})
			.then(p => { console.log('playing', p) })
			.catch(e => { console.log('Error', e) })
	}

	play() {
		this.audioObj.play();
	}

	pause() {
		this.audioObj.pause();
	}

	stop() {
		this.stop$.next();
	}

	seekTo(seconds: number) {
		// console.log('Seeking val to', seconds);
		this.audioObj.currentTime = seconds;
	}

	previous() {
		// console.log('clicking previous');
		this.audioObj.currentTime -= 10;
	}

	next() {
		// console.log('clicking next');
		this.audioObj.currentTime += 10;
	}

	setVolume(volume: number) {
		this.audioObj.volume = volume;
		this.state.volume = volume;
		if (volume === 0) {
			this.setMute();
		} else {
			this.setUnmute();
		}
		this.stateChange.next(this.state);
	}

	setMute() {
		this.audioObj.muted = true;
		this.state.isMute = true;
	}

	setUnmute() {
		this.audioObj.muted = false;
		this.state.isMute = false;
	}

	formatTime(time: number, format: string = "mm:ss") {
		const momentTime = time * 1000;
		return moment.utc(momentTime).format(format);
	}

	download(){
		let fileName = new Date().getMilliseconds().toString();
		fileName += '_voice';
		this.API.downloadReportFromUrl(this.source_url, null, null, fileName).then(()=>{
			this._snackBar.open('Downloaded Successfully', 'close', {
				duration: 2000
			});
		}, (err)=>{
			// console.log('Error received', err)
			this._snackBar.open(err, 'close', {
				duration: 2000
			});
		})
	}

}
