
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>

<form [formGroup]="createdForm" #f="ngForm">

<div class="row pdt">
    <div class="col-md-12 col-sm-7 col-xs-12">
        <h2 class="d-head">ADD CLIENT<img src="../assets/underline.svg" class="linebelow"> </h2>
        <div class="rightside" *ngIf="showHidePassingMessage">
            <h4  [style.color]="_color" style="font-weight: bold;font-size: 17px;">{{passingMessage}}</h4>
          
             </div>
        <div>
            <div class="row pdt">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Project Name" formControlName="projectName" (keypress)="omit_special_char($event)">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Company" formControlName="companyName" (keypress)="omit_special_char($event)">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <mat-select placeholder="CX Touch Point" formControlName="cxTouchPoint">
                         <mat-option value="Call">Call</mat-option>
                         <mat-option value="Email">Email</mat-option>
                         <mat-option value="Chat">Chat</mat-option>
                         <mat-option value="Call Email">Call + Email</mat-option>
                         <mat-option value="Call Chat">Call + Chat</mat-option>
                         <mat-option value="Email Chat">Email + Chat</mat-option>
                         <mat-option value="Call Email Chat">Call + Email + Chat</mat-option>
                       </mat-select>
                   </mat-form-field>
                </div> 
                <!-- <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Group Id">
                    </mat-form-field>
                </div>             -->
            </div>
            <div class="row pdt">
                <!-- <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Super Admin Id">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <mat-select placeholder="Status">
                         <mat-option value="Active">Active</mat-option>
                         <mat-option value="Inactive">Inactive</mat-option>
                       </mat-select>
                   </mat-form-field>
                </div> -->
                <!-- <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <mat-select placeholder="CX Touch Point">
                         <mat-option value="Call">Call</mat-option>
                         <mat-option value="Email">Email</mat-option>
                         <mat-option value="Chat">Chat</mat-option>
                         <mat-option value="Call Email">Call + Email</mat-option>
                         <mat-option value="Call Chat">Call + Chat</mat-option>
                         <mat-option value="Email Chat">Email + Chat</mat-option>
                         <mat-option value="Call Email Chat">Call + Email + Chat</mat-option>
                       </mat-select>
                   </mat-form-field>
                </div>             -->
            </div>
            <h6 class="pdt">Add Form Details</h6>
            <div class="row ">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Form Name" formControlName="formName" (keypress)="omit_special_char($event)">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'" >
                        <input matInput placeholder="Form JSON" formControlName="formJson">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Normalized Score" type="number" formControlName="normalizedScore" (keypress)="omit_special_char($event)">
                    </mat-form-field>
                </div>            
            </div>
        </div>
    </div>

</div>
<div class="row pdt">
    <div class="col-md-6 col-sm-12 col-xs-12"></div>
    <div class="col-md-6 col-sm-12 col-xs-12 rightside">
        <button mat-raised-button class="cancelbtn" (click)="closeDialog()">Cancel</button> &nbsp;&nbsp;
        <button mat-raised-button class="submitbtn" (click)="addClientWithForm(createdForm)"  [disabled]="!createdForm.valid">Save</button>
    </div>

</div>
</form>