import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
@Component({
  selector: 'app-ojt-agent-info',
  templateUrl: './ojt-agent-info.component.html',
  styleUrls: ['./ojt-agent-info.component.scss']
})
export class OjtAgentInfoComponent implements OnInit {

  showHidePopupDelete:boolean=false;
  createdForm:UntypedFormGroup;
  isreadonly:any=true;
  constructor(public dialogRef: MatDialogRef<OjtAgentInfoComponent>,public formbuilder:UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public viewAgentData) {
    this.createdForm = this.formbuilder.group({
      name:[''],
      userid:[''],
      emailid:[''],
      score:[''],
      status:[''],
      dateJoined:[''],
      aadharNumber:[''],
      currentBatchID:[''],
      attendence:[''],
      location:[''],
      batchType:[''],
      lastUpdateOn:[''],
      agency:[''],
      category:['']

    })
   }

  ngOnInit() {
    //debugger;
    if(this.viewAgentData){
      this.createdForm =  this.formbuilder.group({
        name: [this.viewAgentData.name],
        userid: [this.viewAgentData.id],
        emailid: [this.viewAgentData.email],
        score: [this.viewAgentData.score],
        status: [this.viewAgentData.status],
        dateJoined: [this.viewAgentData.created_at],
        aadharNumber: [this.viewAgentData.adhaar_number],
        currentBatchID: [this.viewAgentData.batch_code],
        attendence: [this.viewAgentData.day_count],
        location : [this.viewAgentData.training_location],
        batchType: [this.viewAgentData.batch_type],
        lastUpdateOn: [this.viewAgentData.last_updated],
        agency: [this.viewAgentData.agency],
        category: [this.viewAgentData.category],
     })
    }
  
  }
  closeDialog(){
    this.dialogRef.close();
  }
  openWarningMessage(){
    this.showHidePopupDelete=true;
  }
  closedeletePopup(){
    this.showHidePopupDelete=false;
  }
}


