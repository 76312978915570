import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';

@Component({
	selector: 'app-joinus',
	templateUrl: './joinus.component.html',
	styleUrls: ['./joinus.component.scss']
})
export class JoinusComponent implements OnInit {
	createdForm: UntypedFormGroup;
	msgColor: any = "";
	messageDisplay: any = "";
	showHideMessage: boolean = false;
	constructor(private formbuilder: UntypedFormBuilder, private API: ApiService) {
		this.createdForm = this.formbuilder.group({
			fullName: ['', Validators.required],
			mobileNo: ['', [Validators.required, Validators.maxLength(10)]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			gender: ['M'],
			language: ['', Validators.required],
			educatioanl: ['', Validators.required],
			workExperience: ['', Validators.required],
			experienceCustomerSupport: ['No'],
			businessProcess: ['Voice'],
			expVoiceSupport: ['', Validators.required],
			setup: ['', Validators.required]

		})
	}

	ngOnInit() {
	}
	joinusBtn(createdForm: any) {
		// debugger;
		this.showHideMessage = false;
		let _fullName = createdForm.controls["fullName"].value;
		let _mobileNo = createdForm.controls["mobileNo"].value;
		let _email = createdForm.controls["email"].value;
		let _password = createdForm.controls["password"].value;
		let _gender = createdForm.controls["gender"].value;
		let _language = createdForm.controls["language"].value;
		let _educatioanl = createdForm.controls["educatioanl"].value;
		let _workExperience = createdForm.controls["workExperience"].value;
		let _experienceCustomerSupport = createdForm.controls["experienceCustomerSupport"].value;
		let _businessProcess = createdForm.controls["businessProcess"].value;
		let _expVoiceSupport = createdForm.controls["expVoiceSupport"].value;
		let _setup = createdForm.controls["setup"].value;

		if (_fullName == "") {
			this.messageDisplay = "Please enter full name*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_mobileNo == "") {
			this.messageDisplay = "Please enter mobile number*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_mobileNo.length != 10) {
			this.messageDisplay = "Mobile Number should be 10 digit*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;
		}
		if (_email == "") {
			this.messageDisplay = "Please enter email*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		if (regEmail.test(_email) == false) {
			this.messageDisplay = "Please enter correct email*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return false;
		}
		if (_password == "" || (_password.length < 8) || (15 < _password.length)) {
			this.messageDisplay = "Please enter password between 8 and 15 character*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;
		}
		if (_language == "") {
			this.messageDisplay = "Please select atleast one language*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_educatioanl == "") {
			this.messageDisplay = "Please select education*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_workExperience == "") {
			this.messageDisplay = "Please select work experience*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_expVoiceSupport == "") {
			this.messageDisplay = "Please select voice support*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}
		if (_setup == "") {
			this.messageDisplay = "Please select atleast one setup*";
			this.msgColor = "red";
			this.showHideMessage = true;
			window.scroll({
				top: 20,
				left: 0,
				behavior: 'smooth'
			});
			return;

		}

		let _obj = {
			"name": _fullName,
			"phone": _mobileNo,
			"email": _email,
			"password": _password,
			"gender": _gender,
			"language": _language.toString(),
			"education": _educatioanl,
			"work_experience": _workExperience,
			"business_process": _businessProcess,
			'reg_mode': 'WEB',
			'login_method': 'smb',
			'additional_data': JSON.stringify({
				"customer_support": _experienceCustomerSupport,
				"voice_support": _expVoiceSupport,
				"setup_options": _setup
			})
		}


		this.API.saveJoin(_obj).subscribe((data: any) => {
			if (data.errors) {
				this.messageDisplay = data.errors + "*";
				this.msgColor = "red";
				this.showHideMessage = true;
				window.scroll({
					top: 20,
					left: 0,
					behavior: 'smooth'
				});
			}
			else {
				this.messageDisplay = "Congratulations, Saved Successfully.!!";
				this.msgColor = "green";
				this.showHideMessage = true;
				this.createdForm.reset();
				window.scroll({
					top: 20,
					left: 0,
					behavior: 'smooth'
				});
			}
		});
	}
	omit_special_char(event) {
		let k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}
	onlyNumberValidation(event) {
		return event.charCode >= 48 && event.charCode <= 57;
	}
}
