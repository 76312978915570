import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,UntypedFormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from 'src/app/myservice.service';
@Component({
  selector: 'app-contiinex-shopify-team-delete-dialog',
  templateUrl: './contiinex-shopify-team-delete-dialog.component.html',
  styleUrls: ['./contiinex-shopify-team-delete-dialog.component.scss']
})
export class ContiinexShopifyTeamDeleteDialogComponent implements OnInit {
  showHideErrorMsg:any;
  msgColor: any = "";
  showHideMsg:boolean=false;

  project:any;
  customer_name:any;
  business_process:any;
  businessType:any;
  api_end_point:any;
  constructor(public dialogRef: MatDialogRef<ContiinexShopifyTeamDeleteDialogComponent>,	@Inject(MAT_DIALOG_DATA) public viewEditData, private _service:MyserviceService,
  private http:HttpClient,private fb:UntypedFormBuilder,private API: ApiService,private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company);
      this.project = String(this._service.get_loggedin_user().project);
      this.business_process = String(this._service.get_loggedin_user().business_process);
      this.businessType = String(this._service.get_loggedin_user().businessType);
    }
    if(this.viewEditData){
    }
  }
  closePopup(){
    this.dialogRef.close('false')
  }
  deleteBtn(){
   let  _obj = {
      // "name": this.viewEditData.name,
      // 'company':this.customer_name || "",
      // 'project':this.project || "",
      // "business_process": this.business_process || "",
      // "email": this.viewEditData.email,
      // "phone":  this.viewEditData.phone,
      // "job_profile":this.viewEditData.job_profile,
      // "role": "user",
      "groupid": -1,
      "new_groupid": -1,
      // "status": "On Board",
      // "businessType" : "COMNEX",
      "id":this.viewEditData.id
    };

    this.API.deleteAddShopifyTeam(_obj, this.api_end_point).subscribe((res: any) => {
      if(res.errors){
        this._snackBar.open(res.errors, 'close', {
          duration: 5000,
        });

        return;
      }
      else {
        this.showHideErrorMsg="Good, Deleted Successfully Done..!!";
        this.showHideMsg=true;
        this.msgColor="green";
        setTimeout(()=>{
          this.showHideErrorMsg="";
           this.showHideMsg=false;
          this.msgColor="";
          this.dialogRef.close('true');
        },3000)
      }
    });
  
  }

  
}
