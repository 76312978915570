import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { Subject, Subscription } from 'rxjs';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'underscore';

import { MyserviceService } from '../../myservice.service';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { GenericAgentactivityComponent } from '../generic-agentactivity/generic-agentactivity.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as io from 'socket.io-client';
import { GenericOutboundNotificationDialogComponent } from '../generic-outbound-notification-dialog/generic-outbound-notification-dialog.component';
import { GenericAgentsTreeViewComponent } from '../../generic-agents-tree-view/generic-agents-tree-view.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CampaignService } from '../../campaign.service'
import { GenericOutboundService } from './generic-outbound.service';
import { CHANNEL_SUMMARY, FOLLOW_UPS, AGENT_STATUS, CALLS_SUMMARY, AGENT_DATA, AGENT_TABLE, PROGRESS_BAR } from './generic-outbound.interface'
import { takeUntil } from 'rxjs/operators';

interface Alert {
	type: string;
	message: string;
}

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

@Component({
	selector: 'app-generic-outbound',
	templateUrl: './generic-outbound.component.html',
	styleUrls: ['./generic-outbound.component.scss'],
	providers: [
		Title,
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
	]
})
export class GenericOutboundComponent implements OnInit, OnDestroy {

	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	timer_pulse_rate = 15;
	currentTitle = 'outbound';
	subscription = new Subscription()
	maxDate = moment().subtract(0, 'day').toISOString();
	currentLoader = false;
	selectedTabSts = 'call_status';
	alert: Alert = { type: 'success', message: "Refreshed successfully..." };
	alertClosed = true;
	slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	isToday: boolean = true;
	sales_stages: string[] = [];
	myDetails = this.myService.getMyDetails();
	myTeamDetails = this.myService.getOnlyMyTeamIds();
	dashboard_agents = null;
	dashboard_selected_nodes = [];
	settings: any = ""
	workHours: any = ""
	dashData: any[] = [];
	selectAll = true;
	color_arr: any = {};
	followupCnt: number = 0;
	is_minimized: boolean = false;
	followupCntDone: number = 0;
	displayedColumnsCallSummary = [
		'type',
		// 'time',
		'login_time',
		'spoken_time',
		'wrap_time',
		'wait_time',
		'idle_time',
		// 'break_time',
		'active_time',
		// 'unavailable_time'
	];

	displayedColumnsCallStatus = [
		'user_id',
		'name',
		'notification',
		'action',
		'live_status',
		'status',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'login_time_hms',
		// "unavailable_time_hms",
		'idle_time_hms',
		'wait_time_hms',
		'spoken_time_hms',
		'wrap_time_hms',
		// 'break_time_hms',
		'active_time_hms',
		'aht',
		'audit_score',
		'ctc_score',
		'connectivity',
		'more'
	];
	  

	callSummaryData: any[] = [{
		'type': 'Total',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00',
		'break_time': '00:00:00',
		'active_time': '00:00:00',
		'unavailable_time': '00:00:00',
		'login_time': '00:00:00'
	},
	{
		'type': 'Average of 0 Agents',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00',

	}]

	selectedAgents: any[] = []
	selectedAgentsLen: number = 0;
	myUser: any;
	final_table_data = [];
	dataSource: any = new MatTableDataSource();
	dataSourceCallSummary: any = new MatTableDataSource();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) tableSort: MatSort;

	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;

	@ViewChild('_searchValue') _searchValue: ElementRef;
	socket;
	api_end_point = this.myService.get_API_End_Point();

	selectedProject: any = "";
	selectedChannel: any = "view/outbound";
	user: any = this.myservice.get_loggedin_user();

	channel_summary$: CHANNEL_SUMMARY;
	followups$: FOLLOW_UPS;
	agents_status$: AGENT_STATUS;
	calls_summary$: CALLS_SUMMARY;
	lead_disposition$;
	agent_table_data$: AGENT_TABLE;
	lead_disposition_names: string[];
	progress_bar$: PROGRESS_BAR;
	private destroy$ = new Subject<void>()

	constructor(
		private titleService: Title,
		private API: ApiService,
		private myService: MyserviceService,
		private dialog: MatDialog,
		public router: Router,
		private activateRoute: ActivatedRoute,
		private myservice: MyserviceService,
		public campaign_service: CampaignService,
		public utils: Utils,
		private _snackBar: MatSnackBar,
		private outbound_service: GenericOutboundService) {
			
	}

	async on_load(){
		try{
			//console.log('subscription started');
			this.destroy$ = new Subject<void>();
			//console.log('destroy$ value', this.destroy$)
			this.outbound_service.api_error.pipe(takeUntil(this.destroy$)).subscribe((val:boolean) => {
				try{
					if(val){
						const dialogRef = this.dialog.open(OutboundApiErrorDialog, {
							width: 'auto',
							position: {
								top: '20px',
							},
							panelClass: 'd-top-center'
						})
				
						dialogRef.afterClosed().subscribe(result => {
							// console.log('result', result);
							if(result == 'refresh'){
								this.refresh_dashboard();
							}else{
								this.outbound_service.set_all_defaults();
							}
						})
					}
				}catch(e){
					console.log('error outbound service api error', e);
				}
			})
			this.outbound_service.progress_bar.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					//console.log('progress bar vals', JSON.stringify(val), new Date())
					this.progress_bar$ = val;
				}catch(e){
					console.log('error in progress bar', e);
				}
			})
			this.outbound_service.channel_summary.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					this.channel_summary$ = val;
				}catch(e){
					console.log('error in channel_summary', e)
				}
				// console.log('channel_summary', this.channel_summary$);
			})
			this.outbound_service.followups.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					this.followups$ = val;
				}catch(e){
					console.log('error in followups', e);
				}
				// console.log('followups', this.followups$);
			})
			this.outbound_service.agents_status.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					this.agents_status$ = val;
				}catch(e){
					console.log('error in agents status', e)
				}
				// console.log('agents_status', this.agents_status$)
			})
			this.outbound_service.calls_summary.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					this.calls_summary$ = val;
					this.summarizeData();
				}catch(e){
					console.log('Error in calls summary', e)
				}
				// console.log('calls_summary component', new Date(), this.calls_summary$);
			})
			this.outbound_service.lead_disposition.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					this.lead_disposition$ = val;
					this.lead_disposition_names = this.outbound_service.lead_disposition_names;
					this.lead_disposition_names.forEach(name => {
						if (this.displayedColumnsCallStatus.indexOf(name) < 0) {
							this.displayedColumnsCallStatus.splice(this.displayedColumnsCallStatus.length - 2, 0, name);
						}
					})
				}catch(e){
					console.log('error in lead_disposition', e)
				}
			})
			this.outbound_service.agent_table_data$.pipe(takeUntil(this.destroy$)).subscribe(val => {
				try{
					// console.log('Agents data', JSON.stringify(val));
					this.agent_table_data$ = val;
					this.dataSource = new MatTableDataSource<AGENT_DATA>(val.agent_data);
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.tableSort;
					this.dataSource.filterPredicate = function (data, filter: any): boolean {
						// console.log(data, filter);
						return data.name.toString().toLowerCase().includes(filter)
					};
				}catch(e){
					console.log('error in agent_table_data', e)
				}
			})


			/* this.subscription.add(
				this.outbound_service.api_error.subscribe((val:boolean) => {
					try{
						if(val){
							const dialogRef = this.dialog.open(OutboundApiErrorDialog, {
								width: 'auto',
								position: {
									top: '20px',
								},
								panelClass: 'd-top-center'
							})
					
							dialogRef.afterClosed().subscribe(result => {
								// console.log('result', result);
								if(result == 'refresh'){
									this.refresh_dashboard();
								}else{
									this.outbound_service.set_all_defaults();
								}
							})
						}
					}catch(e){
						console.log('error outbound service api error', e);
					}
				})
			).add(
				this.outbound_service.progress_bar.subscribe(val => {
					try{
						console.log('progress bar vals', JSON.stringify(val), new Date())
						this.progress_bar$ = val;
					}catch(e){
						console.log('error in progress bar', e);
					}
				})
			).add(
				this.outbound_service.channel_summary.subscribe(val => {
					try{
						this.channel_summary$ = val;
					}catch(e){
						console.log('error in channel_summary', e)
					}
					// console.log('channel_summary', this.channel_summary$);
				})
			).add(
				this.outbound_service.followups.subscribe(val => {
					try{
						this.followups$ = val;
					}catch(e){
						console.log('error in followups', e);
					}
					// console.log('followups', this.followups$);
				})
			).add(
				this.outbound_service.agents_status.subscribe(val => {
					try{
						this.agents_status$ = val;
					}catch(e){
						console.log('error in agents status', e)
					}
					// console.log('agents_status', this.agents_status$)
				})
			).add(
				this.outbound_service.calls_summary.subscribe(val => {
					try{
						this.calls_summary$ = val;
						this.summarizeData();
					}catch(e){
						console.log('Error in calls summary', e)
					}
					// console.log('calls_summary component', new Date(), this.calls_summary$);
				})
			).add(
				this.outbound_service.lead_disposition.subscribe(val => {
					try{
						this.lead_disposition$ = val;
						this.lead_disposition_names = this.outbound_service.lead_disposition_names;
						this.lead_disposition_names.forEach(name => {
							if (this.displayedColumnsCallStatus.indexOf(name) < 0) {
								this.displayedColumnsCallStatus.splice(this.displayedColumnsCallStatus.length - 2, 0, name);
							}
						})
					}catch(e){
						console.log('error in lead_disposition', e)
					}
					// console.log('lead_disposition', this.sales_stages, this.lead_disposition$)
				})
			).add(
				this.outbound_service.agent_table_data.subscribe(val => {
					try{
						//console.log('Agents data', JSON.stringify(val));
						this.agent_table_data$ = val;
						this.dataSource = new MatTableDataSource<AGENT_DATA>(val.agent_data);
						this.dataSource.paginator = this.paginator;
						this.dataSource.sort = this.tableSort;
						this.dataSource.filterPredicate = function (data, filter: any): boolean {
							// console.log(data, filter);
							return data.name.toString().toLowerCase().includes(filter)
						};
					}catch(e){
						console.log('error in agent_table_data', e)
					}
				})
			) */
		}catch(e){
			console.log('Error in onload', e);
		}
	}


	async ngOnInit() {
		//console.log('Init started')
		await this.on_load();
		//console.log('onload completed');
		this.titleService.setTitle('Contiinex | Dashboard');
		this.settings = this.myService.get_stage_settings();
		this.workHours = this.settings.workhours;
		this.myUser = this.myService.get_loggedin_user();
		this.selectedProject = this.myUser.company;
		this.refresh_dashboard()
	}

	get_dashboard_name():string{
		try{
			switch(this.selectedChannel){
				case 'view/outbound':
					return 'Outbound';
					break;
				case 'dashboard-inbound':
					return 'Inbound';
					break;
				case 'whatsapp-dashboard':
					return 'Whatsapp';
					break;
				default:
					return '';
					break;
			}
		}catch(e){
			return ''
		}
	}

	summarizeData() {
		const arr = this.agent_table_data$?.agent_data;
		if (arr) {
			let obj: any = {};

			var add_keys = ['followup', 'dialled', 'contacted', 'unique_dialled', 'call_rnr', 'unique_connected', 'forms_filled', 'idle_time', 'wait_time', 'wrap_time', 'active_time', 'spoken_time', 'unavailable_time', 'break_time', 'login_time', 'audited_calls', 'avg_score', 'ctc_calls', 'ctc_score'];
			if (this.sales_stages.length) {
				for (var i in this.sales_stages) {
					add_keys = add_keys.concat(this.sales_stages[i]['name'])
				}
			}
			// console.log('Summarize Data', add_keys);
			for (var i in arr) {
				var loc_obj = arr[i];
				// console.log('agent wrap time', loc_obj.wrap_time);
				for (var k in loc_obj) {
					if (add_keys.indexOf(k) > -1) {
						if (obj.hasOwnProperty(k)) {
							obj[k] += (loc_obj[k]) ? loc_obj[k] : 0;
						} else {
							obj[k] = (loc_obj[k]) ? loc_obj[k] : 0;
						}
					}
				}
			}

			this.callSummaryData = [{
				'type': 'Total',
				'time': "" + this.utils.gethmsformat(obj.active_time, 'clock'),
				'spoken_time': "" + this.utils.gethmsformat(obj.spoken_time, 'clock'),
				'wrap_time': "" + this.utils.gethmsformat(obj.wrap_time, 'clock'),
				'wait_time': "" + this.utils.gethmsformat(obj.wait_time, 'clock'),
				'idle_time': "" + this.utils.gethmsformat(obj.idle_time, 'clock'),
				'break_time': "" + this.utils.gethmsformat(obj.break_time, 'clock'),
				'unavailable_time': "" + this.utils.gethmsformat(obj.unavailable_time, 'clock'),
				'login_time': "" + this.utils.gethmsformat(obj.login_time, 'clock'),
				'active_time': this.utils.gethmsformat(obj.active_time, 'clock'),
			},
			{
				'type': "" + `Avg. of Logged In Agents`,
				'time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.active_time, this.agents_status$.agents_login), 'clock'),
				'spoken_time': this.utils.gethmsformat(this.utils.getDivision(obj.spoken_time, this.agents_status$.agents_login), 'clock'),
				'wrap_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.wrap_time, this.agents_status$.agents_login), 'clock'),
				'wait_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.wait_time, this.agents_status$.agents_login), 'clock'),
				'idle_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.idle_time, this.agents_status$.agents_login), 'clock'),
				'break_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.break_time, this.agents_status$.agents_login), 'clock'),
				'unavailable_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.unavailable_time, this.agents_status$.agents_login), 'clock'),
				'login_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.login_time, this.agents_status$.agents_login), 'clock'),
				'active_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.active_time, this.agents_status$.agents_login), 'clock'),
			},
			/* {
				'type': "" + `Avg. of all Agents`,
				'time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.active_time, arr.length), 'clock'),
				'spoken_time': this.utils.gethmsformat(this.utils.getDivision(obj.spoken_time, arr.length), 'clock'),
				'wrap_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.wrap_time, arr.length), 'clock'),
				'wait_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.wait_time, arr.length), 'clock'),
				'idle_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.idle_time, arr.length), 'clock'),
				'break_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.break_time, arr.length), 'clock'),
				'unavailable_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.unavailable_time, arr.length), 'clock'),
				'login_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.login_time, arr.length), 'clock'),
				'active_time': "" + this.utils.gethmsformat(this.utils.getDivision(obj.active_time, arr.length), 'clock'),
			} */];
			this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);
		}
	}



	display_stage_name(stage: string) {
		if (stage == 'NEW DATA') {
			return 'NOT SUBMITTED'
		}
		return stage;
	}

	order_change_stages() {
		const stages = this.lead_disposition_names;
		const stage = 'NEW DATA';
		const new_data_index = stages.indexOf(stage);
		// const stage_obj = stages[new_data_index];
		if (new_data_index > -1) {
			stages.splice(new_data_index, 1);
			stages.push(stage);
		}
		// console.log('stages', JSON.stringify(stages));
		return stages;
	}

	initSocket() {
		this.socket.on('connect', () => {
			//   console.log('connected');
			//   console.log('listening on -- ','call_activity_event_for_web_'+this.myUser.groupid);

			this.socket.on("call_activity_event_for_web_" + this.myUser.groupid, (msg: any) => {
				msg = JSON.parse(msg);
				// console.log(msg);
				// console.log(this.dataSource.data);
				var data = this.dataSource.data;
				const user_id_index = data.findIndex(x => x.user_id == msg.user_id);
				if (user_id_index >= 0) {
					// console.log(user_id_index);
					data[user_id_index].last_activity_at = this.utils.utcToLocal(msg.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')
					data[user_id_index].last_activity = this.myService.renameStatusMessage(msg.activity_type.trim());
					data[user_id_index].color = this.myService.getStatusColour(data[user_id_index].last_activity);
					this.dataSource = new MatTableDataSource(data);
					this.dataSource.sort = this.tableSort;
					this.dataSource.paginator = this.paginator;
					this.dataSource.filterPredicate = function (data, filter: any): boolean {
						return data.name.toString().toLowerCase().includes(filter) || data.user_id.toString().toLowerCase().includes(filter)
					};
				}
			});
		});
	}

	get_stage_calculation(stage) {
		if (this.lead_disposition$.hasOwnProperty(stage)) {
			// console.log('stage found', this.lead_disposition$[stage])
			return {
				"value": this.lead_disposition$[stage],
				"percentage": this.utils.getPercentage(this.lead_disposition$[stage], this.calls_summary$.calls_unique_contacted, 1, false)
			}
		}
		return {
			'value': 0,
			'percentage': 0
		}
	}

	get_progress_color(index) {
		const color_pairs = [['color-one', 'tdData1'], ['color-two', 'tdData2'], ['color-three', 'tdData3'], ['color-four', 'tdData4'], ['color-five', 'tdData5']];
		const selected_pair = color_pairs[index % color_pairs.length];
		// console.log('selected pair',index,selected_pair);
		if (selected_pair) {
			return selected_pair;
		}
		return color_pairs[0];
	}

	refreshDatafortoday() {
		this.isToday = true;
		this.messageAlert = "";
		this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		// this.refreshData();
		this._searchValue.nativeElement.value = "";
	}

	refresh_dashboard() {
		//debugger;
		const start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		const end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		var agents_list: any = [];
		if (this.selectedAgents.length == 0) {
			for (const item of this.myService.getAllIds('user')) {
				let agent = { name: item.name, user_id: item.id, checked: true };
				this.selectedAgents.push(agent);
			}
			agents_list = this.myService.getAllIds('user', 'id');
			this.selectedAgentsLen = this.selectedAgents.length;
		} else {
			agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
			this.selectedAgentsLen = agents_list.length;
		}
		//console.log('calling refresh dashboard from service');
		this.outbound_service.refresh_dashboard(String(start_date), String(end_date), agents_list)
	}

	check_number(val) {
		if (!isNaN(val)) {
			return parseInt(val)
		} else {
			return 0;
		}
	}

	/* gethmsformat(sec, mode) {
		if (!sec) {
			return 0;
		}
		var hrs, min, seconds;
		hrs = sec / 3600;
		var temp = sec % 3600;
		min = temp / 60;
		seconds = temp % 60;
		function getDigit(digit) {
			if (!digit) {
				return '00'
			} else {
				digit = Math.floor(digit);
				if (digit > 9) {
					return digit;
				} else {
					return ('0' + digit);
				}
			}
		}
		switch (mode) {
			case 'clock':

				return getDigit(hrs) + ' : ' + getDigit(min) + ' : ' + getDigit(seconds);
				break;
			default:
				return Math.round(hrs) + 'hrs ' + Math.round(min) + 'min ' + Math.round(seconds) + 'sec';
				break;
		}
	} */


	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	changeTogleStatus(value) {
		this.selectedTabSts = value;
	}

	getCallStatusObj(obj) {
		// console.log('printing call status obj', obj);
		let loc_obj = {
			"color": "Inherit",
			"text": "",
			"time": "",
			"duration": 0
		}
		if (this.color_arr[obj.user_id]) {
			if (this.color_arr[obj.user_id]['last_activity']) {
				loc_obj.text = this.color_arr[obj.user_id]['last_activity'];
			}
			if (this.color_arr[obj.user_id]['last_activity_time']) {
				loc_obj.time = this.color_arr[obj.user_id]['last_activity_time'];
				let p = this.utils.getDuration(moment().format('hh:mm:ss a'), loc_obj.time, 'hh:mm:ss a', 'second');
				if (p) {
					loc_obj.duration = Math.floor(p / 60);
				}
			}
		}
		return loc_obj;
	}

	getStatusColor(obj) {
		// console.log('color', obj, this.color_arr);
		// console.log('selected color', obj, this.color_arr);
		if (!obj.user_id) {
			return '#DEE1E6';
		}
		let user_id = obj.user_id;
		if (typeof user_id != 'string') {
			user_id = user_id.toString();
		}
		if (!this.color_arr.hasOwnProperty(user_id)) {
			return '#DEE1E6';
			// console.log('userid found in the status color', typeof user_id, this.color_arr[user_id])
		}
		return this.color_arr[obj.user_id]['color'];
	}

	change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
		this.isToday = false;
		if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			/* if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			} */
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this._snackBar.open("Start date should be less than end date *", 'close');
				//this.messageAlert = "Start date should be less than end date *";
				//this.showHide_messageAlert = true;
			} else {
				this.showHide_messageAlert = false;
			}

		}
	}
	change_date(key: string, event: MatDatepickerInputEvent<Date>) {

		if (key == 'start_date') {
			this.slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				// alert("Start date should be less than end date");
				return;
			}
		} else {
			this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert = "Start date should be less than end date *";
				this.showHide_messageAlert = true;
				return;
			} else {
				this.showHide_messageAlert = false;
			}

		}
	}

	downloadReportGeneral(type?: string) {
		var reportSettings = this.myService.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
		// console.log('report settings', reportSettings);
		let hide_number = this.campaign_service.hide_customer_number_access();
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		// obj.offset = current_date.getTimezoneOffset() * -1;
		obj.incsales = 1;
		obj.userset = 'Team';
		if (!type) {
			if (this.selectedAgents.length == 0) {
				for (const item of this.myService.getAllIds('user')) {
					let agent = { name: item.name, user_id: item.id, checked: true };
					this.selectedAgents.push(agent);
				}
				obj.agents_list = this.myService.getAllIds('user', 'id');
			} else {
				obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
			}
		}
		if (hide_number) {
			obj.hcd = true;
		}
		//console.log('printing obj for post', obj);
		//this.currentLoader = true;
		this.API.downloadCallStatusOutboundReport(obj, type, this.api_end_point).subscribe(
			(res: any) => {
				//this.currentLoader = false;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					//	alert(res.errors);
					this.messageAlert = res.errors;
					this.showHide_messageAlert = true;
					setTimeout(() => {
						this.showHide_messageAlert = false;
					}, 6000)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	downloadAuditReport() {
		this.currentLoader = true;
		this.API.downloadAuditOutboundReport(this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), this.api_end_point).subscribe(
			(res: any) => {
				this.currentLoader = false;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					//alert(res.errors)
					this.messageAlert = res.errors;
					this.showHide_messageAlert = true;
					setTimeout(() => {
						this.showHide_messageAlert = false;
					}, 6000)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	agentSummaryReport() {
		this.currentLoader = true;
		// console.log('printing agent summary report');
		let table_data = this.dataSource.data;
		let columns = [
			{ "key": "name", "title": "Agent Name" },
			{ "key": "email", "title": "Agent Email" },
			{ "key": "dialled", "title": "Dialled" },
			{ "key": "unique_dialled", "title": "Unique Dialled" },
			{ "key": "contacted", "title": "Contacted" },
			{ "key": "unique_contacted", "title": "Unique Contacted" },
			{ "key": "rnr", "title": "RNR" },
			{ "key": "forms", "title": "Forms" },
			{ "key": "login_time_hms", "title": "Login Hours" },
			// { "key": "unavailable_time_hms", "title": "Unavailable Time" },
			{ "key": "idle_time_hms", "title": "Idle Time" },
			{ "key": "wait_time_hms", "title": "Wait Time" },
			{ "key": "spoken_time_hms", "title": "Talk Time" },
			{ "key": "wrap_time_hms", "title": "Wrap Time" },
			// { "key": "break_time_hms", "title": "Break Time" },
			{ "key": "active_time_hms", "title": "Active Time" },
			{ "key": "aht", "title": "AHT" }
		]
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Agent Summary');
			this.callStartDate, this.callEndDate
			worksheet.addRow(['Generated At: ' + String(moment().format('YYYY-MM-DD hh:mm A'))]);
			worksheet.addRow(['Filter By: ' + String(moment(this.callStartDate).format('YYYY-MM-DD')) + ' to ' + String(moment(this.callEndDate).format('YYYY-MM-DD'))])
			worksheet.addRow([])
			let api_arr = [];
			api_arr.push(_.pluck(columns, 'title'))
			worksheet.addRow(_.pluck(columns, 'title'));
			table_data.forEach(element => {
				let arr = [];
				columns.forEach(column => {
					if (element.hasOwnProperty(column.key)) {
						switch (column.key) {
							case 'active_time':
							case 'idle_time':
							case 'wait_time':
							case 'wrap_time':
							case 'spoken_time':
								element[column.key] = this.utils.gethmsformat(element[column.key], 'clock')
								break;
						}
						arr.push(element[column.key])
					} else {
						arr.push('');
					}
				})
				api_arr.push(arr);
				worksheet.addRow(arr);
			});
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'AgentSummaryReport.xlsx');
				this.currentLoader = false;
			})
		}
	}
	openFeedback() {
		// alert('click open new tabl');
		this.router.navigate(
			['./forms'],
			{
				relativeTo: this.activateRoute.parent,
				queryParams: {
					start_date: this.callStartDate,
					end_date: this.callEndDate
				}
			});
	}

	openActivity() {
		this.router.navigate(
			['./agent_activity'],
			{
				relativeTo: this.activateRoute.parent,
				/* queryParams: {
					start_date: this.callStartDate,
					end_date: this.callEndDate
				} */
			}
		)
	}

	openDetailActivity(row) {
		// console.log('printing row', row);
		var s = moment(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss').format('ll');
		var e = moment(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss').format('ll');
		var obj = {
			"agent_id": row.user_id,
			"agent_name": row.name,
			"agent_email": row.email,
			"login_time": (row.raw_data.activity.first_login) ? this.utils.utcToLocal(row.raw_data.activity.first_login, 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A') : '',
			"logout_time": (row.raw_data.activity.last_activity && (row.raw_data.activity.last_activity.activity_type == 'signout')) ? this.utils.utcToLocal(row.raw_data.activity.last_activity.created_at, 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A') : '',
			"active_hrs": this.utils.gethmsformat((row.raw_data.activity.active_time), 'clock'),
			"break_hrs": row.break_time_hms,
			"data": row.raw_data.activity.data,
			"selectedDate": (s === e) ? s : (s + " - " + e),
			"first_act": row.raw_data.activity.first_activity,
			"last_act": row.raw_data.activity.last_activity
		}
		// console.log('obj prepared', obj);
		var dialogRef = this.dialog.open(GenericAgentactivityComponent, {
			width: '60%',
			height: '90%',
			disableClose: true,
			data: obj
		});
	}

	exportAgentActivity() {
		this.currentLoader = true;
		let table_data = this.dataSource.data;
		if (!table_data || !table_data.length) {
			//alert('No data available');
			this.messageAlert = "No data available";
			this.showHide_messageAlert = true;
			setTimeout(() => {
				this.showHide_messageAlert = false;
			}, 6000)
			return;
		}
		// console.log('table data', table_data);
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Activity Report');
		let api_arr = [];
		let header_arr = ['Agent Name', 'Agent Email', 'Type', 'Start Time', 'End Time', 'Duration', 'Reason'];
		api_arr.push(header_arr);
		worksheet.addRow(header_arr);
		for (var i in table_data) {
			if (table_data[i]['raw_data'] && table_data[i]['raw_data']['activity']) {
				let act_data = table_data[i]['raw_data']['activity']['data'];
				if (act_data && act_data.length) {
					for (var k in act_data) {
						let arr = [];
						arr[0] = table_data[i]['name'] ? table_data[i]['name'] : '';
						arr[1] = table_data[i]['email'] ? table_data[i]['email'] : '';
						arr[2] = act_data[k]['activity'];
						arr[3] = this.utils.utcToLocal(act_data[k]['start_time'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
						arr[4] = this.utils.utcToLocal(act_data[k]['end_time'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
						arr[5] = act_data[k]['duration'];
						arr[6] = act_data[k]['reason'];
						api_arr.push(arr);
						worksheet.addRow(arr);
					}
				}
			}
		}
		workbook.xlsx.writeBuffer().then((buff_data) => {
			let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, 'Activity_Report.xlsx');
			this.currentLoader = false;
			/* if (api_arr.length) {
				let api_obj: SEND_EMAIL_BYJSON = {
					"title": "Activity_Report.xlsx",
					"data_arr": api_arr
				}
				this.API.sendJSONBYEmail(api_obj).subscribe()
			} */
		})
	}

	filterTableDataByActivity(activity: string) {
		//console.log('activity', activity);
		//console.log('printing data', this.dataSource.data);
		let loc_data = JSON.parse(JSON.stringify(this.final_table_data));
		//console.log('printng loc data', loc_data);
		let arr = [];
		loc_data.forEach(element => {
			if (activity == 'null') {
				if (element.raw_data && element.raw_data.activity) {
					//console.log(element.raw_data.activity.last_activity.activity_type);
					if (!element.raw_data.activity.last_activity.activity_type) {
						//if(element.raw_data.activity.last_activity.activity_type== 'null'){
						arr.push(element);
					}
				}
			}
			else {
				if (element.raw_data && element.raw_data.activity && element.raw_data.activity.last_activity.activity_type && this.myService.renameStatusMessage(element.raw_data.activity.last_activity.activity_type) == activity) {

					arr.push(element);
				}
			}
			/*if (element.raw_data && element.raw_data.activity &&  element.raw_data.activity.last_activity.activity_type &&  this.myService.renameStatusMessage(element.raw_data.activity.last_activity.activity_type) == activity) {

					arr.push(element);
			}*/
		});
		// console.log('arr', arr);
		this.dataSource.data = arr;

	}

	resetTableDataByActivityFilter() {
		//console.log('Reset filter');
		this.dataSource.filter = "";
		this.dataSource.data = this.final_table_data;
		this.dataSource.sort = this.tableSort;
	}


	actionBergewishper(element, typeName: any) {
		if (typeName == "BI") {
			let obj = {
				"agent_number": element.phone,
				"group_id": this.myUser.groupid,//81580,
				"agent_id": element.user_id,// 83055,
				"add_type": "BARGE"///WHISPER"
			}
			this.API.getBerge(obj, this.api_end_point).subscribe((res: any) => {
				if (res.success) {
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage, "", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					});
					// this.refreshData();
				}
			});
		}
		if (typeName == "BW") {
			let obj = {
				"agent_number": element.phone,// "+919743326277",
				"group_id": this.myUser.groupid,// 81580,
				"agent_id": element.user_id
			}
			this.API.getBergeToWishper(obj, this.api_end_point).subscribe((res: any) => {
				if (res.success) {
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage, "", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					});
					// this.refreshData();
				}
			});
		}
		if (typeName == "WB") {
			let obj = {
				"agent_number": element.phone,
				"group_id": this.myUser.groupid,// 81580,
				"agent_id": element.user_id

			}
			this.API.getWishperToBerge(obj, this.api_end_point).subscribe((res: any) => {
				if (res.success) {
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage, "", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					});
					// this.refreshData();
				}
			});
		}
	}

	getBargeinBtn(element) {
		/* if ((element.last_activity == 'On Call') && (element.sub_disposition == 'Talking')) {
			return true;
		} */
		if (element.last_activity == 'Talking') {
			return true;
		}
	}
	getBargeToWishperBtn(element) {
		if (element.last_activity == 'Barge') {
			return true;
		}
	}
	getWishperToBargeBtn(element) {
		if (element.last_activity == 'Whisper') {
			return true;
		}
	}
	getCurrentDate() {
		let myDate = new Date();
		let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
		let date = myDate.getDate();
		let month = monthsList[myDate.getMonth()];
		let year = myDate.getFullYear();
		let day = daysList[myDate.getDay()];
		let today = `${date} ${month} ${year}, ${day}`;
		return today;
	}
	navigateTo(value) {
		//	console.log(value);
		this.selectedChannel = value;
		this.router.navigate(["/" + value]);
	}
	notificationDialog(element: any) {
		const dialogRef = this.dialog.open(GenericOutboundNotificationDialogComponent, {
			width: '55%',
			disableClose: true,
			data: element
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == 'false') {

			}
			else {

			}
		});
	}
	open_agents() {
		if (!this.dashboard_agents) {
			this.dashboard_agents = this.myService.getUserGroupTreeNode();;
			// this.dashboard_agents[0]['checked'] = true;
			// console.log('dashboard agents', JSON.stringify(this.dashboard_agents));
		}
		const dialogRef = this.dialog.open(GenericAgentsTreeViewComponent, {
			width: '70%',
			disableClose: true,
			data: {
				team: this.dashboard_agents,
				selected_agents: this.selectedAgents
			}
		})

		dialogRef.afterClosed().subscribe(result => {
			// console.log('result', result);
			if (result && result.selected_nodes) {
				this.dashboard_selected_nodes = result.selected_nodes;
				this.dashboard_selected_nodes.forEach((node) => {
					node.checked = true;
					node.user_id = node.id;
				});
				this.selectedAgents = this.dashboard_selected_nodes;
				// this.refreshData();
			}
		})
	}

	ngOnDestroy(): void {
		//console.log('calling on destroy', new Date());
		this.destroy$.next();
		this.destroy$.complete();
	}

	onMinimizedChange(status: boolean) {
		this.is_minimized = status;
	}
}


@Component({
	selector: 'outbound-api-error-dialog',
	templateUrl: 'generic-outbound-api-error.html',
})
export class OutboundApiErrorDialog { 
	constructor(public dialogRef: MatDialogRef<OutboundApiErrorDialog>,){

	}

	close(val?){
		this.dialogRef.close(val);
	}
}
