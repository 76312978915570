import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-contiinex-message-preview-dialog',
  templateUrl: './contiinex-message-preview-dialog.component.html',
  styleUrls: ['./contiinex-message-preview-dialog.component.scss']
})
export class ContiinexMessagePreviewDialogComponent implements OnInit {
  _viewData: any;
  previewHeader: boolean = false;
  previewFooter: boolean = false;
  previewCTAButtons: any[] = [false, false, false];
  stringTemplate: any;

  _videoFormatText: any = "";
  _headerPreviewVideoUrl: any = "";


  _ImageFormatText: any = "";
  _headerPreviewImageUrl: any = "";

  _documentFormatText: any = "";
  _headerPreviewDocumentUrl: any = "";

  _txtFormatText: any = "";
  _headerPreviewTxt: any = "";

  _headerExampleHandle: any = [];
  _bodyExampleHandle: any = [];

  isShowEyeOn: boolean = true;
  isShowEyeOff: boolean = false;

  _bodyMsgOnly:any="";
  _bodyMsgWithDataOnly:any="";
  headerViewUI:any="";
  constructor(public dialogRef: MatDialogRef<ContiinexMessagePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public viewData) { }

  ngOnInit() {
   // debugger;
    this._viewData = this.viewData;
    document.getElementById('resultdivTemplate1').style.display="block";
    document.getElementById('resultdivTemplate2').style.display="none";

    this.stringTemplate = (this._viewData.template.text).replace(new RegExp("\n", "g"), "<br>");
    document.getElementById('resultdivTemplate1').innerHTML = this.stringTemplate;
 // this._bodyMsgOnly=  (document.getElementById('resultdivTemplate1') as HTMLInputElement).innerHTML;

    this._bodyExampleHandle = [];
    if (this._viewData.template.type == 'BODY' && this._viewData.template.text != '' && this._viewData.template.example != '') {
      if(this._viewData.template.example){
  
      this._bodyExampleHandle = this._viewData.template.example.body_text;

if(this._bodyExampleHandle.length>0){

for(let i=0;i< this._bodyExampleHandle[0].length;i++)
{
 // console.log("data: ",this._bodyExampleHandle[0][i]);
  this.stringTemplate = (this.stringTemplate).replace(`{{${i+1}}}`, ' ' + this._bodyExampleHandle[0][i] + ' ');
 // this._bodyMsgWithDataOnly= this.stringTemplate;
  document.getElementById('resultdivTemplate2').innerHTML = this.stringTemplate;
}
}
}
else{
  document.getElementById('resultdivTemplate2').innerHTML = this.stringTemplate;
}

    }
//debugger;
   // console.log(this._viewData);
    if (this.viewData.header) {
      this.previewHeader = true;
      if (this._viewData.header.format == 'TEXT') {
        this._headerExampleHandle = [];
        this._txtFormatText = this._viewData.header.format;
        this.headerViewUI=  this._viewData.header.text;
        this._headerExampleHandle=  this.headerViewUI;
        if (this._viewData.header.example) {
          //  this._headerExampleHandle= this._viewData.header.example.header_text;
          let _hdrHandle = this._viewData.header.example.header_text;
          this._headerExampleHandle="";
          this._headerExampleHandle = (this._viewData.header.text).replace(/\s*\{{.*?\}}\s*/g, ' ' + _hdrHandle + ' ');
        }
      }
      if (this._viewData.header.format == 'VIDEO') {
        this._videoFormatText = this._viewData.header.format;
        this._headerPreviewVideoUrl = (this.viewData.header.example.header_handle).toString();
      }
      if (this._viewData.header.format == 'IMAGE') {
        this._ImageFormatText = this._viewData.header.format;
        this._headerPreviewImageUrl = (this.viewData.header.example.header_handle).toString();
      }
      if (this._viewData.header.format == 'DOCUMENT') {
        this._documentFormatText = this._viewData.header.format;
        this._headerPreviewDocumentUrl = (this.viewData.header.example.header_handle).toString();
      }

    }
    if (this.viewData.footer) {
      this.previewFooter = true;
    }
    if (this.viewData.buttons) {
      for (let i in this.viewData.buttons) {
        if (this.viewData.buttons[i] && (this.viewData.buttons[i].type == 'QUICK_REPLY')) {
          this.previewCTAButtons[i] = true;
        }
      }
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  eyeVisibilty(type: any) {
   // debugger;
    if (type == 'on') {
      this.isShowEyeOn = false;
      this.isShowEyeOff = true;
      document.getElementById('resultdivTemplate1').style.display="none";
      document.getElementById('resultdivTemplate2').style.display="block";
    }
    else if (type == 'off') {
      this.isShowEyeOn = true;
      this.isShowEyeOff = false;
      document.getElementById('resultdivTemplate1').style.display="block";
      document.getElementById('resultdivTemplate2').style.display="none";
    }
  }
}
