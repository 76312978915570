import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-profile-attendance-dialog',
  templateUrl: './profile-attendance-dialog.component.html',
  styleUrls: ['./profile-attendance-dialog.component.scss']
})
export class ProfileAttendanceDialogComponent implements OnInit {
  todayDate=new Date();
  constructor() { }
//public dialogRef: MatDialogRef<ProfileAttendanceDialogComponent>,
  ngOnInit() {
  }
  closeDialog(){
   // this.dialogRef.close();
  }
}
