import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription } from "rxjs";
import {  IStreamState } from '../../interface/stream-state.interface';
import { IAudioDialogData } from '../../interface/audio-dialog-data.interface';
import { AudioserviceService } from '../../audioservice.service';

export interface StreamState {
	playing: boolean;
	readableCurrentTime: string;
	readableDuration: string;
	duration: number | undefined;
	currentTime: number | undefined;
	canplay: boolean;
	error: boolean;
	volume: number;
	isMute: boolean;
  }

@Component({
  selector: 'app-audio-dialog',
  templateUrl: './audio-dialog.component.html',
  styleUrls: ['./audio-dialog.component.scss']
})
export class AudioDialogComponent implements OnInit {
	private _subscription = new Subscription();
	state: IStreamState;

	constructor(
	  @Inject(MAT_DIALOG_DATA) public data: IAudioDialogData,
	  private _dialogRef: MatDialogRef<AudioDialogComponent>,
	  private _audioService: AudioserviceService
	) {}
  
	ngOnInit(): void {
		this._subscribeAudioState();
		this._subscribePlayStream(this.data.url);
	}
  
	ngOnDestroy(): void {
	  this._subscription.unsubscribe();
	}
  
	onSliderChange(event: any): void {
		const value = event.value !== undefined ? event.value : event.target?.value;
		if (value !== undefined) {
			this.seekTo(value);
		}
	}
  
	private formatTime(time: number): string {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	}
  
	// play(): void {
	// 	// this._subscribePlayStream(this.data.url);
	// 	// this._subscribeAudioState();

	//   this._audioService.play();
	// }
  
	// pause(): void {
	//   this._audioService.pause();
	// }

	togglePlay(): void {
		if (this.state.playing) {
			this._audioService.pause();
		} else {
			this._audioService.play();
		}
	}
  
	close(): void {
	  this._audioService.stop();
	  this._dialogRef.close({ foo: '123' });
	}
  
	private _subscribePlayStream(url: string): void {
	  const subscription = this._audioService.playStream(url).subscribe();
  
	  this._subscription.add(subscription);
	}
  
	private _subscribeAudioState(): void {
	  const subscription = this._audioService.getState()
		.subscribe((state) => {
		  this.state = {
			...state,
			currentTime: state.currentTime || 0,
			duration: state.duration || 0
		  };
		});
  
	  this._subscription.add(subscription);
	}

	seekForward(): void {
		if (this.state?.currentTime !== undefined) {
			const newTime = Math.min((this.state.currentTime + 10), this.state.duration || 0);
			this._audioService.seekTo(newTime);
			this.state = {
				...this.state,
				currentTime: newTime
			};
		}
	}

	seekBackward(): void {
		if (this.state?.currentTime !== undefined) {
			const newTime = Math.max((this.state.currentTime - 10), 0);
			this._audioService.seekTo(newTime);
			this.state = {
				...this.state,
				currentTime: newTime
			};
		}
	}

	seekTo(seconds: number) {
		this._audioService.seekTo(seconds);
	}
  }