<mat-card class="h-90 border-0 overflow-auto shadow-none">
  <mat-card-header>
    <!-- [{{ agent_obj.date }}] Activity Timings - <span class="agent_name">{{ agent_obj.name }}</span>
    <button mat-icon-button aria-label="Close Popup" class="close_button" (click)="closeWindow()">
      <mat-icon>cancel</mat-icon>
	</button> -->
	<!-- <button mat-icon-button aria-label="Close Popup" class="position-absolute top-0 end-0" (click)="closeWindow()">
		<mat-icon>cancel</mat-icon>
	  </button> -->
	<div class="container-fluid">
		<div class="row mb-3">
			<div class="col-12">
				<div class="d-flex">
					<span class="fw-bold me-2">Date:</span>
					<span>{{ agent_obj.date }}</span>
				</div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">Agent Name:</span>
					<span>{{ agent_obj.name }}</span>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">Agent Email:</span>
					<span>{{ agent_obj.email }}</span>
				</div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">Login Time:</span>
					<span>{{ agent_obj.login_time }}</span>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">Logout Time:</span>
					<span>{{ agent_obj.logout_time }}</span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">First Activity:</span>
					<span>{{ firstActivity_msg() }}</span>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex">
					<span class="fw-bold me-2">Last Activity:</span>
					<span>{{ lastActivity_msg() }}</span>
				</div>
			</div>
		</div>
	</div>
  </mat-card-header>
  <mat-card-content class="mt-4">
	  <div *ngIf="!ERR_MSG">
		<table mat-table [dataSource]="activity_table" class="table table-striped w-100">
			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef>Type </th>
				<td mat-cell *matCellDef="let element" class="CR">{{element.type}} </td>
			  </ng-container>
			  <ng-container matColumnDef="reason">
				<th mat-header-cell *matHeaderCellDef>Reason </th>
				<td mat-cell *matCellDef="let element" class="CR">{{element.reason}} </td>
			  </ng-container>
			  <ng-container matColumnDef="start_time">
				<th mat-header-cell *matHeaderCellDef> Start Time </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.start_time}} </td>
			  </ng-container>
	
			  <ng-container matColumnDef="end_time">
				<th mat-header-cell *matHeaderCellDef> End Time </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.end_time}} </td>
			  </ng-container>
	
			  <ng-container matColumnDef="duration">
				<th mat-header-cell *matHeaderCellDef> Duration </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.duration}} </td>
			  </ng-container>
	
			  <tr mat-header-row *matHeaderRowDef="activity_table_columns; sticky: true"></tr>
			  <tr mat-row *matRowDef="let row; columns: activity_table_columns;"></tr>
		</table>
	  </div>
	  <div *ngIf="ERR_MSG" class="alert alert-danger">{{ERR_MSG}}</div>
  </mat-card-content>
  <mat-card-actions class="d-flex justify-content-end">
	<!-- <button 
		mat-raised-button
		style="background-color: var(--primary); color: white;"
		matTooltip="Export to Excel"
		matTooltipPosition="above" 
		(click)="exportExcel()">
		Export 
		<mat-icon>file_download</mat-icon>
	</button> -->

	<button mat-raised-button class="btn btn-primary d-flex align-items-center gap-2" (click)="exportExcel()">
		<mat-icon>file_download</mat-icon>
		Export
		
	</button>
  </mat-card-actions>
</mat-card>