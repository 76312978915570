<div  class="searchAdjust">
    <mat-form-field style="font-size: 12px;"  [ngClass]="'inputClr'">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
        <i class="fa fa-search search-icon" matSuffix></i>
      </mat-form-field>
</div>
<br>
<div class="scrollTable">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Name</th>
  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
</ng-container>

<ng-container matColumnDef="rating">
  <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Rating </th>
  <td mat-cell *matCellDef="let element" style="padding-top: 10px;">
    <div id="bloc1"> {{element.rating | number:'1.1-1'}}</div>  
    <div id="bloc2"><ng-template #t let-fill="fill">
      <span class="star" [class.full]="fill === 100">
        <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
      </span>
    </ng-template><ngb-rating [max]="5" [rate]="element.rating==null?0:element.rating" (rateChange)="element.rating == null ? 0 : element.rating = $event"  [starTemplate]="t" [readonly]="true"></ngb-rating>
    </div>
    <div id="bloc3" >/ {{element.total_reviews ? element.total_reviews : 0}}</div>
  </td>
</ng-container>

<ng-container matColumnDef="end_date">
  <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Batch Eds </th>
  <td mat-cell *matCellDef="let element">{{element.end_date=="NA" ?"" : element.end_date | date:'yyyy-MM-dd'}}
     <!-- {{element.end_date | date:'yyyy-MM-dd'}} -->
     </td>
</ng-container>

<ng-container matColumnDef="availability">
  <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Availability </th>
  <td mat-cell *matCellDef="let element" [ngStyle]="getAvailiabilityColor(element.availability)"> {{element.availability}} </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>

